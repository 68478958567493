import React, { Fragment, useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination, Navigation, Autoplay } from "swiper/core";
import "../../assets/styles/homepage/mainslider.scss";
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css";
import "swiper/components/navigation/navigation.min.css";
import { connect } from "react-redux";
import BlueColorImage from "../../assets/images/homepage/BlueColorImage.png";
import YelloColorImage from "../../assets/images/homepage/YelloColorImage.png";

import homemob1 from "../../assets/images/homepage/B&CMaterials.jpg";
import homemob2 from "../../assets/images/homepage/Paint.jpg";
import homemob3 from "../../assets/images/homepage/Decor.jpg";
import homemob4 from "../../assets/images/homepage/Automotive.jpg";
import homemob5 from "../../assets/images/homepage/Tools.jpg";
import homemob6 from "../../assets/images/homepage/HomeAPp.jpg";
import homemob7 from "../../assets/images/homepage/Electrical.jpg";
import homemob8 from "../../assets/images/homepage/Cleaners.jpg";





const SliderData = [
  {
    id: 1,
    name: "yellow",
    image: YelloColorImage,
  },
  {
    id: 2,
    name: "blue",
    image: BlueColorImage,
  },
];
SwiperCore.use([Pagination, Navigation, Autoplay]);

const MainSlider = (props) => {
  const [banners, setBanners] = useState([
    // {
    //   "image": "https://alisuq-production-s3-bucket.s3.me-south-1.amazonaws.com/media/images/Ramadan_V2_800x300.jpg",
    //   "redirects_to_link": "https://alisuq.com/uae-en/ramadan-sale"
    // },
     {
      "image": "https://alisuq-production-s3-bucket.s3.me-south-1.amazonaws.com/media/images/Karcher+Promo+Banner+-+800+x+300.jpg",
      "redirects_to_link": "https://alisuq.com/uae-en/items/products?filter_by_brand%5B%5D=44"
    },
    {
      "image": "https://alisuq-production-s3-bucket.s3.me-south-1.amazonaws.com/media/images/Camping+Sale+GIF-+800+x+300.gif",
      "redirects_to_link": "https://alisuq.com/uae-en/winter-sale"
    },
    // {
    //   "image": "https://alisuq-production-s3-bucket.s3.me-south-1.amazonaws.com/media/images/Main%20Banner%20800%20x%20300%20Mobile.jpg",
    //   "redirects_to_link": "https://alisuq.com/uae-en/winter-sale"
    // },
    // {
    //   "image": "https://alisuq-production-s3-bucket.s3.me-south-1.amazonaws.com/media/images/Mobile+Banner+800+x+300.jpg",
    //   "redirects_to_link": "https://alisuq.com/uae-en/electrical-accessories-sale"
    // },
    // {
    //   "image": "https://alisuq-production-s3-bucket.s3.me-south-1.amazonaws.com/media/images/800-x-300+(1).jpg",
    //   "redirects_to_link": "https://alisuq.com/uae-en/items/products?filter_by_brand%5B%5D=44"
    // },
    {
      "image": "https://alisuq-production-s3-bucket.s3.me-south-1.amazonaws.com/media/images/TotalTools_800x300.jpg",
      "redirects_to_link": "https://alisuq.com/uae-en/items/products?filter_by_brand%5B%5D=372"
    },
    {
      "image": "https://alisuq-production-s3-bucket.s3.me-south-1.amazonaws.com/media/images/SunTek_800+x+300.png",
      "redirects_to_link": "https://alisuq.com/uae-en/items/products?filter_by_brand%5B%5D=342"
    },
    {
      "image": "https://alisuq-production-s3-bucket.s3.me-south-1.amazonaws.com/media/images/Legrand_800x300.jpg",
      "redirects_to_link": "https://alisuq.com/uae-en/items/products?filter_by_brand%5B%5D=171"
    },
    {
      "image": "https://alisuq-production-s3-bucket.s3.me-south-1.amazonaws.com/media/images/800-x-300.png",
      "redirects_to_link": "https://alisuq.com/uae-en/items/products?filter_by_brand%5B%5D=328"
    },
    {
      "image": "https://alisuq-production-s3-bucket.s3.me-south-1.amazonaws.com/media/images/CS+-+RustOleum_800+x+300.png",
      "redirects_to_link": "https://alisuq.com/uae-en/items/products?discount_sort=true&filter_by_brand%5B%5D=42&filter_by_brand%5B%5D=56&filter_by_brand%5B%5D=50&filter_by_brand%5B%5D=43"
    },
    {
      "image": "https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/EhkP71Ef8sBamfoZayVo8.jpeg",
      "redirects_to_link": "https://alisuq.com/uae-en/items/products?filter_by_category%5B%5D=182"
    },
    {
      "image": "https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/hPVCpF6UUpoARWkGAXjGkk.jpeg",
      "redirects_to_link": "https://alisuq.com/uae-en/items/products?filter_by_category%5B%5D=194&filter_by_category%5B%5D=271&filter_by_category%5B%5D=269&filter_by_category%5B%5D=270&filter_by_category%5B%5D=272&filter_by_category%5B%5D=274&filter_by_category%5B%5D=275&filter_by_category%5B%5D=273"
    },
    {
      "image": "https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/eGkADENajRmkjrwcV5xDxH.jpeg",
      "redirects_to_link": "https://alisuq.com/uae-en/items/products?filter_by_category%5B%5D=188&filter_by_category%5B%5D=239&filter_by_category%5B%5D=240&filter_by_category%5B%5D=242&filter_by_category%5B%5D=234&filter_by_category%5B%5D=243&filter_by_category%5B%5D=235&filter_by_category%5B%5D=236&filter_by_category%5B%5D=237&filter_by_category%5B%5D=238&filter_by_category%5B%5D=241"
    },
    {
      "image": "https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/4vB7hhJ7jRqRNu6ZUBvMYg.jpeg",
      "redirects_to_link": "https://alisuq.com/uae-en/items/products?filter_by_category%5B%5D=193&filter_by_category%5B%5D=266&filter_by_category%5B%5D=267&filter_by_category%5B%5D=268"
    },
    {
      "image": "https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/6mV6N5y2CtLDYhhXwpFQve.jpeg",
      "redirects_to_link": "https://alisuq.com/uae-en/items/products?filter_by_category%5B%5D=184&filter_by_category%5B%5D=217&filter_by_category%5B%5D=329&filter_by_category%5B%5D=330&filter_by_category%5B%5D=328&filter_by_category%5B%5D=331&filter_by_category%5B%5D=332&filter_by_category%5B%5D=215&filter_by_category%5B%5D=319&filter_by_category%5B%5D=321&filter_by_category%5B%5D=322&filter_by_category%5B%5D=318&filter_by_category%5B%5D=320&filter_by_category%5B%5D=323&filter_by_category%5B%5D=216&filter_by_category%5B%5D=324&filter_by_category%5B%5D=325&filter_by_category%5B%5D=326&filter_by_category%5B%5D=327"
    },
    {
      "image": "https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/s3X1W3m1Dh2auZPSdHpor3.jpeg",
      "redirects_to_link": "https://alisuq.com/uae-en/items/products?filter_by_category%5B%5D=186&filter_by_category%5B%5D=225&filter_by_category%5B%5D=229&filter_by_category%5B%5D=224&filter_by_category%5B%5D=226&filter_by_category%5B%5D=227&filter_by_category%5B%5D=228&filter_by_category%5B%5D=230"
    },
    // {
    //   "image": "https://alisuq.com/static/media/alidaybannermob.2f13cef5.jpg",
    //   "redirects_to_link": "https://alisuq.com/uae-en/aliday"
    // },
    // {
    //   "image": "https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/qen1XxPN3r4wTy3PmGrrfs.jpeg",
    //   "redirects_to_link": "https://alisuq.com/uae-en/items/products?filter_by_category%5B%5D=191&filter_by_category%5B%5D=259&filter_by_category%5B%5D=262&filter_by_category%5B%5D=257&filter_by_category%5B%5D=260&filter_by_category%5B%5D=261&filter_by_category%5B%5D=258"
    // },
    // {
    //   "image": "https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/zno8K8ZciRRMfeY5puwHg.jpeg",
    //   "redirects_to_link": "https://alisuq.com/uae-en/items/products?filter_by_category%5B%5D=183&filter_by_category%5B%5D=205&filter_by_category%5B%5D=290&filter_by_category%5B%5D=289&filter_by_category%5B%5D=206&filter_by_category%5B%5D=291&filter_by_category%5B%5D=207&filter_by_category%5B%5D=210&filter_by_category%5B%5D=294&filter_by_category%5B%5D=295&filter_by_category%5B%5D=296&filter_by_category%5B%5D=297&filter_by_category%5B%5D=298&filter_by_category%5B%5D=299&filter_by_category%5B%5D=300&filter_by_category%5B%5D=301&filter_by_category%5B%5D=302&filter_by_category%5B%5D=303&filter_by_category%5B%5D=304&filter_by_category%5B%5D=305&filter_by_category%5B%5D=211&filter_by_category%5B%5D=306&filter_by_category%5B%5D=307&filter_by_category%5B%5D=308&filter_by_category%5B%5D=309&filter_by_category%5B%5D=212&filter_by_category%5B%5D=310&filter_by_category%5B%5D=449&filter_by_category%5B%5D=448&filter_by_category%5B%5D=450&filter_by_category%5B%5D=311&filter_by_category%5B%5D=452&filter_by_category%5B%5D=453&filter_by_category%5B%5D=451&filter_by_category%5B%5D=312&filter_by_category%5B%5D=313&filter_by_category%5B%5D=214&filter_by_category%5B%5D=208&filter_by_category%5B%5D=209&filter_by_category%5B%5D=292&filter_by_category%5B%5D=293&filter_by_category%5B%5D=213&filter_by_category%5B%5D=314&filter_by_category%5B%5D=315&filter_by_category%5B%5D=316&filter_by_category%5B%5D=317"
    // }



  ]);
  const [desktopBanners, setDesktopBanners] = useState([]);

  useEffect(() => {
    if (props.banners.length > 0) {
      var last_banner = props.banners[props.banners.length - 1];
  
      props.banners.pop();
      
      let final_banners = [];
      final_banners[0] = last_banner;
  
      props.banners?.map((banner, i) => {
        final_banners[i+1] = banner;
      });
      
      setDesktopBanners(final_banners);
    }
  }, [props.banners]);

  return (
    <Fragment>
      {
          desktopBanners[0]?.image ? (
            <Swiper
              autoplay={true}
              slidesPerView={1}
              spaceBetween={30}
              loop={true}
              pagination={{
                clickable: true,
              }}
              navigation={
                props.screenWidth && props.screenWidth === "sm" ? false : true
              }
              className="homepage-main-banner-swiper"
              dir="ltr"
            >
              {desktopBanners?.map((item) => {
                return (
                  <SwiperSlide key={item.id}>
                    {item?.redirects_to_link ? (
                      <a href={item?.redirects_to_link}>
                        <img
                          className="swiper-image"
                          style={{
                            width: "100%",
                            height: "100%",
                            maxHeight: "100%",
                          }}
                          src={props.screenWidth == "sm" ? item?.image_mobile : item?.image}
                          alt=""
                        />
                      </a>
                    ) : (
                      <img
                        className="swiper-image"
                        style={{
                          width: "100%",
                          height: "100%",
                          maxHeight: "100%",
                        }}
                        src={props.screenWidth == "sm" ? item?.image_mobile : item?.image}
                        alt=""
                      />
                    )}
                  </SwiperSlide>
                );
              })}
            </Swiper>
          ) : (
            <Swiper
              autoplay={true}
              slidesPerView={1}
              spaceBetween={30}
              loop={true}
              pagination={{
                clickable: true,
              }}
              navigation={
                props.screenWidth && props.screenWidth === "sm" ? false : true
              }
              className="homepage-main-banner-swiper"
              dir="ltr"
            >
              {SliderData?.map((item) => {
                return (
                  <SwiperSlide key={item.id}>
                    <img className="swiper-image" src={props.screenWidth == "sm" ? item?.image_mobile : item?.image} alt="" />
                  </SwiperSlide>
                );
              })}
            </Swiper>
          )
      }
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    banners: state.home.primaryBanners,
    direction: state.language.direction,
    screenWidth: state.layout.screenWidth,
  };
};

export default connect(mapStateToProps, null)(MainSlider);
