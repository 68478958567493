import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import WebsiteFooter from "../../components/Footer/WebsiteFooter";
import pagesbannerimg from "../../assets/images/footer-pages-banners/brands_az.jpg";

import { apiJson } from "../../Api";
import "../../assets/styles/footer-pages/brands-az.scss";
import $ from "jquery";
import WebsiteHeader from "../../components/header/WebsiteHeader";
import { useTranslation } from "react-i18next";

const BrandsAZ = (props) => {
  const [brands, setBrands] = useState([]);
  const { t } = useTranslation();

  let message = "";

  useEffect(() => {
    $("html, body").animate({ scrollTop: 0 }, "fast");

    apiJson.header.allBrands(props.countryId).then((response) => {
      if (response.success) {
        setBrands(response.data);
      } else if (!response.status) {
        // eslint-disable-next-line
        message = response.message
          ? response.message !== ""
            ? response.message
            : "something went wrong"
          : "something went wrong";
        showError();
      } else {
        if (response.status >= 400 && response.status < 500) {
          message = response.message
            ? response.message
            : response.errors
            ? response.errors
            : t("errors.wrong");
          showError();
        } else {
          message = response.message
            ? response.message !== ""
              ? response.message
              : t("errors.wrong")
            : t("errors.wrong");
          showError();
        }
      }
    });
  }, [props.countryId]);

  const showError = () => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  return (
    <Fragment>
      <div className="product-home-page-desktop brands-desktop mx-auto">
        <WebsiteHeader />
        <div className="pagesbanner">
          <img
            src={pagesbannerimg}
            style={{
              width: "100%",
              height: "100%",
              maxHeight: "400px",
            }}
            alt="category"
          ></img>
        </div>
        <div className="content-wrapper all-page-container">
          <div className="footer-pages-content brandsLogosSection">
            <div className="row">
              {brands.map((brand, i) => {
                return (
                  <div className="col-lg-2 col-md-2 text-center">
                    <Link
                      to={{
                        pathname:
                          "/" +
                          props.countryUrl +
                          "-" +
                          props.language +
                          "/items/products",
                        state: { brand: brand.id },
                      }}
                    >
                      <img
                        src={brand.image}
                        className="img-fluid"
                        style={{ height: "70px" }}
                        alt=""
                      />
                    </Link>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className="w-100">
          <WebsiteFooter />
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    language: state.language.language,
    country: state.language.country,
    countryUrl: state.language.countryUrl,
    countryId: state.language.countryId,
    coutriesList: state.language.coutriesList,
    categories: state.header.allCategories,
    featuredCategories: state.header.featuredCategories,

    width: state.layout.screenWidth,
    lang: state.language.language,
    user: state.auth.user,

    cart_items: state.cart.cart_items,
    cart_items_length: state.cart.cart_items_length,
  };
};

export default connect(mapStateToProps)(BrandsAZ);
