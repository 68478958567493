import React, { Fragment } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "../../assets/styles/customer/cart/thankyoupage.scss";
import ThankYouRBG from "../../assets/images/Cart/Cart-Empty/ThankYouPage/ThankYouRBG.png";
import { useTranslation } from "react-i18next";
import { Link, Redirect, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { useEffect } from "react";
import { set_cart_items } from "../../redux/actions/CartAction";

const en_translation = require("../../assets/locales/en/translation.json");
const ar_translation = require("../../assets/locales/ar/translation.json");

const ThankYouPage = (props) => {
  const { t } = useTranslation();
  const location = useLocation();

  useEffect(
    () =>
      props.set_cart_items({
        cart_items: {},
        cart_items_length: 0,
        order: {},
        sub_total: 0,
        discount: 0,
        delivery_fee: 0,
        vat: 0,
        grand_total: 0,
      }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return (
    <Fragment>
      {location.state ? (
        <div className="container thank-you-page-desktop">
          <Row className="no-gutters">
            <Col md={12}>
              <div className="justify-content-center align-items-center">
                <div className=" thank-you-page-card-area">
                  <div className="col-sm-12  text-center no-gutters">
                    <img
                      src={ThankYouRBG}
                      width="350"
                      height="350"
                      alt=""
                      className="img-fluid mb-4 mr-3 "
                    />
                    <h5 className="cart-text thankyou-head-text">
                      {t("thankyou-page.ty")}
                    </h5>
                    <div className="cart-text thankyou-sub-text">
                      <span className="order-placed-text mt-auto mb-auto">
                        {t("thankyou-page.order-placed")}
                      </span>
                      <h5
                        className="order-placed-no mt-auto mb-auto"
                        style={{ marginRight: "10px" }}
                      >
                        {t("checkout.order-no")} {location.state}!
                      </h5>
                    </div>
                    <Link
                      to={"/" + props.countryUrl + "-" + props.language + "/"}
                    >
                      <button className="thankyoupage-continueshopping-button ">
                        {
                          props.language == "en" ?
                            en_translation["cart-page"]["empty"]["continue-shop-btn"]
                            :
                            ar_translation["cart-page"]["empty"]["continue-shop-btn"]
                        }
                        {/* {t("cart-page.empty.continue-shop-btn")} */}
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      ) : (
        <Redirect
          push
          to={"/" + props.countryUrl + "-" + props.language + "/cart"}
        />
      )}
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    language: state.language.language,
    countryUrl: state.language.countryUrl,
  };
};

const mapDispatchToProps = { set_cart_items };

export default connect(mapStateToProps, mapDispatchToProps)(ThankYouPage);
