import React from "react";

import ProductList from "../../product/ProductList.js";
import "../../../assets/styles/component/customer/itemDetail/ItemsViewed.scss";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import ProductCard from "../../mobile/Home/ProductCardMobile";

function ItemViewed(props) {
  const { t } = useTranslation();

  return (
    <>
      {
        props.width == "sm" ?
          <div className="item-viewed-mobile">
            {props.itemReviewedProducts?.length > 0 && (
              <>
                <div className={
                  props.language == "ar" ? "heading-ar" : "heading"
                }>{t("item-detail.related-products")}</div>
                <div className="product-cont">
                  <div
                    className="product-cont mt-3"
                    style={{ marginTop: "0px !important" }}
                  >
                    {props.itemReviewedProducts?.map((data) => (
                      <Link
                        style={{ textDecoration: "none" }}
                        to={{
                          pathname:
                            "/" +
                            props.countryUrl +
                            "-" +
                            props.language +
                            `/${data.category_title_en
                              .toLowerCase()
                              .replace(/ /g, "-")}/${data.slug}`,
                          state: {
                            id: data.id,
                            type: data.variants ? "products" : "services",
                          },
                          search: "?sku=" + data.variants[0].sku
                        }}
                        key={data.id + Math.random()}
                      >
                        <div
                          className="image-cont"
                          key={data.id + Math.random() + "prod"}
                        >
                          <ProductCard
                            key={data.id + Math.random()}
                            img={
                              data.variants
                                ? data.variants[0].featured_image
                                : data.featured_image
                            }
                            storeLogo={data.store_logo}
                            wishlisted={
                              data.variants
                                ? data.variants[0].wishlisted
                                : data.wishlisted
                            }
                            home={true}
                            type={data.variants ? "products" : "services"}
                            id={data.variants ? data.variants[0].id : data.id}
                            rating={data.average_rating}
                            reviews={data.reviews_count}
                            name={data[`name_${props.language}`]}
                            data={data.variants ? data.variants[0] : data}
                            coupon={data.coupon}
                            is_inquiry={data.is_inquiry}
                          />
                        </div>
                      </Link>
                    ))}
                  </div>
                </div>
              </>
            )}
            {props.itemReviewedServices?.length > 0 && (
              <>
                <div className={
                  props.language == "ar" ? "heading-ar" : "heading"
                }>{t("item-detail.related-services")}</div>
                <div className="product-cont">
                  <div
                    className="product-cont mt-3"
                    style={{ marginTop: "0px !important" }}
                  >
                    {props.itemReviewedServices?.map((data) => (
                      <Link
                        style={{ textDecoration: "none" }}
                        to={{
                          pathname:
                            "/" +
                            props.countryUrl +
                            "-" +
                            props.language +
                            `/${data.category_title_en
                              .toLowerCase()
                              .replace(/ /g, "-")}/${data.slug}`,
                          state: {
                            id: data.id,
                            type: data.variants ? "products" : "services",
                          },
                        }}
                        key={data.id + Math.random()}
                      >
                        <div
                          className="image-cont"
                          key={data.id + Math.random() + "prod"}
                        >
                          <ProductCard
                            key={data.id + Math.random()}
                            img={
                              data.variants
                                ? data.variants[0].featured_image
                                : data.featured_image
                            }
                            storeLogo={data.store_logo}
                            wishlisted={
                              data.variants
                                ? data.variants[0].wishlisted
                                : data.wishlisted
                            }
                            home={true}
                            type={data.variants ? "products" : "services"}
                            id={data.variants ? data.variants[0].id : data.id}
                            rating={data.average_rating}
                            reviews={data.reviews_count}
                            name={data[`name_${props.language}`]}
                            data={data.variants ? data.variants[0] : data}
                            coupon={data.coupon}
                            is_inquiry={data.is_inquiry}
                          />
                        </div>
                      </Link>
                    ))}
                  </div>
                </div>
              </>
            )}
            {props.itemReviewedTools?.length > 0 && (
              <>
                <div className={
                  props.language == "ar" ? "heading-ar" : "heading"
                }>{t("item-detail.related-tools")}</div>
                <div className="product-cont">
                  <div
                    className="product-cont mt-3"
                    style={{ marginTop: "0px !important" }}
                  >
                    {props.itemReviewedTools?.map((data) => (
                      <Link
                        style={{ textDecoration: "none" }}
                        to={{
                          pathname:
                            "/" +
                            props.countryUrl +
                            "-" +
                            props.language +
                            `/${data.category_title_en
                              .toLowerCase()
                              .replace(/ /g, "-")}/${data.slug}`,
                          state: {
                            id: data.id,
                            type: data.variants ? "products" : "services",
                          },
                        }}
                        key={data.id + Math.random()}
                      >
                        <div
                          className="image-cont"
                          key={data.id + Math.random() + "prod"}
                        >
                          <ProductCard
                            key={data.id + Math.random()}
                            img={
                              data.variants
                                ? data.variants[0].featured_image
                                : data.featured_image
                            }
                            storeLogo={data.store_logo}
                            wishlisted={
                              data.variants
                                ? data.variants[0].wishlisted
                                : data.wishlisted
                            }
                            home={true}
                            type={data.variants ? "products" : "services"}
                            id={data.variants ? data.variants[0].id : data.id}
                            rating={data.average_rating}
                            reviews={data.reviews_count}
                            name={data[`name_${props.language}`]}
                            data={data.variants ? data.variants[0] : data}
                            coupon={data.coupon}
                            is_inquiry={data.is_inquiry}
                          />
                        </div>
                      </Link>
                    ))}
                  </div>
                </div>
              </>
            )}
          </div>
          :
          <div className="item-viewed-desktop">
            {props.itemReviewedProducts?.length > 0 && (
              <>
                <div className={"heading " + (props.language == "en" ? "text-left" : "text-right")}>{t("item-detail.related-products")}</div>
                <ProductList onQuickViewClicked={props.onQuickViewClicked} products={props.itemReviewedProducts} />
              </>
            )}
            {props.itemReviewedServices?.length > 0 && (
              <>
                <div className={"heading " + (props.language == "en" ? "text-left" : "text-right")}>{t("item-detail.related-services")}</div>

                <ProductList onQuickViewClicked={props.onQuickViewClicked} products={props.itemReviewedServices} />
              </>
            )}
            {props.itemReviewedTools?.length > 0 && (
              <>
                <div className={"heading " + (props.language == "en" ? "text-left" : "text-right")}>{t("item-detail.related-tools")}</div>

                <ProductList onQuickViewClicked={props.onQuickViewClicked} products={props.itemReviewedTools} />
              </>
            )}
            {props.section1_products?.length > 0 && (
              <>
                <div className={"heading " + (props.language == "en" ? "text-left" : "text-right")}>{t("item-detail.frequently-bought-together")}</div>
                <ProductList onQuickViewClicked={props.onQuickViewClicked} products={props.section1_products} />
              </>
            )}
            {props.brand_products?.length > 0 && (
              <>
                <div className={"heading " + (props.language == "en" ? "text-left" : "text-right")}>{t("item-detail.more-from-brand")}</div>
                <ProductList onQuickViewClicked={props.onQuickViewClicked} products={props.brand_products} />
              </>
            )}
            {props.hardware_products?.length > 0 && (
              <>
                <div className={"heading " + (props.language == "en" ? "text-left" : "text-right")}>{t("item-detail.how-about-these")}</div>
                <ProductList onQuickViewClicked={props.onQuickViewClicked} products={props.hardware_products} />
              </>
            )}
            {props.seller_products?.length > 0 && (
              <>
                <div className={"heading " + (props.language == "en" ? "text-left" : "text-right")}>{t("item-detail.you-might-also-like")}</div>
                <ProductList onQuickViewClicked={props.onQuickViewClicked} products={props.seller_products} />
              </>
            )}
          </div>
      }
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    itemReviewedProducts: state.item.itemReviewedProducts,
    itemReviewedServices: state.item.itemReviewedServices,
    itemReviewedTools: state.item.itemReviewedTools,
    brand_products: state.item.brand_products,
    section1_products: state.item.section1_products,
    hardware_products: state.item.hardware_products,
    seller_products: state.item.seller_products,
    width: state.layout.screenWidth,
    countryUrl: state.language.countryUrl,
    language: state.language.language,
  };
};

export default connect(mapStateToProps, null)(ItemViewed);
