import React, { Fragment } from "react";

import "../../assets/styles/product/productcategory.scss";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

const ProductCategory = (props) => {
  return (
    <Fragment>
      <div className="product-category-container mr-1">
        <div className="product-category-image-container ">
          <Link
            to={{
              pathname: "/" +
                props.countryUrl +
                "-" +
                props.language +
                "/items/products",
              search: "?filter_by_category%5B%5D=" + props.id.toString().replaceAll(",", "&filter_by_category%5B%5D=")
            }}
          >
            <img src={props.product.img} alt="category" className="img img-fluid" />
          </Link>
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    language: state.language.language,
    countryUrl: state.language.countryUrl,
  };
};

export default connect(mapStateToProps, null)(ProductCategory);