import React, { useState } from "react";
import { toast } from "react-toastify";
import { Redirect } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import { apiJson } from "../../Api";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

import "react-toastify/dist/ReactToastify.css";
import "../../assets/styles/mobile/verifyphonemobile.scss";
import { set_cart_items } from "../../redux/actions/CartAction";

const VerifyPhoneMobile = (props) => {
  const { t } = useTranslation();
  const { mobile_number } = props;
  const [redirect, setRedirect] = useState({ value: false });
  const [loading, setLoading] = useState(false);
  const [otp, setOtp] = useState("");

  let message = "";

  const showError = () => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const varifyOtp = () => {
    if (otp.length !== 4) {
      message = "otp length must be 4";
      showError();
    } else {
      setLoading(true);
      apiJson.phone.verifyOtp(props.id, otp).then((response) => {
        if (response.success) {
          apiJson.select
            .varifyAdress(props.request, props.countryId)
            .then((response) => {
              setLoading(false);
              if (response.success) {
                props.set_cart_items({
                  order: {
                    address: props.currAddress,
                    id: response.data.id,
                    createdAddress: response.data,
                  },
                });
                setRedirect({
                  value: true,
                  path:
                    "/" + props.countryUrl + "-" + props.language + "/checkout",
                });
                const timer = setTimeout(() => {
                  setRedirect({ value: false });
                }, 1000);
                clearTimeout(timer);
              } else if (!response.status) {
                setLoading(false);
                message = t("errors.wrong");
                showError();
              } else {
                setLoading(false);
                if (response.status >= 400 && response.status < 500) {
                  message = response.message
                    ? response.message
                    : response.errors
                    ? response.errors
                    : t("errors.wrong");
                  showError();
                } else {
                  message = response.message
                    ? response.message !== ""
                      ? response.message
                      : t("errors.wrong")
                    : t("errors.wrong");
                  showError();
                }
              }
            });
        } else if (!response.status) {
          setLoading(false);
          message = response.message
            ? response.message !== ""
              ? response.message
              : t("errors.wrong")
            : t("errors.wrong");
          showError();
        } else {
          setLoading(false);
          if (response.status >= 400 && response.status < 500) {
            message = response.message
              ? response.message
              : response.errors
              ? response.errors
              : t("errors.wrong");
            showError();
          } else {
            message = response.message
              ? response.message !== ""
                ? response.message
                : t("errors.wrong")
              : t("errors.wrong");
            showError();
          }
        }
      });
    }
  };

  return (
    <div className="verify-phone-mobile d-flex flex-column justify-content-center align-items-center px-3">
      <div className="wrapper d-flex flex-column justify-content-center">
        <div className="cta text-center mt-3">
          {t("verify-phone-number-mobile.title")}
        </div>
        <div className="description text-center mt-3">
          {t("verify-phone-number-mobile.description")}
          <div className="mobile-number">{mobile_number}</div>
        </div>
      </div>

      <div className="input-container d-flex justify-content-center mt-3">
        <input
          type="number"
          value={otp}
          placeholder="1234"
          onChange={(e) => setOtp(e.target.value)}
          name="otp"
          id="otp"
          style={{ textAlign: "center", outline: "none" }}
        />
      </div>
      <div
        onClick={varifyOtp}
        className="bottom d-flex justify-content-center mt-4"
      >
        <button className="verify-btn text-uppercase">
          {loading ? (
            <div className="d-flex justify-content-center align-items-center w-100">
              <ClipLoader color="#fff" loading size={20} />
            </div>
          ) : (
            t("verify-phone-number-mobile.verify")
          )}
        </button>
      </div>

      {redirect.value && (
        <Redirect
          push
          to={{
            pathname: redirect.path,
            state: redirect.state,
          }}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    countryUrl: state.language.countryUrl,
    language: state.language.language,
    address: state.customerAddress.address,
    countryId: state.language.countryId,
  };
};

const mapDispatchToProps = { set_cart_items };

export default connect(mapStateToProps, mapDispatchToProps)(VerifyPhoneMobile);
