import React from "react";
import EditProfileMobile from "../../../components/mobile/customer/EditProfileMobile";
import MobileLayoutRenderer from "../layout/MobileLayoutRenderer";

export default function ProfileMobilePage() {
  return (
    <div>
      <MobileLayoutRenderer
        NavbarTop={true}
        BackBtn={false}
        CancelBtn={true}
        NavbarBottom={false}
      >
        <EditProfileMobile />
      </MobileLayoutRenderer>
    </div>
  );
}
