import React, { useEffect, useState } from "react";
import "../../assets/styles/pages/paymentdatamob.scss";
import { connect } from "react-redux";
import PaymentEmptyMob from "./PaymentEmptyMob";
import Switch from "@material-ui/core/Switch";
import MobileLayoutRenderer from "./layout/MobileLayoutRenderer";
import { set_payment_data } from "../../redux/actions/PaymentAction";
import ClipLoader from "react-spinners/ClipLoader";
import { apiJson } from "../../Api";
import MasterCard from "../../assets/icons/card_2.png";
import VisaCard from "../../assets/icons/visaCard.png";
import { set_loading } from "../../redux/actions/LayoutAction";
import moment from "moment";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import "react-toastify/dist/ReactToastify.css";
import HeaderMobile from "../../components/mobile/HeaderMobile";

const PaymentDataMob = (props) => {
  const { t } = useTranslation();
  const [data, setData] = useState(false);
  const [loading, setLoading] = useState(-1);
  const [def, setDef] = useState({ value: false, id: -1 });
  let message = "";

  const showError = () => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  useEffect(() => {
    document.title = "Payments";
    props.set_loading(true);
    apiJson.payments.getPaymentData().then((response) => {
      props.set_loading(false);
      if (response.success) {
        props.set_payment_data({ payment: response.data });
        setData(true);
      } else if (!response.status) {
        setData(true);
        // eslint-disable-next-line
        message = response.message
          ? response.message !== ""
            ? response.message
            : t("errors.wrong")
          : t("errors.wrong");
        showError();
      } else {
        setData(true);
        if (response.status >= 400 && response.status < 500) {
          message = response.message
            ? response.message
            : response.errors
              ? response.errors
              : t("errors.wrong");
          showError();
        } else {
          message = response.message
            ? response.message !== ""
              ? response.message
              : t("errors.wrong")
            : t("errors.wrong");
          showError();
        }
      }
    });
  }, []);
  const setDefault = (id) => {
    setDef({ value: true, id });
    apiJson.payments.setDefault(id).then((response) => {
      setDef({ value: false, id: -1 });
      if (response.success) {
        props.set_payment_data({
          payment: props.payment.map((ele) =>
            ele.id === id
              ? { ...ele, default: !ele.default }
              : ele.default
                ? { ...ele, default: false }
                : ele
          ),
        });
      } else if (!response.status) {
        message = t("errors.wrong");
        showError();
      } else {
        if (response.status >= 400 && response.status < 500) {
          message = response.message
            ? response.message
            : response.errors
              ? response.errors
              : t("errors.wrong");
          showError();
        } else {
          message = response.message
            ? response.message !== ""
              ? response.message
              : t("errors.wrong")
            : t("errors.wrong");
          showError();
        }
      }
    });
  };

  const deleteCard = (id) => {
    setLoading(id);
    apiJson.payments.deleteCard(id).then((response) => {
      if (response.success) {
        setLoading(-1);
        props.set_payment_data({
          payment: props.payment.filter((ele) => ele.id !== id),
        });
      } else if (!response.status) {
        message = t("errors.wrong");
        showError();
      } else {
        if (response.status >= 400 && response.status < 500) {
          message = response.message
            ? response.message
            : response.errors
              ? response.errors
              : t("errors.wrong");
          showError();
        } else {
          message = response.message
            ? response.message !== ""
              ? response.message
              : t("errors.wrong")
            : t("errors.wrong");
          showError();
        }
      }
    });
  };

  return (
    <MobileLayoutRenderer
      NavbarTop={false}
      BackBtn={true}
      CancelBtn={false}
      NavbarBottom={true}
      SearchIcon={true}
    >
      <div className="home-mob">
        <HeaderMobile />
      </div>
      <>
        {props.width === "sm" && (
          <div className="payment-data-mob-main">
            {!data ? (
              <></>
            ) : props.payment.length < 1 ? (
              <>
                <PaymentEmptyMob />
              </>
            ) : (
              <div>
                {props.payment.map((payment) => (
                  <div key={payment.id} className=" container payment-data-mob">
                    <div className="data-box">
                      <div className="uper-row">
                        <span className="uper-left-side">
                          {Math.floor(
                            parseInt(
                              moment(
                                new Date(
                                  payment.expiry_date.split("/")[0] +
                                  "/01/" +
                                  payment.expiry_date.split("/")[1]
                                )
                              ).diff(moment(), "months", true)
                            )
                          ) > -1
                            ? t("payment.mobile.card-ending-in")
                            : t("payment.mobile.card-ended")}
                          <span className="ending-in">
                            {Math.floor(
                              parseInt(
                                moment(
                                  new Date(
                                    payment.expiry_date.split("/")[0] +
                                    "/01/" +
                                    payment.expiry_date.split("/")[1]
                                  )
                                ).diff(moment(), "months", true)
                              )
                            ) > -1 &&
                              Math.floor(
                                parseInt(
                                  moment(
                                    new Date(
                                      payment.expiry_date.split("/")[0] +
                                      "/01/" +
                                      payment.expiry_date.split("/")[1]
                                    )
                                  ).diff(moment(), "months", true)
                                )
                              )}{" "}
                            {Math.floor(
                              parseInt(
                                moment(
                                  new Date(
                                    payment.expiry_date.split("/")[0] +
                                    "/01/" +
                                    payment.expiry_date.split("/")[1]
                                  )
                                ).diff(moment(), "months", true)
                              )
                            ) > -1 && t("payment.mobile.months")}
                          </span>
                        </span>
                        <div className="uper-right-side">
                          <img
                            src={
                              payment?.payment_card_type === "Visa"
                                ? VisaCard
                                : payment?.payment_card_type === "MasterCard"
                                  ? MasterCard
                                  : MasterCard
                            }
                            alt="mastercard-logo"
                            width="50px"
                            height="35px"
                          />
                        </div>
                      </div>
                      <div className="bottom-row">
                        <div className="bottom-left-span">
                          <span className="name-label">
                            {t("payment.mobile.name")}
                          </span>
                          <span className="name">
                            {props.isSignedIn
                              ? props.user[`first_name_${props.language}`]
                              : ""}
                          </span>
                        </div>
                        <div className="bottom-right-span">
                          <span className="expiry-label">
                            {t("payment.mobile.expiry")}
                          </span>

                          <span className="expiry_date">
                            {moment(
                              new Date(
                                payment.expiry_date.split("/")[0] +
                                "/01/" +
                                payment.expiry_date.split("/")[1]
                              )
                            ).format("LL")}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="delete-label-and-switch">
                      <div
                        onClick={() => deleteCard(payment.id)}
                        className=" label"
                      >
                        {loading === parseInt(payment.id) ? (
                          <div className="d-flex justify-content-center align-items-center w-100">
                            <ClipLoader color="#000" loading size={20} />
                          </div>
                        ) : (
                          t("payment.mobile.delete")
                        )}
                      </div>
                      <div className="switch ">
                        <label className="switch-label">
                          {t("payment.mobile.default")}
                        </label>
                        <div className="inner-switch">
                          <Switch
                            checked={payment.default}
                            onChange={(e) => {
                              setDefault(payment.id);
                            }}
                            color="secondary"
                            name="checkedB"
                            inputProps={{ "aria-label": "primary checkbox" }}
                          />
                          {def.value && def.id === payment.id && (
                            <div className="d-flex justify-content-center align-items-center w-100">
                              <ClipLoader color="#000" loading size={20} />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        )}
      </>
    </MobileLayoutRenderer>
  );
};

const mapStateToProps = (state) => {
  return {
    width: state.layout.screenWidth,
    payment: state.payment.payment,
    user: state.auth.user,
    isSignedIn: state.auth.isSignedIn,
    load: state.layout.loading,
  };
};

const mapDispatchToProps = {
  set_payment_data,
  set_loading,
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentDataMob);
