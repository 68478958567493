import React, { Fragment, useState, useEffect } from "react";

import "./disputesdetails.scss";
import Message from "./components/Message";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ClipLoader from "react-spinners/ClipLoader";
import { apiJson } from "../../../Api";
import moment from "moment";
import { set_loading } from "../../../redux/actions/LayoutAction";

const DisputesDetails = (props) => {
  const [data, setData] = useState(false);
  const [disputeData, setDisputesData] = useState([]);
  const [description, setDescription] = useState("");
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const { id } = useParams();

  let message = "";
  const showError = () => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    props.set_loading(true);
    apiJson.disputes.getDispute(id).then((response) => {
      props.set_loading(false);
      if (response.success) {
        setDisputesData(response.data);
        setData(true);
      } else if (!response.status) {
        // eslint-disable-next-line
        message = response.message
          ? response.message !== ""
            ? response.message
            : t("errors.wrong")
          : t("errors.wrong");
        showError();
      } else {
        if (response.status >= 400 && response.status < 500) {
          message = response.message
            ? response.message
            : response.errors
            ? response.errors
            : t("errors.wrong");
          showError();
        } else {
          message = response.message
            ? response.message !== ""
              ? response.message
              : t("errors.wrong")
            : t("errors.wrong");
          showError();
        }
      }
    });
  }, []);

  const createMessage = () => {
    setLoading(true);
    apiJson.disputes
      .createReply(id, {
        dispute_reply: {
          description: description,
        },
      })
      .then((response) => {
        setLoading(false);
        if (response.success) {
          setDisputesData({
            ...disputeData,
            replies: [...disputeData.replies, response.data],
          });
          setDescription("");
        } else if (!response.status) {
          message = response.message
            ? response.message !== ""
              ? response.message
              : t("errors.wrong")
            : t("errors.wrong");
          showError();
        } else {
          if (response.status >= 400 && response.status < 500) {
            message = response.message
              ? response.message
              : response.errors
              ? response.errors
              : t("errors.wrong");
            showError();
          } else {
            message = response.message
              ? response.message !== ""
                ? response.message
                : t("errors.wrong")
              : t("errors.wrong");
            showError();
          }
        }
      });
  };

  return (
    <Fragment>
      <div className="disputesdetails-page-desktop mx-auto">
        {!data ? (
          <div className="spinner-style"></div>
        ) : (
          <div className="content-wrapper">
            <div className="detail-row mx-auto d-flex">
              <div className="dispute-detail d-flex flex-column">
                <div className="detail d-flex">
                  <div className="label">{t("dispute-details.dispute")}</div>
                  <div className="value">{disputeData.id}</div>
                </div>
                <div className="detail d-flex">
                  <div className="label">{t("dispute-details.store")}</div>
                  <div className="value">
                    {disputeData[`store_name_${props.language}`]}
                  </div>
                </div>
                <div className="detail d-flex">
                  <div className="label">{t("disputes.filed-at")}:</div>
                  <div className="value">
                    {moment(new Date(disputeData.created_at)).format("LL")}
                  </div>
                </div>
                {disputeData.line_item?.sku && (
                  <div className="detail d-flex">
                    <div className="label">{"Sku:"}</div>
                    <div className="value">{disputeData.line_item?.sku}</div>
                  </div>
                )}
              </div>
              <div className="customer-detail  d-flex flex-column">
                <div className="detail d-flex">
                  <div className="label">{t("dispute-details.order-no")}</div>
                  <div className="value">{disputeData.order_id}</div>
                </div>
                <div className="detail d-flex">
                  <div className="label">
                    {t("dispute-details.customer-email")}
                  </div>
                  <div className="value">{disputeData.customer_email}</div>
                </div>
                <div className="detail d-flex">
                  <div className="label">{t("dispute-details.status")}</div>
                  <div className="value">
                    <div
                      className={
                        disputeData.status.toLowerCase() ===
                        "Active".toLowerCase()
                          ? "green"
                          : "border"
                      }
                    >
                      {disputeData.status}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="chat-row mx-auto">
              <div style={{ textAlign: "start" }} className="header">
                {t("disputes-page-mob.replies")}{" "}
              </div>
              <div className="messages">
                {disputeData.replies.map((message) => {
                  return (
                    <Message
                      message={message}
                      dispute={disputeData}
                      key={message.id}
                    />
                  );
                })}
              </div>
              {disputeData.status.toLowerCase() !== "closed" && (
                <form
                  action="
                  "
                  onSubmit={(e) => {
                    e.preventDefault();
                    createMessage();
                  }}
                >
                  <div className="editor-row d-flex w-100">
                    <input
                      type="text"
                      name="message"
                      className="editor"
                      placeholder={t("dispute-details.type-message")}
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                    />
                    <button className="send-button" type="submit">
                      {loading ? (
                        <div className="d-flex justify-content-center align-items-center w-100">
                          <ClipLoader color="#fff" loading size={20} />
                        </div>
                      ) : (
                        t("dispute-details.send-btn")
                      )}
                    </button>
                  </div>
                </form>
              )}
            </div>
          </div>
        )}
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    direction: state.language.direction,
    language: state.language.language,
  };
};

const mapDispatchToProps = { set_loading };

export default connect(mapStateToProps, mapDispatchToProps)(DisputesDetails);
