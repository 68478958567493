import React, { useState } from "react";
import "../../../../assets/styles/utils.scss";
import "../../../../assets/styles/customer/account/orders/orderdetails.scss";
import Shipment from "./shipment/Shipment";
import OrderHeader from "./OrderHeader";
import PaymentMethod from "./shipment/PaymentMethod";
import OrderSummary from "./OrderSummary";
import { IoChevronBackOutline } from "react-icons/io5";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import YelloColorImage from "../../../../assets/images/homepage/YelloColorImage.png";

import { useTranslation } from "react-i18next";

const OrderDetails = (props) => {
  const [redirect, setRedirect] = useState(false);

  const { t } = useTranslation();
  return (
    <div
      className={`order-details h-100 ${props.width !== "sm" ? " pt-2" : " "}`}
    >
      <div
        onClick={() => {
          setRedirect(true);
          const timer = setTimeout(() => {
            setRedirect(false);
          }, 1000);
          clearTimeout(timer);
        }}
        className="small-screen-none back"
      >
        {props.language?.toLowerCase() === "en" ? (
          <IoChevronBackOutline
            className="icon fw-600"
            style={{
              transform: "unset",
            }}
          />
        ) : (
          <IoChevronBackOutline
            className="icon fw-600"
            style={{
              transform: "rotate(180deg)",
            }}
          />
        )}
        <div className=" text faded-black-2-text d-inline-block vertical-align-middle">
          <span className="pointer">{t("orderDetails.back-btn")}</span>
        </div>
      </div>

      <div className={`top-section ${props.width !== "sm" ? " pt-2" : " "}`}>
        <div className="small-screen-none row no-gutters">
          <div className="banner col-md-12">
            {props.banners[0]?.image ? (
              <>
                {props.banners[0]?.redirects_to_link ? (
                  <a href={props.banners[0]?.redirects_to_link}>
                    <img
                      style={{ width: "100%", height: "100%" }}
                      src={props.banners[0].image}
                      alt=""
                    />
                  </a>
                ) : (
                  <img
                    style={{ width: "100%", height: "100%" }}
                    src={props.banners[0].image}
                    alt=""
                  />
                )}
              </>
            ) : (
              <img
                src={YelloColorImage}
                alt="banner"
                style={{ width: "100%", height: "100%" }}
              />
            )}
          </div>
        </div>

        <OrderHeader order={props.orders} />
      </div>

      <div className="shipments">
        {props.orders?.shipments?.map((shipment, index) => (
          <Shipment
            orderId={props.orders.id}
            shipment={shipment}
            currency={props.orders.currency}
            index={index}
            key={shipment.id}
            type="product"
          />
        ))}
      </div>
      <div className="shipments">
        {props.orders?.services?.map((shipment, index) => (
          <Shipment
            orderId={props.orders.id}
            shipment={shipment}
            currency={props.orders.currency}
            index={index}
            key={shipment.id}
            type="services"
          />
        ))}
      </div>
      {props.orders.payment_methods && (
        <PaymentMethod paymentMethod={props.orders.payment_methods} />
      )}

      <OrderSummary shipmentDetail={props.orders} />
      {redirect && (
        <Redirect
          push
          to={"/" + props.countryUrl + "-" + props.language + "/orders"}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    width: state.layout.screenWidth,
    language: state.language.language,
    banners: state.order.banners,
    countryUrl: state.language.countryUrl,
  };
};

export default connect(mapStateToProps)(OrderDetails);
