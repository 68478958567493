import React, { Fragment, useEffect } from "react";
import AddressForm from "../components/customer/AddressForm";
import ShippingFooter from "../components/customer/shippingfooter/ShippingFooter";
import Header from "../components/customer/shippingheader/Header";
import { Redirect, useLocation } from "react-router-dom";
import Sidebar from "../components/customer/account/Sidebar";
import { Row } from "react-bootstrap";
import { connect } from "react-redux";
import WebsiteHeader from "../components/header/WebsiteHeader";
import "../assets/styles/pages/addaddressform.scss";

const AddAddressForm = (props) => {
  const location = useLocation();
  useEffect(() => {
    document.title = "Add Address";
  }, []);
  return !location.state ? (
    <Fragment>
      {props.cart_items?.line_items?.length > 0 ? (
        <div className="address-form-page">
          <Header screen_name="ADDRESS" />
          <div className="address-form-row mx-auto">
            <AddressForm />
          </div>
          <div className="add-address-page-footer">
            <ShippingFooter />
          </div>
        </div>
      ) : (
        <Redirect
          push
          to={"/" + props.countryUrl + "-" + props.language + "/cart"}
        />
      )}
    </Fragment>
  ) : (
    <Fragment>
      <Row className="page-container no-gutters">
        <div className="w-100">
          <WebsiteHeader />

          <div className="content-container all-page-container mx-auto d-flex mt-4 mb-4">
            <div className="left-pane">
              <Sidebar />
            </div>
            <div className="right-pane">
              <AddressForm />
            </div>
          </div>
        </div>
      </Row>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    cart_items: state.cart.cart_items,
    language: state.language.language,
    countryUrl: state.language.countryUrl,
  };
};

export default connect(mapStateToProps, null)(AddAddressForm);
