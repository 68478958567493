import { type } from "../types/types";

export const set_orders = (orders) => (dispatch) => {
  dispatch({
    type: type.SET_ORDERS,
    orders: orders,
  });
};

export const set_order_detail = (orderDetails) => (dispatch) => {
  dispatch({
    type: type.SET_ORDER_DETAIL,
    orderDetails: orderDetails,
  });
};
