import React from "react";
import { connect } from "react-redux";

import TermsAndConditionsComp from "../../../components/mobile/footer-pages/TermsAndConditionsComp";

function TermsAndConditions(props) {
  return <div>{props.width === "sm" && <TermsAndConditionsComp screen="home" />}</div>;
}

const mapStateToProps = (state) => {
  return {
    width: state.layout.screenWidth,
  };
};

export default connect(mapStateToProps, null)(TermsAndConditions);
