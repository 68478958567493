import React, { Fragment, useState, useEffect } from "react";
import { connect } from "react-redux";
import WishlistCardMob from "../../components/mobile/WishlistCardMob";
import MobileLayoutRenderer from "./layout/MobileLayoutRenderer";
import EmptyReturnsState from "../../assets/images/wishlist_art.svg";
import { set_cart_items, set_wish_list } from "../../redux/actions/CartAction";
import { apiJson } from "../../Api";
import { set_loading } from "../../redux/actions/LayoutAction";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import "../../assets/styles/mobile/wishlistmobilepage.scss";
import { getMobileImage } from "../../utils/utils";
import HeaderMobile from "../../components/mobile/HeaderMobile";

const WishlistMobPage = (props) => {
  const [loading, setLoading] = useState(false);
  const [dataWishlist, setDataWishlist] = useState(false);
  const { t } = useTranslation();

  let message = "";

  const showError = () => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  useEffect(() => {
    document.title = "Wishlist";
  }, []);

  useEffect(() => {
    if (localStorage.getItem("access_token")) {
      props.set_loading(true);
      setLoading(true);
      apiJson.cart.getWishList(props.countryId).then((response) => {
        props.set_loading(false);
        if (response.success) {
          props.set_wish_list({ wish_list: response.data });
          setDataWishlist(true);
          setLoading(false);
        } else if (!response.status) {
          setDataWishlist(true);
          // eslint-disable-next-line
          message = response.message
            ? response.message !== ""
              ? response.message
              : t("errors.wrong")
            : t("errors.wrong");
          showError();
          setLoading(false);
        } else {
          setDataWishlist(true);
          if (response.status >= 400 && response.status < 500) {
            message = response.message
              ? response.message
              : response.errors
              ? response.errors
              : t("errors.wrong");
            showError();
          } else {
            message = response.message
              ? response.message !== ""
                ? response.message
                : t("errors.wrong")
              : t("errors.wrong");
            showError();
          }
        }
      });
    } else {
      setDataWishlist(true);
      setLoading(false);
      props.set_wish_list({ wish_list: [] });
    }
  }, []);
  return (
    <MobileLayoutRenderer
      NavbarTop={false}
      BackBtn={true}
      CancelBtn={false}
      NavbarBottom={true}
      SearchIcon={true}
    >
      <>
        {props.width === "sm" && (
          <Fragment>
          <div className="home-mob">
            <HeaderMobile />
          </div>
            <div className="wishlist-mobile-page-main bg-white h-100">
              <div className="inner-main">
                <div className="first-container">
                  {t("wishlist.my-wishlist")}
                  <span className="total-items">
                    ({props.wish_list.length} {t("wishlist.items")})
                  </span>
                </div>
                {loading ? (
                  <></>
                ) : (
                  <div className="wishlist-List-card">
                    {props.wish_list.length === 0 ? (
                      <>
                        <div className="empty-wishlist-image">
                          <img
                            src={EmptyReturnsState}
                            className="img-fluid"
                            alt=""
                            width="100%"
                            height="100%"
                          />
                        </div>
                      </>
                    ) : (
                      props.wish_list.length > 0 &&
                      dataWishlist &&
                      props.wish_list?.map((item) => (
                        <WishlistCardMob
                          key={item.id}
                          id={item.id}
                          orderable_id={item.line_item.id}
                          type={item.orderable_type}
                          cartOrWishlist="wishlist"
                          name={
                            item.orderable_type === "Product"
                              ? item.line_item[
                                  `product_name_${props.language}`
                                ] +
                                " " +
                                item.line_item[`name_${props.language}`]
                              : item.line_item[`name_${props.language}`]
                          }
                          brand={item.line_item[`brand_name_${props.language}`]}
                          discountPrice={item.discounted_price}
                          price={item.price}
                          image_path={getMobileImage(
                            item.line_item.featured_image_resized,
                            item.line_item.featured_image
                          )}
                          sold_by={
                            item.line_item[`store_name_${props.language}`]
                          }
                        />
                      ))
                    )}
                  </div>
                )}
              </div>
            </div>
          </Fragment>
        )}
      </>
    </MobileLayoutRenderer>
  );
};
const mapStateToProps = (state) => {
  return {
    width: state.layout.screenWidth,
    wish_list: state.cart.wish_list,
    countryId: state.language.countryId,
    load: state.layout.loading,
    language: state.language.language,
  };
};
const mapDispatchToProps = { set_wish_list, set_cart_items, set_loading };

export default connect(mapStateToProps, mapDispatchToProps)(WishlistMobPage);
