import { type } from "../../types/types";
const initialState = {
  item: {},
  itemReviewedProducts: [],
  itemReviewedServices: [],
  itemReviewedTools: [],
  section1_products: [],
  brand_products: []
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case type.SET_SINGLE_ITEM:
      return {
        ...state,
        ...action.item,
      };
    default:
      return state;
  }
}
