import React, { Fragment } from "react";

import "../assets/styles/pages/selectshipping.scss";
import Header from "../components/customer/shippingheader/Header";
import SelectShippingAddress from "../components/customer/SelectShippingAddress";
import ShippingFooter from "../components/customer/shippingfooter/ShippingFooter";
import { useEffect } from "react";
import { apiJson } from "../Api";
import { useState } from "react";
import { set_addresses } from "../redux/actions/SelectAddressAction";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { connect } from "react-redux";
import Login from "../components/user/Login";
import { useTranslation } from "react-i18next";
import Signup from "../components/user/Signup";
import { Redirect, useLocation } from "react-router-dom";
import { set_user } from "../redux/actions/AuthAction";
import { set_loading } from "../redux/actions/LayoutAction";

const SelectShipping = (props) => {
  const [data, setData] = useState(false);
  const { t } = useTranslation();
  const [signup, setSignup] = useState(false);
  let message = "";
  const location = useLocation();

  const showError = () => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  useEffect(() => {
    document.title = "Shipping";
  }, []);
  useEffect(() => {
    if (props.cart_items?.line_items?.length > 0) {
      window.scrollTo(0, 0);
      props.set_loading(true);

      if (props.auth) {
        apiJson.select.getAddress().then((response) => {
          props.set_loading(false);

          setData(true);
          if (response.success) {
            props.set_addresses({
              address: response.data,
            });
          } else if (!response.status) {
            // eslint-disable-next-line
            message = t("errors.wrong");
            showError();
          } else {
            if (response.status >= 400 && response.status < 500) {
              message = response.message
                ? response.message
                : response.errors
                ? response.errors
                : t("errors.wrong");
              showError();
            } else {
              message = response.message
                ? response.message !== ""
                  ? response.message
                  : t("errors.wrong")
                : t("errors.wrong");
              showError();
            }
          }
        });
      } else {
        apiJson.cart.guestUser(props.countryId).then((response) => {
          props.set_loading(false);

          if (response.success) {
            props.set_addresses({
              coupon_id: location.state?.coupon_id,
            });
            props.set_user({
              guestUser: response.data.platform_settings.guest_user_checkout,
              guestSignin: response.data.platform_settings.guest_user_checkout
                ? false
                : !props.auth,
            });

            setData(true);
          } else {
            props.set_user({
              guestUser: response.data.platform_settings.guest_user_checkout,
              guestSignin: response.data.platform_settings.guest_user_checkout
                ? false
                : !props.auth,
            });
            setData(true);
          }
        });
        
        if (localStorage.getItem("guest_user_address")) {
          props.set_addresses({
            address: [
              JSON.parse(localStorage.getItem("guest_user_address"))
            ],
          });
        }
        else {
          props.set_addresses({
            address: [],
          });
        }
      }
    }
  }, []);

  useEffect(() => {
    if (props.cart_items?.line_items?.length > 0) {
      window.scrollTo(0, 0);
      props.set_loading(true);
      if (props.auth) {
        apiJson.select.getAddress().then((response) => {
          props.set_loading(false);
          setData(true);
          if (response.success) {
            props.set_addresses({
              address: response.data,
            });
          } else if (!response.status) {
            // eslint-disable-next-line
            message = t("errors.wrong");
            showError();
          } else {
            if (response.status >= 400 && response.status < 500) {
              message = response.message
                ? response.message
                : response.errors
                ? response.errors
                : t("errors.wrong");
              showError();
            } else {
              message = response.message
                ? response.message !== ""
                  ? response.message
                  : t("errors.wrong")
                : t("errors.wrong");
              showError();
            }
          }
        });
      } else {
        apiJson.cart.guestUser(props.countryId).then((response) => {
          props.set_loading(false);
          if (response.success) {
            props.set_user({
              guestUser: response.data.platform_settings.guest_user_checkout,
              guestSignin: response.data.platform_settings.guest_user_checkout
                ? false
                : !props.auth,
            });
            setData(true);
          } else {
            props.set_user({
              guestUser: response.data.platform_settings.guest_user_checkout,
              guestSignin: response.data.platform_settings.guest_user_checkout
                ? false
                : !props.auth,
            });
            setData(true);
          }
        });
        
        if (localStorage.getItem("guest_user_address")) {
          props.set_addresses({
            address: [
              JSON.parse(localStorage.getItem("guest_user_address"))
            ],
          });
        }
        else {
          props.set_addresses({
            address: [],
          });
        }
      }
    }
  }, [props.auth]);
  return (
    <Fragment>
      {props.cart_items?.line_items?.length > 0 ? (
        <div className="select-shipping-page-desktop w-100">
          <Header
            screen_name={props.auth || props.flag ? "ADDRESS" : "SIGNIN"}
          />
          {data ? (
            props.auth || props.flag ? (
              <SelectShippingAddress />
            ) : signup ? (
              <Signup address={true} signinClicked={(val) => setSignup(val)} />
            ) : (
              <Login
                logo={true}
                welcome={t("login.welcome-title")}
                signInTitle={t("login.sign-in-title")}
                address={true}
                signupClicked={(val) => setSignup(val)}
              />
            )
          ) : (
            <div className="spinner-style"></div>
          )}
          {data && (
            <div className="">
              <ShippingFooter />
            </div>
          )}
        </div>
      ) : (
        <Redirect
          push
          to={"/" + props.countryUrl + "-" + props.language + "/cart"}
        />
      )}
    </Fragment>
  );
};
const mapStateToProps = (state) => {
  return {
    auth: state.auth.isSignedIn,
    countryId: state.language.countryId,
    cart_items: state.cart.cart_items,
    language: state.language.language,
    countryUrl: state.language.countryUrl,
    flag: state.auth.guestUser,
  };
};

const mapDispatchToProps = { set_addresses, set_user, set_loading };

export default connect(mapStateToProps, mapDispatchToProps)(SelectShipping);
