import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { apiJson } from "../../Api";
import "../../assets/styles/pages/ShippingPolicyPage/ShippingPolicyPage.scss";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { set_loading } from "../../redux/actions/LayoutAction";
import WebsiteHeader from "../../components/header/WebsiteHeader";
import WebsiteFooter from "../../components/Footer/WebsiteFooter";
import pagesbannerimg from "../../assets/images/footer-pages-banners/Shipping-&-Delivery.jpg";

function ShippingPolicyPage(props) {
  const { t } = useTranslation();
  const [data, setData] = useState("<p></p>");
  let message = "";
  const showError = () => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  useEffect(() => {
    window.document.title = "Shipping Policy";

    window.scrollTo(0, 0);
    props.set_loading(true);
    apiJson.platformData.platformData().then((response) => {
      if (response.success) {
        setData(response.data);
        props.set_loading(false);
      } else if (!response.status) {
        // eslint-disable-next-line
        message = response.message
          ? response.message !== ""
            ? response.message
            : "something went wrong"
          : "something went wrong";
        showError();
      } else {
        if (response.status >= 400 && response.status < 500) {
          message = response.message
            ? response.message
            : response.errors
            ? response.errors
            : "something went wrong";
          showError();
        } else {
          message = response.message
            ? response.message !== ""
              ? response.message
              : "something went wrong"
            : "something went wrong";
          showError();
        }
      }
    });
  }, []);
  return (
    <div className="shipping-page-main">
      <WebsiteHeader />
      <div className="pagesbanner">
        <img
          src={pagesbannerimg}
          alt="category"
          style={{
            width: "100%",
            height: "100%",
            maxHeight: "400px",
          }}
        ></img>
      </div>
      <div className="heading w-100">{t("shipping-policy.heading")}</div>

      <div
        className={`${
          props.language === "ar" ? "text-right" : "text-left"
        } mb-1 body w-95 ml-auto mr-auto`}
        dangerouslySetInnerHTML={{
          __html:
            props.language === "en"
              ? data[`shipping_policy`]
              : data[`shipping_policy_${props.language}`],
        }}
      ></div>
      <WebsiteFooter />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    language: state.language.language,
    loading: state.layout.loading,
  };
};
const mapDispatchToProps = { set_loading };

export default connect(mapStateToProps, mapDispatchToProps)(ShippingPolicyPage);
