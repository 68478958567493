export const CREDITS = [
  {
    id: 1,
    date: '28 May 2021',
    type: 'Refund(Credit Card)',
    details: {
      description: 'Protective Case Cover For Samsung Galaxy A32',
      variation: '6.5 Inch Black',
      sold_by: '1 unit sold by Intag'
    },
    amount: 100,
    balance: 50
  },
  {
    id: 2,
    date: '10 June 2020',
    type: 'Refund(Credit Card)',
    details: {
      description: 'Protective Case Cover For Samsung Galaxy A32',
      variation: '6.5 Inch Black',
      sold_by: '1 unit sold by Intag'
    },
    amount: 300,
    balance: 500
  },
  {
    id: 3,
    date: '15 July 2021',
    type: 'Returned Item',
    details: {
      description: 'Protective Case Cover For Samsung Galaxy A32',
      variation: '6.5 Inch Black',
      sold_by: '1 unit sold by Intag'
    },
    amount: 250,
    balance: 80
  }
]

export const TRANSFERS = [
  {
    id: 1,
    date: '11 January 2019',
    type: 'Refund(Credit Card)',
    details: {
      description: 'Protective Case Cover For Samsung Galaxy A32',
      variation: '6.5 Inch Black',
      sold_by: '1 unit sold by Intag'
    },
    amount: 100,
    balance: 50
  },
  {
    id: 2,
    date: '20 October 2019',
    type: 'Refund(Credit Card)',
    details: {
      description: 'Protective Case Cover For Samsung Galaxy A32',
      variation: '6.5 Inch Black',
      sold_by: '1 unit sold by Intag'
    },
    amount: 300,
    balance: 500
  },
  {
    id: 3,
    date: '15 July 2021',
    type: 'Returned Item',
    details: {
      description: 'Protective Case Cover For Samsung Galaxy A32',
      variation: '6.5 Inch Black',
      sold_by: '1 unit sold by Intag'
    },
    amount: 250,
    balance: 80
  },
  {
    id: 4,
    date: '18 September 2021',
    type: 'Returned Item',
    details: {
      description: 'Protective Case Cover For Samsung Galaxy A32',
      variation: '6.5 Inch Black',
      sold_by: '1 unit sold by Intag'
    },
    amount: 250,
    balance: 80
  }
]