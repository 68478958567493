import React from "react";
import { Row } from "reactstrap";

import "../../../assets/styles/mobile/shared/productdetailcard.scss";
import { useTranslation } from "react-i18next";

const ProductDetailCard = (props) => {
  const { name, brand, price, quantity, image_path, currency, sku } = props;
  const { t } = useTranslation();

  return (
    <div className="product-detail-card px-3 pt-4">
      <div className="brand">{brand}</div>
      <Row noGutters={true} className="wrapper justify-content-between">
        <div className="left">
          {name && <div className="title pt-2">{name}</div>}
          {sku && <div className="title mt-1">{sku}</div>}
          {price && (
            <div className="price mt-2">
              {currency?.toUpperCase()} {parseFloat(price).toFixed(2)}
            </div>
          )}
          {quantity && (
            <div className="quantity mt-2">
              {t("dispute-detail-page-mob.qty")} {quantity}
            </div>
          )}
          {brand && (
            <div className="store-name mt-2">
              {t("dispute-detail-page-mob.sold-by")}{" "}
              <span className="sold-by">{brand}</span>
            </div>
          )}
        </div>
        <div className="right">
          <div className="image-container d-flex justify-content-center mt-4">
            <img
              src={image_path}
              alt="itemimage"
              width="auto"
              height="auto"
              style={{ maxHeight: "80px", maxWidth: "80px" }}
            />
          </div>
        </div>
      </Row>
    </div>
  );
};

export default ProductDetailCard;
