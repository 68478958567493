import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useTranslation } from "react-i18next";
import "../../../../assets/styles/customer/account/orders/ordersummary.scss";
import "../../../../assets/styles/utils.scss";

const OrderSummary = (props) => {
  const { t } = useTranslation();
  return (
    <div className="order-summary custom-card-2 no-gutters mt-3">
      <div className="vertical-separator p-3">
        <Row>
          <Col md={12} className="title fw-700">
            {t("orderDetails.os-title")}
          </Col>
        </Row>
        <Row className="no-gutters subtitle">
          <Col className="large-screen-none d-flex flex-direction-row justify-content-between mt-2">
            <span>{t("orderDetails.os-sub-total")}</span>
            <span>
              {props.shipmentDetail.currency.toUpperCase() +
                " " +
                props.shipmentDetail.sub_total}
            </span>
          </Col>

          <Col className="py-1 small-screen-none d-flex flex-direction-row justify-content-between">
            <span>{t("orderDetails.os-sub-total")}</span>
            <span>
              {props.shipmentDetail.currency.toUpperCase() +
                " " +
                props.shipmentDetail.sub_total}
            </span>
          </Col>
        </Row>

        <Row className="no-gutters subtitle">
          <Col className="large-screen-none d-flex flex-direction-row justify-content-between mt-2">
            <span>{t("orderDetails.os-shipping-fee")}</span>
            <span className="shipping-fee fw-600">
              {props.shipmentDetail.currency.toUpperCase() +
                " " +
                props.shipmentDetail.delivery_fee}
            </span>
          </Col>

          <Col className="pt-1 small-screen-none d-flex flex-direction-row justify-content-between">
            <span>{t("orderDetails.os-shipping-fee")}</span>
            <span className="shipping-fee fw-600">
              {props.shipmentDetail.currency.toUpperCase() +
                " " +
                props.shipmentDetail.delivery_fee}
            </span>
          </Col>
        </Row>

        {props.shipmentDetail?.coupon_code && (
          <Row className="no-gutters subtitle">
            <Col className="large-screen-none d-flex flex-direction-row justify-content-between mt-2">
              <span>{t("orderDetails.os-coupon-code")}</span>
              <span
                className="shipping-fee fw-600"
                style={{ color: "#404553" }}
              >
                {props.shipmentDetail?.coupon_code}
              </span>
            </Col>

            <Col className="pt-1 small-screen-none d-flex flex-direction-row justify-content-between">
              <span>{t("orderDetails.os-coupon-code")}</span>
              <span
                className="shipping-fee fw-600"
                style={{ color: "#404553" }}
              >
                {props.shipmentDetail?.coupon_code}
              </span>
            </Col>
          </Row>
        )}

        {props.shipmentDetail?.discount !== "0.0" && (
          <Row className="no-gutters subtitle">
            <Col className="large-screen-none d-flex flex-direction-row justify-content-between mt-2">
              <span>{t("orderDetails.os-discount")}</span>
              <span
                className="shipping-fee fw-600"
                style={{ color: "#404553" }}
              >
                {props.shipmentDetail.currency.toUpperCase() +
                  " " +
                  props.shipmentDetail?.discount}
              </span>
            </Col>
            <Col className="pt-1 small-screen-none d-flex flex-direction-row justify-content-between ">
              <span>{t("orderDetails.os-discount")}</span>
              <span
                className="shipping-fee fw-600"
                style={{ color: "#404553" }}
              >
                {props.shipmentDetail.currency.toUpperCase() +
                  " " +
                  props.shipmentDetail?.discount}
              </span>
            </Col>
          </Row>
        )}
      </div>

      <div className="vertical-separator px-3 pt-2">
        <Row className="title no-gutters">
          <Col className="large-screen-none d-flex flex-direction-row justify-content-between mt-2">
            <span className="order-total fw-700">
              {t("orderDetails.os-order-total")}
              <span
                className="gray-text fw-400"
                style={{ marginInlineStart: "5px" }}
              >
                ({t("orderDetails.os-inclusive-of-vat")})
              </span>
            </span>
            <span
              className="order-total-price fw-700"
              style={{ textAlign: "end" }}
            >
              {props.shipmentDetail.currency.toUpperCase() +
                " " +
                props.shipmentDetail.grand_total}
            </span>
          </Col>

          <Col className="small-screen-none d-flex flex-direction-row justify-content-between mt-2">
            <span className="order-total fw-700">
              {t("orderDetails.os-order-total")}
              <span
                className="gray-text fw-400"
                style={{ marginInlineStart: "5px" }}
              >
                ({t("orderDetails.os-inclusive-of-vat")})
              </span>
            </span>
            <span
              className="order-total-price fw-700"
              style={{ textAlign: "end" }}
            >
              {props.shipmentDetail.currency.toUpperCase() +
                " " +
                props.shipmentDetail.grand_total}
            </span>
          </Col>
        </Row>
        <Row className="subtitle no-gutters gray-text py-2">
          <Col className="large-screen-none d-flex flex-direction-row justify-content-between mt-2">
            <span>{t("orderDetails.os-est-vat")}</span>
            <span style={{ textAlign: "end" }}>
              {props.shipmentDetail.currency.toUpperCase() +
                " " +
                props.shipmentDetail.vat}
            </span>
          </Col>

          <Col className="small-screen-none d-flex flex-direction-row justify-content-between mt-2">
            <span>{t("orderDetails.os-est-vat")}</span>
            <span style={{ textAlign: "end" }}>
              {props.shipmentDetail.currency.toUpperCase() +
                " " +
                props.shipmentDetail.vat}
            </span>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default OrderSummary;
