import React, { useState } from "react";
import { useEffect } from "react";

import SiteHeader from "../../../components/header/WebsiteHeader";
import SiteFooter from "../../../components/Footer/WebsiteFooter";
import CategoryMain from "../../../components/customer/AllCategories/CategoryMain";

import CategorySidebar from "../../../components/customer/AllCategories/CategorySidebar";
import "../../../assets/styles/pages/customer/AllItems/ItemsPage.scss";
import { set_all_categories } from "../../../redux/actions/AllCategoriesAction";
import { connect } from "react-redux";
import { apiJson } from "../../../Api";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useLocation, useHistory} from "react-router-dom";
import { set_loading } from "../../../redux/actions/LayoutAction";
import { useTranslation } from "react-i18next";
import { replaceAt } from "../../../utils/utils";




function ItemsPage(props) {
  const location = useLocation();
  const { t } = useTranslation();

  const history = useHistory();
  const filters = location.search.length
    ? decodeURI("&" + location.search.substring(1, location.search.length))
    : undefined;
  const tab = location.search.includes("tab=")
    ? new URLSearchParams(location.search).get("tab") === "p"
      ? "products"
      : "services"
    : "products";
  const [data, setData] = useState(false);
  let message = "";

  const showError = () => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  useEffect(() => {
    document.title = "Categories";
  }, []);
  useEffect(() => {
    window.scroll(0, 0);
    props.set_loading(true);

    let pageFilter = 1;
    let recordsFilter = 30;
    let high_to_low = false;
    let low_to_high = false;
    let new_arrivals = false;
    let categoryFilters = "";
    let display_en = ["30 per page", "50 per page", "80 per page"];
    let display_ar = [
      "ثمانون لكل صفحة",
      "خمسون لكل صفحة",
      "ثلاثون لكل صفحة",
    ].reverse();
    let display_val = "";
    let sortBy_en = [
      "Recommended",
      "Price: High to low",
      "Price: Low to high",
      "New Arrivals",
    ];
    let sortBy_ar = [
      "موصى به",
      "السعر الاعلى الى الادنى",
      "السعر من الارخص للاعلى",
      "الوافدون الجدد",
    ];
    let sortVal = "";

    if (filters) {
      categoryFilters = filters
        ?.split("&filter_by_category[]=")
        ?.slice(1, filters?.split("&filter_by_category[]=").length)
        ?.map((category) =>
          category.includes("&")
            ? parseInt(category.split("&")[0])
            : parseInt(category)
        );

      if (filters.includes("&page=")) {
        pageFilter = filters?.split("&page=")[1];
        pageFilter = pageFilter.includes("&")
          ? parseInt(pageFilter.split("&")[0])
          : parseInt(pageFilter);
      }

      if (filters.includes("&records_per_page")) {
        recordsFilter = filters?.split("&records_per_page=")[1];
        recordsFilter = recordsFilter.includes("&")
          ? parseInt(recordsFilter.split("&")[0])
          : parseInt(recordsFilter);
      }

      if (filters.includes("&price_high_to_low=")) {
        high_to_low = true;
      }

      if (filters.includes("&price_low_to_high=")) {
        low_to_high = true;
      }

      if (filters.includes("order_by_new_arrivals=")) {
        new_arrivals = true;
      }
    }

    if (location.state?.title) {
      let stateCategories = Array.isArray(location.state.title)
        ? Array.from(new Set([...location?.state?.title, ...categoryFilters]))
        : Array.from(
            new Set([parseInt(location?.state?.title), ...categoryFilters])
          );
      let filtersCatArr = "";
      stateCategories.length > 0 &&
        stateCategories.map(
          (cat) =>
            (filtersCatArr = filtersCatArr + "&filter_by_category[]=" + cat)
        );
      if (filtersCatArr.length > 0) {
        filtersCatArr =
          (parseInt(pageFilter) !== 1 ? "&page=" + pageFilter : "") +
          (parseInt(recordsFilter) !== 30
            ? "&records_per_page=" + recordsFilter
            : "") +
          (high_to_low ? "&price_high_to_low=true" : "") +
          (low_to_high ? "&price_low_to_high=true" : "") +
          (new_arrivals ? "&order_by_new_arrivals=" + new_arrivals : "") +
          filtersCatArr +
          ("&tab=" + tab[0]);

        filtersCatArr = encodeURI(filtersCatArr);
        history.push(
          location.state
            ? {
                pathname: window.location.pathname,
                search: replaceAt(filtersCatArr, 0, "?"),
                state: location.state,
              }
            : {
                pathname: window.location.pathname,
                search: replaceAt(filtersCatArr, 0, "?"),
              }
        );
      }
    }

    let index = recordsFilter === 80 ? 2 : recordsFilter === 50 ? 1 : 0;
    display_val =
      props.language === "en"
        ? { value: display_en[index], label: display_en[index] }
        : { value: display_ar[index], label: display_ar[index] };

    index = high_to_low ? 1 : low_to_high ? 2 : new_arrivals ? 3 : 0;
    sortVal =
      props.language === "en"
        ? { value: sortBy_en[index], label: sortBy_en[index] }
        : { value: sortBy_ar[index], label: sortBy_ar[index] };

    apiJson.allitems.getCategories(props.countryId).then((response) => {
      if (response.success) {
        props.set_all_categories({
          category: response.data,
        });
      } else if (!response.status) {
        // eslint-disable-next-line
        message = response.message
          ? response.message !== ""
            ? response.message
            : t("errors.wrong")
          : t("errors.wrong");
        showError();
      } else {
        if (response.status >= 400 && response.status < 500) {
          message = response.message
            ? response.message
            : response.errors
            ? response.errors
            : t("errors.wrong");
          showError();
        } else {
          message = response.message
            ? response.message !== ""
              ? response.message
              : t("errors.wrong")
            : t("errors.wrong");
          showError();
        }
      }
    });

    apiJson.categories
      .getItems(
        props.countryId,
        tab,
        pageFilter,
        recordsFilter,
        high_to_low,
        low_to_high,
        new_arrivals,
        location?.state?.title
          ? Array.isArray(location.state.title)
            ? Array.from(
                new Set([...location?.state?.title, ...categoryFilters])
              )
            : Array.from(
                new Set([parseInt(location?.state?.title), ...categoryFilters])
              )
          : categoryFilters
      )
      .then((response) => {
        if (response.success) {
          setData(true);
          // eslint-disable-next-line
          props.set_all_categories({
            items: response.data,
            display_en: display_en,
            display_ar: display_ar,
            page: pageFilter,
            sortBy_en: sortBy_en,
            sortBy_ar: sortBy_ar,
            displayVal: display_val,
            sortVal: sortVal,
            recordPerPage: recordsFilter,
            price_high_to_low: high_to_low,
            price_low_to_high: low_to_high,
            new_arrivals: new_arrivals,

            selectedCat: location.state?.title
              ? Array.isArray(location.state.title)
                ? Array.from(
                    new Set([...categoryFilters, ...location.state.title])
                  )
                : Array.from(
                    new Set([...categoryFilters, location.state.title])
                  )
              : categoryFilters,
            activeTab: tab,
          });

          props.set_loading(false);
        } else if (!response.status) {
          props.set_loading(false);
          setData(true);

          // eslint-disable-next-line
          message = response.message
            ? response.message !== ""
              ? response.message
              : t("errors.wrong")
            : t("errors.wrong");
          showError();
        } else {
          props.set_loading(false);
          setData(true);
          if (response.status >= 400 && response.status < 500) {
            message = response.message
              ? response.message
              : response.errors
              ? response.errors
              : t("errors.wrong");
            showError();
          } else {
            message = response.message
              ? response.message !== ""
                ? response.message
                : t("errors.wrong")
              : t("errors.wrong");
            showError();
          }
        }
      });
  }, [location.state?.title]);
  return (
    <div className="items-page-mobile items-page-desktop">
      <div className="header-mobile">
        <SiteHeader />
      </div>
 

      {data && (
        <div className="all-page-container m-auto  d-flex flex-row">
          <div className=" sidebar-desktop">
            <CategorySidebar />
          </div>
          <div className="items-desktop">
            <CategoryMain data={data} />
          </div>
        </div>
      )}
      {data && (
        <div className="footer-mobile">
          <SiteFooter />
        </div>
      )}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    countryId: state.language.countryId,
    width: state.layout.screenWidth,
    language: state.language.language,
  };
};

const mapDispatchToProps = {
  set_all_categories,
  set_loading,
};

export default connect(mapStateToProps, mapDispatchToProps)(ItemsPage);
