import React, { Fragment, useEffect } from "react";
import { Link } from 'react-router-dom';
import { Modal, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.css";
import "../assets/styles/pages/homepagedesktop.scss";
import { set_home_categories } from "../redux/actions/HomeAction";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { set_country, set_loading } from "../redux/actions/LayoutAction";
import { set_cart_items } from "../redux/actions/CartAction";
import { set_redirect_home } from "../redux/actions/NotFoundAction";
import { set_user } from "../redux/actions/AuthAction";
import OffersBanner from "../assets/images/offers-banner.png";
import WebsiteHeaderDesktop from "../components/header/WebsiteHeaderDesktop";
import WebsiteFooterDesktop from "../components/Footer/WebsiteFooterDesktop";

const HomePageDesktop = (props) => {
    return (
        <Fragment>
            <div className="home-page-desktop mx-auto">
                <WebsiteHeaderDesktop />

                <WebsiteFooterDesktop />
            </div>
        </Fragment>
    );
};

function OffersModal(props) {
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="offers-modal"
        >
            <Modal.Body>
                <Link to="/uae-en/electrical-accessories-sale">
                    <div className="text-center">
                        <img src={OffersBanner} className="img img-fluid" style={{ maxHeight: "500px", maxWidth: "500px", margin: "auto" }} />
                    </div>
                </Link>
            </Modal.Body>
        </Modal>
    )
}

const mapStateToProps = (state) => {
    return {
        banners: state.home.secondaryBanners,
        countryId: state.language.countryId,
        country: state.language.country,
        recommendedForYou: state.home.recommendedForYou,
        diyProducts: state.home.diyProducts,
        paintersTouch: state.home.paintersTouch,
        krudKutter: state.home.krudKutter,
        sections: state.home.sections,
        positionOne: state.home.positionOne,
        positionTwo: state.home.positionTwo,
        positionThree: state.home.positionThree,
        positionFour: state.home.positionFour,
        positionFive: state.home.positionFive,
        language: state.language.language,
        countryUrl: state.language.countryUrl,
        redirect: state.notFound.redirect,

        loading: state.layout.loading,
    };
};

const mapDispatchToProps = {
    set_home_categories,
    set_country,
    set_cart_items,
    set_redirect_home,
    set_loading,
    set_user,
};

export default connect(mapStateToProps, mapDispatchToProps)(HomePageDesktop);
