import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import AllCategories from "../../components/mobile/AllCategories/AllCategories";
import MobileLayoutRenderer from "../../pages/mobile/layout/MobileLayoutRenderer";
import dsspic from "../../assets/images/individual-pages/dss/saleb6.png";

export default function AllCategoriesPage() {
  useEffect(() => (document.title = "Categories"), []);
  return (
    <MobileLayoutRenderer
      NavbarTop={false}
      BackBtn={false}
      CancelBtn={false}
      CloseIcon={false}
      NavbarBottom={true}
    >
    <Link to="/uae-en/dss" style={{ display: "block" }}>
      <img style={{ width: "100%", height: "auto" }} width="585" height="80" className="dssstrip" src={dsspic}></img>
    </Link>
      <div style={{ maxWidth: "767px" }} className="bg-white pt-4">
        <div>
          <AllCategories />
        </div>
      </div>
    </MobileLayoutRenderer>
  );
}
