import React, { Fragment, useEffect, useState } from "react";
import { Row } from "react-bootstrap";
import WebsiteHeader from "../../../components/header/WebsiteHeader";
import Sidebar from "../../../components/customer/account/Sidebar";
import Returns from "../../../components/customer/account/Returns";
import ReturnsMobile from "../../mobile/customer/returns/ReturnsMobile";
import { set_return_items } from "../../../redux/actions/ReturnAction";
import { apiJson } from "../../../Api";
import { connect } from "react-redux";
import MobileLayoutRenderer from "../../mobile/layout/MobileLayoutRenderer";
import { toast } from "react-toastify";
import { set_loading } from "../../../redux/actions/LayoutAction";
import "../../../assets/styles/customer/account/general.scss";
import "../../../assets/styles/pages/walletpage.scss";
import "react-toastify/dist/ReactToastify.css";
import { useTranslation } from "react-i18next";
import HeaderMobile from "../../../components/mobile/HeaderMobile";

const ReturnsPage = (props) => {
  const { t } = useTranslation();

  const [data, setData] = useState(false);
  let message = "";

  const showError = () => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  useEffect(() => {
    document.title = "Returns";
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
    props.set_loading(true);

    apiJson.returnItems.getReturnedItems().then((response) => {
      props.set_loading(false);
      if (response.success) {
        props.set_return_items({ returned_items: response.data });
        setData(true);
      } else if (!response.status) {
        setData(true);
        // eslint-disable-next-line
        message = response.message
          ? response.message !== ""
            ? response.message
            : t("errors.wrong")
          : t("errors.wrong");
        showError();
      } else {
        setData(true);
        if (response.status >= 400 && response.status < 500) {
          message = response.message
            ? response.message
            : response.errors
              ? response.errors
              : t("errors.wrong");
          showError();
        } else {
          message = response.message
            ? response.message !== ""
              ? response.message
              : t("errors.wrong")
            : t("errors.wrong");
          showError();
        }
      }
    });
  }, []);

  return (
    <>
      {" "}
      {props.width === "sm" ? (
        <MobileLayoutRenderer
          NavbarTop={false}
          BackBtn={true}
          CancelBtn={false}
          NavbarBottom={true}
          SearchIcon={true}
        >
          {data ? (
            <>
              <div className="home-mob">
                <HeaderMobile />
              </div>
              <ReturnsMobile
                requestedItems={props.items.filter(
                  (item) =>
                    !(item.status === "approved" || item.status === "completed")
                )}
                approvedItems={props.items.filter(
                  (item) =>
                    item.status === "approved" || item.status === "completed"
                )}
              />
            </>
          ) : (
            <></>
          )}
        </MobileLayoutRenderer>
      ) : (
        <Fragment>
          <Row className="page-container no-gutters g-0">
            <div className="w-100">
              <WebsiteHeader />

              <div className="content-container all-page-container d-flex justify-content-center mx-auto">
                <div className="left-pane">
                  <Sidebar />
                </div>
                <div className="right-pane">
                  {data ? (
                    <Returns items={props.items} />
                  ) : (
                    <div className="d-flex justify-content-center align-items-center w-100 h-100"></div>
                  )}
                </div>
              </div>
            </div>
          </Row>
        </Fragment>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    country: state.language.country,
    width: state.layout.screenWidth,
    direction: state.direction,
    items: state.returns.returned_items,
    load: state.layout.loading,
  };
};

const mapDispatchToProps = {
  set_return_items,
  set_loading,
};

export default connect(mapStateToProps, mapDispatchToProps)(ReturnsPage);
