import React, { Fragment, useEffect } from "react";
import WebsiteFooter from "../../components/Footer/WebsiteFooter";

import bulkorderimg from "../../assets/images/footer-page-images/a.png";
import pagesbannerimg from "../../assets/images/footer-pages-banners/Bulk-Enquiries.jpg";
import "../../assets/styles/footer/footerpagescss.scss";

import WebsiteHeader from "../../components/header/WebsiteHeader";
import $ from "jquery";

const BulkOrder = () => {
  useEffect(() => {
    window.document.title = "Bulk Order";

    $("html, body").animate({ scrollTop: 0 }, "fast");
  }, []);
  return (
    <Fragment>
      <div className="product-home-page-desktop mx-auto">
        <WebsiteHeader />
        <div className="pagesbanner">
          <img
            src={pagesbannerimg}
            alt="category"
            style={{
              width: "100%",
              height: "100%",
              maxHeight: "400px",
            }}
          ></img>
        </div>
        <div className="content-wrapper all-page-container">
          <div className="footer-pages-content">
            <h4 className="footer-page-heading">Bulk order for BIG SAVINGS </h4>

            <p>
              A bulk purchase can be a great way to save money. With our range
              of products, exclusive prices, personalized service, and flexible
              delivery options, we offer a wide range of options. Our products
              are meant to be used in a variety of ways such as Outdoor
              Lifestyle, Gardening, Homeware, Paint & Décor, DIY & Tools,
              Automotive, Construction & more. To know more, get in touch with
              us at sales@alisuq.com{" "}
            </p>

            <h5 className="footer-page-subheading blko">
              A place to find bulk solutions
            </h5>
            <span class="line">
              <h2 className="footer-page-subheading">CATEGORIES</h2>
            </span>

            <div className="bulkcat">
            <a href="/uae-en/all-categories"> 
              <img
                className="bulkcatimg" style={{width:"166px",backgroundColor:"#d4982b"}}
                src={"https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images//5ttA1NJB99zbYkBbZ5uSqr.png"}
                alt="category"
              ></img>
              </a>
              <a href="/uae-en/all-categories"> 
              <img
                className="bulkcatimg" style={{width:"166px",backgroundColor:"#d4982b"}}
                src={"https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images//nSSqhofNxJScJqfaN4xfzz.png"}
                alt="category"
              ></img>
              </a>
              <a href="/uae-en/all-categories"> 
              <img
               className="bulkcatimg" style={{width:"166px",backgroundColor:"#d4982b"}}
                src={"https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images//2hVy2Z5bNrUtLp5zRB8SmW.png"}
                alt="category"
              ></img>
              </a>
              <a href="/uae-en/all-categories"> 
              <img
                className="bulkcatimg" style={{width:"166px",backgroundColor:"#d4982b"}}
                src={"https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images//azowRVeftZcEiDCAYkYVrp.png"}
                alt="category"
              ></img>
              </a>
            </div>
          </div>
        </div>
        <div className="w-100">
          <WebsiteFooter />
        </div>
      </div>
    </Fragment>
  );
};

export default BulkOrder;
