import React, { Fragment, useState } from "react";
import { Row } from "reactstrap";
import { FiArrowUpLeft } from "react-icons/fi";
import { IoCloseOutline } from "react-icons/io5";
import { useTranslation } from "react-i18next";
import "../../assets/styles/header/searchpopovermobile.scss";
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import ClipLoader from "react-spinners/ClipLoader";
import { getMobileImage } from "../../utils/utils";

const SearchPopover = (props) => {
  const [redirectToItems, setRedirectToItems] = useState({ value: false });
  const { t } = useTranslation();
  return (
    <Fragment>
      <div className="search-popover-mobile">
        {props.searching ? (
          <div
            className="d-flex justify-content-center align-items-center w-100"
            style={{ height: "50px" }}
          >
            <ClipLoader color="#000" loading size={20} />
          </div>
        ) : (
          <Row className="search-popover-row">
            {props.recent && props.recentValue.length < 2 ? (
              <div className="recent-search">
                <div className="trending-searches">
                  <div className="trending-left-col">
                    {t("search.trending-searches")}
                  </div>
                  <div className="trending-tags recent-search-result-row align-items-center justify-content-between">
                    <span><Link to={{
                      pathname: "/" + props.countryUrl + "-" + props.language
                        + "/search/products?q=krud%20kutter"
                    }}>krud kutter</Link></span>
                    <span><Link to={{
                      pathname: "/" + props.countryUrl + "-" + props.language
                        + "/search/products?q=carpet%20cleaner"
                    }}>Carpet Cleaner</Link></span>
                    <span><Link to={{
                      pathname: "/" + props.countryUrl + "-" + props.language
                        + "/search/products?q=paint"
                    }}>Paint</Link></span>
                    <span><Link to={{
                      pathname: "/" + props.countryUrl + "-" + props.language
                        + "/search/products?q=Silicon"
                    }}>Silicon</Link></span>
                    <span><Link to={{
                      pathname: "/" + props.countryUrl + "-" + props.language
                        + "/search/products?q=Dap%20All%20purpose"
                    }}>Dap All purpose</Link></span>
                    <span><Link to={{
                      pathname: "/" + props.countryUrl + "-" + props.language
                        + "/search/products?q=Cleaning"
                    }}>Cleaning</Link></span>
                    <span><Link to={{
                      pathname: "/" + props.countryUrl + "-" + props.language
                        + "/search/products?q=Tools"
                    }}>Tools</Link></span>
                  </div>
                </div>
                {props.recent.length > 0 && (
                  <div className="recent-search-row d-flex align-items-center justify-content-between">
                    <div className="recent-left-col">
                      {t("search.recent-searches")}
                    </div>
                    <div
                      onClick={props.clearHistory}
                      className="pointer recent-right-col"
                    >
                      {t("search.clear-all")}
                    </div>
                  </div>
                )}
                {props.recent.map((recent) => {
                  return (
                    <div
                      key={recent.id}
                      className="recent-search-result-row d-flex align-items-center justify-content-between"
                    >
                      <div className="recent-result-col d-flex w-100">
                        <div
                          onClick={() => props.deleteRecent(recent.id)}
                          className="pointer icon"
                        >
                          <IoCloseOutline />
                        </div>
                        <div onClick={() => props.setRecentValue(recent.description)} className="text w-100 pointer">{recent.description}</div>
                      </div>
                      <div
                        onClick={() => props.setRecentValue(recent.description)}
                        className="pointer icon-col"
                      >
                        <FiArrowUpLeft
                          style={{
                            transform:
                              props.language?.toLowerCase() === "en"
                                ? "unset"
                                : `rotate(90deg)`,
                          }}
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : (
              <div className="search-col d-flex">
                <div className="search-result-col pl-2">
                  <ul style={{ textAlign: "initial" }}>
                    {props.products.map((product) => (
                      <li key={product.id}>
                        <Link
                          to={{
                            pathname:
                              "/" +
                              props.countryUrl +
                              "-" +
                              props.language +
                              `/${product.category_title_en
                                .toLowerCase()
                                .replace(/ /g, "-")}/${product.slug}`,
                            state: { id: product.id, type: "products" },
                            search: "?sku=" + product.variants[0].sku
                          }}
                          className="d-flex align-items-center"
                        >
                          <span
                            style={{
                              width: "35px",
                              height: "35px",
                              minWidth: "35px",
                              minHeight: "35px",
                            }}
                            className="d-inline-flex justify-content-center"
                          >
                            <img
                              src={getMobileImage(
                                product.variants[0].featured_image_resized,
                                product.variants[0].featured_image
                              )}
                              style={{
                                maxWidth: "35px",
                                maxHeight: "35px",
                              }}
                              height="auto"
                              width="auto"
                              alt=""
                            />
                          </span>
                          <span
                            style={{
                              paddingInlineStart: "4px",
                              textAlign: "initial",
                              width: "calc(100% - 35px)",
                              maxWidth: "calc(100% - 35px)",
                              overflow: "hidden",
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {product.name_en}
                          </span>
                        </Link>
                      </li>
                    ))}
                    {props.services.map((ele) => (
                      <li key={ele.id}>
                        <Link
                          to={{
                            pathname:
                              "/" +
                              props.countryUrl +
                              "-" +
                              props.language +
                              `/${ele.category_title_en
                                .toLowerCase()
                                .replace(/ /g, "-")}/${ele.slug}`,
                            state: { id: ele.id, type: "services" },
                            search: "?sku=" + ele.sku
                          }}
                        >
                          {" "}
                          <img
                            src={getMobileImage(
                              ele.featured_image_resized,
                              ele.featured_image
                            )}
                            style={{
                              maxWidth: "35px",
                              maxHeight: "35px",
                            }}
                            height="auto"
                            width="auto"
                            alt=""
                          />
                          <span className=" pl-2">
                            {ele[`name_${props.language}`]}
                          </span>
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="search-right-col">
                  <div className="wrapper">
                    <h1 className="header text-uppercase pb-1">
                      {t("searchDropdown.matchingCategories")}
                    </h1>
                    <div className="category-name">
                      {props.categories.map((ele) => (
                        <div
                          onClick={() => {
                            setRedirectToItems({
                              value: true,
                              state: {
                                category:
                                  ele.sub_categories?.length > 0
                                    ? [
                                      ele.id,
                                      ...ele.sub_categories.map(
                                        (subCat) => subCat.id
                                      ),
                                    ]
                                    : [ele.id],
                              },
                            });
                            const timer = setTimeout(() => {
                              setRedirectToItems({ value: false });
                            }, 1000);

                            clearTimeout(timer);
                          }}
                          key={ele.id}
                          className="pointer category-search-item"
                        >
                          {ele[`title_${props.language}`]}
                        </div>
                      ))}
                    </div>
                    <h1 className="header text-uppercase pb-1">
                      {t("searchDropdown.matchingBrands")}
                    </h1>
                    <div className="brand-name">
                      {props.brands.map((ele) => (
                        <div
                          onClick={() => {
                            setRedirectToItems({
                              value: true,
                              state: { brand: ele.id },
                            });
                            const timer = setTimeout(() => {
                              setRedirectToItems({ value: false });
                            }, 1000);

                            clearTimeout(timer);
                          }}
                          className="pointer brand-search-item"
                          key={ele.id}
                        >
                          {ele[`name_${props.language}`]}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            )}
            {props.loader && (
              <div className="d-flex justify-content-center align-items-center w-100 pb-4">
                <ClipLoader color="#000" loading size={30} />
              </div>
            )}
          </Row>
        )}
        {redirectToItems.value && (
          <Redirect
            push
            to={{
              pathname:
                "/" +
                props.countryUrl +
                "-" +
                props.language +
                "/items/products",

              state: redirectToItems.state,
            }}
          />
        )}
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    products: state.header.searchProducts,
    services: state.header.searchServices,
    categories: state.header.searchCat,
    brands: state.header.searchBrands,
    language: state.language.language,
    countryUrl: state.language.countryUrl,
  };
};

export default connect(mapStateToProps, null)(SearchPopover);
