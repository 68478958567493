import React from "react";
import { Fragment } from "react";
import { BiArrowBack } from "react-icons/bi";
import { Link } from "react-router-dom";
import "../../../assets/styles/customer/shippingheader/header.scss";
import Logo from "../../../assets/images/logo.png";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { set_user } from "../../../redux/actions/AuthAction";

const Header = (props) => {
  const history = useHistory();

  const { screen_name } = props;
  const { t } = useTranslation();

  return (
    <Fragment>
      <div className="checkout-header-desktop">
        <div className="row checkout-row">
          <div className="checkout d-flex align-items-center mx-auto">
            {screen_name !== "ORDER" && (
              <div
                className="back-col d-flex align-items-center"
                onClick={() => {
                  history.goBack();
                }}
              >
                <BiArrowBack
                  style={{
                    transform:
                      props.language?.toLowerCase() === "en"
                        ? "unset"
                        : `rotate(180deg)`,
                  }}
                />
                <div className="bold-text">
                  {t("address-form-header.secure-checkout")}
                </div>
              </div>
            )}
            <div className="checkout-col d-flex align-items-center justify-content-between px-0">
              <div className="assistance-col d-flex align-items-center">
                <div className="small-text">
                  {t("address-form-header.help")} 054-306-4845
                </div>
              </div>
              <Link
                className="logo-col"
                to={"/" + props.countryUrl + "-" + props.language + "/"}
              >
                <img src={Logo} alt="" width="100%" height="33px" />
              </Link>
            </div>
          </div>
        </div>

        {/* <div className="row shipping-row">
          <div className="shipping d-flex align-items-center justify-content-center mx-auto">
            {props.guestSignin && (
              <div className="signIn d-flex align-items-center">
                <div
                  className={`signIn-circle1 ${
                    screen_name === "SIGNIN" ||
                    screen_name === "ADDRESS" ||
                    screen_name === "PAYMENT" ||
                    screen_name === "ORDER"
                      ? "colored-if-active"
                      : ""
                  }`}
                >
                  1
                </div>
                {t("address-form-header.sign-in")}
              </div>
            )}
            <div className="address d-flex align-items-center">
              <div
                className={`address-circle2 ${
                  screen_name === "ADDRESS" ||
                  screen_name === "PAYMENT" ||
                  screen_name === "ORDER"
                    ? "colored-if-active"
                    : ""
                }`}
              >
                {props.guestSignin ? 2 : 1}
              </div>
              {t("address-form-header.ship-adrs")}
            </div>
            <div className="payment d-flex align-items-center">
              <div
                className={`payment-circle3 ${
                  screen_name === "PAYMENT" || screen_name === "ORDER"
                    ? "colored-if-active"
                    : ""
                }`}
              >
                {props.guestSignin ? 3 : 2}
              </div>
              {t("address-form-header.payment")}
            </div>
            <div className="order d-flex align-items-center">
              <div
                className={`order-circle4 ${
                  screen_name === "ORDER" ? "colored-if-active" : ""
                }`}
              >
                {props.guestSignin ? 4 : 3}
              </div>
              {t("address-form-header.order-placed")}
            </div>
          </div>
        </div> */}
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth.isSignedIn,
    flag: state.auth.guestUser,
    language: state.language.language,
    countryUrl: state.language.countryUrl,
    guestSignin: state.auth.guestSignin,
  };
};

const mapDispatchToProps = {
  set_user,
};
export default connect(mapStateToProps, mapDispatchToProps)(Header);
