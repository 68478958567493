import { type } from "../../types/types";

export default function reducer(
  state = { address: [], mapAddress: "" },
  action
) {
  switch (action.type) {
    case type.SET_SELECT_ADDRESS:
      return { ...state, ...action.address };
    default:
      return state;
  }
}
