import WebsiteFooter from "../../components/Footer/WebsiteFooter";
import WebsiteHeader from "../../components/header/WebsiteHeader";
import ProductList from "../../components/product/ProductList";
import Row from "react-bootstrap/Row";
import "../../assets/styles/pages/sales.scss";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { apiJson } from "../../Api";
import { useState } from "react";
import { connect } from "react-redux";
import PageBanner from "../../assets/images/november-banner.jpg";
import PaintsImage from "../../assets/images/Paints.jpg";
import AutomotiveImage from "../../assets/images/Automotive copy.jpg";
import HardwareImage from "../../assets/images/Hardware copy.jpg";
import BuildingAndConstructionImage from "../../assets/images/C&B.jpg";
import HomeEssentialImage from "../../assets/images/HomeEssential.jpg";
import CleanersImage from "../../assets/images/Cleaner.jpg";
import VacuumImage from "../../assets/images/Vacuum.jpg";
import BatteriesImage from "../../assets/images/Batteries.jpg";
import AppliancesImage from "../../assets/images/Appliances.jpg";
import DIYPaintsImage from "../../assets/images/DIYPaints.jpg";
import MiddleImage from "../../assets/images/middle-baner.gif";
import { useTranslation } from "react-i18next";

const NovemberSale = (props) => {
    const [title, setTitle] = useState("");
    const [products, setProducts] = useState([]);
    const { t } = useTranslation();

    useEffect(() => {
        apiJson
            .homepage.getSections(48, props.countryId)
            .then(response => {
                if (response.success) {
                    setTitle(response.data.title_en);
                    setProducts(response.data.products);
                }
            });
    }, []);

    return (
        <div>
            <WebsiteHeader />

            <div className="sale">
                <div className="row mx-0">
                    <div className="col-12">
                        <Link
                            to="#">
                            <img
                                src={PageBanner}
                                alt="category"
                                style={{
                                    width: "100%",

                                }} />
                        </Link>
                    </div>
                </div>

                <div className="row mx-0 my-2 cat-11">
                    <div className="col-lg-12">
                        <h3 className="font-weight-bold text-center">DEALS YOU CAN'T MISS</h3>
                    </div>
                    <div className="col-lg-3 my-1">
                        <Link
                            to="/uae-en/offers/section/paints-upto-60">
                            <img
                                src={PaintsImage}
                                alt="category"
                                style={{
                                    width: "100%",

                                }} />
                        </Link>
                    </div>
                    <div className="col-lg-3 my-1">
                        <Link
                            to="/uae-en/offers/section/automotive-upto-60">
                            <img
                                src={AutomotiveImage}
                                alt="category"
                                style={{
                                    width: "100%",

                                }} />
                        </Link>

                    </div>
                    <div className="col-lg-3 my-1">
                        <Link
                            to="/uae-en/offers/section/hardware-tools-upto-55">
                            <img
                                src={HardwareImage}
                                alt="category"
                                style={{
                                    width: "100%",

                                }} />
                        </Link>
                    </div>
                    <div className="col-lg-3 my-1">
                        <Link
                            to="/uae-en/offers/section/building-and-construction-upto-60">

                            <img
                                src={BuildingAndConstructionImage}
                                alt="category"
                                style={{
                                    width: "100%",

                                }} />
                        </Link>
                    </div>
                    <div className="col-lg-3 my-1">
                        <Link
                            to="/uae-en/offers/section/home-essentials-upto-55">

                            <img
                                src={HomeEssentialImage}
                                alt="category"
                                style={{
                                    width: "100%",

                                }} />
                        </Link>

                    </div>
                    <div className="col-lg-3 my-1">
                        <Link
                            to="/uae-en/offers/section/cleaners-upto-50">

                            <img
                                src={CleanersImage}
                                alt="category"
                                style={{
                                    width: "100%",

                                }} />
                        </Link>
                    </div>
                    <div className="col-lg-3 my-1">
                        <Link
                            to="/uae-en/offers/section/vacuum-cleaners-upto-55">

                            <img
                                src={VacuumImage}
                                alt="category"
                                style={{
                                    width: "100%",

                                }} />
                        </Link>
                    </div>
                    <div className="col-lg-3 my-1">
                        <Link
                            to="/uae-en/offers/section/batteries-upto-40">

                            <img
                                src={BatteriesImage}
                                alt="category"
                                style={{
                                    width: "100%",

                                }} />
                        </Link>
                    </div>
                </div>

                <div className="row mx-0 mb-3">
                    <div className="col-12">
                        <Link
                            to="#">
                            <img
                                src={MiddleImage}
                                alt="category"
                                style={{
                                    width: "100%"
                                }} />
                        </Link>
                    </div>
                </div>
                <Row
                    key={0}
                    className={`product-list-row mx-0`}
                >
                    <div className="title-row">
                        <div className="title"
                            dangerouslySetInnerHTML={{
                                __html: title,
                            }}
                        ></div>
                        {/* <div class="viewalldiv">
                            <Link
                                to="#"
                                className="view-all-text">
                        {t("all-categories.view-all")}
                        </Link>
                        </div> */}
                    </div>
                    <ProductList
                        type="product"
                        home={true}
                        products={products}
                    />
                    <div />
                </Row>

                <div className="row mx-0 my-2">
                    <div className="col-6 my-1">
                        <Link
                            to="/uae-en/offers/section/appliances">

                            <img
                                src={AppliancesImage}
                                alt="category"
                                style={{
                                    width: "100%",

                                }} />
                        </Link>

                    </div>
                    <div className="col-6 my-1">
                        <Link
                            to="/uae-en/offers/section/diy-paint">

                            <img
                                src={DIYPaintsImage}
                                alt="category"
                                style={{
                                    width: "100%",

                                }} />
                        </Link>

                    </div>
                </div>
            </div>

            <WebsiteFooter />
        </div>
    )
};

const mapStateToProps = (state) => {
    return {
        seller: state.allItems.seller,
        filter_by: state.allItems.filter_by,
        brand: state.allItems.brand,
        items: state.allItems.items,
        countryId: state.language.countryId,
        countryUrl: state.language.countryUrl,
        width: state.layout.screenWidth,
        language: state.language.language,
    };
};

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(NovemberSale);