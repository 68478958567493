import { type } from "../../types/types";

function getScreenWidth(width) {
  if (width >= 1440) {
    return "xxxl";
  } else if (width >= 1200) {
    return "xxl";
  } else if (width >= 1024) {
    return "xl";
  } else if (width >= 992) {
    return "lg";
  } else if (width >= 768) {
    return "md";
  } else {
    return "sm";
  }
}

export default function reducer(
  state = { screenWidth: getScreenWidth(window.innerWidth), loading: false },
  action
) {
  switch (action.type) {
    case type.SET_WIDTH:
      return { ...state, screenWidth: action.width };
    case type.SET_LOADING:
      return { ...state, loading: action.loading };
    default:
      return state;
  }
}
