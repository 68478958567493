import React, { Fragment, useState, useEffect } from "react";

import "../../../assets/styles/customer/account/wallet.scss";
import "../../../assets/styles/general.scss";
import "../../../assets/styles/utils.scss";
import { Table } from "react-bootstrap";

import EmptyPage from "../../../utils/EmptyPage";

import EmptyImage from "../../../assets/images/wallet_art.svg";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";

const Wallet = (props) => {
  const { t } = useTranslation();
  const CREDITS_TAB = "credits-table";
  const TRANSFERS_TAB = "transfers-table";

  const [currentTab, setCurrentTab] = useState(CREDITS_TAB);
  const [lan, setLan] = useState("");

  const switchTab = (event) => {
    const tabName = event.target.getAttribute("data-tab");

    switch (tabName) {
      case CREDITS_TAB:
        setCurrentTab(CREDITS_TAB);
        break;
      default:
        setCurrentTab(TRANSFERS_TAB);
        break;
    }
  };

  const renderTabContent = () => {
    const DATA = currentTab === CREDITS_TAB ? props.credit : props.transfer;
    return (
      <div>
        {DATA.length > 0 ? (
          <div className={`${currentTab} wallet-table`}>
            <div className="table-responsive">
              <Table hover className="overflow-auto">
                <thead>
                  <tr className="table-head">
                    <td className="pl-4 w-10 fw-500">{t("wallet.date")}</td>
                    <td className="w-10 fw-500">{t("wallet.type")}</td>
                    <td className="w-25 fw-500">{t("wallet.details")}</td>
                    <td className="w-10 fw-500">{t("wallet.amount")}</td>
                    {DATA[0].remaining_balance && (
                      <td className="text-right w-5 fw-500">
                        {t("wallet.balance")}
                      </td>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {DATA.map((transaction) => {
                    return (
                      <tr key={transaction.id}>
                        <td className="date gray word-break-none pl-4">
                          {moment(transaction.created_at).format("ll")}
                        </td>
                        <td className="type font-weight-bold">
                          {transaction.transaction_type === "order_type"
                            ? "order payment"
                            : "refund"}
                        </td>
                        <td>
                          <div className="gray description">
                            <div className="main fw-600">
                              {transaction[
                                `order_payment_details_${props.language}`
                              ] ||
                                transaction[`refund_details_${props.language}`]}
                            </div>
                          </div>
                        </td>
                        {isPositive(transaction.transaction_type) ? (
                          <td className="amount green word-break-none">
                            {" "}
                            + {transaction.currency.toUpperCase()}{" "}
                            {transaction.amount}
                          </td>
                        ) : (
                          <td className="red word-break-none">
                            {" "}
                            - {transaction.currency.toUpperCase()}{" "}
                            {transaction.amount}
                          </td>
                        )}
                        {transaction.remaining_balance && (
                          <td className="text-right balance">
                            {transaction.currency.toUpperCase()}{" "}
                            {transaction.remaining_balance}
                          </td>
                        )}
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          </div>
        ) : (
          <EmptyPage
            img={EmptyImage}
            heading={
              currentTab !== TRANSFERS_TAB
                ? t("wallet.empty.no-credit")
                : t("wallet.empty.no-bank-transfers")
            }
          />
        )}
      </div>
    );
  };

  const isPositive = (type) => {
    switch (type) {
      case "refund_type":
        return true;
      default:
        return false;
    }
  };

  useEffect(() => {
    setLan(localStorage.getItem("i18nextLng"));
  }, []);

  return (
    <Fragment>
      <div className="customer-wallet w-100 py-3">
        {lan === "ar" ? (
          <div className="d-flex justify-content-between">
            <div>
              <div className="title" style={{ textAlign: "initial" }}>
                {t("wallet.title")}
              </div>
              <p style={{ textAlign: "initial" }} className="gray below-title">
                {t("wallet.keep-track-label")}
              </p>
            </div>
            <div>
              <div className="right green">{t("wallet.available-balance")}</div>
              <div className="right start">
                {props.currency.toUpperCase()} {props.balance}
              </div>
            </div>
          </div>
        ) : (
          <div className="d-flex justify-content-between">
            <div>
              <div className="title">{t("wallet.title")}</div>
              <p className="gray below-title">{t("wallet.keep-track-label")}</p>
            </div>
            <div>
              <div className="right green">{t("wallet.available-balance")}</div>
              <div className="right start">
                {props.currency.toUpperCase()} {props.balance}
              </div>
            </div>
          </div>
        )}
        <div className="tabs row no-gutters fw-500 pb-3 pt-1">
          <div
            className={`${
              currentTab === "credits-table" ? "active-tab" : ""
            } pointer py-2`}
            onClick={switchTab}
            data-tab="credits-table"
          >
            {t("wallet.credits")}
          </div>
          <div
            className={`${
              currentTab === "transfers-table" ? "active-tab" : ""
            } pointer start-margin py-2`}
            onClick={switchTab}
            data-tab="transfers-table"
          >
            {t("wallet.transfers")}
          </div>
        </div>

        {renderTabContent()}
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    credit: state.wallet.credit,
    transfer: state.wallet.tranfer,
    language: state.language.language,
  };
};
export default connect(mapStateToProps, null)(Wallet);
