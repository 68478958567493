import HighGloss_600x from "../assets/images/homepage/products/high-gloss-600x.png";
import KurdKutter from "../assets/images/homepage/products/krud-kutter.jpeg";
import KrudKutterAutomotive from "../assets/images/Products_Images/krud-kutter-automotive.jpeg";
import NeverWet from "../assets/images/Products_Images/never-wet.jpeg";
import RubberizedBlack from "../assets/images/Products_Images/rubberized-black.jpeg";
import ToughTaskRemover from "../assets/images/Products_Images/tough-task-remover.jpeg";
import DryErase from "../assets/images/Products_Images/dry-erase.jpeg";
import DapWeldwood from "../assets/images/Products_Images/dap-weldwood.jpeg";
import FastNFinal from "../assets/images/Products_Images/fast-n-final.jpeg";
import ConcreteSaver from "../assets/images/Products_Images/concrete-saver-6700.png";
import PaintPen from "../assets/images/Products_Images/paint-pen.jpeg";
import WoodFloorAdhesive from "../assets/images/Products_Images/wood-floor-adhesive.jpeg";

const getRandomItem = (arr) => {
  return arr[Math.floor(Math.random() * arr.length)];
};

const PRODUCT_IMAGES = [
  HighGloss_600x,
  KurdKutter,
  KrudKutterAutomotive,
  NeverWet,
  RubberizedBlack,
  ToughTaskRemover,
  DryErase,
  DapWeldwood,
  FastNFinal,
  ConcreteSaver,
  PaintPen,
  WoodFloorAdhesive,
];

export const getRandomProductImage = () => {
  return getRandomItem(PRODUCT_IMAGES);
};

export const getCurrencyOfCountry = (country) => {
  switch (country) {
    case "United Arab Emirates":
      return "AED";
    case "Saudi Arabia":
      return "SAR";
    case "Egypt":
      return "EGP";
    default:
      return "AED";
  }
};

export { getRandomItem };

export const getMobileImage = (resized, featured) => {
  if (resized !== null) {
    if (resized?.[0]) {
      return resized[0];
    } else if (resized?.[1]) {
      return resized[1];
    } else {
      return featured;
    }
  } else {
    return featured;
  }
};

export const getDesktopImage = (resized, featured) => {
  if (resized !== null) {
    if (resized?.[1]) {
      return resized[1];
    } else if (resized?.[0]) {
      return resized[0];
    } else {
      return featured;
    }
  } else {
    return featured;
  }
};

export const replaceAt = (str, index, chr) => {
  if (index > str.length - 1) return str;
  return str.substring(0, index) + chr + str.substring(index + 1);
};
