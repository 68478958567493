import React, { Fragment } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./../../../assets/styles/customer/cart/cartordersummary.scss";
import "./../../../assets/styles/customer/cart/cartpage.scss";
import "../../../assets/styles/component/customer/checkout/orderitems.scss";
import "../../../assets/styles/component/customer/checkout/quantitycounter.scss";

import { set_cart_items } from "../../../redux/actions/CartAction";
import { connect } from "react-redux";
import CartWishList from "./CartWishlist";
import "react-toastify/dist/ReactToastify.css";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import YelloColorImage from "../../../assets/images/homepage/YelloColorImage.png";
import EmptyReturnsState from "../../../assets/images/wishlist_art.svg";

const WishlistMain = (props) => {
  const { t } = useTranslation();

  return (
    <Fragment>
      <div className="cart-desktop w-100">
        <Row className="cart-data-state pt-3 pb-2 no-gutters">
          <Col
            lg={12}
            className="no-gutters orderitems-and-wishlist no-gutters"
          >
            <Col lg={12} className=" no-gutters cart-left">
              <div className=" cart-items mt-2">
                {t("my-account-logged-in.wishlist")}{" "}
                <span className="items-text">
                  ({props.wishlist?.length} {t("items.items")})
                </span>
              </div>
              {props.wishlist?.length > 0 ? (
                <>
                  <div md={12} className=" cart-product-image-area mt-2 mb-5">
                    {props.primaryBanners[0]?.image ? (
                      <>
                        {props.primaryBanners[0]?.redirects_to_link ? (
                          <a href={props.primaryBanners[0]?.redirects_to_link}>
                            <img
                              src={
                                props.primaryBanners[0].image || YelloColorImage
                              }
                              width="100%"
                              height="100%"
                              alt="wishlist"
                            />
                          </a>
                        ) : (
                          <img
                            src={
                              props.primaryBanners[0].image || YelloColorImage
                            }
                            width="100%"
                            height="100%"
                            alt="wishlist"
                          />
                        )}
                      </>
                    ) : (
                      <img
                        src={YelloColorImage}
                        width="100%"
                        height="100%"
                        alt="wislist"
                      />
                    )}
                  </div>
                  <CartWishList
                    List={props.wishlist}
                    currency={props.currency}
                  />
                </>
              ) : (
                <div className="empty-wishlist-image d-flex justify-content-center">
                  <img
                    src={EmptyReturnsState}
                    className="img-fluid"
                    alt="wishlistimage"
                    width="30%"
                    height="40%"
                  />
                </div>
              )}
            </Col>
          </Col>
        </Row>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    width: state.layout.screenWidth,
    cart_items: state.cart.cart_items,
    primaryBanners: state.cart.primaryBanners,
    secondaryBanners: state.cart.secondaryBanners,
    wish_list: state.cart.wish_list,
    country: state.language.country,
  };
};

const mapDispatchToProps = { set_cart_items };

export default connect(mapStateToProps, mapDispatchToProps)(WishlistMain);
