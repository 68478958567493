import React, { Fragment, useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import "bootstrap/dist/css/bootstrap.css";
import "../../assets/styles/pages/producthomepage.scss";
import "react-toastify/dist/ReactToastify.css";
import "../../assets/styles/component/middle_section.scss";
import dummyoffer from "../../assets/images/homepage/dummyoffer.png";
import ProductList from "../product/ProductList";
import Tabs from 'react-bootstrap/Tabs';
import Sonnet from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tabs';
import { Row, Container } from "react-bootstrap";
import { apiJson } from "../../Api";

const MiddleSection = (props) => {
    const [sections, setSections] = useState([]);

    useEffect(() => {
        apiJson.homepage.getHomeOffersSections(1)
            .then(response => {
                if (response.success) {
                    setSections(response.data.sections);
                }
            })
    }, []);
    return (

        <div className="middle-section">
              <div className="sectiontitle-container">
            <p className="section-subtitle">THE CORE PRODUCTS</p>
            <h2 className="section-title">This <span className="section-underline">week's</span> highlights</h2>
</div>
            <Tabs defaultActiveKey={sections[0]?.title_en} id="uncontrolled-tab-example" className="mb-3 justify-content-center">
                {
                    sections.map(section => {
                        return (
                            <Tab eventKey={section.title_en} title={section.title_en}>
                                <Container fluid>
                             
                                    <Row>
                                        <div className="col-lg-6 col-md-6 mia">
                                            <a href={section.home_offers_section_images[0]?.link}>
                                                <img className="homeofferimg"
                                                    src={section.home_offers_section_images[0]?.image}
                                                    alt="dummyoffers"
                                                />
                                            </a>
                                        </div>
                                        <div className="col-lg-6 col-md-6 mib">

                                            <Row>
                                                <div className="col-lg-6 col-md-6 mic">
                                                    <a href={section.home_offers_section_images[1]?.link}>
                                                        <img className="homeofferimg"
                                                            src={section.home_offers_section_images[1]?.image}
                                                            alt="dummyoffers"
                                                        />
                                                    </a>
                                                </div>
                                                <div className="col-lg-6 col-md-6 mid">
                                                    <a href={section.home_offers_section_images[2]?.link}>
                                                        <img className="homeofferimg"
                                                            src={section.home_offers_section_images[2]?.image}
                                                            alt="dummyoffers"
                                                        />
                                                    </a>
                                                </div>
                                            </Row>

                                            <Row>
                                                <div className="col-lg-6 col-md-6 mie">
                                                    <a href={section.home_offers_section_images[3]?.link}>
                                                        <img className="homeofferimg"
                                                            src={section.home_offers_section_images[3]?.image}
                                                            alt="dummyoffers"
                                                        />
                                                    </a>
                                                </div>
                                                <div className="col-lg-6 col-md-6 mif">
                                                    <a href={section.home_offers_section_images[4]?.link}>
                                                        <img className="homeofferimg"
                                                            src={section.home_offers_section_images[4]?.image}
                                                            alt="dummyoffers"
                                                        />
                                                    </a>
                                                </div>
                                            </Row>

                                        </div>
                                    </Row>
                                </Container>
                                <Sonnet />
                            </Tab>
                        )
                    })
                }

            </Tabs>
        </div>

    )

}

export default MiddleSection;