import React, { useEffect, useState } from "react";
import { RiSearchLine } from "react-icons/ri";
import { set_loading } from "../../../redux/actions/LayoutAction";
import logo from "../../../assets/images/logo.png";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { Redirect } from "react-router-dom";
import MobileLayoutRenderer from "../../../pages/mobile/layout/MobileLayoutRenderer";
import { useTranslation } from "react-i18next";
import "../../../assets/styles/component/mobile/Home/HomeMobile.scss";
import "react-toastify/dist/ReactToastify.css";
import pagesbannerimg from "../../../assets/images/footer-pages-banners/Contact-Us.jpg";
import { apiJson } from "../../../Api";
import "../../../assets/styles/pages/contactus/ContactUsPageMob.scss";

function ContactUsComp(props) {
  const [redirect, setRedirect] = useState({ value: false });
  // eslint-disable-next-line
  const { t, i18n } = useTranslation();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [messageDes, setMessage] = useState("");
  let message = "";
  const [data, setData] = useState("<p></p>");

  const showError = () => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  useEffect(() => {
    window.document.title = "Contact us";

    window.scrollTo(0, 0);
    props.set_loading(true);
    apiJson.platformData.platformData().then((response) => {
      if (response.success) {
        setData(response.data);
        props.set_loading(false);
      } else if (!response.status) {
        // eslint-disable-next-line
        message = response.message
          ? response.message !== ""
            ? response.message
            : "something went wrong"
          : "something went wrong";
        showError();
      } else {
        if (response.status >= 400 && response.status < 500) {
          message = response.message
            ? response.message
            : response.errors
            ? response.errors
            : "something went wrong";
          showError();
        } else {
          message = response.message
            ? response.message !== ""
              ? response.message
              : "something went wrong"
            : "something went wrong";
          showError();
        }
      }
    });
  }, []);

  // eslint-disable-next-line
  function getMobileOperatingSystem() {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
      return "Windows Phone";
    }

    if (/android/i.test(userAgent)) {
      return "Android";
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return "iOS";
    }

    return "unknown";
  }

  return (
    <MobileLayoutRenderer
      NavbarTop={false}
      BackBtn={false}
      CancelBtn={false}
      NavbarBottom={true}
    >
      <div className="home-mob contact-us-main-mobile">
        <div className="header">
          <div className="logo">
            <img src={logo} width="100%" height="100%" alt="" />
          </div>
          <div className="search">
            <RiSearchLine
              onClick={() =>
                setRedirect({ value: true, path: "all-categories" })
              }
            />
          </div>
        </div>

        <div className="body" style={{ background: "#F5F5F5" }}>
          <div className="pagesbanner">
            <img
              src={pagesbannerimg}
              alt="category"
              style={{
                width: "100%",
                height: "100%",
                maxHeight: "400px",
              }}
            ></img>
          </div>
          <div className="content-wrapper all-page-container">
            <div className="footer-pages-content">
              <div className="heading">{t("contact-us.heading")}</div>
              <div className="sub-heading">{data.heading}</div>
              {/* <div className="mt-3"> */}
              <div
                // className="mb-1 body"
                dangerouslySetInnerHTML={{
                  __html:
                    props.language === "en"
                      ? data[`contact_us`]
                      : data[`contact_us_${props.language}`],
                }}
              ></div>
              {/* </div> */}

              <div className="d-flex flex-column align-items-center form">
                <div className="form-heading">{t("contact-us.feel-free")}</div>
                <div className="d-flex flex-row justify-content-between align-items-center">
                  <div className=" d-flex flex-column input-cont">
                    <label className="label">{t("contact-us.full-name")}</label>
                    <input
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </div>
                  <div className="  d-flex flex-column input-cont">
                    <label className="label">
                      {t("login.email-placeholder")}
                    </label>
                    <input
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                </div>
                <div className="message-cont  d-flex flex-column input-cont">
                  <label className="label">
                    {t("contact-us.your-message")}
                  </label>
                  <textarea
                    value={messageDes}
                    onChange={(e) => setMessage(e.target.value)}
                  />
                </div>

                <div className="submit-button pointer">
                  <span>{t("contact-us.send-message")}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        {redirect.value && (
          <Redirect
            push
            to={{ pathname: redirect.path, state: redirect.state }}
          />
        )}
      </div>
    </MobileLayoutRenderer>
  );
}

const mapStateToProps = (state) => {
  return {
    language: state.language.language,
    loading: state.layout.loading,
  };
};

const mapDispatchToProps = {
  set_loading,
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactUsComp);
