import React from "react";

import ProductList from "../../product/ProductList.js";
import "../../../assets/styles/component/customer/itemDetail/ItemsViewed.scss";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import ProductCard from "../../mobile/Home/ProductCardMobile";

function ProductsSection(props) {
  const { t } = useTranslation();

  return (
    <>
      {
        props.width == "sm" ?
          <div className="item-viewed-mobile">
            {props.itemReviewedProducts?.length > 0 && (
              <>
                <div className={
                  props.language == "ar" ? "heading-ar" : "heading"
                }>{t("item-detail.you-might-also-like")}</div>
                <div className="product-cont">
                  <div
                    className="product-cont mt-3"
                    style={{ marginTop: "0px !important" }}
                  >
                    {props.itemReviewedProducts?.map((data) => (
                      data.variants.length > 0 ?
                        <Link
                          style={{ textDecoration: "none" }}
                          to={{
                            pathname:
                              "/" +
                              props.countryUrl +
                              "-" +
                              props.language +
                              `/${data.category_title_en
                                .toLowerCase()
                                .replace(/ /g, "-")}/${data.slug}`,
                            state: {
                              id: data.id,
                              type: data.variants ? "products" : "services",
                            },
                            search: "?sku=" + data.variants[0].sku
                          }}
                          key={data.id + Math.random()}
                        >
                          <div
                            className="image-cont"
                            key={data.id + Math.random() + "prod"}
                          >
                            <ProductCard
                              key={data.id + Math.random()}
                              img={
                                data.variants
                                  ? data.variants[0].featured_image
                                  : data.featured_image
                              }
                              storeLogo={data.store_logo}
                              wishlisted={
                                data.variants
                                  ? data.variants[0].wishlisted
                                  : data.wishlisted
                              }
                              home={true}
                              type={data.variants ? "products" : "services"}
                              id={data.variants ? data.variants[0].id : data.id}
                              rating={data.average_rating}
                              reviews={data.reviews_count}
                              name={data[`name_${props.language}`]}
                              data={data.variants ? data.variants[0] : data}
                              coupon={data.coupon}
                              is_inquiry={data.is_inquiry}
                            />
                          </div>
                        </Link>
                        :
                        <></>
                    ))}
                  </div>
                </div>
              </>
            )}
            {props.section1_products?.length > 0 && (
              <>
                <div className={
                  props.language == "ar" ? "heading-ar" : "heading"
                }>{t("item-detail.frequently-bought-together")}</div>
                <div className="product-cont">
                  <div
                    className="product-cont mt-3"
                    style={{ marginTop: "0px !important" }}
                  >
                    {props.section1_products?.map((data) => (
                      data.variants.length > 0 ?
                        <Link
                          style={{ textDecoration: "none" }}
                          to={{
                            pathname:
                              "/" +
                              props.countryUrl +
                              "-" +
                              props.language +
                              `/${data.category_title_en
                                .toLowerCase()
                                .replace(/ /g, "-")}/${data.slug}`,
                            state: {
                              id: data.id,
                              type: data.variants ? "products" : "services",
                            },
                            search: "?sku=" + data.variants[0].sku
                          }}
                          key={data.id + Math.random()}
                        >
                          <div
                            className="image-cont"
                            key={data.id + Math.random() + "prod"}
                          >
                            <ProductCard
                              key={data.id + Math.random()}
                              img={
                                data.variants
                                  ? data.variants[0].featured_image
                                  : data.featured_image
                              }
                              storeLogo={data.store_logo}
                              wishlisted={
                                data.variants
                                  ? data.variants[0].wishlisted
                                  : data.wishlisted
                              }
                              home={true}
                              type={data.variants ? "products" : "services"}
                              id={data.variants ? data.variants[0].id : data.id}
                              rating={data.average_rating}
                              reviews={data.reviews_count}
                              name={data[`name_${props.language}`]}
                              data={data.variants ? data.variants[0] : data}
                              coupon={data.coupon}
                              is_inquiry={data.is_inquiry}
                            />
                          </div>
                        </Link>
                        :
                        <></>
                    ))}
                  </div>
                </div>
              </>
            )}
            {props.brand_products?.length > 0 && (
              <>
                <div className={
                  props.language == "ar" ? "heading-ar" : "heading"
                }>{t("item-detail.more-from-brand")}</div>
                <div className="product-cont">
                  <div
                    className="product-cont mt-3"
                    style={{ marginTop: "0px !important" }}
                  >
                    {props.brand_products?.map((data) => (
                      data.variants.length > 0 ?
                        <Link
                          style={{ textDecoration: "none" }}
                          to={{
                            pathname:
                              "/" +
                              props.countryUrl +
                              "-" +
                              props.language +
                              `/${data.category_title_en
                                .toLowerCase()
                                .replace(/ /g, "-")}/${data.slug}`,
                            state: {
                              id: data.id,
                              type: data.variants ? "products" : "services",
                            },
                            search: "?sku=" + data.variants[0].sku
                          }}
                          key={data.id + Math.random()}
                        >
                          <div
                            className="image-cont"
                            key={data.id + Math.random() + "prod"}
                          >
                            <ProductCard
                              key={data.id + Math.random()}
                              img={
                                data.variants
                                  ? data.variants[0].featured_image
                                  : data.featured_image
                              }
                              storeLogo={data.store_logo}
                              wishlisted={
                                data.variants
                                  ? data.variants[0].wishlisted
                                  : data.wishlisted
                              }
                              home={true}
                              type={data.variants ? "products" : "services"}
                              id={data.variants ? data.variants[0].id : data.id}
                              rating={data.average_rating}
                              reviews={data.reviews_count}
                              name={data[`name_${props.language}`]}
                              data={data.variants ? data.variants[0] : data}
                              coupon={data.coupon}
                              is_inquiry={data.is_inquiry}
                            />
                          </div>
                        </Link>
                        :
                        <></>
                    ))}
                  </div>
                </div>
              </>
            )}
            {props.hardware_products?.length > 0 && (
              <>
                <div className={
                  props.language == "ar" ? "heading-ar" : "heading"
                }>{t("item-detail.how-about-these")}</div>
                <div className="product-cont">
                  <div
                    className="product-cont mt-3"
                    style={{ marginTop: "0px !important" }}
                  >
                    {props.hardware_products?.map((data) => (
                      data.variants.length > 0 ?
                        <Link
                          style={{ textDecoration: "none" }}
                          to={{
                            pathname:
                              "/" +
                              props.countryUrl +
                              "-" +
                              props.language +
                              `/${data.category_title_en
                                .toLowerCase()
                                .replace(/ /g, "-")}/${data.slug}`,
                            state: {
                              id: data.id,
                              type: data.variants ? "products" : "services",
                            },
                            search: "?sku=" + data.variants[0].sku
                          }}
                          key={data.id + Math.random()}
                        >
                          <div
                            className="image-cont"
                            key={data.id + Math.random() + "prod"}
                          >
                            <ProductCard
                              key={data.id + Math.random()}
                              img={
                                data.variants
                                  ? data.variants[0].featured_image
                                  : data.featured_image
                              }
                              storeLogo={data.store_logo}
                              wishlisted={
                                data.variants
                                  ? data.variants[0].wishlisted
                                  : data.wishlisted
                              }
                              home={true}
                              type={data.variants ? "products" : "services"}
                              id={data.variants ? data.variants[0].id : data.id}
                              rating={data.average_rating}
                              reviews={data.reviews_count}
                              name={data[`name_${props.language}`]}
                              data={data.variants ? data.variants[0] : data}
                              coupon={data.coupon}
                              is_inquiry={data.is_inquiry}
                            />
                          </div>
                        </Link>
                        :
                        <></>
                    ))}
                  </div>
                </div>
              </>
            )}
            {props.seller_products?.length > 0 && (
              <>
                <div className={
                  props.language == "ar" ? "heading-ar" : "heading"
                }>{t("item-detail.you-might-also-like")}</div>
                <div className="product-cont">
                  <div
                    className="product-cont mt-3"
                    style={{ marginTop: "0px !important" }}
                  >
                    {props.seller_products?.map((data) => (
                      data.variants.length > 0 ?
                        <Link
                          style={{ textDecoration: "none" }}
                          to={{
                            pathname:
                              "/" +
                              props.countryUrl +
                              "-" +
                              props.language +
                              `/${data.category_title_en
                                .toLowerCase()
                                .replace(/ /g, "-")}/${data.slug}`,
                            state: {
                              id: data.id,
                              type: data.variants ? "products" : "services",
                            },
                            search: "?sku=" + data.variants[0].sku
                          }}
                          key={data.id + Math.random()}
                        >
                          <div
                            className="image-cont"
                            key={data.id + Math.random() + "prod"}
                          >
                            <ProductCard
                              key={data.id + Math.random()}
                              img={
                                data.variants
                                  ? data.variants[0].featured_image
                                  : data.featured_image
                              }
                              storeLogo={data.store_logo}
                              wishlisted={
                                data.variants
                                  ? data.variants[0].wishlisted
                                  : data.wishlisted
                              }
                              home={true}
                              type={data.variants ? "products" : "services"}
                              id={data.variants ? data.variants[0].id : data.id}
                              rating={data.average_rating}
                              reviews={data.reviews_count}
                              name={data[`name_${props.language}`]}
                              data={data.variants ? data.variants[0] : data}
                              coupon={data.coupon}
                              is_inquiry={data.is_inquiry}
                            />
                          </div>
                        </Link>
                        :
                        <></>
                    ))}
                  </div>
                </div>
              </>
            )}
          </div>
          :
          <div className="item-viewed-desktop">
            {props.section1_products?.length > 0 && (
              <>
                <div className={
                  props.language == "ar" ? "heading-ar" : "heading"
                }>{props.section_title}</div>
                <ProductList products={props.section1_products} />
              </>
            )}
          </div>
      }
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    width: state.layout.screenWidth,
    countryUrl: state.language.countryUrl,
    language: state.language.language,
    section1_products: state.item.section1_products,
    itemReviewedProducts: state.item.itemReviewedProducts,
    brand_products: state.item.brand_products,
    hardware_products: state.item.hardware_products,
    seller_products: state.item.seller_products
  };
};

export default connect(mapStateToProps, null)(ProductsSection);
