import React, { Fragment, useState } from "react";
import { connect } from "react-redux";
import { AiOutlinePoweroff } from "react-icons/ai";
import { ReactComponent as accountAddresses } from "../../assets/icons/mobile-account/accountAddresses.svg";
import { ReactComponent as accountProfileBlank } from "../../assets/icons/mobile-account/accountProfileBlank.svg";
import { ReactComponent as accountLanguage } from "../../assets/icons/mobile-account/accountLanguage.svg";
import { ReactComponent as accountRegion } from "../../assets/icons/mobile-account/accountRegion.svg";
import { ReactComponent as accountpayment } from "../../assets/icons/mobile-account/accountpayment.svg";
import { ReactComponent as accountClaims } from "../../assets/icons/mobile-account/accountClaims.svg";
import ListItems from "../../components/mobile/MyAccount/ListItems";
import orders from "../../assets/images/My Account/orders.png";
import returns from "../../assets/images/My Account/returns.png";
import wallet from "../../assets/images/My Account/wallet.png";
import wishlist from "../../assets/images/My Account/wishlist.png";
import uae from "../../assets/images/flag.png";
import saudia from "../../assets/images/flag2.png";
import egypt from "../../assets/images/flag3.png";
import whatsappicon from "../../assets/images/My Account/whatsapp.png";
import LinksAndTerms from "../../components/mobile/MyAccount/SocialLinksAndTerms";
import MobileLayoutRenderer from "./layout/MobileLayoutRenderer";
import { set_direction } from "../../redux/actions/LayoutAction";
import { useTranslation } from "react-i18next";
import { Link, Redirect } from "react-router-dom";
import { set_user } from "../../redux/actions/AuthAction";
import { set_cart_items } from "../../redux/actions/CartAction";
import "../../assets/styles/mobile/myaccountloggedinmob.scss";
import { ClipLoader } from "react-spinners";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { apiJson } from "../../Api";

const MyAccountLoggedInMob = (props) => {
  const { t } = useTranslation();
  const [redirect, setRedirect] = useState(false);
  const [signingOut, setSigningOut] = useState(false);
  let message = "";

  const showError = () => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const MyAccount = [
    {
      id: 1,
      icon: accountAddresses,
      title: t("my-account-logged-in.address"),
      path: "/" + props.countryUrl + "-" + props.language + "/addresses",
    },
    {
      id: 2,
      icon: accountpayment,
      title: t("my-account-logged-in.payment"),
      path: "/" + props.countryUrl + "-" + props.language + "/payments",
    },
    {
      id: 3,
      icon: accountClaims,
      title: t("my-account-logged-in.claims"),
      path: "/" + props.countryUrl + "-" + props.language + "/disputes",
    },
    {
      id: 4,
      icon: accountProfileBlank,
      title: t("my-account-logged-in.profile"),
      path: "/" + props.countryUrl + "-" + props.language + "/profile",
    },
  ];
  const Settings_List = [
    {
      id: 1,
      icon: accountRegion,
      title: t("my-account-logged-in.country"),
      rightIcon:
        props.country.toLowerCase() === "United Arab Emirates".toLowerCase()
          ? uae
          : props.country.toLowerCase() === "Saudi Arabia".toLowerCase()
            ? saudia
            : egypt,
    },
    {
      id: 2,
      icon: accountLanguage,
      title: t("my-account-logged-in.language"),
      rightIcon: props.language === "en" ? "English" : "العربية",
    },
  ];
  const page_settings = t("my-account-logged-in.settings-title");
  const account_title = t("my-account-logged-in.my-account-title");

  const reach_out_to_us_heading = t("my-account-logged-in.reach-out-title");
  const Reach_Out_To_Us_List = [
    {
      id: 2,
      icon: accountLanguage,
      // title: t("my-account-logged-in.contact-us"),
      title: "Contact Us",
      rightIcon: "",
    },
  ];

  const signout = () => {
    setSigningOut(true);
    apiJson.auth.signout().then((response) => {
      if (response.success) {
        document.cookie =
          "authHeaders=; path=/; expires=Thu, 21 Aug 2014 20:00:00 UTC";
        localStorage.removeItem("access_token");
        localStorage.removeItem("client");
        localStorage.removeItem("uid");
        localStorage.removeItem("guest_user_address");
        props.set_user({ isSignedIn: false, user: {} });

        props.set_cart_items({ cart_items: { sub_total: 0, line_items: [] } });
        setRedirect(true);
        const timer = setTimeout(() => {
          setRedirect(false);
        }, 1000);
        return () => clearTimeout(timer);
      } else {
        setSigningOut(false);
        if (
          parseInt(response.error?.response?.status) === 404 ||
          parseInt(response.error?.response?.status) === 401
        ) {
          document.cookie =
            "authHeaders=; path=/; expires=Thu, 21 Aug 2014 20:00:00 UTC";
          localStorage.removeItem("access_token");
          localStorage.removeItem("client");
          localStorage.removeItem("uid");
          localStorage.removeItem("guest_user_address");
          props.set_user({ isSignedIn: false, user: {} });

          props.set_cart_items({
            cart_items: { sub_total: 0, line_items: [] },
          });
          setRedirect(true);
          const timer = setTimeout(() => {
            setRedirect(false);
          }, 1000);
          return () => clearTimeout(timer);
        } else {
          message =
            props.language === "ar" ? "هناك خطأ ما" : "something went wrong";
          showError();
        }
      }
    });
  };
  return (
    <MobileLayoutRenderer
      NavbarTop={false}
      BackBtn={false}
      CancelBtn={false}
      CloseIcon={true}
      NavbarBottom={true}
    >
      <>
        {props.width === "sm" ? (
          <Fragment>
            <div className="my-account-page-logged-in-mobile">
              <div className="hala-user">
                <div className="name-email">
                  <span className="name">
                    {t("customer-profile-address.hala")}{" "}
                    {props.user[`first_name_${props.language}`]}
                  </span>
                  <span className="user-email">{props.user.email}</span>
                </div>
              </div>
              <div className="logged-in-menu">
                <Link
                  to={"/" + props.countryUrl + "-" + props.language + "/orders"}
                >
                  <div className="orders align-center-all">
                    <img
                      src={orders}
                      style={{ width: "62px", height: "62px" }}
                      alt="orderimage"
                    />
                    <label className="auth-label">
                      {t("my-account-logged-in.orders")}
                    </label>
                  </div>
                </Link>
                <Link
                  to={
                    "/" + props.countryUrl + "-" + props.language + "/returns"
                  }
                >
                  <div className="returns align-center-all">
                    <img
                      src={returns}
                      style={{ width: "62px", height: "62px" }}
                      alt="retunimage"
                    />
                    <label className="auth-label">
                      {t("my-account-logged-in.returns")}
                    </label>
                  </div>
                </Link>
                <Link
                  to={"/" + props.countryUrl + "-" + props.language + "/wallet"}
                >
                  <div className="noon-credits align-center-all">
                    <img
                      src={wallet}
                      style={{ width: "62px", height: "62px" }}
                      alt="walletimage"
                    />
                    <label className="auth-label">
                      {t("my-account-logged-in.noon-credits")}
                    </label>
                  </div>
                </Link>
                <Link
                  to={
                    "/" + props.countryUrl + "-" + props.language + "/wishlist"
                  }
                >
                  <div className="wishlist align-center-all">
                    <img
                      src={wishlist}
                      style={{ width: "62px", height: "62px" }}
                      alt="wishlist"
                    />
                    <label className="auth-label">
                      {t("my-account-logged-in.wishlist")}
                    </label>
                  </div>
                </Link>
              </div>
              <div className="scrollable-my-account">
                <div className="my-account-list">
                  <ListItems
                    authorized={true}
                    List={MyAccount}
                    header={account_title}
                  />
                </div>
                <div className="account-setting-list">
                  <ListItems
                    authorized={true}
                    List={Settings_List}
                    header={page_settings}
                  />
                </div>
                <div className="settings-list-items">
                  <ListItems
                    authorized={false}
                    List={Reach_Out_To_Us_List}
                    header={reach_out_to_us_heading}
                  />
                </div>
                <hr className="my-0 mx-3" />
                <div className="my-account-list-items-mobile">
                  <div className="list-main">
                    <a href="//api.whatsapp.com/send?phone=971543064845&amp;text=Hi, I need assistance"
                      className="list-items"
                      style={{
                        fontSize: "13px",
                        textDecoration: "none",
                        alignItems: "center",
                        marginLeft: "15px",
                        marginRight: "15px",
                        backgroundColor: "white",
                        justifyContent: "normal",
                        height: "60px"
                      }}
                    >
                      {
                        props.language == "en" ?
                          <img style={{
                            marginRight: "30px"
                          }}
                            src={whatsappicon}
                            alt="flag"
                            width="30px"
                            height="30px"
                          />
                          :
                          <img style={{
                            marginLeft: "30px"
                          }}
                            src={whatsappicon}
                            alt="flag"
                            width="30px"
                            height="30px"
                          />
                      }
                      {t("contact-us.whatsapp")}
                    </a>
                  </div>
                </div>
                <div className="sout-main">
                  <div
                    onClick={() => (!signingOut ? signout() : undefined)}
                    className="signout"
                  >
                    <div className="s-out-icon">
                      <AiOutlinePoweroff size={30} opacity="0.8" />
                    </div>
                    <span className="signout-label mr-3">
                      {t("my-account-logged-in.sign-out")}
                    </span>
                    {signingOut && <ClipLoader color="#000" size="15" />}
                  </div>
                </div>
                <div className="socialIcons-and-term-policies">
                  <LinksAndTerms loggedIn={true} />
                </div>
              </div>
            </div>
          </Fragment>
        ) : (
          <Redirect
            push
            to={"/" + props.countryUrl + "-" + props.language + "/"}
          />
        )}
      </>
      {redirect && <Redirect push to="/login" />}
    </MobileLayoutRenderer>
  );
};

const mapStateToProps = (state) => {
  return {
    country: state.language.country,
    width: state.layout.screenWidth,
    direction: state.language.direction,
    language: state.language.language,
    user: state.auth.user,
    countryUrl: state.language.countryUrl,
  };
};
const mapDispatchToProps = {
  set_direction,
  set_user,
  set_cart_items,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MyAccountLoggedInMob);
