import React, { Fragment } from "react";

import "../../assets/styles/pages/user/signuppage.scss";
import Signup from "../user/Signup";
import { connect } from "react-redux";

const SignupPage = (props) => {
  return (
    <Fragment>
      {props.width !== "sm" && (
        <div className="signup-page mx-auto">
          <Signup />
        </div>
      )}
    </Fragment>
  );
};
const mapStateToProps = (state) => {
  return {
    width: state.layout.screenWidth,
  };
};

export default connect(mapStateToProps, null)(SignupPage);
