import React from "react";
import { connect } from "react-redux";
import SelectCountry from "../../components/mobile/SelectCountry/CountryMobile";

function SelectCountryPage(props) {
  return <div>{props.width === "sm" && <SelectCountry />}</div>;
}

const mapStateToProps = (state) => {
  return {
    width: state.layout.screenWidth,
  };
};

export default connect(mapStateToProps, null)(SelectCountryPage);
