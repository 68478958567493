import React from "react";
import { connect } from "react-redux";

import ShippingAndDeliveryComp from "../../../components/mobile/footer-pages/ShippingAndDeliveryComp";

function ShippingAndDelivery(props) {
  return <div>{props.width === "sm" && <ShippingAndDeliveryComp screen="home" />}</div>;
}

const mapStateToProps = (state) => {
  return {
    width: state.layout.screenWidth,
  };
};

export default connect(mapStateToProps, null)(ShippingAndDelivery);
