import React, { Fragment, useEffect, useState } from "react";
import Addresses from "./Addresses";
import Header from "./Header";
import EmptyPage from "../../../../utils/EmptyPage";
import EmptyImage from "../../../../assets/images/address_art.svg";
import "./../../../../assets/styles/customer/account/addresses/address.scss";
import { connect } from "react-redux";
import { apiJson } from "../../../../Api";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { set_address } from "../../../../redux/actions/CustomerAddress";
import { useTranslation } from "react-i18next";
import { set_loading } from "../../../../redux/actions/LayoutAction";

const CustomerProfileAddress = (props) => {
  const { t } = useTranslation();
  const [data, setData] = useState(false);

  let message = "";

  const showError = () => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    props.set_loading(true);

    apiJson.select.getAddress().then((response) => {
      props.set_loading(false);
      if (response.success) {
        props.set_address({
          address: response.data,
        });
        setData(true);
      } else if (!response.status) {
        setData(true);
        message = "something went wrong"; // eslint-disable-line react-hooks/exhaustive-deps
        showError();
      } else {
        setData(true);
        if (response.status >= 400 && response.status < 500) {
          message = response.message
            ? response.message
            : response.errors
            ? response.errors
            : t("errors.wrong");
          showError();
        } else {
          message = response.message
            ? response.message !== ""
              ? response.message
              : t("errors.wrong")
            : t("errors.wrong");
          showError();
        }
      }
    });
  }, []);

  return (
    <Fragment>
      <div className="container-fluid w-100 h-100  pr-0">
        <Header />
        {!data ? (
          <div className=""></div>
        ) : props.address && props.address.length > 0 ? (
          props.address.map((address) => (
            <div
              className="row d-flex justify-content-center mx-0"
              key={address.id}
            >
              <Addresses
                country={address.country}
                state={address.state}
                lat={address.latitude}
                lng={address.longitude}
                label={address.label}
                name={address.first_name}
                first={address.first_name}
                last={" "}
                address={address.address1}
                address2={address.address2}
                phone={address.contact_number}
                verified={address.contact_number_verified}
                tag={
                  address.default
                    ? t("customer-profile-address.primary-address-prop")
                    : t("customer-profile-address.other-address-prop")
                }
                default={address.default}
                id={address.id}
              />
            </div>
          ))
        ) : (
          <EmptyPage
            img={EmptyImage}
            heading={t("customer-profile-address.mobile.empty.where-are-they")}
          />
        )}
      </div>
    </Fragment>
  );
};
const mapStateToProps = (state) => {
  return {
    address: state.customerAddress.address,
  };
};

const mapDispatchToProps = {
  set_address,
  set_loading,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomerProfileAddress);
