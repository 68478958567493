import React, { useState } from "react";

import img from "../../../assets/images/addAddressLatlngIndicator.svg";
import { MdModeEdit } from "react-icons/md";
import { RiDeleteBin6Line } from "react-icons/ri";
import "../../../assets/styles/component/mobile/CustomerAddress/CustomerAddressData.scss";
import { apiJson } from "../../../Api";
import { connect } from "react-redux";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ClipLoader from "react-spinners/ClipLoader";
import { set_address } from "../../../redux/actions/CustomerAddress";
import { useTranslation } from "react-i18next";
import { Link, Redirect } from "react-router-dom";
function CustomerAddressData(props) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [redirect, setRedirect] = useState(false);

  let message = "";

  const showError = () => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  const setDefault = () => {
    setLoading(true);
    if (localStorage.getItem("access_token")) {
      apiJson.select.setDefault(props.id).then((response) => {
        if (response.success) {
          props.set_address({
            address: props.customer_address.map((item) =>
              item.id === response.data.id
                ? { ...item, default: true }
                : item.default
                  ? { ...item, default: false }
                  : item
            ),
          });
          setLoading(false);
        } else if (!response.status) {
          setLoading(false);
          message = t("errors.wrong");
          showError();
        } else {
          setLoading(false);
          if (response.status >= 400 && response.status < 500) {
            message = response.message
              ? response.message
              : response.errors
                ? response.errors
                : t("errors.wrong");
            showError();
          } else {
            message = response.message
              ? response.message !== ""
                ? response.message
                : t("errors.wrong")
              : t("errors.wrong");
            showError();
          }
        }
      });
    }
    else {
      var guest_user_address = JSON.parse(localStorage.getItem("guest_user_address"));

      props.set_address({
        address: props.customer_address.map((item) =>
          item.id === guest_user_address.id
            ? { ...item, default: true }
            : item.default
              ? { ...item, default: false }
              : item
        ),
      });

      setLoading(false);
    }
  };
  const deleteAddress = () => {
    if (window.confirm("Are you sure you want to delete this address")) {
      setLoading(true);
      apiJson.select.deleteAddress(props.id).then((response) => {
        if (response.success) {
          props.set_address({
            address: props.customer_address.filter(
              (item) => item.id !== props.id
            ),
          });
          setLoading(false);
        } else if (!response.status) {
          setLoading(false);
          message = t("errors.wrong");
          showError();
        } else {
          setLoading(false);
          if (response.status >= 400 && response.status < 500) {
            message = response.message
              ? response.message
              : response.errors
                ? response.errors
                : t("errors.wrong");
            showError();
          } else {
            message = response.message
              ? response.message !== ""
                ? response.message
                : t("errors.wrong")
              : t("errors.wrong");
            showError();
          }
        }
      });
    }
  };
  return (
    <div
      style={{ border: props.isDefault && "2px solid #4267c8" }}
      className="customer-address-data-mobile"
    >
      <div className="data-header d-flex flex-direction-row justify-content-between align-items-center">
        <div className="d-flex flex-direction-row align-items-center">
          <div className="">
            <img src={img} alt="" />
          </div>

          <div className="label ml-1">{props.label}</div>
          {props.isDefault && (
            <div className="default ml-2">
              {t("customer-profile-address.mobile.default")}
            </div>
          )}
        </div>
        {
          localStorage.getItem("access-token") ?
            <div className="d-flex flex-direction-row align-items-center edit-cont">
              {" "}
              <Link
                to={{
                  pathname:
                    "/" + props.countryUrl + "-" + props.language + "/address",
                  state: {
                    name:
                      window.location.pathname ===
                        "/" + props.countryUrl + "-" + props.language + "/addresses"
                        ? "addresses"
                        : undefined,
                    data: {
                      id: props.id,
                      country: props.country,
                      state: props.state,
                      latitude: props.lat,
                      longtitude: props.lng,
                      address1: props.address,
                      // address: props.address,
                      firstName: props.first,
                      lastName: props.last,
                      number: props.phone,
                      label: props.label,
                      address2: props.address2,
                    },
                  },
                }}
                style={{ color: "black" }}
              >
                <MdModeEdit
                // onClick={() => setRedirect(true)}
                />
              </Link>
              <span
                // onClick={() => setRedirect(true)}
                className="ml-1 edit-delete mr-4"
              >
                {t("checkout.os-edit")}
              </span>
              <RiDeleteBin6Line onClick={deleteAddress} />
              <span onClick={deleteAddress} className="ml-1 mr-1 edit-delete">
                {t("customer-profile-address.mobile.delete")}
              </span>
            </div>
            :
            <></>
        }
      </div >

      {loading && (
        <div className="d-flex justify-content-center align-items-center w-100 h-100">
          <ClipLoader color="#000" loading size={25} />
        </div>
      )
      }

      <div onClick={setDefault} className="data-body">
        <div className="d-flex  mt-3">
          <span className="text-key">
            {t("customer-profile-address.mobile.name")}
          </span>
          <span className="text-value">{props.name}</span>
        </div>
        <div className="d-flex  mt-3">
          <span className="text-key">
            {t("customer-profile-address.mobile.address")}
          </span>
          <span className="text-value">{props.address}</span>
        </div>
        <div className="d-flex  mt-3">
          <span className="text-key">
            {t("customer-profile-address.mobile.mobile-no")}
          </span>
          <span className="text-value">{props.phone}</span>
        </div>
      </div>
      {
        redirect && (
          <Redirect
            to={{
              pathname:
                "/" + props.countryUrl + "-" + props.language + "/address",
              state: {
                name:
                  window.location.pathname ===
                    "/" + props.countryUrl + "-" + props.language + "/addresses"
                    ? "addresses"
                    : undefined,
                data: {
                  id: props.id,
                  country: props.country,
                  state: props.state,
                  latitude: props.lat,
                  longtitude: props.lng,
                  address1: props.address,
                  // address: props.address,
                  firstName: props.first,
                  lastName: props.last,
                  number: props.phone,
                  label: props.label,
                  address2: props.address2,
                },
              },
            }}
          />
        )
      }
    </div >
  );
}

const mapStateToProps = (state) => {
  return {
    customer_address: state.customerAddress.address,
    countryUrl: state.language.countryUrl,
    language: state.language.language,
  };
};
const mapDispatchToProps = {
  set_address,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomerAddressData);
