import React, { Fragment, useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import Switch from "react-switch";
import { AiFillCreditCard } from "react-icons/ai";
import { IoMdCash } from "react-icons/io";
import { TiTick } from "react-icons/ti";
import { GiWallet } from "react-icons/gi";
import { useTranslation } from "react-i18next";
import MasterCardIcon from "../../../assets/icons/mastercard.png";
import VisaCardIcon from "../../../assets/icons/visaCard.png";

import "../../../assets/styles/component/customer/checkout/paymentmethod.scss";

import MasterCard from "../../../assets/icons/card_2.png";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { apiJson } from "../../../Api";
import { Redirect } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import { connect } from "react-redux";
import { set_cart_items } from "../../../redux/actions/CartAction";
import moment from "moment";

const PaymentMethod = (props) => {
  const [dataChanged, setDataChanged] = useState(false);
  const [couponExpired, setCoupopnExpired] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const { t } = useTranslation();
  const [redirect, setRedirect] = useState(false);
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState({});
  const [active, setActive] = useState("card");
  const [expiry_month, setExpiryMonth] = useState("");
  const [expiry_month_error, setExpiryMonthError] = useState("");
  const [expiry_year, setExpiryYear] = useState("");
  const [expiry_year_error, setExpiryYearError] = useState("");

  const handleChange = () => {
    setIsChecked(!isChecked);
  };

  const [card, setCard] = useState({});
  const [cardStatus, setCardStatus] = useState({
    wallet_payment_option: false,
    card_payment_option: true,
    cash_on_delivery_payment_option: true,
  });
  const [walletAmount, setWalletAmount] = useState(0);
  const [cardNum, setCardNum] = useState(null);
  const [cardExpiry, setExpiry] = useState("");
  const [cardCode, setCode] = useState(null);
  let message = "";
  const showError = () => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  useEffect(() => {
    if (
      isChecked &&
      parseFloat(walletAmount.amount) >= parseFloat(props.grand_total)
    ) {
      setActive("cash");
    } else if (
      isChecked &&
      parseFloat(walletAmount.amount) < parseFloat(props.grand_total)
    ) {
      setActive("card");
    }
    if (props.is_delivery && props.grand_total >= 249) {
      setActive("card");
    }
  }, [isChecked, props.grand_total]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (props.auth) {
      setUser(props.auth);
      apiJson.payments.getPaymentData().then((response) => {
        if (response.success) {
          response.data.map(
            (card) =>
              card.default &&
              parseInt(
                moment(
                  card.expiry_date.split("/")[0] +
                  "/01/" +
                  card.expiry_date.split("/")[1]
                ).diff(moment(), "months", true)
              ) > -1 &&
              setCard(card)
          );
        } else if (!response.status) {
          message = response.message // eslint-disable-line react-hooks/exhaustive-deps
            ? response.message !== ""
              ? response.message
              : t("errors.wrong")
            : t("errors.wrong");
          showError();
        } else {
          if (response.status >= 400 && response.status < 500) {
            message = response.message
              ? response.message
              : response.errors
                ? response.errors
                : t("errors.wrong");
            showError();
          } else {
            message = response.message
              ? response.message !== ""
                ? response.message
                : t("errors.wrong")
              : t("errors.wrong");
            showError();
          }
        }
      });

      apiJson.checkout.paymentCard().then((response) => {
        if (response.success) {
          response.data.card_payment_option
            ? setActive("card")
            : setActive("cash");
          setCardStatus(response.data);
        } else if (!response.status) {
          message = response.message
            ? response.message !== ""
              ? response.message
              : t("errors.wrong")
            : t("errors.wrong");
          showError();
        } else {
          if (response.status >= 400 && response.status < 500) {
            message = response.message
              ? response.message
              : response.errors
                ? response.errors
                : t("errors.wrong");
            showError();
          } else {
            message = response.message
              ? response.message !== ""
                ? response.message
                : t("errors.wrong")
              : t("errors.wrong");
            showError();
          }
        }
      });
      apiJson.checkout.walletPayment(props.countryId).then((response) => {
        if (response.success) {
          setWalletAmount(response.data);
        } else if (!response.status) {
          message = response.message
            ? response.message !== ""
              ? response.message
              : t("errors.wrong")
            : t("errors.wrong");
          showError();
        } else {
          if (response.status >= 400 && response.status < 500) {
            message = response.message
              ? response.message
              : response.errors
                ? response.errors
                : t("errors.wrong");
            showError();
          } else {
            message = response.message
              ? response.message !== ""
                ? response.message
                : t("errors.wrong")
              : t("errors.wrong");
            showError();
          }
        }
      });
    } else {
      setCardStatus({ ...cardStatus, wallet_payment_option: false });
      setIsChecked(false);
    }
  }, []);

  useEffect(() => {
    if (!props.is_delivery) {
      setActive("card")
    }
  }, [props.is_delivery]);

  const handleSubmit = () => {
    if (!props.is_delivery) {
      if (!props.customer_name) {
        message = "Customer name is required.";
        showError();
        return;
      }
      if (!props.customer_contact || props.customer_contact == "971") {
        message = "Customer contact is required.";
        showError();
        return;
      }
    }

    if (props.is_delivery) {
      if (props.order?.createdAddress?.address) {
        let cardData = card.card_number
          ? {
            payment_card_id: card.id,
          }
          : {
            card_number: cardNum,
            // expiry_date: cardExpiry
            //   ? cardExpiry.split("-")[1] +
            //   "/" +
            //   cardExpiry.split("-")[0].slice(2, 4)
            //   : null,
            expiry_date: expiry_month + "/" + expiry_year,
            cvv: cardCode,
          };
        setLoading(true);

        active === "card"
          ? apiJson.checkout
            .placeOrder(
              {
                payment: {
                  ...cardData,
                  use_card:
                    isChecked &&
                      parseFloat(walletAmount.amount) >=
                      parseFloat(props.grand_total)
                      ? false
                      : active === "card"
                        ? true
                        : false,
                  use_wallet: isChecked,
                  cash_on_delivery:
                    isChecked &&
                      parseFloat(walletAmount.amount) >=
                      parseFloat(props.grand_total)
                      ? false
                      : active === "card"
                        ? false
                        : true,
                },
                customer_name: props.customer_name,
                customer_contact: props.customer_contact
              },
              props.id,
              props.countryId
            )
            .then((response) => {
              setLoading(false);
              if (response.success) {
                if (localStorage.getItem("guest_user_address")) {
                  localStorage.removeItem("guest_user_address");
                }
                setRedirect(true);
              } else if (!response.status) {
                message = response.message
                  ? response.message !== ""
                    ? response.message
                    : t("errors.wrong")
                  : t("errors.wrong");
                showError();
              } else {
                if (response.status >= 400 && response.status < 500) {
                  message = response.message
                    ? response.message
                    : response.errors
                      ? response.errors
                      : t("errors.wrong");
                  showError();
                } else {
                  message = response.message
                    ? response.message !== ""
                      ? response.message
                      : t("errors.wrong")
                    : t("errors.wrong");
                  showError();
                }
              }
            })
          : apiJson.checkout
            .placeOrder(
              {
                payment: {
                  use_card:
                    isChecked &&
                      parseFloat(walletAmount.amount) >=
                      parseFloat(props.grand_total)
                      ? false
                      : active === "card"
                        ? true
                        : false,
                  use_wallet: isChecked,
                  cash_on_delivery:
                    isChecked &&
                      parseFloat(walletAmount.amount) >=
                      parseFloat(props.grand_total)
                      ? false
                      : active === "card"
                        ? false
                        : true,
                },
                customer_name: props.customer_name,
                customer_contact: props.customer_contact
              },
              props.id,
              props.countryId
            )
            .then((response) => {
              setLoading(false);
              if (response.success) {
                if (localStorage.getItem("guest_user_address")) {
                  localStorage.removeItem("guest_user_address");
                }
                setRedirect(true);
              } else if (!response.status) {
                message = response.message
                  ? response.message !== ""
                    ? response.message
                    : t("errors.wrong")
                  : t("errors.wrong");
                showError();
              } else {
                if (response.status >= 400 && response.status < 500) {
                  message = response.message
                    ? response.message
                    : response.errors
                      ? response.errors
                      : t("errors.wrong");
                  showError();
                } else {
                  message = response.message
                    ? response.message !== ""
                      ? response.message
                      : t("errors.wrong")
                    : t("errors.wrong");
                  showError();
                }
              }
            });
      }
      else {
        message = "Select address to place order";
        showError();
      }
    }
    else {
      if (props.order?.createdAddress?.pickup_address) {
        let cardData = card.card_number
          ? {
            payment_card_id: card.id,
          }
          : {
            card_number: cardNum,
            // expiry_date: cardExpiry
            //   ? cardExpiry.split("-")[1] +
            //   "/" +
            //   cardExpiry.split("-")[0].slice(2, 4)
            //   : null,
            expiry_date: expiry_month + "/" + expiry_year,
            cvv: cardCode,
          };
        setLoading(true);

        active === "card"
          ? apiJson.checkout
            .placeOrder(
              {
                payment: {
                  ...cardData,
                  use_card:
                    isChecked &&
                      parseFloat(walletAmount.amount) >=
                      parseFloat(props.grand_total)
                      ? false
                      : active === "card"
                        ? true
                        : false,
                  use_wallet: isChecked,
                  cash_on_delivery:
                    isChecked &&
                      parseFloat(walletAmount.amount) >=
                      parseFloat(props.grand_total)
                      ? false
                      : active === "card"
                        ? false
                        : true,
                },
                customer_name: props.customer_name,
                customer_contact: props.customer_contact
              },
              props.id,
              props.countryId
            )
            .then((response) => {
              setLoading(false);
              if (response.success) {
                if (localStorage.getItem("guest_user_address")) {
                  localStorage.removeItem("guest_user_address");
                }
                setRedirect(true);
              } else if (!response.status) {
                message = response.message
                  ? response.message !== ""
                    ? response.message
                    : t("errors.wrong")
                  : t("errors.wrong");
                showError();
              } else {
                if (response.status >= 400 && response.status < 500) {
                  message = response.message
                    ? response.message
                    : response.errors
                      ? response.errors
                      : t("errors.wrong");
                  showError();
                } else {
                  message = response.message
                    ? response.message !== ""
                      ? response.message
                      : t("errors.wrong")
                    : t("errors.wrong");
                  showError();
                }
              }
            })
          : apiJson.checkout
            .placeOrder(
              {
                payment: {
                  use_card:
                    isChecked &&
                      parseFloat(walletAmount.amount) >=
                      parseFloat(props.grand_total)
                      ? false
                      : active === "card"
                        ? true
                        : false,
                  use_wallet: isChecked,
                  cash_on_delivery:
                    isChecked &&
                      parseFloat(walletAmount.amount) >=
                      parseFloat(props.grand_total)
                      ? false
                      : active === "card"
                        ? false
                        : true,
                },
                customer_name: props.customer_name,
                customer_contact: props.customer_contact
              },
              props.id,
              props.countryId
            )
            .then((response) => {
              setLoading(false);
              if (response.success) {
                if (localStorage.getItem("guest_user_address")) {
                  localStorage.removeItem("guest_user_address");
                }
                setRedirect(true);
              } else if (!response.status) {
                message = response.message
                  ? response.message !== ""
                    ? response.message
                    : t("errors.wrong")
                  : t("errors.wrong");
                showError();
              } else {
                if (response.status >= 400 && response.status < 500) {
                  message = response.message
                    ? response.message
                    : response.errors
                      ? response.errors
                      : t("errors.wrong");
                  showError();
                } else {
                  message = response.message
                    ? response.message !== ""
                      ? response.message
                      : t("errors.wrong")
                    : t("errors.wrong");
                  showError();
                }
              }
            });
      }
      else {
        message = "Select pickup address to place order";
        showError();
      }
    }
  };

  useEffect(() => props.triggerWallet(isChecked), [isChecked]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    props.cart_items.cart_data_changed &&
      setDataChanged(props.cart_items?.cart_data_changed);

    props.cart_items.cart_data_changed &&
      apiJson.cart.updateCartReset(props.countryId);
    props.cart_items.cart_data_changed &&
      props.set_cart_items({
        cart_items: {
          ...props.cart_items,
          cart_data_changed: false,
        },
      });
  }, [props.cart_items.cart_data_changed]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    props.cart_items.expired_coupon_removed &&
      setCoupopnExpired(props.cart_items?.expired_coupon_removed);

    props.cart_items.expired_coupon_removed &&
      apiJson.cart.discountReset(props.countryId);
    props.cart_items.expired_coupon_removed &&
      props.set_cart_items({
        cart_items: {
          ...props.cart_items,
          expired_coupon_removed: false,
        },
      });
  }, [props.cart_items.expired_coupon_removed]); // eslint-disable-line react-hooks/exhaustive-deps

  function GetCardType(number) {
    // visa
    var re = new RegExp("^4");
    if (number.match(re) != null)
      return "Visa";

    // Mastercard 
    // Updated for Mastercard 2017 BINs expansion
    if (/^(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))$/.test(number))
      return "MasterCard";

    // AMEX
    re = new RegExp("^3[47]");
    if (number.match(re) != null)
      return "AMEX";

    // Discover
    re = new RegExp("^(6011|622(12[6-9]|1[3-9][0-9]|[2-8][0-9]{2}|9[0-1][0-9]|92[0-5]|64[4-9])|65)");
    if (number.match(re) != null)
      return "Discover";

    // Diners
    re = new RegExp("^36");
    if (number.match(re) != null)
      return "Diners";

    // Diners - Carte Blanche
    re = new RegExp("^30[0-5]");
    if (number.match(re) != null)
      return "Diners - Carte Blanche";

    // JCB
    re = new RegExp("^35(2[89]|[3-8][0-9])");
    if (number.match(re) != null)
      return "JCB";

    // Visa Electron
    re = new RegExp("^(4026|417500|4508|4844|491(3|7))");
    if (number.match(re) != null)
      return "Visa Electron";

    return "";
  }

  const onMonthChanged = (e) => {
    if (!e.target.value) {
      setExpiryMonth("");
      setExpiryMonthError("*Expiry Month is required.");
    }
    if (!isNaN(e.target.value)) {
      setExpiryMonthError("");
      // var month = parseInt(e.target.value);
      var month = e.target.value;

      if (month.toString().length <= 2) {
        setExpiryMonth(month);
        if (parseInt(month) > 0 && parseInt(month) <= 12) {
        }
        else {
          setExpiryMonthError("*Invalid Month.");
        }
      }
    }
  }

  const onYearChanged = (e) => {
    if (!e.target.value) {
      setExpiryYear("");
      setExpiryYearError("*Expiry Year is required.");
    }
    if (!isNaN(e.target.value)) {
      setExpiryYearError("");
      var year = parseInt(e.target.value);

      if (year.toString().length <= 2) {
        setExpiryYear(year);
      }
    }
  }

  return (
    <Fragment>
      <div className="payment-method-desktop">
        {/* <Row noGutters={true} className="payment-row mb-4">
          <Col md={12} className="d-flex">
            <h2 className="title mb-0">{t("checkout.page-title")}</h2>
          </Col>
        </Row> */}
        <Row noGutters={true} className="payment-row mt-4 mb-2">
          <Col md={12} className="d-flex">
            <div className="payment-method-text mt-2 mb-2 text-uppercase">
              {t("checkout.payment-method")}
            </div>
          </Col>
        </Row>
        {user && cardStatus.wallet_payment_option && (
          <Row
            noGutters={true}
            className="wallet-row payment-row bg-white margin-bottom-4 py-3 br-2"
          >
            <Col
              md={12}
              className="d-flex justify-content-between align-items-center px-3"
            >
              <div className="d-flex align-items-center">
                <Switch
                  onChange={handleChange}
                  disabled={!cardStatus.wallet_payment_option}
                  checked={isChecked}
                  offColor="#CCD7E4"
                  offHandleColor="#FFFFFF"
                  uncheckedIcon={false}
                  checkedIcon={false}
                  uncheckedHandleIcon={
                    <TiTick
                      style={{
                        color: "#38AE04",
                        position: "absolute",
                        top: "1px",
                        left: "2px",
                      }}
                    />
                  }
                  checkedHandleIcon={
                    <TiTick
                      style={{
                        color: "#38AE04",
                        position: "absolute",
                        top: "1px",
                        left: "2px",
                      }}
                    />
                  }
                  activeBoxShadow="none"
                  height={22}
                  width={43}
                />
                <div className="pay-with-alisuq-wallet">
                  {t("checkout.pay-with-alisuq")}
                  <br />
                  <span className="available-balance d-block">
                    {t("checkout.available-balance")}: {props.currency}{" "}
                    {walletAmount.amount}
                  </span>
                </div>
              </div>

              <GiWallet className="wallet-image mr-2" />
            </Col>
          </Row>
        )}
        <div
          className={`${isChecked &&
            parseFloat(walletAmount.amount) >= parseFloat(props.grand_total)
            ? "d-none"
            : ""
            }`}
        >
          {
            props.grand_total <= 249 && props.is_delivery ?
              <Row
                noGutters={true}
                className="payment-options payment-row margin-bottom-4 br-2"
              >
                <Col
                  md={12}
                  className="d-flex justify-content-between align-items-center"
                >
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      setActive("card");
                    }}
                    className={`payment-button card-button ${active === "cash" ? "gray-button" : ""
                      }`}
                  >
                    <AiFillCreditCard className="card-icon" />
                    {t("checkout.pay-with-card")}
                  </button>
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      setActive("cash");
                    }}
                    className={`payment-button cash-button ${active === "card" ? "gray-button" : ""
                      }`}
                  >
                    <IoMdCash className="cash-icon" />
                    {t("checkout.pay-with-cash")}
                  </button>
                </Col>
              </Row>
              :
              <></>
          }
          <Row
            noGutters={true}
            className={`payment-card card-input payment-row bg-white pt-3 pb-3 mb-3 br-2 ${active !== "card" ? "d-none" : ""
              }`}
          >
            {card.card_number ? (
              <Col md={12} className="d-flex flex-column px-3 mb-3">
                <div className="cards-div">
                  <div className="radio-input">
                    <input
                      type="radio"
                      checked={cardStatus.card_payment_option}
                      className="radio"
                      readOnly
                    />
                  </div>
                  <div className="img-and-label-input">
                    <div className="img-and-label">
                      <img
                        src={MasterCard}
                        alt="mastercard-logo"
                        width="50px"
                        height="35px"
                      />
                      <label className="card-ending-in">
                        {t("checkout-mobile.payment-method.card-end")}{" "}
                        {card?.card_number?.slice(
                          card.card_number?.length - 4,
                          card.card_number?.length
                        )}
                      </label>
                    </div>
                  </div>
                </div>
              </Col>
            ) : (
              <>
                <Col md={12} className="d-flex flex-column px-3 mb-3">
                  <label className="card-number text-uppercase">
                    {t("checkout.card-number")}
                  </label>
                  <input
                    disabled={active === "cash" ? true : false}
                    className="w-100"
                    placeholder="**** **** **** ****"
                    type="text"
                    value={cardNum ? cardNum : ""}
                    onChange={(e) => {
                      e.target.value.length < 17 && setCardNum(e.target.value);
                    }
                    }
                  />
                  {
                    cardNum ?
                      <img
                        src={
                          GetCardType(cardNum) === "Visa"
                            ? VisaCardIcon
                            : GetCardType(cardNum) === "MasterCard"
                              ? MasterCardIcon
                              : ""
                        }
                        className={
                          GetCardType(cardNum) === "Visa"
                            ? "card-img-visa"
                            : GetCardType(cardNum) === "MasterCard"
                              ? "card-img-mc"
                              : ""
                        }
                        alt=""
                      />
                      :
                      <></>
                  }
                </Col>
                <Col md={12} className="d-flex align-items-center px-3">
                  <div className="d-flex flex-column">
                    <label className="text-uppercase">
                      {t("checkout.expiry-date")}
                    </label>
                    {/* <input
                      disabled={active === "cash" ? true : false}
                      className="w-100 with-border bg-white"
                      type="month"
                      min={
                        moment().year() +
                        "-" +
                        (moment().month() < 9
                          ? "0" + (moment().month() + 1)
                          : moment().month() + 1)
                      }
                      placeholder="MM/YY"
                      value={cardExpiry}
                      onChange={(e) => setExpiry(e.target.value)}
                    /> */}
                    <div
                      disabled={active === "cash" ? true : false}
                      className="expiry-input-div"
                      style={{ display: "flex" }}
                    >
                      <input className="expiry-input"
                        placeholder="MM"
                        value={expiry_month}
                        onChange={(e) => onMonthChanged(e)} />
                      <div className="slash">/</div>
                      <input className="expiry-input"
                        placeholder="YY"
                        value={expiry_year}
                        onChange={(e) => onYearChanged(e)}
                      />
                    </div>
                  </div>
                  <div className="cvv-input d-flex flex-column ml-4">
                    <label className="text-uppercase">Cvv</label>
                    <div className="input-wrapper">
                      <input
                        disabled={active === "cash" ? true : false}
                        className="w-100"
                        type="number"
                        placeholder="123"
                        value={cardCode ? cardCode : ""}
                        onChange={(e) =>
                          e.target.value.length < 4 && setCode(e.target.value)
                        }
                      />
                    </div>
                  </div>
                </Col>
                <p style={{ margin: "0px", marginLeft: "15px", color: "red" }}>{expiry_month_error}</p>
                <p style={{ margin: "0px", marginLeft: "15px", color: "red" }}>{expiry_year_error}</p>
              </>
            )}
          </Row>
        </div>
        {dataChanged && (
          <Row noGutters={true} className="">
            <Col md={12}>
              <div className="data-change">
                <div className="w-90 ">
                  <div>{t("data-changed.quantity-changed")}</div>
                  <div className="mt-1">{t("data-changed.prices-changed")}</div>
                  <div className="mt-1">{t("data-changed.review-cart")}</div>
                </div>
                <div className="w-8  dismiss-border dismiss m-auto">
                  <span
                    className="pointer"
                    onClick={() => setDataChanged(false)}
                  >
                    {" "}
                    {t("coupon-expire.dismiss")}
                  </span>
                </div>
              </div>
            </Col>
          </Row>
        )}
        {couponExpired && (
          <Row noGutters={true} className="">
            <Col md={12}>
              <div className="data-change">
                <div className="w-90 ">
                  <div>{t("coupon-expire.coupon-expired")}</div>

                  <div className="mt-1">{t("coupon-expire.review-cart")}</div>
                </div>
                <div className="w-8  dismiss-border dismiss m-auto">
                  <span
                    className="pointer"
                    onClick={() => setCoupopnExpired(false)}
                  >
                    {" "}
                    {t("coupon-expire.dismiss")}
                  </span>
                </div>
              </div>
            </Col>
          </Row>
        )}
        <Row noGutters={true} className="payment-row">
          <Col md={12} className="d-flex justify-content-center">
            <button
              onClick={(e) => {
                e.preventDefault();
                if (active === "card") {
                  if (card.card_number) {
                    handleSubmit();
                  } else if (
                    cardNum !== null &&
                    cardNum.length === 16 &&
                    expiry_month.toString().length > 0 &&
                    expiry_month > 0 && expiry_month <= 12 &&
                    expiry_year.toString().length > 0 &&
                    expiry_year.toString() >= parseInt(moment().year().toString().slice(-2)) &&
                    // cardExpiry.length > 0 &&
                    cardCode?.length === 3
                  ) {
                    handleSubmit();
                  } else {
                    message = "Please add valid card details";
                    showError();
                  }
                } else {
                  handleSubmit();
                }
              }}
              disabled={
                props.is_delivery ?
                  props.order?.createdAddress ?
                    active === "card" ?
                      cardNum?.length &&
                        // cardNum.length === 16 &&
                        expiry_month.toString().length > 0 &&
                        // expiry_month > 0 && expiry_month <= 12 &&
                        expiry_year.toString().length > 0 &&
                        // expiry_year.toString() >= parseInt(moment().year().toString().slice(-2)) &&
                        // cardExpiry.length > 0 &&
                        cardCode?.length
                        ?
                        false
                        :
                        true
                      :
                      active === "cash" || isChecked ?
                        false
                        :
                        true
                    :
                    true
                  :
                  props.order?.createdAddress?.pickup_address ?
                    active === "card" ?
                      cardNum?.length &&
                        // cardNum.length === 16 &&
                        expiry_month.toString().length > 0 &&
                        // expiry_month > 0 && expiry_month <= 12 &&
                        expiry_year.toString().length > 0 &&
                        // expiry_year.toString() >= parseInt(moment().year().toString().slice(-2)) &&
                        // cardExpiry.length > 0 &&
                        cardCode?.length
                        ?
                        false
                        :
                        true
                      :
                      active === "cash" || isChecked ?
                        false
                        :
                        true
                    :
                    true
              }
              className={
                "w-100 order-button text-uppercase" +
                (
                  props.is_delivery ?
                    props.order?.createdAddress ?
                      active === "card" ?
                        cardNum?.length &&
                          // cardNum.length === 16 &&
                          expiry_month.toString().length > 0 &&
                          // expiry_month > 0 && expiry_month <= 12 &&
                          expiry_year.toString().length > 0 &&
                          // expiry_year.toString() >= parseInt(moment().year().toString().slice(-2)) &&
                          // cardExpiry.length > 0 &&
                          cardCode?.length
                          ?
                          " active-btn"
                          :
                          " inactive-btn"
                        :
                        active === "cash" || isChecked ?
                          " active-btn"
                          :
                          " inactive-btn"
                      :
                      " inactive-btn"
                    :
                    props.order?.createdAddress?.pickup_address ?
                      active === "card" ?
                        cardNum?.length &&
                          // cardNum.length === 16 &&
                          expiry_month.toString().length > 0 &&
                          // expiry_month > 0 && expiry_month <= 12 &&
                          expiry_year.toString().length > 0 &&
                          // expiry_year.toString() >= parseInt(moment().year().toString().slice(-2)) &&
                          // cardExpiry.length > 0 &&
                          cardCode?.length
                          ?
                          " active-btn"
                          :
                          " inactive-btn"
                        :
                        active === "cash" || isChecked ?
                          " active-btn"
                          :
                          " inactive-btn"
                      :
                      " inactive-btn"
                )
              }
            >
              {loading ? (
                <div className="d-flex justify-content-center align-items-center w-100 h-100">
                  <ClipLoader color="#fff" loading size={20} />
                </div>
              ) : (
                t("checkout.btn-place-order")
              )}
            </button>
          </Col>
        </Row>
        {redirect && (
          <Redirect
            push
            to={{
              pathname:
                "/" + props.countryUrl + "-" + props.language + "/thank-you",
              state: props.id,
            }}
          />
        )}
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    order: state.cart.order,
    direction: state.language.direction,
    countryId: state.language.countryId,
    auth: state.auth.isSignedIn,
    cart_items: state.cart.cart_items,
    countryUrl: state.language.countryUrl,
    language: state.language.language,
    grand_total: state.cart.grand_total,
  };
};

const mapDispatchToProps = { set_cart_items };

export default connect(mapStateToProps, mapDispatchToProps)(PaymentMethod);
