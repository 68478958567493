import React, { Fragment, useState } from "react";

import "../../assets/styles/mobile/paymentaddnewcard.scss";
import { connect } from "react-redux";
import MobileLayoutRenderer from "./layout/MobileLayoutRenderer";
import { useTranslation } from "react-i18next";
import { apiJson } from "../../Api";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Redirect } from "react-router-dom";
import moment from "moment";
import { ClipLoader } from "react-spinners";

const PaymentAddNewCard = (props) => {
  const { t } = useTranslation();
  const [cardNumber, setCardNumber] = useState(null);
  const [expiry, setExpiry] = useState(null);
  const [securityNumber, setSecurityNumber] = useState();
  const [adding, setAdding] = useState(false);
  const [redirect, setRedirect] = useState(false);

  let message = "";
  const showError = () => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const addCard = () => {
    setAdding(true);
    apiJson.checkout
      .cardVar({
        payment_card: {
          card_number: cardNumber,
          expiry_date: expiry
            ? expiry.split("-")[1] + "/" + expiry.split("-")[0].slice(2, 4)
            : null,
          cvv: securityNumber,
        },
      })
      .then((response) => {
        setAdding(false);
        if (response.success) {
          setRedirect(true);
        } else if (!response.status) {
          message = response.message
            ? response.message !== ""
              ? response.message
              : t("errors.wrong")
            : t("errors.wrong");
          showError();
        } else {
          if (response.status >= 400 && response.status < 500) {
            message = response.message
              ? response.message
              : response.errors
              ? response.errors
              : t("errors.wrong");
            showError();
          } else {
            message = response.message
              ? response.message !== ""
                ? response.message
                : t("errors.wrong")
              : t("errors.wrong");
            showError();
          }
        }
      });
  };

  return (
    <>
      {props.width === "sm" && (
        <MobileLayoutRenderer
          NavbarTop={true}
          BackBtn={false}
          CancelBtn={true}
          NavbarBottom={false}
        >
          <Fragment>
            <div className="container payment-add-new-card-mob">
              <div className="add-new-div">
                <span className="add-new-label">
                  {t("add-new-payment-mobile.add-new-card")}
                </span>
              </div>

              <div className="add-new-payment-method">
                <div className="card-number-div">
                  <label className="card-number-label">
                    {t("add-new-payment-mobile.card-num")}
                  </label>
                  <div className="card-number-input">
                    <input
                      type="text"
                      maxLength={16}
                      className="card-input"
                      placeholder={t("add-new-payment-mobile.card-num-ph")}
                      value={cardNumber}
                      onChange={(e) =>
                        e.target.value.length < 17 &&
                        setCardNumber(e.target.value)
                      }
                    />
                  </div>
                </div>
                <div className="expiry-and-security-no">
                  <div className="left-expiry">
                    <label className="expiry-label">
                      {t("add-new-payment-mobile.expiry")}
                    </label>
                    <div className="month-year">
                      <input
                        type="month"
                        min={
                          moment().year() +
                          "-" +
                          (moment().month() < 9
                            ? "0" + (moment().month() + 1)
                            : moment().month() + 1)
                        }
                        className="month-year-input"
                        value={expiry}
                        onChange={(e) => setExpiry(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="right-security-no">
                    <label className="security-label">
                      {t("add-new-payment-mobile.cvv")}
                    </label>
                    <div className="secure-code">
                      <input
                        type="text"
                        className="secure-code-input"
                        placeholder="Secure Code"
                        maxLength={3}
                        value={securityNumber}
                        onChange={(e) =>
                          e.target.value.length < 4 &&
                          setSecurityNumber(e.target.value)
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div onClick={addCard} className="add-card ">
                {adding ? (
                  <div className="d-flex justify-content-center align-items-center w-100">
                    <ClipLoader color="#fff" loading size={20} />
                  </div>
                ) : (
                  <span> Add Card</span>
                )}
              </div>
            </div>
          </Fragment>
        </MobileLayoutRenderer>
      )}
      {redirect && (
        <Redirect
          push
          to={"/" + props.countryUrl + "-" + props.language + "/payments"}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    width: state.layout.screenWidth,
    countryUrl: state.language.countryUrl,
    language: state.language.language,
  };
};

export default connect(mapStateToProps, null)(PaymentAddNewCard);
