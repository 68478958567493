import React from "react";

import "../../../assets/styles/component/customer/itemDetail/ItemStatus.scss";
import sellerImg from "../../../assets/images/seller.svg";
import free_returns_usp from "../../../assets/images/free_returns_usp.svg";
import warranty from "../../../assets/images/warranty.svg";
import trusted_shipping_usp_v2 from "../../../assets/images/trusted_shipping_usp_v2.svg";
import constactless_delivery_usp from "../../../assets/images/constactless_delivery_usp.svg"; // eslint-disable-next-line
import vip_shipping from "../../../assets/images/vip_shipping.svg";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

function ItemStatusMobile(props) {
  const { t } = useTranslation();
  return (
    <div className="item-status-desktop" style={{ textAlign: "initial" }}>
      <div className="small-screen-none vendure-text w-100 mb-10px">
        {t("item-detail.status.sold-by")}
        <Link
          to={
            "/" +
            props.countryUrl +
            "-" +
            props.language +
            "/vendor/shop/" +
            props.data.store_id
          }
        >
          <span className="anchor-style">{props.data.order_vendor}</span>
        </Link>
      </div>
      <div className="large-screen-none offer-text">
      <span className="inf">Shipping Info:</span>
      {
              (props.data.shipment?.name == "shipment_rule_1" || props.data.shipment?.name == "shipment_rule_2") ?
                "Free shipping on orders above AED " + props.data.shipment?.order_threshold_amount
                :
                props.data.shipment?.name == "shipment_rule_3" ?
                  "Delivery based on truck"
                  :
                  props.data.shipment?.name == "shipment_rule_4" ?
                    "Free shipping on orders above " + props.data.shipment?.order_threshold_weight + " tons"
                    :
                    ""
            }
      </div>
      <div className="large-screen-none offer-text">
      <span className="inf">Sold by:</span> <a
            href={
              "/" +
              props.countryUrl +
              "-" +
              props.language +
              "/vendor/shop/" +
              props.data.store_id
            }
            className="anchor-style"
          >
            {props.data.order_vendor}
          </a>
      </div>
    

   

      <div className="small-screen-none d-flex flex-direction-column mt-3 mb-20px">
        <div className="w-10">
          <img width="100%" height="100%" src={free_returns_usp} alt="" />
        </div>

        <div className="ml-3 w-75">
          <div className="vendure-text w-100">
            {t("item-detail.status.free-label")}
          </div>
          <div className="returns-detail-text w-100">
            {t("item-detail.status.get-free-items")}
          </div>
        </div>
      </div>

      <div className="small-screen-none d-flex flex-direction-column mb-20px freeunder">
        <div className="w-10">
          <img
            width="100%"
            height="100%"
            src={trusted_shipping_usp_v2}
            alt=""
          />
        </div>

        <div className="ml-3 w-75">
          <div className="vendure-text w-100">
            {t("item-detail.status.trusted-label")}
          </div>
          <div className="returns-detail-text w-100">
            {t("item-detail.status.trusted-text")}
          </div>
        </div>
      </div>

      <div className="small-screen-none d-flex flex-direction-column mb-20px">
        <div className="w-10">
          <img
            width="100%"
            height="100%"
            src={constactless_delivery_usp}
            alt=""
          />
        </div>

        <div className="ml-3 w-75">
          <div className="vendure-text w-100">
            {t("item-detail.status.contactless-label")}
          </div>
          <div className="returns-detail-text w-100">
            {t("item-detail.status.contactless-text")}
          </div>
        </div>
      </div>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    language: state.language.language,
    countryUrl: state.language.countryUrl,
  };
};

export default connect(mapStateToProps, null)(ItemStatusMobile);
