import React from "react";
import { connect } from "react-redux";
import "../../../assets/styles/component/mobile/footer-page.scss";
import pagesbannerimg from "../../../assets/images/footer-pages-banners/Privacy-Policy.jpg";

function PrivacyPolicyMobileApp(props) {
  return (
    <div className="home-mobile">
      <div className="body" style={{ background: "#F5F5F5" }}>
        <div className="pagesbanner">
          <img
            src={pagesbannerimg}
            alt="category"
            style={{
              width: "100%",
              height: "100%",
              maxHeight: "400px",
            }}
          ></img>
        </div>
        <div className="content-wrapper all-page-container">
          <h4 className="footer-page-heading mycenter ret">
            <span style={{ fontSize: "10px", display: "block" }}>
              {" "}
              Last Updated: 01.10.2021
            </span>
          </h4>

          <p className="mycenter">
            This Privacy Policy describes how your personal information is
            collected, used, and shared when you visit or make a purchase from
            www.alisuq.com
          </p>

          <p>
            <b>1. Personal information we collect </b>
          </p>
          <p>
            When you visit the Site, we automatically collect certain
            information about your device, including information about your
            web browser, IP address, time zone, and some of the cookies that
            are installed on your device. Additionally, as you browse the
            Site, we collect information about the individual web pages or
            products that you view, what websites or search terms referred you
            to the Site, and information about how you interact with the Site.
            We refer to this automatically-collected information as “Device
            Information”.
          </p>

          <p>
            <b>
              {" "}
              2. We collect Device Information using the following
              technologies:{" "}
            </b>
          </p>
          <p>
            ● “Cookies” are data files that are placed on your device or
            computer and often include an anonymous unique identifier. For
            more information about cookies, and how to disable cookies, visit
            http://www.allaboutcookies.org.
          </p>
          <p>
            ● “Log files” track actions occurring on the Site, and collect
            data including your IP address, browser type, Internet service
            provider, referring/exit pages, and date/time stamps.{" "}
          </p>
          <p>
            ● “Web beacons”, “tags”, and “pixels” are electronic files used to
            record information about how you browse the Site.{" "}
          </p>
          <p>
            Additionally when you make a purchase or attempt to make a
            purchase through the Site, we collect certain information from
            you, including your name, billing address, shipping address,
            payment information (including credit card numbers, email address,
            and phone number. We refer to this information as “Order
            Information”. When we talk about “Personal Information” in this
            Privacy Policy, we are talking both about Device Information and
            Order Information.
          </p>
          <p>
            <b> 3. How do we use your personal information? </b>
          </p>
          <p>
            We use the Order Information that we collect generally to fulfill
            any orders placed through the Site (including processing your
            payment information, arranging for shipping, and providing you
            with invoices and/or order confirmations). Additionally, we use
            this Order Information to:{" "}
          </p>
          <p>● Communicate with you;</p>
          <p>● Screen our orders for potential risk or fraud.</p>
          <p>
            ● When in line with the preferences you have shared with us,
            provide you with information or advertising relating to our
            products or services. We use the Device Information that we
            collect to help us screen for potential risk and fraud, and more
            generally to improve and optimize our Site (for example, by
            generating analytics about how our customers browse and interact
            with the Site, and to assess the success of our marketing and
            advertising campaigns).
          </p>

          <p>
            <b> 4. Sharing your personal Information </b>
          </p>
          <p>
            We share your Personal Information with third parties to help us
            use your Personal Information, as described above. For example,
          </p>
          <p>
            ● We use 3rd third party services to power our online store: you
            can read more about how AWS uses your Personal Information here:
            ……………………………………………..{" "}
          </p>
          <p>
            ● We also use Google Analytics to help us understand how our
            customers use the site: you can read more about how Google uses
            your Personal Information here:
            https://www.google.com/intl/en/policies/privacy/.{" "}
          </p>
          <p>
            ● You can also opt-out of Google Analytics here:
            https://tools.google.com/dlpage/gaoptout.{" "}
          </p>
          <p>
            Finally, we may also share your Personal Information to comply
            with applicable laws and regulations, to respond to a subpoena,
            search warrant or other lawful request for information we receive,
            or to otherwise protect our rights.{" "}
          </p>

          <p>
            <b>5. Behavioral advertising</b>
          </p>

          <p>
            As described above, we use your Personal Information to provide
            you with targeted advertisements or marketing communications we
            believe may be of interest to you. For more information about how
            targeted advertising works, you can visit the Network Advertising
            Initiative’s (“NAI”) educational page at
            http://www.networkadvertising.org/understanding-online-advertising/how-does-it-work.{" "}
          </p>
          <p>
            You can opt out of targeted advertising by using the links below:{" "}
          </p>
          <p>● Facebook: https://www.facebook.com/settings/?tab=ads </p>
          <p>● Google: https://www.google.com/settings/ads/anonymous </p>
          <p>
            ● Additionally, you can opt out of some of these services by
            visiting the Digital Advertising Alliance’s opt-out portal at:
            http://optout.aboutads.info/.{" "}
          </p>

          <p>
            As described above, we use your Personal Information to provide
            you with targeted advertisements or marketing communications we
            believe may be of interest to you. For more information about how
            targeted advertising works, you can visit the Network Advertising
            Initiative’s (“NAI”) educational page at
            http://www.networkadvertising.org/understanding-online-advertising/how-does-it-work.{" "}
          </p>
          <p>
            You can opt out of targeted advertising by using the links below:{" "}
          </p>
          <p>● Facebook: https://www.facebook.com/settings/?tab=ads </p>
          <p>● Google: https://www.google.com/settings/ads/anonymous </p>
          <p>
            ● Additionally, you can opt out of some of these services by
            visiting the Digital Advertising Alliance’s opt-out portal at:
            http://optout.aboutads.info/.{" "}
          </p>

          <p>
            <b>6. Do not track </b>
          </p>

          <p>
            Please note that we do not alter our Site’s data collection and
            use practices when we see a Do Not Track signal from your browser.
          </p>

          <p>
            <b>7. Your rights </b>
          </p>

          <p>
            If you are a European resident, you have the right to access
            personal information we hold about you and to ask that your
            personal information be corrected, updated, or deleted. If you
            would like to exercise this right, please contact us through the
            contact information below. Additionally, if you are a European
            resident we note that we are processing your information in order
            to fulfill contracts we might have with you (for example if you
            make an order through the Site), or otherwise to pursue our
            legitimate business interests listed above. Additionally, please
            note that your information will be transferred outside of Europe,
            including to Canada and the United States.
          </p>

          <p>
            <b>8. Data retention </b>
          </p>

          <p>
            When you place an order through the Site, we will maintain your
            Order Information for our records unless and until you ask us to
            delete this information.{" "}
          </p>

          <p>
            <b> 9. Changes </b>
          </p>
          <p>
            We may update this privacy policy from time to time in order to
            reflect, for example, changes to our practices or for other
            operational, legal or regulatory reasons.
          </p>

          <p>
            <b>10. Contact us </b>
          </p>
          <p>
            For more information about our privacy practices, if you have
            questions, or if you would like to make a complaint, please
            contact us by e‑mail at sales@alisuq.com or by mail using the
            details provided below: Alisuq [Re: Privacy Compliance Officer]
            600 Al Wasl Road - Dubai, Dubai DU, United Arab Emirates
          </p>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    width: state.layout.screenWidth,
  };
};

export default connect(mapStateToProps, null)(PrivacyPolicyMobileApp);
