import React from "react";
import ReviewMob from "../../components/mobile/review/ReviewMob";
import MobileLayoutRenderer from "./layout/MobileLayoutRenderer";

export default function ReviewPageMob() {
  return (
    <div>
      <MobileLayoutRenderer
        NavbarTop={true}
        BackBtn={true}
        CancelBtn={false}
        NavbarBottom={false}
        SearchIcon={true}
      >
        <ReviewMob />
      </MobileLayoutRenderer>
    </div>
  );
}
