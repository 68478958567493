import React, { Fragment, useState } from "react";
import { Link } from 'react-router-dom';
import WebsiteHeader from "../../components/header/WebsiteHeader";
import WebsiteFooter from "../../components/Footer/WebsiteFooter";
import "../../assets/styles/offers-landing-pages/electricalAccessoriesSale.scss";

import ProductList from "../../components/product/ProductList";

import TopBanner1 from "../../assets/images/offers-landing-pages/Schneider-legrand1.png";
import TopBanner2 from "../../assets/images/offers-landing-pages/Schneider-legrand2.jpg";
import TopBanner3 from "../../assets/images/offers-landing-pages/Schneider-legrand3.jpg";
import { Helmet } from 'react-helmet';

import a1 from "../../assets/images/offers-landing-pages/one.png";
import a2 from "../../assets/images/offers-landing-pages/two.png";
import a3 from "../../assets/images/offers-landing-pages/three.png";
import a4 from "../../assets/images/offers-landing-pages/four.png";
import a5 from "../../assets/images/offers-landing-pages/five.png";
import a6 from "../../assets/images/offers-landing-pages/six.png";

import o3 from "../../assets/images/offers-landing-pages/of1.png";
import o4 from "../../assets/images/offers-landing-pages/of2.png";
import o5 from "../../assets/images/offers-landing-pages/of3.png";

import d1 from "../../assets/images/offers-landing-pages/d1.png";
import d2 from "../../assets/images/offers-landing-pages/d2.png";
import d3 from "../../assets/images/offers-landing-pages/d3.png";
import t1 from "../../assets/images/offers-landing-pages/t1.png";

import ua3 from "../../assets/images/offers-landing-pages/ua3.png";
import ua4 from "../../assets/images/offers-landing-pages/ua4.png";
import ua5 from "../../assets/images/offers-landing-pages/ua5.png";
import ua6 from "../../assets/images/offers-landing-pages/ua6.png";



import b2 from "../../assets/images/offers-landing-pages/b2.png";
import b3 from "../../assets/images/offers-landing-pages/b3.png";
import b4 from "../../assets/images/offers-landing-pages/b4.png";
import b5 from "../../assets/images/offers-landing-pages/b5.png";



import { useEffect } from "react";
import $ from "jquery";
import { connect } from "react-redux";
import { apiJson } from "../../Api";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination, Navigation } from "swiper/core";
SwiperCore.use([Pagination, Navigation]);

const CampingPage = (props) => {
    const [section1, setSection1] = useState([]);
    const [section2, setSection2] = useState([]);
    const [section3, setSection3] = useState([]);
    const [section4, setSection4] = useState([]);
    const [section5, setSection5] = useState([]);
    const [section6, setSection6] = useState([]);
    const [section7, setSection7] = useState([]);


    const [products2, setProducts2] = useState([]);

    useEffect(() => {
        window.document.title = "Cleaning Tools | Buy Cleaning Essentials For A Hygienic Home";

        $("html, body").animate({ scrollTop: 0 }, "fast");

        apiJson.
            homepage.getSections(71, props.countryId)
            .then(response => {
                if (response.success) {
                    // console.log(response.data);
                    // setProducts(response.data.products);
                    setSection1(response.data);
                }
            });

        apiJson.
            homepage.getSections(72, props.countryId)
            .then(response => {
                if (response.success) {
                    // console.log(response.data);
                    // setProducts(response.data.products);
                    setSection2(response.data);
                }
            });

        apiJson.
            homepage.getSections(73, props.countryId)
            .then(response => {
                if (response.success) {
                    // console.log(response.data);
                    // setProducts(response.data.products);
                    setSection3(response.data);
                }
            });
        apiJson.
            homepage.getSections(74, props.countryId)
            .then(response => {
                if (response.success) {
                    // console.log(response.data);
                    // setProducts(response.data.products);
                    setSection4(response.data);
                }
            });
        apiJson.
            homepage.getSections(75, props.countryId)
            .then(response => {
                if (response.success) {
                    // console.log(response.data);
                    // setProducts(response.data.products);
                    setSection5(response.data);
                }
            });
        apiJson.
            homepage.getSections(76, props.countryId)
            .then(response => {
                if (response.success) {
                    // console.log(response.data);
                    // setProducts(response.data.products);
                    setSection6(response.data);
                }
            });
        apiJson.
            homepage.getSections(77, props.countryId)
            .then(response => {
                if (response.success) {
                    // console.log(response.data);
                    // setProducts(response.data.products);
                    setSection7(response.data);
                }
            });

    }, []);
    return (
        <Fragment>
            <Helmet>
        <meta name="description" content="Discover the best deals on electrical products featuring Schneider and Legrand brands. Find a wide range of high-quality electrical products for your needs." />
        <title>Shop Electrical Products Online | Legrand & Schneider</title>
        <meta name="title" content="High-Quality Electrical Products: Shop Now for Reliable Solutions |Alisuq" />
      </Helmet>
            <div className="product-home-page-desktop mx-auto paintspage">
                <WebsiteHeader />
                <div className="mypages-container">
                    <Swiper className="homepage-main-banner-swiper"
                        spaceBetween={0}
                        slidesPerView={1}
                        pagination={false}
                        navigation={true}
                    >
                        <SwiperSlide
                            onClick={(e) => e.preventDefault()}
                            key={1}
                        >
                            <Link to={{
                                pathname: "/" + props.countryUrl +
                                    "-" + props.language + "/items/products",
                                search: "filter_by_category%5B%5D=186&filter_by_category%5B%5D=225&filter_by_category%5B%5D=333&filter_by_category%5B%5D=335&filter_by_category%5B%5D=334&filter_by_category%5B%5D=336&filter_by_category%5B%5D=229&filter_by_category%5B%5D=337&filter_by_category%5B%5D=338&filter_by_category%5B%5D=226&filter_by_category%5B%5D=224&filter_by_category%5B%5D=227&filter_by_category%5B%5D=228&filter_by_category%5B%5D=230&filter_by_category%5B%5D=339&filter_by_category%5B%5D=340&filter_by_category%5B%5D=341&filter_by_category%5B%5D=342&filter_by_category%5B%5D=549&filter_by_category%5B%5D=550&filter_by_category%5B%5D=548&filter_by_category%5B%5D=551&filter_by_category%5B%5D=552&filter_by_category%5B%5D=490&filter_by_brand%5B%5D=201&filter_by_brand%5B%5D=171"
                            }}>
                                <img
                                    src={TopBanner1}
                                    className="img img-fluid"
                                    height="110px"
                                />
                            </Link>
                        </SwiperSlide>
                        <SwiperSlide
                            onClick={(e) => e.preventDefault()}
                            key={1}
                        >
                            <Link to={{
                                pathname: "/" + props.countryUrl +
                                    "-" + props.language + "/items/products",
                                search: "filter_by_category%5B%5D=186&filter_by_category%5B%5D=225&filter_by_category%5B%5D=333&filter_by_category%5B%5D=335&filter_by_category%5B%5D=334&filter_by_category%5B%5D=336&filter_by_category%5B%5D=229&filter_by_category%5B%5D=337&filter_by_category%5B%5D=338&filter_by_category%5B%5D=226&filter_by_category%5B%5D=224&filter_by_category%5B%5D=227&filter_by_category%5B%5D=228&filter_by_category%5B%5D=230&filter_by_category%5B%5D=339&filter_by_category%5B%5D=340&filter_by_category%5B%5D=341&filter_by_category%5B%5D=342&filter_by_category%5B%5D=549&filter_by_category%5B%5D=550&filter_by_category%5B%5D=548&filter_by_category%5B%5D=551&filter_by_category%5B%5D=552&filter_by_category%5B%5D=490&filter_by_brand%5B%5D=201"
                            }}>
                                <img
                                    src={TopBanner2}
                                    className="img img-fluid"
                                    height="110px"
                                />
                            </Link>
                        </SwiperSlide>
                        <SwiperSlide
                            onClick={(e) => e.preventDefault()}
                            key={1}
                        >
                            <Link to={{
                                pathname: "/" + props.countryUrl +
                                    "-" + props.language + "/items/products",
                                search: "filter_by_category%5B%5D=186&filter_by_category%5B%5D=225&filter_by_category%5B%5D=333&filter_by_category%5B%5D=335&filter_by_category%5B%5D=334&filter_by_category%5B%5D=336&filter_by_category%5B%5D=229&filter_by_category%5B%5D=337&filter_by_category%5B%5D=338&filter_by_category%5B%5D=226&filter_by_category%5B%5D=224&filter_by_category%5B%5D=227&filter_by_category%5B%5D=228&filter_by_category%5B%5D=230&filter_by_category%5B%5D=339&filter_by_category%5B%5D=340&filter_by_category%5B%5D=341&filter_by_category%5B%5D=342&filter_by_category%5B%5D=549&filter_by_category%5B%5D=550&filter_by_category%5B%5D=548&filter_by_category%5B%5D=551&filter_by_category%5B%5D=552&filter_by_category%5B%5D=490&filter_by_brand%5B%5D=171"
                            }}>
                                <img
                                    src={TopBanner3}
                                    className="img img-fluid"
                                    height="110px"
                                />
                            </Link>
                        </SwiperSlide>

                    </Swiper>








                    <div className="container">
                        <div className="emptyspacer"></div>

                        <div className="row justify-content-center">
                            <div className="col d-flex justify-content-center" spaceBetween={20} slidesPerView={7} pagination={false} navigation={true}>

                                <Link to={{
                                    pathname: "/" + props.countryUrl + "-" + props.language + "/items/products",
                                    search: "filter_by_category%5B%5D=226&filter_by_brand%5B%5D=201&filter_by_brand%5B%5D=171"
                                }}>
                                    <img
                                        src={a1} className="img-img-fluid1 cleancat"
                                        height="150px"
                                    />
                                </Link>

                                <Link to={{
                                    pathname: "/" + props.countryUrl + "-" + props.language + "/items/products",
                                    search: "filter_by_category%5B%5D=550&filter_by_brand%5B%5D=201&filter_by_brand%5B%5D=171"
                                }}>
                                    <img
                                        src={a2} className="img-img-fluid1 cleancat"
                                        height="150px"
                                    />
                                </Link>

                                <Link to={{
                                    pathname: "/" + props.countryUrl + "-" + props.language + "/items/products",
                                    search: "filter_by_category%5B%5D=549&filter_by_brand%5B%5D=201&filter_by_brand%5B%5D=171"
                                }}>
                                    <img
                                        src={a3} className="img-img-fluid1 cleancat"
                                        height="150px"
                                    />
                                </Link>

                                <Link to={{
                                    pathname: "/" + props.countryUrl + "-" + props.language + "/items/products",
                                    search: "filter_by_category%5B%5D=227&filter_by_brand%5B%5D=201&filter_by_brand%5B%5D=171"
                                }}>
                                    <img
                                        src={a4} className="img-img-fluid1 cleancat"
                                        height="150px"
                                    />
                                </Link>

                                <Link to={{
                                    pathname: "/" + props.countryUrl + "-" + props.language + "/items/products",
                                    search: "filter_by_category%5B%5D=552&filter_by_brand%5B%5D=201&filter_by_brand%5B%5D=171"
                                }}>
                                    <img
                                        src={a5} className="img-img-fluid1 cleancat"
                                        height="150px"
                                    />
                                </Link>

                                <Link to={{
                                    pathname: "/" + props.countryUrl + "-" + props.language + "/items/products",
                                    search: "filter_by_category%5B%5D=224&filter_by_brand%5B%5D=201&filter_by_brand%5B%5D=171"
                                }}>
                                    <img
                                        src={a6} className="img-img-fluid1 cleancat"
                                        height="150px"
                                    />
                                </Link>
                            </div>
                        </div>
                    </div>





                    <div className="emptyspacer"></div>
                    <div className="emptyspacer"></div>


                    <div className="megadeal">
                        <div className="container">
                            <div className="row no-gutters" style={{ paddingTop: '100px' }}>
                                <div className="col-md-4 mt-5">
                                    <Link to={{
                                        pathname: "/" + props.countryUrl +
                                            "-" + props.language + "/items/products",
                                        search: "filter_by_category%5B%5D=227"
                                    }}>
                                        <img src={o3} className="img-fluid pl-0 pr-0" alt="Image 1" />
                                    </Link>
                                </div>
                                <div className="col-md-4 mt-5">
                                    <Link to={{
                                        pathname: "/" + props.countryUrl +
                                            "-" + props.language + "/items/products",
                                        search: "filter_by_category%5B%5D=226"
                                    }}>
                                        <img src={o4} className="img-fluid pl-0 pr-0" alt="Image 2" />
                                    </Link>
                                </div>
                                <div className="col-md-4 mt-5">
                                    <Link to={{
                                        pathname: "/" + props.countryUrl +
                                            "-" + props.language + "/items/products",
                                        search: "filter_by_category%5B%5D=228"
                                    }}>
                                        <img src={o5} className="img-fluid pl-0 pr-0" alt="Image 3" />
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>



                    <div className="row">
                        <div className="col-md-4 mt-5">
                            <img src={d1} className="img-fluid custom-image" alt="Image 7" />
                        </div>
                        <div className="col-md-4 mt-5">
                            <img src={d2} className="img-fluid custom-image" alt="Image 8" />
                        </div>
                        <div className="col-md-4 mt-5">
                            <img src={d3} className="img-fluid custom-image" alt="Image 9" />
                        </div>
                    </div>

                    <div className="row justify-content-center">
                        <div className="col-md-4 mt-5 text-center">
                            <img src={t1} className="img-fluid custom-image" alt="Image 10" />
                        </div>
                    </div>


                    <div className="row justify-content-center">
                        <div className="col-md mt-5 text-center">
                            <Link to={{
                                pathname: "/" + props.countryUrl +
                                    "-" + props.language + "/items/products",
                                search: "filter_by_category%5B%5D=227&filter_by_brand%5B%5D=201&filter_by_brand%5B%5D=171&filter_by_price%5Bmax%5D=30"
                            }}>
                                <img src={ua3} className="img-fluid custom-image-3" alt="Image 11" />
                            </Link>
                        </div>
                        <div className="col-md mt-5 text-center">
                            <Link to={{
                                pathname: "/" + props.countryUrl +
                                    "-" + props.language + "/items/products",
                                search: "filter_by_category%5B%5D=226&filter_by_brand%5B%5D=201&filter_by_brand%5B%5D=171&filter_by_price%5Bmax%5D=70"
                            }}>
                                <img src={ua4} className="img-fluid custom-image-3" alt="Image 12" />
                            </Link>
                        </div>
                        <div className="col-md mt-5 text-center">
                            <Link to={{
                                pathname: "/" + props.countryUrl +
                                    "-" + props.language + "/items/products",
                                search: "filter_by_category%5B%5D=228&filter_by_brand%5B%5D=201&filter_by_brand%5B%5D=171&filter_by_price%5Bmax%5D=20"
                            }}>
                                <img src={ua5} className="img-fluid custom-image-3" alt="Image 13" />
                            </Link>
                        </div>
                        <div className="col-md mt-5 text-center">
                            <Link to={{
                                pathname: "/" + props.countryUrl +
                                    "-" + props.language + "/items/products",
                                search: "filter_by_category%5B%5D=224&filter_by_brand%5B%5D=201&filter_by_brand%5B%5D=171&filter_by_price%5Bmax%5D=100"
                            }}>
                                <img src={ua6} className="img-fluid custom-image-4" alt="Image 14" />
                            </Link>
                        </div>
                    </div>


                    <div className="emptyspacer"></div>
                    <div className="emptyspacer"></div>


                    {/* asad products switch and socket */}
                    <div className="home-appliances">
                        <div className="titlewrap">
                            <h2 className="section-title lft catsec">{section1["title_" + props.language]}</h2>
                        </div>
                        <div className="surfacepat1">
                            <div className="productborder">
                                <ProductList
                                    type="product"
                                    home={true}
                                    products={section1.products}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="emptyspacer"></div>
                    <div className="emptyspacer"></div>


                    <div className="banner">
                        <div className="imagesofbanner" style={{ display: 'flex', paddingTop: '85px', paddingLeft: '110px' }}>
                            <Link
                                to={{
                                    pathname: "/" + props.countryUrl + "-" + props.language + "/electrical/schneider-lisse-1-gang-2-way-switch-p-17406?sku=GGBL012NIS",

                                }}
                            >
                                <img src={b2} className="img-fluid custom-image-5" alt="Image 16" />
                            </Link>
                            <Link
                                to={{
                                    pathname: "/" + props.countryUrl + "-" + props.language + "/electrical/schneider-lisse-1-gang-13a-switched-socket-p-17427?sku=GGBL3010NIS",

                                }}
                            >
                                <img src={b3} className="img-fluid custom-image-5" alt="Image 17" />
                            </Link>
                            <Link
                                to={{
                                    pathname: "/" + props.countryUrl + "-" + props.language + "/electrical/legrand-2p-e-13-a-250-v-british-standard-plug-p-22517?sku=650013",

                                }}
                            >
                                <img src={b4} className="img-fluid custom-image-5" alt="Image 18" />
                            </Link>
                            <Link
                                to={{
                                    pathname: "/" + props.countryUrl + "-" + props.language + "/electrical/schneider-vivace-a-c-usb-charger-p-17382?sku=KB32ACQUSB_WE",

                                }}
                            >
                                <img src={b5} className="img-fluid custom-image-5" alt="Image 19" />
                            </Link>
                        </div>
                    </div>




                    {/* asad products free delivery */}
                    <div className="emptyspacer"></div>

                    <div className="productborder">
                        <div className="home-appliances">
                            <div className="titlewrap">
                                <h2 className="section-title lft catsec">{section2["title_" + props.language]}</h2>
                            </div>
                            <div className="surfacepat1">
                                <ProductList
                                    type="product"
                                    home={true}
                                    products={section2.products}
                                />
                            </div>
                        </div>
                    </div>

                    {/* asad products more free delivery */}
                    <div className="emptyspacer"></div>

                    <div className="productborder">
                        <div className="home-appliances">
                            <div className="titlewrap">
                                <h2 className="section-title lft catsec">{section3["title_" + props.language]}</h2>
                            </div>
                            <div className="surfacepat1">
                                <ProductList
                                    type="product"
                                    home={true}
                                    products={section3.products}
                                />
                            </div>
                        </div>
                    </div>


                    <div className="emptyspacer"></div>

                    <div className="productborder">
                        <div className="home-appliances">
                            <div className="titlewrap">
                                <h2 className="section-title lft catsec">{section4["title_" + props.language]}</h2>
                            </div>
                            <div className="surfacepat1">
                                <ProductList
                                    type="product"
                                    home={true}
                                    products={section4.products}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="emptyspacer"></div>


                    <div className="productborder">
                        <div className="home-appliances">
                            <div className="titlewrap">
                                <h2 className="section-title lft catsec">{section5["title_" + props.language]}</h2>
                            </div>
                            <div className="surfacepat1">
                                <ProductList
                                    type="product"
                                    home={true}
                                    products={section5.products}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="emptyspacer"></div>

                    <div className="productborder">
                        <div className="home-appliances">
                            <div className="titlewrap">
                                <h2 className="section-title lft catsec">{section6["title_" + props.language]}</h2>
                            </div>
                            <div className="surfacepat1">
                                <ProductList
                                    type="product"
                                    home={true}
                                    products={section6.products}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="emptyspacer"></div>


                    <div className="productborder">
                        <div className="home-appliances">
                            <div className="titlewrap">
                                <h2 className="section-title lft catsec">{section7["title_" + props.language]}</h2>
                            </div>
                            <div className="surfacepat1">
                                <ProductList
                                    type="product"
                                    home={true}
                                    products={section7.products}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="emptyspacer"></div>



                </div>
                <div className="w-100">
                    <WebsiteFooter />
                </div>
            </div>
        </Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
        countryUrl: state.language.countryUrl,
        countryId: state.language.countryId,
        language: state.language.language,
    };
};


export default connect(mapStateToProps, null)(CampingPage);


