import React from "react";
import { useState } from "react";
import { connect } from "react-redux";
import "../../../assets/styles/component/customer/AllItems/ItemsCategory.scss";
import { VscChevronDown } from "react-icons/vsc";
import { HiOutlineChevronRight } from "react-icons/hi";
import PlusIcon from "../../../assets/icons/plus-grey.svg";
import MinusIcon from "../../../assets/icons/minus-grey.svg";

function ItemsCategory(props) {
  const [subCatLevel1, setSubCatLevel1] = useState({ value: false, id: -1 });
  const [subCatLevel2, setSubCatLevel2] = useState({ value: false, id: -1 });
  const [subCatLevel3, setSubCatLevel3] = useState({ value: false, id: -1 });

  return (
    <div className="item-category-desktop w-100 ">
      {props.categories.map((ele) => (
        <div key={ele.id} className="w-100 mb-3">
          <div
            className={`pointer items-category-heading d-flex flex-row ${props.selectedCategory !== "" &&
              props.selectedCategory[0] === ele.id
              ? " active"
              : ""
              }`}
            onClick={() => {
              localStorage.setItem("load_items", "true");
              let subCat = [];
              ele.sub_categories.map((data) => {
                subCat.push(data.id);
                data.sub_categories &&
                  data.sub_categories.map((subCat3) => {
                    subCat.push(subCat3.id);
                    subCat3.sub_categories &&
                      subCat3.sub_categories.map((subCat4) => {
                        subCat.push(subCat4.id);
                        return subCat;
                      });
                    return subCat3;
                  });
                return data;
              });
              setSubCatLevel1({
                value: subCatLevel1.id === ele.id ? !subCatLevel1.value : true,
                id: ele.id,
              });
              props.selectedCat([ele.id, ...subCat]);
            }}
          >
            <div class="icon-div">
              <span
                onClick={(e) => {
                  e.stopPropagation();
                  setSubCatLevel1({
                    value:
                      subCatLevel1.id === ele.id ? !subCatLevel1.value : true,
                    id: ele.id,
                  });
                }}
              >
                {ele.sub_categories.length > 0 &&
                  (subCatLevel1.value && subCatLevel1.id === ele.id ? (
                    <img src={MinusIcon} className={props.language == "en" ? "plus-minus-btn" : "plus-minus-btn plus-minus-btn-ar"} />
                  ) : (
                    <img src={PlusIcon} className={props.language == "en" ? "plus-minus-btn" : "plus-minus-btn plus-minus-btn-ar"} />
                  ))}
              </span>
            </div>
            <span>{ele[`title_${props.language}`]}</span>
          </div>

          {subCatLevel1.value &&
            subCatLevel1.id === ele.id &&
            ele.sub_categories.map((val, index) => (
              <div key={val.id}>
                <div
                  className="d-flex flex-row items-category-val-cont mt-2 pointer"
                  onClick={() => {
                    localStorage.setItem("load_items", "true");
                    let subCat = [];
                    val.sub_categories &&
                      val.sub_categories.map((subCat3) => {
                        subCat.push(subCat3.id);
                        subCat3.sub_categories &&
                          subCat3.sub_categories.map((subCat4) => {
                            subCat.push(subCat4.id);
                            return subCat4;
                          });
                        return subCat3;
                      });

                    setSubCatLevel2({
                      value:
                        subCatLevel2.id === val.id ? !subCatLevel2.value : true,
                      id: val.id,
                    });
                    props.selectedCat([val.id, ...subCat]);
                  }}
                >
                  <div class="icon-div">
                    <span
                      onClick={(e) => {
                        e.stopPropagation();
                        setSubCatLevel2({
                          value:
                            subCatLevel2.id === val.id
                              ? !subCatLevel2.value
                              : true,
                          id: val.id,
                        });
                      }}
                    >
                      {val.sub_categories?.length > 0 &&
                        (subCatLevel2.value && subCatLevel2.id === val.id ? (
                          <img src={MinusIcon} className={props.language == "en" ? "plus-minus-btn" : "plus-minus-btn plus-minus-btn-ar"} />
                        ) : (
                          <img src={PlusIcon} className={props.language == "en" ? "plus-minus-btn" : "plus-minus-btn plus-minus-btn-ar"} />
                        ))}
                    </span>
                  </div>
                  <span
                    className={`pointer items-category-val ${props.selectedCategory !== "" &&
                      props.selectedCategory[0] === val.id
                      ? " active"
                      : ""
                      }`}
                  >
                    {val[`title_${props.language}`]}
                  </span>
                </div>

                {val.sub_categories &&
                  subCatLevel2.value &&
                  subCatLevel2.id === val.id &&
                  val.sub_categories?.map((subCat, index) => (
                    <div key={subCat.id}>
                      <div
                        className="items-category-sub-cat-three-cont mt-2 pointer"
                        onClick={() => {
                          localStorage.setItem("load_items", "true");
                          let subCatId =
                            subCat.sub_categories &&
                              subCat.sub_categories.length > 0
                              ? subCat.sub_categories.map((data) => data.id)
                              : [];

                          setSubCatLevel3({
                            value:
                              subCatLevel3.id === subCat.id
                                ? !subCatLevel3.value
                                : true,
                            id: subCat.id,
                          });
                          props.selectedCat([subCat.id, ...subCatId]);
                        }}
                      >

                        <div className="icon-div">
                          <span
                            onClick={(e) => {
                              e.stopPropagation();
                              setSubCatLevel3({
                                value:
                                  subCatLevel3.id === subCat.id
                                    ? !subCatLevel3.value
                                    : true,
                                id: subCat.id,
                              });
                            }}
                          >
                            {subCat.sub_categories?.length > 0 &&
                              (subCatLevel3.value &&
                                subCatLevel3.id === subCat.id ? (
                                  <img src={MinusIcon} className={props.language == "en" ? "plus-minus-btn" : "plus-minus-btn plus-minus-btn-ar"} />
                                // <VscChevronDown size={21} color="#404553" />
                              ) : (
                                <img src={PlusIcon} className={props.language == "en" ? "plus-minus-btn" : "plus-minus-btn plus-minus-btn-ar"} />
                                // <HiOutlineChevronRight
                                //   size={21}
                                //   color="#404553"
                                //   style={{
                                //     transform:
                                //       props.language === "ar"
                                //         ? "rotate(180deg)"
                                //         : "unset",
                                //   }}
                                // />
                              ))}
                          </span>
                        </div>
                        <span
                          className={`items-category-val-two ${props.selectedCategory !== "" &&
                            props.selectedCategory[0] === subCat.id
                            ? " active"
                            : ""
                            }`}
                        >
                          {subCat[`title_${props.language}`]}
                        </span>
                      </div>

                      {subCat.sub_categories &&
                        subCatLevel3.value &&
                        subCatLevel3.id === subCat.id &&
                        subCat.sub_categories?.map((subCatThree, index) => (
                          <div
                            key={subCatThree.id}
                            onClick={() => {
                              localStorage.setItem("load_items", "true");
                              props.selectedCat([subCatThree.id])
                            }}
                            className="items-category-sub-cat-four-cont mt-2 pointer"
                          >
                            <span
                              className={`items-category-val-three ${props.selectedCategory !== "" &&
                                props.selectedCategory[0] === subCatThree.id
                                ? " active"
                                : ""
                                }`}
                            >
                              {subCatThree[`title_${props.language}`]}
                            </span>
                          </div>
                        ))}
                    </div>
                  ))}
              </div>
            ))}
        </div>
      ))}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    selectedCategory: state.allItems.selectedCat,
    language: state.language.language,
  };
};

export default connect(mapStateToProps, null)(ItemsCategory);