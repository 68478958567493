import React, { Fragment, useEffect } from "react";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import "../../../assets/styles/component/customer/AllItems/ItemsHeader.scss";
import { set_all_categories } from "../../../redux/actions/AllCategoriesAction";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { apiJson } from "../../../Api";
import { set_loading } from "../../../redux/actions/LayoutAction";
import { useHistory, useLocation } from "react-router-dom";
import { replaceAt } from "../../../utils/utils";
const ItemsHeader = (props) => {
  const { t } = useTranslation();
  let message = "";
  const history = useHistory();
  const location = useLocation();

  const showError = () => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  const getFiltersParam = (
    page,
    records,
    price_high_to_low,
    price_low_to_high,
    new_arrivals,
    selectedCat
  ) => {
    let categories = "";
    selectedCat &&
      selectedCat.length > 0 &&
      selectedCat.map(
        (cat) => (categories = categories + "&filter_by_category[]=" + cat)
      );

    return (
      (parseInt(page) !== 1 ? "&page=" + page : "") +
      (parseInt(records) !== 30 ? "&records_per_page=" + records : "") +
      (price_high_to_low ? "&price_high_to_low=true" : "") +
      (price_low_to_high ? "&price_low_to_high=true" : "") +
      (new_arrivals ? "&order_by_new_arrivals=" + new_arrivals : "") +
      (selectedCat && selectedCat.length > 0 ? categories : "") +
      (props.activeTab === "products" ? "&tab=p" : "&tab=s")
    );
  };

  const changeValue = (
    recordPerPage,
    price_high_to_low,
    price_low_to_high,
    new_arrivals
  ) => {
    let filters = getFiltersParam(
      1,
      recordPerPage,
      price_high_to_low,
      price_low_to_high,
      new_arrivals,
      props.selectedCat === "" ? [] : props.selectedCat
    );
    filters = encodeURI(filters);
    history.push(
      location.state
        ? {
            pathname: window.location.pathname,
            search: replaceAt(filters, 0, "?"),
            state: location.state,
          }
        : {
            pathname: window.location.pathname,
            search: replaceAt(filters, 0, "?"),
          }
    );
    props.set_loading(true);
    window.scroll(0, 0);

    apiJson.categories
      .getItems(
        props.countryId,
        props.activeTab,
        1,
        recordPerPage,
        price_high_to_low,
        price_low_to_high,
        new_arrivals,
        props.selectedCat === "" ? [] : props.selectedCat
      )
      .then((response) => {
        if (response.success) {
          props.set_all_categories({
            items: response.data,
          });
          props.set_loading(false);
        } else if (!response.status) {
          props.set_loading(false);
          // eslint-disable-next-line
          message = response.message
            ? response.message !== ""
              ? response.message
              : t("errors.wrong")
            : t("errors.wrong");
          showError();
        } else {
          props.set_loading(false);
          if (response.status >= 400 && response.status < 500) {
            message = response.message
              ? response.message
              : response.errors
              ? response.errors
              : t("errors.wrong");
            showError();
          } else {
            message = response.message
              ? response.message !== ""
                ? response.message
                : t("errors.wrong")
              : t("errors.wrong");
            showError();
          }
        }
      });
  };

  const getTabData = (activeTab) => {
    window.scroll(0, 0);
    props.set_loading(true);
    history.push({
      pathname: window.location.pathname,
      search: activeTab === "products" ? "tab=p" : "tab=s",
      state: location.state,
    });

    apiJson.categories
      .getItems(props.countryId, activeTab, 1, 30)
      .then((response) => {
        if (response.success) {
          props.set_all_categories({
            items: response.data,
          });
          props.set_loading(false);
        } else if (!response.status) {
          props.set_loading(false);

          // eslint-disable-next-line
          message = response.message
            ? response.message !== ""
              ? response.message
              : t("errors.wrong")
            : t("errors.wrong");
          showError();
        } else {
          props.set_loading(false);

          if (response.status >= 400 && response.status < 500) {
            message = response.message
              ? response.message
              : response.errors
              ? response.errors
              : t("errors.wrong");
            showError();
          } else {
            message = response.message
              ? response.message !== ""
                ? response.message
                : t("errors.wrong")
              : t("errors.wrong");
            showError();
          }
        }
      });
  };

  useEffect(() => {
    let index = -1;
    if (props.language === "en") {
      props.display_ar.map((e, i) =>
        e === props.displayVal.value ? (index = i) : []
      );
    } else {
      props.display_en.map((e, i) =>
        e === props.displayVal.value ? (index = i) : []
      );
    }
    if (index !== -1) {
      props.language === "ar"
        ? props.set_all_categories({
            displayVal: {
              value: props.display_ar[index],
              label: props.display_ar[index],
            },
          })
        : props.set_all_categories({
            displayVal: {
              value: props.display_en[index],
              label: props.display_en[index],
            },
          });
    }
    index = -1;
    if (props.language === "en") {
      props.sortBy_ar.map((e, i) =>
        e === props.sortVal.value ? (index = i) : []
      );
    } else {
      props.sortBy_en.map((e, i) =>
        e === props.sortVal.value ? (index = i) : []
      );
    }

    if (index !== -1) {
      props.language === "ar"
        ? props.set_all_categories({
            sortVal: {
              value: props.sortBy_ar[index],
              label: props.sortBy_ar[index],
            },
          })
        : props.set_all_categories({
            sortVal: {
              value: props.sortBy_en[index],
              label: props.sortBy_en[index],
            },
          });
    }
  }, [props.language]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Fragment>
      <div className="item-header-mobile item-header-desktop">
        <div className="small-screen-none ml-2 d-flex flex-direction-row justify-content-between  align-items-center   top-header">
          <div className="d-inline-flex flex-direction-column header-div">
            <span
              className={
                props.activeTab === "products" ? "active-tab" : "text-style"
              }
              onClick={() => {
                if (props.activeTab !== "products") {
                  props.set_all_categories({
                    activeTab: "products",
                    items: {},

                    displayVal:
                      props.language === "en"
                        ? { value: "30 per page", label: "30 per page" }
                        : { value: "خمسون لكل صفحة", label: "خمسون لكل صفحة" },
                    sortVal:
                      props.language === "en"
                        ? { value: "Recommended", label: "Recommended" }
                        : { value: "موصى به", label: "موصى به" },
                    selectedCat: "",
                    recordPerPage: 30,
                    price_high_to_low: false,
                    price_low_to_high: false,
                    new_arrivals: false,
                  });
                  getTabData("products");
                }
              }}
            >
              {t("items.header.products")}
            </span>
            <span
              className={
                props.activeTab === "services" ? "active-tab" : "text-style"
              }
              onClick={() => {
                if (props.activeTab !== "services") {
                  props.set_all_categories({
                    activeTab: "services",
                    items: {},

                    displayVal:
                      props.language === "en"
                        ? { value: "30 per page", label: "30 per page" }
                        : { value: "خمسون لكل صفحة", label: "خمسون لكل صفحة" },
                    sortVal:
                      props.language === "en"
                        ? { value: "Recommended", label: "Recommended" }
                        : { value: "موصى به", label: "موصى به" },
                    selectedCat: "",
                    recordPerPage: 30,

                    price_high_to_low: false,
                    price_low_to_high: false,
                    new_arrivals: false,
                  });
                  getTabData("services");
                }
              }}
            >
              {t("")}
            </span>
          </div>

          <div className=" child-div-one fs-lg-15 fs-sm-11 invisible">
            {" "}
            {props?.title}
          </div>
          <div className="d-flex flex-direction-row">
            <div className="child-div-two d-flex flex-row align-items-center justify-content-center">
              {" "}
              <div
                className="child-text fs-lg-12 fs-sm-10 invisible "
                style={{ margin: "10px" }}
              >
                {t("items.header.sort-by")}
              </div>
              <div
                className="child-text fs-lg-12 fs-sm-10"
                style={{ margin: "10px" }}
              >
                {t("items.header.sort-by")}
              </div>
              <div className="ml-2">
                <Dropdown
                  options={props[`sortBy_${props.language}`]}
                  onChange={(e) => {
                    let i = 0;
                    if (e.value === props[`sortBy_${props.language}`][1]) {
                      i = 1;
                    } else if (
                      e.value === props[`sortBy_${props.language}`][2]
                    ) {
                      i = 2;
                    } else if (
                      e.value === props[`sortBy_${props.language}`][3]
                    ) {
                      i = 3;
                    }
                    props.set_all_categories({
                      sortVal: e,
                      price_high_to_low: i === 1 ? true : false,
                      price_low_to_high: i === 2 ? true : false,
                      new_arrivals: i === 3 ? true : false,
                    });
                    changeValue(props.recordPerPage, i === 1, i === 2, i === 3);
                  }}
                  value={props.sortVal}
                  placeholderClassName="dropdownPlaceholder"
                  menuClassName="dropdownPlaceholder border-shadow"
                  className="dropdown-cont"
                />
              </div>
            </div>

            <div
              className=" ml-2 child-div-two d-flex flex-row align-items-center
          justify-content-center"
            >
              {" "}
              <div
                className="child-text fs-lg-12 fs-sm-10"
                style={{ margin: "10px" }}
              >
                {t("items.header.display")}
              </div>
              <div className="ml-2">
                <Dropdown
                  options={props[`display_${props.language}`]}
                  onChange={(e) => {
                    props.set_all_categories({
                      displayVal: e,
                      recordPerPage:
                        e.value === props[`display_${props.language}`][0]
                          ? 30
                          : e.value === props[`display_${props.language}`][1]
                          ? 50
                          : 80,
                    });
                    changeValue(
                      e.value === props[`display_${props.language}`][0]
                        ? 30
                        : e.value === props[`display_${props.language}`][1]
                        ? 50
                        : 80,
                      props.price_high_to_low,
                      props.price_low_to_high,
                      props.new_arrivals
                    );
                  }}
                  value={props.displayVal}
                  placeholderClassName="dropdownPlaceholder"
                  menuClassName="dropdownPlaceholder border-shadow"
                  className="dropdown-cont"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    sortBy_en: state.categories.sortBy_en,
    activeTab: state.categories.activeTab,
    services: state.categories.services,
    products: state.categories.products,
    display_en: state.categories.display_en,
    sortBy_ar: state.categories.sortBy_ar,
    display_ar: state.categories.display_ar,

    sortVal: state.categories.sortVal,
    displayVal: state.categories.displayVal,

    items: state.categories.items,
    originalItems: state.categories.originalItems,

    language: state.language.language,
    recordPerPage: state.categories.recordPerPage,
    price_high_to_low: state.categories.price_high_to_low,
    price_low_to_high: state.categories.price_low_to_high,
    new_arrivals: state.categories.new_arrivals,
    selectedCat: state.categories.selectedCat,
    countryId: state.language.countryId,
  };
};

const mapDispatchToProps = {
  set_all_categories,
  set_loading,
};
export default connect(mapStateToProps, mapDispatchToProps)(ItemsHeader);
