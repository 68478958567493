import React, { Fragment, useState } from "react";
import { Redirect, useLocation } from "react-router-dom";
import "../../assets/styles/pages/forgetpasswordmob.scss";
import MobileLayoutRenderer from "./layout/MobileLayoutRenderer";
import { useTranslation } from "react-i18next";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ClipLoader from "react-spinners/ClipLoader";
import { apiJson } from "../../Api";
import { BsFillEyeFill, BsFillEyeSlashFill } from "react-icons/bs";

const ForgetPasswordMob = () => {
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const [redirect, setRedirect] = useState(false);
  const [redirectToLogin, setRedirectToLogin] = useState(false);
  const [currentPass, setCurrentPass] = useState("");
  const [newPass, setNewPass] = useState("");
  const [error, setError] = useState(false);
  const [changing, setChanging] = useState(false);
  const [showCurrPass, setShowCurrPass] = useState(false);
  const [showNewPass, setShowNewPass] = useState(false);

  const location = useLocation();
  const reset_password_token = new URLSearchParams(location.search).get(
    "reset_password_token"
  );

  let message = "";
  const showError = () => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const showSuccess = () => {
    toast.success(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const changePass = () => {
    if (reset_password_token) {
      setError(false);
      if (newPass.length > 5 && currentPass.length > 5) {
        setChanging(true);
        const form = new FormData();
        form.append("password", currentPass);
        form.append("password_confirmation", newPass);
        form.append("reset_password_token", reset_password_token);
        apiJson.auth.resetPass(form).then((response) => {
          setChanging(false);
          if (response.success) {
            setRedirectToLogin(true);
            const timer = setTimeout(() => {
              setRedirectToLogin(false);
            }, 1000);
            return () => clearTimeout(timer);
          } else if (!response.status) {
            message = response.message
              ? response.message !== ""
                ? response.message
                : t("errors.wrong")
              : t("errors.wrong");
            showError();
          } else {
            if (response.status >= 400 && response.status < 500) {
              message = response.message
                ? response.message
                : response.errors
                ? response.errors
                : t("errors.wrong");
              showError();
            } else {
              message = response.message
                ? response.message !== ""
                  ? response.message
                  : t("errors.wrong")
                : t("errors.wrong");
              showError();
            }
          }
        });
      } else {
        setError(true);
      }
    } else {
      setChanging(true);
      const form = new FormData();
      form.append("email", email);

      apiJson.auth.resetPassEmail(form).then((response) => {
        setChanging(false);
        if (response.success) {
          message = t("forget-pw-mob.success-message");
          showSuccess();
        } else if (!response.status) {
          message = response.message
            ? response.message !== ""
              ? response.message
              : t("errors.wrong")
            : t("errors.wrong");
          showError();
        } else {
          if (response.status >= 400 && response.status < 500) {
            message = response.message
              ? response.message
              : response.errors
              ? response.errors
              : t("errors.wrong");
            showError();
          } else {
            message = response.message
              ? response.message !== ""
                ? response.message
                : t("errors.wrong")
              : t("errors.wrong");
            showError();
          }
        }
      });
    }
  };

  return (
    <MobileLayoutRenderer
      NavbarTop={true}
      BackBtn={false}
      CancelBtn={false}
      CloseIcon={true}
      NavbarBottom={false}
    >
      <Fragment>
        <div className="forget-password-mobile-page-main px-3">
          <div className="inner-wrapper">
            <div className="first-row-forget-label">
              <span className="forget-pw-label">
                {reset_password_token
                  ? "Reset Password"
                  : "Forgot your password?"}
              </span>
            </div>
            {!reset_password_token ? (
              <>
                <div className="second-row-forgetpw-desc">
                  <span className="forget-pw-desc">
                    {t("forget-pw-mob.enter-email")}
                  </span>
                </div>
                <div className="third-row-forget-apssword-email-input">
                  <label className="e-label">
                    {t("forget-pw-mob.e-label")}
                  </label>

                  <div className="d-flex email">
                    <input
                      className="e-input"
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder={t("forget-pw-mob.email-ph")}
                    />
                  </div>
                </div>
              </>
            ) : (
              <>
                <label
                  className="mt-40-px d-flex w-80 ml-auto mr-auto"
                  style={{
                    color: "rgb(126, 133, 155)",
                    fontSize: "0.875rem",
                    fontWeight: "400",
                  }}
                >
                  New Password
                </label>
                <div
                  className="input-group d-flex flex-direction-row justify-content-between mb-5 align-items-center w-80 ml-auto mr-auto"
                  style={{ borderBottom: "1px solid rgb(226, 229, 241)" }}
                >
                  <input
                    type={showCurrPass ? "text" : "password"}
                    value={currentPass}
                    onChange={(e) => setCurrentPass(e.target.value)}
                    className="input-with-icon w-90"
                    style={{
                      border: "0px",
                      outline: "none",
                      boxShadow: "none",
                    }}
                  />
                  {showCurrPass ? (
                    <BsFillEyeFill
                      cursor="pointer"
                      onClick={() => setShowCurrPass(!showCurrPass)}
                      className="eye-icon"
                      size="14px"
                      color="rgb(126, 133, 155)"
                    />
                  ) : (
                    <BsFillEyeSlashFill
                      cursor="pointer"
                      onClick={() => setShowCurrPass(!showCurrPass)}
                      className="eye-icon"
                      size="14px"
                      color="rgb(126, 133, 155)"
                    />
                  )}
                </div>
                <label
                  className=" d-flex w-80 ml-auto mr-auto"
                  style={{
                    color: "rgb(126, 133, 155)",
                    fontSize: "0.875rem",
                    fontWeight: "400",
                  }}
                >
                  <span>Confirmation Password</span>
                </label>
                <div
                  className="input-group d-flex flex-direction-row justify-content-between mb-5 align-items-center w-80 ml-auto mr-auto"
                  style={{
                    borderBottom: "1px solid rgb(226, 229, 241)",
                    marginBottom: "60px",
                  }}
                >
                  <input
                    type={showNewPass ? "text" : "password"}
                    className="input-with-icon w-90"
                    value={newPass}
                    onChange={(e) => setNewPass(e.target.value)}
                    style={{
                      border: "0px",
                      outline: "none",
                      boxShadow: "none",
                    }}
                  />
                  {showNewPass ? (
                    <BsFillEyeFill
                      cursor="pointer"
                      onClick={() => setShowNewPass(!showNewPass)}
                      size="14px"
                      color="rgb(126, 133, 155)"
                    />
                  ) : (
                    <BsFillEyeSlashFill
                      cursor="pointer"
                      onClick={() => setShowNewPass(!showNewPass)}
                      size="14px"
                      color="rgb(126, 133, 155)"
                    />
                  )}
                </div>
                {error && (
                  <div className="error">
                    Password length must be greater than 5
                  </div>
                )}
              </>
            )}
            <div className="fourth-row-reset-password-btn-div">
              <div onClick={changePass} className="reset-pw">
                {changing ? (
                  <div className="d-flex justify-content-center align-items-center w-100">
                    <ClipLoader color="#fff" loading size={20} />
                  </div>
                ) : (
                  t("forget-pw-mob.reset")
                )}
              </div>
            </div>

            <div
              onClick={() => {
                setRedirect(true);
                const timer = setTimeout(() => {
                  setRedirect(false);
                }, 1000);
                return () => clearTimeout(timer);
              }}
              className="last-row-signup"
            >
              {t("forget-pw-mob.no-account")}{" "}
              <span className="signup-text">{t("forget-pw-mob.sign-up")}</span>
            </div>
          </div>
        </div>
      </Fragment>
      {redirect && <Redirect push to="/signup" />}
      {redirectToLogin && <Redirect push to="/login" />}
    </MobileLayoutRenderer>
  );
};

export default ForgetPasswordMob;
