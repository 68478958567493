import { type } from "../../types/types";

export default function reducer(
  state = {
    redirect: false,
  },
  action
) {
  switch (action.type) {
    case type.CHANGE_REDIRECT:
      return { redirect: action.value };
    default:
      return state;
  }
}
