import React from "react";
import { connect } from "react-redux";

import BrandsFavouritesComp from "../../../components/mobile/footer-pages/BrandsFavouritesComp";

function BrandsFavourites(props) {
  return <div>{props.width === "sm" && <BrandsFavouritesComp screen="home" />}</div>;
}

const mapStateToProps = (state) => {
  return {
    width: state.layout.screenWidth,
  };
};

export default connect(mapStateToProps, null)(BrandsFavourites);
