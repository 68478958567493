import React, { Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import $ from "jquery";
import MobileLayoutRenderer from "../../mobile/layout/MobileLayoutRenderer";
import HeaderMobile from "../../../components/mobile/HeaderMobile";

import "../../../assets/styles/offers-landing-pages/mob/electricalAccessoriesSaleMob.scss";
import { Helmet } from 'react-helmet';


import a1 from "../../../assets/images/offers-landing-pages/one.png";
import a2 from "../../../assets/images/offers-landing-pages/two.png";
import a3 from "../../../assets/images/offers-landing-pages/three.png";
import a4 from "../../../assets/images/offers-landing-pages/four.png";
import a5 from "../../../assets/images/offers-landing-pages/five.png";
import a6 from "../../../assets/images/offers-landing-pages/six.png";

import of1 from "../../../assets/images/offers-landing-pages/of1.png";
import of2 from "../../../assets/images/offers-landing-pages/of2.png";
import of3 from "../../../assets/images/offers-landing-pages/of3.png";
import of4 from "../../../assets/images/offers-landing-pages/of4.png";

import d1 from "../../../assets/images/offers-landing-pages/d1.png";
import d2 from "../../../assets/images/offers-landing-pages/d2.png";
import d3 from "../../../assets/images/offers-landing-pages/d3.png";
import t1 from "../../../assets/images/offers-landing-pages/t1.png";


import ua3 from "../../../assets/images/offers-landing-pages/ua3.png";
import ua4 from "../../../assets/images/offers-landing-pages/ua4.png";
import ua5 from "../../../assets/images/offers-landing-pages/ua5.png";
import ua6 from "../../../assets/images/offers-landing-pages/ua6.png";


import b2 from "../../../assets/images/offers-landing-pages/b2.png";
import b3 from "../../../assets/images/offers-landing-pages/b3.png";
import b4 from "../../../assets/images/offers-landing-pages/b4.png";
import b5 from "../../../assets/images/offers-landing-pages/b5.png";



import TopBanner1 from "../../../assets/images/offers-landing-pages/Schneider-legrand1mob.png";
import TopBanner2 from "../../../assets/images/offers-landing-pages/Schneider-legrand2mob.jpg";
import TopBanner3 from "../../../assets/images/offers-landing-pages/Schneider-legrand3mob.jpg";



import "../../../assets/styles/category-homepages/home-appliances-mob.scss";
import { apiJson } from "../../../Api";
import ProductCard from "../../../components/mobile/Home/ProductCardMobile";


import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination, Navigation } from "swiper/core";
SwiperCore.use([Pagination, Navigation]);

const CampingPageMobile = (props) => {
    const [section1, setSection1] = useState([]);
    const [section2, setSection2] = useState([]);
    const [section3, setSection3] = useState([]);
    const [section4, setSection4] = useState([]);
    const [section5, setSection5] = useState([]);
    const [section6, setSection6] = useState([]);
    const [section7, setSection7] = useState([]);

    useEffect(() => {
        window.document.title = "Cleaning Tools | Buy Cleaning Essentials For A Hygienic Home";

        apiJson.
            homepage.getSections(71, props.countryId)
            .then(response => {
                if (response.success) {
                    // console.log(response.data);
                    // setProducts(response.data.products);
                    setSection1(response.data);
                }
            });

        apiJson.
            homepage.getSections(72, props.countryId)
            .then(response => {
                if (response.success) {
                    // console.log(response.data);
                    // setProducts(response.data.products);
                    setSection2(response.data);
                }
            });

        apiJson.
            homepage.getSections(73, props.countryId)
            .then(response => {
                if (response.success) {
                    // console.log(response.data);
                    // setProducts(response.data.products);
                    setSection3(response.data);
                }
            });
        apiJson.
            homepage.getSections(74, props.countryId)
            .then(response => {
                if (response.success) {
                    // console.log(response.data);
                    // setProducts(response.data.products);
                    setSection4(response.data);
                }
            });
        apiJson.
            homepage.getSections(75, props.countryId)
            .then(response => {
                if (response.success) {
                    // console.log(response.data);
                    // setProducts(response.data.products);
                    setSection5(response.data);
                }
            });
        apiJson.
            homepage.getSections(76, props.countryId)
            .then(response => {
                if (response.success) {
                    // console.log(response.data);
                    // setProducts(response.data.products);
                    setSection6(response.data);
                }
            });
        apiJson.
            homepage.getSections(77, props.countryId)
            .then(response => {
                if (response.success) {
                    // console.log(response.data);
                    // setProducts(response.data.products);
                    setSection7(response.data);
                }
            });
        $("html, body").animate({ scrollTop: 0 }, "fast");
    }, []);
    return (

        <MobileLayoutRenderer
            NavbarTop={false}
            BackBtn={false}
            CancelBtn={false}
            NavbarBottom={true}
            SearchIcon={true}
        >
            <Helmet>
                <meta name="description" content="Discover the best deals on electrical products featuring Schneider and Legrand brands. Find a wide range of high-quality electrical products for your needs." />
                <title>Shop Electrical Products Online | Legrand & Schneider</title>
                <meta name="title" content="High-Quality Electrical Products: Shop Now for Reliable Solutions |Alisuq" />
            </Helmet>
            <div className="home-mob">
                <HeaderMobile />
            </div>
            <div className="banner-sec">
                <Swiper className="catpages-mob"
                    spaceBetween={0}
                    slidesPerView={1}
                    pagination={false}
                    navigation={true}
                >
                    <SwiperSlide
                        onClick={(e) => e.preventDefault()}
                        key={1}
                    >
                        <Link to={{
                            pathname: "/" + props.countryUrl +
                                "-" + props.language + "/items/products",
                            search: "filter_by_category%5B%5D=186&filter_by_category%5B%5D=225&filter_by_category%5B%5D=333&filter_by_category%5B%5D=335&filter_by_category%5B%5D=334&filter_by_category%5B%5D=336&filter_by_category%5B%5D=229&filter_by_category%5B%5D=337&filter_by_category%5B%5D=338&filter_by_category%5B%5D=226&filter_by_category%5B%5D=224&filter_by_category%5B%5D=227&filter_by_category%5B%5D=228&filter_by_category%5B%5D=230&filter_by_category%5B%5D=339&filter_by_category%5B%5D=340&filter_by_category%5B%5D=341&filter_by_category%5B%5D=342&filter_by_category%5B%5D=549&filter_by_category%5B%5D=550&filter_by_category%5B%5D=548&filter_by_category%5B%5D=551&filter_by_category%5B%5D=552&filter_by_category%5B%5D=490&filter_by_brand%5B%5D=201&filter_by_brand%5B%5D=171"
                        }}>
                            <img
                                src={TopBanner1}
                                className="img img-fluid"
                                height="110px"
                            />
                        </Link>
                    </SwiperSlide>
                    <SwiperSlide
                        onClick={(e) => e.preventDefault()}
                        key={1}
                    >
                        <Link to={{
                            pathname: "/" + props.countryUrl +
                                "-" + props.language + "/items/products",
                            search: "filter_by_category%5B%5D=186&filter_by_category%5B%5D=225&filter_by_category%5B%5D=333&filter_by_category%5B%5D=335&filter_by_category%5B%5D=334&filter_by_category%5B%5D=336&filter_by_category%5B%5D=229&filter_by_category%5B%5D=337&filter_by_category%5B%5D=338&filter_by_category%5B%5D=226&filter_by_category%5B%5D=224&filter_by_category%5B%5D=227&filter_by_category%5B%5D=228&filter_by_category%5B%5D=230&filter_by_category%5B%5D=339&filter_by_category%5B%5D=340&filter_by_category%5B%5D=341&filter_by_category%5B%5D=342&filter_by_category%5B%5D=549&filter_by_category%5B%5D=550&filter_by_category%5B%5D=548&filter_by_category%5B%5D=551&filter_by_category%5B%5D=552&filter_by_category%5B%5D=490&filter_by_brand%5B%5D=201"
                        }}>
                            <img
                                src={TopBanner2}
                                className="img img-fluid"
                                height="110px"
                            />
                        </Link>
                    </SwiperSlide>
                    <SwiperSlide
                        onClick={(e) => e.preventDefault()}
                        key={1}
                    >
                        <Link to={{
                            pathname: "/" + props.countryUrl +
                                "-" + props.language + "/items/products",
                            search: "filter_by_category%5B%5D=186&filter_by_category%5B%5D=225&filter_by_category%5B%5D=333&filter_by_category%5B%5D=335&filter_by_category%5B%5D=334&filter_by_category%5B%5D=336&filter_by_category%5B%5D=229&filter_by_category%5B%5D=337&filter_by_category%5B%5D=338&filter_by_category%5B%5D=226&filter_by_category%5B%5D=224&filter_by_category%5B%5D=227&filter_by_category%5B%5D=228&filter_by_category%5B%5D=230&filter_by_category%5B%5D=339&filter_by_category%5B%5D=340&filter_by_category%5B%5D=341&filter_by_category%5B%5D=342&filter_by_category%5B%5D=549&filter_by_category%5B%5D=550&filter_by_category%5B%5D=548&filter_by_category%5B%5D=551&filter_by_category%5B%5D=552&filter_by_category%5B%5D=490&filter_by_brand%5B%5D=171"
                        }}>
                            <img
                                src={TopBanner3}
                                className="img img-fluid"
                                height="110px"
                            />
                        </Link>
                    </SwiperSlide>

                </Swiper>
            </div>


            <div className="emptyspacer"></div>

            <div className="container">

                <div className="row justify-content-center" >
                    <div className="col d-flex justify-content-center">
                        <Link to={{
                            pathname: "/" + props.countryUrl +
                                "-" + props.language + "/items/products",
                            search: "filter_by_category%5B%5D=226&filter_by_brand%5B%5D=201&filter_by_brand%5B%5D=171"
                        }}>
                            <img
                                src={a1}
                                className="img-fluid image"
                                style={{ width: "100px" }}
                                alt="Image 1"
                            />
                        </Link>
                    </div>
                    <div className="col d-flex justify-content-center">
                        <Link to={{
                            pathname: "/" + props.countryUrl +
                                "-" + props.language + "/items/products",
                            search: "filter_by_category%5B%5D=550&filter_by_brand%5B%5D=201&filter_by_brand%5B%5D=171"
                        }}>
                            <img
                                src={a2}
                                className="img-fluid image"
                                style={{ width: "100px" }}
                                alt="Image 2"
                            />
                        </Link>
                    </div>
                    <div className="col d-flex justify-content-center">
                        <Link to={{
                            pathname: "/" + props.countryUrl +
                                "-" + props.language + "/items/products",
                            search: "filter_by_category%5B%5D=549&filter_by_brand%5B%5D=201&filter_by_brand%5B%5D=171"
                        }}>
                            <img
                                src={a3}
                                className="img-fluid image"
                                style={{ width: "100px" }}
                                alt="Image 3"
                            />
                        </Link>
                    </div>
                </div>


                <div className="row justify-content-center">
                    <div className="col d-flex justify-content-center">
                        <Link to={{
                            pathname: "/" + props.countryUrl +
                                "-" + props.language + "/items/products",
                            search: "filter_by_category%5B%5D=227&filter_by_brand%5B%5D=201&filter_by_brand%5B%5D=171"
                        }}>
                            <img
                                src={a4}
                                className="img-fluid image"
                                style={{ width: "100px", paddingTop: '10px' }}
                                alt="Image 4"
                            />
                        </Link>
                    </div>
                    <div className="col d-flex justify-content-center">
                        <Link to={{
                            pathname: "/" + props.countryUrl +
                                "-" + props.language + "/items/products",
                            search: "filter_by_category%5B%5D=552&filter_by_brand%5B%5D=201&filter_by_brand%5B%5D=171"
                        }}>
                            <img
                                src={a5}
                                className="img-fluid image"
                                style={{ width: "100px", paddingTop: '10px' }}
                                alt="Image 5"
                            />
                        </Link>
                    </div>
                    <div className="col d-flex justify-content-center">
                        <Link to={{
                            pathname: "/" + props.countryUrl +
                                "-" + props.language + "/items/products",
                            search: "filter_by_category%5B%5D=224&filter_by_brand%5B%5D=201&filter_by_brand%5B%5D=171"
                        }}>
                            <img
                                src={a6}
                                className="img-fluid image"
                                style={{ width: "100px", paddingTop: '10px' }}
                                alt="Image 6"
                            />
                        </Link>
                    </div>
                </div>
            </div>
            <div className="emptyspacer"></div>




            <div className="container">
                <div className="row justify-content-center" style={{ backgroundColor: '#742013', paddingBottom: '25px', paddingLeft: '15px', paddingRight: '15px' }}>
                    <div className="col-md mt-4 text-center">
                        <h2 style={{ color: 'white', fontFamily: 'Futura Heavy', paddingBottom: '10px' }}>Mega Deals</h2>
                    </div>
                    <div className="col-md  text-center col-6">
                        <Link
                            to={{
                                pathname: "/" + props.countryUrl + "-" + props.language + "/items/products",
                                search: "filter_by_category%5B%5D=227"
                            }}
                        >
                            <img src={of1} className="img-fluid custom-image" style={{ mariginTop: '-30px' }} alt="Image 11" />
                        </Link>
                    </div>
                    <div className="col-md text-center col-6">
                        <Link
                            to={{
                                pathname: "/" + props.countryUrl + "-" + props.language + "/items/products",
                                search: "filter_by_category%5B%5D=226"
                            }}
                        >
                            <img src={of2} className="img-fluid custom-image" style={{ mariginTop: '-30px' }} alt="Image 12" />
                        </Link>
                    </div>
                    <div className="col-md  text-center col-6">
                        <Link
                            to={{
                                pathname: "/" + props.countryUrl + "-" + props.language + "/items/products",
                                search: "filter_by_category%5B%5D=228"
                            }}
                        >
                            <img src={of3} className="img-fluid custom-image" style={{ mariginTop: '-30px' }} alt="Image 13" />
                        </Link>
                    </div>
                    <div className="col-md  text-center col-6">
                        <Link
                            to={{
                                pathname: "/" + props.countryUrl + "-" + props.language + "/items/products",
                                search: "filter_by_category%5B%5D=549"
                            }}
                        >
                            <img src={of4} className="img-fluid custom-image" style={{ mariginTop: '-30px' }} alt="Image 14" />
                        </Link>
                    </div>
                </div>
            </div>




            <div className="emptyspacer"></div>



            <div className="row1freeinfo">
                <div>
                    <img src={d1} className="img-fluid custom-image" alt="Image 7" />
                </div>
                <div>          <img src={d2} className="img-fluid custom-image" alt="Image 8" />
                </div>
                <div>          <img src={d3} className="img-fluid custom-image" alt="Image 9" />
                </div>
            </div>

            <div className="row justify-content-center">
                <div className="col-md-4 mt-5 text-center">
                    <img
                        src={t1}
                        className="img-fluid custom-image"
                        alt="Image 10"
                        style={{ paddingLeft: '40px', paddingRight: '40px' }}
                    />
                </div>
            </div>

            <div className="row justify-content-center">
                <div className="col-md mt-5 text-center col-6">
                    <Link
                        to={{
                            pathname: "/" + props.countryUrl + "-" + props.language + "/items/products",
                            search: "filter_by_category%5B%5D=227&filter_by_brand%5B%5D=201&filter_by_brand%5B%5D=171&filter_by_price%5Bmax%5D=30"
                        }}
                    >
                        <img src={ua3} className="img-fluid custom-image custom-image-1" alt="Image 11" />
                    </Link>
                </div>
                <div className="col-md mt-5 text-center col-6">
                    <Link
                        to={{
                            pathname: "/" + props.countryUrl + "-" + props.language + "/items/products",
                            search: "filter_by_category%5B%5D=226&filter_by_brand%5B%5D=201&filter_by_brand%5B%5D=171&filter_by_price%5Bmax%5D=70"
                        }}
                    >
                        <img src={ua4} className="img-fluid custom-image custom-image-2" alt="Image 12" />
                    </Link>
                </div>
                <div className="col-md mt-5 text-center col-6 elecsale">
                    <Link
                        to={{
                            pathname: "/" + props.countryUrl + "-" + props.language + "/items/products",
                            search: "filter_by_category%5B%5D=228&filter_by_brand%5B%5D=201&filter_by_brand%5B%5D=171&filter_by_price%5Bmax%5D=20"
                        }}
                    >
                        <img src={ua5} className="img-fluid custom-image custom-image-1" alt="Image 13" />
                    </Link>
                </div>
                <div className="col-md mt-5 text-center col-6 elecsale">
                    <Link
                        to={{
                            pathname: "/" + props.countryUrl + "-" + props.language + "/items/products",
                            search: "filter_by_category%5B%5D=224&filter_by_brand%5B%5D=201&filter_by_brand%5B%5D=171&filter_by_price%5Bmax%5D=100"
                        }}
                    >
                        <img src={ua6} className="img-fluid custom-image custom-image-2" alt="Image 14" />
                    </Link>
                </div>
            </div>


            <div className="emptyspacer"></div>

            <div className="home-appliances-mob">
                <div className="emptyspacer"></div>
                <div className="titlewrap px-2">
                    <h2 className="section-title lft catsec">{section1["title_" + props.language]}</h2>
                </div>
                <div className="product-cont mt-3">
                    {section1?.products?.map((data) => (
                        <Link
                            style={{ textDecoration: "none" }}
                            to={{
                                pathname:
                                    "/" +
                                    props.countryUrl +
                                    "-" +
                                    props.language +
                                    `/${data.category_title_en
                                        .toLowerCase()
                                        .replace(/ /g, "-")}/${data.slug}`,
                                state: {
                                    id: data.id,
                                    type: data.variants ? "products" : "services",
                                },
                                search: data.variants ? "?sku=" + data.variants[0].sku : "?sku=" + data.sku
                            }}
                            key={data.id + Math.random()}
                        >
                            <div
                                className="image-cont"
                                key={data.id + Math.random() + "prod"}
                            >
                                <ProductCard
                                    key={data.id + Math.random()}
                                    // img={getMobileImage(
                                    //   data.variants
                                    //     ? data.variants[0].featured_image_resized
                                    //     : data.featured_image_resized,
                                    //   data.variants
                                    //     ? data.variants[0].featured_image
                                    //     : data.featured_image
                                    // )}
                                    img={
                                        data.variants
                                            ? data.variants[0].featured_image
                                            : data.featured_image
                                    }
                                    storeLogo={data.store_logo}
                                    wishlisted={
                                        data.variants
                                            ? data.variants[0].wishlisted
                                            : data.wishlisted
                                    }
                                    home={true}
                                    type={data.variants ? "products" : "services"}
                                    id={data.variants ? data.variants[0].id : data.id}
                                    rating={data.average_rating}
                                    reviews={data.reviews_count}
                                    name={data[`name_${props.language}`]}
                                    data={data.variants ? data.variants[0] : data}
                                    coupon={data.coupon}
                                    is_inquiry={data.is_inquiry}
                                    product_type={data.product_type}
                                    start_price={data.variants ? data.variants[0].start_price : data.start_price}
                                    end_price={data.variants ? data.variants[0].end_price : data.end_price}
                                />
                            </div>
                        </Link>
                    ))}
                </div>

                <div className="emptyspacer"></div>


                <div className="row justify-content-center" style={{ backgroundColor: '#742013' }}>
                    <div className="col-md mt-5 text-center col-12">
                        <h2 style={{ fontWeight: 'bold', color: 'white' }}>Power up your walls</h2>
                        <h4 style={{ color: 'white', fontSize: '18px' }}>Upgrade your space with stylish sockets</h4>
                    </div>
                    <div className="col-md text-center col-6">
                        <Link
                            to={{
                                pathname: "/" + props.countryUrl + "-" + props.language + "/electrical/schneider-lisse-1-gang-2-way-switch-p-17406?sku=GGBL012NIS",

                            }}
                        >
                            <img src={b2} className="img-fluid custom-image custom-image" alt="Image 11" />
                        </Link>
                    </div>
                    <div className="col-md text-center col-6">
                        <Link
                            to={{
                                pathname: "/" + props.countryUrl + "-" + props.language + "/electrical/schneider-lisse-1-gang-13a-switched-socket-p-17427?sku=GGBL3010NIS",

                            }}
                        >
                            <img src={b3} className="img-fluid custom-image custom-image" alt="Image 12" />
                        </Link>
                    </div>
                    <div className="col-md text-center col-6">
                        <Link
                            to={{
                                pathname: "/" + props.countryUrl + "-" + props.language + "/electrical/legrand-2p-e-13-a-250-v-british-standard-plug-p-22517?sku=650013",

                            }}
                        >
                            <img src={b4} className="img-fluid custom-image custom-image" alt="Image 13" />
                        </Link>
                    </div>
                    <div className="col-md text-center col-6">
                        <Link
                            to={{
                                pathname: "/" + props.countryUrl + "-" + props.language + "/electrical/schneider-vivace-a-c-usb-charger-p-17382?sku=KB32ACQUSB_WE",

                            }}
                        >
                            <img src={b5} className="img-fluid custom-image custom-image" alt="Image 14" />
                        </Link>
                    </div>
                </div>

                {/* here123 here  */}
                <div className="emptyspacer"></div>


                <div className="titlewrap px-2">
                    <h2 className="section-title lft catsec">{section2["title_" + props.language]}</h2>
                </div>
                <div className="product-cont mt-3">
                    {section2?.products?.map((data) => (
                        <Link
                            style={{ textDecoration: "none" }}
                            to={{
                                pathname:
                                    "/" +
                                    props.countryUrl +
                                    "-" +
                                    props.language +
                                    `/${data.category_title_en
                                        .toLowerCase()
                                        .replace(/ /g, "-")}/${data.slug}`,
                                state: {
                                    id: data.id,
                                    type: data.variants ? "products" : "services",
                                },
                                search: data.variants ? "?sku=" + data.variants[0].sku : "?sku=" + data.sku
                            }}
                            key={data.id + Math.random()}
                        >
                            <div
                                className="image-cont"
                                key={data.id + Math.random() + "prod"}
                            >
                                <ProductCard
                                    key={data.id + Math.random()}
                                    // img={getMobileImage(
                                    //   data.variants
                                    //     ? data.variants[0].featured_image_resized
                                    //     : data.featured_image_resized,
                                    //   data.variants
                                    //     ? data.variants[0].featured_image
                                    //     : data.featured_image
                                    // )}
                                    img={
                                        data.variants
                                            ? data.variants[0].featured_image
                                            : data.featured_image
                                    }
                                    storeLogo={data.store_logo}
                                    wishlisted={
                                        data.variants
                                            ? data.variants[0].wishlisted
                                            : data.wishlisted
                                    }
                                    home={true}
                                    type={data.variants ? "products" : "services"}
                                    id={data.variants ? data.variants[0].id : data.id}
                                    rating={data.average_rating}
                                    reviews={data.reviews_count}
                                    name={data[`name_${props.language}`]}
                                    data={data.variants ? data.variants[0] : data}
                                    coupon={data.coupon}
                                    is_inquiry={data.is_inquiry}
                                    product_type={data.product_type}
                                    start_price={data.variants ? data.variants[0].start_price : data.start_price}
                                    end_price={data.variants ? data.variants[0].end_price : data.end_price}
                                />
                            </div>
                        </Link>
                    ))}
                </div>

                <div className="emptyspacer"></div>



                <div className="titlewrap px-2">
                    <h2 className="section-title lft catsec">{section3["title_" + props.language]}</h2>
                </div>
                <div className="product-cont mt-3">
                    {section3?.products?.map((data) => (
                        <Link
                            style={{ textDecoration: "none" }}
                            to={{
                                pathname:
                                    "/" +
                                    props.countryUrl +
                                    "-" +
                                    props.language +
                                    `/${data.category_title_en
                                        .toLowerCase()
                                        .replace(/ /g, "-")}/${data.slug}`,
                                state: {
                                    id: data.id,
                                    type: data.variants ? "products" : "services",
                                },
                                search: data.variants ? "?sku=" + data.variants[0].sku : "?sku=" + data.sku
                            }}
                            key={data.id + Math.random()}
                        >
                            <div
                                className="image-cont"
                                key={data.id + Math.random() + "prod"}
                            >
                                <ProductCard
                                    key={data.id + Math.random()}
                                    // img={getMobileImage(
                                    //   data.variants
                                    //     ? data.variants[0].featured_image_resized
                                    //     : data.featured_image_resized,
                                    //   data.variants
                                    //     ? data.variants[0].featured_image
                                    //     : data.featured_image
                                    // )}
                                    img={
                                        data.variants
                                            ? data.variants[0].featured_image
                                            : data.featured_image
                                    }
                                    storeLogo={data.store_logo}
                                    wishlisted={
                                        data.variants
                                            ? data.variants[0].wishlisted
                                            : data.wishlisted
                                    }
                                    home={true}
                                    type={data.variants ? "products" : "services"}
                                    id={data.variants ? data.variants[0].id : data.id}
                                    rating={data.average_rating}
                                    reviews={data.reviews_count}
                                    name={data[`name_${props.language}`]}
                                    data={data.variants ? data.variants[0] : data}
                                    coupon={data.coupon}
                                    is_inquiry={data.is_inquiry}
                                    product_type={data.product_type}
                                    start_price={data.variants ? data.variants[0].start_price : data.start_price}
                                    end_price={data.variants ? data.variants[0].end_price : data.end_price}
                                />
                            </div>
                        </Link>
                    ))}
                </div>

                <div className="emptyspacer"></div>
          

<div className="titlewrap px-2">
    <h2 className="section-title lft catsec">{section4["title_" + props.language]}</h2>
</div>
<div className="product-cont mt-3">
    {section4?.products?.map((data) => (
        <Link
            style={{ textDecoration: "none" }}
            to={{
                pathname:
                    "/" +
                    props.countryUrl +
                    "-" +
                    props.language +
                    `/${data.category_title_en
                        .toLowerCase()
                        .replace(/ /g, "-")}/${data.slug}`,
                state: {
                    id: data.id,
                    type: data.variants ? "products" : "services",
                },
                search: data.variants ? "?sku=" + data.variants[0].sku : "?sku=" + data.sku
            }}
            key={data.id + Math.random()}
        >
            <div
                className="image-cont"
                key={data.id + Math.random() + "prod"}
            >
                <ProductCard
                    key={data.id + Math.random()}
                    // img={getMobileImage(
                    //   data.variants
                    //     ? data.variants[0].featured_image_resized
                    //     : data.featured_image_resized,
                    //   data.variants
                    //     ? data.variants[0].featured_image
                    //     : data.featured_image
                    // )}
                    img={
                        data.variants
                            ? data.variants[0].featured_image
                            : data.featured_image
                    }
                    storeLogo={data.store_logo}
                    wishlisted={
                        data.variants
                            ? data.variants[0].wishlisted
                            : data.wishlisted
                    }
                    home={true}
                    type={data.variants ? "products" : "services"}
                    id={data.variants ? data.variants[0].id : data.id}
                    rating={data.average_rating}
                    reviews={data.reviews_count}
                    name={data[`name_${props.language}`]}
                    data={data.variants ? data.variants[0] : data}
                    coupon={data.coupon}
                    is_inquiry={data.is_inquiry}
                    product_type={data.product_type}
                    start_price={data.variants ? data.variants[0].start_price : data.start_price}
                    end_price={data.variants ? data.variants[0].end_price : data.end_price}
                />
            </div>
        </Link>
    ))}
</div>


                <div className="emptyspacer"></div>


                <div className="titlewrap px-2">
                    <h2 className="section-title lft catsec">{section5["title_" + props.language]}</h2>
                </div>
                <div className="product-cont mt-3">
                    {section5?.products?.map((data) => (
                        <Link
                            style={{ textDecoration: "none" }}
                            to={{
                                pathname:
                                    "/" +
                                    props.countryUrl +
                                    "-" +
                                    props.language +
                                    `/${data.category_title_en
                                        .toLowerCase()
                                        .replace(/ /g, "-")}/${data.slug}`,
                                state: {
                                    id: data.id,
                                    type: data.variants ? "products" : "services",
                                },
                                search: data.variants ? "?sku=" + data.variants[0].sku : "?sku=" + data.sku
                            }}
                            key={data.id + Math.random()}
                        >
                            <div
                                className="image-cont"
                                key={data.id + Math.random() + "prod"}
                            >
                                <ProductCard
                                    key={data.id + Math.random()}
                                    // img={getMobileImage(
                                    //   data.variants
                                    //     ? data.variants[0].featured_image_resized
                                    //     : data.featured_image_resized,
                                    //   data.variants
                                    //     ? data.variants[0].featured_image
                                    //     : data.featured_image
                                    // )}
                                    img={
                                        data.variants
                                            ? data.variants[0].featured_image
                                            : data.featured_image
                                    }
                                    storeLogo={data.store_logo}
                                    wishlisted={
                                        data.variants
                                            ? data.variants[0].wishlisted
                                            : data.wishlisted
                                    }
                                    home={true}
                                    type={data.variants ? "products" : "services"}
                                    id={data.variants ? data.variants[0].id : data.id}
                                    rating={data.average_rating}
                                    reviews={data.reviews_count}
                                    name={data[`name_${props.language}`]}
                                    data={data.variants ? data.variants[0] : data}
                                    coupon={data.coupon}
                                    is_inquiry={data.is_inquiry}
                                    product_type={data.product_type}
                                    start_price={data.variants ? data.variants[0].start_price : data.start_price}
                                    end_price={data.variants ? data.variants[0].end_price : data.end_price}
                                />
                            </div>
                        </Link>
                    ))}
                </div>

                <div className="emptyspacer"></div>




                <div className="emptyspacer"></div>


                <div className="titlewrap px-2">
                    <h2 className="section-title lft catsec">{section6["title_" + props.language]}</h2>
                </div>
                <div className="product-cont mt-3">
                    {section6?.products?.map((data) => (
                        <Link
                            style={{ textDecoration: "none" }}
                            to={{
                                pathname:
                                    "/" +
                                    props.countryUrl +
                                    "-" +
                                    props.language +
                                    `/${data.category_title_en
                                        .toLowerCase()
                                        .replace(/ /g, "-")}/${data.slug}`,
                                state: {
                                    id: data.id,
                                    type: data.variants ? "products" : "services",
                                },
                                search: data.variants ? "?sku=" + data.variants[0].sku : "?sku=" + data.sku
                            }}
                            key={data.id + Math.random()}
                        >
                            <div
                                className="image-cont"
                                key={data.id + Math.random() + "prod"}
                            >
                                <ProductCard
                                    key={data.id + Math.random()}
                                    // img={getMobileImage(
                                    //   data.variants
                                    //     ? data.variants[0].featured_image_resized
                                    //     : data.featured_image_resized,
                                    //   data.variants
                                    //     ? data.variants[0].featured_image
                                    //     : data.featured_image
                                    // )}
                                    img={
                                        data.variants
                                            ? data.variants[0].featured_image
                                            : data.featured_image
                                    }
                                    storeLogo={data.store_logo}
                                    wishlisted={
                                        data.variants
                                            ? data.variants[0].wishlisted
                                            : data.wishlisted
                                    }
                                    home={true}
                                    type={data.variants ? "products" : "services"}
                                    id={data.variants ? data.variants[0].id : data.id}
                                    rating={data.average_rating}
                                    reviews={data.reviews_count}
                                    name={data[`name_${props.language}`]}
                                    data={data.variants ? data.variants[0] : data}
                                    coupon={data.coupon}
                                    is_inquiry={data.is_inquiry}
                                    product_type={data.product_type}
                                    start_price={data.variants ? data.variants[0].start_price : data.start_price}
                                    end_price={data.variants ? data.variants[0].end_price : data.end_price}
                                />
                            </div>
                        </Link>
                    ))}
                </div>

                <div className="emptyspacer"></div>




                <div className="emptyspacer"></div>


                <div className="titlewrap px-2">
                    <h2 className="section-title lft catsec">{section7["title_" + props.language]}</h2>
                </div>
                <div className="product-cont mt-3">
                    {section7?.products?.map((data) => (
                        <Link
                            style={{ textDecoration: "none" }}
                            to={{
                                pathname:
                                    "/" +
                                    props.countryUrl +
                                    "-" +
                                    props.language +
                                    `/${data.category_title_en
                                        .toLowerCase()
                                        .replace(/ /g, "-")}/${data.slug}`,
                                state: {
                                    id: data.id,
                                    type: data.variants ? "products" : "services",
                                },
                                search: data.variants ? "?sku=" + data.variants[0].sku : "?sku=" + data.sku
                            }}
                            key={data.id + Math.random()}
                        >
                            <div
                                className="image-cont"
                                key={data.id + Math.random() + "prod"}
                            >
                                <ProductCard
                                    key={data.id + Math.random()}
                                    // img={getMobileImage(
                                    //   data.variants
                                    //     ? data.variants[0].featured_image_resized
                                    //     : data.featured_image_resized,
                                    //   data.variants
                                    //     ? data.variants[0].featured_image
                                    //     : data.featured_image
                                    // )}
                                    img={
                                        data.variants
                                            ? data.variants[0].featured_image
                                            : data.featured_image
                                    }
                                    storeLogo={data.store_logo}
                                    wishlisted={
                                        data.variants
                                            ? data.variants[0].wishlisted
                                            : data.wishlisted
                                    }
                                    home={true}
                                    type={data.variants ? "products" : "services"}
                                    id={data.variants ? data.variants[0].id : data.id}
                                    rating={data.average_rating}
                                    reviews={data.reviews_count}
                                    name={data[`name_${props.language}`]}
                                    data={data.variants ? data.variants[0] : data}
                                    coupon={data.coupon}
                                    is_inquiry={data.is_inquiry}
                                    product_type={data.product_type}
                                    start_price={data.variants ? data.variants[0].start_price : data.start_price}
                                    end_price={data.variants ? data.variants[0].end_price : data.end_price}
                                />
                            </div>
                        </Link>
                    ))}
                </div>

                <div className="emptyspacer"></div>










            </div>
        </MobileLayoutRenderer>
    );
};

const mapStateToProps = (state) => {
    return {
        countryUrl: state.language.countryUrl,
        countryId: state.language.countryId,
        language: state.language.language,
    };
};


export default connect(mapStateToProps, null)(CampingPageMobile);
