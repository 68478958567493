import React from "react";
import { connect } from "react-redux";
import "../../../assets/styles/component/customer/AllItems/ItemsCategory.scss";

function ItemsCategory(props) {
  return (
    <div
      className="item-category-desktop w-100"
      style={{ textAlign: "initial" }}
    >
      {props.categories.map((ele) => (
        <div key={ele.id} className="w-100 mb-3">
          <div
            onClick={() => {
              let subCat = [];
              ele.sub_categories.map((data) => {
                subCat.push(data.id);
                data.sub_categories &&
                  data.sub_categories.map((subCat3) => {
                    subCat.push(subCat3.id);
                    subCat3.sub_categories &&
                      subCat3.sub_categories.map((subCat4) => {
                        subCat.push(subCat4.id);
                        return subCat4;
                      });
                    return subCat3;
                  });
                return data;
              });
              props.selectedCat([ele.id, ...subCat]);
            }}
            className={`pointer items-category-heading1
             ${ 
              props.selectedCategory[0] === ele.id ? " active" : ""
            }`}
          >
            {ele[`title_${props.language}`]}
          </div>

          {ele.sub_categories.map((val, index) => (
            <div key={val.id}>
              <div
                key={val.id}
                onClick={() => {
                  let subCat = [];
                  val.sub_categories &&
                    val.sub_categories.map((subCat3) => {
                      subCat.push(subCat3.id);
                      subCat3.sub_categories &&
                        subCat3.sub_categories.map((subCat4) => {
                          subCat.push(subCat4.id);
                          return subCat4;
                        });
                      return subCat3;
                    });
                  props.selectedCat([val.id, ...subCat]);
                }}
                className="items-category-val-cont mt-2 pointer"
              >
                <span
                  className={`pointer items-category-val ${
                    props.selectedCategory[0] === val.id ? " active" : ""
                  }`}
                >
                  {val[`title_${props.language}`]}
                </span>
              </div>

              {val.sub_categories?.map((subCat, index) => (
                <div key={subCat.id}>
                  <div
                    onClick={() => {
                      let subCatArr = [];
                      subCat.sub_categories &&
                        subCat.sub_categories.map((subCat4) =>
                          subCatArr.push(subCat4.id)
                        );
                      props.selectedCat([subCat.id, ...subCatArr]);
                    }}
                    className="items-category-sub-cat-three-cont mt-2 pointer"
                  >
                    <span
                      className={`items-category-val-two ${
                        props.selectedCategory[0] === subCat.id ? " active" : ""
                      }`}
                    >
                      {subCat[`title_${props.language}`]}
                    </span>
                  </div>

                  {subCat.sub_categories?.map((subCatThree, index) => (
                    <div
                      key={subCatThree.id}
                      onClick={() => props.selectedCat([subCatThree.id])}
                      className="items-category-sub-cat-four-cont mt-2 pointer"
                    >
                      <span
                        className={`items-category-val-three ${
                          props.selectedCategory[0] === subCatThree.id
                            ? " active"
                            : ""
                        }`}
                      >
                        {subCatThree[`title_${props.language}`]}
                      </span>
                    </div>
                  ))}
                </div>
              ))}
            </div>
          ))}
        </div>
      ))}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    selectedCategory: state.categories.selectedCat,
    language: state.language.language,
  };
};

export default connect(mapStateToProps, null)(ItemsCategory);
