import React, { useEffect, useState } from "react";
import { RiSearchLine } from "react-icons/ri";
import { set_loading } from "../../../redux/actions/LayoutAction";
import logo from "../../../assets/images/logo.png";
import { connect } from "react-redux";
import { set_vendor_data } from "../../../redux/actions/VendorShopAction";
import { toast } from "react-toastify";
import { Link, Redirect, useParams } from "react-router-dom";
import MobileLayoutRenderer from "../../../pages/mobile/layout/MobileLayoutRenderer";
import { useTranslation } from "react-i18next";
import { set_country } from "../../../redux/actions/LayoutAction";
import { set_cart_items } from "../../../redux/actions/CartAction";
import { set_redirect_home } from "../../../redux/actions/NotFoundAction";
import "../../../assets/styles/component/mobile/Home/HomeMobile.scss";
import "react-toastify/dist/ReactToastify.css";
import pagesbannerimg from "../../../assets/images/footer-pages-banners/brands_az.jpg";
import { apiJson } from "../../../Api";
import "../../../assets/styles/footer-pages/brands-az.scss";

function BrandsAZComp(props) {
  const [brands, setBrands] = useState([]);
  let message = "";

  // eslint-disable-next-line
  const { id } = useParams();

  // eslint-disable-next-line
  const { t, i18n } = useTranslation();
  const [redirect, setRedirect] = useState({ value: false });

  useEffect(() => {
    apiJson.header.allBrands(props.countryId).then((response) => {
      if (response.success) {
        setBrands(response.data);
      } else if (!response.status) {
        // eslint-disable-next-line
        message = response.message
          ? response.message !== ""
            ? response.message
            : "something went wrong"
          : "something went wrong";
        showError();
      } else {
        if (response.status >= 400 && response.status < 500) {
          message = response.message
            ? response.message
            : response.errors
            ? response.errors
            : t("errors.wrong");
          showError();
        } else {
          message = response.message
            ? response.message !== ""
              ? response.message
              : t("errors.wrong")
            : t("errors.wrong");
          showError();
        }
      }
    });
  }, [props.countryId]);

  const showError = () => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  // eslint-disable-next-line
  function getMobileOperatingSystem() {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
      return "Windows Phone";
    }

    if (/android/i.test(userAgent)) {
      return "Android";
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return "iOS";
    }

    return "unknown";
  }

  return (
    <MobileLayoutRenderer
      NavbarTop={false}
      BackBtn={false}
      CancelBtn={false}
      NavbarBottom={true}
    >
      <div className="home-mob brands-desktop">
        <div className="header">
          <div className="logo">
            <img src={logo} width="100%" height="100%" alt="" />
          </div>
          <div className="search">
            <RiSearchLine
              onClick={() =>
                setRedirect({ value: true, path: "all-categories" })
              }
            />
          </div>
        </div>

        <div className="body" style={{ background: "#fcfaf4" }}>
          <div className="pagesbanner">
            <img
              src={pagesbannerimg}
              alt="category"
              style={{
                width: "100%",
                height: "100%",
                maxHeight: "400px",
              }}
            ></img>
          </div>
          <div className="content-wrapper">
            <div className="footer-pages-content brandsLogosSection">
              <div className="row">
                {brands.map((brand, i) => {
                  return (
                    <div className="col-lg-2 col-md-2 col-sm-3 col-4 text-center">
                      <Link
                        to={{
                          pathname:
                            "/" +
                            props.countryUrl +
                            "-" +
                            props.language +
                            "/items/products",
                          state: { brand: brand.id },
                        }}
                      >
                        <img
                          src={brand.image}
                          className="img-fluid"
                          style={{ height: "70px" }}
                          alt=""
                        />
                      </Link>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        {redirect.value && (
          <Redirect
            push
            to={{ pathname: redirect.path, state: redirect.state }}
          />
        )}
      </div>
    </MobileLayoutRenderer>
  );
}

const mapStateToProps = (state) => {
  return {
    // categories: state.home.categories,
    // products: state.vendor.products,
    // services: state.vendor.services,
    // sections: state.home.sections,
    // positionOne: state.home.positionOne,
    // positionTwo: state.home.positionTwo,
    // positionThree: state.home.positionThree,
    // positionFour: state.home.positionFour,
    // positionFive: state.home.positionFive,
    load: state.layout.loading,
    countryId: state.language.countryId,
    language: state.language.language,
    country: state.language.country,
    countryUrl: state.language.countryUrl,
    redirect: state.notFound.redirect,
  };
};

const mapDispatchToProps = {
  set_vendor_data,
  // set_home_categories,
  set_country,
  set_cart_items,
  set_redirect_home,
  set_loading,
};

export default connect(mapStateToProps, mapDispatchToProps)(BrandsAZComp);
