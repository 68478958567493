import React, { Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import $ from "jquery";
import MobileLayoutRenderer from "../layout/MobileLayoutRenderer";
import HeaderMobile from "../../../components/mobile/HeaderMobile";

// import TopBanner1 from "../../../assets/images/camping/banners/BagPacksMob.jpg";
// import TopBanner2 from "../../../assets/images/camping/banners/SleepingBagsMob.jpg";
// import TopBanner3 from "../../../assets/images/camping/banners/CampingTentsMob.jpg";
// import TopBanner4 from "../../../assets/images/camping/banners/WaterbottlesMob.jpg";

import TopBanner1 from "../../../assets/images/winter_sale_2023/Camping Sale - 800 x 300 GIF.gif";
import TopBanner2 from "../../../assets/images/winter_sale_2023/BackPacks 800 x 300 Mobile.jpg";
import TopBanner3 from "../../../assets/images/winter_sale_2023/Furniture 800 x 300 Mobile.jpg";
import TopBanner4 from "../../../assets/images/winter_sale_2023/Tents 800 x 300 Mobile.jpg";


import "../../../assets/styles/category-homepages/paints-page-mob.scss";
import { apiJson } from "../../../Api";
import ProductCard from "../../../components/mobile/Home/ProductCardMobile";

import CampingTentsImg from "../../../assets/images/camping/section2/CampingTents.jpg";
import SleepingBagsImg from "../../../assets/images/camping/section2/SleepingBags.jpg";
import CampingFurnitureImg from "../../../assets/images/camping/section2/CampingFurniture.jpg";
import CharcoalImg from "../../../assets/images/camping/section2/Charcoal.jpg";
import FlashlightImg from "../../../assets/images/camping/section2/Flashlight.jpg";
import WaterBottleImg from "../../../assets/images/camping/section2/WaterBottle.jpg";
import CampingBackpacksImg from "../../../assets/images/camping/section2/CampingBackpacks.jpg";
import CampingUtensilsImg from "../../../assets/images/camping/section2/CampingUtensils.jpg";
import CampingCoolersImg from "../../../assets/images/camping/section2/CampingCoolers.jpg";
import BBQgrillsImg from "../../../assets/images/camping/section2/BBQgrills.jpeg";
import CampingToolsImg from "../../../assets/images/camping/section2/CampingTools.jpg";
import CapsCoolnetsImg from "../../../assets/images/camping/section2/Caps_coolnets.jpg";
import BinocularsImg from "../../../assets/images/camping/section2/Binoculars.jpg";

import CampingFurnitureBanner from "../../../assets/images/winter_sale_2023/WinterSale_Backpacks_900x525.jpg";
import BinocularBanner from "../../../assets/images/winter_sale_2023/WinterSale_CampingTents_900x525.jpg";
import CampingCoolersBanner from "../../../assets/images/winter_sale_2023/WinterSale_CampingFurniture_900x525.jpg";
import BBQgrillsBanner from "../../../assets/images/winter_sale_2023/WinterSale_CampingCoolers_900x525.jpg";

import Under30 from "../../../assets/images/camping/section4/under30.png";
import Under50 from "../../../assets/images/camping/section4/under50.png";
import Under100 from "../../../assets/images/camping/section4/under100.png";
import Under200 from "../../../assets/images/camping/section4/under200.png";

import Upto30 from "../../../assets/images/camping/discount/upto30.png";
import Upto50 from "../../../assets/images/camping/discount/upto50.png";
import Upto70 from "../../../assets/images/camping/discount/upto70.png";
import Upto90 from "../../../assets/images/camping/discount/upto90.png";

import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination, Navigation } from "swiper/core";
SwiperCore.use([Pagination, Navigation]);

const CampingPageMobile = (props) => {
  const [products, setProducts] = useState([]);
  const [products2, setProducts2] = useState([]);

  useEffect(() => {
    window.document.title = "Camping";

    apiJson.
      sections.getCamping1Section(props.countryId)
      .then(response => {
        if (response.success) {
          setProducts(response.data.section.products);
        }
      });

    apiJson.
      sections.getCamping2Section(props.countryId)
      .then(response => {
        if (response.success) {
          setProducts2(response.data.section.products);
        }
      });

    $("html, body").animate({ scrollTop: 0 }, "fast");
  }, []);
  return (
    <MobileLayoutRenderer
      NavbarTop={false}
      BackBtn={false}
      CancelBtn={false}
      NavbarBottom={true}
      SearchIcon={true}
    >
      <div className="home-mob">
        <HeaderMobile />
      </div>
      <div className="banner-sec">
        <Swiper
          spaceBetween={0}
          slidesPerView={1}
          pagination={false}
          navigation={true}
        >
          <SwiperSlide
            onClick={(e) => e.preventDefault()}
            key={1}
          >
            {/* <Link to={{
              pathname: "/" + props.countryUrl +
                "-" + props.language + "/items/products",
              search: "filter_by_category%5B%5D=498"
            }}> */}
              <img
                src={TopBanner1}
                className="img img-fluid"
                height="110px"
              />
            {/* </Link> */}
          </SwiperSlide>
          <SwiperSlide
            onClick={(e) => e.preventDefault()}
            key={1}
          >
            {/* <Link to={{
              pathname: "/" + props.countryUrl +
                "-" + props.language + "/items/products",
              search: "filter_by_category%5B%5D=500"
            }}> */}
              <img
                src={TopBanner2}
                className="img img-fluid"
                height="110px"
              />
            {/* </Link> */}
          </SwiperSlide>
          <SwiperSlide
            onClick={(e) => e.preventDefault()}
            key={1}
          >
            {/* <Link to={{
              pathname: "/" + props.countryUrl +
                "-" + props.language + "/items/products",
              search: "filter_by_category%5B%5D=507"
            }}> */}
              <img
                src={TopBanner3}
                className="img img-fluid"
                height="110px"
              />
            {/* </Link> */}
          </SwiperSlide>
          <SwiperSlide
            onClick={(e) => e.preventDefault()}
            key={1}
          >
            {/* <Link to={{
              pathname: "/" + props.countryUrl +
                "-" + props.language + "/items/products",
              search: "filter_by_category%5B%5D=508"
            }}> */}
              <img
                src={TopBanner4}
                className="img img-fluid"
                height="110px"
              />
            {/* </Link> */}
          </SwiperSlide>
        </Swiper>
      </div>
      <div className="paints-page-mob">
        <div className="emptyspacer"></div>

        <div className="px-2">
          <h2 className="sectiontitle-individualpage text-left">Shop by Category</h2>

          <div className="emptyspacer"></div>

          <Swiper
            spaceBetween={20}
            slidesPerView={4}
            pagination={false}
            navigation={true}
          >
            <SwiperSlide
              onClick={(e) => e.preventDefault()}
              key={1}
            >
              <Link to={{
                pathname: "/" + props.countryUrl +
                  "-" + props.language + "/items/products",
                search: "filter_by_category%5B%5D=500"
              }}>
                <img
                  src={CampingTentsImg}
                  className="img img-fluid"
                  height="110px"
                />
              </Link>
            </SwiperSlide>
            <SwiperSlide
              onClick={(e) => e.preventDefault()}
              key={1}
            >
              <Link to={{
                pathname: "/" + props.countryUrl +
                  "-" + props.language + "/items/products",
                search: "filter_by_category%5B%5D=507"
              }}>
                <img
                  src={SleepingBagsImg}
                  className="img img-fluid"
                  height="110px"
                />
              </Link>
            </SwiperSlide>
            <SwiperSlide
              onClick={(e) => e.preventDefault()}
              key={1}
            >
              <Link to={{
                pathname: "/" + props.countryUrl +
                  "-" + props.language + "/items/products",
                search: "filter_by_category%5B%5D=499"
              }}>
                <img
                  src={CampingFurnitureImg}
                  className="img img-fluid"
                  height="110px"
                />
              </Link>
            </SwiperSlide>
            <SwiperSlide
              onClick={(e) => e.preventDefault()}
              key={1}
            >
              <Link to={{
                pathname: "/" + props.countryUrl +
                  "-" + props.language + "/items/products",
                search: "filter_by_category%5B%5D=503"
              }}>
                <img
                  src={CharcoalImg}
                  className="img img-fluid"
                  height="110px"
                />
              </Link>
            </SwiperSlide>
            <SwiperSlide
              onClick={(e) => e.preventDefault()}
              key={1}
            >
              <Link to={{
                pathname: "/" + props.countryUrl +
                  "-" + props.language + "/items/products",
                search: "filter_by_category%5B%5D=505"
              }}>
                <img
                  src={FlashlightImg}
                  className="img img-fluid"
                  height="110px"
                />
              </Link>
            </SwiperSlide>
            <SwiperSlide
              onClick={(e) => e.preventDefault()}
              key={1}
            >
              <Link to={{
                pathname: "/" + props.countryUrl +
                  "-" + props.language + "/items/products",
                search: "filter_by_category%5B%5D=508"
              }}>
                <img
                  src={WaterBottleImg}
                  className="img img-fluid"
                  height="110px"
                />
              </Link>
            </SwiperSlide>
            <SwiperSlide
              onClick={(e) => e.preventDefault()}
              key={1}
            >
              <Link to={{
                pathname: "/" + props.countryUrl +
                  "-" + props.language + "/items/products",
                search: "filter_by_category%5B%5D=498"
              }}>
                <img
                  src={CampingBackpacksImg}
                  className="img img-fluid"
                  height="110px"
                />
              </Link>
            </SwiperSlide>
            <SwiperSlide
              onClick={(e) => e.preventDefault()}
              key={1}
            >
              <Link to={{
                pathname: "/" + props.countryUrl +
                  "-" + props.language + "/items/products",
                search: "filter_by_category%5B%5D=501"
              }}>
                <img
                  src={CampingUtensilsImg}
                  className="img img-fluid"
                  height="110px"
                />
              </Link>
            </SwiperSlide>
            <SwiperSlide
              onClick={(e) => e.preventDefault()}
              key={1}
            >
              <Link to={{
                pathname: "/" + props.countryUrl +
                  "-" + props.language + "/items/products",
                search: "filter_by_category%5B%5D=510"
              }}>
                <img
                  src={CampingCoolersImg}
                  className="img img-fluid"
                  height="110px"
                />
              </Link>
            </SwiperSlide>
            <SwiperSlide
              onClick={(e) => e.preventDefault()}
              key={1}
            >
              <Link to={{
                pathname: "/" + props.countryUrl +
                  "-" + props.language + "/items/products",
                search: "filter_by_category%5B%5D=496"
              }}>
                <img
                  src={BBQgrillsImg}
                  className="img img-fluid"
                  height="110px"
                />
              </Link>
            </SwiperSlide>
            <SwiperSlide
              onClick={(e) => e.preventDefault()}
              key={1}
            >
              <Link to={{
                pathname: "/" + props.countryUrl +
                  "-" + props.language + "/items/products",
                search: "filter_by_category%5B%5D=495"
              }}>
                <img
                  src={CampingToolsImg}
                  className="img img-fluid"
                  height="110px"
                />
              </Link>
            </SwiperSlide>
            <SwiperSlide
              onClick={(e) => e.preventDefault()}
              key={1}
            >
              <Link to={{
                pathname: "/" + props.countryUrl +
                  "-" + props.language + "/items/products",
                search: "filter_by_category%5B%5D=502"
              }}>
                <img
                  src={CapsCoolnetsImg}
                  className="img img-fluid"
                  height="110px"
                />
              </Link>
            </SwiperSlide>
            <SwiperSlide
              onClick={(e) => e.preventDefault()}
              key={1}
            >
              <Link to={{
                pathname: "/" + props.countryUrl +
                  "-" + props.language + "/items/products",
                search: "filter_by_category%5B%5D=497"
              }}>
                <img
                  src={BinocularsImg}
                  className="img img-fluid"
                  height="110px"
                />
              </Link>
            </SwiperSlide>
          </Swiper>
        </div>

        <div className="row mt-4">
          <div className="col-6 mt-2 mb-2">
            <Link to={{
              pathname: "/" + props.countryUrl +
                "-" + props.language + "/items/products",
              search: "filter_by_category%5B%5D=498"
            }}>
              <img
                src={CampingFurnitureBanner}
                alt="category"
                className="img img-fluid"
              />
            </Link>
          </div>
          <div className="col-6 mt-2 mb-2">
            <Link to={{
              pathname: "/" + props.countryUrl +
                "-" + props.language + "/items/products",
              search: "filter_by_category%5B%5D=500"
            }}>
              <img
                src={BinocularBanner}
                alt="category"
                className="img img-fluid"
              />
            </Link>
          </div>
          <div className="col-6 mt-2 mb-2">
            <Link to={{
              pathname: "/" + props.countryUrl +
                "-" + props.language + "/items/products",
              search: "filter_by_category%5B%5D=499"
            }}>
              <img
                src={CampingCoolersBanner}
                alt="category"
                className="img img-fluid"
              />
            </Link>
          </div>
          <div className="col-6 mt-2 mb-2">
            <Link to={{
              pathname: "/" + props.countryUrl +
                "-" + props.language + "/items/products",
              search: "filter_by_category%5B%5D=510"
            }}>
              <img
                src={BBQgrillsBanner}
                alt="category"
                className="img img-fluid"
              />
            </Link>
          </div>
        </div>

        <div className="emptyspacer"></div>

        <div className="px-2">
          <h2 className="sectiontitle-individualpage text-left">Shop by Price</h2>
          <div className="emptyspacer"></div>
          <div className="row">
            <div className="col-3">
              <Link to={{
                pathname: "/" + props.countryUrl +
                  "-" + props.language + "/items/products",
                search: "filter_by_price%5Bmax%5D=30&filter_by_store%5B%5D=88"
              }}>
                <img src={Under30} className="img-img-fluid" width="100%" />
              </Link>
            </div>
            <div className="col-3">
              <Link to={{
                pathname: "/" + props.countryUrl +
                  "-" + props.language + "/items/products",
                search: "filter_by_price%5Bmax%5D=50&filter_by_store%5B%5D=88"
              }}>
                <img src={Under50} className="img-img-fluid" width="100%" />
              </Link>
            </div>
            <div className="col-3">
              <Link to={{
                pathname: "/" + props.countryUrl +
                  "-" + props.language + "/items/products",
                search: "filter_by_price%5Bmax%5D=100&filter_by_store%5B%5D=88"
              }}>
                <img src={Under100} className="img-img-fluid" width="100%" />
              </Link>
            </div>
            <div className="col-3">
              <Link to={{
                pathname: "/" + props.countryUrl +
                  "-" + props.language + "/items/products",
                search: "filter_by_price%5Bmax%5D=200&filter_by_store%5B%5D=88"
              }}>
                <img src={Under200} className="img-img-fluid" width="100%" />
              </Link>
            </div>
          </div>
        </div>

        <div className="emptyspacer"></div>
        <div className="px-2">
          <h2 className="sectiontitle-individualpage text-left">Shop by Discount</h2>
          <div className="emptyspacer"></div>
          <div className="row">
            <div className="col-3">
              <Link to={"/" + props.countryUrl +
                "-" + props.language + "/offers/section/upto-30-off"}>
                <img src={Upto30} className="img-img-fluid" width="100%" />
              </Link>
            </div>
            <div className="col-3">
              <Link to={"/" + props.countryUrl +
                "-" + props.language + "/offers/section/upto-50-off"}>
                <img src={Upto50} className="img-img-fluid" width="100%" />
              </Link>
            </div>
            <div className="col-3">
              <Link to={"/" + props.countryUrl +
                "-" + props.language + "/offers/section/upto-70-off"}>
                <img src={Upto70} className="img-img-fluid" width="100%" />
              </Link>
            </div>
            <div className="col-3">
              <Link to={"/" + props.countryUrl +
                "-" + props.language + "/offers/section/upto-90-off"}>
                <img src={Upto90} className="img-img-fluid" width="100%" />
              </Link>
            </div>
          </div>
        </div>
        <div className="emptyspacer"></div>

        <div className="titlewrap px-2">
          <h2 className="sectiontitle-individualpage text-left">Best Selling</h2>
        </div>
        <div className="surfacepat" style={{ background: "#f8f8f8" }}>
          <div className="product-cont">
            {products?.map((data) => (
              <Link
                style={{ textDecoration: "none" }}
                to={{
                  pathname:
                    "/" +
                    props.countryUrl +
                    "-" +
                    props.language +
                    `/${data.category_title_en
                      .toLowerCase()
                      .replace(/ /g, "-")}/${data.slug}`,
                  state: {
                    id: data.id,
                    type: data.variants ? "products" : "services",
                    sku: data.variants[0].sku
                  },
                }}
                key={data.id + Math.random()}
              >
                <div
                  className="image-cont"
                  key={data.id + Math.random() + "prod"}
                >
                  <ProductCard
                    key={data.id + Math.random()}
                    img={
                      data.variants
                        ? data.variants[0].featured_image
                        : data.featured_image
                    }
                    storeLogo={data.store_logo}
                    wishlisted={
                      data.variants
                        ? data.variants[0].wishlisted
                        : data.wishlisted
                    }
                    home={true}
                    type={data.variants ? "products" : "services"}
                    id={data.variants ? data.id : data.id}
                    rating={data.variants[0].average_rating}
                    reviews={data.variants[0].reviews_count}
                    name={data[`name_${props.language}`] + " " + data.variants[0][`name_${props.language}`]}
                    data={data.variants ? data.variants[0] : data}
                    coupon={data.coupon}
                    is_inquiry={data.is_inquiry}
                  />
                </div>
              </Link>
            ))}
          </div>
        </div>

        <div className="titlewrap px-2">
          <h2 className="sectiontitle-individualpage text-left">Top Picks</h2>
        </div>
        <div className="surfacepat" style={{ background: "#f8f8f8" }}>
          <div className="product-cont">
            {products2?.map((data) => (
              <Link
                style={{ textDecoration: "none" }}
                to={{
                  pathname:
                    "/" +
                    props.countryUrl +
                    "-" +
                    props.language +
                    `/${data.category_title_en
                      .toLowerCase()
                      .replace(/ /g, "-")}/${data.slug}`,
                  state: {
                    id: data.id,
                    type: data.variants ? "products" : "services",
                    sku: data.variants[0].sku
                  },
                }}
                key={data.id + Math.random()}
              >
                <div
                  className="image-cont"
                  key={data.id + Math.random() + "prod"}
                >
                  <ProductCard
                    key={data.id + Math.random()}
                    img={
                      data.variants
                        ? data.variants[0].featured_image
                        : data.featured_image
                    }
                    storeLogo={data.store_logo}
                    wishlisted={
                      data.variants
                        ? data.variants[0].wishlisted
                        : data.wishlisted
                    }
                    home={true}
                    type={data.variants ? "products" : "services"}
                    id={data.variants ? data.id : data.id}
                    rating={data.variants[0].average_rating}
                    reviews={data.variants[0].reviews_count}
                    name={data[`name_${props.language}`] + " " + data.variants[0][`name_${props.language}`]}
                    data={data.variants ? data.variants[0] : data}
                    coupon={data.coupon}
                    is_inquiry={data.is_inquiry}
                  />
                </div>
              </Link>
            ))}
          </div>
        </div>
      </div>
    </MobileLayoutRenderer>
  );
};

const mapStateToProps = (state) => {
  return {
    countryUrl: state.language.countryUrl,
    countryId: state.language.countryId,
    language: state.language.language,
  };
};


export default connect(mapStateToProps, null)(CampingPageMobile);
