import React, { Fragment, useEffect, useState } from "react";
import "./disputes.scss";

import EmptyPage from "../../../utils/EmptyPage";

import EmptyImage from "../../../assets/images/dispute_art.png";

import { getDesktopImage } from "../../../utils/utils";

import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { apiJson } from "../../../Api";
import moment from "moment";

import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { IoChevronForward } from "react-icons/io5";
import { set_loading } from "../../../redux/actions/LayoutAction";
import Pagination from "react-paginate";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

const Disputes = (props) => {
  const [data, setData] = useState(false);
  const [disputesData, setDisputesData] = useState([]);
  const { t } = useTranslation();
  const [pagination, setPagination] = useState({});
  let message = "";
  const showError = () => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  const getDisputes = (page) => {
    window.scrollTo(0, 0);
    props.set_loading(true);
    apiJson.disputes.getData(page).then((response) => {
      props.set_loading(false);
      if (response.success) {
        setDisputesData(response.data.disputes);
        setPagination(response.data.pagination);
        setData(true);
      } else if (!response.status) {
        message = response.message
          ? response.message !== ""
            ? response.message
            : t("errors.wrong")
          : t("errors.wrong");
        showError();
      } else {
        if (response.status >= 400 && response.status < 500) {
          message = response.message
            ? response.message
            : response.errors
            ? response.errors
            : t("errors.wrong");
          showError();
        } else {
          message = response.message
            ? response.message !== ""
              ? response.message
              : t("errors.wrong")
            : t("errors.wrong");
          showError();
        }
      }
    });
  };

  const handlePageChange = (pageNumber) => {
    getDisputes(pageNumber.selected + 1);
  };

  useEffect(() => {
    getDisputes(1);
    // eslint-disable-next-line
  }, []);

  return (
    <Fragment>
      <div className="customer-disputes w-100">
        <div className="d-flex justify-content-between header-row">
          <div className="left">
            <h3>{t("disputes.title")}</h3>
          </div>
          <div className="right">
            <Link
              to={
                "/" +
                props.countryUrl +
                "-" +
                props.language +
                "/order/itemslist/disputes"
              }
            >
              <button type="button" className="btn dispute-request">
                {t("disputes.file-new-btn")}
              </button>
            </Link>
          </div>
        </div>
        {!data ? (
          <div className=""></div>
        ) : disputesData.length > 0 ? (
          disputesData.map((details) => {
            return (
              <div className="customer-disputes w-100" key={details.id}>
                <div className="disputes-data">
                  <Link
                    to={
                      "/" +
                      props.countryUrl +
                      "-" +
                      props.language +
                      `/dispute/detail/${details.id}`
                    }
                    style={{ textDecoration: "none" }}
                  >
                    <div className="dispute-head upper row no-gutters w-100 d-flex justify-content-between align-items-center">
                      <div className="dispute-head-text">
                        {t("disputes.dispute-id")} {details.id}
                      </div>
                      <IoChevronForward
                        style={{
                          textDecoration: "none",
                          color: "grey",
                          transform:
                            props.language === "en"
                              ? "unset"
                              : `rotate(
                          180deg
                          )`,
                        }}
                        size={24}
                      />
                    </div>
                  </Link>
                  <div className="dispute-detail-box w-100">
                    <div className="row first-box border-bottom no-gutters">
                      <div
                        className={
                          (props.direction === "RTL" &&
                            "col-md-6 col-lg-7 dispute-details border-left") ||
                          (props.direction === "LTR" &&
                            "col-md-6 col-lg-7 dispute-details border-right")
                        }
                      >
                        <h5 className="dispute-head-text header">
                          {t("disputes.filed-at")}:
                        </h5>
                        <div className="dispute-fade-text">
                          <p>
                            {moment(new Date(details.created_at)).format("LL")}
                          </p>
                        </div>
                      </div>
                      <div className="dispute-details col-md-6 col-lg-5">
                        <div className="detail d-flex align-items-center">
                          <div className="label">{t("disputes.order-no")}</div>
                          <div className="value">{details.order_id}</div>
                        </div>
                        <div className="detail d-flex align-items-center">
                          <div className="label">
                            {t("disputes.customer-email")}
                          </div>
                          <div className="value">{details.customer_email}</div>
                        </div>
                        <div className="detail d-flex align-items-center">
                          <div className="label">{t("disputes.status")}</div>
                          <div className="value">
                            <div className="status-button">
                              {details.status}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row product-disputes-details flex-row flex-nowrap no-gutters align-items-center w-100 ">
                      <div className="image-col">
                        <img
                          src={getDesktopImage(
                            details.featured_image_resized,
                            details.featured_image
                          )}
                          alt="disputes"
                          style={{
                            width: "auto",
                            maxWidth: "90px",
                            height: "auto",
                            maxHeight: "90px",
                          }}
                        />
                      </div>
                      <div className="description-col d-flex flex-column">
                        <div className="dispute-fade-text mb-1">
                          {details[`store_name_${props.language}`]}
                        </div>
                        {details[`sku`] && (
                          <div className="dispute-fade-text mb-1">
                            {details[`sku`]}
                          </div>
                        )}
                        <div className="dispute-text mb-3">
                          {details[`customer_name_${props.language}`]}
                        </div>
                        <div className="dispute-text">
                          <span className="font-weight ml-1">
                            {details.currency.toUpperCase() + " "}
                            {parseFloat(details.price).toFixed(2)}
                          </span>
                        </div>
                        <div className="dispute-text">
                          {t("disputes.sold-by")}
                          <span className="font-weight ml-1">
                            {details[`store_name_${props.language}`]}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <EmptyPage img={EmptyImage} heading={t("disputes.empty.heading")} />
        )}
        {pagination?.total_pages > 1 && disputesData?.length > 0 && (
          <div
            className="d-flex justify-content-center mb-4 mt-5 w-100"
            style={{ direction: "ltr" }}
          >
            <Pagination
              direction="ltr"
              previousLabel={<FaChevronLeft color="#404553" />}
              nextLabel={<FaChevronRight color="#404553" />}
              pageCount={parseInt(pagination?.total_pages)}
              forcePage={parseInt(pagination?.page) - 1}
              onPageChange={handlePageChange}
              pageRangeDisplayed={props.width === "sm" ? 1 : 2}
              marginPagesDisplayed={props.width === "sm" ? 1 : 2}
              containerClassName="pagination"
              pageClassName="pl-3 pr-3 pt-2 pb-2"
              activeClassName={
                "ml-3 mr-3 text-primary border border-primary font-weight-bold rounded bg-white"
              }
              nextClassName="bg-white pl-3 pr-3 pt-2 pb-2"
              previousClassName="bg-white pl-3 pr-3 pt-2 pb-2"
            />
          </div>
        )}
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    direction: state.language.direction,
    language: state.language.language,
    countryUrl: state.language.countryUrl,
  };
};

const mapDispatchToProps = { set_loading };
export default connect(mapStateToProps, mapDispatchToProps)(Disputes);
