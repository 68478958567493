import React from "react";
import "../../../assets/styles/component/customer/AllItems/ItemsCheckbox.scss";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { debounce } from "lodash";
import { useCallback } from "react";

function ItemsCheckbox(props) {
  const [limit, setLimit] = useState(10);
  const [viewBtnText, setViewBtnText] = useState("VIEW MORE");
  const [options, setOptions] = useState(undefined);
  const [search, setSearch] = useState("");

  const { t } = useTranslation();
  const debouncedCallback =
    // eslint-disable-next-line
    useCallback(
      debounce((text) => setSearch(text), 1000),
      []
    );

  const onSearch = (val) => {
    setOptions(
      props.options.filter(
        (ele) =>
          ele[`name_${props.language}`]
            .toLowerCase()
            .search(val.toLowerCase()) > -1
      )
    );
  };

  const showMoreBrands = () => {
    if (options.length == limit) {
      setLimit(10);
      setViewBtnText("VIEW MORE");
    }
    else {
      setLimit(options.length);
      setViewBtnText("VIEW LESS");
    }
  }

  // eslint-disable-next-line
  useEffect(() => onSearch(search), [props.options]);
  return (
    <div className="item-checkbox-desktop">
      <div>
        {props.name === t("itemsSidebar.brand") && options && (
          <input
            type="text"
            placeholder={`Search`}
            id="search"
            name="search"
            className="search-style w-100 mt-2 mb-4"
            onChange={(e) => {
              onSearch(e.target.value);
              debouncedCallback(e.target.value);
            }}
          />
        )}
      </div>
      {options?.slice(0, limit).map((ele) => (
        <div key={ele.id} className="items-checkbox ">
          <input
            type="checkbox"
            className="m-auto"
            checked={ele.checked}
            onChange={() => {
              localStorage.setItem("load_items", true);
              props.UpdateOptions(
                props.options.map((val) =>
                  val.id !== ele.id
                    ? val
                    : {
                      ...val,
                      checked: !val.checked,
                    }
                )
              );
              props.options.map(
                (val) =>
                  val.id === ele.id &&
                  props.setFilters(
                    {
                      name: val[`name_${props.language}`],
                      count: val.count,
                      heading: props.name,
                      checked: !val.checked,
                    },
                    val.id
                  )
              );
            }}
          />
          <label className="checkbox-cont mt-auto mb-auto" htmlFor="checkbox">
            <span className="checkbox-text">
              {ele[`name_${props.language}`]}
            </span>

            <span className="checkbox-text d-none">
              {"("}
              {ele.count}
              {")"}
            </span>
          </label>
        </div>
      ))}
      <button
        onClick={showMoreBrands}
        className={props.language == "en" ? "view-more" : "view-more float-right"}
      >
        {viewBtnText}
      </button>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    language: state.language.language,
  };
};

export default connect(mapStateToProps, null)(ItemsCheckbox);
