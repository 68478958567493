import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import "../../../../assets/styles/utils.scss";
import AddressImg from "../../../../assets/images/addAddressLatlngIndicator.svg";
import "../../../../assets/styles/customer/account/orders/orderheader.scss";
import ClipLoader from "react-spinners/ClipLoader";

import { AiFillCheckCircle } from "react-icons/ai";
import { IoLocationSharp } from "react-icons/io5";
import { BiError } from "react-icons/bi";
import { FiPhoneCall } from "react-icons/fi";
import { connect } from "react-redux";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { Link, Redirect } from "react-router-dom";
import { apiJson } from "../../../../Api";
import { useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const en_translation = require("../../../../assets/locales/en/translation.json");
const ar_translation = require("../../../../assets/locales/ar/translation.json");

const OrderHeader = (props) => {
  const { t } = useTranslation();
  const [deleting, setDeleting] = useState(false);
  const [redirect, setRedirect] = useState(false);
  let message = "";
  const showError = () => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  const cancelOrder = (id) => {
    setDeleting(true);
    apiJson.orders.cancelOrder(id).then((response) => {
      if (response.success) {
        setDeleting(false);
        setRedirect(true);
      } else if (!response.status) {
        message = response.message
          ? response.message !== ""
            ? response.message
            : t("errors.wrong")
          : t("errors.wrong");
        showError();
      } else {
        if (response.status >= 400 && response.status < 500) {
          message = response.message
            ? response.message
            : response.errors
              ? response.errors
              : t("errors.wrong");
          showError();
        } else {
          message = response.message
            ? response.message !== ""
              ? response.message
              : t("errors.wrong")
            : t("errors.wrong");
          showError();
        }
      }
    });
  };

  const isCancelable = () =>
    props.order.status === "pending" &&
    (props.order.services
      ? props.order.services.filter(
        (service) =>
          service.status.toLowerCase() !== "pending" &&
          service.status.toLowerCase() !== "rejected"
      ).length === 0
      : true) &&
    (props.order.shipments?.length > 0
      ? props.order.shipments.filter(
        (shipment) =>
          shipment.line_items.filter(
            (line_item) =>
              line_item.status.toLowerCase() !== "pending" &&
              line_item.status.toLowerCase() !== "rejected"
          ).length > 0
      ).length === 0
      : true);

  return (
    <div className="order-header">
      <div className="wrapper mx-3 pt-3 pb-4">
        <Row className="first no-gutters justify-content-start pb-3">
          <div className="order-number faded-black-2-text fw-600">
            <span>{t("orderDetails.oh-order-id")}</span>
            {props.order.id}
          </div>
          <div
            className={`gray-text placed-on ${props.language?.toLowerCase() === "en" ? "ml-2" : "mr-2"
              }`}
          >
            {t("orderDetails.oh-placed-on")}{" "}
            {moment(new Date(props.order.created_at)).format("LL")}
          </div>
          <div className={props.width == "sm" ? "mr-auto" : "ml-auto"}>
            <div className="order-number faded-black-2-text fw-600 ml-auto">
              <span>Status:&nbsp;</span>
              <span className={`gray-text placed-on`}>{en_translation["order"][props.order.status]}</span>
            </div>
            {
              (props.order.status == "customer_cancellation" || props.order.status == "rejected") && (
                <div className="order-number faded-black-2-text fw-600 ml-auto">
                  <span>Reason:&nbsp;</span>
                  <span className={`gray-text placed-on`}>{props.order.status == "customer_cancellation" ? props.order.customer_cancellation_reason?.replaceAll("_", " ") : props.order.rejection_reason?.replaceAll("_", " ")}</span>
                </div>
              )
            }
          </div>
        </Row>

        <Row className="second no-gutters pt-2">
          <Col md={6} className="left no-gutters py-1">
            <div className="title faded-black-2-text fw-600">
              <img
                src={AddressImg}
                alt=""
                className="large-screen-none"
                width="20px"
                height="15px"
                style={{ marginInlineEnd: "8px" }}
              />
              {
                props.order.is_delivery ?
                  props.language == "en" ?
                    en_translation["orderDetails"]["oh-shipping-adr"]
                    :
                    ar_translation["orderDetails"]["oh-shipping-adr"]
                  :
                  props.language == "en" ?
                    en_translation["orderDetails"]["oh-pickup-adr"]
                    :
                    ar_translation["orderDetails"]["oh-pickup-adr"]
              }
            </div>
            <div className="name faded-black-2-text fw-600 py-2">
              {
                props.order.is_delivery ?
                  (props.order.address.first_name +
                    " " +
                    props.order.address.last_name || "Customer Name")
                  :
                  props.order.pickup_customer_name
              }
            </div>
            <div className="addAddressLatlngIndicator.svgaddress d-flex align-items-center mobile-address-style gray-text-2">
              <IoLocationSharp />
              <span className="ml-2">{
                props.order.is_delivery ?
                  props.order.address.address1
                  :
                  props.order.pickup_address?.store_address
              }</span>
            </div>
          </Col>

          <Col
            md={6}
            className={`right no-gutters py-1 ${props.width !== "sm"
              ? props.language.toLowerCase() === "en"
                ? "pl-2"
                : "pr-2"
              : " "
              }`}
          >
            <div className="title faded-black-2-text fw-600">
              <FiPhoneCall
                className="large-screen-none"
                size={15}
                color="#9c9c9c"
                style={{ marginInlineEnd: "8px" }}
              />
              {t("orderDetails.oh-mobile-number")}
            </div>
            <Row className="no-gutters">
              <Col md={12}>
                <Row className="no-gutters py-2">
                  <div className="mobile-number gray-text">
                    {
                      props.order.is_delivery ?
                        props.order.address.contact_number
                        :
                        props.order.pickup_customer_contact
                    }
                  </div>
                  <div className="small-screen-none verified-mark">
                    {
                      props.order.is_delivery ?
                        props.order.address.contact_number_verified === true ? (
                          <span className="gray-text">
                            <span className="green-checkbox mr-1">
                              <AiFillCheckCircle />
                            </span>
                            {props.language?.toLowerCase() === "en"
                              ? "Verified"
                              : "تم التحقق"}
                          </span>
                        ) : (
                          <span className="gray-text">
                            <span className="caution mr-1">
                              <BiError />
                            </span>{" "}
                            Not Verified
                          </span>
                        )
                        :
                        <></>
                    }
                  </div>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>

      <Row className="no-gutters justify-content-between mb-3 ml-2 mr-2 pb-3 ">
        <div
          className={`mobile-review-btn w-100 ${!isCancelable() ? " invisible" : ""
            }`}
        >
          <div
            onClick={() => {
              if (isCancelable()) {
                if (
                  window.confirm("Are you sure you want to cancel this order")
                ) {
                  cancelOrder(props.order.id);
                }
              }
            }}
            className="cancle-order-btn py-2 px-5"
          >
            {deleting ? (
              <div className="d-flex justify-content-center align-items-center w-100">
                <ClipLoader color="#000" loading size={20} />
              </div>
            ) : (
              t("order.cancel-order")
            )}
          </div>
        </div>
      </Row>
      {props.order &&
        props.order.status &&
        (props.order.status.toLowerCase() === "delivered" ||
          props.order.status.toLowerCase() === "collected" ||
          props.order.status.toLowerCase() === "partially_returned" ||
          props.order.status.toLowerCase() === "fully_returned") && (
          <Row className="no-gutters justify-content-between ml-2 mr-2 pb-3">
            <Link
              style={{ textDecoration: "none", width: "100%" }}
              to={
                "/" +
                props.countryUrl +
                "-" +
                props.language +
                "/order/itemslist/review"
              }
            >
              <div className="mobile-review-btn">
                <div className="review-order-btn py-2 px-5">
                  {t("order.review-order")}
                </div>
              </div>
            </Link>
          </Row>
        )}
      {redirect && (
        <Redirect
          push
          to={"/" + props.countryUrl + "-" + props.language + "/orders"}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    width: state.layout.screenWidth,
    countryUrl: state.language.countryUrl,
    language: state.language.language,
  };
};

export default connect(mapStateToProps)(OrderHeader);
