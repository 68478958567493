import React, { Fragment, useState, useEffect } from "react";

import "../../../assets/styles/customer/account/orderslist.scss";
import { HiOutlineThumbUp } from "react-icons/hi";
import { BsPlus } from "react-icons/bs";
import OrderItem from "./orders/OrderItem";
import { useParams } from "react-router-dom";
import { Redirect, Link } from "react-router-dom";
import CreateModal from "../../modal/CreateModal";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { IoChevronBack } from "react-icons/io5";
import EmptyPage from "../../../utils/EmptyPage";
import EmptyImage from "../../../assets/images/orders-empty.svg";
import { connect } from "react-redux";
import { apiJson } from "../../../Api";
import { useTranslation } from "react-i18next";
import { set_loading } from "../../../redux/actions/LayoutAction";
const OrderList = (props) => {
  const { t } = useTranslation();
  const [orders, setOrders] = useState([]);
  const { name } = useParams();
  const [redirect, setRedirect] = useState({ value: false, id: -1 });
  const [openModal, setOpenModal] = useState({ value: false });
  let message = "";
  const [data, setData] = useState(false);
  const showError = () => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    props.set_loading(true);

    apiJson.orderItemsList.getItems().then((response) => {
      props.set_loading(false);
      if (response.success) {
        setOrders(response.data);
        setData(true);
      } else if (!response.status) {
        message = response.message // eslint-disable-line react-hooks/exhaustive-deps
          ? response.message !== ""
            ? response.message
            : t("errors.wrong")
          : t("errors.wrong");
        showError();
      } else {
        if (response.status >= 400 && response.status < 500) {
          message = response.message
            ? response.message
            : response.errors
              ? response.errors
              : t("errors.wrong");
          showError();
        } else {
          message = response.message
            ? response.message !== ""
              ? response.message
              : t("errors.wrong")
            : t("errors.wrong");
          showError();
        }
      }
    });
  }, []);

  return (
    <Fragment>
      {!data ? (
        <div className="d-flex justify-content-center align-items-center w-100 h-100"></div>
      ) : (
        <div className="orderslist-page w-100">
          <Link
            style={{ textDecoration: "none" }}
            to={`${name === "disputes"
              ? "/" + props.countryUrl + "-" + props.language + "/disputes"
              : name === "refund"
                ? "/" + props.countryUrl + "-" + props.language + "/returns"
                : "/" + props.countryUrl + "-" + props.language + "/orders"
              }`}
          >
            <div className="back-col d-flex align-items-center">
              <IoChevronBack
                size={16}
                style={{
                  color: "rgb(64, 69, 83)",
                  transform:
                    props.lang === "en"
                      ? "unset"
                      : `rotate(
                  180deg
                  )`,
                }}
              />
              <div className="text ml-2">Back</div>
            </div>
          </Link>
          {orders && orders.length > 0 ? (
            orders.map((item) => {
              return (
                <Fragment key={item.id}>
                  <div className="oreder-container" key={item.id}>
                    <OrderItem currency={item.currency} shipmentItem={item} />
                    {
                      name === "disputes" || name === "review" ?
                        <button
                          onClick={(e) => {
                            e.preventDefault();
                            name === "review" &&
                              setRedirect({
                                value: true,
                                id: item.id,
                                state: item,
                              });
                            setOpenModal({ value: true, item: item });
                          }}
                          type="button"
                          className="review-button"
                        >
                          {name === "disputes" ? (
                            <>
                              {t("items-list-review.create-dispute")}
                              <BsPlus style={{ marginInlineStart: "2px" }} />
                            </>
                          ) : name === "refund" ? (
                            <>
                              {t("items-list-review.create-refund")}
                              <BsPlus style={{ marginInlineStart: "2px" }} />
                            </>
                          ) : (
                            <>
                              {t("items-list-review.submit-review")}{" "}
                              <HiOutlineThumbUp />
                            </>
                          )}{" "}
                        </button>
                        :
                        (item.orderable.brand_name_en == "Rust-Oleum" ||
                          item.orderable.brand_name_en == "DAP" ||
                          item.orderable.brand_name_en == "Krud Kutter" ||
                          item.orderable.brand_name_en == "Pattex" ||
                          item.orderable.brand_name_en == "Roll Roy" ||
                          item.orderable.brand_name_en == "Benjamin Moore" ||
                          item.orderable.brand_name_en == "Karcher" ||
                          item.orderable.brand_name_en == "Misar Hardware" ||
                          item.orderable.brand_name_en == "Stanley" ||
                          item.orderable.brand_name_en == "Energizer") ?
                          <button
                            onClick={(e) => {
                              e.preventDefault();
                              name === "review" &&
                                setRedirect({
                                  value: true,
                                  id: item.id,
                                  state: item,
                                });
                              setOpenModal({ value: true, item: item });
                            }}
                            type="button"
                            className="review-button"
                          >
                            {name === "disputes" ? (
                              <>
                                {t("items-list-review.create-dispute")}
                                <BsPlus style={{ marginInlineStart: "2px" }} />
                              </>
                            ) : name === "refund" ? (
                              <>
                                {t("items-list-review.create-refund")}
                                <BsPlus style={{ marginInlineStart: "2px" }} />
                              </>
                            ) : (
                              <>
                                {t("items-list-review.submit-review")}{" "}
                                <HiOutlineThumbUp />
                              </>
                            )}{" "}
                          </button>
                          :
                          <button
                            type="button"
                            className="review-button"
                          >
                            {name === "disputes" ? (
                              <>
                                {t("items-list-review.no-dispute")}
                                {/* <BsPlus style={{ marginInlineStart: "2px" }} /> */}
                              </>
                            ) : name === "refund" ? (
                              <>
                                {t("items-list-review.no-refund")}
                                {/* <BsPlus style={{ marginInlineStart: "2px" }} /> */}
                              </>
                            ) : (
                              <>
                                {t("items-list-review.submit-review")}{" "}
                                <HiOutlineThumbUp />
                              </>
                            )}{" "}
                          </button>
                    }
                  </div>
                </Fragment>
              );
            })
          ) : (
            <EmptyPage
              img={EmptyImage}
              className="img-fluid"
              heading={t("order.empty.not-delivered")}
            />
          )}
          {openModal.value && (
            <CreateModal
              name={name}
              show={openModal.value}
              item={openModal.value && openModal.item}
              close={(val) => setOpenModal({ value: val })}
            />
          )}
          {redirect.value && (
            <Redirect
              push
              to={{
                pathname:
                  "/" +
                  props.countryUrl +
                  "-" +
                  props.language +
                  `/create/review/${redirect.id}`,
                state: redirect.state,
              }}
            />
          )}
        </div>
      )}
    </Fragment>
  );
};
const mapStateToProps = (state) => {
  return {
    cart_items: state.cart.cart_items,
    country: state.language.country,
    countryUrl: state.language.countryUrl,
    language: state.language.language,
    lang: state.language.language,
  };
};

const mapDispatchToProps = { set_loading };

export default connect(mapStateToProps, mapDispatchToProps)(OrderList);
