import React, { Fragment, useState } from "react";
import { apiJson } from "../../Api";

import "../../assets/styles/customer/verifyphone.scss";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Redirect } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import OtpInput from "react-otp-input";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { set_cart_items } from "../../redux/actions/CartAction";

const VerifyPhone = (props) => {
  const [redirect, setRedirect] = useState({ value: false });
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  let message = "";

  const [singleOtp, setSingleOtp] = useState("");
  const showError = () => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const varifyOtp = () => {
    setLoading(true);
    apiJson.phone.verifyOtp(props.id, singleOtp).then((response) => {
      if (response.success) {
        apiJson.select
          .varifyAdress(props.request, props.countryId)
          .then((response) => {
            setLoading(false);
            if (response.success) {
              props.set_cart_items({
                order: {
                  address: props.address,
                  id: response.data.id,
                  createdAddress: response.data,
                },
              });
              setRedirect({
                value: true,
                path:
                  "/" + props.countryUrl + "-" + props.language + "/checkout",
              });
              const timer = setTimeout(() => {
                setRedirect({ value: false });
              }, 1000);
              clearTimeout(timer);
            } else if (!response.status) {
              setLoading(false);
              message = t("errors.wrong");
              showError();
            } else {
              setLoading(false);
              if (response.status >= 400 && response.status < 500) {
                message = response.message
                  ? response.message
                  : response.errors
                  ? response.errors
                  : t("errors.wrong");
                showError();
              } else {
                message = response.message
                  ? response.message !== ""
                    ? response.message
                    : t("errors.wrong")
                  : t("errors.wrong");
                showError();
              }
            }
          });
      } else if (!response.status) {
        setLoading(false);
        message = response.message
          ? response.message !== ""
            ? response.message
            : t("errors.wrong")
          : t("errors.wrong");
        showError();
      } else {
        setLoading(false);
        if (response.status >= 400 && response.status < 500) {
          message = response.message
            ? response.message
            : response.errors
            ? response.errors
            : t("errors.wrong");
          showError();
        } else {
          message = response.message
            ? response.message !== ""
              ? response.message
              : t("errors.wrong")
            : t("errors.wrong");
          showError();
        }
      }
    });
  };

  return (
    <Fragment>
      <div className="verify-phone-desktop mx-auto">
        <div className="row verify-phone-row no-gutters">
          <div className="heading mx-auto">
            <div className="col-12 header-col">
              <div className="label">{t("verify-phone.title")}</div>
            </div>
            <div className="col-12 phone-verify-col">
              <div className="otp-verification d-flex flex-column align-items-center justify-content-center">
                <div className="otp-header w-100">
                  {t("verify-phone.inner-title")}
                </div>
                <div className="otp-text">
                  {t("verify-phone.otp-text")} {props.mobile_number}
                </div>
                <div className="otp-box d-flex">
                  <OtpInput
                    value={singleOtp}
                    onChange={(e) => setSingleOtp(e)}
                    numInputs={4}
                    isInputNum
                    separator={<span>{""}</span>}
                    inputStyle={"inputStyleClass"}
                  />
                </div>
                <div onClick={varifyOtp} className="otp-verify-button">
                  <button
                    type="button"
                    className={
                      props.direction === "RTL" ? "button-rtl" : "button-ltr"
                    }
                  >
                    {loading ? (
                      <div className="d-flex justify-content-center align-items-center w-100">
                        <ClipLoader color="#fff" loading size={20} />
                      </div>
                    ) : (
                      t("verify-phone.submit-verify-btn")
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {redirect.value && (
        <Redirect
          push
          to={{
            pathname: redirect.path,
            state: redirect.state,
          }}
        />
      )}
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    direction: state.language.direction,
    countryUrl: state.language.countryUrl,
    language: state.language.language,
    countryId: state.language.countryId,
  };
};

const mapDispatchToProps = { set_cart_items };

export default connect(mapStateToProps, mapDispatchToProps)(VerifyPhone);
