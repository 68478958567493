import React, { Fragment } from "react";
import "../../assets/styles/pages/paymentemptymob.scss";
import EmptyPayment from "../../assets/images/payment_art.svg";
import { useTranslation } from "react-i18next";

const PaymentEmptyMob = () => {
  const { t } = useTranslation();
  return (
    <Fragment>
      <div className="paymment-empty-mob-page">
        <div className="img-cont">
          <img src={EmptyPayment}
            className="img-fluid" width="100%" height="100%" alt="emptyimage" />
        </div>
        <div className="text-uper">{t("payment.empty.heading1")}</div>
        <div className="lower-text">{t("payment.empty.subheading")}</div>
      </div>
    </Fragment>
  );
};

export default PaymentEmptyMob;
