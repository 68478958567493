import React from "react";
import { connect } from "react-redux";

import HomeCmp from "../../components/mobile/Home/HomeMobile";

function HomeMobilePage(props) {
  return <div>{props.width === "sm" && <HomeCmp screen="home" />}</div>;
}

const mapStateToProps = (state) => {
  return {
    width: state.layout.screenWidth,
  };
};

export default connect(mapStateToProps, null)(HomeMobilePage);
