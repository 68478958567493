import { type } from "../../types/types";
const initialState = {
  products: {},
  secondaryBanners: [],
  primaryBanners: [],
  services: {},
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case type.SET_VENDOR_SHOP:
      return {
        ...state,
        ...action.data,
      };
    default:
      return state;
  }
}
