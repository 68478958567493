import React, { Fragment } from "react";

import SidebarProductCard from "../cart/CartSidebarProductCard";
import "../../../assets/styles/customer/cart/cartsidebarproductlist.scss";

const CartSidebarProductList = (props) => {
  return (
    <Fragment>
      <div
        className={`${
          props.dataChanged ? "height-60 " : "height-80 "
        }cart-sidebar-product-list-container-desktop`}
      >
        {props.List.line_items.map((item) => (
          <SidebarProductCard
            key={item.id}
            product={item}
            cart_items={props.cart_items}
          />
        ))}
      </div>
    </Fragment>
  );
};

export default CartSidebarProductList;
