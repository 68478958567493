import React, { useState, useEffect } from "react";

import "../../../assets/styles/mobile/customer/ProfileMobile.scss";

import { FiChevronDown } from "react-icons/fi";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { Link, Redirect } from "react-router-dom";

import { set_direction } from "../../../redux/actions/LayoutAction";
import "react-toastify/dist/ReactToastify.css";
import { set_user } from "../../../redux/actions/AuthAction";

function ProfileMobile(props) {
  const { t } = useTranslation();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const englishLanguage = "English";
  const arabiclanguage = "العربية";
  const [language, setLanguage] = useState(
    props.language === "en" ? "English" : "العربية"
  );
  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    if (props.isSignedIn) {
      document.title = "Profile";
      setFirstName(props.user[`first_name_en`]);
      setLastName(props.user[`last_name_en`]);
      setEmail(props.user.email);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div className="profile-mobile" style={{ background: "#f9f9f9" }}>
      <div
        className="prfoile-cont"
        style={{
          marginBottom: "15px",
          marginLeft: "15px",
          alignItems: "baseline",
        }}
      >
        <div
          className="name"
          style={{ fontWeight: "500", fontSize: "20px", lineHeight: "1" }}
        >
          <div>{firstName + " " + lastName}</div>
        </div>
        <div className="email">{email}</div>
      </div>
      <div className="general-info-cont">
        <div
          className="general-text"
          style={{ color: "#7f8188", background: "rgb(249 249 249)" }}
        >
          {t("profile.general-info")}
        </div>
        <div
          style={{ float: "right", color: "rgb(10, 132, 255)" }}
          onClick={() => setIsEdit(true)}
        >
          {t("ship.add-address-mobile.edit")}
        </div>
      </div>

      <div
        className="general-info-values"
        style={{
          fontWeight: "700",
          background: "rgb(249 249 249)",
          lineHeight: "1.4",
        }}
      >
        <div className="title" style={{ fontWeight: "500" }}>
          {t("profile.first-name-label")}
        </div>
        <input
          type="text"
          value={firstName}
          readOnly
          placeholder={t("profile.mobile.fn-placeholder")}
          onChange={(e) => setFirstName(e.target.value)}
          className="input"
          style={{
            lineHeight: "2.2",
            fontWeight: "600",
            fontSize: "14px",
            marginBottom: "40px",
          }}
        />

        <div className="title" style={{ fontWeight: "500" }}>
          {t("profile.last-name-label")}
        </div>
        <input
          type="text"
          value={lastName}
          readOnly
          placeholder={t("profile.mobile.ln-placeholder")}
          onChange={(e) => setLastName(e.target.value)}
          className="input"
          style={{
            lineHeight: "2.2",
            fontWeight: "600",
            fontSize: "14px",
            marginBottom: "40px",
          }}
        />

        <div className="title" style={{ fontWeight: "500" }}>
          {t("profile.mobile.prefered-lang")}
        </div>
        <div className="lang-cont">
          <input
            type="text"
            value={language}
            readOnly
            disabled
            className="input-lang"
            style={{ fontWeight: "600", color: "#232323" }}
          />
          <FiChevronDown size="20" color="rgb(126, 133, 155)" />
        </div>
        {modal && (
          <div className="options-cont">
            <div
              onClick={() => {
                setLanguage(englishLanguage);
                setModal(false);
              }}
            >
              {englishLanguage}
            </div>
            <div
              onClick={() => {
                setLanguage(arabiclanguage);
                setModal(false);
              }}
            >
              {arabiclanguage}
            </div>
          </div>
        )}
      </div>

      <div className="security-cont" style={{ lineHeight: "0.1" }}>
        <div
          className="security-text"
          style={{
            background: "rgb(249 249 249)",
            color: "rgb(127, 129, 136)",
          }}
        >
          {t("profile.security")}
        </div>
      </div>

      <div
        className="security-values"
        style={{
          background: "rgb(249 249 249)",
          marginInlineStart: "20px",
          marginInlineEnd: "6px",
          padding: "0px",
          lineHeight: "normal",
        }}
      >
        <Link
          to={"/" + props.countryUrl + "-" + props.language + "/pw-change-mob"}
        >
          <div className="button-cont">
            <div
              className="button"
              style={{
                marginInlineEnd: "auto",
                padding: "3px 10px",
                color: "rgb(255 255 255)",
                backgroundColor: "#c78e2b",
                border: "3px solid #c78e2b",
              }}
            >
              <span className="button-text">
                {t("profile.change-password.title")}
              </span>
            </div>
          </div>
        </Link>
      </div>
      {isEdit && (
        <Redirect
          push
          to={{
            pathname:
              "/" +
              props.countryUrl +
              "-" +
              props.language +
              "/edit-profile-mobile",
          }}
        />
      )}
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    isSignedIn: state.auth.isSignedIn,
    language: state.language.language,
    countryUrl: state.language.countryUrl,
  };
};

const mapDispatchToProps = {
  set_direction,
  set_user,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileMobile);
