import React, { useState, useEffect } from "react";

import "../../../assets/styles/mobile/customer/editprofilemobile.scss";

import { FiChevronDown } from "react-icons/fi";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { Redirect, useHistory } from "react-router-dom";

import { set_direction } from "../../../redux/actions/LayoutAction";
import { apiJson } from "../../../Api";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ClipLoader } from "react-spinners";
import { set_user } from "../../../redux/actions/AuthAction";

function EditProfileMobile(props) {
  const { t, i18n } = useTranslation();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const englishLanguage = "English";
  const arabiclanguage = "العربية";
  const [language, setLanguage] = useState(
    props.language === "en" ? "English" : "العربية"
  );
  const [modal, setModal] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const history = useHistory();
  let message = "";
  const showError = () => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  const showSuccess = () => {
    toast.success(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  useEffect(() => {
    document.title = "Edit Profile";
    if (props.isSignedIn) {
      setFirstName(props.user[`first_name_en`]);
      setLastName(props.user[`last_name_en`]);
    }
  }, []); // eslint-disable-line

  const updateProfile = () => {
    let data = {
      first_name_en: firstName,
      last_name_en: lastName,
      first_name_ar: props.user.first_name_ar
        ? props.user.first_name_ar
        : firstName,
      last_name_ar: props.user.last_name_ar
        ? props.user.last_name_ar
        : lastName,
      preferred_language: language,
    };
    setUpdating(true);
    apiJson.profile.update(data).then((response) => {
      if (response.success) {
        props.set_user({ user: response.data.data });
        i18n.changeLanguage(
          language.toLowerCase() === "English".toLowerCase() ? "en" : "ar"
        );
        props.set_direction(
          language.toLowerCase() === "English".toLowerCase() ? "en" : "ar"
        );
        history.push({
          pathname:
            "/" +
            props.countryUrl +
            "-" +
            (language.toLowerCase() === "English".toLowerCase() ? "en" : "ar") +
            "/" +
            window.location.pathname.replace(/(?:.*?\/){2}/, ""),
        });
        message = "Updated";
        showSuccess();
        setUpdating(false);
        setRedirect(true);
      } else if (!response.status) {
        setUpdating(false);
        message = response.message
          ? response.message !== ""
            ? response.message
            : t("errors.wrong")
          : t("errors.wrong");
        showError();
      } else {
        setUpdating(false);
        if (response.status >= 400 && response.status < 500) {
          message = response.message
            ? response.message
            : response.errors
            ? response.errors
            : t("errors.wrong");
          showError();
        } else {
          message = response.message
            ? response.message !== ""
              ? response.message
              : t("errors.wrong")
            : t("errors.wrong");
          showError();
        }
      }
    });
  };

  return (
    <div className="edit-profile-mobile" style={{ background: "#f9f9f9" }}>
      <div
        className="edit-page-values"
        style={{
          fontWeight: "700",
          background: "rgb(249 249 249)",
          lineHeight: "1.4",
        }}
      >
        <div className="title" style={{ fontWeight: "500" }}>
          {t("profile.first-name-label")}
        </div>
        <input
          type="text"
          value={firstName}
          placeholder={t("profile.mobile.fn-placeholder")}
          onChange={(e) => setFirstName(e.target.value)}
          className="input"
        />

        <div className="title" style={{ fontWeight: "500" }}>
          {t("profile.last-name-label")}
        </div>
        <input
          type="text"
          value={lastName}
          placeholder={t("profile.mobile.ln-placeholder")}
          onChange={(e) => setLastName(e.target.value)}
          className="input"
        />

        <div className="title" style={{ fontWeight: "500" }}>
          {t("profile.mobile.prefered-lang")}
        </div>
        <div className="lang-cont">
          <input
            type="text"
            value={language}
            disabled
            className="input-lang"
            style={{ fontWeight: "600", color: "#232323" }}
          />
          <FiChevronDown
            onClick={() => setModal(!modal)}
            size="20"
            color="rgb(126, 133, 155)"
          />
        </div>
        {modal && (
          <div className="options-cont">
            <div
              onClick={() => {
                setLanguage(englishLanguage);
                setModal(false);
              }}
            >
              {englishLanguage}
            </div>
            <div
              onClick={() => {
                setLanguage(arabiclanguage);
                setModal(false);
              }}
            >
              {arabiclanguage}
            </div>
          </div>
        )}
      </div>

      <div onClick={updateProfile} className="save-profile-mobile-end">
        <button className="save-profile-mobile-button">
          {updating ? (
            <div className="d-flex justify-content-center align-items-center w-100">
              <ClipLoader color="#000" loading size={20} />
            </div>
          ) : (
            t("profile.save-btn")
          )}
        </button>
      </div>
      {redirect && (
        <Redirect
          push
          to={{
            pathname:
              "/" + props.countryUrl + "-" + props.language + "/profile",
          }}
        />
      )}
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    isSignedIn: state.auth.isSignedIn,
    countryUrl: state.language.countryUrl,
    language: state.language.language,
  };
};

const mapDispatchToProps = {
  set_direction,
  set_user,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditProfileMobile);
