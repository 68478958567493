import React from "react";
import { connect } from "react-redux";
import "../../../assets/styles/component/mobile/AllCategories/AllCategoriesSidePanel.scss";

const AllCategoriesSidePanel = (props) => {
  return (
    <div
      className={`categories-side-panel-mobile ${
        props.direction === "RTL" ? "float-right" : "float-left"
      }`}
    >
      {props.data.map((ele) => (
        <div
          onClick={() =>
            props.changeCategory(
              ele.id,
              ele[`title_${props.language}`],
              ele.sub_categories
            )
          }
          className={`text-cont ${ele.active ? "active-style" : ""}`}
          key={ele.id}
        >
          <span className={`text ${ele.active ? "active-text" : ""}`}>
            {ele[`title_${props.language}`]}
          </span>
        </div>
      ))}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    direction: state.language.direction,
    language: state.language.language,
  };
};

export default connect(mapStateToProps, null)(AllCategoriesSidePanel);
