import React, { Fragment } from "react";
import "../../assets/styles/mobile/uppernavigation.scss";
import { AiOutlineArrowLeft } from "react-icons/ai";
import Logo from "../../assets/images/logo.png";
import { MdClose } from "react-icons/md";
import { RiSearchLine } from "react-icons/ri";
import { Link, useHistory } from "react-router-dom";

import { useTranslation } from "react-i18next";
import { set_direction } from "../../redux/actions/LayoutAction";
import { connect } from "react-redux";

const UpperNavigation = (props) => {
  const { t } = useTranslation();
  const { BackButton, CancelButton, CloseIcon, SearchIcon } = props;
  let history = useHistory();

  return (
    <Fragment>
      <div className="upper-navigation-main">
        <div className="backbtn-noon-svg">
          {BackButton && (
            <div
              onClick={() => {
                window.location.pathname.includes("/profile")
                  ? history.replace(
                    "/" +
                    props.countryUrl +
                    "-" +
                    props.language +
                    "/my-account-li-mob"
                  )
                  : history.goBack();
              }}
              className="leftarrow"
            >
              {props.language?.toLowerCase() === "en" ? (
                <AiOutlineArrowLeft
                  size={20}
                  style={{
                    transform: "unset",
                  }}
                />
              ) : (
                <AiOutlineArrowLeft
                  size={18}
                  style={{
                    transform: "rotate(180deg)",
                  }}
                />
              )}
            </div>
          )}

          <div className="noonblack-svg">
            <Link to={"/" + props.countryUrl + "-" + props.language}>
              <img src={Logo} width="100%" height="100%" alt="" />
            </Link>
          </div>
        </div>
        {
          SearchIcon ?
            <Link to={"/" + props.countryUrl + "-" + props.language + "/all-categories"} className="search">
              <RiSearchLine />
            </Link>
            :
            <></>
        }
        {(CancelButton && (
          <div
            onClick={() =>
              window.location.pathname.includes("/edit-profile-mobile")
                ? history.replace(
                  "/" + props.countryUrl + "-" + props.language + "/profile"
                )
                : history.goBack()
            }
            className="cancel-span"
          >
            <span>{t("upper-nav.cancel")}</span>
          </div>
        )) ||
          (CloseIcon && (
            <div onClick={() => history.goBack()} className="cancel-span">
              <MdClose size={30} />
            </div>
          ))}
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    width: state.layout.screenWidth,
    direction: state.language.direction,
    language: state.language.language,
    countryUrl: state.language.countryUrl,
  };
};

const mapDispatchToProps = {
  set_direction,
};
export default connect(mapStateToProps, mapDispatchToProps)(UpperNavigation);
