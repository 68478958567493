import React, { Fragment, useEffect } from "react";

import "../assets/styles/pages/verifyphonenumber.scss";
import ShippingFooter from "../components/customer/shippingfooter/ShippingFooter";
import Header from "../components/customer/shippingheader/Header";
import VerifyPhone from "../components/customer/VerifyPhone";
import VerifyPhoneMobile from "./mobile/VerifyPhoneMobile";
import MobileLayoutRenderer from "./mobile/layout/MobileLayoutRenderer";
import { Redirect } from "react-router-dom";
import { apiJson } from "../Api";

import { connect } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTranslation } from "react-i18next";

const VerifyPhoneNumber = (props) => {
  const { t } = useTranslation();
  const { width } = props;
  let message = "";

  const showError = () => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  const showSuccess = () => {
    toast.success(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  useEffect(() => {
    document.title = "Verify Phone";
  }, []);
  useEffect(
    () =>
      props.cart_items?.line_items?.length > 0 &&
      apiJson.phone.verifyNumber(props.order?.id).then((response) => {
        if (response.success) {
          // eslint-disable-next-line
          message = response.data.message;
          showSuccess();
        } else if (!response.status) {
          message = response.message
            ? response.message !== ""
              ? response.message
              : t("errors.wrong")
            : t("errors.wrong");
          showError();
        } else {
          if (response.status >= 400 && response.status < 500) {
            message = response.message
              ? response.message
              : response.errors
              ? response.errors
              : t("errors.wrong");
            showError();
          } else {
            message = response.message
              ? response.message !== ""
                ? response.message
                : t("errors.wrong")
              : t("errors.wrong");
            showError();
          }
        }
      }),

    []
  );
  const renderDesktopLayout = () => {
    return (
      <Fragment>
        {props.cart_items?.line_items?.length > 0 ? (
          <div className="verify-phone-number-page">
            <Header screen_name="ADDRESS" />
            <VerifyPhone
              id={props.order?.id}
              mobile_number={props.order?.number}
              address={props.order?.address}
              request={props.order?.request}
            />
            <div className="verify-phone-page-footer">
              <ShippingFooter />
            </div>
          </div>
        ) : (
          <Redirect
            to={"/" + props.countryUrl + "-" + props.language + "/cart"}
          />
        )}
      </Fragment>
    );
  };

  const renderMobileLayout = () => {
    return (
      <MobileLayoutRenderer
        NavbarTop={true}
        BackBtn={false}
        CancelBtn={true}
        NavbarBottom={false}
      >
        {props.cart_items?.line_items?.length > 0 ? (
          <VerifyPhoneMobile
            id={props.order?.id}
            mobile_number={props.order?.number}
            currAddress={props.order?.address}
            request={props.order?.request}
          />
        ) : (
          <Redirect
            push
            to={"/" + props.countryUrl + "-" + props.language + "/cart"}
          />
        )}
      </MobileLayoutRenderer>
    );
  };

  return width === "sm" ? renderMobileLayout() : renderDesktopLayout();
};

const mapStateToProps = (state) => {
  return {
    country: state.language.country,
    width: state.layout.screenWidth,
    direction: state.direction,
    cart_items: state.cart.cart_items,
    order: state.cart.order,
    language: state.language.language,
    countryUrl: state.language.countryUrl,
  };
};

export default connect(mapStateToProps, null)(VerifyPhoneNumber);
