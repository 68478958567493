import React, { Fragment, useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import { Row } from "reactstrap";
import { Modal, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.css";
import "../../assets/styles/pages/producthomepage.scss";
import MainSlider from "../homepage/MainSlider";
import ProductList from "../product/ProductList";
import WebsiteHeader from "../header/WebsiteHeader";
import WebsiteFooter from "../Footer/WebsiteFooter";
import ProductCategoryList from "../product/ProductCategoryList";
import Working from "../product/Working";
import { set_home_categories } from "../../redux/actions/HomeAction";
import { apiJson } from "../../Api";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { toast } from "react-toastify";


import "react-toastify/dist/ReactToastify.css";
import YelloColorImage from "../../assets/images/homepage/YelloColorImage.png";
import BlueColorImage from "../../assets/images/homepage/BlueColorImage.png";
import { set_country, set_loading } from "../../redux/actions/LayoutAction";
import { set_cart_items } from "../../redux/actions/CartAction";
import { set_redirect_home } from "../../redux/actions/NotFoundAction";
import HeartBanner from "../../assets/images/Alisuq heartbeat Compressed Faster.gif";
import { set_user } from "../../redux/actions/AuthAction";
import OffersBanner from "../../assets/images/offers-banner.png";
import featured1 from "../../assets/images/C&BMaterials-banner.jpg";
import featured2 from "../../assets/images/cleaning.jpg";

import featured3 from "../../assets/images/Hardware&Tools-banner.jpg";
import featured4 from "../../assets/images/Paint-banner.jpg";
import featured5 from "../../assets/images/Appliance-banner.jpg";

import brandweek1 from "../../assets/images/brandsWeek/karcher-icon.png";
import brandweek2 from "../../assets/images/brandsWeek/bissell-icon.png";
import brandweek3 from "../../assets/images/brandsWeek/Drbeckmann-icon.png";
import brandweek4 from "../../assets/images/brandsWeek/weicon-icon.png";
import brandweek5 from "../../assets/images/brandsWeek/blackanddecker-icon.png";
import brandweek6 from "../../assets/images/brandsWeek/Wd40-icon.png";

import topbrand3 from "../../assets/images/top-brands/makita.jpeg";

import catbot1 from "../../assets/images/bottomicons/Tools&Supplies.png";
import catbot2 from "../../assets/images/bottomicons/Crafts.png";
import catbot3 from "../../assets/images/bottomicons/Epoxy.png";
import catbot4 from "../../assets/images/bottomicons/Paints&painters.png";
import catbot5 from "../../assets/images/bottomicons/SprayPaints.png";
import catbot6 from "../../assets/images/bottomicons/WoodFinishes.png";
import catbot7 from "../../assets/images/bottomicons/Caulk&Sealant.png";

import catbot8 from "../../assets/images/bottomicons/screws.png";
import catbot9 from "../../assets/images/bottomicons/nuts.png";
import catbot10 from "../../assets/images/bottomicons/bolts.png";
import catbot11 from "../../assets/images/bottomicons/ropes.png";
import catbot12 from "../../assets/images/bottomicons/Chains.png";
import catbot13 from "../../assets/images/bottomicons/screwdriver.png";
import catbot14 from "../../assets/images/bottomicons/Pliers.png";

import catbot15 from "../../assets/images/bottomicons/Chisel.png";
import catbot16 from "../../assets/images/bottomicons/Spanners.png";
import catbot17 from "../../assets/images/bottomicons/Hammer.png";
import catbot18 from "../../assets/images/bottomicons/Knife&Scissors.png";
import catbot19 from "../../assets/images/bottomicons/PlantSnippers.png";
import catbot20 from "../../assets/images/bottomicons/SafetyGalsses.png";
import catbot21 from "../../assets/images/bottomicons/handrake.png";


import MiddleSection from "./MiddleSection";
import MiddleSectionOld from "./MiddleSectionOld";
import VariantsModal from "../../components/customer/VariantsModal";

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

const en_translation = require("../../assets/locales/en/translation.json");
const ar_translation = require("../../assets/locales/ar/translation.json");

const ProductHomePage = (props) => {
  const [offersModalShow, setOffersModalShow] = React.useState(false);
  const [variantsModalShow, setVariantsModalShow] = React.useState(false);
  const [selectedProduct, setSelectedProduct] = React.useState(null);
  const [selectedSku, setSelectedSku] = React.useState(null);

  const [readMore, setReadMore] = useState(false);
  const extraContent = <div>

    <h3>
      Shop From Best Of Alisuq Products & Categories
    </h3>
    <p>
      Save yourself from the constant trips to the superstore. Instead, opt for online shopping with Alisuq UAE store. Pick from a range of products and categories, exclusively available at the biggest online retail store in Dubai.
      <br></br><br></br>
      We are a customer-focused platform with a vision to make online shopping quick and hassle-free. From bestsellers in home appliances, to the finest brands in <Link to={{
        pathname: "/" + props.countryUrl + "-" + props.language + "/items/products",
        search: "filter_by_category%5B%5D=187&filter_by_category%5B%5D=231&filter_by_category%5B%5D=233&filter_by_category%5B%5D=232"
      }} > flooring material </Link>
      , <Link to={{
        pathname: "/" + props.countryUrl + "-" + props.language + "/items/products",
        search: "filter_by_category%5B%5D=184&filter_by_category%5B%5D=217&filter_by_category%5B%5D=329&filter_by_category%5B%5D=330&filter_by_category%5B%5D=328&filter_by_category%5B%5D=331&filter_by_category%5B%5D=332&filter_by_category%5B%5D=215&filter_by_category%5B%5D=319&filter_by_category%5B%5D=321&filter_by_category%5B%5D=322&filter_by_category%5B%5D=318&filter_by_category%5B%5D=320&filter_by_category%5B%5D=323&filter_by_category%5B%5D=216&filter_by_category%5B%5D=324&filter_by_category%5B%5D=325&filter_by_category%5B%5D=326&filter_by_category%5B%5D=327"
      }} > cleaners </Link>, and <Link to={{
        pathname: "/" + props.countryUrl + "-" + props.language + "/items/products",
        search: "filter_by_category%5B%5D=194&filter_by_category%5B%5D=271&filter_by_category%5B%5D=269&filter_by_category%5B%5D=270&filter_by_category%5B%5D=272&filter_by_category%5B%5D=274&filter_by_category%5B%5D=275&filter_by_category%5B%5D=273"
      }} > paint products </Link>, we have all the right commodities.
      <br></br><br></br>
      With Alisuq’s range of quality-centric, innovative products, the best merchandise is just a few clicks away. Browse through <Link to={
        "/" + props.countryUrl + "-" + props.language + "/items/products"
      } > our categories </Link> to purchase quality paints, cleaning solutions, plumbing accessories, hand tools, and more from top-notch brands. Our swift payment process, and friendly customer service are what customers like most about us.
    </p>
    <h3>
      Explore Alisuq Categories
    </h3>
    <p>
      Unlock amazing offers on Alisuq favorites! We have curated a range of categories to help you in various fields of work. We directly obtain products from top-notch brands, so you do not have to worry about authenticity and originality.
      <br></br><br></br>
      Now you can make the most out of your orders with Alisuq! Browse these categories to extract maximum functionality and reliable utility from your purchases.
      <br></br><br></br>

      <strong>Building and Construction Materials</strong> – Buy top-quality <Link to={{
        pathname: "/" + props.countryUrl + "-" + props.language + "/items/products",
        search: "filter_by_category%5B%5D=201&filter_by_category%5B%5D=288&filter_by_category%5B%5D=282&filter_by_category%5B%5D=283&filter_by_category%5B%5D=284&filter_by_category%5B%5D=285&filter_by_category%5B%5D=286&filter_by_category%5B%5D=287"
      }} > adhesives </Link>, <Link to={{
        pathname: "/" + props.countryUrl + "-" + props.language + "/items/products",
        search: "filter_by_category%5B%5D=280"
      }} > ceiling tiles </Link>, ladders, and much more for various construction and repair projects.
      <br></br><br></br>
      <strong>Appliances</strong>– Find practical electronics like vacuums, refrigerators, dishwashers, and small <Link to={{
        pathname: "/" + props.countryUrl + "-" + props.language + "/items/products",
        search: "filter_by_category%5B%5D=210&filter_by_category%5B%5D=294&filter_by_category%5B%5D=295&filter_by_category%5B%5D=296&filter_by_category%5B%5D=297&filter_by_category%5B%5D=298&filter_by_category%5B%5D=299&filter_by_category%5B%5D=300&filter_by_category%5B%5D=301&filter_by_category%5B%5D=302&filter_by_category%5B%5D=303&filter_by_category%5B%5D=304&filter_by_category%5B%5D=305"
      }} > kitchen appliances </Link>, to perform your daily household chores effortlessly.
      <br></br><br></br>
      <strong>Cleaners</strong> – Pick from high-efficiency glass cleaners, floor cleaners, and <Link to={{
        pathname: "/" + props.countryUrl + "-" + props.language + "/items/products",
        search: "filter_by_category%5B%5D=323"
      }} > stain removers </Link>. You can also find practical <Link to={{
        pathname: "/" + props.countryUrl + "-" + props.language + "/items/products",
        search: "filter_by_category%5B%5D=216&filter_by_category%5B%5D=324&filter_by_category%5B%5D=325&filter_by_category%5B%5D=326&filter_by_category%5B%5D=327"
      }} > cleaning equipment </Link> and accessories at Alisuq.
      <br></br><br></br>
      <strong>Bath & Faucets</strong> – Sophisticated and easy to use bathroom and kitchen installments, including faucets, <Link to={{
        pathname: "/" + props.countryUrl + "-" + props.language + "/items/products",
        search: "filter_by_category%5B%5D=219"
      }} > showers </Link>, <Link to={{
        pathname: "/" + props.countryUrl + "-" + props.language + "/items/products",
        search: "filter_by_category%5B%5D=222"
      }} > sinks </Link>, and other accessories.
      <br></br><br></br>
      <strong>Electrical Accessories</strong> – Find everything an electrician may possibly need, including cable wires, lighting, <Link to={{
        pathname: "/" + props.countryUrl + "-" + props.language + "/items/products",
        search: "filter_by_category%5B%5D=226"
      }} > circuit breakers </Link> and <Link to={{
        pathname: "/" + props.countryUrl + "-" + props.language + "/items/products",
        search: "filter_by_category%5B%5D=230&filter_by_category%5B%5D=339&filter_by_category%5B%5D=340&filter_by_category%5B%5D=341&filter_by_category%5B%5D=342"
      }}> batteries </Link>.
      <br></br><br></br>
      <strong>Flooring Products</strong> – Redefine your interiors with contemporary designs in <Link to={{
        pathname: "/" + props.countryUrl + "-" + props.language + "/items/products",
        search: "filter_by_category%5B%5D=231"
      }} > flooring tiles </Link> and paints.
      <br></br><br></br>
      <strong>Decor & Furniture products</strong> – Transforming your interiors into a luxury space is convenient with premium-quality furniture, <Link to={{
        pathname: "/" + props.countryUrl + "-" + props.language + "/items/products",
        search: "filter_by_category%5B%5D=238&filter_by_category%5B%5D=343&filter_by_category%5B%5D=344&filter_by_category%5B%5D=345"
      }}> wall decor </Link>, and lighting.
      <br></br><br></br>
      <strong>Lawn & Garden Maintenance</strong> – Keep your outdoors clean and green with the most in-demand <Link to={{
        pathname: "/" + props.countryUrl + "-" + props.language + "/items/products",
        search: "filter_by_category%5B%5D=244&filter_by_category%5B%5D=359&filter_by_category%5B%5D=360"
      }} > plants </Link>, <Link to={{
        pathname: "/" + props.countryUrl + "-" + props.language + "/items/products",
        search: "filter_by_category%5B%5D=251&filter_by_category%5B%5D=365&filter_by_category%5B%5D=366&filter_by_category%5B%5D=367&filter_by_category%5B%5D=368&filter_by_category%5B%5D=369&filter_by_category%5B%5D=370&filter_by_category%5B%5D=371&filter_by_category%5B%5D=372&filter_by_category%5B%5D=373"
      }} > gardening tools </Link>, and <Link to={{
        pathname: "/" + props.countryUrl + "-" + props.language + "/items/products",
        search: "filter_by_category%5B%5D=363"
      }} > pesticides </Link>.
      <br></br><br></br>
      <strong>Kitchenware</strong> – Build a kitchen from scratch or revamp an old kitchen with durable <Link to={{
        pathname: "/" + props.countryUrl + "-" + props.language + "/items/products",
        search: "filter_by_category%5B%5D=252"
      }} > kitchen cabinets </Link>, faucets, and glassware.
      <br></br><br></br>
      <strong>Hardware & Tools</strong> – Choose from a range of hand and power tools to perform quick repair and installation tasks.
      <br></br><br></br>
      <strong>Outdoor Recreation</strong> – Spend quality time outdoors with the best camping equipment, barbecue grills, and <Link to={{
        pathname: "/" + props.countryUrl + "-" + props.language + "/items/products",
        search: "filter_by_category%5B%5D=192&filter_by_category%5B%5D=263&filter_by_category%5B%5D=264&filter_by_category%5B%5D=265"
      }} > travel accessories </Link> from Alisuq.
      <br></br><br></br>
      <strong>Automotive Gear</strong> – We offer the best <Link to={{
        pathname: "/" + props.countryUrl + "-" + props.language + "/items/products",
        search: "filter_by_category%5B%5D=266"
      }} > automotive oils </Link>, accessories, and <Link to={{
        pathname: "/" + props.countryUrl + "-" + props.language + "/items/products",
        search: "filter_by_category%5B%5D=419"
      }} > anti-rust cleaners </Link>. These products can help extend the life and performance of your vehicles. Automotive Gear is the place to go for all your automotive needs.
      <br></br><br></br>
      <strong>Paint Products</strong> - Give your home a novel feel with high quality <Link to={{
        pathname: "/" + props.countryUrl + "-" + props.language + "/items/products",
        search: "filter_by_category%5B%5D=273&filter_by_category%5B%5D=444&filter_by_category%5B%5D=445"
      }} > spray paints </Link>, <Link to={{
        pathname: "/" + props.countryUrl + "-" + props.language + "/items/products",
        search: "filter_by_category%5B%5D=274"
      }}> wood finishes </Link>, primers, and <Link to={{
        pathname: "/" + props.countryUrl + "-" + props.language + "/items/products",
        search: "filter_by_category%5B%5D=271"
      }}> epoxies </Link>.

    </p>
    <h3>
      Adopt A Higher Standard Of Living With Alisuq Top Brands
    </h3>
    <p>
      Alisuq store features bestselling appliances, cleaners, and hardware from industry-leading brands. We adopt a zero-compromise policy on authenticity to maintain the quality of our products. With a performance-centric and tech-efficient stock, shopping with us is reliable and secure.
      <br></br><br></br>
      As a direct contractor of leading brands, we offer a wide array of options. Each of the Alisuq product is hand-picked to deliver long-term utility in construction, renovation, and cleaning tasks.
      <br></br><br></br>
      Shop top-quality home appliances in UAE - integrating cutting-edge technology – from <Link to={{
        pathname: "/" + props.countryUrl + "-" + props.language + "/items/products",
        search: "filter_by_brand%5B%5D=44"
      }}> Karcher </Link>, <Link to={{
        pathname: "/" + props.countryUrl + "-" + props.language + "/items/products",
        search: "filter_by_brand%5B%5D=63"
      }} > Makita </Link>, <Link to={{
        pathname: "/" + props.countryUrl + "-" + props.language + "/items/products",
        search: "filter_by_brand%5B%5D=125"
      }}>Bissel</Link>, Phillips, and others.
      <br></br><br></br>
      Our hardware & Tools category is filled with best quality power drills, fasteners, wrenches, pliers, impact drivers, saws, and more. Purchase from Stabila, <Link to={{
        pathname: "/" + props.countryUrl + "-" + props.language + "/items/products",
        search: "filter_by_brand%5B%5D=151"
      }}>YATO</Link>,
      <Link to={{
        pathname: "/" + props.countryUrl + "-" + props.language + "/items/products",
        search: "filter_by_brand%5B%5D=73"
      }}>Bosch</Link>, VTOOLs, and others.
      <br></br><br></br>
      Explore our cleaners range to find tough cleaning solutions, sprays, stain removers, in addition to brooms, mops, and all-purpose cleaners. Shop from big brands like Krud Kutter, <Link to={{
        pathname: "/" + props.countryUrl + "-" + props.language + "/items/products",
        search: "filter_by_brand%5B%5D=63&filter_by_brand%5B%5D=247"
      }}>Astonish</Link>, <Link to={{
        pathname: "/" + props.countryUrl + "-" + props.language + "/items/products",
        search: "filter_by_brand%5B%5D=248"
      }}>Dr.Beckmann</Link>, <Link to={{
        pathname: "/" + props.countryUrl + "-" + props.language + "/items/products",
        search: "filter_by_brand%5B%5D=63&filter_by_brand%5B%5D=246"
      }}>Eco Care</Link>.
      <br></br><br></br>
      Purchase from Alisuq top brands to extract maximum practicality from your appliances, kitchenware, and automotive gear. Combining all global and local brands under one roof, we bring consumers a one-stop solution to all their shopping needs.
    </p>
    <h3>
      Largest Platform For Online Home Shopping In UAE & Dubai
    </h3>
    <p>
      Shopping with Alisuq keeps you protected from scams and counterfeits. Our pride lies in the delivery of first-rate, quality-assured products. This way, we make sure that your online home shopping in UAE is free from all risks. Shop from our featured brands that keep luxury and functionality at the top of their priority list.
      <br></br><br></br>
      We are a dedicated team of professionals, with the sole purpose of supplying high-utility home improvement goods throughout the UAE. Browse our range to enjoy the fastest and most convenient shopping, right from the comfort of your home!

    </p>
    <h3>
      Stress-Free Purchasing With The Leading UAE Store

    </h3>
    <p>
      Quit dawdling and start building with the widest array of choices – featuring millions of products in our consumer-trusted retail store.
      <br></br><br></br>
      <li>Alisuq makes online shopping safe and easy with quality assurance and an easy return policy.
      </li>
      <li>Shop to your heart’s content with the best deals and online offers, all available under one roof!
      </li>
      <li>Avail amazing discounts and additional reductions with our occasional seasonal sales.
      </li>
      <li>Save time and money shopping by Alisuq brands that are commended both internationally and locally.
      </li>
      <br></br>
      Our swift payment process lets you pay via cash on delivery or use your credit/debit cards. Enjoy free shipping on big orders that exceed a certain amount.
      <br></br><br></br>
      Your queries and concerns are always tended to as soon as possible with the most devoted customer service of Alisuq.
      <br></br><br></br>
      Explore different categories. Browse through a variety of products. Find the perfect&nbsp;
      <Link to={{
        pathname: "/" + props.countryUrl + "-" + props.language + "/items/products",
        search: "filter_by_category%5B%5D=183&filter_by_category%5B%5D=205&filter_by_category%5B%5D=290&filter_by_category%5B%5D=289&filter_by_category%5B%5D=206&filter_by_category%5B%5D=291&filter_by_category%5B%5D=207&filter_by_category%5B%5D=210&filter_by_category%5B%5D=294&filter_by_category%5B%5D=295&filter_by_category%5B%5D=296&filter_by_category%5B%5D=297&filter_by_category%5B%5D=298&filter_by_category%5B%5D=299&filter_by_category%5B%5D=300&filter_by_category%5B%5D=301&filter_by_category%5B%5D=302&filter_by_category%5B%5D=303&filter_by_category%5B%5D=304&filter_by_category%5B%5D=305&filter_by_category%5B%5D=211&filter_by_category%5B%5D=306&filter_by_category%5B%5D=307&filter_by_category%5B%5D=308&filter_by_category%5B%5D=309&filter_by_category%5B%5D=212&filter_by_category%5B%5D=310&filter_by_category%5B%5D=449&filter_by_category%5B%5D=448&filter_by_category%5B%5D=450&filter_by_category%5B%5D=311&filter_by_category%5B%5D=452&filter_by_category%5B%5D=453&filter_by_category%5B%5D=451&filter_by_category%5B%5D=312&filter_by_category%5B%5D=313&filter_by_category%5B%5D=214&filter_by_category%5B%5D=208&filter_by_category%5B%5D=209&filter_by_category%5B%5D=292&filter_by_category%5B%5D=293&filter_by_category%5B%5D=213&filter_by_category%5B%5D=314&filter_by_category%5B%5D=315&filter_by_category%5B%5D=316&filter_by_category%5B%5D=317"
      }}>appliances</Link>, <Link to={{
        pathname: "/" + props.countryUrl + "-" + props.language + "/items/products",
        search: "filter_by_category%5B%5D=184&filter_by_category%5B%5D=217&filter_by_category%5B%5D=329&filter_by_category%5B%5D=330&filter_by_category%5B%5D=328&filter_by_category%5B%5D=331&filter_by_category%5B%5D=332&filter_by_category%5B%5D=215&filter_by_category%5B%5D=319&filter_by_category%5B%5D=321&filter_by_category%5B%5D=322&filter_by_category%5B%5D=318&filter_by_category%5B%5D=320&filter_by_category%5B%5D=323&filter_by_category%5B%5D=216&filter_by_category%5B%5D=324&filter_by_category%5B%5D=325&filter_by_category%5B%5D=326&filter_by_category%5B%5D=327"
      }}>cleaners</Link>, <Link to={{
        pathname: "/" + props.countryUrl + "-" + props.language + "/items/products",
        search: "filter_by_category%5B%5D=191&filter_by_category%5B%5D=259&filter_by_category%5B%5D=262&filter_by_category%5B%5D=257&filter_by_category%5B%5D=260&filter_by_category%5B%5D=261&filter_by_category%5B%5D=258"
      }}>hardware</Link>, <Link to={{
        pathname: "/" + props.countryUrl + "-" + props.language + "/items/products",
        search: "filter_by_category%5B%5D=240&filter_by_category%5B%5D=346&filter_by_category%5B%5D=347&filter_by_category%5B%5D=348"
      }}>furniture</Link>, and decor for your home today. Shop now for best deals!

    </p>

  </div>
  const linkName = readMore ? 'Read Less ▴ ' : 'Read More ▾ '

  let message = "";
  const showError = () => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  const { t } = useTranslation();
  let sections = [];

  function shuffle(array) {
    let currentIndex = array.length,
      randomIndex;
    while (currentIndex !== 0) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex],
        array[currentIndex],
      ];
    }

    return array;
  }

  const setSection = (section, position, allProducts) => {
    switch (position) {
      case 2:
        props.set_home_categories({
          positionTwo:
            section?.data?.length < 1
              ? { ...section, data: allProducts }
              : { ...section, data: section.data },
        });
        break;
      case 3:
        props.set_home_categories({
          positionThree:
            section?.data?.length < 1
              ? { ...section, data: allProducts }
              : { ...section, data: section.data },
        });
        break;
      case 4:
        props.set_home_categories({
          positionFour:
            section?.data?.length < 1
              ? { ...section, data: allProducts }
              : { ...section, data: section.data },
        });
        break;
      case 5:
        props.set_home_categories({
          positionFive:
            section?.data?.length < 1
              ? { ...section, data: allProducts }
              : { ...section, data: section.data },
        });
        break;
      case 6:
        props.set_home_categories({
          positionSix:
            section?.data?.length < 1
              ? { ...section, data: allProducts }
              : { ...section, data: section.data },
        });
        break;
      case 7:
        props.set_home_categories({
          positionSeven:
            section?.data?.length < 1
              ? { ...section, data: allProducts }
              : { ...section, data: section.data },
        });
        break;
      case 8:
        props.set_home_categories({
          positionEight:
            section?.data?.length < 1
              ? { ...section, data: allProducts }
              : { ...section, data: section.data },
        });
        break;
      case 9:
        props.set_home_categories({
          positionNine:
            section?.data?.length < 1
              ? { ...section, data: allProducts }
              : { ...section, data: section.data },
        });
        break;
      case 10:
        props.set_home_categories({
          positionTen:
            section?.data?.length < 1
              ? { ...section, data: allProducts }
              : { ...section, data: section.data },
        });
        break;
      case 11:
        props.set_home_categories({
          positionEleven:
            section?.data?.length < 1
              ? { ...section, data: allProducts }
              : { ...section, data: section.data },
        });
        break;
      case 12:
        props.set_home_categories({
          positionTwelve:
            section?.data?.length < 1
              ? { ...section, data: allProducts }
              : { ...section, data: section.data },
        });
        break;
      case 13:
        props.set_home_categories({
          positionThirteen:
            section?.data?.length < 1
              ? { ...section, data: allProducts }
              : { ...section, data: section.data },
        });
        break;
      default:
        sections.push(section);
    }
  };

  useEffect(() => {
    document.title = "Alisuq.com - Buy All Your Home Needs Online, UAE";
  }, []);

  useEffect(() => {
    props.redirect && props?.set_redirect_home(false);
    window.scroll(0, 0);
    props.set_loading(true);
    (localStorage.getItem("access_token") ||
      localStorage.getItem("user_key")) &&
      apiJson.cart.getCartItems(props.countryId).then((response) => {
        if (response.success) {
          props.set_cart_items({ cart_items: response.data });
        } else if (!response.status) {
          // eslint-disable-next-line
          message = response.message
            ? response.message !== ""
              ? response.message
              : t("errors.wrong")
            : t("errors.wrong");
          showError();
        } else {
          if (response.status >= 400 && response.status < 500) {
            message = response.message
              ? response.message
              : response.errors
                ? response.errors
                : t("errors.wrong");
            showError();
          } else {
            message = response.message
              ? response.message !== ""
                ? response.message
                : t("errors.wrong")
              : t("errors.wrong");
            showError();
          }
        }
      });

    apiJson.homepage.getBanners(props.countryId).then((response) => {
      if (response.success) {
        let primaryBanners = [];
        let secondaryBanners = [];

        response.data.map((banner) =>
          banner.banner_type === "platform_primary"
            ? primaryBanners.push(banner)
            : secondaryBanners.push(banner)
        );
        props.set_home_categories({
          primaryBanners: primaryBanners,
          secondaryBanners: secondaryBanners,
        });
        props.set_loading(false);
      } else if (!response.status) {
        props.set_loading(false);
        message = response.message
          ? response.message !== ""
            ? response.message
            : t("errors.wrong")
          : t("errors.wrong");
        showError();
      } else {
        props.set_loading(false);
        if (response.status >= 400 && response.status < 500) {
          message = response.message
            ? response.message
            : response.errors
              ? response.errors
              : t("errors.wrong");
          showError();
        } else {
          message = response.message
            ? response.message !== ""
              ? response.message
              : t("errors.wrong")
            : t("errors.wrong");
          showError();
        }
      }
    });

    let allProducts = [];

    apiJson.homepage.getFirstSection(props.countryId).then((response) => {
      if (response.success) {
        const temp = {
          title_en: response.data.section.title_en,
          title_ar: response.data.section.title_ar,
          data: [
            ...response.data.section.products,
            ...response.data.section.services,
          ],
        };
        props.set_home_categories({
          positionOne: {
            id: response.data.section.id,
            title_en: response.data.section.title_en,
            title_ar: response.data.section.title_ar,
            data: [
              ...response.data.section.products,
              ...response.data.section.services,
            ],
          },
        });

        let i = 0;
        response.data.section_ids?.map((id) =>
          apiJson.homepage.getSections(id, props.countryId).then((section) => {
            ++i;
            if (i === 1) {
              temp?.data?.length < 1 &&
                props.set_home_categories({
                  positionOne: { ...temp, data: allProducts },
                });
            }
            if (section.success) {
              section.data.title_en !== "Weekly Sale 1" &&
                section.data.title_en !== "Weekly Sale 2" &&
                section.data.title_en !== "Combo Sale 1" &&
                section.data.title_en !== "Combo Sale 2" &&
                section.data.title_en !== "Brand Favourites 1" &&
                section.data.title_en !== "Brand Favourites 2" &&
                section.data.title_en !== "Brand Favourites 3" &&
                section.data.title_en !== "Offers" &&
                setSection(
                  {
                    id: section.data.id,
                    title_en: section.data.title_en,
                    title_ar: section.data.title_ar,
                    data: [...section.data.products, ...section.data.services],
                  },

                  section.data.position ? section.data.position : null,
                  shuffle(allProducts)
                );
              if (i === response.data.section_ids.length) {
                props.set_home_categories({
                  sections: sections,
                });
              }
            }
          })
        );
      }
    });

    apiJson.allitems
      .getItems(props.countryId, "products", 1, 10)
      .then((response) => {
        response.success
          ? (allProducts = response.data.products)
          : (allProducts = []);
      });

    apiJson.homepage.getCategories(props.countryId).then((response) => {
      if (response.success) {
        props.set_home_categories({ categories: response.data });
      } else if (!response.status) {
        message = response.message
          ? response.message !== ""
            ? response.message
            : t("errors.wrong")
          : t("errors.wrong");
        showError();
      } else {
        if (response.status >= 400 && response.status < 500) {
          message = response.message
            ? response.message
            : response.errors
              ? response.errors
              : t("errors.wrong");
          showError();
        } else {
          message = response.message
            ? response.message !== ""
              ? response.message
              : t("errors.wrong")
            : t("errors.wrong");
          showError();
        }
      }
    });

    apiJson.cart.guestUser(props.countryId).then((response) => {
      if (response.success) {
        props.set_user({
          guestUser: response.data.platform_settings.guest_user_checkout,
          guestSignin: false,
        });
      } else {
        props.set_user({
          guestUser: false,
        });
      }
    });
  }, [props.countryId]);

  const onQuickViewClicked = (product, sku) => {
    setVariantsModalShow(true);
    setSelectedSku(sku);

    apiJson.
      singleItem.getItemData(product.id, product.product_type ? "products" : "services", props.countryId)
      .then(response => {
        response.data.type = "Product";

        setSelectedProduct(response.data);
      });
  }

  return (
    <Fragment>
      <VariantsModal
        show={variantsModalShow}
        onHide={() => setVariantsModalShow(false)}
        closeModal={() => setVariantsModalShow(false)}
        product={selectedProduct}
        selected_sku={selectedSku}
      />
      <div className="product-home-page-desktop mx-auto">
        <WebsiteHeader />
        <MainSlider />
        {!props.loading && (
          <>
            <div className="content-wrapper all-page-container">

              <div className="emptyspacer"></div>
              <div className="sectiontitle-container-left">
                <h2 className="section-title lft catsec">
                  {
                    props.language == "en" ?
                      en_translation["homePage"]["categories-heading"]
                      :
                      ar_translation["homePage"]["categories-heading"]
                  }
                  {/* {t("homePage.categories-heading")} */}
                  <div className="ViewAllbtn catsecbtn">
                    <Link
                      to={{
                        pathname: "/" + props.countryUrl +
                          "-" + props.language +
                          "/items/products"
                      }}
                    >
                      {
                        props.language == "en" ?
                          en_translation["all-categories"]["view-all"]
                          :
                          ar_translation["all-categories"]["view-all"]
                      }
                      {/* {t("all-categories.view-all")} */}
                    </Link>
                  </div>
                </h2>
              </div>
              <Row
                className={`mt-4 mx-auto ${props.positionOne?.data?.length > 0 ? "" : " invisible"
                  } `}
              >

                <ProductCategoryList />
              </Row>
              <div className="emptyspacer"></div>
              <div className="emptyspacer"></div>
              <Row className="working-row mx-auto">
                <Working />
              </Row>
              <MiddleSection
                country={props.countryId}
                onQuickViewClicked={onQuickViewClicked}
              />



              <div>
                <div className="sectiontitle-container-left">
                  <h2 className="section-title lft catsec">
                    {props.positionOne[`title_` + props.language]}

                    <div className="ViewAllbtn catsecbtn">
                      <Link to={{
                        pathname: "/" + props.countryUrl +
                          "-" + props.language +
                          "/section/products/" + props.positionOne.id
                      }}>
                        {
                          props.language == "en" ?
                            en_translation["all-categories"]["view-all"]
                            :
                            ar_translation["all-categories"]["view-all"]
                        }
                        {/* {t("all-categories.view-all")} */}
                      </Link>
                    </div>
                  </h2>
                </div>
                {props.positionOne?.data?.length > 0 && (
                  <Row
                    key={1}
                    className={`${props.positionOne.title_en
                      .toLowerCase()
                      .replace(/ /g, "-")} product-list-row mx-auto`}
                  >
                    <ProductList
                      type="product"
                      home={true}
                      products={props.positionOne.data}
                      onQuickViewClicked={onQuickViewClicked}
                    />
                    <div />
                  </Row>
                )}
              </div>



              <div className="sectiontitle-container-left">

                <h2 className="section-title lft catsec">
                  {
                    props.language == "en" ?
                      en_translation["homePage"]["create-a"]
                      :
                      ar_translation["homePage"]["create-a"]
                  }


                  <div className="ViewAllbtn catsecbtn">
                    <Link to={{
                      pathname: "/" + props.countryUrl +
                        "-" + props.language +
                        "/items/products",
                      search: "?filter_by_price%5Bmax%5D=30"
                    }}>
                      {
                        props.language == "en" ?
                          en_translation["all-categories"]["view-all"]
                          :
                          ar_translation["all-categories"]["view-all"]
                      }
                      {/* {t("all-categories.view-all")} */}
                    </Link>
                  </div>
                </h2>
              </div>
              <div className="emptyspacer"></div>
              <Row className="featuredcat">
                <div className="col-lg-6 col-md-6 mia">
                  <Link
                    to={{
                      pathname: "/" + props.countryUrl +
                        "-" + props.language +
                        "/items/products",
                      search: "?filter_by_category%5B%5D=182&filter_by_category%5B%5D=195&filter_by_category%5B%5D=196&filter_by_category%5B%5D=197&filter_by_category%5B%5D=198&filter_by_category%5B%5D=203&filter_by_category%5B%5D=199&filter_by_category%5B%5D=200&filter_by_category%5B%5D=201&filter_by_category%5B%5D=202&filter_by_category%5B%5D=204"
                    }}
                  >
                    <img className="homeofferimg"
                      src={featured1}
                      alt="Building and Construction Materials"
                    />
                  </Link>
                </div>
                <div className="col-lg-6 col-md-6 mib">

                  <Row>
                    <div className="col-lg-6 col-md-6 mic">
                      <Link
                        to={{
                          pathname: "/" + props.countryUrl +
                            "-" + props.language +
                            "/items/products",
                          search: "?filter_by_category%5B%5D=184&filter_by_category%5B%5D=217&filter_by_category%5B%5D=329&filter_by_category%5B%5D=330&filter_by_category%5B%5D=328&filter_by_category%5B%5D=331&filter_by_category%5B%5D=332&filter_by_category%5B%5D=215&filter_by_category%5B%5D=319&filter_by_category%5B%5D=321&filter_by_category%5B%5D=322&filter_by_category%5B%5D=318&filter_by_category%5B%5D=320&filter_by_category%5B%5D=323&filter_by_category%5B%5D=216&filter_by_category%5B%5D=324&filter_by_category%5B%5D=325&filter_by_category%5B%5D=326&filter_by_category%5B%5D=327"
                        }}
                      >
                        <img className="homeofferimg"
                          src={featured2}
                          alt="Cleaners"
                        />
                      </Link>
                    </div>
                    <div className="col-lg-6 col-md-6 mid">
                      <Link
                        to={{
                          pathname: "/" + props.countryUrl +
                            "-" + props.language +
                            "/items/products",
                          search: "?filter_by_category%5B%5D=191&filter_by_category%5B%5D=259&filter_by_category%5B%5D=262&filter_by_category%5B%5D=257&filter_by_category%5B%5D=260&filter_by_category%5B%5D=261&filter_by_category%5B%5D=258"
                        }}
                      >
                        <img className="homeofferimg"
                          src={featured3}
                          alt="Hardware & Tools"
                        />
                      </Link>
                    </div>
                  </Row>

                  <Row>
                    <div className="col-lg-6 col-md-6 mie">
                      <Link
                        to={{
                          pathname: "/" + props.countryUrl +
                            "-" + props.language +
                            "/items/products",
                          search: "?filter_by_category%5B%5D=194&filter_by_category%5B%5D=271&filter_by_category%5B%5D=269&filter_by_category%5B%5D=270&filter_by_category%5B%5D=272&filter_by_category%5B%5D=274&filter_by_category%5B%5D=275&filter_by_category%5B%5D=273"
                        }}
                      >
                        <img className="homeofferimg"
                          src={featured4}
                          alt="Paint"
                        />
                      </Link>
                    </div>
                    <div className="col-lg-6 col-md-6 mif">
                      <Link
                        to={{
                          pathname: "/" + props.countryUrl +
                            "-" + props.language +
                            "/items/products",
                          search: "?filter_by_category%5B%5D=183&filter_by_category%5B%5D=205&filter_by_category%5B%5D=290&filter_by_category%5B%5D=289&filter_by_category%5B%5D=206&filter_by_category%5B%5D=291&filter_by_category%5B%5D=207&filter_by_category%5B%5D=210&filter_by_category%5B%5D=294&filter_by_category%5B%5D=295&filter_by_category%5B%5D=296&filter_by_category%5B%5D=297&filter_by_category%5B%5D=298&filter_by_category%5B%5D=299&filter_by_category%5B%5D=300&filter_by_category%5B%5D=301&filter_by_category%5B%5D=302&filter_by_category%5B%5D=303&filter_by_category%5B%5D=304&filter_by_category%5B%5D=305&filter_by_category%5B%5D=211&filter_by_category%5B%5D=306&filter_by_category%5B%5D=307&filter_by_category%5B%5D=308&filter_by_category%5B%5D=309&filter_by_category%5B%5D=212&filter_by_category%5B%5D=310&filter_by_category%5B%5D=449&filter_by_category%5B%5D=448&filter_by_category%5B%5D=450&filter_by_category%5B%5D=311&filter_by_category%5B%5D=452&filter_by_category%5B%5D=453&filter_by_category%5B%5D=451&filter_by_category%5B%5D=312&filter_by_category%5B%5D=313&filter_by_category%5B%5D=214&filter_by_category%5B%5D=208&filter_by_category%5B%5D=209&filter_by_category%5B%5D=292&filter_by_category%5B%5D=293&filter_by_category%5B%5D=213&filter_by_category%5B%5D=314&filter_by_category%5B%5D=315&filter_by_category%5B%5D=316&filter_by_category%5B%5D=317"
                        }}
                      >
                        <img className="homeofferimg"
                          src={featured5}
                          alt="Appliances"
                        />
                      </Link>
                    </div>
                  </Row>

                </div>
              </Row>

              <div className="emptyspacer-big"></div>
              {/* home renovation essentials */}
              <div>
                <div className="sectiontitle-container-left">
                  <h2 className="section-title lft catsec">
                    {props.positionTwo[`title_` + props.language]}

                    <div className="ViewAllbtn catsecbtn">
                      <Link to={{
                        pathname: "/" + props.countryUrl +
                          "-" + props.language +
                          "/section/products/" + props.positionTwo.id
                      }}>
                        {
                          props.language == "en" ?
                            en_translation["all-categories"]["view-all"]
                            :
                            ar_translation["all-categories"]["view-all"]
                        }
                        {/* {t("all-categories.view-all")} */}
                      </Link>
                    </div>
                  </h2>
                </div>
                {props.positionTwo?.data?.length > 0 && (
                  <Row
                    key={1}
                    className={`${props.positionTwo.title_en
                      .toLowerCase()
                      .replace(/ /g, "-")} product-list-row mx-auto`}
                  >
                    <ProductList
                      type="product"
                      home={true}
                      products={props.positionTwo.data}
                      onQuickViewClicked={onQuickViewClicked}
                    />
                    <div />
                  </Row>
                )}
              </div>

              {/* Paint Colors & Inspiration */}
              <div>
                <div className="sectiontitle-container-left">
                  <h2 className="section-title lft catsec">
                    {props.positionThree[`title_` + props.language]}

                    <div className="ViewAllbtn catsecbtn">
                      <Link to={{
                        pathname: "/" + props.countryUrl +
                          "-" + props.language +
                          "/section/products/" + props.positionThree.id
                      }}>
                        {
                          props.language == "en" ?
                            en_translation["all-categories"]["view-all"]
                            :
                            ar_translation["all-categories"]["view-all"]
                        }
                        {/* {t("all-categories.view-all")} */}
                      </Link>
                    </div>
                  </h2>
                </div>
                {props.positionThree?.data?.length > 0 && (
                  <Row
                    key={1}
                    className={`${props.positionThree.title_en
                      .toLowerCase()
                      .replace(/ /g, "-")} product-list-row mx-auto`}
                  >
                    <ProductList
                      type="product"
                      home={true}
                      products={props.positionThree.data}
                      onQuickViewClicked={onQuickViewClicked}
                    />
                    <div />
                  </Row>
                )}
              </div>
              {/* All purpose cleaner */}
              <div>
                <div className="sectiontitle-container-left">
                  <h2 className="section-title lft catsec">
                    {props.positionFour[`title_` + props.language]}

                    <div className="ViewAllbtn catsecbtn">
                      <Link to={{
                        pathname: "/" + props.countryUrl +
                          "-" + props.language +
                          "/section/products/" + props.positionFour.id
                      }}>
                        {
                          props.language == "en" ?
                            en_translation["all-categories"]["view-all"]
                            :
                            ar_translation["all-categories"]["view-all"]
                        }
                        {/* {t("all-categories.view-all")} */}
                      </Link>
                    </div>
                  </h2>
                </div>
                {props.positionFour?.data?.length > 0 && (
                  <Row
                    key={1}
                    className={`${props.positionFour.title_en
                      .toLowerCase()
                      .replace(/ /g, "-")} product-list-row mx-auto`}
                  >
                    <ProductList
                      type="product"
                      home={true}
                      products={props.positionFour.data}
                      onQuickViewClicked={onQuickViewClicked}
                    />
                    <div />
                  </Row>
                )}
              </div>
              {/* cleaner for every surface */}
              <div>
                <div className="sectiontitle-container-left">
                  <h2 className="section-title lft catsec">
                    {props.positionFive[`title_` + props.language]}

                    <div className="ViewAllbtn catsecbtn">
                      <Link to={{
                        pathname: "/" + props.countryUrl +
                          "-" + props.language +
                          "/section/products/" + props.positionFive.id
                      }}>
                        {
                          props.language == "en" ?
                            en_translation["all-categories"]["view-all"]
                            :
                            ar_translation["all-categories"]["view-all"]
                        }
                        {/* {t("all-categories.view-all")} */}
                      </Link>
                    </div>
                  </h2>
                </div>
                {props.positionFive?.data?.length > 0 && (
                  <Row
                    key={1}
                    className={`${props.positionFive.title_en
                      .toLowerCase()
                      .replace(/ /g, "-")} product-list-row mx-auto`}
                  >
                    <ProductList
                      type="product"
                      home={true}
                      products={props.positionFive.data}
                      onQuickViewClicked={onQuickViewClicked}
                    />
                    <div />
                  </Row>
                )}
              </div>
              {/* cleaner equipment */}
              <div>
                <div className="sectiontitle-container-left">
                  <h2 className="section-title lft catsec">
                    {props.positionSix[`title_` + props.language]}

                    <div className="ViewAllbtn catsecbtn">
                      <Link to={{
                        pathname: "/" + props.countryUrl +
                          "-" + props.language +
                          "/section/products/" + props.positionSix.id
                      }}>
                        {
                          props.language == "en" ?
                            en_translation["all-categories"]["view-all"]
                            :
                            ar_translation["all-categories"]["view-all"]
                        }
                        {/* {t("all-categories.view-all")} */}
                      </Link>
                    </div>
                  </h2>
                </div>
                {props.positionSix?.data?.length > 0 && (
                  <Row
                    key={1}
                    className={`${props.positionSix.title_en
                      .toLowerCase()
                      .replace(/ /g, "-")} product-list-row mx-auto`}
                  >
                    <ProductList
                      type="product"
                      home={true}
                      products={props.positionSix.data}
                      onQuickViewClicked={onQuickViewClicked}
                    />
                    <div />
                  </Row>
                )}
              </div>
              {/* cleaner essentials */}
              <div>
                <div className="sectiontitle-container-left">
                  <h2 className="section-title lft catsec">
                    {props.positionSeven[`title_` + props.language]}

                    <div className="ViewAllbtn catsecbtn">
                      <Link to={{
                        pathname: "/" + props.countryUrl +
                          "-" + props.language +
                          "/section/products/" + props.positionSeven.id
                      }}>
                        {
                          props.language == "en" ?
                            en_translation["all-categories"]["view-all"]
                            :
                            ar_translation["all-categories"]["view-all"]
                        }
                        {/* {t("all-categories.view-all")} */}
                      </Link>
                    </div>
                  </h2>
                </div>
                {props.positionSeven?.data?.length > 0 && (
                  <Row
                    key={1}
                    className={`${props.positionSeven.title_en
                      .toLowerCase()
                      .replace(/ /g, "-")} product-list-row mx-auto`}
                  >
                    <ProductList
                      type="product"
                      home={true}
                      products={props.positionSeven.data}
                      onQuickViewClicked={onQuickViewClicked}
                    />
                    <div />
                  </Row>
                )}
              </div>


              <div className="sectiontitle-container-left">

                <h2 className="section-title lft catsec">
                  {
                    props.language == "en" ?
                      en_translation["homePage"]["of-the-week"]
                      :
                      ar_translation["homePage"]["of-the-week"]
                  }
                  {/* {t("homePage.of-the-week")} */}
                </h2>
              </div>

              <div className="emptyspacer-big"></div>
              <div className="emptyspacer-big"></div>
              <div className="emptyspacer"></div>
              <div className="emptyspacer"></div>
              <div className="one1">
                <div className="two2"></div>
                <div className="three3">

                  <div className="four4">
                    <div>
                      <Link
                        to={{
                          pathname: "/" + props.countryUrl +
                            "-" + props.language +
                            "/items/products",
                          search: "?filter_by_brand%5B%5D=44"
                        }}
                      >

                        <div className="six6">
                          <img src={brandweek1}>

                          </img>
                        </div>
                      </Link>
                    </div>
                  </div>
                  <div className="four4">
                    <div>
                      <Link
                        to={{
                          pathname: "/" + props.countryUrl +
                            "-" + props.language +
                            "/items/products",
                          search: "?filter_by_brand%5B%5D=125"
                        }}
                      >
                        <div className="six6">
                          <img src={brandweek2}>
                          </img>
                        </div>
                      </Link>
                    </div>
                  </div>
                  <div className="four4">
                    <div>
                      <Link
                        to={{
                          pathname: "/" + props.countryUrl +
                            "-" + props.language +
                            "/items/products",
                          search: "?filter_by_brand%5B%5D=248"
                        }}
                      >
                        <div className="six6">
                          <img src={brandweek3}>
                          </img>
                        </div>
                      </Link>
                    </div>
                  </div>
                  <div className="four4">
                    <div>
                      <Link
                        to={{
                          pathname: "/" + props.countryUrl +
                            "-" + props.language +
                            "/items/products",
                          search: "?filter_by_brand%5B%5D=164"
                        }}
                      >
                        <div className="six6">
                          <img src={brandweek4}>
                          </img>
                        </div>
                      </Link>
                    </div>
                  </div>
                  <div className="four4">
                    <div>
                      <Link
                        to={{
                          pathname: "/" + props.countryUrl +
                            "-" + props.language +
                            "/items/products",
                          search: "?filter_by_brand%5B%5D=116"
                        }}
                      >
                        <div className="six6">
                          <img src={brandweek5}>
                          </img>
                        </div>
                      </Link>
                    </div>
                  </div>
                  <div className="four4">
                    <div>
                      <Link
                        to={{
                          pathname: "/" + props.countryUrl +
                            "-" + props.language +
                            "/items/products",
                          search: "?filter_by_brand%5B%5D=69"
                        }}
                      >
                        <div className="six6">
                          <img src={brandweek6}>
                          </img>
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>

              </div>
              <div className="emptyspacer"></div>

              <div className="emptyspacer-big"></div>
              <div className="emptyspacer-big"></div>

              <div className="emptyspacer"></div>
              <div className="emptyspacer"></div>
              {/* powerful vacuum cleaners */}

              <div>
                <div className="sectiontitle-container-left">
                  <h2 className="section-title lft catsec">
                    {props.positionEight[`title_` + props.language]}

                    <div className="ViewAllbtn catsecbtn">
                      <Link to={{
                        pathname: "/" + props.countryUrl +
                          "-" + props.language +
                          "/section/products/" + props.positionEight.id
                      }}>
                        {
                          props.language == "en" ?
                            en_translation["all-categories"]["view-all"]
                            :
                            ar_translation["all-categories"]["view-all"]
                        }
                        {/* {t("all-categories.view-all")} */}
                      </Link>
                    </div>
                  </h2>
                </div>
                {props.positionEight?.data?.length > 0 && (
                  <Row
                    key={1}
                    className={`${props.positionEight.title_en
                      .toLowerCase()
                      .replace(/ /g, "-")} product-list-row mx-auto`}
                  >
                    <ProductList
                      type="product"
                      home={true}
                      products={props.positionEight.data}
                      onQuickViewClicked={onQuickViewClicked}
                    />
                    <div />
                  </Row>
                )}
              </div>

              <div>
                <div className="sectiontitle-container-left">
                  <h2 className="section-title lft catsec">
                    {props.positionNine[`title_` + props.language]}

                    <div className="ViewAllbtn catsecbtn">
                      <Link to={{
                        pathname: "/" + props.countryUrl +
                          "-" + props.language +
                          "/section/products/" + props.positionNine.id
                      }}>
                        {
                          props.language == "en" ?
                            en_translation["all-categories"]["view-all"]
                            :
                            ar_translation["all-categories"]["view-all"]
                        }
                        {/* {t("all-categories.view-all")} */}
                      </Link>
                    </div>
                  </h2>
                </div>
                {props.positionNine?.data?.length > 0 && (
                  <Row
                    key={1}
                    className={`${props.positionNine.title_en
                      .toLowerCase()
                      .replace(/ /g, "-")} product-list-row mx-auto`}
                  >
                    <ProductList
                      type="product"
                      home={true}
                      products={props.positionNine.data}
                      onQuickViewClicked={onQuickViewClicked}
                    />
                    <div />
                  </Row>
                )}
              </div>

              <div>
                <div className="sectiontitle-container-left">
                  <h2 className="section-title lft catsec">
                    {props.positionTen[`title_` + props.language]}

                    <div className="ViewAllbtn catsecbtn">
                      <Link to={{
                        pathname: "/" + props.countryUrl +
                          "-" + props.language +
                          "/section/products/" + props.positionTen.id
                      }}>
                        {
                          props.language == "en" ?
                            en_translation["all-categories"]["view-all"]
                            :
                            ar_translation["all-categories"]["view-all"]
                        }
                        {/* {t("all-categories.view-all")} */}
                      </Link>
                    </div>
                  </h2>
                </div>
                {props.positionTen?.data?.length > 0 && (
                  <Row
                    key={1}
                    className={`${props.positionTen.title_en
                      .toLowerCase()
                      .replace(/ /g, "-")} product-list-row mx-auto`}
                  >
                    <ProductList
                      type="product"
                      home={true}
                      products={props.positionTen.data}
                      onQuickViewClicked={onQuickViewClicked}
                    />
                    <div />
                  </Row>
                )}
              </div>

              <div>
                <div className="sectiontitle-container-left">
                  <h2 className="section-title lft catsec">
                    {props.positionEleven[`title_` + props.language]}

                    <div className="ViewAllbtn catsecbtn">
                      <Link to={{
                        pathname: "/" + props.countryUrl +
                          "-" + props.language +
                          "/section/products/" + props.positionEleven.id
                      }}>
                        {
                          props.language == "en" ?
                            en_translation["all-categories"]["view-all"]
                            :
                            ar_translation["all-categories"]["view-all"]
                        }
                        {/* {t("all-categories.view-all")} */}
                      </Link>
                    </div>
                  </h2>
                </div>
                {props.positionEleven?.data?.length > 0 && (
                  <Row
                    key={1}
                    className={`${props.positionEleven.title_en
                      .toLowerCase()
                      .replace(/ /g, "-")} product-list-row mx-auto`}
                  >
                    <ProductList
                      type="product"
                      home={true}
                      products={props.positionEleven.data}
                      onQuickViewClicked={onQuickViewClicked}
                    />
                    <div />
                  </Row>
                )}
              </div>

              <div>
                <div className="sectiontitle-container-left">
                  <h2 className="section-title lft catsec">
                    {props.positionTwelve[`title_` + props.language]}

                    <div className="ViewAllbtn catsecbtn">
                      <Link to={{
                        pathname: "/" + props.countryUrl +
                          "-" + props.language +
                          "/section/products/" + props.positionTwelve.id
                      }}>
                        {
                          props.language == "en" ?
                            en_translation["all-categories"]["view-all"]
                            :
                            ar_translation["all-categories"]["view-all"]
                        }
                        {/* {t("all-categories.view-all")} */}
                      </Link>
                    </div>
                  </h2>
                </div>
                {props.positionTwelve?.data?.length > 0 && (
                  <Row
                    key={1}
                    className={`${props.positionTwelve.title_en
                      .toLowerCase()
                      .replace(/ /g, "-")} product-list-row mx-auto`}
                  >
                    <ProductList
                      type="product"
                      home={true}
                      products={props.positionTwelve.data}
                      onQuickViewClicked={onQuickViewClicked}
                    />
                    <div />
                  </Row>
                )}
              </div>

              <div>
                <div className="sectiontitle-container-left">
                  <h2 className="section-title lft catsec">
                    {props.positionThirteen[`title_` + props.language]}

                    <div className="ViewAllbtn catsecbtn">
                      <Link to={{
                        pathname: "/" + props.countryUrl +
                          "-" + props.language +
                          "/section/products/" + props.positionThirteen.id
                      }}>
                        {
                          props.language == "en" ?
                            en_translation["all-categories"]["view-all"]
                            :
                            ar_translation["all-categories"]["view-all"]
                        }
                        {/* {t("all-categories.view-all")} */}
                      </Link>
                    </div>
                  </h2>
                </div>
                {props.positionThirteen?.data?.length > 0 && (
                  <Row
                    key={1}
                    className={`${props.positionThirteen.title_en
                      .toLowerCase()
                      .replace(/ /g, "-")} product-list-row mx-auto`}
                  >
                    <ProductList
                      type="product"
                      home={true}
                      products={props.positionThirteen.data}
                      onQuickViewClicked={onQuickViewClicked}
                    />
                    <div />
                  </Row>
                )}
              </div>

              <Row
                className={`kid-fashion-banner-row mx-auto ${props.positionOne?.data?.length > 0 ? "" : " invisible"
                  } `}
              >
                {props.banners?.length > 0 ? (
                  <div className="kid-fashion-banner-container">
                    {props.banners[2]?.redirects_to_link ? (
                      <a href={props.banners[2]?.redirects_to_link}>
                        <img
                          src={props.banners[2]?.image || YelloColorImage}
                          alt="KidFashion"
                          width="100%"
                          height="300px"
                        />
                      </a>
                    ) : (
                      <img
                        src={props.banners[2]?.image || YelloColorImage}
                        alt="KidFashion"
                        width="100%"
                        height="300px"
                      />
                    )}
                  </div>
                ) : (
                  <div className="kid-fashion-banner-container">
                    <img
                      src={YelloColorImage}
                      alt="KidFashion"
                      width="100%"
                      height="300px"
                    />
                  </div>
                )}
              </Row>
              <div class="emptyspacer"></div>
              <div className="sectiontitle-container-left">
                <h2 className="section-title lft catsec">
                  {
                    props.language == "en" ?
                      en_translation["homePage"]["paints-heading"]
                      :
                      ar_translation["homePage"]["paints-heading"]
                  }
                  {/* {t("homePage.paints-heading")} */}
                  <div className="ViewAllbtn catsecbtn">
                    <Link
                      to={{
                        pathname: "/" + props.countryUrl +
                          "-" + props.language +
                          "/items/products",
                        search: "?filter_by_category%5B%5D=194&filter_by_category%5B%5D=271&filter_by_category%5B%5D=269&filter_by_category%5B%5D=270&filter_by_category%5B%5D=272&filter_by_category%5B%5D=274&filter_by_category%5B%5D=275&filter_by_category%5B%5D=273"
                      }}
                    >
                      {
                        props.language == "en" ?
                          en_translation["all-categories"]["view-all"]
                          :
                          ar_translation["all-categories"]["view-all"]
                      }
                      {/* {t("all-categories.view-all")} */}
                    </Link>
                  </div>
                </h2>
              </div>
              <div class="emptyspacer-big"></div>
              <div class="emptyspacer"></div>
              <div class="emptyspacer"></div>
              <div className="one1">
                <div className="two2"></div>
                <div className="three3">

                  <div className="four4">
                    <div>
                      <Link
                        to={{
                          pathname: "/" + props.countryUrl +
                            "-" + props.language +
                            "/items/products",
                          search: "?filter_by_category%5B%5D=269&filter_by_category%5B%5D=423&filter_by_category%5B%5D=425&filter_by_category%5B%5D=426&filter_by_category%5B%5D=427&filter_by_category%5B%5D=428&filter_by_category%5B%5D=430&filter_by_category%5B%5D=424&filter_by_category%5B%5D=429&filter_by_category%5B%5D=431"
                        }}
                      >
                        <div className="six6 catsecpic">
                          <img src={catbot1}>

                          </img>
                        </div>
                      </Link>
                    </div>
                  </div>
                  <div className="four4">
                    <div>
                      <Link
                        to={{
                          pathname: "/" + props.countryUrl +
                            "-" + props.language +
                            "/items/products",
                          search: "?filter_by_category%5B%5D=270&filter_by_category%5B%5D=432&filter_by_category%5B%5D=436&filter_by_category%5B%5D=437&filter_by_category%5B%5D=438"
                        }}
                      >
                        <div className="six6 catsecpic">
                          <img src={catbot2}>
                          </img>
                        </div>
                      </Link>
                    </div>
                  </div>
                  <div className="four4">
                    <div>
                      <Link
                        to={{
                          pathname: "/" + props.countryUrl +
                            "-" + props.language +
                            "/items/products",
                          search: "?filter_by_category%5B%5D=271"
                        }}
                      >
                        <div className="six6 catsecpic">
                          <img src={catbot3}>
                          </img>
                        </div>
                      </Link>
                    </div>
                  </div>
                  <div className="four4">
                    <div>
                      <Link
                        to={{
                          pathname: "/" + props.countryUrl +
                            "-" + props.language +
                            "/items/products",
                          search: "?filter_by_category%5B%5D=272&filter_by_category%5B%5D=439&filter_by_category%5B%5D=441&filter_by_category%5B%5D=442&filter_by_category%5B%5D=440&filter_by_category%5B%5D=443"
                        }}
                      >
                        <div className="six6 catsecpic">
                          <img src={catbot4}>
                          </img>
                        </div>
                      </Link>
                    </div>
                  </div>
                  <div className="four4">
                    <div>
                      <Link
                        to={{
                          pathname: "/" + props.countryUrl +
                            "-" + props.language +
                            "/items/products",
                          search: "?filter_by_category%5B%5D=273&filter_by_category%5B%5D=444&filter_by_category%5B%5D=445"
                        }}
                      >
                        <div className="six6 catsecpic">
                          <img src={catbot5}>
                          </img>
                        </div>
                      </Link>
                    </div>
                  </div>
                  <div className="four4">
                    <div>
                      <Link
                        to={{
                          pathname: "/" + props.countryUrl +
                            "-" + props.language +
                            "/items/products",
                          search: "?filter_by_category%5B%5D=274"
                        }}
                      >
                        <div className="six6 catsecpic">
                          <img src={catbot6}>
                          </img>
                        </div>
                      </Link>
                    </div>
                  </div>
                  <div className="four4">
                    <div>
                      <Link
                        to={{
                          pathname: "/" + props.countryUrl +
                            "-" + props.language +
                            "/items/products",
                          search: "?filter_by_category%5B%5D=275&filter_by_category%5B%5D=446&filter_by_category%5B%5D=447"
                        }}
                      >
                        <div className="six6 catsecpic">
                          <img src={catbot7}>
                          </img>
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>

              </div>

              <div class="emptyspacer-big"></div>
              <div class="emptyspacer-big"></div>
              <div class="emptyspacer-big"></div>
              <div className="sectiontitle-container-left">
                <h2 className="section-title lft catsec">
                  {
                    props.language == "en" ?
                      en_translation["homePage"]["fasteners"]
                      :
                      ar_translation["homePage"]["fasteners"]
                  }
                  {/* {t("homePage.fasteners")} */}
                  <div className="ViewAllbtn catsecbtn">
                    <Link
                      to={{
                        pathname: "/" + props.countryUrl +
                          "-" + props.language +
                          "/items/products",
                        search: "?filter_by_category%5B%5D=257&filter_by_category%5B%5D=381&filter_by_category%5B%5D=382&filter_by_category%5B%5D=383&filter_by_category%5B%5D=384"
                      }}
                    >
                      {
                        props.language == "en" ?
                          en_translation["all-categories"]["view-all"]
                          :
                          ar_translation["all-categories"]["view-all"]
                      }
                      {/* {t("all-categories.view-all")} */}
                    </Link>
                  </div>
                </h2>
              </div>
              <div class="emptyspacer-big"></div>
              <div class="emptyspacer"></div>
              <div class="emptyspacer"></div>
              <div className="one1">
                <div className="two2"></div>
                <div className="three3">

                  <div className="four4">
                    <div>
                      <Link
                        to={{
                          pathname: "/" + props.countryUrl +
                            "-" + props.language +
                            "/items/products",
                          search: "?filter_by_category%5B%5D=381"
                        }}
                      >
                        <div className="six6 catsecpic">
                          <img src={catbot8}>
                          </img>
                        </div>
                      </Link>
                    </div>
                  </div>
                  <div className="four4">
                    <div>
                      <Link
                        to={{
                          pathname: "/" + props.countryUrl +
                            "-" + props.language +
                            "/items/products",
                          search: "?filter_by_category%5B%5D=382"
                        }}
                      >
                        <div className="six6 catsecpic">
                          <img src={catbot9}>
                          </img>
                        </div>
                      </Link>
                    </div>
                  </div>
                  <div className="four4">
                    <div>
                      <Link
                        to={{
                          pathname: "/" + props.countryUrl +
                            "-" + props.language +
                            "/items/products",
                          search: "?filter_by_category%5B%5D=382"
                        }}
                      >
                        <div className="six6 catsecpic">
                          <img src={catbot10}>
                          </img>
                        </div>
                      </Link>
                    </div>
                  </div>
                  <div className="four4">
                    <div>
                      <Link
                        to={{
                          pathname: "/" + props.countryUrl +
                            "-" + props.language +
                            "/items/products",
                          search: "?filter_by_category%5B%5D=383"
                        }}
                      >
                        <div className="six6 catsecpic">
                          <img src={catbot11}>
                          </img>
                        </div>
                      </Link>
                    </div>
                  </div>
                  <div className="four4">
                    <div>
                      <Link
                        to={{
                          pathname: "/" + props.countryUrl +
                            "-" + props.language +
                            "/items/products",
                          search: "?filter_by_category%5B%5D=384"
                        }}
                      >
                        <div className="six6 catsecpic">
                          <img src={catbot12}>
                          </img>
                        </div>
                      </Link>
                    </div>
                  </div>
                  <div className="four4">
                    <div>
                      <Link
                        to={{
                          pathname: "/" + props.countryUrl +
                            "-" + props.language +
                            "/items/products",
                          search: "?filter_by_category%5B%5D=407"
                        }}
                      >

                        <div className="six6 catsecpic">
                          <img src={catbot13}>
                          </img>
                        </div>
                      </Link>
                    </div>
                  </div>
                  <div className="four4">
                    <div>
                      <Link
                        to={{
                          pathname: "/" + props.countryUrl +
                            "-" + props.language +
                            "/items/products",
                          search: "?filter_by_category%5B%5D=405"
                        }}
                      >
                        <div className="six6 catsecpic">
                          <img src={catbot14}>
                          </img>
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>

              </div>
              <div class="emptyspacer-big"></div>
              <div class="emptyspacer-big"></div>
              <div class="emptyspacer-big"></div>

              <div className="sectiontitle-container-left">
                <h2 className="section-title lft catsec">
                  {
                    props.language == "en" ?
                      en_translation["homePage"]["hand-tools"]
                      :
                      ar_translation["homePage"]["hand-tools"]
                  }
                  {/* {t("homePage.hand-tools")} */}
                  <div className="ViewAllbtn catsecbtn">
                    <Link
                      to={{
                        pathname: "/" + props.countryUrl +
                          "-" + props.language +
                          "/items/products",
                        search: "?filter_by_category%5B%5D=261&filter_by_category%5B%5D=405&filter_by_category%5B%5D=406&filter_by_category%5B%5D=407&filter_by_category%5B%5D=408&filter_by_category%5B%5D=409&filter_by_category%5B%5D=410&filter_by_category%5B%5D=411&filter_by_category%5B%5D=412&filter_by_category%5B%5D=413&filter_by_category%5B%5D=414&filter_by_category%5B%5D=415&filter_by_category%5B%5D=416"
                      }}
                    >
                      {
                        props.language == "en" ?
                          en_translation["all-categories"]["view-all"]
                          :
                          ar_translation["all-categories"]["view-all"]
                      }
                      {/* {t("all-categories.view-all")} */}
                    </Link>
                  </div>
                </h2>
              </div>
              <div class="emptyspacer-big"></div>
              <div class="emptyspacer"></div>
              <div class="emptyspacer"></div>
              <div className="one1">
                <div className="two2"></div>
                <div className="three3">

                  <div className="four4">
                    <div>
                      <Link
                        to={{
                          pathname: "/" + props.countryUrl +
                            "-" + props.language +
                            "/items/products",
                          search: "https://alisuq.com/uae-en/items/products?filter_by_category%5B%5D=406"
                        }}
                      >
                        <div className="six6 catsecpic">
                          <img src={catbot15}>
                          </img>
                        </div>
                      </Link>
                    </div>
                  </div>
                  <div className="four4">
                    <div>
                      <Link
                        to={{
                          pathname: "/" + props.countryUrl +
                            "-" + props.language +
                            "/items/products",
                          search: "?filter_by_category%5B%5D=408"
                        }}
                      >
                        <div className="six6 catsecpic">
                          <img src={catbot16}>
                          </img>
                        </div>
                      </Link>
                    </div>
                  </div>
                  <div className="four4">
                    <div>
                      <Link
                        to={{
                          pathname: "/" + props.countryUrl +
                            "-" + props.language +
                            "/items/products",
                          search: "?filter_by_category%5B%5D=409"
                        }}
                      >
                        <div className="six6 catsecpic">
                          <img src={catbot17}>
                          </img>
                        </div>
                      </Link>
                    </div>
                  </div>
                  <div className="four4">
                    <div>
                      <Link
                        to={{
                          pathname: "/" + props.countryUrl +
                            "-" + props.language +
                            "/items/products",
                          search: "?filter_by_category%5B%5D=410"
                        }}
                      >
                        <div className="six6 catsecpic">
                          <img src={catbot18}>
                          </img>
                        </div>
                      </Link>
                    </div>
                  </div>
                  <div className="four4">
                    <div>
                      <Link
                        to={{
                          pathname: "/" + props.countryUrl +
                            "-" + props.language +
                            "/items/products",
                          search: "?filter_by_category%5B%5D=369"
                        }}
                      >
                        <div className="six6 catsecpic">
                          <img src={catbot19}>
                          </img>
                        </div>
                      </Link>
                    </div>
                  </div>
                  <div className="four4">
                    <div>
                      <Link
                        to={{
                          pathname: "/" + props.countryUrl +
                            "-" + props.language +
                            "/items/products",
                          search: "?filter_by_category%5B%5D=412"
                        }}
                      >
                        <div className="six6 catsecpic">
                          <img src={catbot20}>
                          </img>
                        </div>
                      </Link>
                    </div>
                  </div>
                  <div className="four4">
                    <div>
                      <Link
                        to={{
                          pathname: "/" + props.countryUrl +
                            "-" + props.language +
                            "/items/products",
                          search: "?filter_by_category%5B%5D=366"
                        }}
                      >
                        <div className="six6 catsecpic">
                          <img src={catbot21}>
                          </img>
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>

              </div>
              <div class="emptyspacer-big"></div>
              <div class="emptyspacer-big"></div>
              <div class="emptyspacer-big"></div>

              <Row
                className={`kid-fashion-banner-row mx-auto last-bannernew ${props.positionOne?.data?.length > 0 ? "" : " invisible"
                  } `}
              >
                {props.banners?.length > 0 ? (
                  <div className="kid-fashion-banner-container">
                    {props.banners[4]?.redirects_to_link ? (
                      <a href={props.banners[4]?.redirects_to_link}>
                        <img
                          src={props.banners[4]?.image || YelloColorImage}
                          alt="KidFashion"
                          width="100%"
                          height="300px"
                        />
                      </a>
                    ) : (
                      <img
                        src={props.banners[4]?.image || YelloColorImage}
                        alt="KidFashion"
                        width="100%"
                        height="300px"
                      />
                    )}
                  </div>
                ) : (
                  <div className="kid-fashion-banner-container">
                    <img
                      src={YelloColorImage}
                      alt="KidFashion"
                      width="100%"
                      height="300px"
                    />
                  </div>
                )}
              </Row>

              {/* {props.sections?.map(
                (section, index) =>
                  section.data.length > 0 && (
                    <Row
                      key={index + 5}
                      className={`${section.title_en
                        .toLowerCase()
                        .replace(/ /g, "-")} product-list-row mx-auto`}
                    >
                      <div className="header">
                        {section[`title_${props.language}`]}
                      </div>
                      <ProductList
                        type="product"
                        home={true}
                        products={section.data}
                      />
                      <div />
                    </Row>
                  )
              )} */}


              <div className="brands-section">
                <div className="sectiontitle-container-left">

                  <h2 className="section-title lft catsec">
                    {
                      props.language == "en" ?
                        en_translation["homePage"]["alisuq"]
                        :
                        ar_translation["homePage"]["alisuq"]
                    }
                    <div className="ViewAllbtn catsecbtn">
                      <Link
                        to={{
                          pathname: "/" + props.countryUrl +
                            "-" + props.language +
                            "/brands-az",
                        }}
                      >
                        {
                          props.language == "en" ?
                            en_translation["all-categories"]["view-all"]
                            :
                            ar_translation["all-categories"]["view-all"]
                        }
                        {/* {t("all-categories.view-all")} */}
                      </Link>
                    </div>
                  </h2>
                </div>
                <div className="emptyspacer"></div>

                <Swiper
                  spaceBetween={5}
                  slidesPerView={7}
                  navigation={true}
                >
                  <SwiperSlide>
                    <Link
                      to={{
                        pathname: "/" + props.countryUrl +
                          "-" + props.language +
                          "/items/products",
                        search: "?filter_by_brand%5B%5D=44"
                      }}
                    >
                      <img
                        src="https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/2gbCVfRpwUsgffJzgihfmH.png"
                        alt="dummyoffers"
                        height="100"
                      />
                    </Link>
                  </SwiperSlide>
                  <SwiperSlide>
                    <Link
                      to={{
                        pathname: "/" + props.countryUrl +
                          "-" + props.language +
                          "/items/products",
                        search: "?filter_by_brand%5B%5D=125"
                      }}
                    >
                      <img
                        src="https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/dxZDu1N13rDU9PNWi1zg57.png"
                        alt="dummyoffers"
                        height="100"
                      />
                    </Link>
                  </SwiperSlide>
                  <SwiperSlide>
                    <Link
                      to={{
                        pathname: "/" + props.countryUrl +
                          "-" + props.language +
                          "/items/products",
                        search: "?filter_by_brand%5B%5D=63"
                      }}
                    >
                      <img
                        src={topbrand3}
                        alt="dummyoffers"
                        height="100"
                      />
                    </Link>
                  </SwiperSlide>
                  <SwiperSlide>
                    <Link
                      to={{
                        pathname: "/" + props.countryUrl +
                          "-" + props.language +
                          "/items/products",
                        search: "?filter_by_brand%5B%5D=86"
                      }}
                    >
                      <img
                        src="https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/qjvsNBkNNiWX9jroUg6jZN.png"
                        alt="dummyoffers"
                        height="100"
                      />
                    </Link>
                  </SwiperSlide>
                  <SwiperSlide>
                    <Link
                      to={{
                        pathname: "/" + props.countryUrl +
                          "-" + props.language +
                          "/items/products",
                        search: "?filter_by_brand%5B%5D=248"
                      }}
                    >
                      <img
                        src="https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/gdkTTmb629vbMuZXBfw7nY.jpeg"
                        alt="dummyoffers"
                        height="100"
                      />
                    </Link>
                  </SwiperSlide>
                  <SwiperSlide>
                    <Link
                      to={{
                        pathname: "/" + props.countryUrl +
                          "-" + props.language +
                          "/items/products",
                        search: "?filter_by_brand%5B%5D=42"
                      }}
                    >
                      <img
                        src="https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/hSZ6J8vxQ8pENansr4opLk.png"
                        alt="dummyoffers"
                        height="100"
                      />
                    </Link>
                  </SwiperSlide>
                  <SwiperSlide>
                    <Link
                      to={{
                        pathname: "/" + props.countryUrl +
                          "-" + props.language +
                          "/items/products",
                        search: "?filter_by_brand%5B%5D=62"
                      }}
                    >
                      <img
                        src="https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/9TaBpJzvVLkxxZjmbUsVDn.png"
                        alt="dummyoffers"
                        height="100"
                      />
                    </Link>
                  </SwiperSlide>
                  <SwiperSlide>
                    <Link
                      to={{
                        pathname: "/" + props.countryUrl +
                          "-" + props.language +
                          "/items/products",
                        search: "?filter_by_brand%5B%5D=43"
                      }}
                    >
                      <img
                        src="https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/iReSSYpWhmJcPBKxns3BgJ.png"
                        alt="dummyoffers"
                        height="100"
                      />
                    </Link>
                  </SwiperSlide>
                </Swiper>
              </div>
              <div className="emptyspacer"></div>
              <div className="category-content">
                <h1>
                  Alisuq - Your One-Stop Shop For All Online Shopping In UAE
                </h1>
                <p>
                  Following the legacy of authenticity, durability, and practicality, Alisuq brings you an exclusive platform for online shopping in UAE. We are dedicated to incorporating convenience into your daily projects. From repair, maintenance, cooking, cleaning, and general home-care tasks, we bring assistance in every task.
                </p>

                <p>
                  Alisuq categories are created with the utmost consideration and care. Whether you’re looking for
                  <Link to={{
                    pathname: "/" + props.countryUrl + "-" + props.language + "/items/products",
                    search: "filter_by_category%5B%5D=190&filter_by_category%5B%5D=255&filter_by_category%5B%5D=256&filter_by_category%5B%5D=252&filter_by_category%5B%5D=253&filter_by_category%5B%5D=254"
                  }} > Kitchenware </Link>,
                  <Link to={{
                    pathname: "/" + props.countryUrl + "-" + props.language + "/items/products",
                    search: "filter_by_category%5B%5D=191&filter_by_category%5B%5D=259&filter_by_category%5B%5D=262&filter_by_category%5B%5D=257&filter_by_category%5B%5D=260&filter_by_category%5B%5D=261&filter_by_category%5B%5D=258"
                  }}> hardware & tools </Link>,
                  <Link to={{
                    pathname: "/" + props.countryUrl + "-" + props.language + "/items/products",
                    search: "filter_by_category%5B%5D=182&filter_by_category%5B%5D=195&filter_by_category%5B%5D=196&filter_by_category%5B%5D=197&filter_by_category%5B%5D=198&filter_by_category%5B%5D=203&filter_by_category%5B%5D=199&filter_by_category%5B%5D=200&filter_by_category%5B%5D=201&filter_by_category%5B%5D=202&filter_by_category%5B%5D=204"
                  }}> building & construction materials </Link> , or
                  <Link to={{
                    pathname: "/" + props.countryUrl + "-" + props.language + "/items/products",
                    search: "filter_by_category%5B%5D=183&filter_by_category%5B%5D=205&filter_by_category%5B%5D=290&filter_by_category%5B%5D=289&filter_by_category%5B%5D=206&filter_by_category%5B%5D=291&filter_by_category%5B%5D=207&filter_by_category%5B%5D=210&filter_by_category%5B%5D=294&filter_by_category%5B%5D=295&filter_by_category%5B%5D=296&filter_by_category%5B%5D=297&filter_by_category%5B%5D=298&filter_by_category%5B%5D=299&filter_by_category%5B%5D=300&filter_by_category%5B%5D=301&filter_by_category%5B%5D=302&filter_by_category%5B%5D=303&filter_by_category%5B%5D=304&filter_by_category%5B%5D=305&filter_by_category%5B%5D=211&filter_by_category%5B%5D=306&filter_by_category%5B%5D=307&filter_by_category%5B%5D=308&filter_by_category%5B%5D=309&filter_by_category%5B%5D=212&filter_by_category%5B%5D=310&filter_by_category%5B%5D=449&filter_by_category%5B%5D=448&filter_by_category%5B%5D=450&filter_by_category%5B%5D=311&filter_by_category%5B%5D=452&filter_by_category%5B%5D=453&filter_by_category%5B%5D=451&filter_by_category%5B%5D=312&filter_by_category%5B%5D=313&filter_by_category%5B%5D=214&filter_by_category%5B%5D=208&filter_by_category%5B%5D=209&filter_by_category%5B%5D=292&filter_by_category%5B%5D=293&filter_by_category%5B%5D=213&filter_by_category%5B%5D=314&filter_by_category%5B%5D=315&filter_by_category%5B%5D=316&filter_by_category%5B%5D=317"
                  }}> home appliances </Link>,
                  we have a large array of options in our UAE store. Revamp your home with the best products from popular UAE brands. As a platform that gives prime importance to customer expectations, we offer the finest products at the most market-competitive rates. Save big on your major and minor purchases with Alisuq. Choose from a wide selection of homecare, indoor designing, and
                  <Link to={{
                    pathname: "/" + props.countryUrl + "-" + props.language + "/items/products",
                    search: "filter_by_category%5B%5D=192&filter_by_category%5B%5D=263&filter_by_category%5B%5D=264&filter_by_category%5B%5D=265"
                  }} > outdoor recreation </Link>
                  products to upgrade your lifestyle.
                  <br></br><br></br>
                  Alisuq team works day and night to bring customers the best shopping experience. We aspire to provide a swift purchasing and shipping process, so all your orders are delivered on time. Our team offers quick and reliable services to make online shopping hassle-free. With quality products that outperform in challenging fields, you can rely on us to deliver performance.
                  <br></br><br></br>
                  Practice secure online home shopping in UAE right from your couch or living room. Avail amazing offers and discounts on leading brands in the construction, tools, gardening, crafts, and electronics industry. Get your orders delivered across Dubai, Abu Dhabi, and other cities of the UAE in no time.
                </p>

                {readMore && extraContent}

                <a className="read-more-link" onClick={() => { setReadMore(!readMore) }}><span>{linkName}</span></a>



              </div>
            </div>

            <div className="emptyspacer-big"></div>

            <div>
              <OffersModal
                show={offersModalShow}
                onHide={() => setOffersModalShow(false)}
              />
            </div>

            <WebsiteFooter />
          </>
        )}
      </div>
    </Fragment>
  );
};

function OffersModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="offers-modal"
    >
      <Modal.Body>
        <Link to={props.countryUrl + "-" + props.language + "/uae-en/electrical-accessories-sale"}>
          <div className="text-center">
            <img src={OffersBanner} className="img img-fluid" style={{ maxHeight: "500px", maxWidth: "500px", margin: "auto" }} />
          </div>
        </Link>
      </Modal.Body>
    </Modal>
  )
}

const mapStateToProps = (state) => {
  return {
    banners: state.home.secondaryBanners,
    countryId: state.language.countryId,
    country: state.language.country,
    recommendedForYou: state.home.recommendedForYou,
    diyProducts: state.home.diyProducts,
    paintersTouch: state.home.paintersTouch,
    krudKutter: state.home.krudKutter,
    sections: state.home.sections,
    positionOne: state.home.positionOne,
    positionTwo: state.home.positionTwo,
    positionThree: state.home.positionThree,
    positionFour: state.home.positionFour,
    positionFive: state.home.positionFive,
    positionSix: state.home.positionSix,
    positionSeven: state.home.positionSeven,
    positionEight: state.home.positionEight,
    positionNine: state.home.positionNine,
    positionTen: state.home.positionTen,
    positionEleven: state.home.positionEleven,
    positionTwelve: state.home.positionTwelve,
    positionThirteen: state.home.positionThirteen,
    language: state.language.language,
    countryUrl: state.language.countryUrl,
    redirect: state.notFound.redirect,

    loading: state.layout.loading,
  };
};

const mapDispatchToProps = {
  set_home_categories,
  set_country,
  set_cart_items,
  set_redirect_home,
  set_loading,
  set_user,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductHomePage);
