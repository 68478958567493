import React, { Fragment, useState } from "react";
import "./../../../../assets/styles/customer/account/addresses/address.scss";
import { BsExclamationTriangleFill } from "react-icons/bs";
import { TiTick } from "react-icons/ti";
import { GrMap } from "react-icons/gr";
import { connect } from "react-redux";
import { apiJson } from "../../../../Api";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ClipLoader from "react-spinners/ClipLoader";
import { set_address } from "../../../../redux/actions/CustomerAddress";
import { MdEdit } from "react-icons/md";
import { Link, Redirect } from "react-router-dom";

const Addresses = (props) => {
  const [loading, setLoading] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const { t } = useTranslation();

  let message = "";

  const showError = () => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const setDefault = () => {
    setLoading(true);
    apiJson.select.setDefault(props.id).then((response) => {
      if (response.success) {
        props.set_address({
          address: props.customer_address.map((item) =>
            item.id === response.data.id
              ? { ...item, default: true }
              : item.default
                ? { ...item, default: false }
                : item
          ),
        });
        setLoading(false);
      } else if (!response.status) {
        setLoading(false);
        message = t("errors.wrong");
        showError();
      } else {
        setLoading(false);
        if (response.status >= 400 && response.status < 500) {
          message = response.message
            ? response.message
            : response.errors
              ? response.errors
              : t("errors.wrong");
          showError();
        } else {
          message = response.message
            ? response.message !== ""
              ? response.message
              : t("errors.wrong")
            : t("errors.wrong");
          showError();
        }
      }
    });
  };

  const deleteAddress = () => {
    setDeleting(true);
    apiJson.select.deleteAddress(props.id).then((response) => {
      if (response.success) {
        props.set_address({
          address: props.customer_address.filter(
            (item) => item.id !== props.id
          ),
        });
        setDeleting(false);
      } else if (!response.status) {
        setDeleting(false);
        message = t("errors.wrong");
        showError();
      } else {
        setDeleting(false);
        if (response.status >= 400 && response.status < 500) {
          message = response.message
            ? response.message
            : response.errors
              ? response.errors
              : t("errors.wrong");
          showError();
        } else {
          message = response.message
            ? response.message !== ""
              ? response.message
              : t("errors.wrong")
            : t("errors.wrong");
          showError();
        }
      }
    });
  };

  return (
    <Fragment>
      <div className="address-desktop w-100">
        <div className="col-12 d-flex justify-content-between rectangle-head">
          <div>{props.tag}</div>
          <Link
            to={{
              pathname:
                "/" + props.countryUrl + "-" + props.language + "/address",
              state: {
                data: {
                  id: props.id,
                  country: props.country,
                  state: props.state,
                  latitude: props.lat,
                  longtitude: props.lng,
                  address1: props.address,
                  firstName: props.first,
                  lastName: props.last,
                  number: props.phone,
                  label: props.label,
                  address2: props.address2,
                },
              },
            }}
          >
            <span className="pointer">
              <MdEdit size={18} color="#404553" />
            </span>
          </Link>
        </div>
        <div className="row w-100 mx-0 rectangle">
          <p className="w-100">
            {props.label}{" "}
            <span className="map-icon">
              <GrMap color="plain" />
            </span>
          </p>

          <div className="row w-100 mx-0">
            <div className="col-md-3 col-lg-3 px-0">
              <div className="head-text">{t("address-dektop.name")}</div>
              <div className="info-text">{props.name}</div>
            </div>

            <div className="col-md-3 col-lg-3 col-xl-3 pl-3 pr-0">
              <div className="head-text">{t("address-dektop.address")}</div>
              <div className="info-text">{props.address}</div>
            </div>

            <div className="col-md-4 col-lg-3 col-xl-3 pl-3 pr-0">
              <div className="head-text">
                <span> {t("address-dektop.phone-number")}</span>
              </div>
              <div className="info-text">
                <span>
                  {props.phone}
                  {props.verified ? (
                    <TiTick className="ml-1" style={{ color: "green" }} />
                  ) : (
                    <BsExclamationTriangleFill
                      className="ml-1"
                      style={{ color: "#bb371a" }}
                    />
                  )}
                </span>
              </div>
            </div>
            <div className="col-md-2 col-lg-2 col-xl-2  pl-3 pr-0">
              <span className="btn-cont">
                <span
                  onClick={!props.default ? setDefault : undefined}
                  className={`set-default pointer ${props.default
                      ? "invisible"
                      : "d-flex justify-content-center align-items-center"
                    }`}
                  style={{ height: "26px", lineHeight: "normal" }}
                >
                  {loading ? (
                    <div className="d-flex justify-content-center align-items-center w-100 h-100">
                      <ClipLoader color="#fff" loading size={14} />
                    </div>
                  ) : (
                    t("address-dektop.set-default")
                  )}
                </span>
                <span
                  onClick={deleteAddress}
                  className="delete mt-2 pointer mb-0 d-flex justify-content-center align-items-center"
                  style={{ height: "26px", lineHeight: "normal" }}
                >
                  {deleting ? (
                    <div className="d-flex justify-content-center align-items-center w-100 h-100">
                      <ClipLoader color="#000" loading size={14} />
                    </div>
                  ) : (
                    t("address-dektop.delete")
                  )}
                </span>
              </span>
            </div>
          </div>
        </div>
      </div>
      {redirect && (
        <Redirect
          to={{
            pathname:
              "/" + props.countryUrl + "-" + props.language + "/address",
            state: {
              data: {
                id: props.id,
                country: props.country,
                state: props.state,
                latitude: props.lat,
                longtitude: props.lng,
                address1: props.address,
                firstName: props.first,
                lastName: props.last,
                number: props.phone,
                label: props.label,
                address2: props.address2,
              },
            },
          }}
        />
      )}
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    customer_address: state.customerAddress.address,
    countryUrl: state.language.countryUrl,
    language: state.language.language,
  };
};
const mapDispatchToProps = {
  set_address,
};
export default connect(mapStateToProps, mapDispatchToProps)(Addresses);
