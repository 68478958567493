import React, { useEffect, useState } from "react";
import { RiSearchLine } from "react-icons/ri";
import { set_loading } from "../../../redux/actions/LayoutAction";
import logo from "../../../assets/images/logo.png";
import { connect } from "react-redux";
import { set_vendor_data } from "../../../redux/actions/VendorShopAction";
import { toast } from "react-toastify";
import { Link, Redirect, useParams } from "react-router-dom";
import MobileLayoutRenderer from "../../../pages/mobile/layout/MobileLayoutRenderer";
import { useTranslation } from "react-i18next";
import { set_country } from "../../../redux/actions/LayoutAction";
import { set_cart_items } from "../../../redux/actions/CartAction";
import { set_redirect_home } from "../../../redux/actions/NotFoundAction";
import "../../../assets/styles/component/mobile/Home/HomeMobile.scss";
import "react-toastify/dist/ReactToastify.css";
import $ from "jquery";
import { apiJson } from "../../../Api";
import pagenamebanner from "../../../assets/images/footer-pages-banners/brand-fvavourites.jpg";
import ProductCard from "../Home/ProductCardMobile";
import rustbanner from "../../../assets/images/footer-page-images/Rustoleum-Banner.jpg";
import karcherbanner from "../../../assets/images/footer-page-images/Karcher-Banner.jpg";
import krudbanner from "../../../assets/images/footer-page-images/Krud-Kutter-Banner.jpg";
import { getMobileImage } from "../../../utils/utils";

function BrandsFavouritesComp(props) {
  const [productsInSlider1, setProductsInSlider1] = useState([]);
  const [productsInSlider2, setProductsInSlider2] = useState([]);
  const [productsInSlider3, setProductsInSlider3] = useState([]);

  let message = "";
  // eslint-disable-next-line
  const { id } = useParams();
  // eslint-disable-next-line
  const { t, i18n } = useTranslation();
  const [redirect, setRedirect] = useState({ value: false });

  useEffect(() => {
    props.set_loading(true);
    window.document.title = "Brand Favourites";
    $("html, body").animate({ scrollTop: 0 }, "fast");
    apiJson.homepage.getData(props.countryId).then(async (response) => {
      props.set_loading(false);
      if (response.success) {
        // eslint-disable-next-line
        response.data.sections.map((data) => {
          // eslint-disable-next-line
          if (data.title_en == "Brand Favourites 1") {
            // eslint-disable-next-line
            setProductsInSlider1(data.products);

            // eslint-disable-next-line
          } else if (data.title_en == "Brand Favourites 2") {
            setProductsInSlider2(data.products);
            // eslint-disable-next-line
          } else if (data.title_en == "Brand Favourites 3") {
            setProductsInSlider3(data.products);
          }
          props.set_loading(false);
        });
      } else if (!response.status) {
        // eslint-disable-next-line
        message = response.message
          ? response.message !== ""
            ? response.message
            : t("errors.wrong")
          : t("errors.wrong");
        showError();
      } else {
        if (response.status >= 400 && response.status < 500) {
          message = response.message
            ? response.message
            : response.errors
            ? response.errors
            : t("errors.wrong");
          showError();
        } else {
          message = t("errors.wrong");
          showError();
        }
      }
    });
  }, []);

  const showError = () => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  // eslint-disable-next-line
  function getMobileOperatingSystem() {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
      return "Windows Phone";
    }

    if (/android/i.test(userAgent)) {
      return "Android";
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return "iOS";
    }

    return "unknown";
  }

  return (
    <MobileLayoutRenderer
      NavbarTop={false}
      BackBtn={false}
      CancelBtn={false}
      NavbarBottom={true}
    >
      <div className="home-mob">
        <div className="header">
          <div className="logo">
            <img src={logo} width="100%" height="100%" alt="" />
          </div>
          <div className="search">
            <RiSearchLine
              onClick={() =>
                setRedirect({ value: true, path: "all-categories" })
              }
            />
          </div>
        </div>

        <div className="body" style={{ background: "#F5F5F5" }}>
          <div className="pagesbanner">
            <img
              src={pagenamebanner}
              alt="category"
              style={{
                width: "100%",
                height: "100%",
                maxHeight: "400px",
              }}
            />
          </div>

          <div className="content-wrapper all-page-container">
            <div className="header-products-slider">Rust-oleum Products</div>
            <div
              className="product-cont mt-3"
              style={{ marginTop: "0px !important" }}
            >
              {productsInSlider1.map((data) => (
                <Link
                  style={{ textDecoration: "none" }}
                  to={{
                    pathname:
                      "/" +
                      props.countryUrl +
                      "-" +
                      props.language +
                      `/${data.category_title_en
                        .toLowerCase()
                        .replace(/ /g, "-")}/${data.slug}`,
                    state: {
                      id: data.id,
                      type: data.variants ? "products" : "services",
                    },
                  }}
                  key={data.id + Math.random()}
                >
                  <div
                    className="image-cont"
                    key={data.id + Math.random() + "prod"}
                  >
                    <ProductCard
                      key={data.id + Math.random()}
                      img={getMobileImage(
                        data.variants
                          ? data.variants[0].featured_image_resized
                          : data.featured_image_resized,
                        data.variants
                          ? data.variants[0].featured_image
                          : data.featured_image
                      )}
                      storeLogo={data.store_logo}
                      wishlisted={
                        data.variants
                          ? data.variants[0].wishlisted
                          : data.wishlisted
                      }
                      home={true}
                      type={data.variants ? "products" : "services"}
                      id={data.variants ? data.variants[0].id : data.id}
                      rating={data.average_rating}
                      reviews={data.reviews_count}
                      name={data[`name_${props.language}`]}
                      data={data.variants ? data.variants[0] : data}
                      coupon={data.coupon}
                      is_inquiry={data.is_inquiry}
                    />
                  </div>
                </Link>
              ))}
            </div>

            <div className="content-wrapper all-page-container">
              <div
                style={{
                  marginTop: "50px",
                }}
              >
                {" "}
              </div>
              <div className="pagesbanner">
                <img
                  src={rustbanner}
                  alt="category"
                  style={{
                    width: "100%",
                    height: "100%",
                    maxHeight: "400px",
                  }}
                />
              </div>
            </div>
            <div className="header-products-slider">Karcher Products</div>
            <div
              className="product-cont mt-3"
              style={{ marginTop: "0px !important" }}
            >
              {productsInSlider2.map((data) => (
                <Link
                  style={{ textDecoration: "none" }}
                  to={{
                    pathname:
                      "/" +
                      props.countryUrl +
                      "-" +
                      props.language +
                      `/${data.category_title_en
                        .toLowerCase()
                        .replace(/ /g, "-")}/${data.slug}`,
                    state: {
                      id: data.id,
                      type: data.variants ? "products" : "services",
                    },
                  }}
                  key={data.id + Math.random()}
                >
                  <div
                    className="image-cont"
                    key={data.id + Math.random() + "prod"}
                  >
                    <ProductCard
                      key={data.id + Math.random()}
                      img={getMobileImage(
                        data.variants
                          ? data.variants[0].featured_image_resized
                          : data.featured_image_resized,
                        data.variants
                          ? data.variants[0].featured_image
                          : data.featured_image
                      )}
                      storeLogo={data.store_logo}
                      wishlisted={
                        data.variants
                          ? data.variants[0].wishlisted
                          : data.wishlisted
                      }
                      home={true}
                      type={data.variants ? "products" : "services"}
                      id={data.variants ? data.variants[0].id : data.id}
                      rating={data.average_rating}
                      reviews={data.reviews_count}
                      name={data[`name_${props.language}`]}
                      data={data.variants ? data.variants[0] : data}
                      coupon={data.coupon}
                      is_inquiry={data.is_inquiry}
                    />
                  </div>
                </Link>
              ))}
            </div>
            <div class="content-wrapper all-page-container">
              <div className="pagesbanner">
                <img
                  src={karcherbanner}
                  alt="category"
                  style={{
                    width: "100%",
                    height: "100%",
                    maxHeight: "400px",
                  }}
                />
              </div>
            </div>
            <div className="header-products-slider">Krud Kutter Products</div>
            <div
              className="product-cont mt-3"
              style={{ marginTop: "0px !important" }}
            >
              {productsInSlider3.map((data) => (
                <Link
                  style={{ textDecoration: "none" }}
                  to={{
                    pathname:
                      "/" +
                      props.countryUrl +
                      "-" +
                      props.language +
                      `/${data.category_title_en
                        .toLowerCase()
                        .replace(/ /g, "-")}/${data.slug}`,
                    state: {
                      id: data.id,
                      type: data.variants ? "products" : "services",
                    },
                  }}
                  key={data.id + Math.random()}
                >
                  <div
                    className="image-cont"
                    key={data.id + Math.random() + "prod"}
                  >
                    <ProductCard
                      key={data.id + Math.random()}
                      img={getMobileImage(
                        data.variants
                          ? data.variants[0].featured_image_resized
                          : data.featured_image_resized,
                        data.variants
                          ? data.variants[0].featured_image
                          : data.featured_image
                      )}
                      storeLogo={data.store_logo}
                      wishlisted={
                        data.variants
                          ? data.variants[0].wishlisted
                          : data.wishlisted
                      }
                      home={true}
                      type={data.variants ? "products" : "services"}
                      id={data.variants ? data.variants[0].id : data.id}
                      rating={data.average_rating}
                      reviews={data.reviews_count}
                      name={data[`name_${props.language}`]}
                      data={data.variants ? data.variants[0] : data}
                      coupon={data.coupon}
                      is_inquiry={data.is_inquiry}
                    />
                  </div>
                </Link>
              ))}
            </div>
            <div class="content-wrapper all-page-container">
              <div className="pagesbanner">
                <img
                  src={krudbanner}
                  alt="category"
                  style={{
                    width: "100%",
                    height: "100%",
                    maxHeight: "400px",
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        {redirect.value && (
          <Redirect
            push
            to={{ pathname: redirect.path, state: redirect.state }}
          />
        )}
      </div>
    </MobileLayoutRenderer>
  );
}

const mapStateToProps = (state) => {
  return {
    // categories: state.home.categories,
    // products: state.vendor.products,
    // services: state.vendor.services,
    // sections: state.home.sections,
    // positionOne: state.home.positionOne,
    // positionTwo: state.home.positionTwo,
    // positionThree: state.home.positionThree,
    // positionFour: state.home.positionFour,
    // positionFive: state.home.positionFive,
    load: state.layout.loading,
    countryId: state.language.countryId,
    language: state.language.language,
    country: state.language.country,
    countryUrl: state.language.countryUrl,
    redirect: state.notFound.redirect,
  };
};

const mapDispatchToProps = {
  set_vendor_data,
  // set_home_categories,
  set_country,
  set_cart_items,
  set_redirect_home,
  set_loading,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BrandsFavouritesComp);
