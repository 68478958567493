import { type } from "../../types/types";
const initialState = {
  isSignedIn: false,
  user: {},
  guestSignin: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case type.SET_USER:
      return {
        ...state,
        ...action.user,
      };
    default:
      return state;
  }
}
