import React, { Fragment, useEffect } from "react";
import Row from "react-bootstrap/Row";

import "../../../assets/styles/customer/account/general.scss";
import Sidebar from "../../../components/customer/account/Sidebar";
import WebsiteHeader from "../../../components/header/WebsiteHeader";
import Disputes from "../disputes/Disputes";

const DisputesPage = (props) => {
  useEffect(() => {
    document.title = "Disputes";
  }, []);
  return (
    <Fragment>
      <Row className="page-container no-gutters g-0 w-100">
        <div className="w-100">
          <WebsiteHeader />

          <div className="content-container all-page-container d-flex justify-content-center mx-auto">
            <div className="left-pane">
              <Sidebar />
            </div>
            <div className="right-pane">
              <Disputes />
            </div>
          </div>
        </div>
      </Row>
    </Fragment>
  );
};

export default DisputesPage;
