import React, { Fragment, useState } from "react";
import { GrFormClose } from "react-icons/gr";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ClipLoader from "react-spinners/ClipLoader";

import "./forgetmodal.scss";
import { apiJson } from "../../Api";
import { useTranslation } from "react-i18next";

const ForgetModal = (props) => {
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const [changing, setChanging] = useState(false);

  let message = "";
  const showError = () => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const showSuccess = () => {
    toast.success(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const changePass = () => {
    setChanging(true);
    const form = new FormData();
    form.append("email", email);

    apiJson.auth.resetPassEmail(form).then((response) => {
      setChanging(false);
      if (response.success) {
        message = t("forget-pw-mob.success-message");
        showSuccess();
        props.onClose();
      } else if (!response.status) {
        message = response.message
          ? response.message !== ""
            ? response.message
            : t("errors.wrong")
          : t("errors.wrong");
        showError();
      } else {
        if (response.status >= 400 && response.status < 500) {
          message = response.message
            ? response.message
            : response.errors
            ? response.errors
            : t("errors.wrong");
          showError();
        } else {
          message = response.message
            ? response.message !== ""
              ? response.message
              : t("errors.wrong")
            : t("errors.wrong");
          showError();
        }
      }
    });
  };

  return (
    <Fragment>
      <div className="forget-modal">
        <div className="header-row w-100 d-flex justify-content-between align-items-center">
          <div className="close-col pointer" onClick={props.onClose}>
            <GrFormClose />
          </div>
        </div>
        <div className="boday-row w-100">
          <div className="title-row">{props.title}</div>
          <div className="guide-text-row pt-2">
            {t("forget-password.enter-email")}
          </div>
          <div className="input-row">
            <div className="label">{t("forget-password.email")}</div>
            <input
              type="email"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="email-input"
            />
          </div>
        </div>
        <div className="button-row w-100 d-flex align-items-center justify-content-center">
          <button
            className="submit-button"
            type="submit"
            onClick={changePass}
            disabled={changing}
            style={{ height: "33px" }}
          >
            {changing ? (
              <div className="d-flex justify-content-center align-items-center w-100">
                <ClipLoader color="#000" loading size={25} />
              </div>
            ) : (
              t("forget-password.submit-btn")
            )}
          </button>
        </div>
      </div>
    </Fragment>
  );
};

export default ForgetModal;
