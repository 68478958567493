import React, { Fragment, useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import "bootstrap/dist/css/bootstrap.css";
import "../../assets/styles/pages/producthomepage.scss";
import "react-toastify/dist/ReactToastify.css";
import "../../assets/styles/component/middle_section.scss";
import dummyoffer from "../../assets/images/homepage/dummyoffer.png";
import Tabs from 'react-bootstrap/Tabs';
import Sonnet from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tabs';
import { Row, Container } from "react-bootstrap";
import { apiJson } from "../../Api";
import TabProductList from "../product/TabProductList";
import TabProductListMob from "../product/TabProductListMob";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

const en_translation = require("../../assets/locales/en/translation.json");
const ar_translation = require("../../assets/locales/ar/translation.json");

const MiddleSectionMob = (props) => {
    const [tabs, setTabs] = useState([]);
    const { t } = useTranslation();

    // useEffect(() => {
    //     apiJson.homepage.getHomeOffersSections(1)
    //         .then(response => {
    //             if (response.success) {
    //                 setSections(response.data.sections);
    //             }
    //         })
    // }, []);

    useEffect(() => {
        apiJson.homepage.getHomeTabs(props.country)
            .then(response => {
                if (response.success) {
                    console.log(response.data.tabs);
                    setTabs(response.data.tabs);
                }
            })
    }, []);
    return (

        <div className="middle-section">
            <div className="sectiontitle-container">
                <p className="section-subtitle">
                    {
                        props.language == "en" ?
                            en_translation["homePage"]["core-products"]
                            :
                            ar_translation["homePage"]["core-products"]
                    }
                    {/* {t("homePage.core-products")} */}
                </p>
                <h2 className="section-title">
                    {
                        props.language == "en" ?
                            en_translation["homePage"]["highlights"]
                            :
                            ar_translation["homePage"]["highlights"]
                    }
                    {/* {t("homePage.highlights")} */}
                </h2>
            </div>
            <Tabs defaultActiveKey={tabs[0]?.title_en} id="uncontrolled-tab-example" className="mb-3 justify-content-center">
                {
                    tabs.map(tab => {
                        return (
                            <Tab eventKey={tab.title_en} title={tab[`title_` + props.language]}>
                                <Container fluid className="p-0">
                                    <div className="product-cont mt-3">
                                        <TabProductListMob
                                            type="product"
                                            home={true}
                                            products={tab.tab_products}
                                        />
                                        <div />

                                    </div>
                                    <div className="emptyspacermob"></div>
                                    <div className="emptyspacermob"></div>
                                    <div className="ViewAllbtn mt-2">
                                        <a href={tab.view_all_link}>
                                            {
                                                props.language == "en" ?
                                                    en_translation["all-categories"]["view-all"]
                                                    :
                                                    ar_translation["all-categories"]["view-all"]
                                            }
                                            {/* {t("all-categories.view-all")} */}
                                        </a>
                                    </div>

                                </Container>
                                <Sonnet />
                            </Tab>
                        )
                    })
                }

            </Tabs>
        </div>

    )

}

const mapStateToProps = (state) => {
    return {
        language: state.language.language
    };
};

const mapDispatchToProps = null;

export default connect(mapStateToProps, mapDispatchToProps)(MiddleSectionMob);