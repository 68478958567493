import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { connect } from "react-redux";

import "../../../../../assets/styles/utils.scss";
import "../../../../../assets/styles/customer/account/orders/shipments/shipmentitem.scss";

import { getDesktopImage } from "../../../../../utils/utils.js";
import format from "date-fns/format";

const ShipmentItem = (props) => {
  return (
    <Row className="shipment-item no-gutters">
      <div className="image-container product-box-padding d-flex justify-content-center">
        <img
          style={{
            width: "auto",
            maxWidth: "100%",
            height: "100%",
          }}
          src={getDesktopImage(
            props.shipmentItem.orderable.featured_image_resized,
            props.shipmentItem.orderable.featured_image
          )}
          alt="Produc"
        />
      </div>

      <Col className="details py-2">
        <Row className="no-gutters px-2 h-100">
          <Col
            md={9}
            className="h-100 position-relative"
            style={{ textAlign: "initial" }}
          >
            <Row className="no-gutters">
              <Col md={12}>
                {props.shipmentItem.orderable[
                  `brand_name_${props.language}`
                ] && (
                  <span className="brand gray-text mr-2">
                    {
                      props.shipmentItem.orderable[
                        `brand_name_${props.language}`
                      ]
                    }
                  </span>
                )}
                <span className="name">
                  {props.shipmentItem?.orderable[`name_${props.language}`]}
                </span>
              </Col>
              <Col md={12}>
                {props.shipmentItem.orderable[`sku`] && (
                  <span className="brand gray-text">
                    {props.shipmentItem.orderable[`sku`]}
                  </span>
                )}
              </Col>
              {props.shipmentItem?.start_at && (
                <Col md={12} className="pt-2">
                  <span className="start-date">
                    {props.language?.toLowerCase() === "en"
                      ? "Starts at"
                      : "يبدأ في"}
                    :
                  </span>
                  <span className="date">
                    {format(
                      new Date(props.shipmentItem.start_at),
                      "EEE MMM dd, yyy p"
                    )}
                  </span>
                </Col>
              )}

              {props.shipmentItem?.end_at && (
                <Col md={12} className="pt-1">
                  <span className="end-date">
                    {props.language?.toLowerCase() === "en"
                      ? "Ends at"
                      : "ينتهي عند"}
                    :
                  </span>
                  <span className="date">
                    {format(
                      new Date(props.shipmentItem.end_at),
                      "EEE MMM dd, yyy p"
                    )}
                  </span>
                </Col>
              )}

              <Col md={12} className="sold-by faded-black-2-text">
                {props.language?.toLowerCase() === "en"
                  ? "Sold by"
                  : "تم بيعها من قبل"}{" "}
                <span className="fw-700">
                  {props.shipmentItem.orderable[`store_name_${props.language}`]}
                </span>
              </Col>
            </Row>
          </Col>

          <Col md={3}>
            <Row className="no-gutters text-right">
              <Col md={12} className="price faded-black-2-text">
                {props.currency.toUpperCase()}{" "}
                <span className="fw-700">{props.shipmentItem.price}</span>
              </Col>

              {props.shipmentItem.quantity && (
                <Col md={12} className="quantity gray-text">
                  {props.shipmentItem.quantity}{" "}
                  {props.shipmentItem.quantity > 1 ? "units" : "unit"}
                </Col>
              )}
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

const mapStateToProps = (state) => {
  return {
    language: state.language.language,
  };
};

export default connect(mapStateToProps, null)(ShipmentItem);
