import React, { Fragment } from "react";
import WebsiteHeader from "../../../components/header/WebsiteHeader";
import WebsiteFooter from "../../../components/Footer/WebsiteFooter";
import pagesbannerimg from "../../../assets/images/footer-pages-banners/alisuq_story.jpg";
import { useEffect } from "react";
import $ from "jquery";

const BuildingConstruction = () => {
  useEffect(() => {
    window.document.title = "Building Construction and Materials";

    $("html, body").animate({ scrollTop: 0 }, "fast");
  }, []);
  return (
    <Fragment>
      <div className="product-home-page-desktop mx-auto">
        <WebsiteHeader />
        <div className="pagesbanner">
          <img
            src={pagesbannerimg}
            alt="category"
            style={{
              width: "100%",
              height: "100%",
              maxHeight: "400px",
            }}
          ></img>
        </div>
        <h1>Asad mobile</h1>
        <div className="content-wrapper all-page-container">
       
        </div>
        <div className="w-100">
          <WebsiteFooter />
        </div>
      </div>
    </Fragment>
  );
};

export default BuildingConstruction;
