import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import "../../assets/styles/header/category.scss";

const Category = (props) => {
  const cls = props.language === "en" ? "english" : "arabic";
  return (
    <Fragment>
      <div className="category-container pb-2">
        <Link
          style={{ textDecoration: "none" }}
          to={{
            pathname:
              "/" + props.countryUrl + "-" + props.language + "/all-categories",
            state: {
              title: props.parentId,
            },
          }}
        >
          <h1 className="header-title">{props.cat}</h1>
          <div className={`individual-category-img-container ${cls}`}>
            <img src={props.image} alt="for cat" width="auto" height="auto" />
          </div>
        </Link>
        <ul className="items">
          {props.data?.map((ele) => (
            <li key={ele.id}>
              <Link
                to={{
                  pathname:
                    "/" +
                    props.countryUrl +
                    "-" +
                    props.language +
                    "/all-categories",
                  state: {
                    title:
                      ele.sub_categories?.length > 0
                        ? [
                            ele.id,
                            ...ele.sub_categories.map((subCat) => subCat.id),
                          ]
                        : [ele.id],
                  },
                }}
              >
                {ele[`title_${props.language}`]}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    language: state.language.language,
    countryUrl: state.language.countryUrl,
  };
};

export default connect(mapStateToProps, null)(Category);
