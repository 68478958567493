import React, { useEffect } from "react";
import Row from "react-bootstrap/Row";

import "../../../assets/styles/customer/account/general.scss";
import "../../../assets/styles/pages/walletpage.scss";
import WebsiteHeader from "../../../components/header/WebsiteHeader";
import Sidebar from "../../../components/customer/account/Sidebar";
import CustomerProfileAddress from "../../../components/customer/account/Addresses/CustomerProfileAddress";

const CustProfAddrPage = () => {
  useEffect(() => {
    document.title = "Address";
  }, []);
  return (
    <Row className="page-container no-gutters">
      <div className="w-100">
        <WebsiteHeader />

        <div className="content-container all-page-container d-flex justify-content-center mx-auto">
          <div className="left-pane">
            <Sidebar />
          </div>
          <div className="right-pane">
            <CustomerProfileAddress />
          </div>
        </div>
      </div>
    </Row>
  );
};

export default CustProfAddrPage;
