import React, { Fragment } from "react";

import "../../assets/styles/pages/loginpage.scss";
import Login from "../user/Login";
import { useTranslation } from "react-i18next";

const LoginPage = (props) => {
  const { t } = useTranslation();
  return (
    <Fragment>
      <div className="login-page mx-auto">
        <div className="account-login">
          <Login
            logo={true}
            welcome={t("login.welcome-title")}
            signInTitle={t("login.sign-in-title")}
          />
        </div>
      </div>
    </Fragment>
  );
};

export default LoginPage;
