import { type } from "../types/types";

export const set_width = (width) => (dispatch) => {
  dispatch({
    type: type.SET_WIDTH,
    width,
  });
};

export const set_loading = (loading) => (dispatch) => {
  dispatch({
    type: type.SET_LOADING,
    loading,
  });
};

export const set_direction = (language) => (dispatch) => {
  dispatch({
    type: type.SET_DIRECTION,
    direction: {
      direction: language.toLowerCase() === "en" ? "LTR" : "RTL",
      language: language,
    },
  });
};

export const set_country = (country) => (dispatch) => {
  dispatch({
    type: type.SET_COUNTRY,
    country,
  });
};
