import React from "react";
import { useState } from "react";
import { Row } from "react-bootstrap";
import ReturnItemMobile from "../../../../components/mobile/customer/returns/ReturnItemMobile";
import EmptyReturnsState from "../../../../assets/images/returns_art.svg";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import "../../../../assets/styles/mobile/customer/returns/returnsmobile.scss";

const ReturnsMobile = (props) => {
  const { t } = useTranslation();
  const REQUESTED_TAB = "requested";
  const APPROVED_TAB = "approved";
  const [currentTab, setCurrentTab] = useState(REQUESTED_TAB);

  const switchTab = (event) => {
    const tabName = event.target.getAttribute("data-tab");

    switch (tabName) {
      case APPROVED_TAB:
        setCurrentTab(APPROVED_TAB);
        break;
      default:
        setCurrentTab(REQUESTED_TAB);
        break;
    }
  };

  const renderEmptyState = () => {
    return (
      <div className="empty-returns-state d-flex flex-column justify-content-center align-items-center">
        <div className="image-container">
          <img
            src={EmptyReturnsState}
            className="img-fluid"
            alt=""
            height="100%"
            width="100%"
          />
        </div>
        <div className="description text-center">
          {t("returns-mobile.no-req1") + " "}
          {currentTab + " "}
          {" " + t("returns-mobile.no-req2")}
        </div>
      </div>
    );
  };

  const renderDataState = (items) => {
    return items.map((item, index) => {
      return <ReturnItemMobile item={item} key={index} />;
    });
  };

  return (
    <div className="returns-mobile">
      <div className="content">
        <Row
          noGutters={true}
          className="tabs justify-content-between align-items-stretch"
        >
          <div
            className={`${
              currentTab === REQUESTED_TAB ? "active-tab" : ""
            } tab left bordered-right d-flex justify-content-center align-items-center`}
            onClick={switchTab}
            data-tab={REQUESTED_TAB}
          >
            {t("returns-mobile.requested")}
          </div>
          <div
            className={`${
              currentTab === APPROVED_TAB ? "active-tab" : ""
            } tab right d-flex justify-content-center align-items-center`}
            onClick={switchTab}
            data-tab={APPROVED_TAB}
          >
            {t("returns-mobile.approved")}
          </div>
        </Row>

        {currentTab === REQUESTED_TAB ? (
          <div className="return-items item-padding">
            {props.requestedItems.length === 0
              ? renderEmptyState()
              : renderDataState(props.requestedItems)}
          </div>
        ) : (
          <div className="return-items item-padding">
            {props.approvedItems.length === 0
              ? renderEmptyState()
              : renderDataState(props.approvedItems)}
          </div>
        )}
      </div>
      <Link
        to={
          "/" +
          props.countryUrl +
          "-" +
          props.language +
          "/order/itemslist/refund"
        }
      >
        <Row noGutters={true} className="bottom justify-content-center">
          <button className="new-request-btn">
            {t("returns-mobile.file-a-new-ret-request")}
          </button>
        </Row>
      </Link>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    countryUrl: state.language.countryUrl,
    language: state.language.language,
  };
};

export default connect(mapStateToProps, null)(ReturnsMobile);
