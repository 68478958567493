import React, { useState, Fragment } from "react";
import Dropdown from "react-dropdown";
import { Link } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import "react-dropdown/style.css";
import { AiOutlineClose } from "react-icons/ai";
import { GrClose } from "react-icons/gr";
import "../../../assets/styles/component/customer/AllItems/ItemsHeader.scss";
import shipping from "../../../assets/images/vip_shipping_mobile.svg";
import sort from "../../../assets/images/sort_mobile.svg";
import { set_all_items } from "../../../redux/actions/AllItemsAction";
import { connect } from "react-redux";
import ItemsSidebar from "./ItemsSidebar";
import Checked from "../../../assets/icons/Checked.svg";
import Unchecked from "../../../assets/icons/Unchecked.svg";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { set_loading } from "../../../redux/actions/LayoutAction";
import { apiJson } from "../../../Api";
import { toast } from "react-toastify";
import { useHistory, useLocation } from "react-router-dom";
import { replaceAt } from "../../../utils/utils";

const ItemsHeader = (props) => {
  const [openFilters, setOpenFilters] = useState(false);
  const [openSortBy, setOpenSortBy] = useState(false);
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  let message = "";
  const [top_brands, setTopBrands] = useState([]);
  const [categories, setCategories] = useState([]);

  var filters = location.search.length
    ? decodeURI("&" + location.search.substring(1, location.search.length))
    : undefined;

  useEffect(() => {
    if (filters) {
      let categoryFilters = "";
      categoryFilters = filters
        ?.split("&filter_by_category[]=")
        ?.slice(1, filters?.split("&filter_by_category[]=").length)
        ?.map((category) =>
          category.includes("&")
            ? parseInt(category.split("&")[0])
            : parseInt(category)
        );
      if (categoryFilters != "") {
        apiJson.categories
          .getCategory(categoryFilters[0], props.countryId)
          .then(response => {
            if (response.success) {
              var brands = [];
              if (response.data.brands && response.data.brands.length > 0) {
                if (response.data.length > 4) {
                  for (var i = 0; i < 5; i++) {
                    brands = brands.concat(response.data.brands[i]);
                  }
                }
                else {
                  for (var i = 0; i < response.data.brands.length; i++) {
                    brands = brands.concat(response.data.brands[i]);
                  }
                }
              }
              setTopBrands(brands);
              let cat = [];
              cat = cat.concat(response.data);
              response.data.sub_categories?.forEach(category => {
                cat = cat.concat(category);
              });
              setCategories(cat);
            }
            else {
              if (response.status >= 400 && response.status < 500) {
                message = response.message
                  ? response.message
                  : response.errors
                    ? response.errors
                    : t("errors.wrong");
                showError();
              } else {
                message = response.message
                  ? response.message !== ""
                    ? response.message
                    : t("errors.wrong")
                  : t("errors.wrong");
                showError();
              }
            }
          })
      }
      else {
        var temp_top_brands = [];
        props.brand?.forEach(brand => {
          if (brand.name_en == "Rust-Oleum") {
            temp_top_brands[0] = brand;
          }
          else if (brand.name_en == "Karcher") {
            temp_top_brands[1] = brand;
          }
          else if (brand.name_en == "Schneider Electric") {
            temp_top_brands[2] = brand;
          }
          else if (brand.name_en == "Weicon") {
            temp_top_brands[3] = brand;
          }
          else if (brand.name_en == "Osprey") {
            temp_top_brands[4] = brand;
          }
          else if (brand.name_en == "Legrand") {
            temp_top_brands[5] = brand;
          }
          else if (brand.name_en == "Leatherman") {
            temp_top_brands[6] = brand;
          }
        });
        setTopBrands(temp_top_brands);
      }
    }
    else if (window.location.search) {
      filters = window.location.search.length
        ? decodeURI("&" + window.location.search.substring(1, window.location.search.length))
        : undefined;
      let categoryFilters = "";
      categoryFilters = filters
        ?.split("&filter_by_category[]=")
        ?.slice(1, filters?.split("&filter_by_category[]=").length)
        ?.map((category) =>
          category.includes("&")
            ? parseInt(category.split("&")[0])
            : parseInt(category)
        );

      if (categoryFilters != "") {
        apiJson.categories
          .getCategory(categoryFilters[0], props.countryId)
          .then(response => {
            if (response.success) {
              var brands = [];
              if (response.data.brands && response.data.brands.length > 0) {
                if (response.data.length > 4) {
                  for (var i = 0; i < 5; i++) {
                    brands = brands.concat(response.data.brands[i]);
                  }
                }
                else {
                  for (var i = 0; i < response.data.brands.length; i++) {
                    brands = brands.concat(response.data.brands[i]);
                  }
                }
              }
              setTopBrands(brands);
              let cat = [];
              cat = cat.concat(response.data);
              response.data.sub_categories?.forEach(category => {
                cat = cat.concat(category);
              });
              setCategories(cat);
            }
            else {
              if (response.status >= 400 && response.status < 500) {
                message = response.message
                  ? response.message
                  : response.errors
                    ? response.errors
                    : t("errors.wrong");
                showError();
              } else {
                message = response.message
                  ? response.message !== ""
                    ? response.message
                    : t("errors.wrong")
                  : t("errors.wrong");
                showError();
              }
            }
          })
      }
      else {
        var temp_top_brands = [];
        props.brand?.forEach(brand => {
          if (brand.name_en == "Rust-Oleum") {
            temp_top_brands[0] = brand;
          }
          else if (brand.name_en == "Karcher") {
            temp_top_brands[1] = brand;
          }
          else if (brand.name_en == "Schneider Electric") {
            temp_top_brands[2] = brand;
          }
          else if (brand.name_en == "Weicon") {
            temp_top_brands[3] = brand;
          }
          else if (brand.name_en == "Osprey") {
            temp_top_brands[4] = brand;
          }
          else if (brand.name_en == "Legrand") {
            temp_top_brands[5] = brand;
          }
          else if (brand.name_en == "Leatherman") {
            temp_top_brands[6] = brand;
          }
        });
        setTopBrands(temp_top_brands);
      }
    }
    else {
      var temp_top_brands = [];
      props.brand?.forEach(brand => {
        if (brand.name_en == "Rust-Oleum") {
          temp_top_brands[0] = brand;
        }
        else if (brand.name_en == "Karcher") {
          temp_top_brands[1] = brand;
        }
        else if (brand.name_en == "Schneider Electric") {
          temp_top_brands[2] = brand;
        }
        else if (brand.name_en == "Weicon") {
          temp_top_brands[3] = brand;
        }
        else if (brand.name_en == "Osprey") {
          temp_top_brands[4] = brand;
        }
        else if (brand.name_en == "Legrand") {
          temp_top_brands[5] = brand;
        }
        else if (brand.name_en == "Leatherman") {
          temp_top_brands[6] = brand;
        }
      });
      setTopBrands(temp_top_brands);
    }
  }, [filters, window.location.search]);

  useEffect(() => {
    if (!filters) {
      var temp_top_brands = [];
      props.brand?.forEach(brand => {
        if (brand.name_en == "Rust-Oleum") {
          temp_top_brands[0] = brand;
        }
        else if (brand.name_en == "Karcher") {
          temp_top_brands[1] = brand;
        }
        else if (brand.name_en == "Schneider Electric") {
          temp_top_brands[2] = brand;
        }
        else if (brand.name_en == "Weicon") {
          temp_top_brands[3] = brand;
        }
        else if (brand.name_en == "Osprey") {
          temp_top_brands[4] = brand;
        }
        else if (brand.name_en == "Legrand") {
          temp_top_brands[5] = brand;
        }
        else if (brand.name_en == "Leatherman") {
          temp_top_brands[6] = brand;
        }
      });
      setTopBrands(temp_top_brands);
    }
  }, [props.brand]);

  const showError = () => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const getFiltersString = (
    page,
    records,
    price_high_to_low,
    price_low_to_high,
    new_arrivals,
    selectedCat,
    selectedBrands,
    minPrice,
    maxPrice,
    selectedStores,
    rating,
    discount_sort
  ) => {
    let categories = "";
    selectedCat &&
      selectedCat.length > 0 &&
      selectedCat.map(
        (cat) => (categories = categories + "&filter_by_category[]=" + cat)
      );

    let brands = "";
    selectedBrands &&
      selectedBrands.length > 0 &&
      selectedBrands.map(
        (brandId) => (brands = brands + "&filter_by_brand[]=" + brandId)
      );
    let stores = "";
    selectedStores &&
      selectedStores.length > 0 &&
      selectedStores.map(
        (storeId) => (stores = stores + "&filter_by_store[]=" + storeId)
      );

    return (
      (parseInt(page) !== 1 ? "&page=" + page : "") +
      (parseInt(records) !== 30 ? "&records_per_page=" + records : "") +
      (price_high_to_low ? "&price_high_to_low=true" : "") +
      (discount_sort ? "&discount_sort=true" : "") +
      (price_low_to_high ? "&price_low_to_high=true" : "") +
      (new_arrivals ? "&order_by_new_arrivals=" + new_arrivals : "") +
      (selectedCat && selectedCat.length > 0 ? categories : "") +
      (selectedBrands && selectedBrands.length > 0 ? brands : "") +
      (minPrice > 0
        ? "&filter_by_price[min]=" + (minPrice ? minPrice : 0)
        : "") +
      (parseFloat(maxPrice) !== 9999999
        ? "&filter_by_price[max]=" + (maxPrice ? maxPrice : 9999999)
        : "") +
      (selectedStores && selectedStores.length > 0 ? stores : "") +
      (rating > 0 ? "&filter_by_rating=" + (rating ? rating : 0) : "")
    );
  };

  const handleClose = () => {
    setOpenFilters(false);
    setOpenSortBy(false);
  };

  const applyFilters = (
    price_high_to_low,
    price_low_to_high,
    new_arrivals,
    selectedCat,
    selectedBrands,
    minPrice,
    maxPrice,
    selectedStores,
    rating,
    discount_sort
  ) => {
    let filters = getFiltersString(
      1,
      props.recordPerPage,
      price_high_to_low,
      price_low_to_high,
      new_arrivals,
      selectedCat,
      selectedBrands,
      minPrice,
      maxPrice,
      selectedStores,
      rating,
      discount_sort
    );

    setOpenFilters(false);
    setOpenSortBy(false);
    props.set_loading(true);
    window.scroll(0, 0);

    filters = encodeURI(filters);

    history.push(
      location.state
        ? {
          pathname: window.location.pathname,
          search: replaceAt(filters, 0, "?"),
          state: location.state,
        }
        : {
          pathname: window.location.pathname,
          search: replaceAt(filters, 0, "?"),
        }
    );

    let product_type = "retail";

    if (window.location.href.includes("/wholesale")) {
      product_type = "wholesale";
    }

    if (props.type == "products") {
      apiJson.allitems
        .getVariants(
          props.countryId,
          props.type,
          1,
          props.recordPerPage,
          price_high_to_low,
          price_low_to_high,
          new_arrivals,
          selectedCat,
          selectedBrands,
          minPrice,
          maxPrice,
          selectedStores,
          rating,
          discount_sort,
          product_type
        )
        .then((response) => {
          if (response.success) {
            props.set_all_items({
              items:
                props.type === "products"
                  ? response.data.products
                  : response.data.services,
              pagination: response.data.pagination,
            });
            props.set_loading(false);
          } else if (!response.status) {
            props.set_loading(false);
            // eslint-disable-next-line
            message = response.message
              ? response.message !== ""
                ? response.message
                : t("errors.wrong")
              : t("errors.wrong");
            showError();
          } else {
            props.set_loading(false);
            if (response.status >= 400 && response.status < 500) {
              message = response.message
                ? response.message
                : response.errors
                  ? response.errors
                  : t("errors.wrong");
              showError();
            } else {
              message = response.message
                ? response.message !== ""
                  ? response.message
                  : t("errors.wrong")
                : t("errors.wrong");
              showError();
            }
          }
        });
    }
    else {
      apiJson.allitems
        .getItems(
          props.countryId,
          props.type,
          1,
          props.recordPerPage,
          price_high_to_low,
          price_low_to_high,
          new_arrivals,
          selectedCat,
          selectedBrands,
          minPrice,
          maxPrice,
          selectedStores,
          rating
        )
        .then((response) => {
          if (response.success) {
            props.set_all_items({
              items:
                props.type === "products"
                  ? response.data.products
                  : response.data.services,
              pagination: response.data.pagination,
            });
            props.set_loading(false);
          } else if (!response.status) {
            props.set_loading(false);
            // eslint-disable-next-line
            message = response.message
              ? response.message !== ""
                ? response.message
                : t("errors.wrong")
              : t("errors.wrong");
            showError();
          } else {
            props.set_loading(false);
            if (response.status >= 400 && response.status < 500) {
              message = response.message
                ? response.message
                : response.errors
                  ? response.errors
                  : t("errors.wrong");
              showError();
            } else {
              message = response.message
                ? response.message !== ""
                  ? response.message
                  : t("errors.wrong")
                : t("errors.wrong");
              showError();
            }
          }
        });
    }
  };

  const clearFilters = () => {
    props.set_all_items({
      seller: props.seller.map((seller) => {
        return {
          ...seller,
          name_en: seller.name_en,
          name_ar: seller.name_ar,
          count: seller.count,
          checked: false,
        };
      }),
      brand: props.brand.map((brand) => {
        return {
          ...brand,
          name_en: brand.name_en,
          name_ar: brand.name_ar,
          count: brand.count,
          checked: false,
        };
      }),
      minPrice: "0",
      maxPrice: "9999999",
      selectedCat: "",
      filter_by: [],
      mobileFiltersCache: [],
      rating: 0,
      selectedBrands: [],
      selectedStores: [],
      go: !props.go,
    });
    applyFilters(
      props.price_high_to_low,
      props.price_low_to_high,
      props.new_arrivals,
      "",
      [],
      "0",
      "9999999",
      [],
      0,
      props.discount_sort
    );
  };
  useEffect(() => {
    let index = 0;
    if (props.language === "en") {
      props.display_ar.map((e, i) =>
        e === props.displayVal.value ? (index = i) : []
      );
    } else {
      props.display_en.map((e, i) =>
        e === props.displayVal.value ? (index = i) : []
      );
    }
    props.language === "ar"
      ? props.set_all_items({
        displayVal: {
          value: props.display_ar[index],
          label: props.display_ar[index],
        },
      })
      : props.set_all_items({
        displayVal: {
          value: props.display_en[index],
          label: props.display_en[index],
        },
      });
    index = 0;
    if (props.language === "en") {
      props.sortBy_ar.map((e, i) =>
        e === props.sortVal.value ? (index = i) : []
      );
    } else {
      props.sortBy_en.map((e, i) =>
        e === props.sortVal.value ? (index = i) : []
      );
    }
    props.language === "ar"
      ? props.set_all_items({
        sortVal: {
          value: props.sortBy_ar[index],
          label: props.sortBy_ar[index],
        },
      })
      : props.set_all_items({
        sortVal: {
          value: props.sortBy_en[index],
          label: props.sortBy_en[index],
        },
      });
  }, [props.language]); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <Fragment>
      <div className="item-header-mobile item-header-desktop">
        <div className="small-screen-none ml-2 d-flex flex-direction-row justify-content-between top-header">
          <div className=" child-div-one fs-lg-15 fs-sm-11">
            {props.title === "Products"
              ? t("items.header.products")
              : t("bottom-nav.services")}
          </div>
          <div className="d-flex flex-direction-row">
            <div className=" child-div-two d-flex flex-row align-items-center justify-content-center">
              <div className="child-text fs-lg-12 fs-sm-10">
                {t("items.header.sort-by")}
              </div>
              <div className="ml-2 mr-2">
                <Dropdown
                  options={props.sortBy}
                  onChange={(e) => {
                    localStorage.setItem("load_items", "true");

                    let i = 0;
                    if (e.value === props[`sortBy_${props.language}`][1]) {
                      i = 1;
                    } else if (
                      e.value === props[`sortBy_${props.language}`][2]
                    ) {
                      i = 2;
                    } else if (
                      e.value === props[`sortBy_${props.language}`][3]
                    ) {
                      i = 3;
                    }
                    props.set_all_items({
                      sortVal: e,
                      // price_high_to_low: i === 1 ? true : false,
                      // price_low_to_high: i === 2 ? true : false,
                      // new_arrivals: i === 3 ? true : false,
                      discount_sort: i == 1 ? true : false
                    });
                  }}
                  value={props.sortVal}
                  placeholderClassName="dropdownPlaceholder"
                  menuClassName="dropdownPlaceholder border-shadow"
                  className="dropdown-cont cursor-class"
                />
              </div>
            </div>

            <div
              className=" ml-2 child-div-two d-flex flex-row align-items-center
          justify-content-center"
            >
              <div className="child-text fs-lg-12 fs-sm-10">
                {t("items.header.display")}
              </div>
              <div className="ml-2 mr-2">
                <Dropdown
                  options={props.display}
                  onChange={(e) => {
                    localStorage.setItem("load_items", "true");
                    props.set_all_items({
                      displayVal: e,
                      recordPerPage:
                        e.value === props[`display_${props.language}`][0]
                          ? 30
                          : e.value === props[`display_${props.language}`][1]
                            ? 50
                            : 80,
                    })
                  }
                  }
                  value={props.displayVal}
                  placeholderClassName="dropdownPlaceholder"
                  menuClassName="dropdownPlaceholder border-shadow"
                  className="dropdown-cont"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="main-item-body">
          <div
            style={{
              borderRight: "1px solid rgb(241, 243, 249)",
            }}
            onClick={() => setOpenFilters(!openFilters)}
            className="d-flex flex-direction-column justify-content-center align-items-center filter-cont"
          >
            <span className="text">{t("items.header.filter-by")}</span>
            <img src={shipping} className="img-cont" alt="" />
          </div>

          <div
            onClick={() => setOpenSortBy(!openSortBy)}
            className="d-flex flex-direction-column justify-content-center align-items-center filter-cont"
          >
            <span className="text ml-2">{t("items.header.sort-by")}</span>
            <img src={sort} className="img-cont" alt="" />
          </div>
        </div>


        {
          props.width == "sm" ?
            <div className="categories-layout">
              {
                categories?.map(category => {
                  return (
                    <Link to={{
                      pathname: "/" + props.countryUrl + "-" + props.language + "/items/products",
                      search: "?filter_by_category%5B%5D=" + category.id
                    }} className="category-box">
                      <p>{category.title_en}</p>
                    </Link>
                  )
                })
              }
            </div>
            :
            <></>
        }

        {props.filterBy?.length > 0 && (
          <div className="background-white-sm bottom-header">
            <span className="small-screen-none filter-text fs-lg-14 fs-sm-10">
              {t("items.header.filter-by")}
            </span>

            <span
              onClick={() => {
                localStorage.setItem("load_items", "true");
                props.RemoveFilter([]);
              }}
              className="large-screen-none clear-all-sm pointer "
            >
              {t("items.header.clear-all")}
            </span>

            {props.filterBy.map((value, index) => (
              <span className={props.language == "en" ? "filteredDiv" : "filteredDiv filteredDiv-ar"} key={index}>
                <span className="filter-text-sm large-screen-none">
                  {value.name}
                  <span className="pl-1">
                    <AiOutlineClose
                      size={10}
                      onClick={() => {
                        props.removedEle(
                          value,
                          props.filterBy.filter((v) => value.name !== v.name)
                        );
                      }}
                    />
                  </span>
                </span>
                <span className="filtered fs-lg-12 fs-sm-10 small-screen-none">
                  {value.heading} : <b>{value.name}</b>
                  {value.count && <span>+{value.count}</span>}
                </span>
                <span className={props.language == "en" ? "filterCloseButton pointer small-screen-none" : "filterCloseButton filterCloseButton-ar pointer small-screen-none"}>
                  <AiOutlineClose
                    size={10}
                    onClick={() => {
                      localStorage.setItem("load_items", "true");
                      props.removedEle(
                        value,
                        props.filterBy.filter((v) => value.name !== v.name)
                      );
                    }}
                  />
                </span>
              </span>
            ))}

            <span
              onClick={() => {
                localStorage.setItem("load_items", "true");
                props.RemoveFilter([]);
              }}
              className={
                props.language == "en" ?
                  "small-screen-none filter-clear-text fs-lg-12 fs-sm-10 pointer"
                  :
                  "small-screen-none filter-clear-text filter-clear-text-ar fs-lg-12 fs-sm-10 pointer"
              }
            >
              {t("items.header.clear-all")}
            </span>
          </div>
        )}

        {
          props.showBrands ?
            <div className="main-item-heading pt-2">
              Top Brands
            </div>
            :
            <></>
        }

        {
          props.width != "sm" ?
            <div className="top-brands-desktop-view">
              {
                top_brands && top_brands.length > 0 ?
                  <>
                    <div className="topbrand-head">
                      Top Brands
                    </div>
                    <div className="row brands-row no-gutters">
                      {
                        top_brands?.map((brand) => {
                          return (
                            <div className="col-width text-center">
                              <Link
                                to={{
                                  pathname:
                                    "/" +
                                    props.countryUrl +
                                    "-" +
                                    props.language +
                                    "/items/products",
                                  // state: { brand: brand.id },
                                  search: "?filter_by_brand%5B%5D=" + brand.id
                                }}>
                                <img src={brand.image} className="brand-logo" />
                                <p className="text-center brands-title">{brand.name_en}</p>
                              </Link>
                            </div>
                          )
                        })
                      }
                    </div>
                  </>
                  :
                  <></>
              }
            </div>
            :
            <div className="top-brands-mobile-view">
              <div className="brands-row no-gutters">
                {
                  top_brands?.map((brand) => {
                    return (
                      <div className="col-width text-center">
                        <Link
                          to={{
                            pathname:
                              "/" +
                              props.countryUrl +
                              "-" +
                              props.language +
                              "/items/products",
                            // state: { brand: brand.id },
                            search: "?filter_by_brand%5B%5D=" + brand.id
                          }}>
                          <img src={brand.image} className="brand-logo" />
                          <p className="text-center brands-title">{brand.name_en}</p>
                        </Link>
                      </div>
                    )
                  })
                }
              </div>
            </div>
        }

        <div className="main-item-heading pt-4">
          {props.title === "Products"
            ? t("items.header.products")
            : t("bottom-nav.services")}
        </div>

        {openFilters && (
          <Modal
            show={props.width === "sm" ? openFilters : false}
            onHide={handleClose}
          >
            <Modal.Header
              style={{
                textAlign: "start",
                direction: props.language === "ar" ? "rtl" : "ltr",
              }}
              className="pb-2"
            >
              <h5 style={{ color: "#404553", fontWeight: "bold" }}>
                {t("items.header.filters")}
              </h5>
              <div onClick={handleClose}>
                <GrClose color="#404553" size="19" />
              </div>
            </Modal.Header>
            <Modal.Body
              style={{
                textAlign: "start",
                direction: props.language === "ar" ? "rtl" : "ltr",
              }}
              className="pt-0 pl-3 pr-3 pb-0"
            >
              <ItemsSidebar
                type={props.title === "Products" ? "Products" : "Services"}
                className=""
              />
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="outline-primary"
                style={{
                  borderRadius: "0px",
                  fontWeight: "bold",
                  fontSize: "14px",
                  width: "30%",
                }}
                onClick={clearFilters}
              >
                {t("items.header.reset")}
              </Button>
              <Button
                onClick={() => {
                  applyFilters(
                    props.price_high_to_low,
                    props.price_low_to_high,
                    props.new_arrivals,
                    props.selectedCat,
                    props.selectedBrands,
                    props.minPrice,
                    props.maxPrice,
                    props.selectedStores,
                    props.rating,
                    props.discount_sort
                  );
                  props.set_all_items({
                    filter_by: [...props.mobileFiltersCache],
                  });
                }}
                style={{
                  borderRadius: "0px",
                  fontWeight: "bold",
                  fontSize: "14px",
                  width: "58%",
                }}
                variant="primary"
              >
                {t("items.header.apply")}
              </Button>
            </Modal.Footer>
          </Modal>
        )}
        {openSortBy && (
          <Modal
            show={props.width === "sm" ? openSortBy : false}
            onHide={handleClose}
            style={{ marginLeft: "20%" }}
          >
            <Modal.Body className="pt-0 w-80 pl-3 pr-3 pb-0">
              {props.sortBy?.map((sort, index) => (
                <div
                  key={index}
                  className="d-flex flex-direction-row justify-content-between align-items-center sort-by-modal"
                  onClick={() => {
                    let i = 0;
                    if (sort === props[`sortBy_${props.language}`][1]) {
                      i = 1;
                    } else if (sort === props[`sortBy_${props.language}`][2]) {
                      i = 2;
                    } else if (sort === props[`sortBy_${props.language}`][3]) {
                      i = 3;
                    }
                    props.set_all_items({
                      sortVal: sort,
                      discount_sort: i == 1 ? true : false
                      // price_high_to_low: i === 1 ? true : false,
                      // price_low_to_high: i === 2 ? true : false,
                      // new_arrivals: i === 3 ? true : false,
                    });
                    applyFilters(
                      // i === 1,
                      0,
                      i === 2,
                      i === 3,
                      props.selectedCat,
                      props.selectedBrands,
                      props.minPrice,
                      props.maxPrice,
                      props.selectedStores,
                      props.rating,
                      i == 1 ? true : false
                    );
                    handleClose();
                  }}
                >
                  <label
                    className={
                      (
                        props.sortVal.value
                          ? props.sortVal.value === sort
                          : props.sortVal === sort
                      )
                        ? `active-text`
                        : " "
                    }
                    for={sort}
                  >
                    {sort}
                  </label>
                  <img
                    alt={sort}
                    src={
                      (
                        props.sortVal.value
                          ? props.sortVal.value === sort
                          : props.sortVal === sort
                      )
                        ? Checked
                        : Unchecked
                    }
                    width="22px"
                    height="22px"
                  />
                </div>
              ))}
            </Modal.Body>
          </Modal>
        )}
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    items: state.allItems.items,
    mobileFiltersCache: state.allItems.mobileFiltersCache,
    originalItems: state.allItems.originalItems,
    sortVal: state.allItems.sortVal,
    displayVal: state.allItems.displayVal,
    width: state.layout.screenWidth,
    seller: state.allItems.seller,
    brand: state.allItems.brand,
    language: state.language.language,

    sortBy_en: state.allItems.sortBy_en,
    display_en: state.allItems.display_en,
    sortBy_ar: state.allItems.sortBy_ar,
    display_ar: state.allItems.display_ar,

    recordPerPage: state.allItems.recordPerPage,
    page: state.allItems.page,
    rating: state.allItems.rating,
    minPrice: state.allItems.minPrice,
    price_high_to_low: state.allItems.price_high_to_low,
    price_low_to_high: state.allItems.price_low_to_high,
    discount_sort: state.allItems.discount_sort,
    selectedBrands: state.allItems.selectedBrands,
    selectedStores: state.allItems.selectedStores,
    new_arrivals: state.allItems.new_arrivals,
    go: state.allItems.go,
    maxPrice: state.allItems.maxPrice,
    filter_by: state.allItems.filter_by,
    selectedCat: state.allItems.selectedCat,
    countryUrl: state.language.countryUrl,
    load: state.layout.loading,
    countryId: state.language.countryId,
  };
};

const mapDispatchToProps = {
  set_all_items,
  set_loading,
};
export default connect(mapStateToProps, mapDispatchToProps)(ItemsHeader);