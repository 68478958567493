import React, { Fragment } from "react";
import WishList from "./WishList";
import "../../../assets/styles/customer/cart/wishlist/cartwishlist.scss";

const CartWishlist = (props) => {
  return (
    <Fragment>
      {props.List?.length === 0 ? (
        <div></div>
      ) : (
        <WishList currency={props.currency} List={props.List} />
      )}
    </Fragment>
  );
};

export default CartWishlist;
