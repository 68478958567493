import React, { Fragment, useState, useEffect } from "react";
import "../../assets/styles/component/mobile/bottomnav.scss";
import { ReactComponent as MobileAccountInactive } from "../../assets/icons/MobileAccountInactive.svg";
import { ReactComponent as MobileCartInactive } from "../../assets/icons/MobileCartInactive.svg";
import { ReactComponent as MobileCategoriesInactive } from "../../assets/icons/MobileCategoriesInactive.svg";
import { ReactComponent as MobileHomeInactive } from "../../assets/icons/MobileHomeInactive.svg";
import { ReactComponent as MobileTrendingIinactive } from "../../assets/icons/MobileTrendingIinactive.svg";
import settingsIcon from "../../assets/images/customer-support.png";

import { GiClothes } from "react-icons/gi";
import { BsInboxes } from "react-icons/bs";
import { useTranslation } from "react-i18next";
import { Redirect, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { set_cart_items } from "../../redux/actions/CartAction";

const BottomNav = (props) => {
  const { t } = useTranslation();
  const [redirect, setRedirect] = useState({ value: false });
  const location = useLocation();

  useEffect(() => {
    var total = 0;
    props.cart_items?.line_items?.map((item) => {
      if (item.orderable_type === "Product") {
        total = total + parseInt(item.quantity);
      } else if (item.orderable_type === "Service") {
        total = total + 1;
      }
      props.set_cart_items({ cart_items_length: total });
      return item;
    });
    if (props.cart_items?.line_items?.length === 0) {
      props.set_cart_items({ cart_items_length: total });
    }
  }, [props.cart_items?.line_items]); // eslint-disable-line

  return (
    <Fragment>
      <div className="bottom-nav-my-account">
        <div
          onClick={() => {
            setRedirect({
              value: true,
              path: "/" + props.countryUrl + "-" + props.lang + "/",
            });
            const timer = setTimeout(() => {
              setRedirect({ value: false });
            }, 1000);
            return () => clearTimeout(timer);
          }}
          className="icon-label-set"
        >
          <MobileHomeInactive width="20" height="20" />
          <label className="icon-label">{t("bottom-nav.home")}</label>
        </div>
        <div
          onClick={() => {
            setRedirect({
              value: true,
              path:
                "/" + props.countryUrl + "-" + props.lang + "/all-categories",
            });
            const timer = setTimeout(() => {
              setRedirect({ value: false });
            }, 1000);
            return () => clearTimeout(timer);
          }}
          className="icon-label-set"
        >
          <MobileCategoriesInactive width="20" height="20" />
          <label className="icon-label">{t("bottom-nav.categories")}</label>
        </div>


        <div
          onClick={() => {
            setRedirect({
              value: true,
              path:
                "/" +
                props.countryUrl +
                "-" +
                props.lang +
                "/dss"
            });
            const timer = setTimeout(() => {
              setRedirect({ value: false });
            }, 1000);
            return () => clearTimeout(timer);
          }}
          className="icon-label-set offersmb"
       
        >
          {props.loggedIn ? (
            <>
              <GiClothes size={30} />
              <label className="icon-label">
                {t("bottom-nav.new-arrival")}
              </label>
            </>
          ) : (
            <>
              <MobileTrendingIinactive
                width="20"
                height="20"
                className="mob-trend"
              />
              <label className="icon-label">DSS</label>
            </>
          )}
        </div>
        
        <div
          onClick={() => {
            setRedirect({
              value: true,
              path:
                "/" +
                props.countryUrl +
                "-" +
                props.lang +
                "/items/products" +
                (window.location.pathname.includes("/items/products") &&
                !location.state?.brand
                  ? window.location.search
                  : ""),
            });
            const timer = setTimeout(() => {
              setRedirect({ value: false });
            }, 1000);
            return () => clearTimeout(timer);
          }}
          className="icon-label-set"
        >
          {props.loggedIn ? (
            <>
              <BsInboxes size={30} />
              <label className="icon-label">
                {t("bottom-nav.new-arrival")}
              </label>
            </>
          ) : (
            <>
              <BsInboxes
                width="20"
                height="20"
                className="mob-trend"
              />
              <label className="icon-label">Products</label>
            </>
          )}
        </div>

        {/* <div
          onClick={() => {
            setRedirect({
              value: true,
              path:
                "/" +
                props.countryUrl +
                "-" +
                props.lang +
                "/items/services" +
                (window.location.pathname.includes("/items/services")
                  ? window.location.search
                  : ""),
            });
            const timer = setTimeout(() => {
              setRedirect({ value: false });
            }, 1000);
            return () => clearTimeout(timer);
          }}
          className="icon-label-set cart-col"
        >
          <div className="cart-col">
            <img src={settingsIcon} width="20px" height="20px" alt="" />
          </div>
          <label className="icon-label">{t("bottom-nav.services")}</label>
        </div> */}

        <div
          onClick={() => {
            setRedirect({
              value: true,
              path: localStorage.getItem("access_token")
                ? "/" +
                  props.countryUrl +
                  "-" +
                  props.lang +
                  "/my-account-li-mob"
                : "/" +
                  props.countryUrl +
                  "-" +
                  props.lang +
                  "/my-account-lo-mob",
            });
            const timer = setTimeout(() => {
              setRedirect({ value: false });
            }, 1000);
            return () => clearTimeout(timer);
          }}
          className="icon-label-set"
        >
          <MobileAccountInactive width="18" height="20" />
          <label className="icon-label">{t("bottom-nav.my-account")}</label>
        </div>

        <div
          onClick={() => {
            setRedirect({
              value: true,
              path: "/" + props.countryUrl + "-" + props.lang + "/cart",
            });
            const timer = setTimeout(() => {
              setRedirect({ value: false });
            }, 1000);
            return () => clearTimeout(timer);
          }}
          className="icon-label-set cart-col"
        >
          <div className="cart-col">
            <span
              style={{
                left: props.lang === "en" ? "15px" : "100%",
                right: props.lang === "ar" ? "-8px" : "100%",
              }}
              className="cart-counter"
            >
              {props.cart_items_length ? props.cart_items_length : 0}
            </span>
            <span>
              <MobileCartInactive width="20" height="20" />
            </span>
          </div>
          <label className="icon-label">{t("bottom-nav.cart")}</label>
        </div>
      </div>
      {redirect.value && <Redirect push to={redirect.path} />}
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    lang: state.language.language,
    cart_items: state.cart.cart_items,

    countryUrl: state.language.countryUrl,
    cart_items_length: state.cart.cart_items_length,
  };
};

const mapDispatchToProps = {
  set_cart_items,
};

export default connect(mapStateToProps, mapDispatchToProps)(BottomNav);
