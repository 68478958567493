import React, { Fragment, useRef, useEffect } from "react";

import "./message.scss";
import { connect } from "react-redux";
import moment from "moment";

const Message = (props) => {
  const messagesEndRef = useRef(null);
  const dispute = props.dispute;
  const message = props.message;
  const classNames =
    message.owner !== "customer" ? "direction-left" : "direction-right";

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [dispute.replies]);

  return (
    <Fragment>
      <div className="meassage-desktop w-100">
        <div
          className={`${classNames} wrapper w-100 d-flex align-items-center`}
        >
          <div className="image-container d-flex align-items-center justify-content-center">
            <div className="first-letter">
              {message.owner === "customer"
                ? props.user?.name
                  ? props.user.name[0]
                  : "C"
                : message.owner[0]}
            </div>
          </div>
          <div className="text-container">
            <span className="text">{message.description}</span>
            <span className="date">
              {moment(new Date(message.created_at)).format("lll")}
            </span>
          </div>
        </div>
        <div ref={messagesEndRef}></div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};

export default connect(mapStateToProps, null)(Message);
