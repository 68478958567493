import React, { useEffect, useState } from "react";
import MobileLayoutRenderer from "./layout/MobileLayoutRenderer";
import "../../assets/styles/pages/orderitemslistforrefund.scss";
import { AiOutlineRight, AiOutlineLeft } from "react-icons/ai";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { apiJson } from "../../Api";

import { Redirect, useParams } from "react-router-dom";
import { set_loading } from "../../redux/actions/LayoutAction";
import { getMobileImage } from "../../utils/utils";

const OrderItemsListForRefund = (props) => {
  const { name } = useParams();
  const [redirect, setRedirect] = useState({ value: false });
  const [orders, setOrders] = useState([]);
  const [data, setData] = useState(false);

  let message = "";
  const showError = () => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    props.set_loading(true);
    apiJson.orderItemsList.getItems().then((response) => {
      props.set_loading(false);
      if (response.success) {
        setOrders(response.data);
        setData(true);
      } else if (!response.status) {
        // eslint-disable-next-line
        message = response.message
          ? response.message !== ""
            ? response.message
            : t("errors.wrong")
          : t("errors.wrong");
        showError();
      } else {
        if (response.status >= 400 && response.status < 500) {
          message = response.message
            ? response.message
            : response.errors
              ? response.errors
              : t("errors.wrong");
          showError();
        } else {
          message = response.message
            ? response.message !== ""
              ? response.message
              : t("errors.wrong")
            : t("errors.wrong");
          showError();
        }
      }
    });
  }, []);

  useEffect(() => {
    document.title = "Order Items";
  }, []);

  const { t } = useTranslation();
  return (
    <MobileLayoutRenderer
      NavbarTop={true}
      BackBtn={false}
      CancelBtn={true}
      NavbarBottom={false}
    >
      <div className="order-items-list-for-refund-main-mobile">
        <div className="information-tag-line">
          <span className="tag-line-text">
            {t(`order-item-list-for-refund.upper-text-${name}`)}
          </span>
        </div>
        <div className="list-of-items-refunds">
          {!data ? (
            <div className="spinner-style"></div>
          ) : (
            orders.map((item) => {
              return (
                <div
                  key={item.id}
                  className="item d-flex mt-3 mb-3 pl-2 pr-2"
                  onClick={(e) => {
                    e.preventDefault();
                    name === "review"
                      ? setRedirect({
                        value: true,
                        path:
                          "/" +
                          props.countryUrl +
                          "-" +
                          props.language +
                          `/create/review/${item.id}`,
                        id: item.id,
                        state: item,
                      })
                      :
                      name == "refund" ?
                      (
                        item.orderable.brand_name_en == "Rust-Oleum" ||
                        item.orderable.brand_name_en == "DAP" ||
                        item.orderable.brand_name_en == "Krud Kutter" ||
                        item.orderable.brand_name_en == "Pattex" ||
                        item.orderable.brand_name_en == "Roll Roy" ||
                        item.orderable.brand_name_en == "Benjamin Moore" ||
                        item.orderable.brand_name_en == "Karcher" ||
                        item.orderable.brand_name_en == "Misar Hardware" ||
                        item.orderable.brand_name_en == "Stanley" ||
                        item.orderable.brand_name_en == "Energizer"
                      ) ?
                        setRedirect({
                          value: true,
                          path:
                            "/" +
                            props.countryUrl +
                            "-" +
                            props.language +
                            "/create/" +
                            name +
                            "/" +
                            item.id,
                        })
                        :
                        <></>
                      :
                      setRedirect({
                        value: true,
                        path:
                          "/" +
                          props.countryUrl +
                          "-" +
                          props.language +
                          "/create/" +
                          name +
                          "/" +
                          item.id,
                      })
                  }}
                >
                  <div
                    style={{ width: "50px", height: "50px" }}
                    className="d-flex justify-content-center"
                  >
                    <img
                      src={getMobileImage(
                        item.orderable.featured_image_resized,
                        item.orderable.featured_image
                      )}
                      alt=""
                      style={{
                        width: "auto",
                        maxWidth: "100%",
                        height: "100%",
                      }}
                    />
                  </div>
                  <div className="description-container">
                    <span className="description">
                      {(item.orderable_type === "Product"
                        ? item.orderable[`product_name_${props.language}`]
                        : "") +
                        " " +
                        item.orderable[`name_${props.language}`]}
                    </span>
                    {item.orderable_type === "Product" && (
                      <p className="sku ">{item.orderable[`sku`]}</p>
                    )}
                    {
                      name == "refund" ?
                      (
                        item.orderable.brand_name_en == "Rust-Oleum" ||
                        item.orderable.brand_name_en == "DAP" ||
                        item.orderable.brand_name_en == "Krud Kutter" ||
                        item.orderable.brand_name_en == "Pattex" ||
                        item.orderable.brand_name_en == "Roll Roy" ||
                        item.orderable.brand_name_en == "Benjamin Moore" ||
                        item.orderable.brand_name_en == "Karcher" ||
                        item.orderable.brand_name_en == "Misar Hardware" ||
                        item.orderable.brand_name_en == "Stanley" ||
                        item.orderable.brand_name_en == "Energizer"
                      ) ?
                        <>
                        </>
                        :
                        <p style={{ margin: "0px", fontSize: "13px", color: "red" }}>You can't refund this item.</p>
                      :
                      <></>
                    }
                    <p className="order-id ">
                      {t("order-items-list.order")} {item.order_id}
                    </p>
                  </div>
                  <div className="right-arrow">
                    {
                      name == "refund" ?
                      (
                        item.orderable.brand_name_en == "Rust-Oleum" ||
                        item.orderable.brand_name_en == "DAP" ||
                        item.orderable.brand_name_en == "Krud Kutter" ||
                        item.orderable.brand_name_en == "Pattex" ||
                        item.orderable.brand_name_en == "Roll Roy" ||
                        item.orderable.brand_name_en == "Benjamin Moore" ||
                        item.orderable.brand_name_en == "Karcher" ||
                        item.orderable.brand_name_en == "Misar Hardware" ||
                        item.orderable.brand_name_en == "Stanley" ||
                        item.orderable.brand_name_en == "Energizer"
                      ) ?
                        props.direction === "RTL" ? (
                          <AiOutlineLeft size={25} />
                        ) : (
                          <AiOutlineRight size={25} color="grey" />
                        )
                        :
                        <>
                        </>
                      :
                      props.direction === "RTL" ? (
                        <AiOutlineLeft size={25} />
                      ) : (
                        <AiOutlineRight size={25} color="grey" />
                      )
                    }
                  </div>
                </div>
              );
            })
          )}
        </div>
      </div>
      {redirect.value && (
        <Redirect
          push
          to={{ pathname: redirect.path, state: redirect.state }}
        />
      )}
    </MobileLayoutRenderer>
  );
};

const mapStateToProps = (state) => {
  return {
    country: state.language.country,
    language: state.language.language,
    direction: state.language.direction,
    countryUrl: state.language.countryUrl,
  };
};
const mapDispatchToProps = { set_loading };
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrderItemsListForRefund);
