import React, { useState, useEffect } from "react";
import "../../../assets/styles/component/customer/itemDetail/FrequentBoughtMobile.scss";
import FreqProdMobile from './FreqProdMobile';


export default function frequentBoughtMobile(props) {

    return (

        <div className="frequentContainerMobile">

            <div className="frequent">
              
                <div className="frequent2main">
                    <span className="frequenttxt">Frequently Bought Together</span>
                    <div className="freqprodbox">
                        <FreqProdMobile sku={props.sku} product={props.product}/>

                    </div>
                    {/* <div className="freqadddiv"><button className="freqadd">Buy 3 togethor for AED 830.40</button>
                    </div> */}
                </div>
            </div>

        </div>














    );


}