import React, { Fragment, useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import "bootstrap/dist/css/bootstrap.css";
import "../../assets/styles/pages/producthomepage.scss";
import "react-toastify/dist/ReactToastify.css";
import "../../assets/styles/component/middle_section.scss";
import dummyoffer from "../../assets/images/homepage/dummyoffer.png";
import Tabs from 'react-bootstrap/Tabs';
import Sonnet from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tabs';
import { Row, Container } from "react-bootstrap";
import { apiJson } from "../../Api";
import TabProductList from "../product/TabProductList";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

const en_translation = require("../../assets/locales/en/translation.json");
const ar_translation = require("../../assets/locales/ar/translation.json");

const MiddleSection = (props) => {
    const [tabs, setTabs] = useState([]);
    const { t } = useTranslation();

    // useEffect(() => {
    //     apiJson.homepage.getHomeOffersSections(1)
    //         .then(response => {
    //             if (response.success) {
    //                 setSections(response.data.sections);
    //             }
    //         })
    // }, []);

    useEffect(() => {
        apiJson.homepage.getHomeTabs(props.country)
            .then(response => {
                if (response.success) {
                    setTabs(response.data.tabs);
                }
            })
    }, []);
    return (

        <div className="middle-section">
            <div className="sectiontitle-container-left">

                <h2 className="section-title">
                    {
                        props.language == "en" ?
                            en_translation["homePage"]["highlights"]
                            :
                            ar_translation["homePage"]["highlights"]
                    }
                    {/* {t("homePage.highlights")} */}
                </h2>
            </div>

            <Tabs defaultActiveKey={tabs[0]?.title_en} id="uncontrolled-tab-example" className="mb-3 justify-content-left">
                {
                    tabs.map(tab => {
                        return (
                            <Tab eventKey={tab.title_en} title={tab[`title_` + props.language]}>
                                <Container fluid>

                                    <Row
                                        key={0}
                                        className={`product-list-row mx-auto`}
                                    >
                                        <TabProductList
                                            type="product"
                                            home={true}
                                            products={tab.tab_products}
                                            onQuickViewClicked={props.onQuickViewClicked}
                                        />
                                        <div />
                                    </Row>
                                    <div className={
                                        "ViewAllbtn view-all-middle-sec"
                                        +
                                        (props.language == "ar" ? " view-all-middle-sec-ar" : "")
                                    }>
                                        <a href={tab.view_all_link}>
                                            {
                                                props.language == "en" ?
                                                    en_translation["all-categories"]["view-all"]
                                                    :
                                                    ar_translation["all-categories"]["view-all"]
                                            }
                                            {/* {t("all-categories.view-all")} */}
                                        </a>
                                    </div>

                                </Container>
                                <Sonnet />
                            </Tab>
                        )
                    })
                }

            </Tabs>
        </div>

    )

}

const mapStateToProps = (state) => {
    return {
        language: state.language.language
    };
};

const mapDispatchToProps = null;

export default connect(mapStateToProps, mapDispatchToProps)(MiddleSection);