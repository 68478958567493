import React, { useState, useEffect } from "react";
import { AiOutlineHome } from "react-icons/ai";
import { CgToolbox } from "react-icons/cg";
import { useTranslation } from "react-i18next";
import { apiJson } from "../../../Api";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ClipLoader from "react-spinners/ClipLoader";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import addressImg from "../../../assets/images/address_defualt_map.png";
import "../../../assets/styles/component/mobile/AddAddress/AddAddressBody.scss";
import { set_cart_items } from "../../../redux/actions/CartAction";

function AddAddressBody(props) {
  const [active, setActive] = useState("Home");
  const [address1, setAddress1] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("-");
  const [phone, setPhone] = useState("+971");
  const { t } = useTranslation();
  const [addressTwo, setAddressTwo] = useState("");
  const [state, setState] = useState("");

  const [error, setError] = useState(false);
  const [addingData, setAddingData] = useState(false);
  const [redirectPage, setRedirectPage] = useState(false);
  const [redirect, setRedirect] = useState({
    value: false,
    path: "/" + props.countryUrl + "-" + props.language + "/",
  });
  let message = "";

  const showError = () => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const saveAddress = () => {
    if (
      address1.length > 0 &&
      // props.address.address?.length > 1 &&
      state.length > 0 &&
      firstName.length > 0 &&
      // lastName.length > 0 &&
      addressTwo.length > 0 &&
      /^(?:00971|\+971|0)?(?:|05|5|04|4)\d{8}$/.test(phone)
    ) {
      setAddingData(true);
      apiJson.select
        .addAdress({
          address: {
            country:
              props.countryId == 1
                ? "uae"
                : props.countryId == 2
                  ? "ksa"
                  : "egypt",
            state: state,
            address1: address1,
            // country: props.address.country,
            // state: props.address.state,
            // address1: props.address.address,
            address2: addressTwo,
            latitude: "-",
            longitude: "-",
            // latitude: props.address.lat,
            // longitude: props.address.lng,
            label: active,
            first_name: firstName,
            last_name: lastName,
            contact_number: phone,
          },
        })
        .then((response) => {
          localStorage.setItem("guest_user_address", JSON.stringify(response.data));
          setAddingData(false);
          if (response.success) {
            setFirstName("");
            setLastName("");
            setAddingData(false);
            setError(false);
            setPhone("");
            if (!props.auth) {
              const request = {
                order: {
                  address_id: response.data.id,
                },
              };
              // props.set_cart_items({
              //   order: {
              //     id: response.data.id,

              //     number: response.data.contact_number,
              //     address: response.data,
              //     request: request,
              //   },
              // });

              // setRedirect({
              //   value: true,
              //   path: "/" + props.countryUrl + "-" + props.language + "/phone",
              // });

              //Added by kamran
              var address = response.data;
              apiJson.phone.verifyOtp(address.id, "1").then((response) => {
                if (response.success) {
                  apiJson.select
                    .varifyAdress({
                      order: {
                        address_id: address.id,
                        is_delivery: 1
                      }
                    }, props.countryId)
                    .then((response) => {
                      // setLoading(false);
                      if (response.success) {
                        props.set_cart_items({
                          order: {
                            address: address,
                            id: response.data.id,
                            number: address.contact_number,
                            createdAddress: response.data,
                            request: request,
                          },
                        });
                        setRedirect({
                          value: true,
                          path:
                            "/" + props.countryUrl + "-" + props.language + "/checkout",
                        });
                        const timer = setTimeout(() => {
                          setRedirect({ value: false });
                        }, 1000);
                        clearTimeout(timer);
                      } else if (!response.status) {
                        // setLoading(false);
                        message = t("errors.wrong");
                        showError();
                      } else {
                        // setLoading(false);
                        if (response.status >= 400 && response.status < 500) {
                          message = response.message
                            ? response.message
                            : response.errors
                              ? response.errors
                              : t("errors.wrong");
                          showError();
                        } else {
                          message = response.message
                            ? response.message !== ""
                              ? response.message
                              : t("errors.wrong")
                            : t("errors.wrong");
                          showError();
                        }
                      }
                    });
                } else if (!response.status) {
                  // setLoading(false);
                  message = response.message
                    ? response.message !== ""
                      ? response.message
                      : t("errors.wrong")
                    : t("errors.wrong");
                  showError();
                } else {
                  // setLoading(false);
                  if (response.status >= 400 && response.status < 500) {
                    message = response.message
                      ? response.message
                      : response.errors
                        ? response.errors
                        : t("errors.wrong");
                    showError();
                  } else {
                    message = response.message
                      ? response.message !== ""
                        ? response.message
                        : t("errors.wrong")
                      : t("errors.wrong");
                    showError();
                  }
                }
              });

            } else {
              setRedirectPage(true);
              const timer = setTimeout(() => {
                setRedirectPage(false);
              }, 1000);
              clearTimeout(timer);
            }
          } else if (!response.status) {
            message = t("errors.wrong");
            showError();
          } else {
            if (response.status >= 400 && response.status < 500) {
              message = response.message
                ? response.message
                : response.errors
                  ? response.errors
                  : t("errors.wrong");
              showError();
            } else {
              message = response.message
                ? response.message !== ""
                  ? response.message
                  : t("errors.wrong")
                : t("errors.wrong");
              showError();
            }
          }
        });
    } else {
      setError(true);
    }
  };

  const updateAddress = () => {
    if (
      address1.length > 1 &&
      state.length > 0 &&
      // props.address.address?.length > 1 &&
      firstName.length > 0 &&
      // lastName.length > 0 &&
      addressTwo.length > 0 &&
      /^(?:00971|\+971|0)?(?:|05|5|04|4)\d{8}$/.test(phone)
    ) {
      setAddingData(true);
      apiJson.select
        .updateAddress(props.addressData.id, {
          address: {
            country:
              props.countryId == 1
                ? "uae"
                : props.countryId == 2
                  ? "ksa"
                  : "egypt",
            state: state,
            address1: address1,
            // country: props.address.country,
            // state: props.address.state,
            // address1: props.address.address,
            address2: addressTwo,
            latitude: "-",
            longitude: "-",
            // latitude: props.address.lat,
            // longitude: props.address.lng,
            label: active,
            first_name: firstName,
            last_name: lastName,
            contact_number: phone,
          },
        })
        .then((response) => {
          setAddingData(false);
          if (response.success) {
            setFirstName("");
            setLastName("");
            setAddingData(false);
            setError(false);
            setPhone("");
            if (!props.auth) {
              const request = {
                order: {
                  address_id: response.data.id,
                },
              };
              // props.set_cart_items({
              //   order: {
              //     id: response.data.id,

              //     number: response.data.contact_number,
              //     address: response.data,
              //     request: request,
              //   },
              // });

              // setRedirect({
              //   value: true,
              //   path: "/" + props.countryUrl + "-" + props.language + "/phone",
              // });

              //Added by kamran
              var address = response.data;
              apiJson.phone.verifyOtp(address.id, "1").then((response) => {
                if (response.success) {
                  apiJson.select
                    .varifyAdress({
                      order: {
                        address_id: address.id,
                        is_delivery: 1
                      }
                    }, props.countryId)
                    .then((response) => {
                      // setLoading(false);
                      if (response.success) {
                        props.set_cart_items({
                          order: {
                            address: address,
                            id: response.data.id,
                            number: address.contact_number,
                            createdAddress: response.data,
                            request: request,
                          },
                        });
                        setRedirect({
                          value: true,
                          path:
                            "/" + props.countryUrl + "-" + props.language + "/checkout",
                        });
                        const timer = setTimeout(() => {
                          setRedirect({ value: false });
                        }, 1000);
                        clearTimeout(timer);
                      } else if (!response.status) {
                        // setLoading(false);
                        message = t("errors.wrong");
                        showError();
                      } else {
                        // setLoading(false);
                        if (response.status >= 400 && response.status < 500) {
                          message = response.message
                            ? response.message
                            : response.errors
                              ? response.errors
                              : t("errors.wrong");
                          showError();
                        } else {
                          message = response.message
                            ? response.message !== ""
                              ? response.message
                              : t("errors.wrong")
                            : t("errors.wrong");
                          showError();
                        }
                      }
                    });
                } else if (!response.status) {
                  // setLoading(false);
                  message = response.message
                    ? response.message !== ""
                      ? response.message
                      : t("errors.wrong")
                    : t("errors.wrong");
                  showError();
                } else {
                  // setLoading(false);
                  if (response.status >= 400 && response.status < 500) {
                    message = response.message
                      ? response.message
                      : response.errors
                        ? response.errors
                        : t("errors.wrong");
                    showError();
                  } else {
                    message = response.message
                      ? response.message !== ""
                        ? response.message
                        : t("errors.wrong")
                      : t("errors.wrong");
                    showError();
                  }
                }
              });
            } else {
              setRedirectPage(true);
              const timer = setTimeout(() => {
                setRedirectPage(false);
              }, 1000);
              clearTimeout(timer);
            }
          } else if (!response.status) {
            message = t("errors.wrong");
            showError();
          } else {
            if (response.status >= 400 && response.status < 500) {
              message = response.message
                ? response.message
                : response.errors
                  ? response.errors
                  : t("errors.wrong");
              showError();
            } else {
              message = response.message
                ? response.message !== ""
                  ? response.message
                  : t("errors.wrong")
                : t("errors.wrong");
              showError();
            }
          }
        });
    } else {
      setError(true);
    }
  };

  useEffect(() => {
    if (props.addressData) {
      setAddress1(props.addressData.address1);
      setAddressTwo(props.addressData.address2);
      setState(props.addressData.state);
      setPhone(props.addressData.number);
      setFirstName(props.addressData.firstName);
      setLastName(props.addressData.lastName);
      setActive(props.addressData.label);
    }
  }, []); // eslint-disable-line

  return (
    <div className="add-address-body-mobile">
      <div className="location-txt mt-3 ml-3">
        {t("ship.add-address-mobile.loc-info")}
      </div>
      {/* <div className="d-flex flex-direction-row justify-content-between mt-3 ml-3 mr-3 address-text">
        <span style={{ width: "calc(100% - 80px)", textAlign: "initial" }}>
          {props.address.address}
        </span>
        <div
          className="map-img"
          style={{
            backgroundImage: `url(${addressImg})`,
          }}
        >
          <span className="edit-text" onClick={() => props.setLocation(false)}>
            {t("ship.add-address-mobile.edit")}
          </span>
        </div>
      </div>
      {error && props.address.address.length < 1 && (
        <div className="mt-error error">*Required</div>
      )} */}

      <div className="mt-3 ml-3  mr-3">
        <div className="additional-address-text">
          {t("address-form.location.address1-placeholder")}
        </div>
        <input
          type="text"
          placeholder={t("address-form.location.address1-placeholder")}
          className="additional-address-input mt-3 pb-3"
          value={address1}
          onChange={(e) => setAddress1(e.target.value)}
        />
      </div>
      {error && address1.length < 1 && (
        <div className="mt-error error">*Required</div>
      )}

      <div className="mt-3 ml-3  mr-3">
        <div className="additional-address-text">
          {t("ship.add-address-mobile.additional-adr-details")}
        </div>
        <input
          type="text"
          placeholder={t("ship.add-address-mobile.where-to-drop")}
          className="additional-address-input mt-3 pb-3"
          value={addressTwo}
          onChange={(e) => setAddressTwo(e.target.value)}
        />
      </div>
      {error && addressTwo.length < 1 && (
        <div className="mt-error error">*Required</div>
      )}

      <div className="mt-3 ml-3  mr-3">
        <div className="additional-address-text">
          {t("ship.add-address-mobile.state")}
        </div>
        <select
          className="additional-address-input mt-3 pb-3 border-0"
          value={state}
          onChange={(e) => setState(e.target.value)}
        >
          <option value="">--- Select emirate ---</option>
          {
            props.countryId == 1 ?
              <>
                <option value="Dubai">Dubai</option>
                <option value="Abu Dhabi">Abu Dhabi</option>
                <option value="Sharjah">Sharjah</option>
                <option value="Ajman">Ajman</option>
                <option value="Umm Al Quwain">Umm Al Quwain</option>
                <option value="Ras Al Khaimah">Ras Al Khaimah</option>
                <option value="Fujairah">Fujairah</option>
              </>
              :
              <></>
          }
        </select>
      </div>
      {error && state.length < 1 && (
        <div className="mt-error error">*Required</div>
      )}

      <div className="mt-3 ml-3 mr-3">
        <div className="location-txt mt-5 ">
          {t("ship.add-address-mobile.personal-info")}
        </div>

        <div className="additional-address-text mt-5">
          {t("ship.add-address-mobile.mobile-no")}
        </div>
        <input
          type="tel"
          placeholder={t("ship.add-address-mobile.mobile-no")}
          className="additional-address-input mt-3 pb-3"
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
          style={{ direction: "ltr" }}
          maxLength="14"
        />
      </div>
      {error && !/^(?:00971|\+971|0)?(?:|05|5|04|4)\d{8}$/.test(phone) && (
        <div className="mt-error error">Should be a valid phone number</div>
      )}

      <div className=" ml-3 mr-3">
        <div className="additional-address-text mt-4">
          {t("ship.add-address-mobile.first-name")}
        </div>
        <input
          type="text"
          placeholder={t("ship.add-address-mobile.fn-ph")}
          className="name-input mt-3 pb-3"
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
        />
      </div>
      {error && firstName.length < 1 && (
        <div className="mt-error error">*Required</div>
      )}

      {/* <div className=" ml-3 mr-3">
        <div className="additional-address-text mt-4">
          {t("ship.add-address-mobile.last-name")}
        </div>
        <input
          type="text"
          placeholder={t("ship.add-address-mobile.ln-ph")}
          className="name-input mt-3 pb-3"
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
        />
      </div>
      {error && lastName.length < 1 && (
        <div className="mt-error error">*Required</div>
      )} */}

      {/* <div className="d-flex mt-3 ml-3">
        <span
          onClick={() => setActive("Home")}
          className={`home-cont mr-2 ${active === "Home" ? " active-cont" : ""
            }`}
        >
          <span className="mr-1">
            <AiOutlineHome color="#646466" size="17" />
          </span>
          <span className="sp-bold"> {t("ship.add-address-mobile.home")}</span>
        </span>
        <span
          onClick={() => setActive("Work")}
          className={`home-cont ${active === "Work" ? " active-cont" : ""}`}
        >
          <span className="mr-1">
            <CgToolbox color="#646466" size="17" />
          </span>
          <span className="sp-bold">{t("ship.add-address-mobile.work")}</span>
        </span>
      </div>

      <div className="mt-3 ml-3 mr-3">
        <div className="label-text mt-4">
          {t("ship.add-address-mobile.adrs-label-optional")}
        </div>
      </div> */}

      <div
        className="save-cont mt-4 mb-4"
        onClick={(e) => {
          props.addressData ? updateAddress() : saveAddress();
        }}
      >
        <div className="save-text">
          {addingData ? (
            <div className="d-flex justify-content-center align-items-center w-100">
              <ClipLoader color="#fff" loading size={20} />
            </div>
          ) : props.addressData ? (
            t("ship.add-address-mobile.update-address")
          ) : (
            t("ship.add-address-mobile.save-adrs-btn")
          )}
        </div>
      </div>
      {redirect.value && (
        <Redirect
          push
          to={{
            pathname: redirect.path,
            state: redirect.state,
          }}
        />
      )}
      {redirectPage && (
        <Redirect
          push
          to={`${props.addresses
            ? "/" + props.countryUrl + "-" + props.language + "/addresses"
            : "/" +
            props.countryUrl +
            "-" +
            props.language +
            "/select-address"
            }`}
        />
      )}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    coupon_id: state.customerAddress.coupon_id,
    auth: state.auth.isSignedIn,
    countryUrl: state.language.countryUrl,
    language: state.language.language,
    countryId: state.language.countryId,
  };
};
const mapDispatchToProps = { set_cart_items };

export default connect(mapStateToProps, mapDispatchToProps)(AddAddressBody);
