import React from "react";

import "../../../../assets/styles/customer/account/CustomerProfileMap/CustomerProfileMapFooter.scss";

export default function CustomerProfileMapFooter() {
  return (
    <div className="profile-map-footer-desktop d-flex justify-content-between">
      <div className="copy-right">©2021Alisuq. All Rights Reserved.</div>
      <div className="statement d-flex">
        <div className="term-of-use">Terms Of Use</div>
        <div className="term-of-sale">Terms Of Sale</div>
        <div className="privacy-policy">Privacy Policy</div>
      </div>
    </div>
  );
}
