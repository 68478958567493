import React, { Fragment, useEffect } from "react";
import { Row } from "react-bootstrap";

import "../../../assets/styles/customer/account/general.scss";
import "../../../assets/styles/pages/profilepage.scss";
import Profile from "../../../components/customer/account/Profile";
import Sidebar from "../../../components/customer/account/Sidebar";
import WebsiteHeader from "../../../components/header/WebsiteHeader";

const ProfilePage = (props) => {
  useEffect(() => {
    document.title = "Profile";
  }, []);
  return (
    <Fragment>
      <Row className="page-container no-gutters">
        <div className="w-100">
          <WebsiteHeader />

          <div className="content-container all-page-container mx-auto d-flex">
            <div className="left-pane">
              <Sidebar />
            </div>
            <div className="right-pane">
              <Profile />
            </div>
          </div>
        </div>
      </Row>
    </Fragment>
  );
};

export default ProfilePage;
