import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import "../../../../../assets/styles/customer/account/orders/shipments/paymentmethod.scss";
import "../../../../../assets/styles/utils.scss";

import MasterCardIcon from "../../../../../assets/icons/mastercard.png";
import VisaCard from "../../../../../assets/icons/visaCard.png";
import { useTranslation } from "react-i18next";
import AccountPayment from "../../../../../assets/icons/account_payments.svg";
import Cash from "../../../../../assets/icons/CashBW.svg";

const PaymentMethod = (props) => {
  const { t } = useTranslation();
  return (
    <Row className="payment-method custom-card-2 no-gutters mt-3 p-3">
      <Col md={12} className="title text-uppercase fw-600">
        {t("orderDetails.pm-title")}
      </Col>
      <Col md={12} className="pt-2">
        <Row className="no-gutters align-items-center">
          {props.paymentMethod.payment_card && (
            <>
              <div
                className={` ${
                  props.paymentMethod?.payment_card_type === "Visa"
                    ? "logo-container-visa mr-2"
                    : "logo-container mr-3"
                }`}
              >
                <img
                  src={
                    props.paymentMethod?.payment_card_type === "Visa"
                      ? VisaCard
                      : props.paymentMethod?.payment_card_type === "MasterCard"
                      ? MasterCardIcon
                      : MasterCardIcon
                  }
                  alt=""
                />
              </div>

              <div className="card-number mr-3">
                {"xxx" + props.paymentMethod.payment_card_number}
              </div>
              <div className="card-number mr-3">
                {"( " +
                  props.paymentMethod.currency.toUpperCase() +
                  " " +
                  props.paymentMethod.payment_card +
                  " )"}
              </div>
            </>
          )}

          {props.paymentMethod.wallet && (
            <>
              <div className="mr-3 logo-container">
                <img src={AccountPayment} alt="" />
              </div>

              <div className="card-number mr-3">
                {props.paymentMethod.currency.toUpperCase() +
                  " " +
                  props.paymentMethod.wallet}
              </div>
            </>
          )}
          {props.paymentMethod.cash_on_delivery && (
            <>
              <div className="mr-3 logo-container">
                <img src={Cash} alt="" />
              </div>

              <div className="card-number mr-3">
                {props.paymentMethod.currency.toUpperCase() +
                  " " +
                  props.paymentMethod.cash_on_delivery}
              </div>
            </>
          )}
        </Row>
      </Col>
    </Row>
  );
};

export default PaymentMethod;
