import React, { Fragment, useEffect, useState } from "react";
import { apiJson } from "../../Api";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import WebsiteFooter from "../../components/Footer/WebsiteFooter";
import pagesbannerimg from "../../assets/images/footer-page-images/Thunder Sale.gif";
import WebsiteHeader from "../../components/header/WebsiteHeader";
import { set_all_items } from "../../redux/actions/AllItemsAction";
import { set_cart_items } from "../../redux/actions/CartAction";
import { set_loading } from "../../redux/actions/LayoutAction";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import "../../assets/styles/footer-pages/offers.scss";
import ProductList from "../../components/product/ProductList";
import Countdown from "react-countdown";

import $ from "jquery";
import OffersMain from "../../components/customer/AllItems/OffersMain";

const Offers = (props) => {
  const [data, setData] = useState(false);
  const [saleCounter, setSaleCounter] = useState("");

  let message = "";
  const showError = () => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  const { t } = useTranslation();

  useEffect(() => {
    props.set_loading(true);
    window.document.title = "Offers";
    $("html, body").animate({ scrollTop: 0 }, "fast");

    setData(false);

    let pageFilter = 1;
    let recordsFilter = 30;
    let display_en = ["30 per page", "50 per page", "80 per page"];
    let display_ar = [
      "ثمانون لكل صفحة",
      "خمسون لكل صفحة",
      "ثلاثون لكل صفحة",
    ].reverse();

    apiJson.homepage.offer().then((response) => {
      if (response.success) {
        if (response.data.length > 0) {
          setSaleCounter(
            new Date(response.data[0].expiry).getTime() - Date.now()
          );
        }
      }
    });

    apiJson.homepage.getOffersData(props.countryId, pageFilter, recordsFilter).then(async (response) => {
      props.set_loading(false);
      if (response.success) {
        setData(true);
        // eslint-disable-next-line

        //temporarily added
        let final_products = [];

        response.data.products.forEach(product => {
          if (product.variants) {
            if (product.variants[0] != null) {
              final_products = final_products.concat(product);
            }
          }
        });

        final_products.forEach(product => {
          product.variants = product.variants.sort((el1,el2) => el1.price.localeCompare(el2.price, undefined, {numeric: true}));
        })
        //temporarily added
        
        props.set_all_items({
          display_en: display_en,
          display_ar: display_ar,
          page: pageFilter,
          recordPerPage: recordsFilter,
          // items: response.data.products,
          items: final_products,
          pagination: response.data.pagination,
        });
      } else if (!response.status) {
        setData(true);
        // eslint-disable-next-line
        message = response.message
          ? response.message !== ""
            ? response.message
            : t("errors.wrong")
          : t("errors.wrong");
        showError();
      } else {
        setData(true);
        if (response.status >= 400 && response.status < 500) {
          message = response.message
            ? response.message
            : response.errors
              ? response.errors
              : t("errors.wrong");
          showError();
        } else {
          message = t("errors.wrong");
          showError();
        }
      }
    });
  }, []);
  return (
    <Fragment>
      <div className="product-home-page-desktop mx-auto">
        <WebsiteHeader />
        <div className="pagesbanner">
          <img
            src={pagesbannerimg}
            alt="category"
            style={{
              width: "100%",
              height: "100%",
              maxHeight: "400px",
            }}
          ></img>
        </div>

        <div className="product-home-page-desktop mx-auto">
          <div className="offer-tabs">
            {saleCounter ? (
              <div class="avcc">
                <h1 className="weeklysales">
                  SALE <span className="offerends">Ends in </span>{" "}
                  <Countdown date={Date.now() + saleCounter} />
                </h1>
              </div>
            ) : (
              <></>
            )}
          </div>

          <div className="all-page-container mx-auto">
            <OffersMain
              data={data}
              title={"Products"}
              type={"products"}
            />
          </div>

          <div className="w-100">
            <WebsiteFooter />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    seller: state.allItems.seller,
    filter_by: state.allItems.filter_by,
    brand: state.allItems.brand,
    items: state.allItems.items,
    countryId: state.language.countryId,
    width: state.layout.screenWidth,
    language: state.language.language,
  };
};

const mapDispatchToProps = {
  set_all_items,
  set_loading,
};

export default connect(mapStateToProps, mapDispatchToProps)(Offers);
