import { type } from "../types/types";

export const set_cart_items = (cart_items) => (dispatch) => {
  dispatch({
    type: type.SET_CART_ITEMS,
    cart_items,
  });
};

export const set_wish_list = (wish_list) => (dispatch) => {
  dispatch({
    type: type.SET_WISH_LIST,
    wish_list,
  });
};
