import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { connect } from "react-redux";

import "../../../../assets/styles/utils.scss";
import "../../../../assets/styles/customer/account/orders/orderitem.scss";

import { getDesktopImage } from "../../../../utils/utils.js";
import { useTranslation } from "react-i18next";

const OrderItem = ({ shipmentItem, currency, language }) => {
  const { t } = useTranslation();
  return (
    <Row className="shipment-item no-gutters">
      <div className="image-container product-box-padding d-flex justify-content-center align-items-center">
        <img
          src={getDesktopImage(
            shipmentItem.orderable.featured_image_resized,
            shipmentItem.orderable.featured_image
          )}
          alt="Item"
        />
      </div>

      <Col className="details py-2">
        <Row className="no-gutters px-2 h-100">
          <Col md={10} className="h-100 position-relative">
            <Row className="no-gutters">
              <Col md={12} style={{ textAlign: "initial" }}>
                <span className="brand gray-text mr-2">
                  {shipmentItem.orderable[`brand_name_${language}`]}
                </span>
                <span className="title">
                  {(shipmentItem.orderable[`product_name_${language}`]
                    ? shipmentItem.orderable[`product_name_${language}`]
                    : "") +
                    " " +
                    shipmentItem.orderable[`name_${language}`]}
                </span>
              </Col>
              {shipmentItem.orderable[`sku`] && (
                <Col md={12} style={{ textAlign: "initial" }}>
                  <span className="brand gray-text mr-2">
                    {shipmentItem.orderable[`sku`]}
                  </span>
                </Col>
              )}

              <Col
                md={12}
                className="sold-by faded-black-2-text"
                style={{ textAlign: "initial" }}
              >
                {t("items-list-review.desktop-card.sold-by")} &nbsp;
                <span className="fw-700">
                  {shipmentItem.orderable[`store_name_${language}`]}
                </span>
              </Col>
            </Row>
          </Col>

          <Col md={2}>
            <Row className="no-gutters text-right">
              <Col md={12} className="price faded-black-2-text">
                {currency + " "}
                <span className="fw-700">{shipmentItem.price}</span>
              </Col>

              {shipmentItem.quantity && (
                <Col md={12} className="quantity gray-text">
                  {shipmentItem.quantity}
                  {shipmentItem.quantity > 1
                    ? t("items-list-review.desktop-card.units")
                    : t("items-list-review.desktop-card.unit")}
                </Col>
              )}
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

const mapStateToProps = (state) => {
  return {
    language: state.language.language,
  };
};

export default connect(mapStateToProps, null)(OrderItem);
