import React, { useState } from "react";
import { useEffect } from "react";

import SiteHeader from "../../../components/header/WebsiteHeader";
import WholesaleHeader from "../../../components/header/BWebsiteHeader";
import SiteFooter from "../../../components/Footer/WebsiteFooter";
import ItemsMain from "../../../components/customer/section_items/SectionItems";

import ItemsSidebar from "../../../components/customer/AllItems/ItemsSidebar";
import "../../../assets/styles/pages/customer/AllItems/SearchPage.scss";
import { set_all_items } from "../../../redux/actions/AllItemsAction";
import { connect } from "react-redux";
import { apiJson } from "../../../Api";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useParams } from "react-router-dom";
import MobileLayoutRenderer from "../../mobile/layout/MobileLayoutRenderer";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { set_loading } from "../../../redux/actions/LayoutAction";
// import didYouMean from 'didyoumean';
import { keywords } from "../../../assets/keywords";
import HeaderMobile from "../../../components/mobile/HeaderMobile";
import VariantsModal from "../../../components/customer/VariantsModal";

function SectionItemsPage(props) {
  const { t } = useTranslation();
  const [data, setData] = useState(false);
  let { type, id } = useParams();
  const location = useLocation();
  type = type.split("?")[0];
  const filters = location.search.length
    ? decodeURI("&" + location.search.substring(1, location.search.length))
    : undefined;
  let message = "";
  const [variantsModalShow, setVariantsModalShow] = React.useState(false);
  const [selectedProduct, setSelectedProduct] = React.useState(null);
  const [selectedSku, setSelectedSku] = React.useState(null);

  const showError = () => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  useEffect(() => {
    document.title = "Shop";
  }, []);

  // eslint-disable-next-line
  useEffect(async () => {
    window.scroll(0, 0);
    setData(false);
    props.set_loading(true);

    let pageFilter = 1;
    let recordsFilter = 30;
    let high_to_low = false;
    let low_to_high = false;
    let new_arrivals = false;
    let categoryFilters = "";
    let brandFilters = [];
    let storeFilters = [];
    let priceMinFilter = 0;
    let priceMaxFilter = 9999999;
    let ratingFilter = 0;
    let display_en = ["30 per page", "50 per page", "80 per page"];
    let display_ar = [
      "ثمانون لكل صفحة",
      "خمسون لكل صفحة",
      "ثلاثون لكل صفحة",
    ].reverse();
    let display_val = "";
    let sortBy_en = [
      "Recommended",
      "Price: High to low",
      "Price: Low to high",
      "New Arrivals",
    ];
    let sortBy_ar = [
      "موصى به",
      "السعر الاعلى الى الادنى",
      "السعر من الارخص للاعلى",
      "الوافدون الجدد",
    ];
    let sortVal = "";

    if (filters) {
      categoryFilters = filters
        ?.split("&filter_by_category[]=")
        ?.slice(1, filters?.split("&filter_by_category[]=").length)
        ?.map((category) =>
          category.includes("&")
            ? parseInt(category.split("&")[0])
            : parseInt(category)
        );

      brandFilters = filters
        ?.split("&filter_by_brand[]=")
        ?.slice(1, filters.split("&filter_by_brand[]=").length)
        ?.map((brand) =>
          brand.includes("&") ? parseInt(brand.split("&")[0]) : parseInt(brand)
        );

      storeFilters = filters
        ?.split("&filter_by_store[]=")
        ?.slice(1, filters.split("&filter_by_store[]=").length)
        ?.map((store) =>
          store.includes("&") ? parseInt(store.split("&")[0]) : parseInt(store)
        );

      if (filters.includes("&page=")) {
        pageFilter = filters?.split("&page=")[1];
        pageFilter = pageFilter.includes("&")
          ? parseInt(pageFilter.split("&")[0])
          : parseInt(pageFilter);
      }

      if (filters.includes("&records_per_page")) {
        recordsFilter = filters?.split("&records_per_page=")[1];
        recordsFilter = recordsFilter.includes("&")
          ? parseInt(recordsFilter.split("&")[0])
          : parseInt(recordsFilter);
      }

      if (filters.includes("&price_high_to_low=")) {
        high_to_low = true;
      }

      if (filters.includes("&price_low_to_high=")) {
        low_to_high = true;
      }

      if (filters.includes("order_by_new_arrivals=")) {
        new_arrivals = true;
      }

      if (filters.includes("&filter_by_price[min]=")) {
        priceMinFilter = filters?.split("&filter_by_price[min]=")[1];
        priceMinFilter = priceMinFilter.includes("&")
          ? priceMinFilter.split("&")[0]
          : priceMinFilter;
      }

      if (filters.includes("&filter_by_price[max]=")) {
        priceMaxFilter = filters?.split("&filter_by_price[max]=")[1];
        priceMaxFilter = priceMaxFilter.includes("&")
          ? priceMaxFilter.split("&")[0]
          : priceMaxFilter;
      }

      if (filters.includes("&filter_by_rating=")) {
        ratingFilter = filters?.split("&filter_by_rating=")[1];
        ratingFilter = ratingFilter.includes("&")
          ? ratingFilter.split("&")[0]
          : ratingFilter;
      }
    }

    let index = recordsFilter === 80 ? 2 : recordsFilter === 50 ? 1 : 0;
    display_val =
      props.language === "en" ? display_en[index] : display_ar[index];
    index = high_to_low ? 1 : low_to_high ? 2 : new_arrivals ? 3 : 0;
    sortVal = props.language === "en" ? sortBy_en[index] : sortBy_ar[index];

    // var text = ((new URLSearchParams(window.location.search)).get('q')).trim().replace(/\s\s+/g, ' ');
    // if (didYouMean(((new URLSearchParams(window.location.search)).get('q')).trim().replace(/\s\s+/g, ' '), keywords)) {
    //   text = didYouMean(((new URLSearchParams(window.location.search)).get('q')).trim().replace(/\s\s+/g, ' '), keywords);
    // }

    apiJson.sections
      .getSectionProducts(
        id,
        props.countryId,
        pageFilter,
        recordsFilter
      )
      .then((response) => {
        if (response.success) {
          setData(true);
          props.set_all_items({
            display_en: display_en,
            display_ar: display_ar,

            sortBy_en: sortBy_en,
            sortBy_ar: sortBy_ar,
            displayVal: display_val,
            sortVal: sortVal,

            rating: parseFloat(ratingFilter),
            selectedCat: location?.state?.category
              ? Array.isArray(location?.state?.category)
                ? Array.from(
                  new Set([...categoryFilters, ...location?.state?.category])
                )
                : Array.from(
                  new Set([
                    ...categoryFilters,
                    parseInt(location?.state?.category),
                  ])
                )
              : categoryFilters,
            minPrice: priceMinFilter,
            maxPrice: priceMaxFilter,
            page: pageFilter,
            recordPerPage: recordsFilter,
            price_high_to_low: high_to_low,
            price_low_to_high: low_to_high,
            new_arrivals: new_arrivals,
            selectedBrands: location.state?.brand
              ? Array.from(new Set([location.state?.brand, ...brandFilters]))
              : brandFilters,
            selectedStores: storeFilters,
            go: false,
            items:
              type === "products"
                ? response.data.products
                : response.data.services,
            pagination: response.data.pagination,
          });
        } else if (!response.status) {
          setData(true);
          // eslint-disable-next-line
          message = response.message
            ? response.message !== ""
              ? response.message
              : t("errors.wrong")
            : t("errors.wrong");
          showError();
        } else {
          setData(true);
          if (response.status >= 400 && response.status < 500) {
            message = response.message
              ? response.message
              : response.errors
                ? response.errors
                : t("errors.wrong");
            showError();
          } else {
            message = response.message
              ? response.message !== ""
                ? response.message
                : t("errors.wrong")
              : t("errors.wrong");
            showError();
          }
        }

        props.set_loading(false);
      });

    apiJson.allitems.getCategories(props.countryId).then((response) => {
      if (response.success) {
        props.set_all_items({
          category: response.data,
        });
      } else if (!response.status) {
        message = response.message
          ? response.message !== ""
            ? response.message
            : t("errors.wrong")
          : t("errors.wrong");
        showError();
      } else {
        if (response.status >= 400 && response.status < 500) {
          message = response.message
            ? response.message
            : response.errors
              ? response.errors
              : t("errors.wrong");
          showError();
        } else {
          message = response.message
            ? response.message !== ""
              ? response.message
              : t("errors.wrong")
            : t("errors.wrong");
          showError();
        }
      }
    });

    let response = {};
    let filtersArr = [];
    let brandNames = [];
    let storeNames = [];

    if (type === "products") {
      response = await apiJson.allitems.getBrands(props.countryId);
      if (response.success) {
        let data = response.data.map((item) => {
          if (
            item.id === location?.state?.brand ||
            brandFilters.filter((brand) => brand === item.id).length > 0
          ) {
            brandNames.push(item[`name_${props.language}`]);
            return {
              ...item,
              name_en: item[`name_en`],
              name_ar: item[`name_ar`],
              count: 1,
              checked: true,
            };
          } else {
            return {
              ...item,
              name: item[`name_${props.language}`],
              count: 1,
              checked: false,
            };
          }
        });
        if (brandNames.length > 0) {
          filtersArr.push(
            ...brandNames.map((brand) => {
              return {
                name: brand,
                count: 1,
                heading: t("itemsSidebar.brand"),
              };
            })
          );
        }
        props.set_all_items({
          brand: data,
        });
      } else if (!response.status) {
        message = response.message
          ? response.message !== ""
            ? response.message
            : t("errors.wrong")
          : t("errors.wrong");
        showError();
      } else {
        if (response.status >= 400 && response.status < 500) {
          message = response.message
            ? response.message
            : response.errors
              ? response.errors
              : t("errors.wrong");
          showError();
        } else {
          message = response.message
            ? response.message !== ""
              ? response.message
              : t("errors.wrong")
            : t("errors.wrong");
          showError();
        }
      }
    }

    response = await apiJson.allitems.getSeller(props.countryId);
    if (response.success) {
      let data = response.data.map((item) => {
        if (storeFilters.filter((store) => store === item.id).length > 0) {
          storeNames.push(item[`name_${props.language}`]);
          return {
            ...item,
            name_en: item[`name_en`],
            name_ar: item[`name_ar`],
            count: 1,
            checked: true,
          };
        } else {
          return {
            ...item,
            name_en: item[`name_en`],
            name_ar: item[`name_ar`],
            count: 1,
            checked: false,
          };
        }
      });
      if (storeNames.length > 0) {
        filtersArr.push(
          ...storeNames.map((store) => {
            return {
              name: store,
              count: 1,
              heading: t("itemsSidebar.seller"),
            };
          })
        );
      }
      if (priceMinFilter > 0 || priceMaxFilter !== 9999999) {
        filtersArr.push({
          name: priceMinFilter + "-" + priceMaxFilter,
          heading: t("itemsSidebar.price"),
        });
      }
      ratingFilter > 0 &&
        filtersArr.push({
          name: ratingFilter,
          heading: t("itemsSidebar.rating"),
        });
      props.set_all_items({
        seller: data,
        filter_by: [...filtersArr],
        mobileFiltersCache: [...filtersArr],
      });
    } else if (!response.status) {
      message = response.message
        ? response.message !== ""
          ? response.message
          : t("errors.wrong")
        : t("errors.wrong");
      showError();
    } else {
      if (response.status >= 400 && response.status < 500) {
        message = response.message
          ? response.message
          : response.errors
            ? response.errors
            : t("errors.wrong");
        showError();
      } else {
        message = response.message
          ? response.message !== ""
            ? response.message
            : t("errors.wrong")
          : t("errors.wrong");
        showError();
      }
    }
  }, [location.state?.brand, type, id]);

  const onQuickViewClicked = (product, sku) => {
    setVariantsModalShow(true);
    setSelectedSku(sku);

    apiJson.
      singleItem.getItemData(product.id, type, props.countryId)
      .then(response => {
        response.data.type = "Product";

        setSelectedProduct(response.data);
      });
  }

  return (
    <div className="items-page-mobile items-page-desktop">
      <VariantsModal
        show={variantsModalShow}
        onHide={() => setVariantsModalShow(false)}
        closeModal={() => setVariantsModalShow(false)}
        product={selectedProduct}
        selected_sku={selectedSku}
      />
      {props.width !== "sm" ? (
        <>
          {
            window.location.href.includes("/wholesale") ?
              <div className="header-mobile">
                <WholesaleHeader />
              </div>
              :
              <div className="header-mobile">
                <SiteHeader />
              </div>
          }

          <div className="all-page-container m-auto  d-flex flex-row">
            {/* <div className=" sidebar-desktop">
              <ItemsSidebar
                type={type === "products" ? "Products" : "Services"}
              />
            </div> */}
            <div className="items-desktop">
              <ItemsMain
                data={data}
                title={type === "products" ? "Products" : "Services"}
                type={type}
                id={id}
                onQuickViewClicked={onQuickViewClicked}
              />
            </div>
          </div>
          <div className="footer-mobile">
            <SiteFooter />
          </div>
        </>
      ) : (
        <MobileLayoutRenderer
          NavbarTop={false}
          BackBtn={false}
          CancelBtn={false}
          NavbarBottom={true}
          SearchIcon={true}
        >
          <div className="home-mob">
            <HeaderMobile />
          </div>
          <div className="all-page-container m-auto  d-flex flex-row">
            <div className=" sidebar-desktop">
              <ItemsSidebar
                type={type === "products" ? "Products" : "Services"}
                setFilterBy={(val) => { }}
              />
            </div>
            <div className="items-desktop">
              <ItemsMain
                data={data}
                title={type === "products" ? "Products" : "Services"}
                type={type}
                id={id}
              />
            </div>
          </div>
        </MobileLayoutRenderer>
      )}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    seller: state.allItems.seller,
    filter_by: state.allItems.filter_by,
    brand: state.allItems.brand,
    items: state.allItems.items,
    countryId: state.language.countryId,
    width: state.layout.screenWidth,
    language: state.language.language,
  };
};

const mapDispatchToProps = {
  set_all_items,
  set_loading,
};

export default connect(mapStateToProps, mapDispatchToProps)(SectionItemsPage);
