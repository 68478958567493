import React, { useState, useEffect } from "react";
import { Fragment } from "react";

import "../../assets/styles/customer/shippingaddress.scss";
import { set_addresses } from "../../redux/actions/SelectAddressAction";
import GoogleMaps from "./GoogleMaps";
import ShippingFooter from "./shippingfooter/ShippingFooter";
import Header from "./shippingheader/Header";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import { useTranslation } from "react-i18next";

const ShippingAddress = (props) => {
  const { t } = useTranslation();
  const [active, setActive] = useState(false);
  const [redirectPage, setRedirectPage] = useState(false);
  useEffect(() => {
    document.title = "Shipping Address";
  }, []);
  return (
    <Fragment>
      {props.cart_items?.line_items?.length > 0 ? (
        <div className="shipping-address-page-desktop">
          <Header screen_name="ADDRESS" />
          <div className="row map-row no-gutters mx-auto">
            <div className="map mx-auto">
              <div className="adress-col col-12 align-items-center">
                <div className="label">{t("ship.add-new-address")}</div>
              </div>
              <div className="map-col col-12 align-items-center">
                <GoogleMaps
                  Address={(val) => props.set_addresses({ mapAddress: val })}
                  active={(val) => setActive(val)}
                />
              </div>
              <div className="confirm-location-col col-12 d-flex align-items-center justify-content-start">
                <button
                  disabled={!active}
                  onClick={(e) => {
                    e.preventDefault();
                    setRedirectPage(true);
                  }}
                  type="button"
                  className={`location-button ml-2 ${
                    active
                      ? "pointer location-background-active"
                      : "location-background-inactive"
                  }`}
                >
                  {t("ship.confirm-location")}
                </button>
                <Link
                  to={
                    "/" +
                    props.countryUrl +
                    "-" +
                    props.language +
                    "/select-address"
                  }
                >
                  <button type="button" className=" ml-3 back-button">
                    {t("ship.back")}
                  </button>
                </Link>
              </div>
            </div>
          </div>
          <div className="shipping-address-page-footer">
            <ShippingFooter />
          </div>
        </div>
      ) : (
        <Redirect
          push
          to={"/" + props.countryUrl + "-" + props.language + "/cart"}
        />
      )}
      {redirectPage && (
        <Redirect
          push
          to={{
            pathname:
              "/" + props.countryUrl + "-" + props.language + "/address",
            state: undefined,
          }}
        />
      )}
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    cart_items: state.cart.cart_items,
    language: state.language.language,
    countryUrl: state.language.countryUrl,
  };
};

const mapDispatchToProps = { set_addresses };

export default connect(mapStateToProps, mapDispatchToProps)(ShippingAddress);
