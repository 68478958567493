import React, { Fragment, useEffect } from "react";
import { BsFillEyeFill, BsFillEyeSlashFill } from "react-icons/bs";
import { Button, Form, FormGroup, Input, Row } from "reactstrap";
import $ from "jquery";

import Logo from "../../assets/images/logo.png";
import Facebook from "../../assets/icons/Icon awesome-facebook-square@2x.png";
import Google from "../../assets/icons/2659939281579738432-512@2x.png";
import "../../assets/styles/user/login.scss";
import { connect } from "react-redux";
import { useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ForgetModal from "../modal/ForgetModal.js";
import { set_user } from "../../redux/actions/AuthAction";
import ClipLoader from "react-spinners/ClipLoader";
import { Link, Redirect } from "react-router-dom";

import { useTranslation } from "react-i18next";
import { set_direction } from "../../redux/actions/LayoutAction.js";
import { HiArrowNarrowRight, HiArrowNarrowLeft } from "react-icons/hi";
import { apiJson } from "../../Api";

const Login = (props) => {
  const { t, i18n } = useTranslation();
  const [showModal, setshowModal] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [redirect, setRedirect] = useState(false);
  const [data, setData] = useState(false);
  let message = "";
  const [showPass, setShowPass] = useState(false);

  const showError = () => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  const showSuccess = () => {
    toast.success(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const handleAuthClick = function (provider) {
    $.auth.oAuthSignIn({ provider });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setData(true);
    let params = localStorage.getItem("user_key")
      ? {
          email,
          password,
          user_key: localStorage.getItem("user_key"),
          role: "customer",
        }
      : {
          email,
          password,
          role: "customer",
        };
    $.auth
      .emailSignIn(params)
      .then((response) => {
        localStorage.removeItem("user_key");
        localStorage.removeItem("guest_user_address");
        localStorage.setItem(
          "access_token",
          $.auth.retrieveData("authHeaders")["access-token"]
        );
        localStorage.setItem(
          "client",
          $.auth.retrieveData("authHeaders").client
        );
        localStorage.setItem("uid", $.auth.retrieveData("authHeaders").uid);

        props.set_user({ isSignedIn: true, user: $.auth.user });
        setData(false);
        setRedirect(true);
      })
      .fail((error) => {
        setData(false);
        if (error.data?.errors?.base) {
          message = error.data?.errors?.full_messages[0];
          showError();
        } else if (error.data?.errors) {
          message = error.data?.errors[0];
          showError();
        } else if (error?.data?.status === "password_reset_required") {
          message = t("forget-pw-mob.success-message");
          showSuccess();
        } else {
          message = t("errors.network");
          showError();
        }
      });
  };

  var getCookies = function () {
    var pairs = document.cookie?.split(";");
    var cookies = {};
    for (var i = 0; i < pairs.length; i++) {
      var pair = pairs[i].split("=");
      cookies[(pair[0] + "").trim()] = unescape(pair.slice(1).join("="));
    }
    return cookies;
  };

  useEffect(() => {
    if (
      getCookies()?.authHeaders &&
      $?.auth?.retrieveData("authHeaders")?.client
    ) {
      apiJson.auth
        .validateLoginToken({
          headers: {
            client: $?.auth?.retrieveData("authHeaders")?.client,
            "access-token":
              $?.auth?.retrieveData("authHeaders")["access-token"],
            uid: $?.auth?.retrieveData("authHeaders")?.uid,
          },
        })
        .then((response) => {
          const role = response?.data?.data?.roles?.filter(
            (role) => role === "customer"
          )?.length;
          if (response.success && role > 0) {
            localStorage.removeItem("user_key");
            localStorage.setItem(
              "access_token",
              $.auth.retrieveData("authHeaders")["access-token"]
            );
            localStorage.setItem(
              "client",
              $.auth.retrieveData("authHeaders").client
            );
            localStorage.setItem("uid", $.auth.retrieveData("authHeaders").uid);

            props.set_user({
              isSignedIn: true,
              user: response.data.data,
            });
            setRedirect(true);
          } else {
            props.set_user({ isSignedIn: false, user: {} });
            localStorage.removeItem("access_token");
            localStorage.removeItem("client");
            localStorage.removeItem("uid");
            document.cookie =
              "authHeaders=; path=/; expires=Thu, 21 Aug 2014 20:00:00 UTC";
          }
        });
    }
    // eslint-disable-next-line
  }, []);

  const changeLan = (lang) => {
    i18n.changeLanguage(lang);
    props.set_direction(lang);
  };

  useEffect(() => {
    document.title = "Login";
  }, []);

  return (
    <Fragment>
      <div className="bg-white page-wraper">
        <div className="user-login mx-auto">
          {!props.address && (
            <Row className="w-90 ml-auto mr-auto justify-content-between align-items-center no-gutters">
              <div
                onClick={() => setRedirect(true)}
                className="pointer user-login-logo d-inline-flex flex-row align-items-center"
              >
                <span>
                  {props.lang === "en" ? (
                    <HiArrowNarrowLeft size={21} />
                  ) : (
                    <HiArrowNarrowRight />
                  )}
                </span>
                <span className="ml-2 fs-headers-13">
                  {t("checkout-mobile.home")}
                </span>
              </div>
              <div className="pointer user-login-logo ">
                {props.lang === "en" ? (
                  <div
                    className="fs-headers-13"
                    onClick={() => changeLan("ar")}
                  >
                    العربية
                  </div>
                ) : (
                  <div
                    className="fs-headers-13"
                    onClick={() => changeLan("en")}
                  >
                    ENGLISH
                  </div>
                )}
              </div>
            </Row>
          )}
          <Row className=" no-gutters">
            <div
              onClick={() => setRedirect(true)}
              className="pointer user-login-logo mx-auto"
            >
              {props.logo && <img src={Logo} alt="logo" width="250px" />}
            </div>
          </Row>
          <Row className="no-gutters">
            <div className="user-login-form-container mx-auto">
              {props.welcome && (
                <div className="user-welcome-text">{props.welcome}</div>
              )}

              <div className="user-text-bold">{props.signInTitle}</div>
              <div className="user-no-account-text pointer">
                {t("login.no-account")}
                {props.lang === "en" && (
                  <span className="font-family-arial">? </span>
                )}
                {!props.address && (
                  <Link to="/signup">{t("login.sign-up")}</Link>
                )}
                {props.address && (
                  <span
                    onClick={() => props.signupClicked(true)}
                    className="blue"
                  >
                    {t("login.sign-up")}
                  </span>
                )}
              </div>
              <Form className="user-login-form">
                <FormGroup>
                  <Input
                    className="user-login-email-input px-2"
                    type="email"
                    name="email"
                    id="user-login-email"
                    placeholder={t("login.email-placeholder")}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </FormGroup>
                <FormGroup className="mb-3">
                  <Input
                    className="user-login-password-input px-2"
                    type={`${showPass ? "text" : "password"}`}
                    name="password"
                    id="user-login-password"
                    placeholder={t("login.password-placeholder")}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  {showPass ? (
                    <BsFillEyeFill
                      cursor="pointer"
                      onClick={() => setShowPass(!showPass)}
                      className={
                        (props.direction.direction === "RTL" &&
                          "icon-position-rtl") ||
                        (props.direction.direction === "LTR" &&
                          "icon-position-ltr")
                      }
                      size="14px"
                      color="rgb(126, 133, 155)"
                    />
                  ) : (
                    <BsFillEyeSlashFill
                      cursor="pointer"
                      onClick={() => setShowPass(!showPass)}
                      className={
                        (props.direction === "RTL" &&
                          "eye-icon icon-position-rtl") ||
                        (props.direction === "LTR" &&
                          "eye-icon icon-position-ltr")
                      }
                      size="14px"
                      color="rgb(126, 133, 155)"
                    />
                  )}
                </FormGroup>
                <div className="user-forget-password-text">
                  <div
                    className="pointer forget-text"
                    onClick={() => setshowModal(!showModal)}
                  >
                    {t("login.forget-password")}
                    {props.lang === "en" && (
                      <span className="font-family-arial">?</span>
                    )}
                  </div>
                  {showModal && (
                    <div className="modal-container">
                      <ForgetModal
                        onClose={() => setshowModal(false)}
                        title={t("forget-password.title")}
                      />
                    </div>
                  )}
                </div>
                <Button
                  onClick={handleSubmit}
                  type="submit"
                  className="user-login-form-button"
                  disabled={data}
                  style={{ height: "45px" }}
                >
                  {data ? (
                    <div className="d-flex justify-content-center align-items-center w-100">
                      <ClipLoader color="#fff" loading size={20} />
                    </div>
                  ) : (
                    t("login.btn-sign-in")
                  )}
                </Button>
              </Form>
            </div>
          </Row>
          {/* <Row className="no-gutters">
            <div className="horizental-line-with-text d-flex justify-content-between align-items-center mx-auto">
              <div className="line-before-or"></div>
              <div className="text-between-lines">{t("login.label-or")}</div>
              <div className="line-after-or"></div>
            </div>
          </Row>
          <Row className="no-gutters">
            <div className="login-social-buttons pointer  mx-auto">
              <div
                onClick={() => handleAuthClick("facebook")}
                className="login-with-facebook"
              >
                <img src={Facebook} alt="facebook" width="29px" height="29px" />
                {t("login.btn-with-fb")}
              </div>

              <div
                onClick={() => handleAuthClick("google")}
                className="login-with-google pointer"
              >
                <img src={Google} alt="google" width="21px" height="21px" />
                {t("login.btn-with-google")}
              </div>
            </div>
          </Row> */}
        </div>
      </div>{" "}
      {redirect && !props.address && (
        <Redirect push to={"/" + props.countryUrl + "-" + props.lang + "/"} />
      )}
    </Fragment>
  );
};

const mapDispatchToProps = {
  set_user,
  set_direction,
};
const mapStateToProps = (state) => {
  return {
    direction: state.language.direction,
    lang: state.language.language,
    countryUrl: state.language.countryUrl,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
