import React, { useEffect, useState } from "react";
import { Modal, Row } from "react-bootstrap";
import { set_loading } from "../../../redux/actions/LayoutAction";
import ProductCard from "./ProductCardMobile";
import MainSlider from "../../homepage/MainSlider";
import VendorMainSlider from "../../vendorShopPage/VendorMainSlider";
import { connect } from "react-redux";
import { set_home_categories } from "../../../redux/actions/HomeAction";
import { set_vendor_data } from "../../../redux/actions/VendorShopAction";
import { apiJson } from "../../../Api";
import { toast } from "react-toastify";
import { Link, Redirect, useParams } from "react-router-dom";
import MobileLayoutRenderer from "../../../pages/mobile/layout/MobileLayoutRenderer";
import { useTranslation } from "react-i18next";
import { set_country } from "../../../redux/actions/LayoutAction";
import { set_cart_items } from "../../../redux/actions/CartAction";
import { set_redirect_home } from "../../../redux/actions/NotFoundAction";
import "../../../assets/styles/component/mobile/Home/HomeMobile.scss";
import "react-toastify/dist/ReactToastify.css";
import { set_user } from "../../../redux/actions/AuthAction";
import OffersBanner from "../../../assets/images/offers-banner.png";
import YelloColorImage from "../../../assets/images/homepage/YelloColorImage.png";

import HeaderMobile from "../HeaderMobile";

import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';

function ShopPageMobile(props) {
  const [offersModalShow, setOffersModalShow] = React.useState(false);
  const [modalShow, setModalShow] = React.useState(true);
  let message = "";
  const { id } = useParams();
  const { t } = useTranslation();
  const [redirect, setRedirect] = useState({ value: false });
  const [middle_banners, setMiddleBanners] = useState([]);
  const [secondary_banners, setSecondaryBanners] = useState([]);
  const [menu_items, setMenuItems] = useState([]);
  const [logo, setLogo] = useState("");

  const showError = () => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  let sections = [];
  function shuffle(array) {
    let currentIndex = array.length,
      randomIndex;
    while (currentIndex !== 0) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex],
        array[currentIndex],
      ];
    }

    return array;
  }

  const setSection = (section, position, allProducts) => {
    switch (position) {
      case 2:
        props.set_home_categories({
          positionTwo:
            section?.data?.length < 1
              ? { ...section, data: allProducts }
              : { ...section, data: section.data },
        });
        break;
      case 3:
        props.set_home_categories({
          positionThree:
            section?.data?.length < 1
              ? { ...section, data: allProducts }
              : { ...section, data: section.data },
        });
        break;
      case 4:
        props.set_home_categories({
          positionFour:
            section?.data?.length < 1
              ? { ...section, data: allProducts }
              : { ...section, data: section.data },
        });
        break;
      case 5:
        props.set_home_categories({
          positionFive:
            section?.data?.length < 1
              ? { ...section, data: allProducts }
              : { ...section, data: section.data },
        });
        break;
      default:
        sections.push(section);
    }
  };

  useEffect(() => {
    document.title = "Alisuq";
    props.set_loading(true);
    props.redirect && props?.set_redirect_home(false);
    (localStorage.getItem("access_token") ||
      localStorage.getItem("user_key")) &&
      apiJson.cart.getCartItems(props.countryId).then((response) => {
        if (response.success) {
          props.set_cart_items({ cart_items: response.data });
        } else if (!response.status) {
          // eslint-disable-next-line
          message = response.message
            ? response.message !== ""
              ? response.message
              : t("errors.wrong")
            : t("errors.wrong");
          showError();
        } else {
          if (response.status >= 400 && response.status < 500) {
            message = response.message
              ? response.message
              : response.errors
                ? response.errors
                : t("errors.wrong");
            showError();
          } else {
            message = response.message
              ? response.message !== ""
                ? response.message
                : t("errors.wrong")
              : t("errors.wrong");
            showError();
          }
        }
      });
    if (props.screen === "home") {
      apiJson.homepage.getBanners(props.countryId).then((response) => {
        if (response.success) {
          let primaryBanners = [];
          let secondaryBanners = [];

          response.data.map((banner) =>
            banner.banner_type === "platform_primary"
              ? primaryBanners.push(banner)
              : secondaryBanners.push(banner)
          );
          props.set_home_categories({
            primaryBanners: primaryBanners,
            secondaryBanners: secondaryBanners,
          });
          setSecondaryBanners(secondaryBanners);
          props.set_loading(false);
        } else if (!response.status) {
          props.set_loading(false);
          message = response.message
            ? response.message !== ""
              ? response.message
              : t("errors.wrong")
            : t("errors.wrong");
          showError();
        } else {
          props.set_loading(false);
          if (response.status >= 400 && response.status < 500) {
            message = response.message
              ? response.message
              : response.errors
                ? response.errors
                : t("errors.wrong");
            showError();
          } else {
            message = response.message
              ? response.message !== ""
                ? response.message
                : t("errors.wrong")
              : t("errors.wrong");
            showError();
          }
        }
      });
      apiJson.homepage.getCategories(props.countryId).then((response) => {
        if (response.success) {
          props.set_home_categories({ categories: response.data });
        } else if (!response.status) {
          message = response.message
            ? response.message !== ""
              ? response.message
              : t("errors.wrong")
            : t("errors.wrong");
          showError();
        } else {
          if (response.status >= 400 && response.status < 500) {
            message = response.message
              ? response.message
              : response.errors
                ? response.errors
                : t("errors.wrong");
            showError();
          } else {
            message = response.message
              ? response.message !== ""
                ? response.message
                : t("errors.wrong")
              : t("errors.wrong");
            showError();
          }
        }
      });
      let allProducts = [];

      apiJson.homepage.getFirstSection(props.countryId).then((response) => {
        if (response.success) {
          const temp = {
            title_en: response.data.section.title_en,
            title_ar: response.data.section.title_ar,
            data: [
              ...response.data.section.products,
              ...response.data.section.services,
            ],
          };
          props.set_home_categories({
            positionOne: {
              title_en: response.data.section.title_en,
              title_ar: response.data.section.title_ar,
              data: [
                ...response.data.section.products,
                ...response.data.section.services,
              ],
            },
          });

          let i = 0;
          response.data.section_ids?.map((id) =>
            apiJson.homepage
              .getSections(id, props.countryId)
              .then((section) => {
                ++i;
                if (i === 1) {
                  temp?.data?.length < 1 &&
                    props.set_home_categories({
                      positionOne: { ...temp, data: allProducts },
                    });
                }
                if (section.success) {
                  section.data.title_en !== "Weekly Sale 1" &&
                    section.data.title_en !== "Weekly Sale 2" &&
                    section.data.title_en !== "Combo Sale 1" &&
                    section.data.title_en !== "Combo Sale 2" &&
                    section.data.title_en !== "Brand Favourites 1" &&
                    section.data.title_en !== "Brand Favourites 2" &&
                    section.data.title_en !== "Brand Favourites 3" &&
                    section.data.title_en !== "Offers" &&
                    setSection(
                      {
                        title_en: section.data.title_en,
                        title_ar: section.data.title_ar,
                        data: [
                          ...section.data.products,
                          ...section.data.services,
                        ],
                      },

                      section.data.position ? section.data.position : 7,
                      shuffle(allProducts)
                    );
                  if (i === response.data.section_ids.length) {
                    props.set_home_categories({
                      sections: sections,
                    });
                  }
                }
              })
          );
        }
      });

      apiJson.allitems
        .getItems(props.countryId, "products", 1, 10)
        .then((response) => {
          response.success
            ? (allProducts = response.data.products)
            : (allProducts = []);
        });

      apiJson.cart.guestUser(props.countryId).then((response) => {
        if (response.success) {
          props.set_user({
            guestUser: response.data.platform_settings.guest_user_checkout,
            guestSignin: false,
          });
        } else {
          props.set_user({
            guestUser: false,
          });
        }
      });
    } else {
      apiJson.vendorShop.getData(props.countryId, id).then((response) => {
        if (response.success) {
          props.set_vendor_data({
            products: response.data.products,
            services: response.data.services,
            secondaryBanners: response.data.banners.filter(
              (banner) => banner.banner_type === "store_secondary"
            ),
            primaryBanners: response.data.banners.filter(
              (banner) => banner.banner_type === "store_primary"
            ),
          });
        } else if (!response.status) {
          message = response.message
            ? response.message !== ""
              ? response.message
              : t("errors.wrong")
            : t("errors.wrong");
          showError();
        } else {
          if (response.status >= 400 && response.status < 500) {
            message = response.message
              ? response.message
              : response.errors
                ? response.errors
                : t("errors.wrong");
            showError();
          } else {
            message = t("errors.wrong");
            showError();
          }
        }
      });
      apiJson.vendorShop.getMenu(id)
      .then(response => {
        if (response.success) {
          setMenuItems(response.data.menu_items);
        }
      });
      apiJson.homepage.getCategories(props.countryId).then((response) => {
        if (response.success) {
          props.set_home_categories({ categories: response.data });
        } else if (!response.status) {
          message = response.message
            ? response.message !== ""
              ? response.message
              : t("errors.wrong")
            : t("errors.wrong");
          showError();
        } else {
          if (response.status >= 400 && response.status < 500) {
            message = response.message
              ? response.message
              : response.errors
                ? response.errors
                : t("errors.wrong");
            showError();
          } else {
            message = response.message
              ? response.message !== ""
                ? response.message
                : t("errors.wrong")
              : t("errors.wrong");
            showError();
          }
        }
      });
      apiJson.vendorShop.getMiddleBanners(id)
        .then(response => {
          if (response.success) {
            setMiddleBanners(response.data.banners);
          }
        });
    }
  }, [props.countryId]);

  // eslint-disable-next-line
  function getMobileOperatingSystem() {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
      return "Windows Phone";
    }

    if (/android/i.test(userAgent)) {
      return "Android";
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return "iOS";
    }

    return "unknown";
  }

  return (
    <MobileLayoutRenderer
      NavbarTop={false}
      BackBtn={false}
      CancelBtn={false}
      NavbarBottom={true}
    >
      <div className="home-mob">
        <HeaderMobile />

        <div className="body" style={{ background: "#F5F5F5" }}>
          {props.screen === "vendor" ? <VendorMainSlider /> : <MainSlider />}

          <Navbar bg="light" expand="lg">
            <Container fluid>
              <Navbar.Brand href="#">
                <img src={logo} height="50px" width="auto" />
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="navbarScroll" />
              <Navbar.Collapse id="navbarScroll">
                <Nav
                  className="me-auto my-2 my-lg-0"
                  style={{ maxHeight: '100px' }}
                  navbarScroll
                >
                  {/* <Form className="d-flex">
                    <Form.Control
                      type="search"
                      placeholder="Search"
                      className="me-2"
                      aria-label="Search"
                    />
                    <Button variant="outline-success">Search</Button>
                  </Form> */}
                  {
                    menu_items?.map(item => {
                      if (item.sub_vendor_menu_items?.length > 0) {
                        return (
                          <NavDropdown title={item.name_en} id="navbarScrollingDropdown">
                            {
                              item.sub_vendor_menu_items.map(sub_item => {
                                return (
                                  <>
                                    <Link
                                      to={{
                                        pathname: "/" + sub_item.link.split("?")[0],
                                        search: sub_item.link.split("?")[1]
                                      }}
                                      className="dropdown-item"
                                    >{sub_item.name_en}</Link>
                                    <NavDropdown.Divider />
                                  </>
                                )
                              })
                            }
                          </NavDropdown>
                        )
                      }
                      else {
                        return (
                          <Link
                            to={{
                              pathname: "/" + item.link.split("?")[0],
                              search: item.link.split("?")[1]
                            }}
                            className="nav-link store-menu"
                          >{item.name_en}</Link>
                        )
                      }
                    })
                  }
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
          {/* <div className="emptyspacermob"></div>

          <div className="mt-2 ml-2 mr-2">
            {Object.entries(props.categories).length !== 0 && (
              <Swiper
                slidesPerView={4} // or 'auto'
                slidesPerColumn={2}
                slidesPerColumnFill="row"
                spaceBetween={3}
                pagination={{
                  clickable: true,
                }}
                navigation
              >
                <SwiperSlide
                  onClick={(e) => e.preventDefault()}
                  key="0"
                >
                  <Link
                    key="0"
                    style={{ textDecoration: "none" }}
                    to={{
                      pathname:
                        "/" +
                        props.countryUrl +
                        "-" +
                        props.language +
                        "/offers"
                    }}
                  >
                    <div className="img-cont">
                      <div className="imgofr">
                        <h3><span></span>Alisuq Exclusive</h3>
                        <span className="ofrtxt">Offers</span>
                      </div>
                    </div>
                  </Link>
                </SwiperSlide>
                {props.categories.slice(0, 100).map((ele, index) => {
                  return (
                    <SwiperSlide
                      onClick={(e) => e.preventDefault()}
                      key={ele.id}
                    >
                      {
                        ele.position >= 11 && (
                          <Link
                            key={ele.id}
                            style={{ textDecoration: "none" }}
                            to={{
                              pathname:
                                "/" +
                                props.countryUrl +
                                "-" +
                                props.language +
                                "/items/products",
                              search: "?filter_by_category%5B%5D=" + ele.id
                              // state: {
                              //   category:
                              //     ele.sub_categories?.length > 0
                              //       ? [
                              //         ele.id,
                              //         ...ele.sub_categories.map(
                              //           (subCat) => subCat.id
                              //         ),
                              //       ]
                              //       : [ele.id],
                              // },
                            }}
                          >
                            <div className="img-cont">
                              <div className="img">
                                <img
                                  src={ele.image}
                                  width="100%"
                                  height="100%"
                                  alt=""
                                  className="img-cat"
                                />
                              </div>
                            </div>
                          </Link>
                        )
                      }
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            )}
          </div> */}

          <div className="emptyspacermob"></div>
          <div className="emptyspacermob"></div>
          <div className="products-slider-1">
            <div className="product-cont mt-3">
              {
                props.products.length > 0 ?
                  props.products?.map((data) => (
                    <Link
                      style={{ textDecoration: "none" }}
                      to={{
                        pathname:
                          "/" +
                          props.countryUrl +
                          "-" +
                          props.language +
                          `/${data.category_title_en
                            .toLowerCase()
                            .replace(/ /g, "-")}/${data.slug}`,
                        state: {
                          id: data.id,
                          type: data.variants ? "products" : "services",
                        },
                      }}
                      key={data.id + Math.random()}
                    >
                      <div
                        className="image-cont"
                        key={data.id + Math.random() + "prod"}
                      >
                        <ProductCard
                          key={data.id + Math.random()}
                          // img={getMobileImage(
                          //   data.variants
                          //     ? data.variants[0].featured_image_resized
                          //     : data.featured_image_resized,
                          //   data.variants
                          //     ? data.variants[0].featured_image
                          //     : data.featured_image
                          // )}
                          img={
                            data.variants
                              ? data.variants[0].featured_image
                              : data.featured_image
                          }
                          storeLogo={data.store_logo}
                          wishlisted={
                            data.variants
                              ? data.variants[0].wishlisted
                              : data.wishlisted
                          }
                          home={true}
                          type={data.variants ? "products" : "services"}
                          id={data.variants ? data.variants[0].id : data.id}
                          rating={data.average_rating}
                          reviews={data.reviews_count}
                          name={data[`name_${props.language}`]}
                          data={data.variants ? data.variants[0] : data}
                          coupon={data.coupon}
                          is_inquiry={data.is_inquiry}
                        />
                      </div>
                    </Link>
                  ))
                  :
                  <></>
              }
            </div>
          </div>
          <div className="emptyspacermob"></div>
          <div className="emptyspacermob"></div>
          <div className="ViewAllbtn mt-2">
            <Link to={{
              pathname: "/" + props.countryUrl +
                "-" + props.language +
                "/items/products",
              search: "?filter_by_store=" + id
            }}>
              {t("all-categories.view-all")}
            </Link>
          </div>
          <div className="emptyspacermob"></div>
          <div className="emptyspacermob"></div>
          <div className="emptyspacermob"></div>
          {
            middle_banners.length > 0 ?
              <>
                <div className="featured-images">
                  <div className="brands-row">
                    <Link
                      to={{
                        pathname: "/" + middle_banners[0].link.split("?")[0],
                        search: middle_banners[0].link.split("?")[1]
                      }}
                    >
                      <img className="homeofferimg"
                        src={middle_banners[0].image}
                      />
                    </Link>

                    {
                      middle_banners?.map((banner, key) => {
                        if (key != 0) {
                          return (
                            <Link
                              to={{
                                pathname: "/" + banner.link.split("?")[0],
                                search: banner.link.split("?")[1]
                              }}
                            >
                              <img className="homeofferimg"
                                src={banner.image}
                                alt="Cleaners"
                              />
                            </Link>
                          )
                        }
                      }
                      )
                    }
                  </div>
                </div>

                <div className="emptyspacer"></div>
              </>
              :
              <></>
          }

          <div className="banner-sec">
            {props.secondaryBanners[0]?.image ? (
              <div className="vendor-shop-banner">
                {props.secondaryBanners[0]?.redirects_to_link ? (
                  <a href={props.secondaryBanners[0]?.redirects_to_link}>
                    <img
                      src={props.secondaryBanners[0]?.image}
                      alt="KidFashion"
                      style={{
                        width: "100%",
                        height: "100%",
                        maxHeight: "100%",
                      }}
                    />
                  </a>
                ) : (
                  <img
                    src={props.secondaryBanners[0]?.image}
                    alt="KidFashion"
                    style={{
                      width: "100%",
                      height: "100%",
                      maxHeight: "100%",
                    }}
                  />
                )}
              </div>
            ) : (
              <div className="vendor-shop-banner">
                <img
                  src={YelloColorImage}
                  alt="banner"
                  style={{
                    width: "100%",
                    height: "100%",
                    maxHeight: "100%",
                  }}
                />
              </div>
            )}

          </div>

        </div>
        {redirect.value && (
          <Redirect
            push
            to={{ pathname: redirect.path, state: redirect.state }}
          />
        )}
      </div>
    </MobileLayoutRenderer>
  );
}

function OffersModal(props) {
  return (
    <Modal
      {...props}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="offers-modal"
      style={{ padding: "20px", height: "auto", position: "absolute", top: "30%" }}
    >
      <Modal.Body>
        <Link to="/uae-en/electrical-accessories-sale">
          <img src={OffersBanner} className="img-fluid" width="100%" height="100%" />
        </Link>
      </Modal.Body>
    </Modal>
  )
}

const mapStateToProps = (state) => {
  return {
    categories: state.home.categories,
    products: state.vendor.products,
    services: state.vendor.services,
    sections: state.home.sections,
    positionOne: state.home.positionOne,
    positionTwo: state.home.positionTwo,
    positionThree: state.home.positionThree,
    positionFour: state.home.positionFour,
    positionFive: state.home.positionFive,
    load: state.layout.loading,
    countryId: state.language.countryId,
    language: state.language.language,
    country: state.language.country,
    countryUrl: state.language.countryUrl,
    redirect: state.notFound.redirect,
    lang: state.language.language,
    cart_items: state.cart.cart_items,
    countryUrl: state.language.countryUrl,
    cart_items_length: state.cart.cart_items_length,
    primaryBanners: state.vendor.primaryBanners,
    secondaryBanners: state.vendor.secondaryBanners,
  };
};

const mapDispatchToProps = {
  set_vendor_data,
  set_home_categories,
  set_country,
  set_cart_items,
  set_redirect_home,
  set_loading,
  set_user,
};

export default connect(mapStateToProps, mapDispatchToProps)(ShopPageMobile);
