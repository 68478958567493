import React, { Fragment } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { MdDelete } from "react-icons/md";
import { BiCartAlt } from "react-icons/bi";

import "../../../assets/styles/customer/cart/wishlist/wishlistcard.scss";
import { apiJson } from "../../../Api";
import ClipLoader from "react-spinners/ClipLoader";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useState } from "react";
import { set_cart_items } from "../../../redux/actions/CartAction";
import { connect } from "react-redux";
import moment from "moment";
import { useTranslation } from "react-i18next";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";

import { getDesktopImage } from "../../../utils/utils";

import "react-datetime/css/react-datetime.css";

const WishListCard = (props) => {
  const { t } = useTranslation();
  const { product } = props;
  const [removing, setRemoving] = useState(false);
  const [adding, setAdding] = useState(false);
  const [startDate, setStartDate] = useState(
    new Date(new Date().setSeconds(0))
  );
  const [endDate, setEndDate] = useState(
    new Date(new Date(Date.now() + 3600 * 1000 * 24).setSeconds(0))
  );
  let message = "";
  function renderDay(props, currentDate, selectedDate) {
    return (
      <td style={{ fontSize: "13px" }} {...props}>
        {currentDate.date()}
      </td>
    );
  }
  function renderMonth(props, month, year, selectedDate) {
    return <td {...props}>{month}</td>;
  }
  function renderYear(props, year, selectedDate) {
    return <td {...props}>{year % 100}</td>;
  }

  const showError = () => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  const showSucces = () => {
    toast.success(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const addToCart = () => {
    setAdding(true);
    apiJson.cart
      .addToCart(
        product.orderable_type === "Product"
          ? {
              line_item: {
                quantity: 1,
                orderable_type: "Variant",
                orderable_id: product.line_item.id,
              },
            }
          : {
              line_item: {
                orderable_type: "Service",
                orderable_id: product.line_item.id,
                start_at: new Date(startDate).toString(),
                end_at: new Date(endDate).toString(),
              },
            },
        props.countryId
      )
      .then((response) => {
        setAdding(false);
        if (response.success) {
          props.set_cart_items({
            cart_items: response.data,
          });
          message = "added to your cart";
          showSucces();
        } else if (!response.status) {
          message = t("errors.wrong");
          showError();
        } else {
          if (response.status >= 400 && response.status < 500) {
            message = response.message
              ? response.message
              : response.errors
              ? response.errors
              : t("errors.wrong");
            showError();
          } else {
            message = response.message
              ? response.message !== ""
                ? response.message
                : t("errors.wrong")
              : t("errors.wrong");
            showError();
          }
        }
      });
  };

  const removeFromWishlist = () => {
    setRemoving(true);
    apiJson.cart
      .moveToWishlist(
        {
          wishlist_item: {
            orderable_type:
              product.orderable_type === "Product" ? "Variant" : "Service",
            orderable_id: product.line_item.id,
          },
        },
        props.countryId
      )
      .then((response) => {
        setRemoving(false);
        if (response.success) {
          props.set_cart_items({
            wish_list: props.wishlist.filter(
              (item) =>
                item.line_item.id !== product.line_item.id ||
                item.orderable_type !== product.orderable_type
            ),
          });
        } else if (!response.status) {
          message = t("errors.wrong");
          showError();
        } else {
          if (response.status >= 400 && response.status < 500) {
            message = response.message
              ? response.message
              : response.errors
              ? response.errors
              : t("errors.wrong");
            showError();
          } else {
            message = response.message
              ? response.message !== ""
                ? response.message
                : t("errors.wrong")
              : t("errors.wrong");
            showError();
          }
        }
      });
  };

  return (
    <Fragment>
      <Row className="wishlist-card-container-desktop no-gutters mb-3">
        <Col md={2} className="main">
          <div className="image-container text-center">
            <img
              className="image"
              src={getDesktopImage(
                product.line_item.featured_image_resized,
                product.line_item.featured_image
              )}
              alt="product"
              width="auto"
              height="auto"
            />
          </div>
        </Col>
        <Col md={8} className="details-container">
          <div className="brand-and-price">
            <span className="brand">
              {product.line_item[`brand_name_${props.language}`]}
            </span>
          </div>
          <div className="description">
            <p>
              {(product.orderable_type === "Product"
                ? product.line_item[`product_name_${props.language}`]
                : "") +
                " " +
                product.line_item[`name_${props.language}`]}
            </p>
          </div>
          {product.orderable_type === "Service" && (
            <div className="mt-1 mb-2 d-flex flex-column">
              <div className="date-heading mb-1">
                {t("item-detail.start-date") + ":"}
              </div>
              <div style={{ width: "200px" }} className="mb-2">
                <Datetime
                  onChange={(val) => {
                    if (
                      moment(new Date(val)).format("YYYY-MM-DDTHH:mm") <
                      moment(new Date(startDate)).format("YYYY-MM-DDTHH:mm")
                    ) {
                      message = "Start date can not be less than current date";
                      showError();
                    } else {
                      if (
                        moment(new Date(val)).format("YYYY-MM-DDTHH:mm") <
                        moment(new Date(endDate)).format("YYYY-MM-DDTHH:mm")
                      ) {
                        setStartDate(val);
                      } else {
                        message = "Start date should be less than end date";
                        showError();
                      }
                    }
                  }}
                  value={startDate}
                  renderDay={renderDay}
                  renderMonth={renderMonth}
                  renderYear={renderYear}
                />
              </div>
            </div>
          )}
          {product.orderable_type === "Service" && (
            <div className="mt-1 mb-2 d-flex flex-column">
              <div className="date-heading mb-1">
                {t("item-detail.end-date") + ":"}
              </div>
              <div style={{ width: "200px" }} className="mb-2">
                <Datetime
                  onChange={(val) => {
                    if (
                      moment(new Date(val)).format("YYYY-MM-DDTHH:mm") >
                      moment(new Date(startDate)).format("YYYY-MM-DDTHH:mm")
                    ) {
                      setEndDate(val);
                    } else {
                      message = "End date should be greater than start date";
                      showError();
                    }
                  }}
                  value={endDate}
                  renderDay={renderDay}
                  renderMonth={renderMonth}
                  renderYear={renderYear}
                />
              </div>
            </div>
          )}
          <div className="sold-by">
            <span>{t("cart-page.sold-by")} </span>
            <span className="ml-1 company">
              {product.line_item[`store_name_${props.language}`]}
            </span>
          </div>
          <div className="buttons">
            <button
              onClick={(e) => {
                e.preventDefault();
                addToCart();
              }}
              type="button"
              className="bg-white d-flex align-items-center mb-1"
            >
              <BiCartAlt className="mb-1" />
              <span className="small-text ml-1">
                {adding ? (
                  <div className="d-flex justify-content-center align-items-center w-100">
                    <ClipLoader color="#000" loading size={20} />
                  </div>
                ) : (
                  t("cart-page.move-to-cart")
                )}
              </span>
            </button>
            <button
              onClick={(e) => {
                e.preventDefault();
                removeFromWishlist();
              }}
              type="button"
              className="bg-white d-flex align-items-center mb-1"
            >
              <MdDelete className="mb-1" />
              <span className="small-text ml-1">
                {removing ? (
                  <div className="d-flex justify-content-center align-items-center w-100">
                    <ClipLoader color="#000" loading size={20} />
                  </div>
                ) : (
                  t("cart-page.remove")
                )}
              </span>
            </button>
          </div>
        </Col>
        <Col md={2}>
          <p className="price d-flex align-items-end mb-0">
            <span className="currency">{props.currency} </span>
            <span className="amount"> {product.discounted_price}</span>
          </p>
        </Col>
      </Row>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    cart_items: state.cart.cart_items,
    countryId: state.language.countryId,
    language: state.language.language,
  };
};

const mapDispatchToProps = {
  set_cart_items,
};

export default connect(mapStateToProps, mapDispatchToProps)(WishListCard);
