import React, { Fragment, useEffect, useState } from "react";
import "../../assets/styles/component/mobile/paymentmethodmob.scss";
import { CgCreditCard } from "react-icons/cg";
import MasterCard from "../../assets/icons/card_2.png";
import VisaCard from "../../assets/icons/visaCard.png";
import { ReactComponent as Checked } from "../../assets/icons/Checked.svg";
import { ReactComponent as Unchecked } from "../../assets/icons/Unchecked.svg";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { apiJson } from "../../Api";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import ClipLoader from "react-spinners/ClipLoader";
import moment from "moment";

const PaymentMethodMob = (props) => {
  const [showWallet, setShowWallet] = useState(false);
  const [cardStatus, setCardStatus] = useState({
    wallet_payment_option: false,
    card_payment_option: false,
    cash_on_delivery_payment_option: false,
  });
  const [walletAmount, setWalletAmount] = useState(0);
  const [card, setCard] = useState({});

  const { t } = useTranslation();
  const addingCard = false;

  const [cardNum, setCardNum] = useState(null);
  const [cardExpiry, setExpiry] = useState(null);
  const [cardExpiryMonth, setCardExpiryMonth] = useState(null);
  const [cardExpiryYear, setCardExpiryYear] = useState(null);
  const [cardCode, setCode] = useState(null);
  let message = "";
  const showError = () => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const addCard = () => {
    if (
      cardNum &&
      cardNum?.length === 16 &&
      cardExpiryMonth && cardExpiryYear &&
      cardCode &&
      cardCode?.length === 3
    ) {
      setCard({
        card_number: cardNum,
        expiry_date: cardExpiryMonth + "/" + cardExpiryYear,
        // cardExpiry.split("-")[1] + "/" + cardExpiry.split("-")[0].slice(2, 4),
        cvv: cardCode,
        id: -1,
      });
    } else {
      message = "Please add valid card  details";
      showError();
    }
  };

  useEffect(() => {
    if (props.auth) {
      apiJson.checkout.paymentCard().then((response) => {
        if (response.success) {
          setShowWallet(response.data.wallet_payment_option);
          setCardStatus({
            ...cardStatus,
            card_payment_option: props.address?.length && props.address?.filter(address => address.default == true).length && response.data.card_payment_option,
            cash_on_delivery_payment_option: response.data.card_payment_option
              ? false
              : response.data.cash_on_delivery_payment_option,
          });
        } else if (!response.status) {
          // eslint-disable-next-line
          message = response.message
            ? response.message !== ""
              ? response.message
              : t("errors.wrong")
            : t("errors.wrong");
          showError();
        } else {
          if (response.status >= 400 && response.status < 500) {
            message = response.message
              ? response.message
              : response.errors
                ? response.errors
                : t("errors.wrong");
            showError();
          } else {
            message = response.message
              ? response.message !== ""
                ? response.message
                : t("errors.wrong")
              : t("errors.wrong");
            showError();
          }
        }
      });

      apiJson.payments.getPaymentData().then((response) => {
        if (response.success) {
          response.data.map(
            (card) =>
              card.default &&
              parseInt(
                moment(
                  new Date(
                    card.expiry_date.split("/")[0] +
                    "/01/" +
                    card.expiry_date.split("/")[1]
                  )
                ).diff(moment(), "months", true)
              ) > -1 &&
              setCard(card)
          );
        } else if (!response.status) {
          message = response.message
            ? response.message !== ""
              ? response.message
              : t("errors.wrong")
            : t("errors.wrong");
          showError();
        } else {
          if (response.status >= 400 && response.status < 500) {
            message = response.message
              ? response.message
              : response.errors
                ? response.errors
                : t("errors.wrong");
            showError();
          } else {
            message = response.message
              ? response.message !== ""
                ? response.message
                : t("errors.wrong")
              : t("errors.wrong");
            showError();
          }
        }
      });

      apiJson.checkout.walletPayment(props.countryId).then((response) => {
        if (response.success) {
          setWalletAmount(response.data);
        } else if (!response.status) {
          message = response.message
            ? response.message !== ""
              ? response.message
              : t("errors.wrong")
            : t("errors.wrong");
          showError();
        } else {
          if (response.status >= 400 && response.status < 500) {
            message = response.message
              ? response.message
              : response.errors
                ? response.errors
                : t("errors.wrong");
            showError();
          } else {
            message = response.message
              ? response.message !== ""
                ? response.message
                : t("errors.wrong")
              : t("errors.wrong");
            showError();
          }
        }
      });
    } else {
      setCardStatus({
        wallet_payment_option: false,
        card_payment_option: props.address?.length && props.address?.filter(address => address.default == true).length ? true : false,
        cash_on_delivery_payment_option: false,
      });
    }
  }, []);
  useEffect(() => props.cardData(card), [card]); // eslint-disable-line

  useEffect(
    () =>
      props.wallet({
        flag: cardStatus.wallet_payment_option,
        cost: walletAmount.amount,
      }),
    [cardStatus?.wallet_payment_option, walletAmount.amount] // eslint-disable-line
  );
  useEffect(
    () => props.card(cardStatus.card_payment_option),
    [cardStatus?.card_payment_option] // eslint-disable-line
  );
  useEffect(
    () => props.cod(cardStatus.cash_on_delivery_payment_option),
    [cardStatus?.cash_on_delivery_payment_option] // eslint-disable-line
  );
  useEffect(
    () => {
      if (props.auth) {
        apiJson.checkout.paymentCard().then((response) => {
          if (response.success) {
            setShowWallet(response.data.wallet_payment_option);
            setCardStatus({
              ...cardStatus,
              card_payment_option: props.address?.length && props.address?.filter(address => address.default == true).length && response.data.card_payment_option,
              cash_on_delivery_payment_option: response.data.card_payment_option
                ? false
                : response.data.cash_on_delivery_payment_option,
            });
          } else if (!response.status) {
            // eslint-disable-next-line
            message = response.message
              ? response.message !== ""
                ? response.message
                : t("errors.wrong")
              : t("errors.wrong");
            showError();
          } else {
            if (response.status >= 400 && response.status < 500) {
              message = response.message
                ? response.message
                : response.errors
                  ? response.errors
                  : t("errors.wrong");
              showError();
            } else {
              message = response.message
                ? response.message !== ""
                  ? response.message
                  : t("errors.wrong")
                : t("errors.wrong");
              showError();
            }
          }
        });

        apiJson.payments.getPaymentData().then((response) => {
          if (response.success) {
            response.data.map(
              (card) =>
                card.default &&
                parseInt(
                  moment(
                    new Date(
                      card.expiry_date.split("/")[0] +
                      "/01/" +
                      card.expiry_date.split("/")[1]
                    )
                  ).diff(moment(), "months", true)
                ) > -1 &&
                setCard(card)
            );
          } else if (!response.status) {
            message = response.message
              ? response.message !== ""
                ? response.message
                : t("errors.wrong")
              : t("errors.wrong");
            showError();
          } else {
            if (response.status >= 400 && response.status < 500) {
              message = response.message
                ? response.message
                : response.errors
                  ? response.errors
                  : t("errors.wrong");
              showError();
            } else {
              message = response.message
                ? response.message !== ""
                  ? response.message
                  : t("errors.wrong")
                : t("errors.wrong");
              showError();
            }
          }
        });

        apiJson.checkout.walletPayment(props.countryId).then((response) => {
          if (response.success) {
            setWalletAmount(response.data);
          } else if (!response.status) {
            message = response.message
              ? response.message !== ""
                ? response.message
                : t("errors.wrong")
              : t("errors.wrong");
            showError();
          } else {
            if (response.status >= 400 && response.status < 500) {
              message = response.message
                ? response.message
                : response.errors
                  ? response.errors
                  : t("errors.wrong");
              showError();
            } else {
              message = response.message
                ? response.message !== ""
                  ? response.message
                  : t("errors.wrong")
                : t("errors.wrong");
              showError();
            }
          }
        });
      } else {
        setCardStatus({
          wallet_payment_option: false,
          card_payment_option: props.address?.length && props.address?.filter(address => address.default == true).length ? true : false,
          cash_on_delivery_payment_option: false,
        });
      }
    },
    [props.address?.length && props.address?.filter(address => address.default == true).length]
  )
  useEffect(() => {
    if (!props.is_delivery) {
      setCardStatus({
        wallet_payment_option: false,
        card_payment_option: true,
        cash_on_delivery_payment_option: false
      })
    }
  }, [props.is_delivery]);

  return (
    <Fragment>
      <div className="main-container-payment-method-mobile">
        {props.auth && showWallet && (
          <>
            <label className="payment-method-label">
              {t("checkout-mobile.payment-method.title")}
            </label>
            <div
              className={`${cardStatus.wallet_payment_option ? "active" : "inactive"
                } pay-with-noon-pay`}
              onClick={() =>
                setCardStatus({
                  card_payment_option: !cardStatus.wallet_payment_option
                    ? false
                    : cardStatus.card_payment_option,
                  cash_on_delivery_payment_option:
                    !cardStatus.wallet_payment_option
                      ? false
                      : cardStatus.cash_on_delivery_payment_option,
                  wallet_payment_option: !cardStatus.wallet_payment_option,
                })
              }
            >
              {cardStatus.wallet_payment_option ? (
                <div className="checkbocs">
                  <Checked style={{ height: 27, width: 27 }} />
                </div>
              ) : (
                <div className="checkbocs">
                  <Unchecked style={{ height: 27, width: 27 }} />
                </div>
              )}
              <div className="pay-label-and-balance">
                <span className="pay-label">
                  {t("checkout-mobile.payment-method.noon-pay-text")}
                </span>
                <span className="balance">
                  {t("checkout-mobile.payment-method.available-bal") + " "}
                  {props.country.toLowerCase() === "saudi arabia"
                    ? "SAR"
                    : props.country.toLowerCase() === "united arab emirates"
                      ? "AED"
                      : "EGP"}{" "}
                  {walletAmount.amount}
                </span>
              </div>
            </div>
          </>
        )}
        <div
          className={`${cardStatus.wallet_payment_option &&
            parseFloat(walletAmount.amount) >=
            parseFloat(props.order.createdAddress ? props.order.createdAddress.grand_total : props.cart_items.sub_total)
            ? "d-none"
            : ""
            }`}
        >
          <div
            className={`${(props.address.length && props.address?.filter(address => address.default == true).length && cardStatus.card_payment_option) ? "active" : "inactive"
              } pay-with-card pt-3 mb-2`}
          >
            <div
              className="checkbox-pay-card-label-icon"
              onClick={() =>
                setCardStatus({
                  ...cardStatus,
                  card_payment_option: !cardStatus.card_payment_option,
                  cash_on_delivery_payment_option:
                    cardStatus.cash_on_delivery_payment_option
                      ? !cardStatus.cash_on_delivery_payment_option
                      : cardStatus.cash_on_delivery_payment_option,
                })
              }
            >
              {cardStatus.card_payment_option ? (
                <div className="checkbocs">
                  <Checked style={{ height: 27, width: 27 }} />
                </div>
              ) : (
                <div className="checkbocs">
                  <Unchecked style={{ height: 27, width: 27 }} />
                </div>
              )}
              <div className="pay-card-label-icon align-items-center">
                <span className="pay-label">
                  {t("checkout-mobile.payment-method.pay-with-card")}
                </span>
                <CgCreditCard size={30} className="card" />
              </div>
            </div>
            {card?.card_number ? (
              <div className="cards-div mb-3">
                <div className="radio-input">
                  <input
                    type="radio"
                    checked={cardStatus.card_payment_option}
                    className="radio"
                    readOnly
                  />
                </div>
                <div className="img-and-label-input">
                  <div className="img-and-label">
                    <img
                      src={
                        card?.payment_card_type === "Visa"
                          ? VisaCard
                          : card?.payment_card_type === "MasterCard"
                            ? MasterCard
                            : MasterCard
                      }
                      alt="mastercard-logo"
                      width="50px"
                      height="35px"
                    />
                    <label className="card-ending-in">
                      {t("checkout-mobile.payment-method.card-end")}{" "}
                      {card?.card_number?.slice(
                        card.card_number?.length - 4,
                        card.card_number?.length
                      )}
                    </label>
                  </div>
                </div>
              </div>
            ) :
              (
                (props.is_delivery && cardStatus.card_payment_option && props.address?.length && props.address?.filter(address => address.default == true).length)
                ||
                (!props.is_delivery && cardStatus.card_payment_option && props.order?.createdAddress?.pickup_address)
              ) ?
                (
                  <div className="payment-card-body">
                    <div className="d-flex flex-column mt-2 w-90 ml-auto mr-auto">
                      <label className="card-number">
                        {t("checkout.card-number")}
                      </label>
                      <input
                        className=""
                        placeholder="**** **** **** ****"
                        maxLength={16}
                        value={cardNum ? cardNum : ""}
                        type="number"
                        onChange={(e) =>
                          e.target.value.length < 17 && setCardNum(e.target.value)
                        }
                      />
                    </div>

                    <div className="d-flex w-90 m-auto">
                      <div className="d-flex flex-column mr-auto mt-2 mr-1">
                        <label className="text-uppercase">
                          {t("checkout.expiry-date")}
                        </label>
                        <div
                          className="expiry-input-div"
                          style={{ display: "flex" }}
                        >
                          <input className="expiry-input"
                            placeholder="MM"
                            value={cardExpiryMonth}
                            onChange={(e) => {
                              if (e.target.value?.toString()?.length <= 2 && (e.target.value >= 0 && e.target.value <= 12)) {
                                setCardExpiryMonth(e.target.value);
                              }
                            }
                            }
                          />
                          <div className="slash">/</div>
                          <input className="expiry-input"
                            placeholder="YY"
                            value={cardExpiryYear}
                            onChange={(e) => {
                              if (e.target.value?.toString()?.length <= 2) {
                                setCardExpiryYear(e.target.value)
                              }
                            }
                            }
                          />
                        </div>
                        {/* <input
                          className=""
                          type="month"
                          placeholder="MM/YY"
                          value={cardExpiry ? cardExpiry : ""}
                          min={
                            moment().year() +
                            "-" +
                            (moment().month() < 9
                              ? "0" + (moment().month() + 1)
                              : moment().month() + 1)
                          }
                          onChange={(e) => setExpiry(e.target.value)}
                        /> */}
                      </div>

                      <div className="cvv-input d-flex flex-column mr-auto mt-2 mb-4 ml-1">
                        <label className="text-uppercase">Cvv</label>
                        <div className="input-wrapper">
                          <input
                            className="w-100"
                            type="number"
                            maxLength={3}
                            placeholder="CVV"
                            value={cardCode ? cardCode : ""}
                            onChange={(e) =>
                              e.target.value.length < 4 && setCode(e.target.value)
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      onClick={
                        cardNum &&
                          // cardNum?.length === 16 &&
                          cardExpiryMonth && cardExpiryYear &&
                          cardCode
                          // && cardCode?.length === 3 
                          ?
                          addCard
                          :
                          () => { }
                      }
                      className={
                        "pointer cvv-input d-flex flex-column add-card w-90 ml-auto mr-auto mt-2 mb-4"
                        +
                        (
                          cardNum &&
                            // cardNum?.length === 16 &&
                            cardExpiryMonth && cardExpiryYear &&
                            cardCode
                            // && cardCode?.length === 3 
                            ?
                            " active-btn"
                            :
                            " inactive-btn"
                        )
                      }
                    >
                      {addingCard ? (
                        <div className="d-flex justify-content-center align-items-center w-100">
                          <ClipLoader color="#fff" loading size={20} />
                        </div>
                      ) : (
                        <span>{t("checkout-mobile.payment-method.add-card")}</span>
                      )}
                    </div>
                  </div>
                )
                :
                <></>
            }
          </div>
          {
            props.order.createdAddress ?
              props.order.createdAddress.grand_total <= 249 && props.is_delivery ?
                <div
                  className={`${cardStatus.cash_on_delivery_payment_option ? "active" : "inactive"
                    } pay-with-noon-pay d-flex align-items-center mt-2`}
                  onClick={() =>
                    setCardStatus({
                      ...cardStatus,
                      card_payment_option: cardStatus.card_payment_option
                        ? !cardStatus.card_payment_option
                        : cardStatus.card_payment_option,
                      cash_on_delivery_payment_option:
                        !cardStatus.cash_on_delivery_payment_option,
                    })
                  }
                >
                  {cardStatus.cash_on_delivery_payment_option ? (
                    <div className="checkbocs">
                      <Checked style={{ height: 27, width: 27 }} />
                    </div>
                  ) : (
                    <div className="checkbocs">
                      <Unchecked style={{ height: 27, width: 27 }} />
                    </div>
                  )}
                  <div className="pay-label-and-balance">
                    <span className="pay-label">
                      {t("checkout-mobile.payment-method.pay-with-cash")}
                    </span>
                  </div>
                </div>
                :
                <></>
              :
              props.cart_items.sub_total <= 249 ?
                <div
                  className={`${cardStatus.cash_on_delivery_payment_option ? "active" : "inactive"
                    } pay-with-noon-pay d-flex align-items-center mt-2`}
                  onClick={() =>
                    setCardStatus({
                      ...cardStatus,
                      card_payment_option: cardStatus.card_payment_option
                        ? !cardStatus.card_payment_option
                        : cardStatus.card_payment_option,
                      cash_on_delivery_payment_option:
                        !cardStatus.cash_on_delivery_payment_option,
                    })
                  }
                >
                  {cardStatus.cash_on_delivery_payment_option ? (
                    <div className="checkbocs">
                      <Checked style={{ height: 27, width: 27 }} />
                    </div>
                  ) : (
                    <div className="checkbocs">
                      <Unchecked style={{ height: 27, width: 27 }} />
                    </div>
                  )}
                  <div className="pay-label-and-balance">
                    <span className="pay-label">
                      {t("checkout-mobile.payment-method.pay-with-cash")}
                    </span>
                  </div>
                </div>
                :
                <></>
          }
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    cart_items: state.cart.cart_items,
    country: state.language.country,
    countryId: state.language.countryId,
    order: state.cart.order,
    auth: state.auth.isSignedIn,
    address: state.select.address,
  };
};

export default connect(mapStateToProps, null)(PaymentMethodMob);
