import React, { Fragment, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { apiJson } from "../../../Api";
import "../../../assets/styles/component/customer/checkout/ordersummary.scss";
import { useTranslation } from "react-i18next";

const OrderSummary = (props) => {
  const { t } = useTranslation();

  const suggestme = 100 - props.sub_total;
  const [walletAmount, setWalletAmount] = useState(0);
  let message = "";
  const showError = () => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  useEffect(() => {
    if (props.auth)
      apiJson.checkout.walletPayment(props.countryId).then((response) => {
        if (response.success) {
          setWalletAmount(response.data.amount);
        } else if (!response.status) {
          message = response.message // eslint-disable-line react-hooks/exhaustive-deps
            ? response.message !== ""
              ? response.message
              : t("errors.wrong")
            : t("errors.wrong");
          showError();
        } else {
          if (response.status >= 400 && response.status < 500) {
            message = response.message
              ? response.message
              : response.errors
                ? response.errors
                : t("errors.wrong");
            showError();
          } else {
            message = response.message
              ? response.message !== ""
                ? response.message
                : t("errors.wrong")
              : t("errors.wrong");
            showError();
          }
        }
      });
  }, []);
  return (
    <div className="order-summary-desktop">
      <div className="upper">
        <Row noGutters={true} className="summary-row mb-3">
          <Col md={12} className="d-flex">
            <h3 className="text-uppercase">{t("checkout.order-summary")}</h3>
          </Col>
          <Col md={12} className="d-flex justify-content-between">
            <div className="subtotal">{t("checkout.os-sub-total")}</div>
            <div className="subtotal-value">
              <span className="mr-2">
                {props.country.toLowerCase() === "saudi arabia"
                  ? "SAR"
                  : props.country.toLowerCase() === "united arab emirates"
                    ? "AED"
                    : "EGP"}
              </span>
              {props.sub_total}
            </div>
          </Col>
          <Col md={12} className="d-flex justify-content-between mt-1 mb-3">
            <div className="shipping">{t("checkout.os-shipping-fee")}</div>

            {
              props.address?.filter(a => a.default == true).length > 0 ?
                <div className="shipping-value text-capitalize blue">
                  {props.country.toLowerCase() === "saudi arabia"
                    ? "SAR "
                    : props.country.toLowerCase() === "united arab emirates"
                      ? "AED "
                      : "EGP "}
                  {props.delivery_fee}
                </div>
                :
                <div
                  className="shipping-value text-capitalize my-auto"
                  style={{
                    fontSize: "12px",
                    fontFamily: "12px",
                    color: "gray"
                  }}
                >
                  Will be calculated after selecting address
                </div>
            }
          </Col>





          {/* {

              props.sub_total < 100 &&
              <Col md={12} className="suggestfree justify-content-between mt-1 mb-3">
              <span className="suggesttext">
                You're Only&nbsp;
                {props.country.toLowerCase() === "saudi arabia"
                  ? "SAR "
                  : props.country.toLowerCase() === "united arab emirates"
                    ? "AED "
                    : "EGP "}
                <span className="suggestvalue">
                  {
                   Math.round((suggestme + Number.EPSILON) * 100) / 100
                  }
                </span>
                &nbsp;Away From Free Shipping!
              </span>
              </Col>
            } */}




          {props.discount && props.discount !== "0.0" && (
            <Col md={12} className="d-flex justify-content-between mt-1 mb-3">
              <div className="shipping">{t("checkout.os-discount")}</div>
              <div className="shipping-value text-capitalize blue">
                {props.discount}
              </div>

            </Col>
          )}
          {props.cart_items?.coupon_code && (
            <Col md={12} className="d-flex justify-content-between mt-1 mb-3">
              <div className="shipping">Coupon</div>
              <div className="shipping-value text-capitalize blue">
                {props.cart_items?.coupon_code}
              </div>
            </Col>
          )}
        </Row>
        <Row noGutters={true} className="total-container border-bottom-0">
          <Col md={12} className="d-flex justify-content-between mb-1">
            <div>
              <span className="total mr-1">{t("checkout.os-total")}</span>{" "}
              <span className="vat">({t("checkout.os-taxt-vat")})</span>
            </div>
            <div className="total-value">
              <span className="mr-2">
                {props.country.toLowerCase() === "saudi arabia"
                  ? "SAR"
                  : props.country.toLowerCase() === "united arab emirates"
                    ? "AED"
                    : "EGP"}
              </span>
              {props.grand_total}
            </div>
          </Col>
          <Col md={12} className="d-flex justify-content-between mb-3">
            <div className="estimated-vat">{t("checkout.os-est-vat")}</div>
            <div className="estimated-vat-value">
              <span className="mr-2">
                {props.country.toLowerCase() === "saudi arabia"
                  ? "SAR"
                  : props.country.toLowerCase() === "united arab emirates"
                    ? "AED"
                    : "EGP"}
              </span>
              {props.vat}
            </div>
          </Col>
        </Row>
        {props.wallet && (
          <>
            <Row noGutters={true} className="summary-row mb-3">
              <Col md={12} className="d-flex justify-content-between mt-1 mb-3">
                <div className="shipping">{t("checkout-mobile.wallet")}</div>
                <div className="shipping-value green text-capitalize ">
                  {props.country.toLowerCase() === "saudi arabia"
                    ? "-SAR "
                    : props.country.toLowerCase() === "united arab emirates"
                      ? "-AED "
                      : "-EGP "}
                  {walletAmount >= parseFloat(props.grand_total)
                    ? props.grand_total
                    : walletAmount}
                </div>
              </Col>
            </Row>
            <Row noGutters={true} className="total-container border-bottom-0">
              <Col md={12} className="d-flex justify-content-between mb-1">
                <div>
                  <span className="total mr-1">{t("wallet.balance")}</span>{" "}
                </div>
                <div className="total-value">
                  <span className="mr-2">
                    {props.country.toLowerCase() === "saudi arabia"
                      ? "SAR"
                      : props.country.toLowerCase() === "united arab emirates"
                        ? "AED"
                        : "EGP"}
                  </span>
                  {parseFloat(props.grand_total) - walletAmount > 0
                    ? (parseFloat(props.grand_total) - walletAmount).toFixed(2)
                    : 0}
                </div>
              </Col>
            </Row>
          </>
        )}
      </div>
      {/* <Row noGutters={true} className="shiping-row border-bottom-0">
        <Col md={12} className="d-flex justify-content-between">
          <h3 className="text-uppercase mb-0">{t("checkout.os-ship-to")}</h3>
          <h3 className="pointer edit mb-0">{props.defaultAddress[0]?.address1} - {props.defaultAddress[0]?.address2}</h3>
          <Link
            to={
              "/" + props.countryUrl + "-" + props.language + "/select-address"
            }
          >
            <h3 className="pointer edit mb-0">{t("checkout.os-edit")}</h3>
          </Link>
        </Col>
      </Row> */}
      {/* <div className="lower">
        <Row noGutters={true} className="contact summary-row border-bottom-0">
          <Col md={12} className="name d-flex justify-content-between">
            <h3 className="text-capitalize">
              {props.address?.first_name}
            </h3>
          </Col>
          <Col md={12} className="address d-flex justify-content-between">
            <div className="left">{props.address?.address1}</div>
          </Col>
          <Col md={12} className="phone d-flex justify-content-between">
            <p className="left">{props.address?.contact_number}</p>
          </Col>
        </Row>
      </div> */}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    cart_items: state.cart.cart_items,
    sub_total: state.cart.sub_total,
    discount: state.cart.discount,
    delivery_fee: state.cart.delivery_fee,
    vat: state.cart.vat,
    grand_total: state.cart.grand_total,
    country: state.language.country,
    countryId: state.language.countryId,
    auth: state.auth.isSignedIn,
    countryUrl: state.language.countryUrl,
    language: state.language.language,
  };
};

export default connect(mapStateToProps, null)(OrderSummary);
