import React, { Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import $ from "jquery";
import MobileLayoutRenderer from "../layout/MobileLayoutRenderer";
import HeaderMobile from "../../../components/mobile/B2bHeaderMobile";

import TopBanner1 from "../../../assets/images/offers/ramadan2023/Ramadan2023MobileBanner.jpg";
import TopBanner2 from "../../../assets/images/camping/banners/SleepingBagsMob.jpg";
import TopBanner3 from "../../../assets/images/camping/banners/CampingTentsMob.jpg";
import TopBanner4 from "../../../assets/images/camping/banners/WaterbottlesMob.jpg";


import "../../../assets/styles/b2bMob/b2bMobhome.scss";
import { apiJson } from "../../../Api";
import ProductCard from "../../../components/mobile/Home/ProductCardMobile";

import CleaningEss from "../../../assets/images/offers/ramadan2023/section2/CleaningEss.png";
import HandTools from "../../../assets/images/offers/ramadan2023/section2/HandTools.png";
import KitchenAcc from "../../../assets/images/offers/ramadan2023/section2/KitchenAcc.png";
import KitchenAPp from "../../../assets/images/offers/ramadan2023/section2/KitchenAPp.png";
import PowerTools from "../../../assets/images/offers/ramadan2023/section2/PowerTools.png";
import Vacuum from "../../../assets/images/offers/ramadan2023/section2/Vacuum.png";
import WaterBottles from "../../../assets/images/offers/ramadan2023/section2/WaterBottles.png";

import CleanerBanner from "../../../assets/images/offers/ramadan2023/CleanerBanner.png";

import CampingTentsImg from "../../../assets/images/camping/section2/CampingTents.jpg";
import SleepingBagsImg from "../../../assets/images/camping/section2/SleepingBags.jpg";
import CampingFurnitureImg from "../../../assets/images/camping/section2/CampingFurniture.jpg";
import CharcoalImg from "../../../assets/images/camping/section2/Charcoal.jpg";
import FlashlightImg from "../../../assets/images/camping/section2/Flashlight.jpg";
import WaterBottleImg from "../../../assets/images/camping/section2/WaterBottle.jpg";
import CampingBackpacksImg from "../../../assets/images/camping/section2/CampingBackpacks.jpg";
import CampingUtensilsImg from "../../../assets/images/camping/section2/CampingUtensils.jpg";
import CampingCoolersImg from "../../../assets/images/camping/section2/CampingCoolers.jpg";
import BBQgrillsImg from "../../../assets/images/camping/section2/BBQgrills.jpeg";
import CampingToolsImg from "../../../assets/images/camping/section2/CampingTools.jpg";
import CapsCoolnetsImg from "../../../assets/images/camping/section2/Caps_coolnets.jpg";
import BinocularsImg from "../../../assets/images/camping/section2/Binoculars.jpg";

import AutomotiveBanner from "../../../assets/images/offers/ramadan2023/section1/Automotive.png";
import AppliancesBanner from "../../../assets/images/offers/ramadan2023/section1/Appliances.png";
import CleanersBanner from "../../../assets/images/offers/ramadan2023/section1/Cleaners.png";
import HardwareBanner from "../../../assets/images/offers/ramadan2023/section1/Hardware&Tools.png";

import SpecialBanner from "../../../assets/images/offers/ramadan2023/section4/SpecialBanner.png";

import Blender from "../../../assets/images/offers/ramadan2023/section4/Blenders.png";
import Coffee from "../../../assets/images/offers/ramadan2023/section4/CoffeeMachines.png";
import Craft from "../../../assets/images/offers/ramadan2023/section4/Craft.png";
import Pillow from "../../../assets/images/offers/ramadan2023/section4/Pillow.png";
import Plant from "../../../assets/images/offers/ramadan2023/section4/Plants.png";
import Wallpaper from "../../../assets/images/offers/ramadan2023/section4/Wallpaper.png";

import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination, Navigation } from "swiper/core";
SwiperCore.use([Pagination, Navigation]);

const RamadanMobile = (props) => {
  const [section1, setSection1] = useState(null);
  const [section2, setSection2] = useState(null);
  const [section3, setSection3] = useState(null);
  const [section4, setSection4] = useState(null);
  const [section5, setSection5] = useState(null);

  useEffect(() => {
    window.document.title = "Alisuq";

    apiJson.
      homepage.getSections(52, props.countryId)
      .then(response => {
        if (response.success) {
          setSection1(response.data);

          apiJson.
            homepage.getSections(53, props.countryId)
            .then(response => {
              if (response.success) {
                setSection2(response.data);
              }

              apiJson.
                homepage.getSections(54, props.countryId)
                .then(response => {
                  if (response.success) {
                    setSection3(response.data);
                  }

                  apiJson.
                    homepage.getSections(55, props.countryId)
                    .then(response => {
                      if (response.success) {
                        setSection4(response.data);
                      }

                      apiJson.
                        homepage.getSections(56, props.countryId)
                        .then(response => {
                          if (response.success) {
                            setSection5(response.data);
                          }
                        });
                    });
                });
            });
        }
      });

    $("html, body").animate({ scrollTop: 0 }, "fast");
  }, []);
  return (
    <MobileLayoutRenderer
      NavbarTop={false}
      BackBtn={false}
      CancelBtn={false}
      NavbarBottom={true}
      SearchIcon={true}
    >
      <div className="bb-home-mob">
        <HeaderMobile />
      </div>
      <div className="banner-sec">
        <Swiper
          spaceBetween={0}
          slidesPerView={1}
          pagination={false}
          navigation={true}
        >
          <SwiperSlide
            onClick={(e) => e.preventDefault()}
            key={1}
          >
            <Link to={{
              pathname: "/" + props.countryUrl +
                "-" + props.language + "/items/products",
              search: "filter_by_category%5B%5D=498"
            }}>
              <img
                src="https://image.made-in-china.com/228f0j00DYkGOtBEVUWq/made-in-china.webp"
                // src={TopBanner1}
                className="img img-fluid"
                height="110px"
              />
            </Link>
          </SwiperSlide>
        </Swiper>
      </div>
      <div className="b2b-home-mob pt-4" style={{ background: "#f2f2f2" }}>
        {/* <div className="emptyspacer"></div> */}
        <div className="px-2">

          <Swiper
            spaceBetween={20}
            slidesPerView={4}
            pagination={false}
            navigation={true}
          >
            <SwiperSlide
              onClick={(e) => e.preventDefault()}
              key={1}
            >
              <Link to={{
                pathname: "/" + props.countryUrl +
                  "-" + props.language + "/items/products",
                search: "discount_sort=true&filter_by_category%5B%5D=217&filter_by_category%5B%5D=329&filter_by_category%5B%5D=330&filter_by_category%5B%5D=328&filter_by_category%5B%5D=332&filter_by_category%5B%5D=331"
              }}>
                <img
                  src={CleaningEss}
                  alt="Alisuq cleaner essentials"
                  className="img img-fluid"
                  height="110px"
                />
              </Link>
            </SwiperSlide>
            <SwiperSlide
              onClick={(e) => e.preventDefault()}
              key={1}
            >
              <Link to={{
                pathname: "/" + props.countryUrl +
                  "-" + props.language + "/items/products",
                search: "discount_sort=true&filter_by_category%5B%5D=261&filter_by_category%5B%5D=405&filter_by_category%5B%5D=407&filter_by_category%5B%5D=408&filter_by_category%5B%5D=409&filter_by_category%5B%5D=414&filter_by_category%5B%5D=415&filter_by_category%5B%5D=406&filter_by_category%5B%5D=410&filter_by_category%5B%5D=416"
              }}>
                <img
                  src={HandTools}
                  alt="Alisuq Hand tools"
                  className="img img-fluid"
                  height="110px"
                />
              </Link>
            </SwiperSlide>
            <SwiperSlide
              onClick={(e) => e.preventDefault()}
              key={1}
            >
              <Link to={{
                pathname: "/" + props.countryUrl +
                  "-" + props.language + "/items/products",
                search: "discount_sort=true&filter_by_category%5B%5D=255&filter_by_category%5B%5D=374&filter_by_category%5B%5D=377&filter_by_category%5B%5D=378&filter_by_category%5B%5D=379&filter_by_category%5B%5D=380&filter_by_category%5B%5D=375&filter_by_category%5B%5D=376"
              }}>
                <img
                  src={KitchenAcc}
                  alt="Alisuq Kitchen Accessories"
                  className="img img-fluid"
                  height="110px"
                />
              </Link>
            </SwiperSlide>
            <SwiperSlide
              onClick={(e) => e.preventDefault()}
              key={1}
            >
              <Link to={{
                pathname: "/" + props.countryUrl +
                  "-" + props.language + "/items/products",
                search: "discount_sort=true&filter_by_category%5B%5D=210&filter_by_category%5B%5D=295&filter_by_category%5B%5D=299&filter_by_category%5B%5D=300&filter_by_category%5B%5D=302&filter_by_category%5B%5D=304&filter_by_category%5B%5D=294&filter_by_category%5B%5D=296&filter_by_category%5B%5D=298&filter_by_category%5B%5D=303&filter_by_category%5B%5D=297&filter_by_category%5B%5D=301&filter_by_category%5B%5D=305"
              }}>
                <img
                  src={KitchenAPp}
                  alt="Kitchen appliances"
                  className="img img-fluid"
                  height="110px"
                />
              </Link>
            </SwiperSlide>
            <SwiperSlide
              onClick={(e) => e.preventDefault()}
              key={1}
            >
              <Link to={{
                pathname: "/" + props.countryUrl +
                  "-" + props.language + "/items/products",
                search: "discount_sort=true&filter_by_category%5B%5D=259&filter_by_category%5B%5D=388&filter_by_category%5B%5D=389&filter_by_category%5B%5D=390&filter_by_category%5B%5D=391&filter_by_category%5B%5D=392&filter_by_category%5B%5D=394&filter_by_category%5B%5D=396&filter_by_category%5B%5D=393&filter_by_category%5B%5D=395&filter_by_category%5B%5D=397"
              }}>
                <img
                  src={PowerTools}
                  alt="Alisuq Power Tools"
                  className="img img-fluid"
                  height="110px"
                />
              </Link>
            </SwiperSlide>
            <SwiperSlide
              onClick={(e) => e.preventDefault()}
              key={1}
            >
              <Link to={{
                pathname: "/" + props.countryUrl +
                  "-" + props.language + "/items/products",
                search: "discount_sort=true&filter_by_category%5B%5D=209&filter_by_category%5B%5D=292&filter_by_category%5B%5D=293&filter_by_category%5B%5D=489"
              }}>
                <img
                  src={Vacuum}
                  alt="Alisuq Vacuums"
                  className="img img-fluid"
                  height="110px"
                />
              </Link>
            </SwiperSlide>
            <SwiperSlide
              onClick={(e) => e.preventDefault()}
              key={1}
            >
              <Link to={{
                pathname: "/" + props.countryUrl +
                  "-" + props.language + "/items/products",
                search: "discount_sort=true&filter_by_category%5B%5D=508"
              }}>
                <img
                  src={WaterBottles}
                  alt="Alisuq Water Bottles"
                  className="img img-fluid"
                  height="110px"
                />
              </Link>
            </SwiperSlide>
          </Swiper>
        </div>

        <div className="emptyspacer"></div>

        <div className="trending-products">
          <p className="m-0 heading">Trending Products</p>
          <div className="row m-0">
            <div className="col-4">
              <Link to="/">
                <div>
                  <img src="https://image.made-in-china.com/43f34j00luLfqPQWRIzi/Aibort-Custom-Logo-Fitness-Activewear-Gym-Wear-Track-Suit-Hip-Hop-Style-Apparel-Casual-Wears-Hoodies-Ladies-Tracksuit-Hooded-Jogging-Suit-Wholesale-Sweat-Suits.webp" className="img img-fluid" />
                  <p className="m-0 text-center">Sports Wear</p>
                </div>
              </Link>
            </div>
            <div className="col-4">
              <Link to="/">
                <div>
                  <img src="https://image.made-in-china.com/43f34j00MvbtFZznyNlh/Victoria-Pure-Seduction-Body-Mist-250ml-Sweet-Secret-Perfume-Mist-Spray.webp" className="img img-fluid" />
                  <p className="m-0 text-center">Sports Wear</p>
                </div>
              </Link>
            </div>
            <div className="col-4">
              <Link to="/">
                <div>
                  <img src="https://image.made-in-china.com/43f34j00wIdkOnCFPEbg/I14-PRO-Max-Cell-Phones-Smartphone-4G-5g-Network-8GB-RAM-256GB-ROM-Mobile-Phone-Pods-Bluetooth.webp" className="img img-fluid" />
                  <p className="m-0 text-center">Sports Wear</p>
                </div>
              </Link>
            </div>
          </div>
        </div>

        <div className="row mx-0 pt-2 section2">
          <div className="col-6 p-0 left">
            <div className="top">
              <p className="m-0 title">2023 Amazing April</p>
              <p className="m-0 date">Apr 02 - Apr 30, 2023</p>
              <Link to="/" className="go-btn">
                <p className="m-0">GO &gt;</p>
              </Link>
            </div>
            <div className="bottom-left">
              <div className="row mx-0">
                <div className="col-6">
                  <img src="https://image.made-in-china.com/43f34j00mHdRCIBAkPbf/Horizontal-Flow-Wrapper-Packing-Packaging-Machine-for-Bread-Potato-Chip-AHP-100-.webp" className="img img-fluid" />
                </div>
                <div className="col-6">
                  <img src="https://image.made-in-china.com/43f34j00fvOayouBEqhk/High-Quality-Foton-Forland-Auto-Parts.webp" className="img img-fluid" />
                </div>
              </div>
            </div>
          </div>
          <div className="col-6 p-0 right">
            <div className="top">
              <p className="m-0 heading">Popular in UAE</p>
              <div className="row m-0">
                <div className="col-6 p-0 img-div">
                  <img src="https://image.made-in-china.com/43f34j00oZSRLWqgCGkB/Top-and-Bottom-Circular-Slitting-Blades.webp" className="img img-fluid" />
                  <p className="m-0">US$ 50</p>
                </div>
                <div className="col-6 p-0 img-div">
                  <img src="https://image.made-in-china.com/43f34j00oZSRLWqgCGkB/Top-and-Bottom-Circular-Slitting-Blades.webp" className="img img-fluid" />
                  <p className="m-0">US$ 50</p>
                </div>
              </div>
            </div>
            <div className="bottom-right">
              <p className="m-0 heading">Low MOQ Products</p>
              <div className="row m-0">
                <div className="col-6 p-0 img-div">
                  <img src="https://image.made-in-china.com/43f34j00eEZbScDPlnqo/All-in-One-Integrated-LED-Solar-Street-Light-for-Government-Road-Lighting-Project-with-CE-RoHS-IP67-Ik10-CB-IEC-TUV-Sud-Certificates.webp" className="img img-fluid" />
                  <p className="m-0">US$ 50</p>
                </div>
                <div className="col-6 p-0 img-div">
                  <img src="https://image.made-in-china.com/43f34j00LlqrMgsHEkcF/2023-Hot-Selling-Wholesale-Premium-Quality-Ultra-Soft-High-Absorption-Cheap-Price-Breathable-Care-Baby-Comfortable-Diaper-Nappy-Items-Made-in-China.webp" className="img img-fluid" />
                  <p className="m-0">US$ 50</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="surfacepat">
          <h6 style={{
            padding: "10px",
            paddingBottom: "0px",
            fontWeight: "bold"
          }}>{section1?.title_en}</h6>
          <div className="product-cont">
            {section1?.products?.map((data) => (
              <Link
                style={{ textDecoration: "none" }}
                to={{
                  pathname:
                    "/" +
                    props.countryUrl +
                    "-" +
                    props.language +
                    `/${data.category_title_en
                      .toLowerCase()
                      .replace(/ /g, "-")}/${data.slug}`,
                  state: {
                    id: data.id,
                    type: data.variants ? "products" : "services",
                    sku: data.variants[0].sku
                  },
                }}
                key={data.id + Math.random()}
              >
                <div
                  className="image-cont"
                  key={data.id + Math.random() + "prod"}
                >
                  <ProductCard
                    key={data.id + Math.random()}
                    img={
                      data.variants
                        ? data.variants[0].featured_image
                        : data.featured_image
                    }
                    storeLogo={data.store_logo}
                    wishlisted={
                      data.variants
                        ? data.variants[0].wishlisted
                        : data.wishlisted
                    }
                    home={true}
                    type={data.variants ? "products" : "services"}
                    id={data.variants ? data.id : data.id}
                    rating={data.variants[0].average_rating}
                    reviews={data.variants[0].reviews_count}
                    name={data[`name_${props.language}`] + " " + data.variants[0][`name_${props.language}`]}
                    data={data.variants ? data.variants[0] : data}
                    coupon={data.coupon}
                    is_inquiry={data.is_inquiry}
                    product_type={data.product_type}
                    start_price={data.start_price}
                    end_price={data.end_price}
                  />
                </div>
              </Link>
            ))}
          </div>
        </div>

        <div className="surfacepat">
          <h6 style={{
            padding: "10px",
            paddingBottom: "0px",
            fontWeight: "bold"
          }}>{section2?.title_en}</h6>
          <div className="product-cont">
            {section2?.products?.map((data) => (
              <Link
                style={{ textDecoration: "none" }}
                to={{
                  pathname:
                    "/" +
                    props.countryUrl +
                    "-" +
                    props.language +
                    `/${data.category_title_en
                      .toLowerCase()
                      .replace(/ /g, "-")}/${data.slug}`,
                  state: {
                    id: data.id,
                    type: data.variants ? "products" : "services",
                    sku: data.variants[0].sku
                  },
                }}
                key={data.id + Math.random()}
              >
                <div
                  className="image-cont"
                  key={data.id + Math.random() + "prod"}
                >
                  <ProductCard
                    key={data.id + Math.random()}
                    img={
                      data.variants
                        ? data.variants[0].featured_image
                        : data.featured_image
                    }
                    storeLogo={data.store_logo}
                    wishlisted={
                      data.variants
                        ? data.variants[0].wishlisted
                        : data.wishlisted
                    }
                    home={true}
                    type={data.variants ? "products" : "services"}
                    id={data.variants ? data.id : data.id}
                    rating={data.variants[0].average_rating}
                    reviews={data.variants[0].reviews_count}
                    name={data[`name_${props.language}`] + " " + data.variants[0][`name_${props.language}`]}
                    data={data.variants ? data.variants[0] : data}
                    coupon={data.coupon}
                    is_inquiry={data.is_inquiry}
                    product_type={data.product_type}
                    start_price={data.start_price}
                    end_price={data.end_price}
                  />
                </div>
              </Link>
            ))}
          </div>
        </div>

        <img src="https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/9SqwAfYPpYSRSZSaFw2Zkk.jpeg" className="img img-fluid my-3" />

        <div className="surfacepat">
          <h6 style={{
            padding: "10px",
            paddingBottom: "0px",
            fontWeight: "bold"
          }}>{section3?.title_en}</h6>
          <div className="product-cont">
            {section3?.products?.map((data) => (
              <Link
                style={{ textDecoration: "none" }}
                to={{
                  pathname:
                    "/" +
                    props.countryUrl +
                    "-" +
                    props.language +
                    `/${data.category_title_en
                      .toLowerCase()
                      .replace(/ /g, "-")}/${data.slug}`,
                  state: {
                    id: data.id,
                    type: data.variants ? "products" : "services",
                    sku: data.variants[0].sku
                  },
                }}
                key={data.id + Math.random()}
              >
                <div
                  className="image-cont"
                  key={data.id + Math.random() + "prod"}
                >
                  <ProductCard
                    key={data.id + Math.random()}
                    img={
                      data.variants
                        ? data.variants[0].featured_image
                        : data.featured_image
                    }
                    storeLogo={data.store_logo}
                    wishlisted={
                      data.variants
                        ? data.variants[0].wishlisted
                        : data.wishlisted
                    }
                    home={true}
                    type={data.variants ? "products" : "services"}
                    id={data.variants ? data.id : data.id}
                    rating={data.variants[0].average_rating}
                    reviews={data.variants[0].reviews_count}
                    name={data[`name_${props.language}`] + " " + data.variants[0][`name_${props.language}`]}
                    data={data.variants ? data.variants[0] : data}
                    coupon={data.coupon}
                    is_inquiry={data.is_inquiry}
                    product_type={data.product_type}
                    start_price={data.start_price}
                    end_price={data.end_price}
                  />
                </div>
              </Link>
            ))}
          </div>
        </div>

        <div className="surfacepat">
          <h6 style={{
            padding: "10px",
            paddingBottom: "0px",
            fontWeight: "bold"
          }}>{section4?.title_en}</h6>
          <div className="product-cont">
            {section4?.products?.map((data) => (
              <Link
                style={{ textDecoration: "none" }}
                to={{
                  pathname:
                    "/" +
                    props.countryUrl +
                    "-" +
                    props.language +
                    `/${data.category_title_en
                      .toLowerCase()
                      .replace(/ /g, "-")}/${data.slug}`,
                  state: {
                    id: data.id,
                    type: data.variants ? "products" : "services",
                    sku: data.variants[0].sku
                  },
                }}
                key={data.id + Math.random()}
              >
                <div
                  className="image-cont"
                  key={data.id + Math.random() + "prod"}
                >
                  <ProductCard
                    key={data.id + Math.random()}
                    img={
                      data.variants
                        ? data.variants[0].featured_image
                        : data.featured_image
                    }
                    storeLogo={data.store_logo}
                    wishlisted={
                      data.variants
                        ? data.variants[0].wishlisted
                        : data.wishlisted
                    }
                    home={true}
                    type={data.variants ? "products" : "services"}
                    id={data.variants ? data.id : data.id}
                    rating={data.variants[0].average_rating}
                    reviews={data.variants[0].reviews_count}
                    name={data[`name_${props.language}`] + " " + data.variants[0][`name_${props.language}`]}
                    data={data.variants ? data.variants[0] : data}
                    coupon={data.coupon}
                    is_inquiry={data.is_inquiry}
                    product_type={data.product_type}
                    start_price={data.start_price}
                    end_price={data.end_price}
                  />
                </div>
              </Link>
            ))}
          </div>
        </div>

        <img src="https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/9SqwAfYPpYSRSZSaFw2Zkk.jpeg" className="img img-fluid my-3" />

        <div className="surfacepat">
          <h6 style={{
            padding: "10px",
            paddingBottom: "0px",
            fontWeight: "bold"
          }}>{section5?.title_en}</h6>
          <div className="product-cont">
            {section5?.products?.map((data) => (
              <Link
                style={{ textDecoration: "none" }}
                to={{
                  pathname:
                    "/" +
                    props.countryUrl +
                    "-" +
                    props.language +
                    `/${data.category_title_en
                      .toLowerCase()
                      .replace(/ /g, "-")}/${data.slug}`,
                  state: {
                    id: data.id,
                    type: data.variants ? "products" : "services",
                    sku: data.variants[0].sku
                  },
                }}
                key={data.id + Math.random()}
              >
                <div
                  className="image-cont"
                  key={data.id + Math.random() + "prod"}
                >
                  <ProductCard
                    key={data.id + Math.random()}
                    img={
                      data.variants
                        ? data.variants[0].featured_image
                        : data.featured_image
                    }
                    storeLogo={data.store_logo}
                    wishlisted={
                      data.variants
                        ? data.variants[0].wishlisted
                        : data.wishlisted
                    }
                    home={true}
                    type={data.variants ? "products" : "services"}
                    id={data.variants ? data.id : data.id}
                    rating={data.variants[0].average_rating}
                    reviews={data.variants[0].reviews_count}
                    name={data[`name_${props.language}`] + " " + data.variants[0][`name_${props.language}`]}
                    data={data.variants ? data.variants[0] : data}
                    coupon={data.coupon}
                    is_inquiry={data.is_inquiry}
                    product_type={data.product_type}
                    start_price={data.start_price}
                    end_price={data.end_price}
                  />
                </div>
              </Link>
            ))}
          </div>
        </div>

      </div>
    </MobileLayoutRenderer>
  );
};

const mapStateToProps = (state) => {
  return {
    countryUrl: state.language.countryUrl,
    countryId: state.language.countryId,
    language: state.language.language,
  };
};


export default connect(mapStateToProps, null)(RamadanMobile);
