import React, { Fragment, useState, useEffect } from "react";

import CartSidebarProductList from "./CartSidebarProductList";
import "../../../assets/styles/customer/cart/cartsidebar.scss";
import { apiJson } from "../../../Api";
import { set_cart_items } from "../../../redux/actions/CartAction";

import { Redirect } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ClipLoader from "react-spinners/ClipLoader";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getCurrencyOfCountry } from "../../../utils/utils";

const CartSidebar = (props) => {
  const [checked, setChecked] = useState(false);
  const [data, setData] = useState(false);
  const [dataChanged, setDataChanged] = useState(false);
  const [couponExpired, setCoupopnExpired] = useState(false);
  let message = "";
  const suggestme = 100 - props.cart_items.sub_total;
  const { t } = useTranslation();
  const [redirect, setRedirect] = useState(false);

  const showError = () => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  useEffect(() => {
    apiJson.cart.getCartItems(props.countryId).then((response) => {
      if (response.success) {
        props.set_cart_items({ cart_items: response.data });
        setData(true);
      } else if (!response.status) {
        message = response.message // eslint-disable-line react-hooks/exhaustive-deps
          ? response.message !== ""
            ? response.message
            : t("errors.wrong")
          : t("errors.wrong");
        showError();
      } else {
        if (response.status >= 400 && response.status < 500) {
          message = response.message
            ? response.message
            : response.errors
              ? response.errors
              : t("errors.wrong");
          showError();
        } else {
          message = response.message
            ? response.message !== ""
              ? response.message
              : t("errors.wrong")
            : t("errors.wrong");
          showError();
        }
      }
    });
    setChecked(props.bool);
  }, []);
  useEffect(() => {
    props.cart_items.cart_data_changed &&
      setDataChanged(props.cart_items?.cart_data_changed);

    props.cart_items.cart_data_changed &&
      apiJson.cart.updateCartReset(props.countryId);
    props.cart_items.cart_data_changed &&
      props.set_cart_items({
        cart_items: {
          ...props.cart_items,
          cart_data_changed: false,
        },
      });
  }, [props.cart_items.cart_data_changed]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    props.cart_items.expired_coupon_removed &&
      setCoupopnExpired(props.cart_items?.expired_coupon_removed);

    props.cart_items.expired_coupon_removed &&
      apiJson.cart.discountReset(props.countryId);
    props.cart_items.expired_coupon_removed &&
      props.set_cart_items({
        cart_items: {
          ...props.cart_items,
          expired_coupon_removed: false,
        },
      });
  }, [props.cart_items.expired_coupon_removed]); // eslint-disable-line react-hooks/exhaustive-deps

  const checkoutBtnClicked = (e) => {
    e.preventDefault();

    if (props.auth) {
      apiJson
        .select.getDefault()
        .then(resp => {
          if (resp.success) {
            if (resp.data.length > 0) {
              const request = {
                order: {
                  address_id: resp.data[0].id,
                  is_delivery: 1
                },
              };

              apiJson.select
                .varifyAdress(request, props.countryId)
                .then((response) => {
                  if (response.success) {
                    props.set_cart_items({
                      order: {
                        address: resp.data[0],
                        id: response.data.id,
                        createdAddress: response.data,
                      },
                    });
                    setRedirect({
                      value: true,
                      path:
                        "/" + props.countryUrl + "-" + props.language + "/checkout",
                    });
                  } else if (!response.status) {
                    message = t("errors.wrong");
                    showError();
                  } else {
                    if (response.status >= 400 && response.status < 500) {
                      message = response.message
                        ? response.message
                        : response.errors
                          ? response.errors
                          : t("errors.wrong");
                      showError();
                    } else {
                      message = response.message
                        ? response.message !== ""
                          ? response.message
                          : t("errors.wrong")
                        : t("errors.wrong");
                      showError();
                    }
                  }
                });
            }
            else {
              props.set_cart_items({
                order: {
                  address: null,
                  id: null,
                  createdAddress: null,
                },
              });
              setRedirect({
                value: true,
                path:
                  "/" + props.countryUrl + "-" + props.language + "/checkout",
              });
            }
          }
        });
    }
    else {
      props.set_cart_items({
        order: {
          address: null,
          id: null,
          createdAddress: null,
        },
      });
      setRedirect({
        value: true,
        path:
          "/" + props.countryUrl + "-" + props.language + "/checkout",
      });
    }

  }

  return (
    <Fragment>
      <div
        className={
          checked
            ? "cart-sidebar-with-translation-desktop"
            : "cart-sidebar-without-translation-desktop"
        }
        ref={props.refs}
      >
        {!data ? (
          <div className="d-flex justify-content-center align-items-center w-100 h-100">
            <ClipLoader color="#000" loading size={35} />
          </div>
        ) : (
          <div className="panel">
            <CartSidebarProductList
              dataChanged={dataChanged || couponExpired}
              cart_items={props.cart_items}
              List={props.cart_items}
            />
            <hr />
            {dataChanged && (
              <div className="data-change">
                <div className="w-80 ">
                  <div>{t("data-changed.quantity-changed")}</div>
                  <div className="mt-1">{t("data-changed.prices-changed")}</div>
                  <div className="mt-1">{t("data-changed.review-cart")}</div>
                </div>
                <div className="w-10  dismiss-border dismiss m-auto">
                  <span
                    className="pointer"
                    onClick={() => setDataChanged(false)}
                  >
                    {" "}
                    {t("coupon-expire.dismiss")}
                  </span>
                </div>
              </div>
            )}
            {couponExpired && (
              <div className="data-change mt-2">
                <div className="w-90 ">
                  <div>{t("coupon-expire.coupon-expired")}</div>

                  <div className="mt-1">{t("coupon-expire.review-cart")}</div>
                </div>
                <div className="w-8  dismiss-border dismiss m-auto">
                  <span
                    className="pointer"
                    onClick={() => setCoupopnExpired(false)}
                  >
                    {" "}
                    {t("coupon-expire.dismiss")}
                  </span>
                </div>
              </div>
            )}

            <div className="bottom-container ">
              <div className="upper-container">
                <p>{t("cart-sidebar.cart-total")}</p>
                <p>
                  {getCurrencyOfCountry(props.country)}{" "}
                  {props.cart_items.sub_total}
                </p>

              </div>
              {/* {



                props.cart_items.sub_total < 100 &&

                <div className="suggestfree">
                  <span className="suggesttext">
                    You're Only   {getCurrencyOfCountry(props.country)}{" "}&nbsp;
                    <span className="suggestvalue">
                      {
                        Math.round((suggestme + Number.EPSILON) * 100) / 100

                      }
                    </span>
                    &nbsp;Away From Free Shipping!
                  </span>
                </div>


              } */}
              <div className="bottom-buttons">
                <Link
                  to={"/" + props.countryUrl + "-" + props.language + "/cart"}
                >
                  <button type="button" className="sidebar-viewcart-button">
                    {t("cart-sidebar.view-cart")}
                  </button>
                </Link>
                <Link
                  // to={
                  //   "/" +
                  //   props.countryUrl +
                  //   "-" +
                  //   props.language +
                  //   "/select-address"
                  // }
                  onClick={checkoutBtnClicked}
                >
                  <button type="button" className="sidebar-checkout-button">
                    {t("cart-sidebar.checkout")}
                  </button>
                </Link>
              </div>
            </div>
          </div>
        )}
      </div>
      {redirect.value && (
        <Redirect
          push
          to={{
            pathname: redirect.path,
            state: redirect.state,
          }}
        />
      )}
    </Fragment>
  );
};
const mapStateToProps = (state) => {
  return {
    cart_items: state.cart.cart_items,
    wish_list: state.cart.wish_list,
    country: state.language.country,
    language: state.language.language,
    countryUrl: state.language.countryUrl,
    countryId: state.language.countryId,
    auth: state.auth.isSignedIn,
  };
};
const mapDispatchToProps = { set_cart_items };

export default connect(mapStateToProps, mapDispatchToProps)(CartSidebar);
