import React, { useState, useCallback, useEffect, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import "../../../assets/styles/component/mobile/AllCategories/MobileSearch.scss";
// import didYouMean from 'didyoumean';
import { useTranslation } from "react-i18next";
import { RiSearchLine } from "react-icons/ri";
import { apiJson } from "../../../Api";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { debounce } from "lodash";
import { connect } from "react-redux";
import { set_search } from "../../../redux/actions/HeaderAction";
import ClipLoader from "react-spinners/ClipLoader";
import SearchPopoverMobile from "../../header/SearchPopoverMobile";
import { useOutsideAlerter } from "../../../utils/CustomHooks";
import { keywords } from "../../../assets/keywords";

function MobileSearch(props) {
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();
  const [isSearchClick, setIsSearchClick] = useState(false);
  const [recentSearch, setrecentSearch] = useState([]);
  const [recentvalue, setrecentvalue] = useState("");
  const [loader, setLoader] = useState(false);
  const [searching, setSearching] = useState(false);
  const searchRef = useRef(null);

  useOutsideAlerter(searchRef, setIsSearchClick);

  let message = "";

  const handleSearchClcik = (e) => {
    setIsSearchClick(true);
  };

  const showError = () => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const clearRecentHistory = () =>
    apiJson.header.clearHistory().then((response) => {
      setLoader(false);
      if (response.success) {
        setrecentSearch([]);
      } else if (!response.status) {
        message = response.message
          ? response.message !== ""
            ? response.message
            : t("errors.wrong")
          : t("errors.wrong");
        showError();
      } else {
        if (response.status >= 400 && response.status < 500) {
          message = response.message
            ? response.message
            : response.errors
            ? response.errors
            : t("errors.wrong");
          showError();
        } else {
          message = response.message
            ? response.message !== ""
              ? response.message
              : t("errors.wrong")
            : t("errors.wrong");
          showError();
        }
      }
    });

  const deleteRecent = (id) => {
    setLoader(true);
    apiJson.header.deleteRecent(id).then((response) => {
      setLoader(false);
      if (response.success) {
        setrecentSearch(recentSearch.filter((ele) => ele.id !== id));
      } else if (!response.status) {
        message = response.message
          ? response.message !== ""
            ? response.message
            : t("errors.wrong")
          : t("errors.wrong");
        showError();
      } else {
        if (response.status >= 400 && response.status < 500) {
          message = response.message
            ? response.message
            : response.errors
            ? response.errors
            : t("errors.wrong");
          showError();
        } else {
          message = response.message
            ? response.message !== ""
              ? response.message
              : t("errors.wrong")
            : t("errors.wrong");
          showError();
        }
      }
    });
  };

  const onSeacrhChange = (text) => {
    setrecentvalue(text);
    if (text.length < 2) {
      return [];
    } else {
      !searching && setSearching(true);
      debouncedCallback(text);
    }
  };

  const debouncedCallback =
    // eslint-disable-next-line
    useCallback(
      debounce((text) => performSearch(text), 1000),
      []
    );

  const performSearch = function (text) {
    // if (didYouMean(text.trim().replace(/\s\s+/g, ' '), keywords)) {
    //   text = didYouMean(text.trim().replace(/\s\s+/g, ' '), keywords);
    // }
    text = text.replace("&", "");

    let product_type = "retail";

    if (window.location.href.includes("/wholesale")) {
      product_type = "wholesale";
    }

    apiJson.header.search(props.countryId, text.trim().replace(/\s\s+/g, ' '), product_type).then(function (response) {
      if (response.success) {
        props.set_search({
          searchCat: response.data.categories,
          searchBrands: response.data.brands,
          searchProducts: response.data.products,
          searchServices: response.data.services,
        });
        setSearching(false);
      } else if (!response.status) {
        message = response.message
          ? response.message !== ""
            ? response.message
            : t("errors.wrong")
          : t("errors.wrong");
        showError();
      } else {
        if (response.status >= 400 && response.status < 500) {
          message = response.message
            ? response.message
            : response.errors
            ? response.errors
            : t("errors.wrong");
          showError();
        } else {
          message = response.message
            ? response.message !== ""
              ? response.message
              : t("errors.wrong")
            : t("errors.wrong");
          showError();
        }
      }
    });
  };

  useEffect(
    () =>
      localStorage.getItem("access_token") &&
      recentvalue === "" &&
      apiJson.header.recentSearches().then((response) => {
        if (response.success) {
          setrecentSearch(response.data);
        } else if (!response.status) {
          // eslint-disable-next-line
          message = response.message
            ? response.message !== ""
              ? response.message
              : t("errors.wrong")
            : t("errors.wrong");
          showError();
        } else {
          if (response.status >= 400 && response.status < 500) {
            message = response.message
              ? response.message
              : response.errors
              ? response.errors
              : t("errors.wrong");
            showError();
          } else {
            message = response.message
              ? response.message !== ""
                ? response.message
                : t("errors.wrong")
              : t("errors.wrong");
            showError();
          }
        }
      }),
    [recentvalue]
  );

  const handleKeyPressed = (e) => {
    if (e.key == "Enter") {
      var text = recentvalue.replace("&", "");
      // if (didYouMean(recentvalue.trim().replace(/\s\s+/g, ' '), keywords)) {
      //   text = didYouMean(recentvalue.trim().replace(/\s\s+/g, ' '), keywords);
      // }
      
      history.push({
        pathname:
          "/" +
          props.countryUrl +
          "-" +
          props.language +
          "/search/products?q=" + text,
        state: location.state,
      });
    }
  }

  return (
    <div
      ref={searchRef}
      className="mobile-search-cont d-flex flex-direction-row pl-2 pr-3 pt-2 pb-2"
    >
      {searching ? (
        <ClipLoader color="#fff" loading size={20} />
      ) : (
        <RiSearchLine size={22} color="#979aa7" />
      )}
      <input
        type="text"
        className="ml-1 input-field"
        onChange={(e) => onSeacrhChange(e.target.value)}
        placeholder={props.placeholder}
        value={recentvalue}
        onClick={handleSearchClcik}
        onKeyDown={e => handleKeyPressed(e)}
      />
      {isSearchClick && (
        <div>
          <SearchPopoverMobile
            recent={recentSearch}
            recentValue={recentvalue}
            clearHistory={() => {
              setLoader(true);
              clearRecentHistory();
            }}
            deleteRecent={(id) => deleteRecent(id)}
            loader={loader}
            setRecentValue={(val) => onSeacrhChange(val)}
            setSearchClick={() => setIsSearchClick(false)}
            searching={searching}
          />
        </div>
      )}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    countryId: state.language.countryId,
    language: state.language.language,
    countryUrl: state.language.countryUrl
  };
};
const mapDispatchToProps = {
  set_search,
};
export default connect(mapStateToProps, mapDispatchToProps)(MobileSearch);
