import React, { Fragment } from "react";
import { Link } from 'react-router-dom';
import WebsiteHeader from "../../components/header/WebsiteHeader";
import WebsiteFooter from "../../components/Footer/WebsiteFooter";
import pagesbannerimg from "../../assets/images/footer-pages-banners/alisuq_story.jpg";
import "../../assets/styles/category-homepages/dss-page.scss";
import bulkorderimg from "../../assets/images/footer-page-images/a.png";



import dssbanner from "../../assets/images/individual-pages/DSS_Banner_1440x300.jpg";


import dsscat1 from "../../assets/images/individual-pages/dss/mob/dsstest1.jpg";
import dsscat2 from "../../assets/images/individual-pages/dss/mob/dsstest2.jpg";
import dsscat3 from "../../assets/images/individual-pages/dss/mob/dsstest3.jpg";
import dsscat4 from "../../assets/images/individual-pages/dss/mob/dsstest4.jpg";
import dsscat5 from "../../assets/images/individual-pages/dss/mob/dsstest5.jpg";
import dsscat6 from "../../assets/images/individual-pages/dss/mob/dsstest6.jpg";




import dsscat7 from "../../assets/images/individual-pages/dss/mob/dsstest7.jpg";
import dsscat8 from "../../assets/images/individual-pages/dss/mob/dsstest8.jpg";

import dsscat9 from "../../assets/images/individual-pages/dss/mob/dsstest9.jpg";
import dsscat10 from "../../assets/images/individual-pages/dss/mob/dsstest10.jpg";
import dsscat11 from "../../assets/images/individual-pages/dss/mob/dsstest11.jpg";
import dsscat12 from "../../assets/images/individual-pages/dss/mob/dsstest12.jpg";



import dsscat13 from "../../assets/images/individual-pages/dss/mob/dsstest13.jpg";
import dsscat14 from "../../assets/images/individual-pages/dss/mob/dsstest14.jpg";
import dsscat15 from "../../assets/images/individual-pages/dss/mob/dsstest15.jpg";
import dsscat16 from "../../assets/images/individual-pages/dss/mob/dsstest16.jpg";

// import dsscat9 from "../../assets/images/individual-pages/dss/dss_building_cat1.jpg";
// import dsscat10 from "../../assets/images/individual-pages/dss/dss_tapes_cat2.jpg";
// import dsscat11 from "../../assets/images/individual-pages/dss/dss_tapes_cat2.jpg";
// import dsscat12 from "../../assets/images/individual-pages/dss/dss_tapes_cat2.jpg";
// import dsscat13 from "../../assets/images/individual-pages/dss/dss_tapes_cat2.jpg";
// import dsscat14 from "../../assets/images/individual-pages/dss/dss_tapes_cat2.jpg";
// import dsscat15 from "../../assets/images/individual-pages/dss/dss_tapes_cat2.jpg";
// import dsscat16 from "../../assets/images/individual-pages/dss/dss_tapes_cat2.jpg";




import vacuumdss from "../../assets/images/individual-pages/dss/vacuum.png";
import gendss from "../../assets/images/individual-pages/dss/gen.png";
import washerdss from "../../assets/images/individual-pages/dss/washer.png";
import gorila1 from "../../assets/images/individual-pages/dss/Mockup-01.gif";

import spraydss from "../../assets/images/individual-pages/dss/spraypaints.png";
import metalicdss from "../../assets/images/individual-pages/dss/metallicdss.png";
import decorativedss from "../../assets/images/individual-pages/dss/decorativedss.png";
import interiordss from "../../assets/images/individual-pages/dss/interiordss.png";
import markingdss from "../../assets/images/individual-pages/dss/marking.png";
import glittersdss from "../../assets/images/individual-pages/dss/glittersdss.png";

import hero1 from "../../assets/images/individual-pages/dss/DSS_HeroBanner_675x300_Build-and-Construction_Final.gif";
import hero2 from "../../assets/images/individual-pages/dss/DSS_HeroBanner_675x300_Tapes-and-Adhesives_Final.gif";

import hero3 from "../../assets/images/individual-pages/dss/DSS_HeroBanner_675x300_PowerTools_Final.gif";
import hero4 from "../../assets/images/individual-pages/dss/DSS_HeroBanner_675x300_Tools-Section_Final.gif";

import weicon1dss from "../../assets/images/individual-pages/dss/Sealants-Gif.gif";
import weicon2dss from "../../assets/images/individual-pages/dss/BuildingEssestials-Gif.gif";



import { useEffect } from "react";
import ProductList from "../../components/product/ProductList";
import $ from "jquery";
import { useState } from "react";
import { connect } from "react-redux";
import { apiJson } from "../../Api";
import { useTranslation } from "react-i18next";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination, Navigation } from "swiper/core";
SwiperCore.use([Pagination, Navigation]);

const SummerDss = (props) => {
    const [products1, setProducts1] = useState([]);
    const [products2, setProducts2] = useState([]);
    const [products3, setProducts3] = useState([]);
    const [products4, setProducts4] = useState([]);
    const { t } = useTranslation();

    useEffect(() => {
        window.document.title = "Dubai Summer Surprises";

        $("html, body").animate({ scrollTop: 0 }, "fast");

        apiJson.
            sections.getDubaiEvents1Section(props.countryId)
            .then(response => {
                if (response.success) {
                    setProducts1(response.data.section.products);
                }
            });

        apiJson.
            sections.getDubaiEvents2Section(props.countryId)
            .then(response => {
                if (response.success) {
                    setProducts2(response.data.section.products);
                }
            });

        apiJson.
            sections.getDubaiEvents3Section(props.countryId)
            .then(response => {
                if (response.success) {
                    setProducts3(response.data.section.products);
                }
            });

        apiJson.
            sections.getDubaiEvents4Section(props.countryId)
            .then(response => {
                if (response.success) {
                    setProducts4(response.data.section.products);
                }
            });
    }, []);
    return (
        <Fragment>
            <div className="product-home-page-desktop mx-auto dsspage">
                <WebsiteHeader />
                <div className="pagesbanner">
                    <img
                        src={dssbanner}
                        alt="category"
                        style={{
                            width: "100%",
                            height: "100%",
                        }}
                    ></img>
                </div>

                <div>
                    <div className="emptyspacer"></div>

                    <div class="shopbycat">
                        <h3>DSS Top Categories</h3>
                    </div>
                    <div className="row catrow"
                        style={{
                            marginLeft: "0px !important",
                            marginRight: "0px !important",
                            width: "100%",
                        }}
                    >
                        <div className="col-lg-2 col-md-2 col-sm-2 col-2 catbox">
                            <Link
                                to={{
                                    pathname: "/" + props.countryUrl +
                                        "-" + props.language +
                                        "/offers/section/build-and-construct-spot",
                                    // search: "?filter_by_category%5B%5D=388&filter_by_brand%5B%5D=249&filter_by_brand%5B%5D=46&filter_by_brand%5B%5D=63"
                                }}
                            >


                                <div className="circlecat">
                                    <img className="img-fluid"
                                        src={dsscat1}
                                        alt="category"
                                        style={{
                                            width: "100%",
                                        }}
                                    ></img>
                                </div>

                            </Link>
                        </div>
                        <div className="col-lg-2 col-md-2 col-sm-2 col-2 catbox">
                            <Link
                                to={{
                                    pathname: "/" + props.countryUrl +
                                        "-" + props.language +
                                        "/offers/section/tapes-and-adhesives",
                                    // search: "?filter_by_category%5B%5D=388&filter_by_brand%5B%5D=249&filter_by_brand%5B%5D=46&filter_by_brand%5B%5D=63"
                                }}
                            >


                                <div className="circlecat">
                                    <img className="img-fluid"
                                        src={dsscat2}
                                        alt="category"
                                        style={{
                                            width: "100%",
                                        }}
                                    ></img>
                                </div>

                            </Link>
                        </div>
                        <div className="col-lg-2 col-md-2 col-sm-2 col-2 catbox">
                            <Link
                                to={{
                                    pathname: "/" + props.countryUrl +
                                        "-" + props.language +
                                        "/offers/section/power-tools",
                                    // search: "?filter_by_category%5B%5D=388&filter_by_brand%5B%5D=249&filter_by_brand%5B%5D=46&filter_by_brand%5B%5D=63"
                                }}
                            >


                                <div className="circlecat">
                                    <img className="img-fluid"
                                        src={dsscat3}
                                        alt="category"
                                        style={{
                                            width: "100%",
                                        }}
                                    ></img>
                                </div>

                            </Link>
                        </div>
                        <div className="col-lg-2 col-md-2 col-sm-2 col-2 catbox">

                            <Link
                                to={{
                                    pathname: "/" + props.countryUrl +
                                        "-" + props.language +
                                        "/offers/section/tools-section",
                                    // search: "?filter_by_category%5B%5D=209&filter_by_category%5B%5D=292&filter_by_category%5B%5D=293&filter_by_brand%5B%5D=249&filter_by_brand%5B%5D=63&filter_by_brand%5B%5D=44&filter_by_brand%5B%5D=125"
                                }}
                            >

                                <div className="circlecat">
                                    <img className="img-fluid"
                                        src={dsscat4}
                                        alt="category"
                                        style={{
                                            width: "100%",
                                        }}
                                    ></img>
                                </div>

                            </Link>
                        </div>
                        <div className="col-lg-2 col-md-2 col-sm-2 col-2 catbox">
                            <Link
                                to={{
                                    pathname: "/" + props.countryUrl +
                                        "-" + props.language +
                                        "/offers/section/diy-essentials",
                                    // search: "?filter_by_category%5B%5D=338&filter_by_brand%5B%5D=249&filter_by_brand%5B%5D=46"
                                }}
                            >

                                <div className="circlecat">
                                    <img className="img-fluid"
                                        src={dsscat5}
                                        alt="category"
                                        style={{
                                            width: "100%",
                                        }}
                                    ></img>
                                </div>
                            </Link>
                        </div>
                        <div className="col-lg-2 col-md-2 col-sm-2 col-2 catbox">
                            <Link
                                to={{
                                    pathname: "/" + props.countryUrl +
                                        "-" + props.language +
                                        "/offers/section/automotive-hub",
                                    // search: "?filter_by_category%5B%5D=464&filter_by_brand%5B%5D=249&filter_by_brand%5B%5D=46"
                                }}
                            >

                                <div className="circlecat">
                                    <img className="img-fluid"
                                        src={dsscat6}
                                        alt="category"
                                        style={{
                                            width: "100%",
                                        }}
                                    ></img>
                                </div>
                            </Link>
                        </div>


                    </div>

                    <div className="row catrow2"
                        style={{
                            marginLeft: "0px !important",
                            marginRight: "0px !important",
                            width: "100%",
                        }}
                    >
                        <div className="col-lg-2 col-md-2 col-sm-2 col-2 catbox">
                            <Link
                                to={{
                                    pathname: "/" + props.countryUrl +
                                        "-" + props.language +
                                        "/offers/section/lawn-and-garden-supplies",
                                    // search: "?filter_by_category%5B%5D=388&filter_by_brand%5B%5D=249&filter_by_brand%5B%5D=46&filter_by_brand%5B%5D=63"
                                }}
                            >


                                <div className="circlecat">
                                    <img className="img-fluid"
                                        src={dsscat7}
                                        alt="category"
                                        style={{
                                            width: "100%",
                                        }}
                                    ></img>
                                </div>

                            </Link>
                        </div>
                        <div className="col-lg-2 col-md-2 col-sm-2 col-2 catbox">
                            <Link
                                to={{
                                    pathname: "/" + props.countryUrl +
                                        "-" + props.language +
                                        "/offers/section/home-essentials",
                                    // search: "?filter_by_category%5B%5D=388&filter_by_brand%5B%5D=249&filter_by_brand%5B%5D=46&filter_by_brand%5B%5D=63"
                                }}
                            >


                                <div className="circlecat">
                                    <img className="img-fluid"
                                        src={dsscat8}
                                        alt="category"
                                        style={{
                                            width: "100%",
                                        }}
                                    ></img>
                                </div>

                            </Link>
                        </div>
                        <div className="col-lg-2 col-md-2 col-sm-2 col-2 catbox">
                            <Link
                                to={{
                                    pathname: "/" + props.countryUrl +
                                        "-" + props.language +
                                        "/offers/section/paints-hub",
                                    // search: "?filter_by_category%5B%5D=388&filter_by_brand%5B%5D=249&filter_by_brand%5B%5D=46&filter_by_brand%5B%5D=63"
                                }}
                            >


                                <div className="circlecat">
                                    <img className="img-fluid"
                                        src={dsscat9}
                                        alt="category"
                                        style={{
                                            width: "100%",
                                        }}
                                    ></img>
                                </div>

                            </Link>
                        </div>
                        <div className="col-lg-2 col-md-2 col-sm-2 col-2 catbox">

                            <Link
                                to={{
                                    pathname: "/" + props.countryUrl +
                                        "-" + props.language +
                                        "/offers/section/clean-and-clear-corner",
                                    // search: "?filter_by_category%5B%5D=209&filter_by_category%5B%5D=292&filter_by_category%5B%5D=293&filter_by_brand%5B%5D=249&filter_by_brand%5B%5D=63&filter_by_brand%5B%5D=44&filter_by_brand%5B%5D=125"
                                }}
                            >

                                <div className="circlecat">
                                    <img className="img-fluid"
                                        src={dsscat10}
                                        alt="category"
                                        style={{
                                            width: "100%",
                                        }}
                                    ></img>
                                </div>

                            </Link>
                        </div>
                        <div className="col-lg-2 col-md-2 col-sm-2 col-2 catbox">
                            <Link
                                to={{
                                    pathname: "/" + props.countryUrl +
                                        "-" + props.language +
                                        "/offers/section/hand-tools",
                                    // search: "?filter_by_category%5B%5D=338&filter_by_brand%5B%5D=249&filter_by_brand%5B%5D=46"
                                }}
                            >

                                <div className="circlecat">
                                    <img className="img-fluid"
                                        src={dsscat11}
                                        alt="category"
                                        style={{
                                            width: "100%",
                                        }}
                                    ></img>
                                </div>
                            </Link>
                        </div>
                        <div className="col-lg-2 col-md-2 col-sm-2 col-2 catbox">
                            <Link
                                to={{
                                    pathname: "/" + props.countryUrl +
                                        "-" + props.language +
                                        "/offers/section/machinery-and-measuring-tools",
                                    // search: "?filter_by_category%5B%5D=464&filter_by_brand%5B%5D=249&filter_by_brand%5B%5D=46"
                                }}
                            >

                                <div className="circlecat">
                                    <img className="img-fluid"
                                        src={dsscat12}
                                        alt="category"
                                        style={{
                                            width: "100%",
                                        }}
                                    ></img>
                                </div>
                            </Link>
                        </div>


                    </div>
                    <div className="emptyspacer15"></div>

                    <div class="shopbycat">
                        <h3>DSS Top Picks</h3>
                    </div>
                    <div className="emptyspacer"></div>
                    <div className="surfacepat">
                        <ProductList
                            type="product"
                            home={true}
                            products={products1}
                        />
                    </div>
                    <div className="emptyspacer"></div>
                    <div className="ViewAllbtn">
                        <Link
                            to={{
                                pathname: "/" + props.countryUrl +
                                    "-" + props.language +
                                    "/offers"

                            }}
                        >
                            {t("all-categories.view-all")}
                        </Link>
                    </div>
                    <div className="emptyspacer"></div>
                </div>
                <div class="shopbycat">
                    <h3>Shop by Brand</h3>
                </div>
                <div className="row catrow"
                    style={{
                        marginLeft: "0px !important",
                        marginRight: "0px !important",
                        width: "100%",
                    }}
                >


                    <Swiper className="shopbybrand"
                        spaceBetween={20}
                        slidesPerView={7}
                        pagination={false}
                        navigation={true}
                    >
                        <SwiperSlide
                            onClick={(e) => e.preventDefault()}
                            key={1}
                        >
                            <Link to={{
                                pathname: "/" + props.countryUrl +
                                    "-" + props.language + "/items/products",
                                search: "filter_by_brand%5B%5D=544"
                            }}>
                                <img
                                    src="https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/rB2fP6qVbvffx5Ws7bZkx9.png"
                                    className="img-img-fluid cleancat"
                                    height="150px"
                                />
                            </Link>
                        </SwiperSlide>
                        <SwiperSlide
                            onClick={(e) => e.preventDefault()}
                            key={1}
                        >
                            <Link to={{
                                pathname: "/" + props.countryUrl +
                                    "-" + props.language + "/items/products",
                                search: "filter_by_brand%5B%5D=164"
                            }}>
                                <img
                                    src="https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/peSAhEhokenQ9EjtR2YGq7.png"
                                    className="img-img-fluid cleancat"
                                    height="150px"
                                />
                            </Link>
                        </SwiperSlide>
                        <SwiperSlide
                            onClick={(e) => e.preventDefault()}
                            key={1}
                        >
                            <Link to={{
                                pathname: "/" + props.countryUrl +
                                    "-" + props.language + "/items/products",
                                search: "filter_by_brand%5B%5D=488"
                            }}>
                                <img
                                    src="https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/qToBx3WMZ3TVBUy9GQugeX.png"
                                    className="img-img-fluid cleancat"
                                    height="150px"
                                />
                            </Link>
                        </SwiperSlide>
                        <SwiperSlide
                            onClick={(e) => e.preventDefault()}
                            key={1}
                        >
                            <Link to={{
                                pathname: "/" + props.countryUrl +
                                    "-" + props.language + "/items/products",
                                search: "filter_by_brand%5B%5D=288"
                            }}>
                                <img
                                    src="https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/nhqWVXmQnqWGCr3X1hxEHg.png"
                                    className="img-img-fluid cleancat"
                                    height="150px"
                                />
                            </Link>
                        </SwiperSlide>
                        <SwiperSlide
                            onClick={(e) => e.preventDefault()}
                            key={1}
                        >
                            <Link to={{
                                pathname: "/" + props.countryUrl +
                                    "-" + props.language + "/items/products",
                                search: "filter_by_brand%5B%5D=42"
                            }}>
                                <img
                                    src="https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/wmo9bcqbAN7dHV4ZpFYcCE.png"

                                    className="img-img-fluid cleancat"
                                    height="150px"
                                />
                            </Link>
                        </SwiperSlide>

                        <SwiperSlide
                            onClick={(e) => e.preventDefault()}
                            key={1}
                        >
                            <Link to={{
                                pathname: "/" + props.countryUrl +
                                    "-" + props.language + "/items/products",
                                search: "filter_by_brand%5B%5D=413"
                            }}>
                                <img
                                    src="https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/cjrqdRMj5VQPTBvSRBtAwU.png"
                                    className="img-img-fluid cleancat"
                                    height="150px"
                                />
                            </Link>
                        </SwiperSlide>
                        <SwiperSlide
                            onClick={(e) => e.preventDefault()}
                            key={1}
                        >
                            <Link to={{
                                pathname: "/" + props.countryUrl +
                                    "-" + props.language + "/items/products",
                                search: "filter_by_brand%5B%5D=454"
                            }}>
                                <img
                                    src="https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/74moKtoepgjSNjvgMkST1c.png"
                                    className="img-img-fluid cleancat"
                                    height="150px"
                                />
                            </Link>
                        </SwiperSlide>
                        <SwiperSlide
                            onClick={(e) => e.preventDefault()}
                            key={1}
                        >
                            <Link to={{
                                pathname: "/" + props.countryUrl +
                                    "-" + props.language + "/items/products",
                                search: "filter_by_brand%5B%5D=453"
                            }}>
                                <img
                                    src="https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/waTUrjpjRM9TYip1d4oHws.jpeg"
                                    className="img-img-fluid cleancat"
                                    height="150px"
                                />
                            </Link>
                        </SwiperSlide>
                        <SwiperSlide
                            onClick={(e) => e.preventDefault()}
                            key={1}
                        >
                            <Link to={{
                                pathname: "/" + props.countryUrl +
                                    "-" + props.language + "/items/products",
                                search: "filter_by_brand%5B%5D=415"
                            }}>
                                <img
                                    src="https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/fDcVJRrX5r45Y3zAUNG8W2.png"
                                    className="img-img-fluid cleancat"
                                    height="150px"
                                />
                            </Link>
                        </SwiperSlide>

                        <SwiperSlide
                            onClick={(e) => e.preventDefault()}
                            key={1}
                        >
                            <Link to={{
                                pathname: "/" + props.countryUrl +
                                    "-" + props.language + "/items/products",
                                search: "filter_by_brand%5B%5D=547"
                            }}>
                                <img
                                    src="https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/pWEdBD4LNRj3kgNf3vJLt9.png"
                                    className="img-img-fluid cleancat"
                                    height="150px"
                                />
                            </Link>
                        </SwiperSlide>
                        <SwiperSlide
                            onClick={(e) => e.preventDefault()}
                            key={1}
                        >
                            <Link to={{
                                pathname: "/" + props.countryUrl +
                                    "-" + props.language + "/items/products",
                                search: "filter_by_brand%5B%5D=520"
                            }}>
                                <img
                                    src="https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/oHAuQGoabKyozCyRSFSYeU.png"
                                    className="img-img-fluid cleancat"
                                    height="150px"
                                />
                            </Link>
                        </SwiperSlide>
                        <SwiperSlide
                            onClick={(e) => e.preventDefault()}
                            key={1}
                        >
                            <Link to={{
                                pathname: "/" + props.countryUrl +
                                    "-" + props.language + "/items/products",
                                search: "filter_by_brand%5B%5D=48"
                            }}>
                                <img
                                    src="https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/fwyPyYsDLWYNkLtUk8MS4E.png"
                                    className="img-img-fluid cleancat"
                                    height="150px"
                                />
                            </Link>
                        </SwiperSlide>
                        <SwiperSlide
                            onClick={(e) => e.preventDefault()}
                            key={1}
                        >
                            <Link to={{
                                pathname: "/" + props.countryUrl +
                                    "-" + props.language + "/items/products",
                                search: "filter_by_brand%5B%5D=56"
                            }}>
                                <img
                                    src="https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/5yEpHy771FyBamNNRqtbEB.png"
                                    className="img-img-fluid cleancat"
                                    height="150px"
                                />
                            </Link>
                        </SwiperSlide>
                        <SwiperSlide
                            onClick={(e) => e.preventDefault()}
                            key={1}
                        >
                            <Link to={{
                                pathname: "/" + props.countryUrl +
                                    "-" + props.language + "/items/products",
                                search: "filter_by_brand%5B%5D=58"
                            }}>
                                <img
                                    src="https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/g7RTVX4RccyCUNRjmSJ31b.png"
                                    className="img-img-fluid cleancat"
                                    height="150px"
                                />
                            </Link>
                        </SwiperSlide>
                        <SwiperSlide
                            onClick={(e) => e.preventDefault()}
                            key={1}
                        >
                            <Link to={{
                                pathname: "/" + props.countryUrl +
                                    "-" + props.language + "/items/products",
                                search: "filter_by_brand%5B%5D=49"
                            }}>
                                <img
                                    src="https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/7ZMiKGr5RjQgKztQv6iQjt.png"
                                    className="img-img-fluid cleancat"
                                    height="150px"
                                />
                            </Link>
                        </SwiperSlide>
                        <SwiperSlide
                            onClick={(e) => e.preventDefault()}
                            key={1}
                        >
                            <Link to={{
                                pathname: "/" + props.countryUrl +
                                    "-" + props.language + "/items/products",
                                search: "filter_by_brand%5B%5D=156"
                            }}>
                                <img
                                    src="https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/7XvXAiAgaHjQRRLPZLAYG3.png"
                                    className="img-img-fluid cleancat"
                                    height="150px"
                                />
                            </Link>
                        </SwiperSlide>
                        <SwiperSlide
                            onClick={(e) => e.preventDefault()}
                            key={1}
                        >
                            <Link to={{
                                pathname: "/" + props.countryUrl +
                                    "-" + props.language + "/items/products",
                                search: "filter_by_brand%5B%5D=43"
                            }}>
                                <img
                                    src="https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/iReSSYpWhmJcPBKxns3BgJ.png"
                                    className="img-img-fluid cleancat"
                                    height="150px"
                                />
                            </Link>
                        </SwiperSlide>
                        <SwiperSlide
                            onClick={(e) => e.preventDefault()}
                            key={1}
                        >
                            <Link to={{
                                pathname: "/" + props.countryUrl +
                                    "-" + props.language + "/items/products",
                                search: "filter_by_brand%5B%5D=171"
                            }}>
                                <img
                                    src="https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/ep7BhDu7z286wNP262KLtt.png"
                                    className="img-img-fluid cleancat"
                                    height="150px"
                                />
                            </Link>
                        </SwiperSlide>
                        <SwiperSlide
                            onClick={(e) => e.preventDefault()}
                            key={1}
                        >
                            <Link to={{
                                pathname: "/" + props.countryUrl +
                                    "-" + props.language + "/items/products",
                                search: "filter_by_brand%5B%5D=201"
                            }}>
                                <img
                                    src="https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/3SJPX3is7wh98KzcGNToDq.jpeg"
                                    className="img-img-fluid cleancat"
                                    height="150px"
                                />
                            </Link>
                        </SwiperSlide>
                        <SwiperSlide
                            onClick={(e) => e.preventDefault()}
                            key={1}
                        >
                            <Link to={{
                                pathname: "/" + props.countryUrl +
                                    "-" + props.language + "/items/products",
                                search: "filter_by_brand%5B%5D=89"
                            }}>
                                <img
                                    src="https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/ZtdMF9jWsKTPPrAoozTQb.png"
                                    className="img-img-fluid cleancat"
                                    height="150px"
                                />
                            </Link>
                        </SwiperSlide>
                        <SwiperSlide
                            onClick={(e) => e.preventDefault()}
                            key={1}
                        >
                            <Link to={{
                                pathname: "/" + props.countryUrl +
                                    "-" + props.language + "/items/products",
                                search: "filter_by_brand%5B%5D=63"
                            }}>
                                <img
                                    src="https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/5tFSvZ45KEwSh8toU7PeA8.jpeg"
                                    className="img-img-fluid cleancat"
                                    height="150px"
                                />
                            </Link>
                        </SwiperSlide>

                        <SwiperSlide
                            onClick={(e) => e.preventDefault()}
                            key={1}
                        >
                            <Link to={{
                                pathname: "/" + props.countryUrl +
                                    "-" + props.language + "/items/products",
                                search: "filter_by_brand%5B%5D=248"
                            }}>
                                <img
                                    src="https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/gdkTTmb629vbMuZXBfw7nY.jpeg"
                                    className="img-img-fluid cleancat"
                                    height="150px"
                                />
                            </Link>
                        </SwiperSlide>
                        <SwiperSlide
                            onClick={(e) => e.preventDefault()}
                            key={1}
                        >
                            <Link to={{
                                pathname: "/" + props.countryUrl +
                                    "-" + props.language + "/items/products",
                                search: "filter_by_brand%5B%5D=517"
                            }}>
                                <img
                                    src="https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/f4JE3isMm9EUYnPnpJG1t.png"
                                    className="img-img-fluid cleancat"
                                    height="150px"
                                />
                            </Link>
                        </SwiperSlide>
                        <SwiperSlide
                            onClick={(e) => e.preventDefault()}
                            key={1}
                        >
                            <Link to={{
                                pathname: "/" + props.countryUrl +
                                    "-" + props.language + "/items/products",
                                search: "filter_by_brand%5B%5D=177"
                            }}>
                                <img
                                    src="https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/wvphiwJYVkvdmWRbwLnLHk.png"
                                    className="img-img-fluid cleancat"
                                    height="150px"
                                />
                            </Link>
                        </SwiperSlide>

                        <SwiperSlide
                            onClick={(e) => e.preventDefault()}
                            key={1}
                        >
                            <Link to={{
                                pathname: "/" + props.countryUrl +
                                    "-" + props.language + "/items/products",
                                search: "filter_by_brand%5B%5D=316"
                            }}>
                                <img
                                    src="https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/3q6d2MXBQmcchs1C1SBJ3L.png"
                                    className="img-img-fluid cleancat"
                                    height="150px"
                                />
                            </Link>
                        </SwiperSlide>

                        <SwiperSlide
                            onClick={(e) => e.preventDefault()}
                            key={1}
                        >
                            <Link to={{
                                pathname: "/" + props.countryUrl +
                                    "-" + props.language + "/items/products",
                                search: "filter_by_brand%5B%5D=329"
                            }}>
                                <img
                                    src="https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/9Yr64PJiix98n5rnXJujxb.png"
                                    className="img-img-fluid cleancat"
                                    height="150px"
                                />
                            </Link>
                        </SwiperSlide>

                    </Swiper>

                </div>
                <div className="club2">
                    <div className="emptyspacer"></div>

                    <div class="shopbycat">
                        <h3>Building Supplies & Adhesives</h3>
                    </div>
                    <div className="row catrow"
                        style={{
                            marginLeft: "0px !important",
                            marginRight: "0px !important",
                            width: "100%",
                        }}
                    >
                        <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                            <Link
                                to={{
                                    pathname: "/" + props.countryUrl +
                                        "-" + props.language +
                                        "/offers/section/build-and-construct",
                                    // search: "?price_low_to_high=true&filter_by_category%5B%5D=286&filter_by_brand%5B%5D=67"
                                }}
                            >


                                <div className="bigbox">
                                    <img className="img-fluid"
                                        src={hero1}
                                        alt="category"
                                        style={{
                                            width: "100%",
                                        }}
                                    ></img>
                                </div>

                            </Link>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                            <Link
                                to={{
                                    pathname: "/" + props.countryUrl +
                                        "-" + props.language +
                                        "/offers/section/tapes-and-adhesives",
                                    // search: "?price_low_to_high=true&filter_by_category%5B%5D=282&filter_by_brand%5B%5D=67"
                                }}
                            >

                                <div className="bigbox">
                                    <img className="img-fluid"
                                        src={hero2}
                                        alt="category"
                                        style={{
                                            width: "100%",
                                        }}
                                    ></img>
                                </div>

                            </Link>
                        </div>

                    </div>
                    <div className="emptyspacer"></div>
                    <div className="surfacepat">
                        <ProductList
                            type="product"
                            home={true}
                            products={products2}
                        />
                    </div>
                    <div className="emptyspacer"></div>
                    <div className="ViewAllbtn">
                        <Link
                            to={{
                                pathname: "/" + props.countryUrl +
                                    "-" + props.language +
                                    "/offers/section/build-and-construct"
                            }}
                        >
                            {t("all-categories.view-all")}
                        </Link>
                    </div>
                    <div className="emptyspacer"></div>
                </div>
                <div className="club2">

                    <div class="shopbycat">
                        <h3>Power & Hand Tools</h3>
                    </div>
                    <div className="row catrow"
                        style={{
                            marginLeft: "0px !important",
                            marginRight: "0px !important",
                            width: "100%",
                        }}
                    >
                        <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                            <Link
                                to={{
                                    pathname: "/" + props.countryUrl +
                                        "-" + props.language +
                                        "/offers/section/power-tools",
                                    // search: "?price_low_to_high=true&filter_by_category%5B%5D=286&filter_by_brand%5B%5D=67"
                                }}
                            >


                                <div className="bigbox">
                                    <img className="img-fluid"
                                        src={hero3}
                                        alt="category"
                                        style={{
                                            width: "100%",
                                        }}
                                    ></img>
                                </div>

                            </Link>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                            <Link
                                to={{
                                    pathname: "/" + props.countryUrl +
                                        "-" + props.language +
                                        "/offers/section/tools-section",
                                    // search: "?price_low_to_high=true&filter_by_category%5B%5D=282&filter_by_brand%5B%5D=67"
                                }}
                            >

                                <div className="bigbox">
                                    <img className="img-fluid"
                                        src={hero4}
                                        alt="category"
                                        style={{
                                            width: "100%",
                                        }}
                                    ></img>
                                </div>

                            </Link>
                        </div>

                    </div>
                    <div className="emptyspacer"></div>
                    <div className="surfacepat">
                        <ProductList
                            type="product"
                            home={true}
                            products={products3}
                        />
                    </div>
                    <div className="emptyspacer"></div>
                    <div className="ViewAllbtn">
                        <Link
                            to={{
                                pathname: "/" + props.countryUrl +
                                    "-" + props.language +
                                    "/offers/section/power-tools"
                            }}
                        >
                            {t("all-categories.view-all")}
                        </Link>
                    </div>
                    <div className="emptyspacer"></div>
                </div>
                <div className="club1">
                    <div className="emptyspacer"></div>

                    <div class="shopbycat">
                        <h3>More DSS Categories</h3>
                    </div>

                    <div className="row catrow"
                        style={{
                            marginLeft: "0px !important",
                            marginRight: "0px !important",
                            width: "100%",
                        }}
                    >
                        <div className="col-lg-3 col-md-3 col-sm-3 col-3 catbox">
                            <Link
                                to={{
                                    pathname: "/" + props.countryUrl +
                                        "-" + props.language +
                                        "/offers/section/wood-work-stars",
                                    // search: "?filter_by_category%5B%5D=273&filter_by_category%5B%5D=444&filter_by_category%5B%5D=445&filter_by_brand%5B%5D=42"
                                }}
                            >


                                <div className="circlecat-paint">
                                    <img className="img-fluid"
                                        src={dsscat13}
                                        alt="category"
                                        style={{
                                            width: "100%",
                                        }}
                                    ></img>
                                </div>
                            </Link>
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-3 col-3 catbox">
                            <Link
                                to={{
                                    pathname: "/" + props.countryUrl +
                                        "-" + props.language +
                                        "/offers/section/electric-essentials",
                                    // search: "?price_low_to_high=true&filter_by_category%5B%5D=437&filter_by_brand%5B%5D=42"
                                }}
                            >


                                <div className="circlecat-paint">
                                    <img className="img-fluid"
                                        src={dsscat14}
                                        alt="category"
                                        style={{
                                            width: "100%",
                                        }}
                                    ></img>
                                </div>
                            </Link>
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-3 col-3 catbox">
                            <Link
                                to={{
                                    pathname: "/" + props.countryUrl +
                                        "-" + props.language +
                                        "/offers/section/communication-tools",
                                    // search: "?price_low_to_high=true&filter_by_category%5B%5D=441&filter_by_brand%5B%5D=42"
                                }}
                            >


                                <div className="circlecat-paint">
                                    <img className="img-fluid"
                                        src={dsscat15}
                                        alt="category"
                                        style={{
                                            width: "100%",
                                        }}
                                    ></img>
                                </div>
                            </Link>
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-3 col-3 catbox">
                            <Link
                                to={{
                                    pathname: "/" + props.countryUrl +
                                        "-" + props.language +
                                        "/offers/section/appliances",
                                    // search: " ?price_low_to_high=true&filter_by_category%5B%5D=444&filter_by_brand%5B%5D=42"
                                }}
                            >



                                <div className="circlecat-paint">
                                    <img className="img-fluid"
                                        src={dsscat16}
                                        alt="category"
                                        style={{
                                            width: "100%",
                                        }}
                                    ></img>
                                </div>

                            </Link>
                        </div>




                    </div>
                    <div className="emptyspacer"></div>
                    <div className="surfacepat">
                        <ProductList
                            type="product"
                            home={true}
                            products={products4}
                        />
                    </div>
                    <div className="emptyspacer"></div>
                    <div className="ViewAllbtn">
                        <Link to={{
                            pathname: "/" + props.countryUrl +
                                "-" + props.language +
                                "/offers"
                        }}>
                            {t("all-categories.view-all")}
                        </Link>
                    </div>
                    <div className="emptyspacer"></div>
                </div>

                <div className="w-100">
                    <WebsiteFooter />
                </div>
            </div>
        </Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
        countryUrl: state.language.countryUrl,
        countryId: state.language.countryId,
        language: state.language.language,
    };
};


export default connect(mapStateToProps, null)(SummerDss);
