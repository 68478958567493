import React, { useEffect, useState } from "react";
import { RiSearchLine } from "react-icons/ri";
import { set_loading } from "../../../redux/actions/LayoutAction";
import logo from "../../../assets/images/logo.png";
import { connect } from "react-redux";
import { set_vendor_data } from "../../../redux/actions/VendorShopAction";
import { toast } from "react-toastify";
import { Redirect } from "react-router-dom";
import MobileLayoutRenderer from "../../../pages/mobile/layout/MobileLayoutRenderer";
import { useTranslation } from "react-i18next";
import { set_country } from "../../../redux/actions/LayoutAction";
import { set_cart_items } from "../../../redux/actions/CartAction";
import { set_redirect_home } from "../../../redux/actions/NotFoundAction";
import "../../../assets/styles/component/mobile/footer-page.scss";
import "../../../assets/styles/pages/ShippingPolicyPage/ShippingPolicyPage.scss";
import "react-toastify/dist/ReactToastify.css";
import pagesbannerimg from "../../../assets/images/footer-pages-banners/Shipping-&-Delivery.jpg";
import { apiJson } from "../../../Api";

function ShippingAndDeliveryMobileApps(props) {
  const [redirect, setRedirect] = useState({ value: false }); // eslint-disable-next-line
  const { t, i18n } = useTranslation();
  const [data, setData] = useState("<p></p>");
  let message = "";
  const showError = () => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  useEffect(() => {
    window.document.title = "Shipping Policy";

    window.scrollTo(0, 0);
    props.set_loading(true);
    apiJson.platformData.platformData().then((response) => {
      if (response.success) {
        setData(response.data);
        props.set_loading(false);
      } else if (!response.status) {
        // eslint-disable-next-line
        message = response.message
          ? response.message !== ""
            ? response.message
            : "something went wrong"
          : "something went wrong";
        showError();
      } else {
        if (response.status >= 400 && response.status < 500) {
          message = response.message
            ? response.message
            : response.errors
            ? response.errors
            : "something went wrong";
          showError();
        } else {
          message = response.message
            ? response.message !== ""
              ? response.message
              : "something went wrong"
            : "something went wrong";
          showError();
        }
      }
    });
  }, []);

  return (
      <div className="home-mobile shipping-page-main">
        <div className="body" style={{ background: "#F5F5F5" }}>
          <div className="pagesbanner">
            <img
              src={pagesbannerimg}
              alt="category"
              style={{
                width: "100%",
                height: "100%",
                maxHeight: "400px",
              }}
            ></img>
          </div>
          <div className="content-wrapper all-page-container">
            <div className="heading w-100">{t("shipping-policy.heading")}</div>

            <div
              className={`${
                props.language === "ar" ? "text-right" : "text-left"
              } mb-1 body w-95 ml-auto mr-auto`}
              dangerouslySetInnerHTML={{
                __html:
                  props.language === "en"
                    ? data[`shipping_policy`]
                    : data[`shipping_policy_${props.language}`],
              }}
            ></div>
          </div>
        </div>
      </div>
  );
}

const mapStateToProps = (state) => {
  return {
    // categories: state.home.categories,
    // products: state.vendor.products,
    // services: state.vendor.services,
    // sections: state.home.sections,
    // positionOne: state.home.positionOne,
    // positionTwo: state.home.positionTwo,
    // positionThree: state.home.positionThree,
    // positionFour: state.home.positionFour,
    // positionFive: state.home.positionFive,
    load: state.layout.loading,
    countryId: state.language.countryId,
    language: state.language.language,
    country: state.language.country,
    countryUrl: state.language.countryUrl,
    redirect: state.notFound.redirect,
  };
};

const mapDispatchToProps = {
  set_vendor_data,
  // set_home_categories,
  set_country,
  set_cart_items,
  set_redirect_home,
  set_loading,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ShippingAndDeliveryMobileApps);
