import React from "react";
import "../../../assets/styles/component/customer/AllItems/ItemsSidebar.scss";
import CategoryData from "./CategoryData";

import { set_all_categories } from "../../../redux/actions/AllCategoriesAction";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { apiJson } from "../../../Api";
import { set_loading } from "../../../redux/actions/LayoutAction";
import { toast } from "react-toastify";
import { useHistory, useLocation } from "react-router-dom";
import { replaceAt } from "../../../utils/utils";

function ItemsSidebar(props) {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();

  let message = "";
  const showError = () => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  const getFiltersParam = (
    page,
    records,
    price_high_to_low,
    price_low_to_high,
    new_arrivals,
    selectedCat
  ) => {
    let categories = "";
    selectedCat &&
      selectedCat.length > 0 &&
      selectedCat.map(
        (cat) => (categories = categories + "&filter_by_category[]=" + cat)
      );

    return (
      (parseInt(page) !== 1 ? "&page=" + page : "") +
      (parseInt(records) !== 30 ? "&records_per_page=" + records : "") +
      (price_high_to_low ? "&price_high_to_low=true" : "") +
      (price_low_to_high ? "&price_low_to_high=true" : "") +
      (new_arrivals ? "&order_by_new_arrivals=" + new_arrivals : "") +
      (selectedCat && selectedCat.length > 0 ? categories : "") +
      (props.activeTab === "products" ? "&tab=p" : "&tab=s")
    );
  };

  const selectCat = (cat) => {
    let filters = getFiltersParam(
      1,
      props.recordPerPage,
      props.price_high_to_low,
      props.price_low_to_high,
      props.new_arrivals,
      cat
    );
    filters = encodeURI(filters);
    history.push(
      location.state
        ? {
            pathname: window.location.pathname,
            search: replaceAt(filters, 0, "?"),
            state: location.state,
          }
        : {
            pathname: window.location.pathname,
            search: replaceAt(filters, 0, "?"),
          }
    );

    props.set_loading(true);
    window.scroll(0, 0);
    apiJson.categories
      .getItems(
        props.countryId,
        props.activeTab,
        1,
        props.recordPerPage,
        props.price_high_to_low,
        props.price_low_to_high,
        props.new_arrivals,
        cat
      )
      .then((response) => {
        if (response.success) {
          props.set_all_categories({
            items: response.data,
          });
          props.set_loading(false);
        } else if (!response.status) {
          props.set_loading(false);
          // eslint-disable-next-line
          message = response.message
            ? response.message !== ""
              ? response.message
              : t("errors.wrong")
            : t("errors.wrong");
          showError();
        } else {
          props.set_loading(false);
          if (response.status >= 400 && response.status < 500) {
            message = response.message
              ? response.message
              : response.errors
              ? response.errors
              : t("errors.wrong");
            showError();
          } else {
            message = response.message
              ? response.message !== ""
                ? response.message
                : t("errors.wrong")
              : t("errors.wrong");
            showError();
          }
        }
      });
  };

  return (
    <div className={`items-sidebar-desktop `}>
      <div
        className="sidebar-items-cont pointer"
        onClick={() => {
          props.set_all_categories({
            selectedCat: "",
          });
          selectCat("");
        }}
      >
        <span className="sidebar-heading">{t("itemsSidebar.category")}</span>
      </div>

      <div>
        <CategoryData
          items={props.originalItems}
          categories={props.category}
          selectedCat={(category) => {
            props.set_all_categories({
              selectedCat: category,
            });
            selectCat(category);
          }}
        />
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    category: state.categories.category,
    items: state.categories.items,
    originalItems: state.categories.originalItems,
    width: state.layout.screenWidth,
    recordPerPage: state.categories.recordPerPage,
    price_high_to_low: state.categories.price_high_to_low,
    price_low_to_high: state.categories.price_low_to_high,
    new_arrivals: state.categories.new_arrivals,
    selectedCat: state.categories.selectedCat,
    countryId: state.language.countryId,

    activeTab: state.categories.activeTab,
  };
};

const mapDispatchToProps = {
  set_all_categories,
  set_loading,
};
export default connect(mapStateToProps, mapDispatchToProps)(ItemsSidebar);
