import React, { Fragment, useEffect } from "react";
import { Link } from 'react-router-dom';
import { Row } from "reactstrap";
import { Modal, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.css";
import "../../assets/styles/pages/producthomepage.scss";
import MainSlider from "../homepage/MainSlider";
import ProductList from "../product/ProductList";
import WebsiteHeader from "../header/WebsiteHeader";
import WebsiteFooter from "../Footer/WebsiteFooter";
import ProductCategoryList from "../product/ProductCategoryList";
import Working from "../product/Working";
import { set_home_categories } from "../../redux/actions/HomeAction";
import { apiJson } from "../../Api";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import YelloColorImage from "../../assets/images/homepage/YelloColorImage.png";
import BlueColorImage from "../../assets/images/homepage/BlueColorImage.png";
import { set_country, set_loading } from "../../redux/actions/LayoutAction";
import { set_cart_items } from "../../redux/actions/CartAction";
import { set_redirect_home } from "../../redux/actions/NotFoundAction";
import HeartBanner from "../../assets/images/Alisuq heartbeat Compressed Faster.gif";
import { set_user } from "../../redux/actions/AuthAction";
import OffersBanner from "../../assets/images/offers-banner.png";
import MiddleSection from "./MiddleSection";

const ProductHomePageOld = (props) => {
  const [offersModalShow, setOffersModalShow] = React.useState(false);

  let message = "";
  const showError = () => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  const { t } = useTranslation();
  let sections = [];

  function shuffle(array) {
    let currentIndex = array.length,
      randomIndex;
    while (currentIndex !== 0) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex],
        array[currentIndex],
      ];
    }

    return array;
  }

  const setSection = (section, position, allProducts) => {
    switch (position) {
      case 2:
        props.set_home_categories({
          positionTwo:
            section?.data?.length < 1
              ? { ...section, data: allProducts }
              : { ...section, data: section.data },
        });
        break;
      case 3:
        props.set_home_categories({
          positionThree:
            section?.data?.length < 1
              ? { ...section, data: allProducts }
              : { ...section, data: section.data },
        });
        break;
      case 4:
        props.set_home_categories({
          positionFour:
            section?.data?.length < 1
              ? { ...section, data: allProducts }
              : { ...section, data: section.data },
        });
        break;
      case 5:
        props.set_home_categories({
          positionFive:
            section?.data?.length < 1
              ? { ...section, data: allProducts }
              : { ...section, data: section.data },
        });
        break;
      default:
        sections.push(section);
    }
  };

  useEffect(() => {
    document.title = "Alisuq.com - Buy All Your Home Needs Online, UAE";
  }, []);

  useEffect(() => {
    props.redirect && props?.set_redirect_home(false);
    window.scroll(0, 0);
    props.set_loading(true);
    (localStorage.getItem("access_token") ||
      localStorage.getItem("user_key")) &&
      apiJson.cart.getCartItems(props.countryId).then((response) => {
        if (response.success) {
          props.set_cart_items({ cart_items: response.data });
        } else if (!response.status) {
          // eslint-disable-next-line
          message = response.message
            ? response.message !== ""
              ? response.message
              : t("errors.wrong")
            : t("errors.wrong");
          showError();
        } else {
          if (response.status >= 400 && response.status < 500) {
            message = response.message
              ? response.message
              : response.errors
                ? response.errors
                : t("errors.wrong");
            showError();
          } else {
            message = response.message
              ? response.message !== ""
                ? response.message
                : t("errors.wrong")
              : t("errors.wrong");
            showError();
          }
        }
      });

    apiJson.homepage.getBanners(props.countryId).then((response) => {
      if (response.success) {
        let primaryBanners = [];
        let secondaryBanners = [];

        response.data.map((banner) =>
          banner.banner_type === "platform_primary"
            ? primaryBanners.push(banner)
            : secondaryBanners.push(banner)
        );
        props.set_home_categories({
          primaryBanners: primaryBanners,
          secondaryBanners: secondaryBanners,
        });
        props.set_loading(false);
      } else if (!response.status) {
        props.set_loading(false);
        message = response.message
          ? response.message !== ""
            ? response.message
            : t("errors.wrong")
          : t("errors.wrong");
        showError();
      } else {
        props.set_loading(false);
        if (response.status >= 400 && response.status < 500) {
          message = response.message
            ? response.message
            : response.errors
              ? response.errors
              : t("errors.wrong");
          showError();
        } else {
          message = response.message
            ? response.message !== ""
              ? response.message
              : t("errors.wrong")
            : t("errors.wrong");
          showError();
        }
      }
    });

    let allProducts = [];

    apiJson.homepage.getFirstSection(props.countryId).then((response) => {
      if (response.success) {
        const temp = {
          title_en: response.data.section.title_en,
          title_ar: response.data.section.title_ar,
          data: [
            ...response.data.section.products,
            ...response.data.section.services,
          ],
        };
        props.set_home_categories({
          positionOne: {
            title_en: response.data.section.title_en,
            title_ar: response.data.section.title_ar,
            data: [
              ...response.data.section.products,
              ...response.data.section.services,
            ],
          },
        });

        let i = 0;
        response.data.section_ids?.map((id) =>
          apiJson.homepage.getSections(id, props.countryId).then((section) => {
            ++i;
            if (i === 1) {
              temp?.data?.length < 1 &&
                props.set_home_categories({
                  positionOne: { ...temp, data: allProducts },
                });
            }
            if (section.success) {
              section.data.title_en !== "Weekly Sale 1" &&
                section.data.title_en !== "Weekly Sale 2" &&
                section.data.title_en !== "Combo Sale 1" &&
                section.data.title_en !== "Combo Sale 2" &&
                section.data.title_en !== "Brand Favourites 1" &&
                section.data.title_en !== "Brand Favourites 2" &&
                section.data.title_en !== "Brand Favourites 3" &&
                section.data.title_en !== "Offers" &&
                setSection(
                  {
                    title_en: section.data.title_en,
                    title_ar: section.data.title_ar,
                    data: [...section.data.products, ...section.data.services],
                  },

                  section.data.position ? section.data.position : 7,
                  shuffle(allProducts)
                );
              if (i === response.data.section_ids.length) {
                props.set_home_categories({
                  sections: sections,
                });
              }
            }
          })
        );
      }
    });

    apiJson.allitems
      .getItems(props.countryId, "products", 1, 10)
      .then((response) => {
        response.success
          ? (allProducts = response.data.products)
          : (allProducts = []);
      });

    apiJson.homepage.getCategories(props.countryId).then((response) => {
      if (response.success) {
        props.set_home_categories({ categories: response.data });
      } else if (!response.status) {
        message = response.message
          ? response.message !== ""
            ? response.message
            : t("errors.wrong")
          : t("errors.wrong");
        showError();
      } else {
        if (response.status >= 400 && response.status < 500) {
          message = response.message
            ? response.message
            : response.errors
              ? response.errors
              : t("errors.wrong");
          showError();
        } else {
          message = response.message
            ? response.message !== ""
              ? response.message
              : t("errors.wrong")
            : t("errors.wrong");
          showError();
        }
      }
    });

    apiJson.cart.guestUser(props.countryId).then((response) => {
      if (response.success) {
        props.set_user({
          guestUser: response.data.platform_settings.guest_user_checkout,
          guestSignin: false,
        });
      } else {
        props.set_user({
          guestUser: false,
        });
      }
    });
  }, [props.countryId]);
  return (
    <Fragment>
      <div className="product-home-page-desktop mx-auto">
        <WebsiteHeader />
        {!props.loading && (
          <>
            <div className="content-wrapper all-page-container">
              <MainSlider />
              <Row className="working-row mx-auto">
                <Working />
              </Row>
              <MiddleSection />

              {props.positionOne?.data?.length > 0 && (
                <Row
                  key={0}
                  className={`${props.positionOne.title_en
                    .toLowerCase()
                    .replace(/ /g, "-")} product-list-row mx-auto`}
                >
                  <div className="header">
                    {props.positionOne[`title_${props.language}`]}
                  </div>

                  <ProductList
                    type="product"
                    home={true}
                    products={props.positionOne.data}
                  />
                  <div />
                </Row>
              )}

              <Row
                className={`kid-fashion-banner-row mx-auto ${props.positionOne?.data?.length > 0 ? "" : " invisible"
                  } `}
              >
                <div className="kid-fashion-banner-container">
                  <img
                    src={HeartBanner}
                    alt="Heart Banner"
                    width="100%"
                    height="300px"
                  />
                </div>
              </Row>

              {props.positionTwo?.data?.length > 0 && (
                <Row
                  key={1}
                  className={`${props.positionTwo.title_en
                    .toLowerCase()
                    .replace(/ /g, "-")} product-list-row mx-auto`}
                >
                  <div className="header">
                    {props.positionTwo[`title_${props.language}`]}
                  </div>
                  <ProductList
                    type="product"
                    home={true}
                    products={props.positionTwo.data}
                  />
                  <div />
                </Row>
              )}

              <div
                className={`two-banners-row mx-auto ${props.positionOne?.data?.length > 0 ? "" : " invisible"
                  } `}
              >
                <div className="two-banners-container">
                  {props.banners?.length > 1 ? (
                    <div className="banner-inner">
                      <div className="left-banner">
                        {props.banners[0]?.redirects_to_link ? (
                          <a href={props.banners[0]?.redirects_to_link}>
                            <img
                              src={props.banners[0]?.image || YelloColorImage}
                              alt="KidFashion"
                              width="100%"
                              height="300px"
                            />
                          </a>
                        ) : (
                          <img
                            src={props.banners[0]?.image || YelloColorImage}
                            alt="KidFashion"
                            width="100%"
                            height="300px"
                          />
                        )}
                      </div>
                      <div className="right-banner">
                        {props.banners[1]?.redirects_to_link ? (
                          <a href={props.banners[1]?.redirects_to_link}>
                            <img
                              src={props.banners[1]?.image || YelloColorImage}
                              alt="KidFashion"
                              width="100%"
                              height="300px"
                            />
                          </a>
                        ) : (
                          <img
                            src={props.banners[1]?.image || YelloColorImage}
                            alt="KidFashion"
                            width="100%"
                            height="300px"
                          />
                        )}
                      </div>
                    </div>
                  ) : (
                    <div className="banner-inner">
                      <div className="left-banner">
                        <img
                          src={YelloColorImage}
                          alt="KidFashion"
                          width="100%"
                          height="300px"
                        />
                      </div>
                      <div className="right-banner">
                        <img
                          src={BlueColorImage}
                          alt="KidFashion"
                          width="100%"
                          height="300px"
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>

              {props.positionThree?.data?.length > 0 && (
                <Row
                  key={2}
                  className={`${props.positionThree.title_en
                    .toLowerCase()
                    .replace(/ /g, "-")} product-list-row mx-auto`}
                >
                  <div className="header">
                    {props.positionThree[`title_${props.language}`]}
                  </div>
                  <ProductList
                    type="product"
                    home={true}
                    products={props.positionThree.data}
                  />
                  <div />
                </Row>
              )}

              <div
                className={`top-category-header ${props.positionOne?.data?.length > 0 ? "" : " invisible"
                  } `}
              >
                <span>{t("homePage.alisuq-top-cat")}</span>
              </div>

              <Row
                className={`mt-4 mx-auto ${props.positionOne?.data?.length > 0 ? "" : " invisible"
                  } `}
              >
                <ProductCategoryList />
              </Row>

              <Row
                className={`kid-fashion-banner-row mx-auto ${props.positionOne?.data?.length > 0 ? "" : " invisible"
                  } `}
              >
                {props.banners?.length > 0 ? (
                  <div className="kid-fashion-banner-container">
                    {props.banners[2]?.redirects_to_link ? (
                      <a href={props.banners[2]?.redirects_to_link}>
                        <img
                          src={props.banners[2]?.image || YelloColorImage}
                          alt="KidFashion"
                          width="100%"
                          height="300px"
                        />
                      </a>
                    ) : (
                      <img
                        src={props.banners[2]?.image || YelloColorImage}
                        alt="KidFashion"
                        width="100%"
                        height="300px"
                      />
                    )}
                  </div>
                ) : (
                  <div className="kid-fashion-banner-container">
                    <img
                      src={YelloColorImage}
                      alt="KidFashion"
                      width="100%"
                      height="300px"
                    />
                  </div>
                )}
              </Row>

              {props.positionFour?.data?.length > 0 && (
                <Row
                  key={3}
                  className={`${props.positionFour.title_en
                    .toLowerCase()
                    .replace(/ /g, "-")} product-list-row mx-auto`}
                >
                  <div className="header">
                    {props.positionFour[`title_${props.language}`]}
                  </div>
                  <ProductList
                    type="product"
                    home={true}
                    products={props.positionFour.data}
                  />
                  <div />
                </Row>
              )}

              <Row
                className={`kid-fashion-banner-row mx-auto ${props.positionOne?.data?.length > 0 ? "" : " invisible"
                  } `}
              >
                {props.banners?.length > 0 ? (
                  <div className="kid-fashion-banner-container">
                    {props.banners[3]?.redirects_to_link ? (
                      <a href={props.banners[3]?.redirects_to_link}>
                        <img
                          src={props.banners[3]?.image || YelloColorImage}
                          alt="KidFashion"
                          width="100%"
                          height="300px"
                        />
                      </a>
                    ) : (
                      <img
                        src={props.banners[3]?.image || YelloColorImage}
                        alt="KidFashion"
                        width="100%"
                        height="300px"
                      />
                    )}
                  </div>
                ) : (
                  <div className="kid-fashion-banner-container">
                    <img
                      src={YelloColorImage}
                      alt="KidFashion"
                      width="100%"
                      height="300px"
                    />
                  </div>
                )}
              </Row>

              {props.positionFive?.data?.length > 0 && (
                <Row
                  key={4}
                  className={`${props.positionFive.title_en
                    .toLowerCase()
                    .replace(/ /g, "-")} product-list-row mx-auto`}
                >
                  <div className="header">
                    {props.positionFive[`title_${props.language}`]}
                  </div>
                  <ProductList
                    type="product"
                    home={true}
                    products={props.positionFive.data}
                  />
                  <div />
                </Row>
              )}
              <Row
                className={`kid-fashion-banner-row mx-auto last-bannernew ${props.positionOne?.data?.length > 0 ? "" : " invisible"
                  } `}
              >
                {props.banners?.length > 0 ? (
                  <div className="kid-fashion-banner-container">
                    {props.banners[4]?.redirects_to_link ? (
                      <a href={props.banners[4]?.redirects_to_link}>
                        <img
                          src={props.banners[4]?.image || YelloColorImage}
                          alt="KidFashion"
                          width="100%"
                          height="300px"
                        />
                      </a>
                    ) : (
                      <img
                        src={props.banners[4]?.image || YelloColorImage}
                        alt="KidFashion"
                        width="100%"
                        height="300px"
                      />
                    )}
                  </div>
                ) : (
                  <div className="kid-fashion-banner-container">
                    <img
                      src={YelloColorImage}
                      alt="KidFashion"
                      width="100%"
                      height="300px"
                    />
                  </div>
                )}
              </Row>

              {props.sections?.map(
                (section, index) =>
                  section.data.length > 0 && (
                    <Row
                      key={index + 5}
                      className={`${section.title_en
                        .toLowerCase()
                        .replace(/ /g, "-")} product-list-row mx-auto`}
                    >
                      <div className="header">
                        {section[`title_${props.language}`]}
                      </div>
                      <ProductList
                        type="product"
                        home={true}
                        products={section.data}
                      />
                      <div />
                    </Row>
                  )
              )}
            </div>

            <div>
              <OffersModal
                show={offersModalShow}
                onHide={() => setOffersModalShow(false)}
              />
            </div>

            <WebsiteFooter />
          </>
        )}
      </div>
    </Fragment>
  );
};

function OffersModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="offers-modal"
    >
      <Modal.Body>
        <Link to="/uae-en/electrical-accessories-sale">
          <div className="text-center">
            <img src={OffersBanner} className="img img-fluid" style={{ maxHeight: "500px", maxWidth: "500px", margin: "auto" }} />
          </div>
        </Link>
      </Modal.Body>
    </Modal>
  )
}

const mapStateToProps = (state) => {
  return {
    banners: state.home.secondaryBanners,
    countryId: state.language.countryId,
    country: state.language.country,
    recommendedForYou: state.home.recommendedForYou,
    diyProducts: state.home.diyProducts,
    paintersTouch: state.home.paintersTouch,
    krudKutter: state.home.krudKutter,
    sections: state.home.sections,
    positionOne: state.home.positionOne,
    positionTwo: state.home.positionTwo,
    positionThree: state.home.positionThree,
    positionFour: state.home.positionFour,
    positionFive: state.home.positionFive,
    language: state.language.language,
    countryUrl: state.language.countryUrl,
    redirect: state.notFound.redirect,

    loading: state.layout.loading,
  };
};

const mapDispatchToProps = {
  set_home_categories,
  set_country,
  set_cart_items,
  set_redirect_home,
  set_loading,
  set_user,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductHomePageOld);
