import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import "../../../../../assets/styles/customer/account/orders/shipments/shipment.scss";
import "../../../../../assets/styles/utils.scss";

import ShipmentItem from "./ShipmentItem";
import ShipmentItemMobile from "./ShipmentItemMobile";

import { GoPackage } from "react-icons/go";
import { connect } from "react-redux";

const status = {
  pending: "ريثما",
  cancelled: "ألغيت",
  rejected: "مرفوض",
  customer_cancellation: "إلغاء العميل",
  processing: "معالجة",
  shipped: "شحنها",
  delivered: "تم التوصيل",
  collected: "تم جمعها",
  partially_returned: "عاد جزئيا",
  fully_returned: "عاد بالكامل",
  return_requested: "طلب الإرجاع",
  returned: "عاد",
  return_rejected: "تم رفض الإرجاع",
};

const pending = (
  <Row className="progress no-gutters mt-1">
    <Col xs={3} md={3} className="bar"></Col>
    <Col xs={3} md={3} className="default-bar"></Col>
    <Col xs={3} md={3} className="default-bar"></Col>
    <Col xs={3} md={3} className="default-bar"></Col>
  </Row>
);
const processing = (
  <Row className="progress no-gutters mt-1">
    <Col xs={3} md={3} className="bar"></Col>
    <Col xs={3} md={3} className="bar"></Col>
    <Col xs={3} md={3} className="default-bar"></Col>
    <Col xs={3} md={3} className="default-bar"></Col>
  </Row>
);

const shipped = (
  <Row className="progress no-gutters mt-1">
    <Col xs={3} md={3} className="bar"></Col>
    <Col xs={3} md={3} className="bar"></Col>
    <Col xs={3} md={3} className="bar"></Col>
    <Col xs={3} md={3} className="default-bar"></Col>
  </Row>
);

const ready_for_collection = (
  <Row className="progress no-gutters mt-1">
    <Col xs={3} md={3} className="bar"></Col>
    <Col xs={3} md={3} className="bar"></Col>
    <Col xs={3} md={3} className="bar"></Col>
    <Col xs={3} md={3} className="default-bar"></Col>
  </Row>
);

const delivered = (
  <Row className="progress no-gutters mt-1">
    <Col xs={3} md={3} className="bar"></Col>
    <Col xs={3} md={3} className="bar"></Col>
    <Col xs={3} md={3} className="bar"></Col>
    <Col xs={3} md={3} className="bar"></Col>
  </Row>
);

const collected = (
  <Row className="progress no-gutters mt-1">
    <Col xs={3} md={3} className="bar"></Col>
    <Col xs={3} md={3} className="bar"></Col>
    <Col xs={3} md={3} className="bar"></Col>
    <Col xs={3} md={3} className="bar"></Col>
  </Row>
);

const cancelled = (
  <Row className="progress no-gutters mt-1">
    <Col xs={3} md={3} className="red-bar"></Col>
    <Col xs={3} md={3} className="red-bar"></Col>
    <Col xs={3} md={3} className="red-bar"></Col>
    <Col xs={3} md={3} className="red-bar"></Col>
  </Row>
);

const rejected = (
  <Row className="progress no-gutters mt-1">
    <Col xs={3} md={3} className="red-bar"></Col>
    <Col xs={3} md={3} className="red-bar"></Col>
    <Col xs={3} md={3} className="red-bar"></Col>
    <Col xs={3} md={3} className="red-bar"></Col>
  </Row>
);

const partially_returned = (
  <Row className="progress no-gutters mt-1">
    <Col xs={3} md={3} className="orange-bar"></Col>
    <Col xs={3} md={3} className="orange-bar"></Col>
    <Col xs={3} md={3} className="default-bar"></Col>
    <Col xs={3} md={3} className="default-bar"></Col>
  </Row>
);

const fully_returned = (
  <Row className="progress no-gutters mt-1">
    <Col xs={3} md={3} className="red-bar"></Col>
    <Col xs={3} md={3} className="red-bar"></Col>
    <Col xs={3} md={3} className="red-bar"></Col>
    <Col xs={3} md={3} className="red-bar"></Col>
  </Row>
);

const Shipment = (props) => {
  return (
    <div className="shipment custom-card-2 mt-3">
      <div className="wrapper">
        <div className="shipment-summary vertical-separator px-2 pt-3 pb-2">
          <Row className="no-gutters">
            <Col md={12} className="pb-2">
              <span className="shipment-title text-uppercase faded-black-2-text fw-600">
                <GoPackage className="mr-2" />
                {props.type === "product"
                  ? props.language?.toLowerCase() === "en"
                    ? "Shipment"
                    : "شحنة"
                  : props.language?.toLowerCase() === "en"
                  ? "Service"
                  : "الخدمات"}{" "}
                {props.index + 1} :
              </span>
              <span className="shipment-number faded-black-2-text">
                {props.orderId} - {props.shipment.id}
              </span>
            </Col>
          </Row>
          {props.shipment.status && (
            <>
              {props.shipment.status === "pending" && pending}
              {props.shipment.status === "return_requested" && pending}
              {props.shipment.status === "cancelled" && cancelled}
              {(props.shipment.status === "rejected" || props.shipment.status === "customer_cancellation") && rejected}
              {props.shipment.status === "processing" && processing}
              {props.shipment.status === "shipped" && shipped}
              {props.shipment.status === "ready_for_collection" && ready_for_collection}
              {props.shipment.status === "delivered" && delivered}
              {props.shipment.status === "collected" && collected}
              {props.shipment.status === "partially_returned" &&
                partially_returned}
              {props.shipment.status === "fully_returned" && fully_returned}
              {props.shipment.status === "returned" && fully_returned}
              {props.shipment.status === "return_rejected" && fully_returned}
            </>
          )}
          <Col xs={3} md={3} className="bar"></Col>
          <Col xs={3} md={3} className="bar"></Col>
          <Col xs={3} md={3} className="bar"></Col>
          <Col xs={3} md={3} className="bar"></Col>
          <div className="status">
            <div
              className={`${
                props.width !== "sm" ? "row " : "mt-2"
              } display-row-mob no-gutters`}
            >
              <div
                className={`${
                  props.shipment?.status === "pending" ||
                  props.shipment?.status === "processing" ||
                  props.shipment?.status === "shipped" ||
                  props.shipment?.status === "ready_for_collection" ||
                  props.shipment?.status === "return_requested" ||
                  props.shipment?.status === "delivered" ||
                  props.shipment?.status === "collected"
                    ? "green-text "
                    : props.shipment?.status === "partially_returned"
                    ? "orange-text "
                    : "red-text "
                } ${props.width !== "sm" ? "col-md-6 mt-1" : ""} fw-700`}
              >
                {props.language?.toLowerCase() === "en"
                  ? props.shipment?.status.replace("_", " ")
                  : status[props.shipment?.status]
                  ? status[props.shipment?.status]
                  : ""}
              </div>
            </div>
          </div>
        </div>
        <div className="shipment-items">
          {props.type.toLowerCase() === "product".toLowerCase() ? (
            props.shipment.line_items.map((shipmentItem) =>
              props.width === "sm" ? (
                <ShipmentItemMobile
                  shipmentItem={shipmentItem}
                  currency={props.currency}
                  key={shipmentItem.id}
                />
              ) : (
                <ShipmentItem
                  shipmentItem={shipmentItem}
                  currency={props.currency}
                  key={shipmentItem.id}
                />
              )
            )
          ) : props.width === "sm" ? (
            <ShipmentItemMobile
              shipmentItem={props.shipment}
              currency={props.currency}
              key={props.shipment.id}
              type={props.type}
            />
          ) : (
            <ShipmentItem
              shipmentItem={props.shipment}
              currency={props.currency}
              key={props.shipment.id}
              type={props.type}
            />
          )}
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    width: state.layout.screenWidth,
    language: state.language.language,
  };
};

export default connect(mapStateToProps, null)(Shipment);
