import React, { useEffect, useState } from "react";
import CategoryHeader from "./CategoryHeader";
import Pagination from "react-paginate";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import "../../../assets/styles/component/customer/AllItems/ItemsMain.scss";
import ProductCard from "../../product/ProductCard";
import { set_all_categories } from "../../../redux/actions/AllCategoriesAction";
import { connect } from "react-redux";
import { getDesktopImage, replaceAt } from "../../../utils/utils";
import { apiJson } from "../../../Api";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { set_loading } from "../../../redux/actions/LayoutAction";
import { Link, useHistory, useLocation } from "react-router-dom";

const ItemsMain = (props) => {
  const history = useHistory();
  const location = useLocation();
  const [top_brands, setTopBrands] = useState([]);
  const filters = location.search.length
    ? decodeURI("&" + location.search.substring(1, location.search.length))
    : undefined;
  const { t } = useTranslation();
  let message = "";


  // useEffect(() => {
  //   if (filters) {
  //     let categoryFilters = "";
  //     categoryFilters = filters
  //       ?.split("&filter_by_category[]=")
  //       ?.slice(1, filters?.split("&filter_by_category[]=").length)
  //       ?.map((category) =>
  //         category.includes("&")
  //           ? parseInt(category.split("&")[0])
  //           : parseInt(category)
  //       );

  //     if (categoryFilters) {
  //       apiJson.categories
  //         .getCategory(categoryFilters[0], props.countryId)
  //         .then(response => {
  //           if (response.success) {
  //             var brands = [];
  //             if (response.data.brands && response.data.brands.length > 0) {
  //               if (response.data.length > 4) {
  //                 for (var i = 0; i < 5; i++) {
  //                   brands = brands.concat(response.data.brands[i]);
  //                 }
  //               }
  //               else {
  //                 for (var i = 0; i < response.data.brands.length; i++) {
  //                   brands = brands.concat(response.data.brands[i]);
  //                 }
  //               }
  //             }
  //             setTopBrands(brands);
  //           }
  //           else {
  //             if (response.status >= 400 && response.status < 500) {
  //               message = response.message
  //                 ? response.message
  //                 : response.errors
  //                   ? response.errors
  //                   : t("errors.wrong");
  //               showError();
  //             } else {
  //               message = response.message
  //                 ? response.message !== ""
  //                   ? response.message
  //                   : t("errors.wrong")
  //                 : t("errors.wrong");
  //               showError();
  //             }
  //           }
  //         })
  //     }
  //   }
  // }, [filters]);

  const showError = () => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const getFiltersParam = (
    page,
    records,
    price_high_to_low,
    price_low_to_high,
    new_arrivals,
    selectedCat
  ) => {
    let categories = "";
    selectedCat &&
      selectedCat.length > 0 &&
      selectedCat.map(
        (cat) => (categories = categories + "&filter_by_category[]=" + cat)
      );

    return (
      (parseInt(page) !== 1 ? "&page=" + page : "") +
      (parseInt(records) !== 30 ? "&records_per_page=" + records : "") +
      (price_high_to_low ? "&price_high_to_low=true" : "") +
      (price_low_to_high ? "&price_low_to_high=true" : "") +
      (new_arrivals ? "&order_by_new_arrivals=" + new_arrivals : "") +
      (selectedCat && selectedCat.length > 0 ? categories : "") +
      (props.activeTab === "products" ? "&tab=p" : "&tab=s")
    );
  };

  const handlePageChange = (pageNumber) => {
    let filters = getFiltersParam(
      pageNumber.selected + 1,
      props.recordPerPage,
      props.price_high_to_low,
      props.price_low_to_high,
      props.new_arrivals,
      props.selectedCat === "" ? [] : props.selectedCat
    );
    filters = encodeURI(filters);
    history.push(
      location.state
        ? {
          pathname: window.location.pathname,
          search: replaceAt(filters, 0, "?"),
          state: location.state,
        }
        : {
          pathname: window.location.pathname,
          search: replaceAt(filters, 0, "?"),
        }
    );
    window.scroll(0, 0);
    props.set_loading(true);
    apiJson.categories
      .getItems(
        props.countryId,
        props.activeTab,
        pageNumber.selected + 1,
        props.recordPerPage,
        props.price_high_to_low,
        props.price_low_to_high,
        props.new_arrivals,
        props.selectedCat === "" ? [] : props.selectedCat
      )
      .then((response) => {
        if (response.success) {
          props.set_all_categories({
            page: pageNumber.selected + 1,
            items: response.data,
          });
          props.set_loading(false);
        } else if (!response.status) {
          props.set_loading(false);
          // eslint-disable-next-line
          message = response.message
            ? response.message !== ""
              ? response.message
              : t("errors.wrong")
            : t("errors.wrong");
          showError();
        } else {
          props.set_loading(false);
          if (response.status >= 400 && response.status < 500) {
            message = response.message
              ? response.message
              : response.errors
                ? response.errors
                : t("errors.wrong");
            showError();
          } else {
            message = response.message
              ? response.message !== ""
                ? response.message
                : t("errors.wrong")
              : t("errors.wrong");
            showError();
          }
        }
      });
  };
  return (
    <div className="item-main-desktop">
      <div className="w-100">
        <CategoryHeader />
      </div>

      <div>
        {
          <div className="top-brands-desktop-view">
            {
              top_brands && top_brands.length > 0 ?
                <>
                  <div className="topbrand-head">
                    Top Brands
                  </div>
                  <div className="row brands-row no-gutters">
                    {
                      top_brands?.map((brand) => {
                        return (
                          <div className="col-width text-center">
                            <Link
                              to={{
                                pathname:
                                  "/" +
                                  props.countryUrl +
                                  "-" +
                                  props.language +
                                  "/items/products",
                                state: { brand: brand.id },
                              }}>
                              <img src={brand.image} className="brand-logo" />
                              <p className="text-center brands-title">{brand.name_en}</p>
                            </Link>
                          </div>
                        )
                      })
                    }
                  </div>
                </>
                :
                <></>
            }
          </div>
        }
      </div>

      <div>
        {props.data && (
          <div className="items-body row no-gutters">

            {props.items[`${props.activeTab}`]?.map((value) =>
              value.variants ? (
                <ProductCard
                  key={value.id + "prod"}
                  product={{
                    id: value.id,
                    line_item_id: value.variants[0].id,
                    img: getDesktopImage(
                      value.variants[0].featured_image_resized,
                      value.variants[0].featured_image
                    ),
                    slug: value.slug,
                    storeLogo: value.store_logo,
                    type: "products",
                    description: value[`name_${props.language}`],
                    price_after_discount: parseFloat(
                      value.variants[0].discounted_price
                    ),
                    description_en: value[`name_en`],
                    category_en: value.category_title_en,

                    price_before_discount: parseFloat(value.variants[0].price),
                    rating: parseInt(value.average_rating),
                    count: value.reviews_count,
                    wishlisted: value.variants[0].wishlisted,
                    is_inquiry: value.is_inquiry
                  }}
                />
              ) : (
                <ProductCard
                  key={value.id + "ser"}
                  product={{
                    id: value.id,
                    img: getDesktopImage(
                      value.featured_image_resized,
                      value.featured_image
                    ),
                    type: "services",
                    storeLogo: value.store_logo,
                    description: value[`name_${props.language}`],
                    slug: value.slug,
                    description_en: value[`name_en`],
                    category_en: value.category_title_en,
                    price_after_discount: parseFloat(value.discounted_price),
                    price_before_discount: parseFloat(value.price),
                    rating: parseInt(value.average_rating),
                    count: value.reviews_count,
                    wishlisted: value.wishlisted,
                    is_inquiry: value.is_inquiry
                  }}
                />
              )
            )}
          </div>
        )}
      </div>
      <div
        className="d-flex justify-content-center mb-4 w-100"
        style={{ direction: "ltr" }}
      >
        {props.items?.pagination?.total_pages > 1 && props.data && (
          <Pagination
            direction="ltr"
            previousLabel={<FaChevronLeft color="#404553" />}
            nextLabel={<FaChevronRight color="#404553" />}
            pageCount={parseInt(props.items?.pagination?.total_pages)}
            forcePage={parseInt(props.items?.pagination?.page) - 1}
            onPageChange={handlePageChange}
            pageRangeDisplayed={2}
            containerClassName="pagination"
            pageClassName="pl-3 pr-3 pt-2 pb-2"
            activeClassName={
              "ml-3 mr-3 text-primary border border-primary font-weight-bold rounded bg-white"
            }
            nextClassName="bg-white pl-3 pr-3 pt-2 pb-2"
            previousClassName="bg-white pl-3 pr-3 pt-2 pb-2"
          />
        )}
        {props.items?.pagination?.total_pages < 2 &&
          props.data &&
          props.items[`${props.activeTab}`]?.length > 0 && (
            <div
              className="ml-3 mr-3 text-primary border border-primary font-weight-bold rounded bg-white pl-3 pr-3 pt-2 pb-2
  "
            >
              <span>1</span>
            </div>
          )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    countryUrl: state.language.countryUrl,
    items: state.categories.items,
    width: state.layout.screenWidth,
    sortVal: state.categories.sortVal,
    activeTab: state.categories.activeTab,
    recordPerPage: state.categories.recordPerPage,
    price_high_to_low: state.categories.price_high_to_low,
    price_low_to_high: state.categories.price_low_to_high,
    new_arrivals: state.categories.new_arrivals,
    selectedCat: state.categories.selectedCat,
    originalItems: state.categories.originalItems,
    language: state.language.language,
    countryId: state.language.countryId,
  };
};

const mapDispatchToProps = {
  set_all_categories,
  set_loading,
};
export default connect(mapStateToProps, mapDispatchToProps)(ItemsMain);
