import { createStore, applyMiddleware } from "redux";
import rootReducer from "./reducers/RootReducer";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
const initialState = {};

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["language", "auth", "cart"],
  blacklist: [
    "layout",
    "header",
    "home",
    "allItems",
    "item",
    "customerAddress",
    "vendor",
    "payment",
    "order",
    "returns",
    "select",
    "wallet",
    "categories",
  ],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
const middleware = [thunk];

export const store = createStore(
  persistedReducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export const persistor = persistStore(store);
