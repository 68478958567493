import { type } from "../types/types";

export const set_wallet_credits = (credit) => (dispatch) => {
  dispatch({
    type: type.SET_WALLET_CREDIT,
    credit,
  });
};

export const set_wallet_tranfers = (trasnfer) => (dispatch) => {
  dispatch({
    type: type.SET_WALLET_TRANSFERS,
    trasnfer,
  });
};
