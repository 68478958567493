import React, { Fragment, useEffect, useState } from "react";
import { Row } from "react-bootstrap";
import Sidebar from "../components/customer/account/Sidebar";
import Order from "../components/customer/Order";
import WebsiteHeader from "../components/header/WebsiteHeader";
import { connect } from "react-redux";
import { set_orders } from "../redux/actions/OrdersAction";
import { set_loading } from "../redux/actions/LayoutAction";
import { apiJson } from "../Api";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MobileLayoutRenderer from "./mobile/layout/MobileLayoutRenderer";
import "../assets/styles/pages/orderpage.scss";
import "./../assets/styles/customer/account/general.scss";
import { useTranslation } from "react-i18next";
import HeaderMobile from "../components/mobile/HeaderMobile";

const OrderPage = (props) => {
  const { t } = useTranslation();
  const [data, setData] = useState(false);
  let message = "";

  const showError = () => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  useEffect(() => {
    document.title = "Order";
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
    props.set_loading(true);
    apiJson.orders.getOrders().then((response) => {
      props.set_loading(false);
      if (response.success) {
        props.set_orders({ orders: response.data });
        setData(true);
      } else if (!response.status) {
        // eslint-disable-next-line
        message = response.message
          ? response.message !== ""
            ? response.message
            : t("errors.wrong")
          : t("errors.wrong");
        showError();
        setData(true);
      } else {
        setData(true);
        if (response.status >= 400 && response.status < 500) {
          message = response.message
            ? response.message
            : response.errors
            ? response.errors
            : t("errors.wrong");
          showError();
        } else {
          message = response.message
            ? response.message !== ""
              ? response.message
              : t("errors.wrong")
            : t("errors.wrong");
          showError();
        }
      }
    });
    apiJson.orders.getBanners(props.countryId).then((response) => {
      if (response.success) {
        props.set_orders({ banners: response.data });
      } else if (!response.status) {
        message = response.message
          ? response.message !== ""
            ? response.message
            : t("errors.wrong")
          : t("errors.wrong");
        showError();
        setData(true);
      } else {
        setData(true);
        if (response.status >= 400 && response.status < 500) {
          message = response.message
            ? response.message
            : response.errors
            ? response.errors
            : t("errors.wrong");
          showError();
        } else {
          message = response.message
            ? response.message !== ""
              ? response.message
              : t("errors.wrong")
            : t("errors.wrong");
          showError();
        }
      }
    });
  }, []);

  return (
    <Fragment>
      <Row className="page-container no-gutters">
        {props.widht === "sm" ? (
          <MobileLayoutRenderer
            NavbarTop={false}
            BackBtn={true}
            CancelBtn={false}
            NavbarBottom={true}
            SearchIcon={true}
          >
          <div className="home-mob">
            <HeaderMobile />
          </div>
            <div className="w-100">
              <div className="content-container all-page-container d-flex justify-content-center mx-auto">
                <div className="left-pane">
                  <Sidebar />
                </div>
                <div className="right-pane">
                  {data ? (
                    <>
                      <Order orders={props.orders} />
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          </MobileLayoutRenderer>
        ) : (
          <div className="w-100">
            <div className="header-mobile">
              <WebsiteHeader />
            </div>

            <div className="content-container all-page-container d-flex justify-content-center mx-auto">
              <div className="left-pane">
                <Sidebar />
              </div>
              <div className="right-pane">
                {data ? (
                  <Order orders={props.orders} />
                ) : (
                  <div className="spinner-style"></div>
                )}
              </div>
            </div>
          </div>
        )}
      </Row>{" "}
    </Fragment>
  );
};
const mapStateToProsp = (state) => {
  return {
    orders: state.order.orders,
    countryId: state.language.countryId,
    language: state.language.language,
    widht: state.layout.screenWidth,
    banners: state.order.banners,
    load: state.layout.loading,
  };
};

const mapDispatchToProps = { set_orders, set_loading };

export default connect(mapStateToProsp, mapDispatchToProps)(OrderPage);
