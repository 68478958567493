import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import "../assets/styles/EmptyPage.scss";

function EmptyPage(props) {
  return (
    <div className="empty-page">
      {props.img && (
        <div className="img-cont">
          <img src={props.img} width="100%" height="100%" alt="emptyImage" />
        </div>
      )}
      {props.heading && (
        <div className="empty-bold-text mt-3">{props.heading}</div>
      )}
      {props.subHeading && <div className="empty-text">{props.subHeading}</div>}
      {props.btn && (
        <Link to={"/" + props.countryUrl + "-" + props.language + "/"}>
          <div className="btn-cont mt-3">
            <span className="btn-text">{props.btn}</span>
          </div>
        </Link>
      )}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    countryUrl: state.language.countryUrl,
    language: state.language.language,
  };
};

export default connect(mapStateToProps, null)(EmptyPage);
