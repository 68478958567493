import React from "react";
import "../../../assets/styles/mobile/customer/disputeorderdesc.scss";
import { useTranslation } from "react-i18next";
import moment from "moment";

const DisputeOrderDESC = (props) => {
  const { t } = useTranslation();
  const {
    disputeNum,
    StoreName,
    CreatedAt,
    OrderNum,
    CustomerEmail,
    Status,
    sku,
  } = props;
  return (
    <div className="dispute-order-description-container-component px-3 py-3">
      <div className="dispute-container">
        <div className="disp-first-row">
          <span className="disp-num-span dispute-labels">
            {t("dispute-detail-page-mob.dispute-ID")}
          </span>
          <span className="disp-num dispute-text">{disputeNum}</span>
        </div>
        <div className="disp-second-row pt-2">
          <span className="store-label dispute-labels">
            {t("dispute-detail-page-mob.store")}
          </span>
          <span className="store-name dispute-text">{StoreName}</span>
        </div>
        <div className="disp-third-row pt-2">
          <span className="description-label dispute-labels">
            {t("disputes.filed-at")}:
          </span>
          <span className="description-text dispute-text">
            {moment(new Date(CreatedAt)).format("LL")}
          </span>
        </div>

        {sku && (
          <div className="disp-second-row pt-2">
            <span className="store-label dispute-labels">Sku:</span>
            <span className="store-name dispute-text">{sku}</span>
          </div>
        )}
      </div>
      <div className="order_customer-deatils-container">
        <div className="ocd-first-row ">
          <span className="order-num-span dispute-labels">
            {t("dispute-detail-page-mob.order-no")}
          </span>
          <span className="disp-num dispute-text">{OrderNum}</span>
        </div>
        <div className="ocd-second-row pt-2">
          <span className="customer-email dispute-labels">
            {t("forget-password.email")}
          </span>
          <span className="store-name dispute-text">{CustomerEmail}</span>
        </div>
        <div className="ocd-third-row pt-2">
          <span className="status-label dispute-labels">
            {t("dispute-detail-page-mob.status")}
          </span>
          <button className="status-btn dispute-text">{Status}</button>
        </div>
      </div>
    </div>
  );
};

export default DisputeOrderDESC;
