import React, { useEffect, useState } from "react";
import Row from "react-bootstrap/Row";

import "../../../assets/styles/customer/account/general.scss";
import "../../../assets/styles/pages/paymentpage.scss";
import Sidebar from "../../../components/customer/account/Sidebar";
import Payment from "../../../components/customer/Payment";
import WebsiteHeader from "../../../components/header/WebsiteHeader";
import EmptyPage from "../../../utils/EmptyPage";
import EmptyImage from "../../../assets/images/payment_art.svg";
import { apiJson } from "../../../Api";
import { set_payment_data } from "../../../redux/actions/PaymentAction";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTranslation } from "react-i18next";
import { set_loading } from "../../../redux/actions/LayoutAction";

const PaymentPage = (props) => {
  const [data, setData] = useState(false);
  const { t } = useTranslation();

  let message = "";

  const showError = () => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  useEffect(() => {
    document.title = "Payment";
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
    props.set_loading(true);

    apiJson.payments.getPaymentData().then((response) => {
      props.set_loading(false);
      if (response.success) {
        props.set_payment_data({ payment: response.data });
        setData(true);
      } else if (!response.status) {
        // eslint-disable-next-line
        message = response.message
          ? response.message !== ""
            ? response.message
            : t("errors.wrong")
          : t("errors.wrong");
        showError();
        setData(true);
      } else {
        setData(true);
        if (response.status >= 400 && response.status < 500) {
          message = response.message
            ? response.message
            : response.errors
            ? response.errors
            : t("errors.wrong");
          showError();
        } else {
          message = response.message
            ? response.message !== ""
              ? response.message
              : t("errors.wrong")
            : t("errors.wrong");
          showError();
        }
      }
    });
  }, []);
  return (
    <Row className="page-container no-gutters">
      <div className="w-100">
        <WebsiteHeader />

        <div className="content-container all-page-container d-flex justify-content-center mx-auto h-100">
          <div className="left-pane">
            <Sidebar />
          </div>
          {!data ? (
            <div className="d-flex justify-content-center align-items-center w-100 h-100"></div>
          ) : props.payment.length > 0 ? (
            <div className="right-pane">
              <Payment />
            </div>
          ) : (
            <EmptyPage
              img={EmptyImage}
              heading={t("payment.empty.heading1")}
              subHeading={t("payment.empty.subheading")}
              btn={t("payment.empty.btn-continue-shop")}
            />
          )}
        </div>
      </div>{" "}
    </Row>
  );
};

const mapStateToProsp = (state) => {
  return {
    payment: state.payment.payment,
  };
};

const mapDispatchToProps = {
  set_payment_data,
  set_loading,
};

export default connect(mapStateToProsp, mapDispatchToProps)(PaymentPage);
