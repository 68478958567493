import React, { Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import $ from "jquery";
import MobileLayoutRenderer from "../layout/MobileLayoutRenderer";
import HeaderMobile from "../../../components/mobile/HeaderMobile";

import TopBanner1 from "../../../assets/images/RamadanOffer2024/TopBanner/Ramadan_V2_800x300.jpg";


import "../../../assets/styles/category-homepages/ramadan-page-mob.scss";
import { apiJson } from "../../../Api";
import ProductCard from "../../../components/mobile/Home/ProductCardMobile";

import CatAppliances from "../../../assets/images/RamadanOffer2024/TopRamadanCategories/Category_Appliances_135x195.jpg";
import CatAutomotive from "../../../assets/images/RamadanOffer2024/TopRamadanCategories/Category_Automotive_135x195.jpg";
import CatBuilding from "../../../assets/images/RamadanOffer2024/TopRamadanCategories/Category_BuildingandConstruction_135x195.jpg";
import CatCleaning from "../../../assets/images/RamadanOffer2024/TopRamadanCategories/Category_CleaningSupplies_135x195.jpg";
import CatCrafts from "../../../assets/images/RamadanOffer2024/TopRamadanCategories/Category_Crafts_135x195.jpg";
import CatElectrical from "../../../assets/images/RamadanOffer2024/TopRamadanCategories/Category_Electrical_135x195.jpg";
import CatHardware from "../../../assets/images/RamadanOffer2024/TopRamadanCategories/Category_HardwareandTools_135x195.jpg";
import CatHomeImprovements from "../../../assets/images/RamadanOffer2024/TopRamadanCategories/Category_HomeImprovement_135x195.jpg";
import CatOutdoorRecreation from "../../../assets/images/RamadanOffer2024/TopRamadanCategories/Category_OutdoorRecreation_135x195.jpg";
import CatPaint from "../../../assets/images/RamadanOffer2024/TopRamadanCategories/Category_Paint_135x195.jpg";

import RamadanEssential1 from "../../../assets/images/RamadanOffer2024/RamadanEssentials/Category_385x420.jpg";
import RamadanEssential2 from "../../../assets/images/RamadanOffer2024/RamadanEssentials/Category_385x420 copy.jpg";
import RamadanEssential3 from "../../../assets/images/RamadanOffer2024/RamadanEssentials/Category_385x420 copy 5.jpg";
import RamadanEssential4 from "../../../assets/images/RamadanOffer2024/RamadanEssentials/Category_385x420 copy 4.jpg";
import RamadanEssential5 from "../../../assets/images/RamadanOffer2024/RamadanEssentials/Category_385x420 copy 3.jpg";
import RamadanEssential6 from "../../../assets/images/RamadanOffer2024/RamadanEssentials/Category_385x420 copy 2.jpg";

import CategoryBanner1 from "../../../assets/images/RamadanOffer2024/HomeImprovements/mobile/Category-18.jpg";
import CategoryBanner2 from "../../../assets/images/RamadanOffer2024/HomeImprovements/mobile/Category-19.jpg";

import CleaningBanner1 from "../../../assets/images/RamadanOffer2024/CleaningSupplies/mobile/Category-20.jpg";
import CleaningBanner2 from "../../../assets/images/RamadanOffer2024/CleaningSupplies/mobile/Category-21.jpg";
import CleaningBanner3 from "../../../assets/images/RamadanOffer2024/CleaningSupplies/mobile/Category-22.jpg";
import CleaningBanner4 from "../../../assets/images/RamadanOffer2024/CleaningSupplies/mobile/Category-23.jpg";

import StorageSolutions from "../../../assets/images/RamadanOffer2024/StorageSolutions/mobile/deahomesmob.jpg";


import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination, Navigation } from "swiper/core";
SwiperCore.use([Pagination, Navigation]);

const RamadanMobile = (props) => {
  const [products1, setProducts1] = useState([]);
  const [products2, setProducts2] = useState([]);
  const [products3, setProducts3] = useState([]);
  const [products4, setProducts4] = useState([]);
  const [products5, setProducts5] = useState([]);

  useEffect(() => {
    window.document.title = "Ramadan Offer - Alisuq";

    apiJson.
      sections.getSectionProducts(
        84,
        props.countryId,
        1,
        10
      )
      .then(response => {
        if (response.success) {
          setProducts1(response.data.products);
        }
      });

    apiJson.
      sections.getSectionProducts(
        85,
        props.countryId,
        1,
        10
      )
      .then(response => {
        if (response.success) {
          setProducts2(response.data.products);
        }
      });

    apiJson.
      sections.getSectionProducts(
        86,
        props.countryId,
        1,
        10
      )
      .then(response => {
        if (response.success) {
          setProducts3(response.data.products);
        }
      });

    apiJson.
      sections.getSectionProducts(
        87,
        props.countryId,
        1,
        10
      )
      .then(response => {
        if (response.success) {
          setProducts4(response.data.products);
        }
      });
      apiJson.
      sections.getSectionProducts(
        88,
        props.countryId,
        1,
        10
      )
      .then(response => {
        if (response.success) {
          setProducts5(response.data.products);
        }
      });

    $("html, body").animate({ scrollTop: 0 }, "fast");
  }, []);
  return (
    <MobileLayoutRenderer
      NavbarTop={false}
      BackBtn={false}
      CancelBtn={false}
      NavbarBottom={true}
      SearchIcon={true}
    >
      <div className="home-mob">
        <HeaderMobile />
      </div>
      <div className="banner-sec">
        <Swiper className="catpages-mob"
          spaceBetween={0}
          slidesPerView={1}
          pagination={false}
          navigation={true}
        >
          <SwiperSlide
            onClick={(e) => e.preventDefault()}
            key={1}
          >
            <Link to={{
              pathname: "/" + props.countryUrl +
                "-" + props.language + "/ramadan-sale"
            }}>
              <img
                src={TopBanner1}
                className="img img-fluid"
                height="110px"
              />
            </Link>
          </SwiperSlide>
        </Swiper>
      </div>
      <div className="ramadan-page-mob pt-4">

        <div className="px-2">
          <h2 className="sectiontitle-individualpage mb-2">Top Ramadan Categories</h2>

          <Swiper
            spaceBetween={20}
            slidesPerView={4}
            pagination={false}
            navigation={true}
          >
            <SwiperSlide
              onClick={(e) => e.preventDefault()}
              key={1}
            >
              <Link to={{
                pathname: "/" + props.countryUrl +
                  "-" + props.language + "/items/products",
                search: "discount_sort=true&filter_by_category%5B%5D=183"
              }}>
                <img
                  src={CatAppliances}
                  alt="Alisuq cleaner essentials"
                  className="img img-fluid"
                  height="110px"
                />
              </Link>
            </SwiperSlide>
            <SwiperSlide
              onClick={(e) => e.preventDefault()}
              key={1}
            >
              <Link to={{
                pathname: "/" + props.countryUrl +
                  "-" + props.language + "/items/products",
                search: "discount_sort=true&filter_by_category%5B%5D=193"
              }}>
                <img
                  src={CatAutomotive}
                  alt="Alisuq Hand tools"
                  className="img img-fluid"
                  height="110px"
                />
              </Link>
            </SwiperSlide>
            <SwiperSlide
              onClick={(e) => e.preventDefault()}
              key={1}
            >
              <Link to={{
                pathname: "/" + props.countryUrl +
                  "-" + props.language + "/items/products",
                search: "discount_sort=true&filter_by_category%5B%5D=182"
              }}>
                <img
                  src={CatBuilding}
                  alt="Alisuq Kitchen Accessories"
                  className="img img-fluid"
                  height="110px"
                />
              </Link>
            </SwiperSlide>
            <SwiperSlide
              onClick={(e) => e.preventDefault()}
              key={1}
            >
              <Link to={{
                pathname: "/" + props.countryUrl +
                  "-" + props.language + "/items/products",
                search: "discount_sort=true&filter_by_category%5B%5D=184"
              }}>
                <img
                  src={CatCleaning}
                  alt="Kitchen appliances"
                  className="img img-fluid"
                  height="110px"
                />
              </Link>
            </SwiperSlide>
            <SwiperSlide
              onClick={(e) => e.preventDefault()}
              key={1}
            >
              <Link to={{
                pathname: "/" + props.countryUrl +
                  "-" + props.language + "/items/products",
                search: "discount_sort=true&filter_by_category%5B%5D=201"
              }}>
                <img
                  src={CatCrafts}
                  alt="Alisuq Power Tools"
                  className="img img-fluid"
                  height="110px"
                />
              </Link>
            </SwiperSlide>
            <SwiperSlide
              onClick={(e) => e.preventDefault()}
              key={1}
            >
              <Link to={{
                pathname: "/" + props.countryUrl +
                  "-" + props.language + "/items/products",
                search: "discount_sort=true&filter_by_category%5B%5D=186"
              }}>
                <img
                  src={CatElectrical}
                  alt="Alisuq Vacuums"
                  className="img img-fluid"
                  height="110px"
                />
              </Link>
            </SwiperSlide>
            <SwiperSlide
              onClick={(e) => e.preventDefault()}
              key={1}
            >
              <Link to={{
                pathname: "/" + props.countryUrl +
                  "-" + props.language + "/items/products",
                search: "discount_sort=true&filter_by_category%5B%5D=191"
              }}>
                <img
                  src={CatHardware}
                  alt="Alisuq Water Bottles"
                  className="img img-fluid"
                  height="110px"
                />
              </Link>
            </SwiperSlide>
            <SwiperSlide
              onClick={(e) => e.preventDefault()}
              key={1}
            >
              <Link to={{
                pathname: "/" + props.countryUrl +
                  "-" + props.language + "/items/products",
                search: "discount_sort=true&filter_by_category%5B%5D=189"
              }}>
                <img
                  src={CatHomeImprovements}
                  alt="Alisuq Water Bottles"
                  className="img img-fluid"
                  height="110px"
                />
              </Link>
            </SwiperSlide>
            <SwiperSlide
              onClick={(e) => e.preventDefault()}
              key={1}
            >
              <Link to={{
                pathname: "/" + props.countryUrl +
                  "-" + props.language + "/items/products",
                search: "discount_sort=true&filter_by_category%5B%5D=192"
              }}>
                <img
                  src={CatOutdoorRecreation}
                  alt="Alisuq Water Bottles"
                  className="img img-fluid"
                  height="110px"
                />
              </Link>
            </SwiperSlide>
            <SwiperSlide
              onClick={(e) => e.preventDefault()}
              key={1}
            >
              <Link to={{
                pathname: "/" + props.countryUrl +
                  "-" + props.language + "/items/products",
                search: "discount_sort=true&filter_by_category%5B%5D=194"
              }}>
                <img
                  src={CatPaint}
                  alt="Alisuq Water Bottles"
                  className="img img-fluid"
                  height="110px"
                />
              </Link>
            </SwiperSlide>
          </Swiper>
        </div>

        <div className="emptyspacer"></div>


        <div className="surfacepat">
          <h2 className="sectiontitle-individualpage mb-2">Best Deals</h2>
          <div className="product-cont">
            {products1?.map((data) => (
              <Link
                style={{ textDecoration: "none" }}
                to={{
                  pathname:
                    "/" +
                    props.countryUrl +
                    "-" +
                    props.language +
                    `/${data.category_title_en
                      .toLowerCase()
                      .replace(/ /g, "-")}/${data.slug}`,
                  state: {
                    id: data.id,
                    type: data.variants ? "products" : "services",
                    sku: data.variants[0].sku
                  },
                }}
                key={data.id + Math.random()}
              >
                <div
                  className="image-cont"
                  key={data.id + Math.random() + "prod"}
                >
                  <ProductCard
                    key={data.id + Math.random()}
                    img={
                      data.variants
                        ? data.variants[0].featured_image
                        : data.featured_image
                    }
                    storeLogo={data.store_logo}
                    wishlisted={
                      data.variants
                        ? data.variants[0].wishlisted
                        : data.wishlisted
                    }
                    home={true}
                    type={data.variants ? "products" : "services"}
                    id={data.variants ? data.id : data.id}
                    rating={data.variants[0].average_rating}
                    reviews={data.variants[0].reviews_count}
                    name={data[`name_${props.language}`] + " " + data.variants[0][`name_${props.language}`]}
                    data={data.variants ? data.variants[0] : data}
                    coupon={data.coupon}
                    is_inquiry={data.is_inquiry}
                  />
                </div>
              </Link>
            ))}
          </div>
        </div>

        <div className="emptyspacer"></div>


        <h2 className="sectiontitle-individualpage mb-2">Ramadan Essentials</h2>
        <div
          className="row mx-0 ramadancatpics"
        >
          <div className="col-6 left-deal mt-0">
            <Link to={{
              pathname: "/" + props.countryUrl +
                "-" + props.language + "/items/products",
              search: "discount_sort=true&filter_by_category%5B%5D=209&filter_by_category%5B%5D=292&filter_by_category%5B%5D=293&filter_by_category%5B%5D=489"
            }}>
              <img
                src={RamadanEssential1}
                alt="Alisuq cleaner essentials"
                className="img img-fluid"
                height="110px"
              />
            </Link>
          </div>
          <div className="col-6 right-deal mt-0">
            <Link to={{
              pathname: "/" + props.countryUrl +
                "-" + props.language + "/items/products",
              search: "discount_sort=true&filter_by_category%5B%5D=499"
            }}>
              <img
                src={RamadanEssential2}
                alt="Alisuq cleaner essentials"
                className="img img-fluid"
                height="110px"
              />
            </Link>
          </div>
          <div className="col-6 left-deal">
            <Link to={{
              pathname: "/" + props.countryUrl +
                "-" + props.language + "/items/products",
              search: "discount_sort=true&filter_by_category%5B%5D=226"
            }}>
              <img
                src={RamadanEssential3}
                alt="Alisuq cleaner essentials"
                className="img img-fluid"
                height="110px"
              />
            </Link>
          </div>
          <div className="col-6 right-deal">
            <Link to={{
              pathname: "/" + props.countryUrl +
                "-" + props.language + "/items/products",
              search: "discount_sort=true&filter_by_category%5B%5D=272&filter_by_category%5B%5D=439&filter_by_category%5B%5D=441&filter_by_category%5B%5D=440&filter_by_category%5B%5D=443&filter_by_category%5B%5D=442"
            }}>
              <img
                src={RamadanEssential4}
                alt="Alisuq cleaner essentials"
                className="img img-fluid"
                height="110px"
              />
            </Link>
          </div>
          <div className="col-6 left-deal">
            <Link to={{
              pathname: "/" + props.countryUrl +
                "-" + props.language + "/items/products",
              search: "discount_sort=true&filter_by_category%5B%5D=217&filter_by_category%5B%5D=329&filter_by_category%5B%5D=330&filter_by_category%5B%5D=332&filter_by_category%5B%5D=328&filter_by_category%5B%5D=331"
            }}>
              <img
                src={RamadanEssential5}
                alt="Alisuq cleaner essentials"
                className="img img-fluid"
                height="110px"
              />
            </Link>
          </div>
          <div className="col-6 right-deal">
            <Link to={{
              pathname: "/" + props.countryUrl +
                "-" + props.language + "/items/products",
              search: "discount_sort=true&filter_by_category%5B%5D=251&filter_by_category%5B%5D=365&filter_by_category%5B%5D=366&filter_by_category%5B%5D=368&filter_by_category%5B%5D=369&filter_by_category%5B%5D=370&filter_by_category%5B%5D=371&filter_by_category%5B%5D=372&filter_by_category%5B%5D=373&filter_by_category%5B%5D=367&filter_by_category%5B%5D=481"
            }}>
              <img
                src={RamadanEssential6}
                alt="Alisuq cleaner essentials"
                className="img img-fluid"
                height="110px"
              />
            </Link>
          </div>
        </div>

        <div className="emptyspacer"></div>


        <div className="surfacepat">
          <h2 className="sectiontitle-individualpage mb-2">We Recommend</h2>
          <div className="product-cont">
            {products2?.map((data) => (
              <Link
                style={{ textDecoration: "none" }}
                to={{
                  pathname:
                    "/" +
                    props.countryUrl +
                    "-" +
                    props.language +
                    `/${data.category_title_en
                      .toLowerCase()
                      .replace(/ /g, "-")}/${data.slug}`,
                  state: {
                    id: data.id,
                    type: data.variants ? "products" : "services",
                    sku: data.variants[0].sku
                  },
                }}
                key={data.id + Math.random()}
              >
                <div
                  className="image-cont"
                  key={data.id + Math.random() + "prod"}
                >
                  <ProductCard
                    key={data.id + Math.random()}
                    img={
                      data.variants
                        ? data.variants[0].featured_image
                        : data.featured_image
                    }
                    storeLogo={data.store_logo}
                    wishlisted={
                      data.variants
                        ? data.variants[0].wishlisted
                        : data.wishlisted
                    }
                    home={true}
                    type={data.variants ? "products" : "services"}
                    id={data.variants ? data.id : data.id}
                    rating={data.variants[0].average_rating}
                    reviews={data.variants[0].reviews_count}
                    name={data[`name_${props.language}`] + " " + data.variants[0][`name_${props.language}`]}
                    data={data.variants ? data.variants[0] : data}
                    coupon={data.coupon}
                    is_inquiry={data.is_inquiry}
                  />
                </div>
              </Link>
            ))}
          </div>
        </div>
        <div className="emptyspacer"></div>
        <h2 className="sectiontitle-individualpage mb-2">Home Improvements</h2>
        <div className="row ramadanhome">
          <div className="col-6 left-deal">
            <Link to={{
              pathname: "/" + props.countryUrl +
                "-" + props.language + "/items/products",
              search: "discount_sort=true&filter_by_category%5B%5D=189"
            }}>
              <img src={CategoryBanner1} className="img img-fluid" />
            </Link>
          </div>
          <div className="col-6 right-deal mt-0">
            <Link to={{
              pathname: "/" + props.countryUrl +
                "-" + props.language + "/items/products",
              search: "discount_sort=true&filter_by_category%5B%5D=183"
            }}>
              <img src={CategoryBanner2} className="img img-fluid" />
            </Link>
          </div>
        </div>

        <div className="emptyspacer"></div>


        <div className="surfacepat">
          <h2 className="sectiontitle-individualpage mb-2">Tools</h2>
          <div className="product-cont">
            {products3?.map((data) => (
              <Link
                style={{ textDecoration: "none" }}
                to={{
                  pathname:
                    "/" +
                    props.countryUrl +
                    "-" +
                    props.language +
                    `/${data.category_title_en
                      .toLowerCase()
                      .replace(/ /g, "-")}/${data.slug}`,
                  state: {
                    id: data.id,
                    type: data.variants ? "products" : "services",
                    sku: data.variants[0].sku
                  },
                }}
                key={data.id + Math.random()}
              >
                <div
                  className="image-cont"
                  key={data.id + Math.random() + "prod"}
                >
                  <ProductCard
                    key={data.id + Math.random()}
                    img={
                      data.variants
                        ? data.variants[0].featured_image
                        : data.featured_image
                    }
                    storeLogo={data.store_logo}
                    wishlisted={
                      data.variants
                        ? data.variants[0].wishlisted
                        : data.wishlisted
                    }
                    home={true}
                    type={data.variants ? "products" : "services"}
                    id={data.variants ? data.id : data.id}
                    rating={data.variants[0].average_rating}
                    reviews={data.variants[0].reviews_count}
                    name={data[`name_${props.language}`] + " " + data.variants[0][`name_${props.language}`]}
                    data={data.variants ? data.variants[0] : data}
                    coupon={data.coupon}
                    is_inquiry={data.is_inquiry}
                  />
                </div>
              </Link>
            ))}
          </div>
        </div>
        <div className="emptyspacer"></div>
        <h2 className="sectiontitle-individualpage mb-2">Cleaning Supplies</h2>
        <div
          className="row mx-0 ramadancleaning"
         >
          <div className="col-12">
            <Link to={{
              pathname: "/" + props.countryUrl +
                "-" + props.language + "/items/products",
              search: "discount_sort=true&filter_by_category%5B%5D=184"
            }}>
              <img
                src={CleaningBanner1}
                alt="Alisuq cleaner essentials"
                className="img img-fluid"
                height="110px"
              />
            </Link>
          </div>
          <div className="col-4 left-deal" style={{ marginTop: "14px" }}>
            <Link to={{
              pathname: "/" + props.countryUrl +
                "-" + props.language + "/items/products",
              search: "discount_sort=true&filter_by_category%5B%5D=323"
            }}>
              <img
                src={CleaningBanner2}
                alt="Alisuq cleaner essentials"
                className="img img-fluid"
                height="110px"
              />
            </Link>
          </div>
          <div className="col-4 center-deal" style={{ marginTop: "14px" }}>
            <Link to={{
              pathname: "/" + props.countryUrl +
                "-" + props.language + "/items/products",
              search: "discount_sort=true&filter_by_category%5B%5D=217&filter_by_category%5B%5D=329&filter_by_category%5B%5D=330&filter_by_category%5B%5D=332&filter_by_category%5B%5D=328&filter_by_category%5B%5D=331"
            }}>
              <img
                src={CleaningBanner3}
                alt="Alisuq cleaner essentials"
                className="img img-fluid"
                height="110px"
              />
            </Link>
          </div>
          <div className="col-4 right-deal" style={{ marginTop: "14px" }}>
            <Link to={{
              pathname: "/" + props.countryUrl +
                "-" + props.language + "/items/products",
              search: "discount_sort=true&filter_by_category%5B%5D=215&filter_by_category%5B%5D=319&filter_by_category%5B%5D=321&filter_by_category%5B%5D=320&filter_by_category%5B%5D=323&filter_by_category%5B%5D=322&filter_by_category%5B%5D=318&filter_by_category%5B%5D=541"
            }}>
              <img
                src={CleaningBanner4}
                alt="Alisuq cleaner essentials"
                className="img img-fluid"
                height="110px"
              />
            </Link>
          </div>
        </div>

        <div className="emptyspacer"></div>


        <div className="surfacepat">
          <h2 className="sectiontitle-individualpage mb-2">Cleaners</h2>
          <div className="product-cont">
            {products4?.map((data) => (
              <Link
                style={{ textDecoration: "none" }}
                to={{
                  pathname:
                    "/" +
                    props.countryUrl +
                    "-" +
                    props.language +
                    `/${data.category_title_en
                      .toLowerCase()
                      .replace(/ /g, "-")}/${data.slug}`,
                  state: {
                    id: data.id,
                    type: data.variants ? "products" : "services",
                    sku: data.variants[0].sku
                  },
                }}
                key={data.id + Math.random()}
              >
                <div
                  className="image-cont"
                  key={data.id + Math.random() + "prod"}
                >
                  <ProductCard
                    key={data.id + Math.random()}
                    img={
                      data.variants
                        ? data.variants[0].featured_image
                        : data.featured_image
                    }
                    storeLogo={data.store_logo}
                    wishlisted={
                      data.variants
                        ? data.variants[0].wishlisted
                        : data.wishlisted
                    }
                    home={true}
                    type={data.variants ? "products" : "services"}
                    id={data.variants ? data.id : data.id}
                    rating={data.variants[0].average_rating}
                    reviews={data.variants[0].reviews_count}
                    name={data[`name_${props.language}`] + " " + data.variants[0][`name_${props.language}`]}
                    data={data.variants ? data.variants[0] : data}
                    coupon={data.coupon}
                    is_inquiry={data.is_inquiry}
                  />
                </div>
              </Link>
            ))}
          </div>
        </div>

        <div className="emptyspacer"></div>
        <h2 className="sectiontitle-individualpage mb-2">Storage Solutions</h2>
        <div
          className="row mx-0 ramadancleaning"
         >
          <div className="col-12">
            <Link to={{
              pathname: "/" + props.countryUrl +
                "-" + props.language + "/items/products",
                search: "discount_sort=true&filter_by_brand%5B%5D=177"
            }}>
              <img
                src={CleaningBanner1}
                alt="Alisuq cleaner essentials"
                className="img img-fluid"
                height="110px"
              />
            </Link>
          </div>

        </div>

        <div className="emptyspacer"></div>


        <div className="surfacepat">
          <h2 className="sectiontitle-individualpage mb-2">Storage, Organizers & Bins</h2>
          <div className="product-cont">
            {products5?.map((data) => (
              <Link
                style={{ textDecoration: "none" }}
                to={{
                  pathname:
                    "/" +
                    props.countryUrl +
                    "-" +
                    props.language +
                    `/${data.category_title_en
                      .toLowerCase()
                      .replace(/ /g, "-")}/${data.slug}`,
                  state: {
                    id: data.id,
                    type: data.variants ? "products" : "services",
                    sku: data.variants[0].sku
                  },
                }}
                key={data.id + Math.random()}
              >
                <div
                  className="image-cont"
                  key={data.id + Math.random() + "prod"}
                >
                  <ProductCard
                    key={data.id + Math.random()}
                    img={
                      data.variants
                        ? data.variants[0].featured_image
                        : data.featured_image
                    }
                    storeLogo={data.store_logo}
                    wishlisted={
                      data.variants
                        ? data.variants[0].wishlisted
                        : data.wishlisted
                    }
                    home={true}
                    type={data.variants ? "products" : "services"}
                    id={data.variants ? data.id : data.id}
                    rating={data.variants[0].average_rating}
                    reviews={data.variants[0].reviews_count}
                    name={data[`name_${props.language}`] + " " + data.variants[0][`name_${props.language}`]}
                    data={data.variants ? data.variants[0] : data}
                    coupon={data.coupon}
                    is_inquiry={data.is_inquiry}
                  />
                </div>
              </Link>
            ))}
          </div>
        </div>



    

      </div>
    </MobileLayoutRenderer>
  );
};

const mapStateToProps = (state) => {
  return {
    countryUrl: state.language.countryUrl,
    countryId: state.language.countryId,
    language: state.language.language,
  };
};


export default connect(mapStateToProps, null)(RamadanMobile);
