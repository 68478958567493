import React, { Fragment, useState, useRef, useEffect } from "react";
import Select from "react-select";
import { FaEyeSlash, FaEye } from "react-icons/fa";
import { Modal, Button } from "react-bootstrap";
import ReactPasswordToggleIcon from "react-password-toggle-icon";
import "bootstrap/dist/css/bootstrap.min.css";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ClipLoader from "react-spinners/ClipLoader";

import "../../../assets/styles/customer/account/profile.scss";
import { set_user } from "../../../redux/actions/AuthAction";

import { apiJson } from "../../../Api";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { set_direction } from "../../../redux/actions/LayoutAction";
import { useHistory } from "react-router-dom";

const customStyles = {
  container: (provided, state) => ({
    cursor: "pointer",
    ...provided,
    fontSize: "12px",
    paddingLeft: "5px",
    borderBottom: "1px solid #e2e5f1",
    outline: "none",
    borderTop: "none",
    borderLeft: "none",
    borderRight: "none",
  }),
  control: (provided, state) => ({
    ...provided,
    border: "none",
    outline: "none",
    boxShadow: "none",
    backgroundColor: "white",
    marginTop: "1px",
    cursor: "pointer",
  }),

  valueContainer: (provided, state) => ({
    ...provided,
    paddingLeft: "0px",
    cursor: "pointer",
  }),
};

const Profile = (props) => {
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const { t, i18n } = useTranslation();
  let inputRef = useRef();
  let currentPassRef = useRef();
  const [open, setOpen] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [currentPass, setCurrentPass] = useState("");
  const [newPass, setNewPass] = useState("");
  const [changing, setChanging] = useState(false);
  const [error, setError] = useState(false);
  const [updating, setUpdating] = useState(false);
  var re = new RegExp("^\\d+$");
  const [ErrorFN, setErrorREF] = useState(false);
  const [ErrorLN, setErrorREL] = useState(false);

  const history = useHistory();
  const showIcon = () => <FaEyeSlash aria-hidden="true" />;
  const hideIcon = () => <FaEye aria-hidden="true" />;

  let message = "";
  const showError = () => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  const showSuccess = () => {
    toast.success(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const handleShow = () => {
    setOpen(true);
  };
  const handleHide = () => {
    setOpen(false);
  };

  const options = [
    { value: "English", label: "English" },
    { value: "العربية", label: "العربية" },
  ];

  const [selectedOption, setSelectionOption] = useState({
    value: props.language === "en" ? "English" : "العربية",
    label: props.language === "en" ? "English" : "العربية",
  });

  const handleChange = (value) => {
    setSelectionOption(value);
  };
  const changePass = () => {
    setError(false);
    if (newPass.length > 5) {
      setChanging(true);
      apiJson.profile
        .changPass({
          old_password: currentPass,
          new_password: newPass,
          confirmation_password: newPass,
        })
        .then((response) => {
          setChanging(false);
          if (response.success) {
            message = "Changed Successfully";
            showSuccess();
            setOpen(!open);
          } else if (!response.status) {
            message = response.message
              ? response.message !== ""
                ? response.message
                : t("errors.wrong")
              : t("errors.wrong");
            showError();
          } else {
            if (response.status >= 400 && response.status < 500) {
              message = response.message
                ? response.message
                : response.errors
                ? response.errors
                : t("errors.wrong");
              showError();
            } else {
              message = response.message
                ? response.message !== ""
                  ? response.message
                  : t("errors.wrong")
                : t("errors.wrong");
              showError();
            }
          }
        });
    } else {
      setError(true);
    }
  };

  useEffect(() => {
    if (props.isSignedIn) {
      setFirstName(props.user[`first_name_${props.language}`]);
      setLastName(props.user[`last_name_${props.language}`]);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const updateProfile = () => {
    if (firstName.length === 0) {
      setErrorREF(true);
      return;
    } else if (lastName.length === 0) {
      setErrorREL(true);
      return;
    } else {
      let data = {
        first_name_en: firstName,
        last_name_en: lastName,
        first_name_ar: props.user.first_name_ar
          ? props.user.first_name_ar
          : firstName,
        last_name_ar: props.user.last_name_ar
          ? props.user.last_name_ar
          : lastName,
        preferred_language: selectedOption.value,
      };
      setUpdating(true);
      apiJson.profile.update(data).then((response) => {
        if (response.success) {
          props.set_user({ user: response.data.data });
          i18n.changeLanguage(
            selectedOption.value.toLowerCase() === "English".toLowerCase()
              ? "en"
              : "ar"
          );
          props.set_direction(
            selectedOption.value.toLowerCase() === "English".toLowerCase()
              ? "en"
              : "ar"
          );
          history.push({
            pathname:
              "/" +
              props.countryUrl +
              "-" +
              (selectedOption.value.toLowerCase() === "English".toLowerCase()
                ? "en"
                : "ar") +
              "/" +
              window.location.pathname.replace(/(?:.*?\/){2}/, ""),
          });
          message = "Updated";
          showSuccess();
          setUpdating(false);
        } else if (!response.status) {
          setUpdating(false);
          message = response.message
            ? response.message !== ""
              ? response.message
              : t("errors.wrong")
            : t("errors.wrong");
          showError();
        } else {
          setUpdating(false);
          if (response.status >= 400 && response.status < 500) {
            message = response.message
              ? response.message
              : response.errors
              ? response.errors
              : t("errors.wrong");
            showError();
          } else {
            message = response.message
              ? response.message !== ""
                ? response.message
                : t("errors.wrong")
              : t("errors.wrong");
            showError();
          }
        }
      });
    }
  };
  const handleFirstName = (val) => {
    setFirstName(val);
    setErrorREF(false);
  };
  const handleLastName = (val) => {
    setLastName(val);
    setErrorREL(false);
  };

  return (
    <Fragment>
      <div className="customer-profile w-100">
        <h3>{t("profile.heading")}</h3>
        <p className="gray-text mb-0">{t("profile.manage")}</p>

        <div className="profile-card general-information w-100">
          <div className="header w-100">
            <h4>{t("profile.general-info")}</h4>
          </div>
          <div className="profile-body w-100">
            <div className="profile-field d-flex align-items-end row mx-0 w-100">
              <div className="first-name col-lg-4 col-md-12 pl-0 pr-md-0 pr-lg-3">
                <label htmlFor="fname">{t("profile.first-name-label")}</label>
                <input
                  className={`first-name pl-0 w-100 ${
                    ErrorFN ? "bottom-red" : ""
                  }`}
                  type="text"
                  id="fname"
                  name="fname"
                  placeholder="first name"
                  value={firstName}
                  onChange={(e) => {
                    re.test(e.target.value) || e.target.value.length < 0
                      ? setErrorREF(true)
                      : handleFirstName(e.target.value);
                  }}
                />
              </div>
              <div className="last-name col-lg-4 col-md-12 pl-md-0 pr-md-0 pr-lg-3 mt-md-2 mt-lg-0">
                <label htmlFor="lname">{t("profile.last-name-label")}</label>
                <input
                  className={`last-name pl-0 w-100 ${
                    ErrorLN ? "bottom-red" : ""
                  }`}
                  type="text"
                  id="lname"
                  name="lname"
                  placeholder="last name"
                  onChange={(e) => {
                    re.test(e.target.value) || e.target.value.length < 0
                      ? setErrorREL(true)
                      : handleLastName(e.target.value);
                  }}
                  value={lastName}
                />
              </div>
              <div className="prefered-language col-lg-4 col-md-12 pl-md-0 pr-0 mt-md-3 mt-lg-0">
                <label
                  htmlFor="preferedlanguage"
                  style={{ textAlign: "initial" }}
                >
                  {t("profile.mobile.prefered-lang")}
                </label>
                <Select
                  className="pl-0"
                  styles={customStyles}
                  value={selectedOption}
                  onChange={(value) => handleChange(value)}
                  options={options}
                  isMulti={false}
                  isSearchable={false}
                  defaultValue={selectedOption}
                />
              </div>
            </div>
            <div className="button row d-flex justify-content-md-center justify-content-lg-end mx-0 w-100">
              <button
                onClick={(e) => {
                  e.preventDefault();
                  updateProfile();
                }}
                type="button"
                className="save-button"
                disabled={updating}
              >
                {updating ? (
                  <div className="d-flex justify-content-center align-items-center w-100">
                    <ClipLoader color="#000" loading size={19} />
                  </div>
                ) : (
                  t("profile.save-btn")
                )}
              </button>
            </div>
          </div>
        </div>

        <div className="profile-card security-information w-100">
          <div className="header w-100">
            <h4>{t("profile.security")}</h4>
          </div>
          <div className="profile-body w-100">
            <div className="profile-field row mx-0 w-100">
              <div className="email col-lg-6 col-md-12 pl-0 pr-md-0 pr-lg-3">
                <label htmlFor="email">{t("profile.email-label")}</label>
                <input
                  className="email w-100 pl-0"
                  type="email"
                  id="email"
                  name="email"
                  placeholder="email"
                  value={props.isSignedIn ? props.user.email : ""}
                  disabled
                />
              </div>
              <div className="password col-lg-6 col-md-12 pr-0 pl-md-0 mt-md-2 mt-lg-0">
                <label htmlFor="password">{t("profile.pw-label")}</label>
                <input
                  className="password w-100 pl-0"
                  type="password"
                  id="password"
                  name="password"
                  placeholder="password"
                  value={props.isSignedIn ? "123456" : ""}
                  disabled
                />
              </div>
            </div>
            <div className="button row d-flex justify-content-md-center justify-content-lg-end mx-0 w-100">
              <button
                type="button"
                className="change-password"
                onClick={handleShow}
              >
                {t("profile.change-pw-btn")}
              </button>
              <Modal show={open} onHide={handleHide} centered>
                <Modal.Header
                  style={{
                    borderBottom: "none",
                    justifyContent: "center",
                    paddingBottom: "0px",
                  }}
                  className="forget-password-modal d-flex justify-content-center"
                >
                  <Modal.Title
                    className="header"
                    style={{
                      fontWeight: "bold",
                      color: "rgb(65,69,82)",
                      fontSize: "1.239575rem",
                    }}
                  >
                    {t("profile.change-password.title")}
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body
                  className="customer-profile change-password-input pb-0"
                  style={{
                    width: "94%",
                    margin: "0px auto",
                    direction: props.language === "ar" ? "rtl" : "ltr",
                  }}
                >
                  <p
                    className="text-center mx-auto"
                    style={{
                      color: "rgb(65,69,82)",
                      width: "94%",
                      fontSize: "0.765625rem",
                      fontWeight: "400",
                    }}
                  >
                    {t("profile.change-password.enter-current-pw")}
                  </p>
                  <label
                    className="mb-0"
                    style={{
                      color: "rgb(126, 133, 155)",
                      fontSize: "0.875rem",
                      fontWeight: "400",
                    }}
                  >
                    {t("profile.change-password.current-pw")}
                  </label>
                  <div
                    className="input-group d-flex mb-5"
                    style={{ borderBottom: "1px solid rgb(226, 229, 241)" }}
                  >
                    <input
                      ref={currentPassRef}
                      type={showCurrentPassword ? "text" : "password"}
                      value={currentPass}
                      onChange={(e) => setCurrentPass(e.target.value)}
                      className="input-with-icon w-100"
                      style={{
                        border: "0px",
                        outline: "none",
                        boxShadow: "none",
                      }}
                    />
                    <ReactPasswordToggleIcon
                      inputRef={currentPassRef}
                      showIcon={showIcon}
                      hideIcon={hideIcon}
                      onClick={() =>
                        setShowCurrentPassword(!showCurrentPassword)
                      }
                      style={{
                        color: "rgb(126, 133, 155)",
                        width: "22px",
                        right: props.language === "ar" ? "95%" : "0px",
                      }}
                    />
                  </div>
                  <label
                    className="mb-0"
                    style={{
                      color: "rgb(126, 133, 155)",
                      fontSize: "0.875rem",
                      fontWeight: "400",
                    }}
                  >
                    {t("profile.change-password.new-pw")}
                  </label>
                  <div
                    className="input-group d-flex"
                    style={{
                      borderBottom: "1px solid rgb(226, 229, 241)",
                      marginBottom: "60px",
                    }}
                  >
                    <input
                      ref={inputRef}
                      type={showNewPassword ? "text" : "password"}
                      className="input-with-icon w-100"
                      value={newPass}
                      onChange={(e) => setNewPass(e.target.value)}
                      style={{
                        border: "0px",
                        outline: "none",
                        boxShadow: "none",
                      }}
                    />
                    <ReactPasswordToggleIcon
                      inputRef={inputRef}
                      hideIcon={hideIcon}
                      showIcon={showIcon}
                      onClick={() => setShowNewPassword(!showNewPassword)}
                      style={{
                        color: "rgb(126, 133, 155)",
                        width: "22px",
                        right: props.language === "ar" ? "95%" : "0px",
                      }}
                    />
                  </div>
                  {error && (
                    <div className="error">Length must be greater than 8</div>
                  )}
                </Modal.Body>
                <Modal.Footer
                  className="d-flex p-0"
                  style={{ borderTopColor: "rgb(226, 229, 241)" }}
                >
                  <Button
                    onClick={handleHide}
                    className="w-50 pt-3 pb-3 m-0 text-uppercase"
                    style={{
                      border: "none",
                      backgroundColor: "white",
                      color: "black",
                      borderRight: "1px solid rgb(226, 229, 241)",
                      fontSize: "1.09375rem",
                      fontWeight: "600",
                      outline: "none",
                      boxShadow: "none",
                    }}
                  >
                    {t("profile.change-password.cncl")}
                  </Button>
                  <Button
                    onClick={changePass}
                    className="w-50 pt-3 pb-3 m-0 text-uppercase"
                    style={{
                      border: "none",
                      backgroundColor: "white",
                      color: "black",
                      fontSize: "1.09375",
                      fontWeight: "600",
                      outline: "none",
                      boxShadow: "none",
                    }}
                  >
                    {changing ? (
                      <div className="d-flex justify-content-center align-items-center w-100">
                        <ClipLoader color="#000" loading size={20} />
                      </div>
                    ) : (
                      t("profile.change-password.ok")
                    )}
                  </Button>
                </Modal.Footer>
              </Modal>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    isSignedIn: state.auth.isSignedIn,
    language: state.language.language,
    countryUrl: state.language.countryUrl,
  };
};

const mapDispatchToProps = { set_user, set_direction };

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
