import React, { Fragment, useState } from "react";
import { Link } from 'react-router-dom';
import WebsiteHeader from "../../components/header/WebsiteHeader";
import WebsiteFooter from "../../components/Footer/WebsiteFooter";
import "../../assets/styles/category-homepages/home-appliances.scss";

import ProductList from "../../components/product/ProductList";

import TopBanner2 from "../../assets/images/cleaners/Cleaning-Chemicals.jpg";
import TopBanner1 from "../../assets/images/cleaners/Cleaning-Equipment.jpg";
import TopBanner3 from "../../assets/images/cleaners/Stain-Removers.jpg";


import five from "../../assets/images/individual-pages/cleaners/Air-Fresheners.png";

import eight from "../../assets/images/individual-pages/cleaners/All-Purpose-Cleaners.png";
import four from "../../assets/images/individual-pages/cleaners/Floor-Cleaners.png";
import nine from "../../assets/images/individual-pages/cleaners/Gloves.png";
import three from "../../assets/images/individual-pages/cleaners/Metal-Cleaners.png";
import one from "../../assets/images/individual-pages/cleaners/Mopsname.png";
import seven from "../../assets/images/individual-pages/cleaners/Plastic-Bags.png";

import two from "../../assets/images/individual-pages/cleaners/Sponges.png";
import ten from "../../assets/images/individual-pages/cleaners/Squeegees.png";
import six from "../../assets/images/individual-pages/cleaners/Tissues-and-Wipes.png";

import offerbanner4 from "../../assets/images/individual-pages/cleaners/banners/Air-Fresheners.jpg";
import offerbanner7 from "../../assets/images/individual-pages/cleaners/banners/All-Purpose-Cleaners.jpg";

import offerbanner10 from "../../assets/images/individual-pages/cleaners/banners/Brooms.jpg";
import offerbanner8 from "../../assets/images/individual-pages/cleaners/banners/Floor-Cleaners.jpg";

import offerbanner9 from "../../assets/images/individual-pages/cleaners/banners/Floor-Mops.jpg";
import offerbanner2 from "../../assets/images/individual-pages/cleaners/banners/Metal-Cleaners.jpg";

import offerbanner6 from "../../assets/images/individual-pages/cleaners/banners/Plastic-Bags.jpg";
import offerbanner3 from "../../assets/images/individual-pages/cleaners/banners/Sanitizers.jpg";

import offerbanner12 from "../../assets/images/individual-pages/cleaners/banners/Sponges.jpg";
import offerbanner11 from "../../assets/images/individual-pages/cleaners/banners/Squeegees.jpg";

import offerbanner1 from "../../assets/images/individual-pages/cleaners/banners/Stain-Removers.jpg";
import offerbanner5 from "../../assets/images/individual-pages/cleaners/banners/Tissues-Wipes.jpg";




import { useEffect } from "react";
import $ from "jquery";
import { connect } from "react-redux";
import { apiJson } from "../../Api";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination, Navigation } from "swiper/core";
SwiperCore.use([Pagination, Navigation]);

const CampingPage = (props) => {
    const [section1, setSection1] = useState([]);
    const [section2, setSection2] = useState([]);
    const [section3, setSection3] = useState([]);
    const [section4, setSection4] = useState([]);
    const [section5, setSection5] = useState([]);


    const [products2, setProducts2] = useState([]);

    useEffect(() => {
        window.document.title = "Cleaning Tools | Buy Cleaning Essentials For A Hygienic Home";

        $("html, body").animate({ scrollTop: 0 }, "fast");

        apiJson.
            homepage.getSections(66, props.countryId)
            .then(response => {
                if (response.success) {
                    // console.log(response.data);
                    // setProducts(response.data.products);
                    setSection1(response.data);
                }
            });

        apiJson.
            homepage.getSections(67, props.countryId)
            .then(response => {
                if (response.success) {
                    // console.log(response.data);
                    // setProducts(response.data.products);
                    setSection2(response.data);
                }
            });

        apiJson.
            homepage.getSections(68, props.countryId)
            .then(response => {
                if (response.success) {
                    // console.log(response.data);
                    // setProducts(response.data.products);
                    setSection3(response.data);
                }
            });
        apiJson.
            homepage.getSections(69, props.countryId)
            .then(response => {
                if (response.success) {
                    // console.log(response.data);
                    // setProducts(response.data.products);
                    setSection4(response.data);
                }
            });
        apiJson.
            homepage.getSections(70, props.countryId)
            .then(response => {
                if (response.success) {
                    // console.log(response.data);
                    // setProducts(response.data.products);
                    setSection5(response.data);
                }
            });

    }, []);
    return (
        <Fragment>
            <div className="product-home-page-desktop mx-auto paintspage">
                <WebsiteHeader />
                <div className="mypages-container">
                    <Swiper className="homepage-main-banner-swiper"
                        spaceBetween={0}
                        slidesPerView={1}
                        pagination={false}
                        navigation={true}
                    >
                        <SwiperSlide
                            onClick={(e) => e.preventDefault()}
                            key={1}
                        >
                            <Link to={{
                                pathname: "/" + props.countryUrl +
                                    "-" + props.language + "/items/products",
                                search: "filter_by_category%5B%5D=216"
                            }}>
                                <img
                                    src={TopBanner1}
                                    className="img img-fluid"
                                    height="110px"
                                />
                            </Link>
                        </SwiperSlide>
                        <SwiperSlide
                            onClick={(e) => e.preventDefault()}
                            key={1}
                        >
                            <Link to={{
                                pathname: "/" + props.countryUrl +
                                    "-" + props.language + "/items/products",
                                search: "filter_by_category%5B%5D=215"
                            }}>
                                <img
                                    src={TopBanner2}
                                    className="img img-fluid"
                                    height="110px"
                                />
                            </Link>
                        </SwiperSlide>
                        <SwiperSlide
                            onClick={(e) => e.preventDefault()}
                            key={1}
                        >
                            <Link to={{
                                pathname: "/" + props.countryUrl +
                                    "-" + props.language + "/items/products",
                                search: "filter_by_category%5B%5D=323"
                            }}>
                                <img
                                    src={TopBanner3}
                                    className="img img-fluid"
                                    height="110px"
                                />
                            </Link>
                        </SwiperSlide>

                    </Swiper>
                    <div className="emptyspacer"></div>
                    <div className="home-appliances">
                        <div className="titlewrap">
                            <h2 className="section-title lft catsec">{section1["title_" + props.language]}</h2>
                        </div>
                        <div className="surfacepat1">
                            <ProductList
                                type="product"
                                home={true}
                                products={section1.products}
                            />
                        </div>
                        <div className="emptyspacer"></div>


                        <h2 className="sectiontitle-individualpage">Shop by category</h2>
                        <div className="emptyspacer"></div>

                        <Swiper
                            spaceBetween={40}
                            slidesPerView={7}
                            pagination={false}
                            navigation={true}
                            >
                            <SwiperSlide
                                onClick={(e) => e.preventDefault()}
                                key={1}
                            >
                                <Link to={{
                                    pathname: "/" + props.countryUrl +
                                        "-" + props.language + "/items/products",
                                    search: "filter_by_category%5B%5D=324"
                                }}>
                                    <img
                                        src={one}
                                        className="img-img-fluid cleancat"
                                        height="150px"
                                    />
                                </Link>
                            </SwiperSlide>
                            <SwiperSlide
                                onClick={(e) => e.preventDefault()}
                                key={1}
                            >
                                <Link to={{
                                    pathname: "/" + props.countryUrl +
                                        "-" + props.language + "/items/products",
                                    search: "filter_by_category%5B%5D=326"
                                }}>
                                    <img
                                        src={two} className="img-img-fluid cleancat"
                                        height="150px"
                                    />
                                </Link>
                            </SwiperSlide>
                            <SwiperSlide
                                onClick={(e) => e.preventDefault()}
                                key={1}
                            >
                                <Link to={{
                                    pathname: "/" + props.countryUrl +
                                        "-" + props.language + "/items/products",
                                    search: "filter_by_category%5B%5D=321"
                                }}>
                                    <img
                                        src={three} className="img-img-fluid cleancat"
                                        height="150px"
                                    />
                                </Link>
                            </SwiperSlide>
                            <SwiperSlide
                                onClick={(e) => e.preventDefault()}
                                key={1}
                            >
                                <Link to={{
                                    pathname: "/" + props.countryUrl +
                                        "-" + props.language + "/items/products",
                                    search: "filter_by_category%5B%5D=320"
                                }}>
                                    <img
                                        src={four} className="img-img-fluid cleancat"
                                        height="150px"
                                    />
                                </Link>
                            </SwiperSlide>
                            <SwiperSlide
                                onClick={(e) => e.preventDefault()}
                                key={1}
                            >
                                <Link to={{
                                    pathname: "/" + props.countryUrl +
                                        "-" + props.language + "/items/products",
                                    search: "filter_by_category%5B%5D=328"
                                }}>
                                    <img
                                        src={five}
                                        className="img-img-fluid cleancat"
                                        height="150px"
                                    />
                                </Link>
                            </SwiperSlide>
                            <SwiperSlide
                                onClick={(e) => e.preventDefault()}
                                key={1}
                            >
                                <Link to={{
                                    pathname: "/" + props.countryUrl +
                                        "-" + props.language + "/items/products",
                                    search: "filter_by_category%5B%5D=332"
                                }}>
                                    <img
                                        src={six} className="img-img-fluid cleancat"
                                        height="150px"
                                    />
                                </Link>
                            </SwiperSlide>
                            <SwiperSlide
                                onClick={(e) => e.preventDefault()}
                                key={1}
                            >
                                <Link to={{
                                    pathname: "/" + props.countryUrl +
                                        "-" + props.language + "/items/products",
                                    search: "filter_by_category%5B%5D=331"
                                }}>
                                    <img
                                        src={seven} className="img-img-fluid cleancat"
                                        height="150px"
                                    />
                                </Link>
                            </SwiperSlide>
                            <SwiperSlide
                                onClick={(e) => e.preventDefault()}
                                key={1}
                            >
                                <Link to={{
                                    pathname: "/" + props.countryUrl +
                                        "-" + props.language + "/items/products",
                                    search: "filter_by_category%5B%5D=322"
                                }}>
                                    <img
                                        src={eight} className="img-img-fluid cleancat"
                                        height="150px"
                                    />
                                </Link>
                            </SwiperSlide>
                            <SwiperSlide
                                onClick={(e) => e.preventDefault()}
                                key={1}
                            >
                                <Link to={{
                                    pathname: "/" + props.countryUrl +
                                        "-" + props.language + "/items/products",
                                    search: "filter_by_category%5B%5D=330"
                                }}>
                                    <img
                                        src={nine} className="img-img-fluid cleancat"
                                        height="150px"
                                    />
                                </Link>
                            </SwiperSlide>
                            <SwiperSlide
                                onClick={(e) => e.preventDefault()}
                                key={1}
                            >
                                <Link to={{
                                    pathname: "/" + props.countryUrl +
                                        "-" + props.language + "/items/products",
                                    search: "filter_by_category%5B%5D=327"
                                }}>
                                    <img
                                        src={ten} className="img-img-fluid cleancat"
                                        height="150px"
                                    />
                                </Link>
                            </SwiperSlide>

                        </Swiper>

                        <div className="row mt-4">
                            <div className="col-6 mt-2 mb-2">
                                <Link to={{
                                    pathname: "/" + props.countryUrl +
                                        "-" + props.language + "/items/products",
                                    search: "filter_by_category%5B%5D=323"
                                }}>
                                    <img
                                        src={offerbanner1}
                                        alt="category"
                                        className="img img-fluid"
                                    />
                                </Link>
                            </div>
                            <div className="col-6 mt-2 mb-2">
                                <Link to={{
                                    pathname: "/" + props.countryUrl +
                                        "-" + props.language + "/items/products",
                                    search: "filter_by_category%5B%5D=321"
                                }}>
                                    <img
                                        src={offerbanner2}
                                        alt="category"
                                        className="img img-fluid"
                                    />
                                </Link>
                            </div>
                            <div className="emptyspacer"></div>

                            <div className="titlewrap">
                                <h2 className="section-title lft catsec">{section2["title_" + props.language]}</h2>
                            </div>
                            <div className="surfacepat1">
                                <ProductList
                                    type="product"
                                    home={true}
                                    products={section2.products}
                                />
                            </div>
                            <div className="col-6 mt-2 mb-2">
                                <Link to={{
                                    pathname: "/" + props.countryUrl +
                                        "-" + props.language + "/items/products",
                                    search: "filter_by_category%5B%5D=329"
                                }}>
                                    <img
                                        src={offerbanner3}
                                        alt="category"
                                        className="img img-fluid"
                                    />
                                </Link>
                            </div>
                            <div className="col-6 mt-2 mb-2">
                                <Link to={{
                                    pathname: "/" + props.countryUrl +
                                        "-" + props.language + "/items/products",
                                    search: "filter_by_category%5B%5D=328"
                                }}>
                                    <img
                                        src={offerbanner4}
                                        alt="category"
                                        className="img img-fluid"
                                    />
                                </Link>
                            </div>
                            <div className="emptyspacer"></div>
                            <div className="titlewrap">
                                <h2 className="section-title lft catsec">{section3["title_" + props.language]}</h2>
                            </div>
                            <div className="surfacepat1">
                                <ProductList
                                    type="product"
                                    home={true}
                                    products={section3.products}
                                />
                            </div>


                        </div>
                        <div className="emptyspacer"></div>
                        <div className="px-2">
                            <h2 className="sectiontitle-individualpage">Shop by brand</h2>
                            <div className="row">
                                <div className="col-2">
                                    <Link to={{
                                        pathname: "/" + props.countryUrl +
                                            "-" + props.language + "/items/products",
                                        search: "filter_by_brand%5B%5D=247"
                                    }}>
                                        <img src="https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/hLhipUUwc5VKtkATKRUEjb.jpeg" className="img-img-fluid sbb" width="100%" />
                                    </Link>
                                </div>
                                <div className="col-2">
                                    <Link to={{
                                        pathname: "/" + props.countryUrl +
                                            "-" + props.language + "/items/products",
                                        search: "filter_by_brand%5B%5D=248"
                                    }}>
                                        <img src="https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/gdkTTmb629vbMuZXBfw7nY.jpeg" className="img-img-fluid sbb" width="100%" />
                                    </Link>
                                </div>
                                <div className="col-2">
                                    <Link to={{
                                        pathname: "/" + props.countryUrl +
                                            "-" + props.language + "/items/products",
                                        search: "filter_by_brand%5B%5D=206"
                                    }}>
                                        <img src="https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/3eH6BBGck1KaPTbsqTGPYx.jpeg" className="img-img-fluid sbb" width="100%" />
                                    </Link>
                                </div>
                                <div className="col-2">
                                    <Link to={{
                                        pathname: "/" + props.countryUrl +
                                            "-" + props.language + "/items/products",
                                        search: "filter_by_brand%5B%5D=128"
                                    }}>
                                        <img src="https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/eBtivS6qaprWjzh6YQdZFP.png" className="img-img-fluid sbb" width="100%" />
                                    </Link>
                                </div>


                                <div className="col-2">
                                    <Link to={{
                                        pathname: "/" + props.countryUrl +
                                            "-" + props.language + "/items/products",
                                        search: "filter_by_brand%5B%5D=113"
                                    }}>
                                        <img src="https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/gU9vHbdpZiYFfLF4F2zh7T.png" className="img-img-fluid sbb" width="100%" />
                                    </Link>
                                </div>
                                <div className="col-2">
                                    <Link to={{
                                        pathname: "/" + props.countryUrl +
                                            "-" + props.language + "/items/products",
                                        search: "filter_by_brand%5B%5D=327"
                                    }}>
                                        <img src="https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/k6Jhp8fXto8PiPqJmqVoTe.png" className="img-img-fluid sbb" width="100%" />
                                    </Link>
                                </div>


                            </div>

                        </div>
                        <div className="row mt-4">
                            <div className="col-6 mt-2 mb-2">
                                <Link to={{
                                    pathname: "/" + props.countryUrl +
                                        "-" + props.language + "/items/products",
                                    search: "filter_by_category%5B%5D=332"
                                }}>
                                    <img
                                        src={offerbanner5}
                                        alt="category"
                                        className="img img-fluid"
                                    />
                                </Link>
                            </div>
                            <div className="col-6 mt-2 mb-2">
                                <Link to={{
                                    pathname: "/" + props.countryUrl +
                                        "-" + props.language + "/items/products",
                                    search: "filter_by_category%5B%5D=331"
                                }}>
                                    <img
                                        src={offerbanner6}
                                        alt="category"
                                        className="img img-fluid"
                                    />
                                </Link>
                            </div>
                        </div>
                        <div className="emptyspacer"></div>

                        <div className="titlewrap">
                            <h2 className="section-title lft catsec">{section4["title_" + props.language]}</h2>
                        </div>
                        <div className="surfacepat1">
                            <ProductList
                                type="product"
                                home={true}
                                products={section4.products}
                            />
                        </div>
                        <div className="emptyspacer"></div>
                        <div className="row mt-4">
                            <div className="col-6 mt-2 mb-2">
                                <Link to={{
                                    pathname: "/" + props.countryUrl +
                                        "-" + props.language + "/items/products",
                                        search: "filter_by_category%5B%5D=322"
                                }}>
                                    <img
                                        src={offerbanner7}
                                        alt="category"
                                        className="img img-fluid"
                                    />
                                </Link>
                            </div>
                            <div className="col-6 mt-2 mb-2">
                                <Link to={{
                                    pathname: "/" + props.countryUrl +
                                        "-" + props.language + "/items/products",
                                    search: "filter_by_category%5B%5D=320"
                                }}>
                                    <img
                                        src={offerbanner8}
                                        alt="category"
                                        className="img img-fluid"
                                    />
                                </Link>
                            </div>
                        </div>
                        <div className="emptyspacer"></div>

                        <div className="titlewrap">
                            <h2 className="section-title lft catsec">{section5["title_" + props.language]}</h2>
                        </div>
                        <div className="surfacepat1">
                            <ProductList
                                type="product"
                                home={true}
                                products={section5.products}
                            />
                        </div>
                        <div className="emptyspacer"></div>
                        <div className="row mt-4">
                            <div className="col-6 mt-2 mb-2">
                                <Link to={{
                                    pathname: "/" + props.countryUrl +
                                        "-" + props.language + "/items/products",
                                    search: "filter_by_category%5B%5D=324"
                                }}>
                                    <img
                                        src={offerbanner9}
                                        alt="category"
                                        className="img img-fluid"
                                    />
                                </Link>
                            </div>
                            <div className="col-6 mt-2 mb-2">
                                <Link to={{
                                    pathname: "/" + props.countryUrl +
                                        "-" + props.language + "/items/products",
                                    search: "filter_by_category%5B%5D=325"
                                }}>
                                    <img
                                        src={offerbanner10}
                                        alt="category"
                                        className="img img-fluid"
                                    />
                                </Link>
                            </div>
                            <div className="col-6 mt-2 mb-2">
                                <Link to={{
                                    pathname: "/" + props.countryUrl +
                                        "-" + props.language + "/items/products",
                                    search: "filter_by_category%5B%5D=327"
                                }}>
                                    <img
                                        src={offerbanner11}
                                        alt="category"
                                        className="img img-fluid"
                                    />
                                </Link>
                            </div>
                            <div className="col-6 mt-2 mb-2">
                                <Link to={{
                                    pathname: "/" + props.countryUrl +
                                        "-" + props.language + "/items/products",
                                    search: "filter_by_category%5B%5D=326"
                                }}>
                                    <img
                                        src={offerbanner12}
                                        alt="category"
                                        className="img img-fluid"
                                    />
                                </Link>
                            </div>

                        </div>
                        <div className="emptyspacer"></div>

                        {/* 
                        <div className="emptyspacer"></div>
                        <h2 className="sectiontitle-individualpage text-left">Shop by Discount</h2>
                        <div className="emptyspacer"></div>
                        <div className="row">
                            <div className="col-3">
                                <Link to={"/" + props.countryUrl +
                                    "-" + props.language + "/offers/section/upto-30-off"}>
                                    <img src={Upto30} className="img-img-fluid" />
                                </Link>
                            </div>
                            <div className="col-3">
                                <Link to={"/" + props.countryUrl +
                                    "-" + props.language + "/offers/section/upto-50-off"}>
                                    <img src={Upto50} className="img-img-fluid" />
                                </Link>
                            </div>
                            <div className="col-3">
                                <Link to={"/" + props.countryUrl +
                                    "-" + props.language + "/offers/section/upto-70-off"}>
                                    <img src={Upto70} className="img-img-fluid" />
                                </Link>
                            </div>
                            <div className="col-3">
                                <Link to={"/" + props.countryUrl +
                                    "-" + props.language + "/offers/section/upto-90-off"}>
                                    <img src={Upto90} className="img-img-fluid" />
                                </Link>
                            </div>
                        </div> */}

                        <div className="emptyspacer"></div>
                        <div className="category-content">
                            <h1>
                                Cleaners
                            </h1>
                            <p>
                                Keeping your home clean is key to practicing optimal hygiene. A clean home carries lesser risk of infection and disease. With a wide range of cleaning chemicals and equipment, Alisuq makes daily cleaning swift and undemanding.  From dusting furniture to cleaning floors, utensil scrubbing, and doing laundry, every home chore is fast and hassle-free with our range of cleaners. We offer something for all types of domestic and commercial cleaning processes. Keep your surroundings fresh and tidy with a range of options in cleaning solutions. Scrub away marks and stains with super absorbent sponges, mops, and squeegees.  Get your hands on top-quality <Link to='items/products?filter_by_category%5B%5D=217&filter_by_category%5B%5D=329&filter_by_category%5B%5D=330&filter_by_category%5B%5D=328&filter_by_category%5B%5D=331&filter_by_category%5B%5D=332'>cleaning essentials</Link> from popular brands to enjoy instant cleaning in minimal effort.
                            </p>
                            <p>
                                Alisuq is home to the largest selection of cleaning tools and cleaning chemicals. With the best-in-class detergents, stain removers, carpet cleaners, and air fresheners, your home is sure to remain squeaky clean all day long. Pick your favorite cleaning chemicals in UAE to meet your particular hygiene standards. Practice wellness and sanitation with professional grade cleaners from Alisuq.
                            </p>
                            <h2>
                                Pick From Our Best Seller Cleaners
                            </h2>
                            <p>
                                Featuring the widest array of choices, our cleaners range is a one-stop solution for every home. From kitchen cleaning tools to household cleaning essentials, this extensive collection brings you all the right products. Whether it's your floors that require a thorough mop, or your laundry that is brutally stained, our cleaners cover all possible cleaning aspects.                             </p>

                            <p>
                            Pick from <Link to='items/products?filter_by_category%5B%5D=323'>stain removers</Link>, <Link to='items/products?filter_by_category%5B%5D=325'>brooms</Link>, <Link to='items/products?filter_by_category%5B%5D=322'>all-purpose cleaners</Link>, sanitizers, and specialized cleaning solutions to tackle daily stains and spots. With powerful dirt picking agents and colour extraction capabilities, our cleaners excel in delivering instant results. Make your surroundings clean and pristine with heavy-duty disinfectants and rinsing agents. Browse this range to find the ideal picks for commercial and domestic needs!
                            </p>


                            <h3>
                                Cleaning Chemicals
                            </h3>
                            <p>
                                Our range of cleaning chemicals take on and ace every household cleaning task. With instant acting <Link to='items/products?filter_by_category%5B%5D=318'>glass cleaners</Link>, floor, upholstery, and metal cleaning solutions, no part of your home will remain blemished. Purchase high-end cleaning solutions that instantly pick up stains and dirt from clothes, floors, furniture, and more. Pick an all-purpose cleaner to enjoy versatile functionality and superior performance in a single package.                             </p>
                            <h3>
                                Cleaning Equipment
                            </h3>
                            <p>
                                We have crafted an extensive range of sturdy and practical cleaning tools and equipment to assist you in daily cleaning. From scrubs to squeeges, mops to brooms, find everything you need to achieve an immaculate shine. Wipe away dirt, grime, and solid contaminants with our specialized cleaning equipment designed to counter challenging cleaning jobs.
                            </p>
                            <h3>
                                Cleaning Essentials
                            </h3>
                            <p>
                                House cleaning has never been easier with our selection of cleaning essentials. Purchase <Link to='items/products?filter_by_category%5B%5D=329'>sanitizers</Link>, <Link to='items/products?filter_by_category%5B%5D=330'>gloves</Link>, <Link to='items/products?filter_by_category%5B%5D=331'>garbage bags</Link>, and more to practice optimal hygiene. Our cleaning range is designed to protect you and your family from potential disease and allergy-causing agents. With the pleasant and floral scents of our room fresheners, your home will not only look clean, but smell divine as well!
                            </p>
                            <h2>
                                Practice Hygiene With The Best Rated Cleaning Tools

                            </h2>
                            <p>
                                Alisuq is a one-stop shop for all your home and commercial cleaning needs. We have organic compounds for eco-conscious individuals, as well as high-grade concrete and floor cleaners. Eliminate all dust, dirt, stains, and germs from your home with consumer-trusted cleaning chemicals. Expand the life and utility of your daily-wear garments with high-efficiency stain removers that lift even permanent stains from clothes within minutes. Choose from an array of cleaning materials to make your regular cleaning tasks swift and convenient.
                            </p>
                            <p>
                                Eco friendly compounds with no harsh surfactants or chemicals to avoid damage to plant or animal life.
                                Economical rates on the most in-demand cleaning tools and equipment.
                                <Link to='items/products?filter_by_category%5B%5D=215&filter_by_category%5B%5D=319&filter_by_category%5B%5D=321&filter_by_category%5B%5D=322&filter_by_category%5B%5D=318&filter_by_category%5B%5D=320&filter_by_category%5B%5D=323'>High grade cleaning chemicals</Link>, integrated with powerful spot lifting technology to eliminate all color and solid contaminants from home surfaces.
                            </p>
                            <p>
                                Bristled brushes, mops, and brooms for effective clean up of floors and flat surfaces.
                                Wide range of options for furniture, laundry, outdoor, kitchen, floor, vehicle, and all-purpose cleaning.
                            </p>
                            <h2>
                                Purchase From Consumer-Trusted Cleaning Materials Brands
                            </h2>
                            <p>
                                Fill up your cart with quality cleaners from top-notch brands. There’s no better assistance in cleaning than a heavy-duty cleaning agent, equipment, or appliance. Harboring innovative technology and advanced features, we adopt a vision of delivering complete customer satisfaction. Our cleaning line adheres to the highest standards to deliver performance like no other. Relying on the most in-demand features of market-trusted brands, we bring you high-end cleaning tools and equipment to make everyday cleaning swift and hassle-free. Enjoy precision cleaning with professional-grade surfactant solutions, cleaning supplies, and equipment.
                            </p>

                            <h3>
                                Krud Kutter
                            </h3>
                            <p>
                                With the finest cleaning agents and solutions, Krud Kutter is the leader in producing commercial and domestic cleaners. Scrub off the most stubborn stains and spots from all home surfaces, all thanks to the reliable cleaning efficiency of Krud Kutter solvents.
                            </p>
                            <h3>
                                Ducon
                            </h3>
                            <p>
                                A reputable name in the cleaning industry, Ducon’s cleaning equipment and sponges are known to tackle the toughest grime in minutes.
                            </p>

                            <h3>
                                Paul Masquin
                            </h3>
                            <p>
                                Combining all practical features in the cleaning gear, Paul Masquin has become one of our bestsellers. Purchase their top quality microfiber mops, floor wipers, and dust pan sets from our range to incorporate more convenience into your cleaning tasks.
                            </p>
                            <h3>
                                Dr Beckmann
                            </h3>
                            <p>
                                A well known name in household cleaning products, Dr Beckmann puts together the best stain removing and grease cutting formulas to deliver instant clean up. Doing laundry is a no-hassle task with the wide portfolio of Dr Beckmann’s products in our store.
                            </p>
                            <h3>
                                Eco Care
                            </h3>
                            <p>
                                Eco Care features the largest selection of biodegradable garbage bags and containers. Practice hygiene while keeping your support for environmental safety intact with this brand.

                            </p>
                            <p>
                                Browse this range to find other practical cleaners from local and international brands. Save time and money on domestic and commercial cleaning with high-efficiency solutions, equipment, and hygiene supplies, available at best rates in UAE.
                            </p>

                            <h2>
                                Enjoy Secure Shopping On Alisuq Cleaners
                            </h2>
                            <p>
                                Online shopping has never been as secure and comfortable than with Alisuq. We are a customer-trusted platform with an endless stock of home improvement, construction, automotive and cleaning gear. Get your hands on the finest merchandise in UAE, only at Alisuq!
                            </p>
                            <h3>
                                Best Rates
                            </h3>
                            <p>
                                Our prime focus if to support luxury living by making premium quality products accessible to everyone. Purchase best cleaning essentials, <Link to='items/products?filter_by_category%5B%5D=183&filter_by_category%5B%5D=205&filter_by_category%5B%5D=290&filter_by_category%5B%5D=289&filter_by_category%5B%5D=206&filter_by_category%5B%5D=291&filter_by_category%5B%5D=207&filter_by_category%5B%5D=210&filter_by_category%5B%5D=294&filter_by_category%5B%5D=295&filter_by_category%5B%5D=296&filter_by_category%5B%5D=297&filter_by_category%5B%5D=298&filter_by_category%5B%5D=299&filter_by_category%5B%5D=300&filter_by_category%5B%5D=301&filter_by_category%5B%5D=302&filter_by_category%5B%5D=303&filter_by_category%5B%5D=304&filter_by_category%5B%5D=305&filter_by_category%5B%5D=211&filter_by_category%5B%5D=306&filter_by_category%5B%5D=307&filter_by_category%5B%5D=308&filter_by_category%5B%5D=309&filter_by_category%5B%5D=212&filter_by_category%5B%5D=310&filter_by_category%5B%5D=449&filter_by_category%5B%5D=448&filter_by_category%5B%5D=450&filter_by_category%5B%5D=311&filter_by_category%5B%5D=452&filter_by_category%5B%5D=453&filter_by_category%5B%5D=451&filter_by_category%5B%5D=312&filter_by_category%5B%5D=313&filter_by_category%5B%5D=214&filter_by_category%5B%5D=208&filter_by_category%5B%5D=209&filter_by_category%5B%5D=292&filter_by_category%5B%5D=293&filter_by_category%5B%5D=213&filter_by_category%5B%5D=314&filter_by_category%5B%5D=315&filter_by_category%5B%5D=316&filter_by_category%5B%5D=317'>appliances</Link>, and more at reasonable rates in UAE with us.
                            </p>
                            <h3>
                                Free Returns
                            </h3>
                            <p>
                                Return requests are catered to with utmost consideration at our customer support office. Our free returns policy is designed, so you can shop online without any risks or fear of fraud.                            </p>
                            <h3>
                                Instant Delivery
                            </h3>
                            <p>
                                Get your urgent orders delivered to you within 24–36 hours. Our shipping service is free of delays and mishaps, so your orders can get to you in the allocated time.
                            </p>
                            <p>
                                Explore this collection to get the finest cleaners for your homes, offices, restaurants, and other domestic and commercial spaces. Enjoy the efficiency of high-end cleaning supplies to make your daily sweeping, washing, and wiping tasks swift and precise.
                            </p>


                        </div>



                    </div>
                </div>

                <div className="w-100">
                    <WebsiteFooter />
                </div>
            </div>
        </Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
        countryUrl: state.language.countryUrl,
        countryId: state.language.countryId,
        language: state.language.language,
    };
};


export default connect(mapStateToProps, null)(CampingPage);


