import React, { Fragment, useState } from "react";

import "../../assets/styles/customer/selectshippingaddress.scss";

import CustomerAddress from "./CustomerAddress";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { apiJson } from "../../Api";
import ClipLoader from "react-spinners/ClipLoader";
import { useTranslation } from "react-i18next";
import { set_cart_items } from "../../redux/actions/CartAction";

const SelectShippingAddress = (props) => {
  const { t } = useTranslation();
  const [redirectPage, setRedirectPage] = useState(false);
  const [redirect, setRedirect] = useState({
    value: false,
    path: "/" + props.countryUrl + "-" + props.language + "/select-address",
  });
  const [loading, setLoading] = useState(false);
  let country_code =
    props.country.toLowerCase() === "united arab emirates"
      ? "uae"
      : props.country.toLowerCase() === "saudi arabia"
        ? "ksa"
        : "egypt";
  let message = "";

  const showError = () => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const handle = () => {
    setLoading(true);
    let selectedAdd = {};
    props.address.map((address) =>
      address.default ? (selectedAdd = address) : address
    );
    if (Object.entries(selectedAdd).length === 0) {
      setLoading(false);
      message = "Please select your Shipping Address";
      showError();
    } else {
      const request = {
        order: {
          address_id: selectedAdd.id,
          is_delivery: 1
        },
      };
      if (selectedAdd.contact_number_verified) {
        apiJson.select
          .varifyAdress(request, props.countryId)
          .then((response) => {
            setLoading(false);
            if (response.success) {
              props.set_cart_items({
                order: {
                  address: selectedAdd,
                  id: response.data.id,
                  createdAddress: response.data,
                },
              });
              setRedirect({
                value: true,
                path:
                  "/" + props.countryUrl + "-" + props.language + "/checkout",
              });
            } else if (!response.status) {
              message = t("errors.wrong");
              showError();
            } else {
              if (response.status >= 400 && response.status < 500) {
                message = response.message
                  ? response.message
                  : response.errors
                    ? response.errors
                    : t("errors.wrong");
                showError();
              } else {
                message = response.message
                  ? response.message !== ""
                    ? response.message
                    : t("errors.wrong")
                  : t("errors.wrong");
                showError();
              }
            }
          });
      } else {
        props.set_cart_items({
          order: {
            id: selectedAdd.id,

            number: selectedAdd.contact_number,
            address: selectedAdd,
            request: request,
          },
        });
        // setRedirect({
        //   value: true,
        //   path: "/" + props.countryUrl + "-" + props.language + "/phone",
        // });

        // Added By Kamran
        apiJson.phone.verifyOtp(selectedAdd.id, "1").then((response) => {
          if (response.success) {
            apiJson.select
              .varifyAdress({
                order: {
                  address_id: selectedAdd.id,
                  is_delivery: 1
                }
              }, props.countryId)
              .then((response) => {
                setLoading(false);
                if (response.success) {
                  props.set_cart_items({
                    order: {
                      address: selectedAdd,
                      id: response.data.id,
                      createdAddress: response.data,
                    },
                  });
                  setRedirect({
                    value: true,
                    path:
                      "/" + props.countryUrl + "-" + props.language + "/checkout",
                  });
                  const timer = setTimeout(() => {
                    setRedirect({ value: false });
                  }, 1000);
                  clearTimeout(timer);
                } else if (!response.status) {
                  setLoading(false);
                  message = t("errors.wrong");
                  showError();
                } else {
                  setLoading(false);
                  if (response.status >= 400 && response.status < 500) {
                    message = response.message
                      ? response.message
                      : response.errors
                        ? response.errors
                        : t("errors.wrong");
                    showError();
                  } else {
                    message = response.message
                      ? response.message !== ""
                        ? response.message
                        : t("errors.wrong")
                      : t("errors.wrong");
                    showError();
                  }
                }
              });
          } else if (!response.status) {
            setLoading(false);
            message = response.message
              ? response.message !== ""
                ? response.message
                : t("errors.wrong")
              : t("errors.wrong");
            showError();
          } else {
            setLoading(false);
            if (response.status >= 400 && response.status < 500) {
              message = response.message
                ? response.message
                : response.errors
                  ? response.errors
                  : t("errors.wrong");
              showError();
            } else {
              message = response.message
                ? response.message !== ""
                  ? response.message
                  : t("errors.wrong")
                : t("errors.wrong");
              showError();
            }
          }
        });

        // setRedirect({
        //   value: true,
        //   path:
        //     "/" + props.countryUrl + "-" + props.language + "/checkout",
        // });
      }
      const timer = setTimeout(() => {
        setRedirect({
          value: false,
          path:
            "/" + props.countryUrl + "-" + props.language + "/select-address",
        });
      }, 1000);
      clearTimeout(timer);
    }
  };

  return (
    <Fragment>
      <div className="select-shipping-address-desktop">
        <div className="row address-row">
          <div className="addresses d-flex  flex-row align-items-md-start align-items-lg-end mx-auto">
            {props.address.map(
              (address) =>
                (address.country.toLowerCase() ===
                  props.country.toLowerCase() ||
                  address.country.toLowerCase() === country_code) && (
                  <div className="w-340-px address-col mt-5" key={address.id}>
                    <CustomerAddress
                      id={address.id}
                      verified={address.contact_number_verified}
                      isPrimary={address.default}
                      label={address.label}
                      name={address.first_name}
                      address={address.address1}
                      phone={address.contact_number}
                    />
                  </div>
                )
            )}
            <Link
              to={"/" + props.countryUrl + "-" + props.language + "/address"}
              className="address-col"
            >
              {/* <div
              onClick={(e) => {
                e.preventDefault();
                setRedirectPage(true);
                const timer = setTimeout(() => {
                  setRedirectPage(false);
                }, 1000);
                clearTimeout(timer);
              }}
              className="address-col"
            > */}
              <div className="w-340-px mt-5 add-address-col d-flex align-items-center justify-content-center">
                <div className="add-address">
                  + {t("select-shipping-address.add-new-address")}
                </div>
              </div>
              {/* </div> */}
            </Link>
          </div>
          <div className="continue d-flex justify-content-end mx-auto">
            <button
              onClick={(e) => {
                e.preventDefault();
                handle();
              }}
              type="button"
              className="continue-button"
              style={{ height: "54px" }}
            >
              {loading ? (
                <div className="d-flex justify-content-center align-items-center w-100">
                  <ClipLoader color="#fff" loading size={20} />
                </div>
              ) : (
                t("select-shipping-address.continue")
              )}
            </button>
          </div>
        </div>
      </div>

      {redirectPage && (
        <Redirect
          to={"/" + props.countryUrl + "-" + props.language + "/address"}
        />
      )}
      {redirect.value && (
        <Redirect
          push
          to={{
            pathname: redirect.path,
            state: redirect.state,
          }}
        />
      )}
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    address: state.select.address,
    countryUrl: state.language.countryUrl,
    language: state.language.language,
    countryId: state.language.countryId,
    country: state.language.country,
  };
};

const mapDispatchToProps = { set_cart_items };
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SelectShippingAddress);
