import { type } from "../../types/types";

export default function reducer(
  state = { orders: [], banners: [], orderDetails: [] },
  action
) {
  switch (action.type) {
    case type.SET_ORDERS:
      return { ...state, ...action.orders };
    case type.SET_ORDER_DETAIL:
      return { ...state, ...action.orderDetails };
    default:
      return state;
  }
}
