import React, { Fragment } from "react";
import WebsiteHeader from "../../components/header/WebsiteHeader";
import WebsiteFooter from "../../components/Footer/WebsiteFooter";
import pagesbannerimg from "../../assets/images/footer-pages-banners/alisuq_story.jpg";
import "../../assets/styles/category-homepages/building-and-constructioncss.scss";
import bulkorderimg from "../../assets/images/footer-page-images/a.png";
import { useEffect } from "react";
import $ from "jquery";

const VacuumCleaners = () => {
    useEffect(() => {
        window.document.title = "Alisuq's Premium Vacuum Cleaners";

        $("html, body").animate({ scrollTop: 0 }, "fast");
    }, []);
    return (
        <Fragment>
            <div className="product-home-page-desktop mx-auto">
                <WebsiteHeader />
                <div className="pagesbanner">
                    <img
                        src="https://k.nooncdn.com/mpcms/EN0001/assets/95e628c1-1662-429c-9b43-dfeaf4cf6830.png"
                        alt="category"
                        style={{
                            width: "100%",
                            height: "100%",
                        }}
                    ></img>
                </div>
                <div className="content-wrapper all-page-container">
                    <div class="shopbycat">
                        <h3>Shop by Category</h3>
                    </div>



                </div>

                <div className="row catrow"
                    style={{
                        marginLeft: "0px !important",
                        marginRight: "0px !important",
                        width: "100%",
                    }}
                >
                    <div className="col-lg-4 col-md-4 col-sm-4 col-4 catbox">
                        <a href="offers/section/166">
                            <img className="img-fluid"
                                src="https://k.nooncdn.com/mpcms/EN0001/assets/e2735f01-d1e5-4e17-80dd-05ba8e9c2a38.png"
                                alt="category"
                                style={{
                                    width: "100%",
                                }}
                            ></img>
                            <h6 className="cattxt">Hot Deals</h6>
                        </a>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-4 col-4 catbox">
                        <a href="offers/section/166">
                            <img className="img-fluid"
                                src="https://k.nooncdn.com/mpcms/EN0001/assets/e2735f01-d1e5-4e17-80dd-05ba8e9c2a38.png"
                                alt="category"
                                style={{
                                    width: "100%",
                                }}
                            ></img>
                            <h6 className="cattxt">Hot Deals</h6>
                        </a>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-4 col-4 catbox">
                        <a href="offers/section/166">
                            <img className="img-fluid"
                                src="https://k.nooncdn.com/mpcms/EN0001/assets/e2735f01-d1e5-4e17-80dd-05ba8e9c2a38.png"
                                alt="category"
                                style={{
                                    width: "100%",
                                }}
                            ></img>
                            <h6 className="cattxt">Hot Deals</h6>
                        </a>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-4 col-4 catbox">
                        <a href="offers/section/166">
                            <img className="img-fluid"
                                src="https://k.nooncdn.com/mpcms/EN0001/assets/e2735f01-d1e5-4e17-80dd-05ba8e9c2a38.png"
                                alt="category"
                                style={{
                                    width: "100%",
                                }}
                            ></img>
                            <h6 className="cattxt">Hot Deals</h6>
                        </a>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-4 col-4 catbox">
                        <a href="offers/section/166">
                            <img className="img-fluid"
                                src="https://k.nooncdn.com/mpcms/EN0001/assets/e2735f01-d1e5-4e17-80dd-05ba8e9c2a38.png"
                                alt="category"
                                style={{
                                    width: "100%",
                                }}
                            ></img>
                        
                              
                            <h6 className="cattxt">Hot Deals</h6>
                        </a>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-4 col-4 catbox">
                        <a href="offers/section/166">
                            <img className="img-fluid"
                                src="https://k.nooncdn.com/mpcms/EN0001/assets/e2735f01-d1e5-4e17-80dd-05ba8e9c2a38.png"
                                alt="category"
                                style={{
                                    width: "100%",
                                }}
                            ></img>
                            <h6 className="cattxt">Hot Deals</h6>
                        </a>
                    </div>
                </div>
                <div className="w-100">
                    <WebsiteFooter />
                </div>
            </div>
        </Fragment>
    );
};

export default VacuumCleaners;


