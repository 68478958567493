import React, { Fragment } from "react";
import { connect } from "react-redux";
import { format } from "date-fns";

import "../../../../../assets/styles/utils.scss";
import "../../../../../assets/styles/customer/account/orders/shipments/ShipmentItemMobile.scss";

import { getMobileImage } from "../../../../../utils/utils.js";
import { useTranslation } from "react-i18next";

const ShipmentItemMobile = ({ shipmentItem, currency, language, type }) => {
  const { t } = useTranslation();
  return (
    <div className="shipment-item-mobile">
      <div className="order-detail">
        {shipmentItem.orderable[`brand_name_${language}`] && (
          <span className="brand gray-text">
            {shipmentItem.orderable[`brand_name_${language}`]}
          </span>
        )}
        <span className="title">
          {shipmentItem.orderable[`name_${language}`]}
        </span>
        {shipmentItem.orderable[`sku`] && (
          <span className="brand gray-text">
            {shipmentItem.orderable[`sku`]}
          </span>
        )}
        {type?.toLowerCase() === "services" && (
          <Fragment>
            <div
              className="start-date d-flex"
              style={{
                marginTop: "3px",
                fontSize: "13px",
                fontWeight: "400",
              }}
            >
              <span
                style={{
                  marginInlineEnd: "8px",
                  color: "gray",
                }}
              >
                {language === "en" ? "Start At" : "تبدأ في"}:
              </span>
              <span style={{ color: "green" }}>
                {format(new Date(shipmentItem.start_at), "EEE MMM dd, yyy p")}
              </span>
            </div>
            <div
              className="start-date d-flex"
              style={{
                marginTop: "3px",
                fontSize: "13px",
                fontWeight: "400",
              }}
            >
              <span
                style={{
                  marginInlineEnd: "8px",
                  color: "gray",
                }}
              >
                {language === "en" ? "End At" : "يغلق عند مستوى"}:
              </span>
              <span style={{ color: "green", fontWeight: "400" }}>
                {format(new Date(shipmentItem.end_at), "EEE MMM dd, yyy p")}
              </span>
            </div>
          </Fragment>
        )}
        <span className="fw-700 price">
          {currency.toUpperCase()} {shipmentItem.price}
        </span>
        {shipmentItem.quantity && (
          <span className="qty">QTY {shipmentItem.quantity}</span>
        )}
        <span className="sold-text">
          {t("order.sold-by")}{" "}
          <span className="fw-700">
            {shipmentItem.orderable[`store_name_${language}`]}
          </span>
        </span>
      </div>

      <div className="image-container d-flex justify-content-center">
        <img
          style={{
            width: "auto",
            maxWidth: "100%",
            height: "100%",
          }}
          src={getMobileImage(
            shipmentItem.orderable.featured_image_resized,
            shipmentItem.orderable.featured_image
          )}
          alt=""
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    language: state.language.language,
  };
};

export default connect(mapStateToProps, null)(ShipmentItemMobile);
