import React, { Fragment, useState } from "react";
import { Link } from 'react-router-dom';
import WebsiteHeader from "../../components/header/WebsiteHeader";
import WebsiteFooter from "../../components/Footer/WebsiteFooter";
import "../../assets/styles/category-homepages/home-appliances.scss";

import ProductList from "../../components/product/ProductList";

import TopBanner1 from "../../assets/images/home-appliances/Vacuum-Category-Banner.png";
import TopBanner2 from "../../assets/images/home-appliances/Household-Category-Banner.png";
import TopBanner3 from "../../assets/images/home-appliances/Kitchen-Category-Banner.png";
import one from "../../assets/images/individual-pages/home-appliances/VacuumCleaners.png";

import two from "../../assets/images/individual-pages/home-appliances/Washer.png";
import three from "../../assets/images/individual-pages/home-appliances/Heater.png";
import four from "../../assets/images/individual-pages/home-appliances/Microwave.png";
import five from "../../assets/images/individual-pages/home-appliances/Refrigerator.png";
import six from "../../assets/images/individual-pages/home-appliances/Freezer.png";
import seven from "../../assets/images/individual-pages/home-appliances/Juicer.png";

import eight from "../../assets/images/individual-pages/home-appliances/Aircondition.png";
import nine from "../../assets/images/individual-pages/home-appliances/Toaster.png";
import ten from "../../assets/images/individual-pages/home-appliances/Aircondition.png";

import offerbanner1 from "../../assets/images/individual-pages/home-appliances/Pressure-Washers-Category-Offer.png";
import offerbanner2 from "../../assets/images/individual-pages/home-appliances/Kitchen-Category-Offer.png";

import offerbanner3 from "../../assets/images/individual-pages/home-appliances/Vacuum-Category-Offer.png";
import offerbanner4 from "../../assets/images/individual-pages/home-appliances/Microwave-Category-Offer.png";

import offerbanner5 from "../../assets/images/individual-pages/home-appliances/Robotic-Cleaner-Category-Offer.png";
import offerbanner6 from "../../assets/images/individual-pages/home-appliances/Air-Purifiers-Category-Offer.png";

import offerbanner7 from "../../assets/images/individual-pages/home-appliances/Cooler-Offer.jpg";
import offerbanner8 from "../../assets/images/individual-pages/home-appliances/Blenders-Category-Offer.png";

import offerbanner9 from "../../assets/images/individual-pages/home-appliances/Air-Fryer-Category-Offer.png";
import offerbanner10 from "../../assets/images/individual-pages/home-appliances/Coffee-Maker-Category-Offer.png";

import offerbanner11 from "../../assets/images/individual-pages/home-appliances/AC-Category-Offer.png";
import offerbanner12 from "../../assets/images/individual-pages/home-appliances/Iron-Category-Offer.png";




import { useEffect } from "react";
import $ from "jquery";
import { connect } from "react-redux";
import { apiJson } from "../../Api";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination, Navigation } from "swiper/core";
SwiperCore.use([Pagination, Navigation]);

const CampingPage = (props) => {
    const [section1, setSection1] = useState([]);
    const [section2, setSection2] = useState([]);
    const [section3, setSection3] = useState([]);
    const [section4, setSection4] = useState([]);
    const [section5, setSection5] = useState([]);


    const [products2, setProducts2] = useState([]);

    useEffect(() => {
        window.document.title = "Home Appliances & kitchen appliances |  Alisuq start buliding";

        $("html, body").animate({ scrollTop: 0 }, "fast");

        apiJson.
            homepage.getSections(61, props.countryId)
            .then(response => {
                if (response.success) {
                    // console.log(response.data);
                    // setProducts(response.data.products);
                    setSection1(response.data);
                }
            });

        apiJson.
            homepage.getSections(62, props.countryId)
            .then(response => {
                if (response.success) {
                    // console.log(response.data);
                    // setProducts(response.data.products);
                    setSection2(response.data);
                }
            });

        apiJson.
            homepage.getSections(63, props.countryId)
            .then(response => {
                if (response.success) {
                    // console.log(response.data);
                    // setProducts(response.data.products);
                    setSection3(response.data);
                }
            });
        apiJson.
            homepage.getSections(64, props.countryId)
            .then(response => {
                if (response.success) {
                    // console.log(response.data);
                    // setProducts(response.data.products);
                    setSection4(response.data);
                }
            });
        apiJson.
            homepage.getSections(65, props.countryId)
            .then(response => {
                if (response.success) {
                    // console.log(response.data);
                    // setProducts(response.data.products);
                    setSection5(response.data);
                }
            });

    }, []);
    return (
        <Fragment>
            <div className="product-home-page-desktop mx-auto paintspage">
                <WebsiteHeader />
                <div className="mypages-container">
                    <Swiper className="homepage-main-banner-swiper"
                        spaceBetween={0}
                        slidesPerView={1}
                        pagination={false}
                        navigation={true}
                    >
                        <SwiperSlide
                            onClick={(e) => e.preventDefault()}
                            key={1}
                        >
                            <Link to={{
                                pathname: "/" + props.countryUrl +
                                    "-" + props.language + "/items/products",
                                search: "filter_by_category%5B%5D=209&filter_by_category%5B%5D=292&filter_by_category%5B%5D=293&filter_by_category%5B%5D=489"
                            }}>
                                <img
                                    src={TopBanner1}
                                    className="img img-fluid"
                                    height="110px"
                                />
                            </Link>
                        </SwiperSlide>
                        <SwiperSlide
                            onClick={(e) => e.preventDefault()}
                            key={1}
                        >
                            <Link to={{
                                pathname: "/" + props.countryUrl +
                                    "-" + props.language + "/items/products",
                                search: "filter_by_category%5B%5D=211&filter_by_category%5B%5D=306&filter_by_category%5B%5D=307&filter_by_category%5B%5D=308&filter_by_category%5B%5D=309&filter_by_category%5B%5D=464"
                            }}>
                                <img
                                    src={TopBanner2}
                                    className="img img-fluid"
                                    height="110px"
                                />
                            </Link>
                        </SwiperSlide>
                        <SwiperSlide
                            onClick={(e) => e.preventDefault()}
                            key={1}
                        >
                            <Link to={{
                                pathname: "/" + props.countryUrl +
                                    "-" + props.language + "/items/products",
                                search: "filter_by_category%5B%5D=210&filter_by_category%5B%5D=295&filter_by_category%5B%5D=299&filter_by_category%5B%5D=300&filter_by_category%5B%5D=302&filter_by_category%5B%5D=304&filter_by_category%5B%5D=294&filter_by_category%5B%5D=296&filter_by_category%5B%5D=298&filter_by_category%5B%5D=303&filter_by_category%5B%5D=297&filter_by_category%5B%5D=301&filter_by_category%5B%5D=305"
                            }}>
                                <img
                                    src={TopBanner3}
                                    className="img img-fluid"
                                    height="110px"
                                />
                            </Link>
                        </SwiperSlide>

                    </Swiper>
                    <div className="emptyspacer"></div>
                    <div className="home-appliances">
                        <div className="titlewrap">
                            <h2 className="section-title lft catsec">{section1["title_" + props.language]}</h2>
                        </div>
                        <div className="surfacepat1">
                            <ProductList
                                type="product"
                                home={true}
                                products={section1.products}
                            />
                        </div>
                        <div className="emptyspacer"></div>


                        <h2 className="sectiontitle-individualpage">Shop by category</h2>
                        <div className="emptyspacer"></div>

                        <Swiper
                            spaceBetween={40}
                            slidesPerView={7}
                            pagination={false}
                            navigation={true}
                        >
                            <SwiperSlide
                                onClick={(e) => e.preventDefault()}
                                key={1}
                            >
                                <Link to={{
                                    pathname: "/" + props.countryUrl +
                                        "-" + props.language + "/items/products",
                                    search: "filter_by_category%5B%5D=209&filter_by_category%5B%5D=292&filter_by_category%5B%5D=293&filter_by_category%5B%5D=489"
                                }}>
                                    <img
                                        src={one}
                                        className="img-img-fluid sbc"
                                        height="150px"
                                    />
                                </Link>
                            </SwiperSlide>
                            <SwiperSlide
                                onClick={(e) => e.preventDefault()}
                                key={1}
                            >
                                <Link to={{
                                    pathname: "/" + props.countryUrl +
                                        "-" + props.language + "/items/products",
                                    search: "filter_by_category%5B%5D=206&filter_by_category%5B%5D=291"
                                }}>
                                    <img
                                        src={two} className="img-img-fluid sbc"
                                        height="150px"
                                    />
                                </Link>
                            </SwiperSlide>
                            <SwiperSlide
                                onClick={(e) => e.preventDefault()}
                                key={1}
                            >
                                <Link to={{
                                    pathname: "/" + props.countryUrl +
                                        "-" + props.language + "/items/products",
                                    search: "filter_by_category%5B%5D=214"
                                }}>
                                    <img
                                        src={three} className="img-img-fluid sbc"
                                        height="150px"
                                    />
                                </Link>
                            </SwiperSlide>
                            <SwiperSlide
                                onClick={(e) => e.preventDefault()}
                                key={1}
                            >
                                <Link to={{
                                    pathname: "/" + props.countryUrl +
                                        "-" + props.language + "/items/products",
                                    search: "filter_by_category%5B%5D=208"
                                }}>
                                    <img
                                        src={four} className="img-img-fluid sbc"
                                        height="150px"
                                    />
                                </Link>
                            </SwiperSlide>
                            <SwiperSlide
                                onClick={(e) => e.preventDefault()}
                                key={1}
                            >
                                <Link to={{
                                    pathname: "/" + props.countryUrl +
                                        "-" + props.language + "/items/products",
                                    search: "filter_by_category%5B%5D=205&filter_by_category%5B%5D=290&filter_by_category%5B%5D=289"
                                }}>
                                    <img
                                        src={five}
                                        className="img-img-fluid sbc"
                                        height="150px"
                                    />
                                </Link>
                            </SwiperSlide>
                            <SwiperSlide
                                onClick={(e) => e.preventDefault()}
                                key={1}
                            >
                                <Link to={{
                                    pathname: "/" + props.countryUrl +
                                        "-" + props.language + "/items/products",
                                    search: "filter_by_category%5B%5D=290"
                                }}>
                                    <img
                                        src={six} className="img-img-fluid sbc"
                                        height="150px"
                                    />
                                </Link>
                            </SwiperSlide>
                            <SwiperSlide
                                onClick={(e) => e.preventDefault()}
                                key={1}
                            >
                                <Link to={{
                                    pathname: "/" + props.countryUrl +
                                        "-" + props.language + "/items/products",
                                    search: "filter_by_category%5B%5D=295"
                                }}>
                                    <img
                                        src={seven} className="img-img-fluid sbc"
                                        height="150px"
                                    />
                                </Link>
                            </SwiperSlide>
                            <SwiperSlide
                                onClick={(e) => e.preventDefault()}
                                key={1}
                            >
                                <Link to={{
                                    pathname: "/" + props.countryUrl +
                                        "-" + props.language + "/items/products",
                                    search: "filter_by_category%5B%5D=297"
                                }}>
                                    <img
                                        src={eight} className="img-img-fluid sbc"
                                        height="150px"
                                    />
                                </Link>
                            </SwiperSlide>
                            <SwiperSlide
                                onClick={(e) => e.preventDefault()}
                                key={1}
                            >
                                <Link to={{
                                    pathname: "/" + props.countryUrl +
                                        "-" + props.language + "/items/products",
                                    search: "filter_by_category%5B%5D=300"
                                }}>
                                    <img
                                        src={nine} className="img-img-fluid sbc"
                                        height="150px"
                                    />
                                </Link>
                            </SwiperSlide>
                            <SwiperSlide
                                onClick={(e) => e.preventDefault()}
                                key={1}
                            >
                                <Link to={{
                                    pathname: "/" + props.countryUrl +
                                        "-" + props.language + "/items/products",
                                    search: "filter_by_category%5B%5D=310&filter_by_category%5B%5D=449&filter_by_category%5B%5D=448&filter_by_category%5B%5D=450"
                                }}>
                                    <img
                                        src={ten} className="img-img-fluid sbc"
                                        height="150px"
                                    />
                                </Link>
                            </SwiperSlide>

                        </Swiper>

                        <div className="row mt-4">
                            <div className="col-6 mt-2 mb-2">
                                <Link to={{
                                    pathname: "/" + props.countryUrl +
                                        "-" + props.language + "/items/products",
                                    search: "filter_by_category%5B%5D=464"
                                }}>
                                    <img
                                        src={offerbanner1}
                                        alt="category"
                                        className="img img-fluid"
                                    />
                                </Link>
                            </div>
                            <div className="col-6 mt-2 mb-2">
                                <Link to={{
                                    pathname: "/" + props.countryUrl +
                                        "-" + props.language + "/items/products",
                                    search: "filter_by_category%5B%5D=210&filter_by_category%5B%5D=295&filter_by_category%5B%5D=299&filter_by_category%5B%5D=300&filter_by_category%5B%5D=302&filter_by_category%5B%5D=304&filter_by_category%5B%5D=294&filter_by_category%5B%5D=296&filter_by_category%5B%5D=298&filter_by_category%5B%5D=303&filter_by_category%5B%5D=297&filter_by_category%5B%5D=301&filter_by_category%5B%5D=305"
                                }}>
                                    <img
                                        src={offerbanner2}
                                        alt="category"
                                        className="img img-fluid"
                                    />
                                </Link>
                            </div>
                            <div className="emptyspacer"></div>

                            <div className="titlewrap">
                                <h2 className="section-title lft catsec">{section2["title_" + props.language]}</h2>
                            </div>
                            <div className="surfacepat1">
                                <ProductList
                                    type="product"
                                    home={true}
                                    products={section2.products}
                                />
                            </div>
                            <div className="col-6 mt-2 mb-2">
                                <Link to={{
                                    pathname: "/" + props.countryUrl +
                                        "-" + props.language + "/items/products",
                                    search: "filter_by_category%5B%5D=211&filter_by_category%5B%5D=306&filter_by_category%5B%5D=307&filter_by_category%5B%5D=308&filter_by_category%5B%5D=309&filter_by_category%5B%5D=464"
                                }}>
                                    <img
                                        src={offerbanner3}
                                        alt="category"
                                        className="img img-fluid"
                                    />
                                </Link>
                            </div>
                            <div className="col-6 mt-2 mb-2">
                                <Link to={{
                                    pathname: "/" + props.countryUrl +
                                        "-" + props.language + "/items/products",
                                    search: "filter_by_category%5B%5D=208"
                                }}>
                                    <img
                                        src={offerbanner4}
                                        alt="category"
                                        className="img img-fluid"
                                    />
                                </Link>
                            </div>
                            <div className="emptyspacer"></div>
                            <div className="titlewrap">
                                <h2 className="section-title lft catsec">{section3["title_" + props.language]}</h2>
                            </div>
                            <div className="surfacepat1">
                                <ProductList
                                    type="product"
                                    home={true}
                                    products={section3.products}
                                />
                            </div>


                        </div>
                        <div className="emptyspacer"></div>
                        <div className="px-2">
                            <h2 className="sectiontitle-individualpage">Shop by brand</h2>
                            <div className="row">
                                <div className="col-2">
                                    <Link to={{
                                        pathname: "/" + props.countryUrl +
                                            "-" + props.language + "/items/products",
                                        search: "filter_by_brand%5B%5D=125"
                                    }}>
                                        <img src="https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/dxZDu1N13rDU9PNWi1zg57.png" className="img-img-fluid sbb" width="100%" />
                                    </Link>
                                </div>
                                <div className="col-2">
                                    <Link to={{
                                        pathname: "/" + props.countryUrl +
                                            "-" + props.language + "/items/products",
                                        search: "filter_by_brand%5B%5D=44"
                                    }}>
                                        <img src="https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/2gbCVfRpwUsgffJzgihfmH.png" className="img-img-fluid sbb" width="100%" />
                                    </Link>
                                </div>
                                <div className="col-2">
                                    <Link to={{
                                        pathname: "/" + props.countryUrl +
                                            "-" + props.language + "/items/products",
                                        search: "filter_by_brand%5B%5D=63"
                                    }}>
                                        <img src="https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/5tFSvZ45KEwSh8toU7PeA8.jpeg" className="img-img-fluid sbb" width="100%" />
                                    </Link>
                                </div>
                                <div className="col-2">
                                    <Link to={{
                                        pathname: "/" + props.countryUrl +
                                            "-" + props.language + "/items/products",
                                        search: "filter_by_brand%5B%5D=116"
                                    }}>
                                        <img src="https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/ddCgbQEmWK1k4yhsNxVFvT.png" className="img-img-fluid sbb" width="100%" />
                                    </Link>
                                </div>


                                <div className="col-2">
                                    <Link to={{
                                        pathname: "/" + props.countryUrl +
                                            "-" + props.language + "/items/products",
                                        search: "filter_by_brand%5B%5D=111"
                                    }}>
                                        <img src="https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/4F3VVifuiqgsuUY8g5otzF.png" className="img-img-fluid sbb" width="100%" />
                                    </Link>
                                </div>
                                <div className="col-2">
                                    <Link to={{
                                        pathname: "/" + props.countryUrl +
                                            "-" + props.language + "/items/products",
                                        search: "filter_by_brand%5B%5D=126"
                                    }}>
                                        <img src="https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/jjtrishRik1VDDaniqd868.png" className="img-img-fluid sbb" width="100%" />
                                    </Link>
                                </div>


                            </div>

                        </div>
                        <div className="row mt-4">
                            <div className="col-6 mt-2 mb-2">
                                <Link to={{
                                    pathname: "/" + props.countryUrl +
                                        "-" + props.language + "/items/products",
                                    search: "filter_by_category%5B%5D=489"
                                }}>
                                    <img
                                        src={offerbanner5}
                                        alt="category"
                                        className="img img-fluid"
                                    />
                                </Link>
                            </div>
                            <div className="col-6 mt-2 mb-2">
                                <Link to={{
                                    pathname: "/" + props.countryUrl +
                                        "-" + props.language + "/items/products",
                                    search: "filter_by_category%5B%5D=313"
                                }}>
                                    <img
                                        src={offerbanner6}
                                        alt="category"
                                        className="img img-fluid"
                                    />
                                </Link>
                            </div>
                        </div>
                        <div className="emptyspacer"></div>

                        <div className="titlewrap">
                            <h2 className="section-title lft catsec">{section4["title_" + props.language]}</h2>
                        </div>
                        <div className="surfacepat1">
                            <ProductList
                                type="product"
                                home={true}
                                products={section4.products}
                            />
                        </div>
                        <div className="emptyspacer"></div>
                        <div className="row mt-4">
                            <div className="col-6 mt-2 mb-2">
                                <Link to={{
                                    pathname: "/" + props.countryUrl +
                                        "-" + props.language + "/items/products",
                                    search: "filter_by_category%5B%5D=312"
                                }}>
                                    <img
                                        src={offerbanner7}
                                        alt="category"
                                        className="img img-fluid"
                                    />
                                </Link>
                            </div>
                            <div className="col-6 mt-2 mb-2">
                                <Link to={{
                                    pathname: "/" + props.countryUrl +
                                        "-" + props.language + "/items/products",
                                    search: "filter_by_category%5B%5D=294"
                                }}>
                                    <img
                                        src={offerbanner8}
                                        alt="category"
                                        className="img img-fluid"
                                    />
                                </Link>
                            </div>
                        </div>
                        <div className="emptyspacer"></div>

                        <div className="titlewrap">
                            <h2 className="section-title lft catsec">{section5["title_" + props.language]}</h2>
                        </div>
                        <div className="surfacepat1">
                            <ProductList
                                type="product"
                                home={true}
                                products={section5.products}
                            />
                        </div>
                        <div className="emptyspacer"></div>
                        <div className="row mt-4">
                            <div className="col-6 mt-2 mb-2">
                                <Link to={{
                                    pathname: "/" + props.countryUrl +
                                        "-" + props.language + "/items/products",
                                    search: "filter_by_category%5B%5D=298"
                                }}>
                                    <img
                                        src={offerbanner9}
                                        alt="category"
                                        className="img img-fluid"
                                    />
                                </Link>
                            </div>
                            <div className="col-6 mt-2 mb-2">
                                <Link to={{
                                    pathname: "/" + props.countryUrl +
                                        "-" + props.language + "/items/products",
                                    search: "filter_by_category%5B%5D=297"
                                }}>
                                    <img
                                        src={offerbanner10}
                                        alt="category"
                                        className="img img-fluid"
                                    />
                                </Link>
                            </div>
                            <div className="col-6 mt-2 mb-2">
                                <Link to={{
                                    pathname: "/" + props.countryUrl +
                                        "-" + props.language + "/items/products",
                                    search: "filter_by_category%5B%5D=310&filter_by_category%5B%5D=449&filter_by_category%5B%5D=448&filter_by_category%5B%5D=450"
                                }}>
                                    <img
                                        src={offerbanner11}
                                        alt="category"
                                        className="img img-fluid"
                                    />
                                </Link>
                            </div>
                            <div className="col-6 mt-2 mb-2">
                                <Link to={{
                                    pathname: "/" + props.countryUrl +
                                        "-" + props.language + "/items/products",
                                    search: "filter_by_category%5B%5D=306"
                                }}>
                                    <img
                                        src={offerbanner12}
                                        alt="category"
                                        className="img img-fluid"
                                    />
                                </Link>
                            </div>

                        </div>
                        <div className="emptyspacer"></div>

                        {/* 
                        <div className="emptyspacer"></div>
                        <h2 className="sectiontitle-individualpage text-left">Shop by Discount</h2>
                        <div className="emptyspacer"></div>
                        <div className="row">
                            <div className="col-3">
                                <Link to={"/" + props.countryUrl +
                                    "-" + props.language + "/offers/section/upto-30-off"}>
                                    <img src={Upto30} className="img-img-fluid" />
                                </Link>
                            </div>
                            <div className="col-3">
                                <Link to={"/" + props.countryUrl +
                                    "-" + props.language + "/offers/section/upto-50-off"}>
                                    <img src={Upto50} className="img-img-fluid" />
                                </Link>
                            </div>
                            <div className="col-3">
                                <Link to={"/" + props.countryUrl +
                                    "-" + props.language + "/offers/section/upto-70-off"}>
                                    <img src={Upto70} className="img-img-fluid" />
                                </Link>
                            </div>
                            <div className="col-3">
                                <Link to={"/" + props.countryUrl +
                                    "-" + props.language + "/offers/section/upto-90-off"}>
                                    <img src={Upto90} className="img-img-fluid" />
                                </Link>
                            </div>
                        </div> */}

                        <div className="emptyspacer"></div>
                        <div className="category-content">
                            <h1>
                                Home Appliances
                            </h1>
                            <p>
                                Home appliances offer an extra helping hand in household tasks. Investing in high-quality electronics saves you a lot of time and effort, generating more room for your hobbies and leisure activities. The key is in defining your standards first, and then choosing a home appliance that fulfills your expectations.

                                Alisuq is a leading retailer of home and kitchen appliances, which aims to bring consumers a wide range of small and heavy-duty home appliances. Find top-tier accessories, electronics, and brands, all in one place! Whether you’re looking for a mixer or chopper for your kitchen, or are in search of a good quality <Link to='items/products?filter_by_category%5B%5D=291'>washer</Link>, cooler, air purifier, and <Link to='items/products?filter_by_category%5B%5D=209&filter_by_category%5B%5D=292&filter_by_category%5B%5D=293'>vacuum cleaner</Link>, we have got you covered. We have acquired an extensive variety of products to offer efficient time and energy conservation in everyday household chores. Enjoy the finest delivery service and the safest return policy on your major investments. Get electronics from Alsiuq to save big and enjoy reliable performance for years!
                            </p>
                            <h2>
                                Best Seller Home Appliances:
                            </h2>
                            <p>
                                Upgrade your lifestyle with the latest features and designs in home electronics and <Link to='items/products?filter_by_category%5B%5D=210&filter_by_category%5B%5D=294&filter_by_category%5B%5D=295&filter_by_category%5B%5D=296&filter_by_category%5B%5D=297&filter_by_category%5B%5D=298&filter_by_category%5B%5D=299&filter_by_category%5B%5D=300&filter_by_category%5B%5D=301&filter_by_category%5B%5D=302&filter_by_category%5B%5D=303&filter_by_category%5B%5D=304&filter_by_category%5B%5D=305'>small kitchen appliances</Link>. Expand your family time by minimizing the hours you spend on household cleaning and maintenance.
                            </p>
                            <h3>
                                Fridge & Refrigerators:
                            </h3>
                            <p>
                                Keep your groceries fresh in the highest quality freezers and refrigerating units. From icemakers to freezers, you’ll find every possible variety here that matches your practical needs while offering durable aesthetics.
                            </p>
                            <h3>
                                Washing Machines:
                            </h3>
                            <p>
                                Laundry does not have to be a tiring chore with our collection of high-end <Link to='items/products?filter_by_category%5B%5D=206&filter_by_category%5B%5D=291'>washers and dryers</Link>. Featuring innovative mechanics and superior functionality, you can finish up your laundry in minimal time and effort.
                            </p>
                            <h3>
                                Dishwashing Appliances:
                            </h3>
                            <p>
                                An array of <Link to='items/products?filter_by_category%5B%5D=207'>dishwashers</Link> for quick and instant cleaning of everyday cutlery. The collection includes both front-control and top-control washers with different numbers of racks for different sizes of families.
                            </p>
                            <h3>
                                Microwaves & Ovens
                            </h3>
                            <p>
                                A must-have kitchen appliance in every home, now you can bake away all your stress in our range of <Link to='items/products?filter_by_category%5B%5D=208'>microwaves and ovens</Link>. With user-friendly features and easy operations, your cooking time and labor are sure to decline.
                            </p>
                            <h3>
                                Vacuums:
                            </h3>
                            <p>
                                We have portable vacuum machines designed to deliver immediate clean-up in carpeted spaces. High-end construction with state-of-the-art features for quick and effortless execution of tasks.
                            </p>
                            <h3>
                                Small Kitchen Appliances
                            </h3>
                            <p>
                                Limit time spent in the kitchen with modern appliances designed to deliver ease in small kitchen tasks. Find sandwich makers, juicers, blenders, stoves, coffee machines, electric kettles, and air fryers, all at one place!
                            </p>
                            <h3>
                                Household Electronics
                            </h3>
                            <p>
                                An endless array of convenient electronics for different household chores. Get steamers, <Link to='items/products?filter_by_category%5B%5D=306'>ironing machines</Link>, <Link to='items/products?filter_by_category%5B%5D=308'>water dispensers</Link>, and other practical appliances to optimize your surroundings.
                            </p>
                            <h3>
                                Heating & Cooling Devices
                            </h3>
                            <p>
                                Maintain optimal house environment with high-end air conditioners, heaters, fans, air purifiers, and coolers. Adopt a modern standard of living by investing in our cost-effective heating and cooling devices.
                            </p>
                            <h2>
                                Household Appliances:
                            </h2>
                            <p>
                                A home is a place that can be interchangeably used for comfort and laid-back energy. However, when there are so many chores lying around, it becomes extremely difficult to relax and unwind. Now you can transform your messy surroundings with the best-in-class household appliances from Alisuq!

                                Enjoy quick and effortless chores with futuristic designs that overcome overworking in living spaces. Our collection includes state-of-the-art electronics with simple operations and high functionality for effortless task execution. Each appliance is sourced from the most in-demand brands to ensure quality and durability. With simple installment techniques and latest features, they streamline house chores effectively. Not only that, the striking physical features will make you love making your morning coffee, or baking your favourite delicacies in a high-end oven. You can rely on our range of small home appliances, vacuum cleaners, washers, and <Link to='items/products?filter_by_category%5B%5D=210&filter_by_category%5B%5D=294&filter_by_category%5B%5D=295&filter_by_category%5B%5D=296&filter_by_category%5B%5D=297&filter_by_category%5B%5D=298&filter_by_category%5B%5D=299&filter_by_category%5B%5D=300&filter_by_category%5B%5D=301&filter_by_category%5B%5D=302&filter_by_category%5B%5D=303&filter_by_category%5B%5D=304&filter_by_category%5B%5D=305'>kitchen electronics</Link> to bring ease into your daily tasks.
                            </p>
                            <h2>
                                Appliances Brands
                            </h2>
                            <p>
                                Home appliances are indispensable in modern homes. They have revolutionized the standard lifestyle to incorporate luxury into a common man’s life. Investing in premium products offers great returns as there is nothing better than an extra helping hand.

                                Our carefully curated collection combines the best products from global brands. From Karcher to Siemens and Makita, there is a device for every household. Purchase the best quality home appliances in Dubai today from popular brands at affordable rates.
                            </p>
                            <h3>
                                Karcher:
                            </h3>
                            <p>
                                Presenting a premium collection of vacuum cleaners, washers, ironing stations, and mobile cleaners, Karcher has proved its name in the home appliances' industry.
                            </p>
                            <h3>
                                Bissel:
                            </h3>
                            <p>
                                From high-end air purifiers to sweeping equipment, Bissel never ceases to offer premium services via their home appliances.
                            </p>
                            <h3>
                                Fakhir:
                            </h3>
                            <p>
                                Featuring modernistic approach, Fakhir coffee makers, hand mixers, and blenders set standards in delivering functionality within kitchen spaces.
                            </p>
                            <h3>
                                Ariston:
                            </h3>
                            <p>
                                Ariston offers a range of solar and electric water heaters for a luxury bathroom experience, free of uncomfortable cold sprinkles.
                            </p>
                            <h3>
                                Siemens:
                            </h3>
                            <p>
                                The widely recognized name in the tech industry, we have acquired induction hobs, chimney hoods, and much more from Siemens to bring ease in daily chores.
                            </p>
                            <h3>
                                Makita:
                            </h3>
                            <p>
                                Manufacturing a range of electronics with a sense of style and practicality, Makita vacuums, cloth filters, and portable appliances offer reliable assistance in everyday household tasks.
                            </p>
                            <h2>
                                Purchase From Alisuq To Enjoy Customer Benefits
                            </h2>
                            <p>
                                For exclusive benefits, Alisuq has refined their service policies to bring consumers extended comfort in the purchasing process. We make home appliances accessible to everyone with a customer-friendly buyer’s experience.
                            </p>
                            <h3>
                                Longer Lifespan:
                            </h3>
                            <p>
                                Alisuq inhabits products from the most recognized international and local manufacturers to offer lasting performance.
                            </p>
                            <h3>
                                Promising Rates:
                            </h3>
                            <p>
                                Our goal is to make high-end electronics accessible to all consumers. With this objective in mind, we strive to offer the most market-competitive rates.
                            </p>
                            <h3>
                                Commendable Delivery Service:
                            </h3>
                            <p>
                                Enjoy the fastest delivery on your urgent orders! We guarantee that our delivery service is free of delays.

                                Get easy-to-install and low-maintenance home appliances in Dubai from Alisuq. We deliver high-end products that exceed in quality and durability. With appropriate use, there will be no damage, and the appliance will last you for years to come. Avail lasting functionality and reliable performance of top-notch brands, all under one roof!
                            </p>

                        </div>



                    </div>
                </div>

                <div className="w-100">
                    <WebsiteFooter />
                </div>
            </div>
        </Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
        countryUrl: state.language.countryUrl,
        countryId: state.language.countryId,
        language: state.language.language,
    };
};


export default connect(mapStateToProps, null)(CampingPage);


