import { type } from "../types/types";

export const set_categories = (categories) => (dispatch) => {
  dispatch({
    type: type.SET_CATEGORIES,
    categories: categories,
  });
};
export const set_search = (search) => (dispatch) => {
  dispatch({
    type: type.SET_SEARCH,
    search,
  });
};
