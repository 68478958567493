import React, { Fragment, useEffect, useState } from "react";
import "../../assets/styles/mobile/disputepagemob.scss";
import DisputeMessages from "../../components/mobile/DisputeMessages";
import MobileLayoutRenderer from "./layout/MobileLayoutRenderer";
import DisputeOrderDESC from "../../components/mobile/customer/DisputeOrderDESC";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { apiJson } from "../../Api";
import EmptyDisputes from "../../assets/images/dispute_art.png";
import { ClipLoader } from "react-spinners";
import { connect } from "react-redux";
import { set_loading } from "../../redux/actions/LayoutAction";

const DisputePageMob = (props) => {
  const { t } = useTranslation();
  const [data, setData] = useState(false);
  const [disputeData, setDisputesData] = useState([]);
  const [description, setDescription] = useState("");
  const [loading, setLoading] = useState(false);

  const { id } = useParams();
  const history = useHistory();
  let message = "";
  const showError = () => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  useEffect(() => {
    props.set_loading(true);
    apiJson.disputes.getDispute(id).then((response) => {
      props.set_loading(false);
      if (response.success) {
        setDisputesData(response.data);
        setData(true);
      } else if (!response.status) {
        // eslint-disable-next-line
        message = response.message
          ? response.message !== ""
            ? response.message
            : t("errors.wrong")
          : t("errors.wrong");
        showError();
      } else {
        if (response.status >= 400 && response.status < 500) {
          message = response.message
            ? response.message
            : response.errors
            ? response.errors
            : t("errors.wrong");
          showError();
        } else {
          message = response.message
            ? response.message !== ""
              ? response.message
              : t("errors.wrong")
            : t("errors.wrong");
          showError();
        }
      }
    });
  }, []);

  const createMessage = () => {
    setLoading(true);
    apiJson.disputes
      .createReply(id, {
        dispute_reply: {
          description: description,
        },
      })
      .then((response) => {
        setLoading(false);
        if (response.success) {
          setDisputesData({
            ...disputeData,
            replies: [...disputeData.replies, response.data],
          });
          setDescription("");
        } else if (!response.status) {
          message = response.message
            ? response.message !== ""
              ? response.message
              : t("errors.wrong")
            : t("errors.wrong");
          showError();
        } else {
          if (response.status >= 400 && response.status < 500) {
            message = response.message
              ? response.message
              : response.errors
              ? response.errors
              : t("errors.wrong");
            showError();
          } else {
            message = response.message
              ? response.message !== ""
                ? response.message
                : t("errors.wrong")
              : t("errors.wrong");
            showError();
          }
        }
      });
  };

  return (
    <MobileLayoutRenderer
      NavbarTop={true}
      BackBtn={true}
      CancelBtn={false}
      NavbarBottom={false}
      SearchIcon={true}
    >
      <Fragment>
        <div className="dispute-page-mobile-main-container px-3 pb-5">
          {!data ? (
            <></>
          ) : !disputeData ? (
            <div className="empty-disputes-page">
              <div className="img-cont">
                <img
                  src={EmptyDisputes}
                  className="img-fluid"
                  width="100%"
                  height="100%"
                  alt="emptyimage"
                />
              </div>
              <div className="cart-text dispute-empty-head-text">
                {t("dispute-detail-page-mob.empty.no-dispute-requested")}
              </div>
              <div className="cart-text dispute-empty-sub-text">
                {t("dispute-detail-page-mob.empty.submit-new-text")}
              </div>
              <br />
              <button
                onClick={() =>
                  history.push(
                    "/" +
                      props.countryUrl +
                      "-" +
                      props.language +
                      "/order/itemslist/disputes"
                  )
                }
                className="file-a-dispute-button"
              >
                {t("dispute-detail-page-mob.file-new-btn")}
              </button>
            </div>
          ) : (
            <>
              {" "}
              <div className="dispute-description-order-detail">
                <DisputeOrderDESC
                  disputeNum={disputeData.id}
                  StoreName={disputeData[`store_name_${props.language}`]}
                  CreatedAt={disputeData.created_at}
                  OrderNum={disputeData.order_id}
                  CustomerEmail={disputeData.customer_email}
                  Status={disputeData.status}
                  sku={disputeData.line_item.sku}
                />
              </div>
              <div className="dispute-messages">
                <div className="chat-row p-2">
                  <div className="header">{t("disputes-page-mob.replies")}</div>
                  <div className="messages">
                    {disputeData.replies.map((message) => {
                      return (
                        <DisputeMessages
                          key={message.id}
                          message={message}
                          dispute={disputeData}
                        />
                      );
                    })}
                  </div>
                  {disputeData.status.toLowerCase() !== "closed" && (
                    <form
                      action="
                    "
                      onSubmit={(e) => {
                        e.preventDefault();
                        createMessage();
                      }}
                    >
                      <div className="editor-row d-flex w-100 justify-content-between align-items-center">
                        <input
                          type="text"
                          name="message"
                          className="editor"
                          value={description}
                          onChange={(e) => setDescription(e.target.value)}
                          placeholder={t("disputes-page-mob.type-msg-ph")}
                        />
                        <button className="send-button" type="submit">
                          {loading ? (
                            <div className="d-flex justify-content-center align-items-center w-100">
                              <ClipLoader color="#fff" loading size={20} />
                            </div>
                          ) : (
                            t("disputes-page-mob.send")
                          )}
                        </button>
                      </div>
                    </form>
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      </Fragment>
    </MobileLayoutRenderer>
  );
};

const mapStateToProps = (state) => {
  return {
    countryUrl: state.language.countryUrl,
    language: state.language.language,
    load: state.layout.loading,
  };
};

const mapDispatchToProps = {
  set_loading,
};

export default connect(mapStateToProps, mapDispatchToProps)(DisputePageMob);
