import { type } from "../../types/types";

export default function reducer(state = { payment: [] }, action) {
  switch (action.type) {
    case type.SET_PAYMENTS:
      return { ...state, ...action.payment };
    default:
      return state;
  }
}
