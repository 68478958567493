import React, { useEffect, useState } from "react";
import "../../../assets/styles/component/mobile/Home/ProductCardMobile.scss";
import { Button, Row } from "reactstrap";

import { FaRegHeart, FaHeart } from "react-icons/fa";
import { apiJson } from "../../../Api";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { connect } from "react-redux";
import { BsFillStarFill } from "react-icons/bs";
import { Redirect } from "react-router-dom";

import Logo from "../../../assets/images/logo.png";
import { ClipLoader } from "react-spinners";
import { set_home_categories } from "../../../redux/actions/HomeAction";
import { useTranslation } from "react-i18next";
import { AiOutlineShoppingCart } from "react-icons/ai";

const en_translation = require("../../../assets/locales/en/translation.json");
const ar_translation = require("../../../assets/locales/ar/translation.json");

function ProductCardMobile(props) {
  const [wishlisted, setWishlisted] = useState(props.wishlisted);
  const [adding, setAdding] = useState(false);
  const { t } = useTranslation();
  const [redirect, setRedirect] = useState(false);
  const [cartRedirect, setCartRedirect] = useState(false);

  let message = "";
  const showError = () => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const wishlistToggle = () => {
    if (localStorage.getItem("access_token")) {
      setAdding(true);
      apiJson.cart
        .moveToWishlist(
          {
            wishlist_item: {
              orderable_type: props.type === "products" ? "Variant" : "Service",
              orderable_id: props.id,
            },
          },
          props.countryId
        )
        .then((response) => {
          setAdding(false);
          if (response.success) {
            setWishlisted(!wishlisted);
            props.home &&
              props.set_home_categories({
                positionOne: {
                  ...props.positionOne,
                  data: props.positionOne?.data?.map((data) => {
                    return data.variants
                      ? {
                        ...data,
                        variants: data.variants.map((variant) =>
                          variant.id === response.data.line_item.id
                            ? { ...variant, wishlisted: !variant.wishlisted }
                            : variant
                        ),
                      }
                      : {
                        ...data,
                        wishlisted:
                          data.id === response.data.line_item.id
                            ? !data.wishlisted
                            : data.wishlisted,
                      };
                  }),
                },
                positionTwo: {
                  ...props.positionTwo,
                  data: props.positionTwo?.data?.map((data) => {
                    return data.variants
                      ? {
                        ...data,
                        variants: data.variants.map((variant) =>
                          variant.id === response.data.line_item.id
                            ? { ...variant, wishlisted: !variant.wishlisted }
                            : variant
                        ),
                      }
                      : {
                        ...data,
                        wishlisted:
                          data.id === response.data.line_item.id
                            ? !data.wishlisted
                            : data.wishlisted,
                      };
                  }),
                },
                positionThree: {
                  ...props.positionThree,
                  data: props.positionThree?.data?.map((data) => {
                    return data.variants
                      ? {
                        ...data,
                        variants: data.variants.map((variant) =>
                          variant.id === response.data.line_item.id
                            ? { ...variant, wishlisted: !variant.wishlisted }
                            : variant
                        ),
                      }
                      : {
                        ...data,
                        wishlisted:
                          data.id === response.data.line_item.id
                            ? !data.wishlisted
                            : data.wishlisted,
                      };
                  }),
                },
                positionFour: {
                  ...props.positionFour,
                  data: props.positionFour?.data?.map((data) => {
                    return data.variants
                      ? {
                        ...data,
                        variants: data.variants.map((variant) =>
                          variant.id === response.data.line_item.id
                            ? { ...variant, wishlisted: !variant.wishlisted }
                            : variant
                        ),
                      }
                      : {
                        ...data,
                        wishlisted:
                          data.id === response.data.line_item.id
                            ? !data.wishlisted
                            : data.wishlisted,
                      };
                  }),
                },
                positionFive: {
                  ...props.positionFive,
                  data: props.positionFive?.data?.map((data) => {
                    return data.variants
                      ? {
                        ...data,
                        variants: data.variants.map((variant) =>
                          variant.id === response.data.line_item.id
                            ? { ...variant, wishlisted: !variant.wishlisted }
                            : variant
                        ),
                      }
                      : {
                        ...data,
                        wishlisted:
                          data.id === response.data.line_item.id
                            ? !data.wishlisted
                            : data.wishlisted,
                      };
                  }),
                },
                sections: props.sections?.map((section) => {
                  return {
                    ...section,
                    data: section.data.map((data) => {
                      return data.variants
                        ? {
                          ...data,
                          variants: data.variants.map((variant) =>
                            variant.id === response.data.line_item.id
                              ? {
                                ...variant,
                                wishlisted: !variant.wishlisted,
                              }
                              : variant
                          ),
                        }
                        : {
                          ...data,
                          wishlisted:
                            data.id === response.data.line_item.id
                              ? !data.wishlisted
                              : data.wishlisted,
                        };
                    }),
                  };
                }),
              });
          } else if (!response.status) {
            message = t("errors.wrong");
            showError();
          } else {
            if (response.status >= 400 && response.status < 500) {
              message = response.message
                ? response.message
                : response.errors
                  ? response.errors
                  : t("errors.wrong");
              showError();
            } else {
              message = response.message
                ? response.message !== ""
                  ? response.message
                  : t("errors.wrong")
                : t("errors.wrong");
              showError();
            }
          }
        });
    } else {
      message = "Please login first";
      showError();
      setRedirect(true);
    }
  };

  const addToCart = (e) => {
    e.preventDefault();
    addToCartApiMethod();
  }

  const addToCartApiMethod = () => {
    // setAdding(true);
    apiJson.cart
      .addToCart(
        props.product_type == "wholesale" ?
          {
            line_item: {
              quantity: 1,
              orderable_type: "Variant",
              orderable_id: props.data.id,
              product_type: "wholesale"
            }
          }
          :
          props.type === "products"
            ? {
              line_item: {
                quantity: 1,
                orderable_type: "Variant",
                orderable_id: props.data.id,
              },
            }
            : {
              line_item: {
                orderable_type: "Service",
                orderable_id: props.data.id,
                // start_at: new Date(startDate).toString(),
                // end_at: new Date(endDate).toString(),
              },
            },
        props.countryId
      )
      .then((response) => {
        setAdding(false);
        const timer = setTimeout(() => {
          setCartRedirect(false);
        }, 1000);
        clearTimeout(timer);
        if (response.success) {
          if (
            localStorage.getItem("access_token") ||
            localStorage.getItem("user_key") === response.data.user_key
          ) {
            setCartRedirect(true);
          } else {
            localStorage.setItem("user_key", response.data.user_key);
            setCartRedirect(true);
          }
        } else if (!response.status) {
          message = t("errors.wrong");
          showError();
        } else {
          if (response.status >= 400 && response.status < 500) {
            message = response.message;
            showError();
          } else {
            message = t("errors.wrong");
            showError();
          }
        }
      });
  };

  return (
    <div
      className={
        `product-card-mob mb-2 bg-white ${props.className ? props.className : ""
        }`
        +
        (props.language == "ar" ? " text-right" : "")
      }
    >
      <div className="row m-0 fltright">
        {
          props.is_inquiry ?
            <></>
            :
            props.data.price != props.data.discounted_price ?
              <div className={props.language == "en" ? "discount-box" : "discount-box-ar"}>
                <p
                  style={{ color: "white", fontSize: "12px" }}
                  className="m-0"
                >
                  &nbsp;
                  {Math.round(
                    ((props.data.price - props.data.discounted_price) /
                      props.data.price) *
                    100
                  )}
                  % OFF
                </p>
              </div>
              :
              <></>
        }
        <div
          onClick={(e) => {
            e.preventDefault();
            wishlistToggle();
          }}
          className={props.language == "en" ? "like-img" : "like-img-ar"}
        >
          {adding ? (
            <div
              style={{
                borderRadius: "50%",
                textAlign: "center",
                paddingTop: "6px",
              }}
              className="d-flex justify-content-center align-items-center w-100"
            >
              <ClipLoader color="#000" loading size={15} />
            </div>
          ) : wishlisted ? (
            <div
              style={{
                borderRadius: "50%",
                textAlign: "center",
                paddingTop: "6px",
              }}
            >
              <div
                style={{
                  background: "#F5F5F5",
                  borderRadius: "50%",
                  textAlign: "center",
                }}
              >
                <FaHeart size="14px" color="#F64951" />
              </div>
            </div>
          ) : (
            <div
              style={{
                borderRadius: "50%",
                textAlign: "center",
                paddingTop: "6px",
              }}
            >
              <div
                style={{
                  background: "#fff",
                  borderRadius: "100px",
                  textAlign: "center",
                  position: 'absolute',
                  boxShadow: '0px 0px 5px 0px #00000078',
                  width: '25px',
                  height: '25px',

                }}
              >
                <FaRegHeart size="14px" color="grey" />
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="img">
        <img
          src={props.img}
          style={{ width: "auto", maxWidth: "100px", height: "130px" }}
          alt=""
        />
      </div>

      {
        props.is_inquiry ?
          <></>
          :
          <div className="coupon-row">
            {
              props.coupon ?
                new Date(props.coupon.expiry) >= new Date() ?
                  <div className="coupon-box">
                    <p className="px-2 my-0">Coupon: {props.coupon.code}</p>
                  </div>
                  :
                  <></>
                :
                <></>
            }
          </div>
      }

      <div className="description">
        <p className="mb-0">
          {props.name?.slice(0, 25)}
          {props.name?.length > 26 && "..."}
        </p>
        <p className="mt-0">
          {props.data.name_en?.slice(0, 15)}
          {props.data.name_en.length > 16 && "..."}
        </p>
      </div>

      {
        props.is_inquiry ?
          <></>
          :
          props.product_type == "wholesale" ?
            <div>
              <div className={"disc-price"}>
                <span className={"currency"}>
                  {props.country.toLowerCase() === "saudi arabia"
                    ? "SAR"
                    : props.country.toLowerCase() === "united arab emirates"
                      ? "AED"
                      : "EGP"}{" "}
                </span>
                <span className="font-weight-bold">{props.data.start_price}</span>
                <span className="font-weight-bold"> - </span>
                <span>
                  {props.country.toLowerCase() === "saudi arabia"
                    ? "SAR"
                    : props.country.toLowerCase() === "united arab emirates"
                      ? "AED"
                      : "EGP"}{" "}
                </span>
                <span className="priceamount">{props.data.end_price}</span>
              </div>
            </div>
            :
            <>
              <div className={`${props.data.discounted_price < props.data.price && ("disc-price-text-color")
                } disc-price`}>
                <span className={"currency"}>
                  {props.country.toLowerCase() === "saudi arabia"
                    ? "SAR"
                    : props.country.toLowerCase() === "united arab emirates"
                      ? "AED"
                      : "EGP"}{" "}
                </span>
                <span className="priceamount">{props.data.discounted_price}{props.type == "services" && (" / Hour")}</span>
              </div>
              <div
                style={{ fontSize: "9px" }}
                className={`${props.data.discounted_price < props.data.price ? "price" : "invisible"
                  }`}
              >
                <div>
                  <span style={{ textDecoration: "line-through" }}>
                    {props.country.toLowerCase() === "saudi arabia"
                      ? "SAR"
                      : props.country.toLowerCase() === "united arab emirates"
                        ? "AED"
                        : "EGP"}{" "}
                  </span>
                  <span style={{ textDecoration: "line-through" }}>
                    {props.data.price}{" "}{props.type == "services" && (" / Hour")}
                  </span>
                </div>
              </div>
            </>
      }

      <div className="d-flex flex-direction-row align-items-center mt-2">
        <div className="logo" style={{ marginRight: "5px" }}>
          <img
            src={props.storeLogo ? props.storeLogo : Logo}
            style={{
              width: "auto",
              height: "20px",
              maxWidth: "65%",
              maxHeight: "20px",
            }}
            alt=""
          />
        </div>
        <BsFillStarFill
          size="12"
          color="rgb(245, 165, 35)"
          style={{ padding: "0px", margin: "0px" }}
        />
        <div className="rating">
          <span className="number">
            {props.type === "products"
              ? props.rating
                ? props.rating
                : 0
              : props.data.average_rating
                ? props.data.average_rating
                : 0}
          </span>
          <span className="count">{`(${props.data.reviews_count ? props.data.reviews_count : props.reviews
            })`}</span>
        </div>
      </div>

      {/* {props?.data} */}
      {
        props.type === "products" ?
          props?.product_type == "wholesale" ?
            <></>
            :
            props?.is_inquiry ?
              <a
                onClick={(e) => {
                  e.preventDefault();
                  let url = encodeURI(`//api.whatsapp.com/send?phone=971543064845&text=Hi, I would like to inquire about the product. I am interested in : ${props?.name}. Check here: ${process.env.REACT_APP_WEBSITE_URL + "/" + props.countryUrl + "-" + props.language + "/" + props?.category_en?.toLowerCase().replace(/ /g, "-") + "/" + props?.slug}?sku=${props.data?.sku}`);
                  window.open(url, '_blank', 'noreferrer');
                }}
                className="inquire-now-btn"
              >{t("item-detail.get-inquiry")}</a>
              :
              props.data?.quantity > 0 ?
                <Button
                  className="quick-view-btn"
                  onClick={addToCart}
                >
                  <AiOutlineShoppingCart size={20} />&nbsp;
                  {
                    props.language == "en" ?
                      en_translation["item-detail"]["shortcut-add-to-cart"]
                      :
                      ar_translation["item-detail"]["shortcut-add-to-cart"]
                  }
                </Button>
                :
                <Button
                  className="out-of-stock-btn"
                >{t("item-detail.out-of-stock")}</Button>
          :
          <></>
      }



      {redirect && <Redirect push to="/login" />}

      {cartRedirect &&
        <Redirect
          push
          to={
            "/" + props.countryUrl + "-" + props.language + "/cart"
          } />}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    country: state.language.country,
    countryUrl: state.language.countryUrl,
    width: state.layout.screenWidth,
    countryId: state.language.countryId,
    sections: state.home.sections,
    language: state.language.language,
    positionOne: state.home.positionOne,
    positionTwo: state.home.positionTwo,
    positionThree: state.home.positionThree,
    positionFour: state.home.positionFour,
    positionFive: state.home.positionFive,
  };
};

const mapDispatchToProps = { set_home_categories };

export default connect(mapStateToProps, mapDispatchToProps)(ProductCardMobile);
