import React, { Fragment } from "react";
import "../../../assets/styles/customer/cart/wishlist/wishlist.scss";
import WishListCard from "./WishListCard";
import { connect } from "react-redux";

const Wishlist = (props) => {
  const { List } = props;
  return (
    <Fragment>
      <div className="">
        {List.map((item) => (
          <WishListCard
            currency={props.currency}
            key={item.id}
            name={item.line_item[`name_${props.language}`]}
            product={item}
            wishlist={List}
          />
        ))}
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    language: state.language.language,
  };
};

export default connect(mapStateToProps, null)(Wishlist);
