import React, { useState } from "react";
import { RiSearchLine } from "react-icons/ri";
import { set_loading } from "../../../redux/actions/LayoutAction";
import logo from "../../../assets/images/logo.png";
import { connect } from "react-redux";
import { set_vendor_data } from "../../../redux/actions/VendorShopAction";
import { toast } from "react-toastify";
import { Redirect, useParams } from "react-router-dom";
import MobileLayoutRenderer from "../../../pages/mobile/layout/MobileLayoutRenderer";
import { useTranslation } from "react-i18next";
import { set_country } from "../../../redux/actions/LayoutAction";
import { set_cart_items } from "../../../redux/actions/CartAction";
import { set_redirect_home } from "../../../redux/actions/NotFoundAction";
import "../../../assets/styles/component/mobile/Home/HomeMobile.scss";
import "react-toastify/dist/ReactToastify.css";
import pagesbannerimg from "../../../assets/images/footer-pages-banners/Payment-OPtions.jpg";

function PaymentOptionsComp(props) {
  // eslint-disable-next-line
  const [modalShow, setModalShow] = React.useState(true);
  let message = ""; // eslint-disable-next-line
  const { id } = useParams(); // eslint-disable-next-line
  const { t, i18n } = useTranslation();
  const [redirect, setRedirect] = useState({ value: false });
  // eslint-disable-next-line
  const showError = () => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  // eslint-disable-next-line
  function getMobileOperatingSystem() {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
      return "Windows Phone";
    }

    if (/android/i.test(userAgent)) {
      return "Android";
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return "iOS";
    }

    return "unknown";
  }

  return (
    <MobileLayoutRenderer
      NavbarTop={false}
      BackBtn={false}
      CancelBtn={false}
      NavbarBottom={true}
    >
      <div className="home-mob">
        <div className="header">
          <div className="logo">
            <img src={logo} width="100%" height="100%" alt="" />
          </div>
          <div className="search">
            <RiSearchLine
              onClick={() =>
                setRedirect({ value: true, path: "all-categories" })
              }
            />
          </div>
        </div>

        <div className="body" style={{ background: "#F5F5F5" }}>
          <div className="pagesbanner">
            <img
              src={pagesbannerimg}
              alt="category"
              style={{
                width: "100%",
                height: "100%",
                maxHeight: "400px",
              }}
            ></img>
          </div>
          <div className="content-wrapper all-page-container">
            <div
              className="footer-pages-content"
              style={{
                textAlign: "left",
              }}
            >
              <b>Are online payments secure?</b>

              <p>
                Your security is very important to us. We work closely with our
                delivery team to ensure Cash On Delivery orders are safe. You
                will receive an invoice by email upon delivery to confirm your
                payment. If paying by debit or credit card, Alisuq provides safe
                and secure checkout by encrypting your credit card information
                using SSL.
              </p>
              <b>Payment Methods:</b>
              <ul>
                <li>Cash on Delivery</li>
                <li>Credit Cards</li>
                <li>Debit Cards</li>
                <li>Alisuq Wallet</li>
                <li>Gift Cards, eGift Cards</li>
              </ul>
              <b>1. Cash on Delivery</b>
              <p>
                Alisuq provides a COD option. Buy online and pay when the order
                is delivered to you. The maximum order value for COD is AED
                1,000. Only cash payments will be accepted. Any other payment
                methods like personal cheque or credit card will not be
                accepted.
              </p>
              <b>2. Credit & Debit Cards</b>
              <p>
                We currently accept Visa and Master Card credit and debit cards.
                You can store multiple credit cards to use for your online
                purchase on Alisuq.com. During this step in the checkout
                process, you can add, edit, or delete your information. Select
                one credit or debit card for the payment of your order. When
                your credit or debit card is authorized, funds are put on hold
                until the transaction is completed. When the order is shipped,
                the payment is processed. Your billing information must be
                exactly as it appears on your financial statement. If any
                numbers are different, the order cannot be processed. Be sure to
                correctly spell city names and avoid using abbreviations unless
                that is how your information appears on your statement. Contact
                your financial institution if you need the exact billing address
                associated with your credit or debit card. For your security,
                Alisuq may require you to enter your 3- or 4-digit card
                identification number (CVV) on certain orders for Visa,
                MasterCards
              </p>

              <b>3. Alisuq Wallet</b>
              <p>
                Online credits are automatically applied to your Alisuq.com
                order as the first method of payment. The remaining balance can
                be paid by credit card, Gift Cards or eGift Cards (only
                available on www.alisuq.com). There are no codes to remember or
                numbers to type. Alisuq wallet is not redeemable or exchangeable
                for cash. To view your online credit, visit My Account on your
                Alisuq.com account.
              </p>
              <b>4. Gift Cards, eGift Cards</b>
              <p>
                Gift Cards are physical cards with a 16-digit card number and an
                8-digit PIN. eGift Cards are virtual cards with a 16-digit card
                number and an 8-digit PIN. Gift Cards and eGift Cards can be
                used online, on our mobile app. Any unused balance will remain
                on the card and can be used toward a future purchase. If using a
                gift card to pay for your order, please retain the gift card
                until the order has been received. If the order total changes
                due to cancellations, the balance will be reinstated on the gift
                card. To check your gift card or egift card balance visit
                .................. on your Alisuq.com account
              </p>
            </div>
          </div>
        </div>
        {redirect.value && (
          <Redirect
            push
            to={{ pathname: redirect.path, state: redirect.state }}
          />
        )}
      </div>
    </MobileLayoutRenderer>
  );
}

const mapStateToProps = (state) => {
  return {
    // categories: state.home.categories,
    // products: state.vendor.products,
    // services: state.vendor.services,
    // sections: state.home.sections,
    // positionOne: state.home.positionOne,
    // positionTwo: state.home.positionTwo,
    // positionThree: state.home.positionThree,
    // positionFour: state.home.positionFour,
    // positionFive: state.home.positionFive,
    load: state.layout.loading,
    countryId: state.language.countryId,
    language: state.language.language,
    country: state.language.country,
    countryUrl: state.language.countryUrl,
    redirect: state.notFound.redirect,
  };
};

const mapDispatchToProps = {
  set_vendor_data,
  // set_home_categories,
  set_country,
  set_cart_items,
  set_redirect_home,
  set_loading,
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentOptionsComp);
