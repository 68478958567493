import React, { useEffect } from "react";

import ItemData from "./ItemData";
import FrequentBought from "./frequentBought";
import ItemStatus from "./ItemStatus";
import ItemOverview from "./ItemOverview";
import { connect } from "react-redux";
import ImageGallery from "./ImageGallery";
import { set_single_item } from "../../../redux/actions/SingleItemAction";
import "react-toastify/dist/ReactToastify.css";


function ItemsDetailMain(props) {
  return (
    <div className={props.className}>
 
      {props.item.img && (
        <>
          <div className="row w-100 pt-4 pb-5 bg-white no-gutters">
            <div
              className={
                props.width !== "md"
                  ? props.width === "sm"
                    ? "w-100"
                    : "col-9"
                  : "w-100 mr-2"
              }
            >

              <ItemData
                onChangeStart={(val) => props.onChangeStart(val)}
                onChangeEnd={(val) => props.onChangeEnd(val)}
                startDate={props.startDate}
                endDate={props.endDate}
                data={props.item}
                current_variant_key={props.current_variant_key}
              />
              {
                props.item.order_quantity > 0 ?
                  <FrequentBought sku={props.item.sku} product={props.item} />
                  :
                  <></>
              }
            </div>
            <div
              className={
                props.width !== "md"
                  ? props.width === "sm"
                    ? "w-100"
                    : "col-3"
                  : "w-35 mt-3 ml-auto mr-auto"
              }
            >
              <ItemStatus data={props.item} />
            </div>
          </div>

          <div className="bg-white">
            <div className="w-95 m-auto ">
              <ItemOverview data={props.item} />
            </div>
          </div>

          <ImageGallery images={props.item?.img} video={props.item?.video} />
        </>
      )}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    width: state.layout.screenWidth,
    item: state.item.item,
    countryId: state.language.countryId,
  };
};

const mapDispatchToProps = {
  set_single_item,
};

export default connect(mapStateToProps, mapDispatchToProps)(ItemsDetailMain);
