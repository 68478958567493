import React, { useState } from "react";
import { RiSearchLine } from "react-icons/ri";
import { set_loading } from "../../../redux/actions/LayoutAction";
import logo from "../../../assets/images/logo.png";
import { connect } from "react-redux";
import { set_vendor_data } from "../../../redux/actions/VendorShopAction";
import { toast } from "react-toastify";
import { Redirect, useParams } from "react-router-dom";
import MobileLayoutRenderer from "../../../pages/mobile/layout/MobileLayoutRenderer";
import { useTranslation } from "react-i18next";
import { set_country } from "../../../redux/actions/LayoutAction";
import { set_cart_items } from "../../../redux/actions/CartAction";
import { set_redirect_home } from "../../../redux/actions/NotFoundAction";
import "../../../assets/styles/component/mobile/Home/HomeMobile.scss";
import "react-toastify/dist/ReactToastify.css";
import pagesbannerimg from "../../../assets/images/footer-pages-banners/alisuq_story.jpg";
import alisuqstorybanner from "../../../assets/images/footer-page-images/alisuqstory.jpg";
import bulkorderimg from "../../../assets/images/footer-page-images/a.png";

function AlisuqStoryComp(props) {
  // eslint-disable-next-line
  const [modalShow, setModalShow] = React.useState(true);
  let message = "";
  // eslint-disable-next-line
  const { id } = useParams();
  // eslint-disable-next-line
  const { t, i18n } = useTranslation();
  const [redirect, setRedirect] = useState({ value: false });
  // eslint-disable-next-line
  const showError = () => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  // eslint-disable-next-line
  function getMobileOperatingSystem() {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
      return "Windows Phone";
    }

    if (/android/i.test(userAgent)) {
      return "Android";
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return "iOS";
    }

    return "unknown";
  }

  return (
    <MobileLayoutRenderer
      NavbarTop={false}
      BackBtn={false}
      CancelBtn={false}
      NavbarBottom={true}
      SearchIcon={true}
    >
      <div className="home-mob">
        <div className="header">
          <div className="logo">
            <img src={logo} width="100%" height="100%" alt="" />
          </div>
          <div className="search">
            <RiSearchLine
              onClick={() =>
                setRedirect({ value: true, path: "all-categories" })
              }
            />
          </div>
        </div>

        <div className="body" style={{ background: "#F5F5F5" }}>
          <div className="pagesbanner">
            <img
              src={pagesbannerimg}
              alt="category"
              style={{
                width: "100%",
                height: "100%",
                maxHeight: "400px",
              }}
            ></img>
          </div>
          <div className="content-wrapper all-page-container">
            <div className="footer-pages-content">
              <p>
                Alisuq is an online marketplace for materials, do-it-yourself
                products and tools. As an one-stop destination for consumers and
                corporate clients, Alisuq offers an extensive and fast-growing
                array of products and tools of highest quality from leading
                brands around the world.
              </p>
              <p>
                Alisuq is owned and operated by Alrais Enterprises Group, a
                diverse conglomerate based in Dubai, United Arab Emirates with
                business presence that extends through the Middle East, Europe
                and Southeast Asia. As a hallmark of its holding entity, Alisuq
                is synonymous to excellence and efficiency in delivering
                customer-centric and secure online shopping experience.
              </p>
              <p>
                Our intuitive interface, that brings all relevant product
                information in one look, enables our clients to shop with ease
                and convenience while having the peace of mind that all
                transactions and financial information are fully secure and
                protected. Alisuq offers the most competitive pricing across all
                our products and categories.
              </p>
              <p>
                With it's parent company at the helm, Alisuq draws its strength
                on the stability and integrity of its leadership and experience
                for 25 years and counting. Our efforts and dedication offers you
                the confidence that you can always find the right product and
                tool to do things yourself, at a winning price, winning customer
                service and winning value.
              </p>
              <p>
                We'd love to hear from you through:{" "}
                <strong>customerservice@alisuq.com</strong>.
              </p>
              <div className="pagesbanner">
                <img
                  src={alisuqstorybanner}
                  alt="alisuq-story"
                  style={{
                    width: "100%",
                    height: "100%",
                    opacity: "0.5",
                    maxHeight: "400px",
                  }}
                ></img>
              </div>
              <div className="emptyspace"></div>
              <h4 className="subheadings">Vision</h4>

              <p>
                Empowering, enabling, and providing choice to people everywhere,
                everyday.
              </p>
              <div className="emptyspace"></div>
              <h4 className="subheadings">Mission</h4>

              <p>
                To create a world where our consumers have access to a finely
                curated, authentic assortment of products and services that
                delight and elevate the human spirit
              </p>
              <div className="emptyspace"></div>
              <h4 className="subheadings">Values</h4>
              <div className="bulkcat">
                <img
                  className="bulkcatimg"
                  src={bulkorderimg}
                  alt="category"
                ></img>
                <img
                  className="bulkcatimg"
                  src={bulkorderimg}
                  alt="category"
                ></img>
                <img
                  className="bulkcatimg"
                  src={bulkorderimg}
                  alt="category"
                ></img>
              </div>
              <div className="bulkcat">
                <img
                  className="bulkcatimg"
                  src={bulkorderimg}
                  alt="category"
                ></img>
                <img
                  className="bulkcatimg"
                  src={bulkorderimg}
                  alt="category"
                ></img>
                <img
                  className="bulkcatimg"
                  src={bulkorderimg}
                  alt="category"
                ></img>
              </div>
            </div>
          </div>
        </div>
        {redirect.value && (
          <Redirect
            push
            to={{ pathname: redirect.path, state: redirect.state }}
          />
        )}
      </div>
    </MobileLayoutRenderer>
  );
}

const mapStateToProps = (state) => {
  return {
    // categories: state.home.categories,
    // products: state.vendor.products,
    // services: state.vendor.services,
    // sections: state.home.sections,
    // positionOne: state.home.positionOne,
    // positionTwo: state.home.positionTwo,
    // positionThree: state.home.positionThree,
    // positionFour: state.home.positionFour,
    // positionFive: state.home.positionFive,
    load: state.layout.loading,
    countryId: state.language.countryId,
    language: state.language.language,
    country: state.language.country,
    countryUrl: state.language.countryUrl,
    redirect: state.notFound.redirect,
  };
};

const mapDispatchToProps = {
  set_vendor_data,
  // set_home_categories,
  set_country,
  set_cart_items,
  set_redirect_home,
  set_loading,
};

export default connect(mapStateToProps, mapDispatchToProps)(AlisuqStoryComp);
