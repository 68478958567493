import React, { useState, useEffect } from "react";
import AllCategoriesRightPanel from "./AllCategoriesRightPanel";
import "../../../assets/styles/mobile/allcategories.scss";
import AllCategoriesSidePanel from "./AllCategoriesSidePanel";
import MobileSearch from "./MobileSearch";
import { useTranslation } from "react-i18next";
import { apiJson } from "../../../Api";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { set_loading } from "../../../redux/actions/LayoutAction";
import { connect } from "react-redux";
import { set_all_categories } from "../../../redux/actions/AllCategoriesAction";
import { useHistory, useLocation } from "react-router";

function AllCategories(props) {
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const [data, setData] = useState(false);
  const [subCategory, setSubCategory] = useState([]);
  let message = "";
  const showError = () => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const [selectedOption, setSelectedOption] = useState();

  useEffect(() => {
    if (location.search.length > 0) {
      const tab = location.search.includes("tab=")
        ? new URLSearchParams(location.search).get("tab") === "p"
          ? "products"
          : "services"
        : "products";

      let params = new URLSearchParams(location.search);
      params.delete("tab");
      params = params.toString();

      params?.length > 0 &&
        history.push({
          pathname:
            "/" + props.countryUrl + "-" + props.language + "/items/" + tab,
          search: params,
        });
    }
    props.set_loading(true);
    apiJson.categories.allCategories(props.countryId).then((response) => {
      setData(true);
      props.set_loading(false);
      if (response.success) {
        props.set_all_categories({
          allCategories: response.data.map((ele, id) => {
            return { ...ele, active: id === 0 };
          }),
        });
        setSelectedOption(response.data[0].title_en);
        setSubCategory(response.data[0].sub_categories);
      } else if (!response.status) {
        message = response.message // eslint-disable-line
          ? response.message !== ""
            ? response.message
            : t("errors.wrong")
          : t("errors.wrong");
        showError();
      } else {
        if (response.status >= 400 && response.status < 500) {
          message = response.message
            ? response.message
            : response.errors
            ? response.errors
            : t("errors.wrong");
          showError();
        } else {
          message = response.message
            ? response.message !== ""
              ? response.message
              : t("errors.wrong")
            : t("errors.wrong");
          showError();
        }
      }
    });
    apiJson.categories.getBanners(props.countryId).then((response) => {
      if (response.success) {
        props.set_all_categories({
          banners: response.data,
        });
      } else if (!response.status) {
        message = response.message
          ? response.message !== ""
            ? response.message
            : t("errors.wrong")
          : t("errors.wrong");
        showError();
      } else {
        if (response.status >= 400 && response.status < 500) {
          message = response.message
            ? response.message
            : response.errors
            ? response.errors
            : t("errors.wrong");
          showError();
        } else {
          message = response.message
            ? response.message !== ""
              ? response.message
              : t("errors.wrong")
            : t("errors.wrong");
          showError();
        }
      }
    });
  }, []);

  const changeCategory = (id, name, sub_categories) => {
    props.set_all_categories({
      allCategories: props.categories.map((ele) =>
        ele.id === id ? { ...ele, active: true } : { ...ele, active: false }
      ),
    });
    setSelectedOption(name);
    setSubCategory(sub_categories);
  };

  return (
    <div
      style={{ maxWidth: "767px" }}
      className="all-categories-main-container"
    >
      <div className="mobile-search-div">
        <MobileSearch
          value={(val) => {}}
          placeholder={t("all-categories.search-ph")}
        />
      </div>
      {data && (
        <div className="bootom-main">
          <div className="all-categories-left-div">
            <AllCategoriesSidePanel
              changeCategory={(id, name, sub_categories) =>
                changeCategory(id, name, sub_categories)
              }
              data={props.categories}
            />
          </div>
          <div className="all-categories-right-div">
            {props.categories.length > 0 && (
              <AllCategoriesRightPanel
                name={selectedOption}
                subCategory={subCategory}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    categories: state.categories.allCategories,
    language: state.language.language,
    countryUrl: state.language.countryUrl,

    countryId: state.language.countryId,
  };
};

const mapDispatchToProps = { set_all_categories, set_loading };

export default connect(mapStateToProps, mapDispatchToProps)(AllCategories);
