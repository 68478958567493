import React, { useState, useEffect } from "react";
import "../../assets/styles/mobile/walletpagemob.scss";
import MobileLayoutRenderer from "./layout/MobileLayoutRenderer";
import WalletCardMob from "../../components/mobile/WalletCardMob";
import { CREDITS } from "../../Extra/data/customer/account/wallet";
import { apiJson } from "../../Api";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import HeaderMobile from "../../components/mobile/HeaderMobile";

const WalletPageMob = (props) => {
  const [balance, setBalance] = useState();
  const [currency, setCurrency] = useState("");
  const { t } = useTranslation();

  let message = "";

  const showError = () => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  useEffect(() => {
    document.title = "Wallet";
    apiJson.checkout.walletPayment(props.countryId).then((response) => {
      if (response.success) {
        setBalance(response.data.amount);
        setCurrency(response.data.currency);
      } else if (!response.status) {
        // eslint-disable-next-line
        message = response.message
          ? response.message !== ""
            ? response.message
            : t("errors.wrong")
          : t("errors.wrong");
        showError();
      } else {
        if (response.status >= 400 && response.status < 500) {
          message = response.message
            ? response.message
            : response.errors
              ? response.errors
              : t("errors.wrong");
          showError();
        } else {
          message = response.message
            ? response.message !== ""
              ? response.message
              : t("errors.wrong")
            : t("errors.wrong");
          showError();
        }
      }
    });
  }, []);
  return (
    <MobileLayoutRenderer
      NavbarTop={false}
      BackBtn={true}
      CancelBtn={false}
      NavbarBottom={true}
      SearchIcon={true}
    >
      <div className="home-mob">
        <HeaderMobile />
      </div>
      <div className="wallet-page-mob-main-container">
        <div className="balance mt-3">
          <span>
            {currency.toUpperCase()} {balance}
          </span>
        </div>
        <div className="wallet-card-lsit-inpage px-3">
          <WalletCardMob List={CREDITS} />
        </div>
      </div>
    </MobileLayoutRenderer>
  );
};

const mapStateToProps = (state) => {
  return { countryId: state.language.countryId };
};

export default connect(mapStateToProps, null)(WalletPageMob);
