import React, { Fragment, useEffect } from "react";
import { Row } from "react-bootstrap";

import "../../../assets/styles/pages/checkout/checkoutpage.scss";
import "../../../assets/styles/customer/account/general.scss";
import Header from "../../../components/customer/shippingheader/Header";
import Checkout from "../../../components/customer/checkout/Checkout";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";

const CheckoutPage = (props) => {
  useEffect(() => {
    document.title = "Checkout";
  }, []);
  return (
    <Fragment>
      {/* {!props.order?.id ? (
        <Redirect
          push
          to={"/" + props.countryUrl + "-" + props.language + "/cart"}
        />
      ) : ( */}
        <Row className="page-container no-gutters checkout-page-desktop">
          <div className="w-100">
            <Header screen_name="PAYMENT" />

            <div className="content-container d-flex justify-content-center mx-auto">
              <Checkout
                id={props.order?.id}
                address={props.order?.address}
                createdAddress={props.order?.createdAddress}
                currency={
                  props.country.toLowerCase() === "saudi arabia"
                    ? "SAR"
                    : props.country.toLowerCase() === "united arab emirates"
                    ? "AED"
                    : "EGP"
                }
              />
            </div>
          </div>
        </Row>
      {/* )} */}
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    country: state.language.country,
    language: state.language.language,
    countryUrl: state.language.countryUrl,
    order: state.cart.order,
  };
};

export default connect(mapStateToProps, null)(CheckoutPage);
