import React, { Fragment } from "react";
import { useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import ProductCard from "../../components/mobile/Home/ProductCardMobile";

import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination, Navigation } from "swiper/core";

import "swiper/swiper.scss";
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css";
import "swiper/components/navigation/navigation.min.css";

import "../../assets/styles/product/productlist.scss";

import { getDesktopImage } from "../../utils/utils.js";

const screenBreakpoints = {
  md: 768,
  lg: 992,
  xl: 1024,
  xxl: 1200,
  xxxl: 1440,
};
SwiperCore.use([Pagination, Navigation]);

const TabProductListMob = (props) => {
  const [width, setWidth] = useState(window.innerWidth);

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  window.addEventListener("resize", handleWindowSizeChange);

  const productCountPerSlideView = () => {
    if (width >= screenBreakpoints.xxxl) {
      return 5;
    } else if (width >= screenBreakpoints.xxl) {
      return 5;
    } else if (width >= screenBreakpoints.xl) {
      return 4;
    } else if (width >= screenBreakpoints.lg) {
      return 3;
    } else if (width >= screenBreakpoints.md) {
      return 3;
    } else {
      return 5;
    }
  };

  return (
    <Fragment>
      {/* <div className="product-list-desktop">
        <div className="product-list-container mx-auto"> */}
          
            {props.products.map((product) =>
              product.product.variants ? (
                <Link
                  style={{ textDecoration: "none" }}
                  to={{
                    pathname:
                      "/" +
                      props.countryUrl +
                      "-" +
                      props.language +
                      `/${product.product.category_title_en?.toLowerCase()
                        .replace(/ /g, "-")}/${product.product.slug}`,
                    state: {
                      id: product.product.id,
                      type: product.product.variants ? "products" : "services",
                    },
                    search: product.product.variants ? "?sku=" + product.product.variants[0].sku : "?sku=" + product.product.sku
                  }}
                  key={product.product.id + Math.random()}
                >
                  <div
                    className="image-cont"
                    key={product.product.id + Math.random() + "prod"}
                  >
                    <ProductCard
                      key={product.product.id + Math.random()}
                      img={
                        product.product.variants
                          ? product.product.variants[0].featured_image
                          : product.product.featured_image
                      }
                      storeLogo={product.product.store_logo}
                      wishlisted={
                        product.product.variants
                          ? product.product.variants[0].wishlisted
                          : product.product.wishlisted
                      }
                      home={true}
                      type={product.product.variants ? "products" : "services"}
                      id={product.product.variants ? product.product.variants[0].id : product.product.id}
                      rating={product.product.average_rating}
                      reviews={product.product.reviews_count}
                      name={product.product[`name_${props.language}`]}
                      data={product.product.variants ? product.product.variants[0] : product.product}
                      coupon={product.product.coupon}
                      is_inquiry={product.product.is_inquiry}
                      product_type={product.product.product_type}
                      category_en={product.product.category_title_en}
                      slug={product.product.slug}
                    />
                  </div>
                </Link>
              ) : (
                <Link
                  style={{ textDecoration: "none" }}
                  to={{
                    pathname:
                      "/" +
                      props.countryUrl +
                      "-" +
                      props.language +
                      `/${product.product.category_title_en
                        .toLowerCase()
                        .replace(/ /g, "-")}/${product.product.slug}`,
                    state: {
                      id: product.product.id,
                      type: product.product.variants ? "products" : "services",
                    },
                    search: product.product.variants ? "?sku=" + product.product.variants[0].sku : "?sku=" + product.product.sku
                  }}
                  key={product.product.id + Math.random()}
                >
                  <div
                    className="image-cont"
                    key={product.product.id + Math.random() + "prod"}
                  >
                    <ProductCard
                      key={product.product.id + Math.random()}
                      img={
                        product.product.variants
                          ? product.product.variants[0].featured_image
                          : product.product.featured_image
                      }
                      storeLogo={product.product.store_logo}
                      wishlisted={
                        product.product.variants
                          ? product.product.variants[0].wishlisted
                          : product.product.wishlisted
                      }
                      home={true}
                      type={product.product.variants ? "products" : "services"}
                      id={product.product.variants ? product.product.variants[0].id : product.product.id}
                      rating={product.product.average_rating}
                      reviews={product.product.reviews_count}
                      name={product.product[`name_${props.language}`]}
                      data={product.product.variants ? product.product.variants[0] : product.product}
                      is_inquiry={product.product.is_inquiry}
                      product_type={product.product.product_type}
                    />
                  </div>
                </Link>
              )
            )}
            
        {/* </div>
      </div> */}
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    language: state.language.language,
    countryUrl: state.language.countryUrl
  };
};

export default connect(mapStateToProps, null)(TabProductListMob);
