import React, { Fragment } from "react";

import "../../../assets/styles/customer/shippingfooter/shippingfooter.scss";
import { useTranslation } from "react-i18next";

const ShippingFooter = () => {
  const { t } = useTranslation();
  return (
    <Fragment>
      <div className="shipping-footer-desktop mx-auto">
        <div className="shipping-footer mx-auto">
          <div className="security-text d-flex justify-content-center w-100">
            <div className="label">{t("address-form.secure-co")}</div>
          </div>
          <div className="footer d-flex justify-content-between w-100">
            <div className="copy-right">©2021Alisuq. All Rights Reserved.</div>
            <div className="statement d-flex">
              <div className="term-of-use">{t("my-account-logged-in.tou")}</div>
              <div className="term-of-sale">
                {t("my-account-logged-in.tos")}
              </div>
              <div className="privacy-policy">
                {t("my-account-logged-in.pr-po")}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ShippingFooter;
