import React from "react";
import { connect } from "react-redux";
import LoginMobOption1 from "./LoginMobOption1";

function LoginMobPageOpt1(props) {
  return <div className="">{props.width === "sm" && <LoginMobOption1 />}</div>;
}

const mapStateToProps = (state) => {
  return {
    width: state.layout.screenWidth,
  };
};

export default connect(mapStateToProps, null)(LoginMobPageOpt1);
