import React, { useEffect, useState } from "react";
import Row from "react-bootstrap/Row";

import OrderDetails from "../../../components/customer/account/orders/OrderDetails";
import WebsiteHeader from "../../../components/header/WebsiteHeader";
import Sidebar from "../../../components/customer/account/Sidebar";
import MobileLayoutRenderer from "../../mobile/layout/MobileLayoutRenderer";
import "../../../assets/styles/customer/account/general.scss";
import { connect } from "react-redux";
import { set_order_detail } from "../../../redux/actions/OrdersAction";
import { apiJson } from "../../../Api";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { set_loading } from "../../../redux/actions/LayoutAction";
import { useTranslation } from "react-i18next";
import HeaderMobile from "../../../components/mobile/HeaderMobile";

const OrderDetailsPage = (props) => {
  const { t } = useTranslation();

  const { width } = props;
  const [data, setData] = useState(false);
  const { id } = useParams();
  let message = "";

  const showError = () => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    props.set_loading(true);

    apiJson.orders.getOrderDetail(id).then((response) => {
      props.set_loading(false);
      if (response.success) {
        props.set_order_detail({ orderDetails: response.data });
        setData(true);
      } else if (!response.status) {
        // eslint-disable-next-line
        message = response.message
          ? response.message !== ""
            ? response.message
            : t("errors.wrong")
          : t("errors.wrong");
        showError();
      } else {
        if (response.status >= 400 && response.status < 500) {
          message = response.message
            ? response.message
            : response.errors
            ? response.errors
            : t("errors.wrong");
          showError();
        } else {
          message = response.message
            ? response.message !== ""
              ? response.message
              : t("errors.wrong")
            : t("errors.wrong");
          showError();
        }
      }
    });
  }, []);

  const renderDesktopLayout = () => {
    return (
      <Row className="page-container no-gutters">
        <div className="w-100">
          <div className="header-mobile">
            <WebsiteHeader />
          </div>
          {!data ? (
            <div className=""></div>
          ) : (
            <div className="content-container all-page-container d-flex justify-content-center mx-auto">
              <div className="left-pane">
                <Sidebar />
              </div>
              <div className="right-pane pb-3">
                <OrderDetails orders={props.order_detail} />
              </div>
            </div>
          )}
        </div>
      </Row>
    );
  };

  const renderMobileLayout = () => {
    return (
      <MobileLayoutRenderer
        NavbarTop={false}
        BackBtn={true}
        CancelBtn={false}
        NavbarBottom={true}
        noPadding={data ? false : true}
        SearchIcon={true}
      >
        {!data ? (
          <div className="d-flex justify-content-center align-items-center w-100 h-100"></div>
        ) : (
          <div className="right-pane pb-3">
          <div className="home-mob">
            <HeaderMobile />
          </div>
            <OrderDetails orders={props.order_detail} />
          </div>
        )}
      </MobileLayoutRenderer>
    );
  };

  return width === "sm"
    ? renderMobileLayout()
    : width !== "" && renderDesktopLayout();
};

const mapStateToProps = (state) => {
  return {
    country: state.language.country,
    width: state.layout.screenWidth,
    direction: state.direction,
    order_detail: state.order.orderDetails,
  };
};

const mapDispatchToProps = {
  set_order_detail,
  set_loading,
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderDetailsPage);
