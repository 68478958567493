import React, { useEffect } from "react";

import ItemData from "./ItemDataMobile";
import ItemStatus from "./ItemStatusMobile";
import ItemOverview from "./ItemOverviewMobile";
import FrequentBoughtMobile from "./frequentBoughtMobile";
import { connect } from "react-redux";
import { set_single_item } from "../../../redux/actions/SingleItemAction";
import "react-toastify/dist/ReactToastify.css";
import ImageGalleryMobile from "./ImageGalleryMobile";


function ItemDetailMainMobile(props) {
  return (
    <div className={props.className}>
   
      {props.item.img && (
        <>
          <div className="spro row w-100 pt-1 pb-5 bg-white no-gutters">
            <div
              className={
                props.width !== "md"
                  ? props.width === "sm"
                    ? "w-100"
                    : "col-9"
                  : "w-100 mr-2"
              }
            >
              <ItemData
                onChangeStart={(val) => props.onChangeStart(val)}
                onChangeEnd={(val) => props.onChangeEnd(val)}
                startDate={props.startDate}
                endDate={props.endDate}
                data={props.item}
                current_variant_key={props.current_variant_key}
              />
            </div>
            <div
              className={
                props.width !== "md"
                  ? props.width === "sm"
                    ? "w-100"
                    : "col-3"
                  : "w-35 mt-3 ml-auto mr-auto"
              }
            >
              <ItemStatus data={props.item} />
            </div>
          </div>

          <div className="bg-white">
            <div className="w-95 m-auto ">
              <ItemOverview data={props.item} />
            </div>
          </div>
          {
            props.item.order_quantity > 0 ?
              <FrequentBoughtMobile sku={props.item.sku} product={props.item} />
              :
              <></>
          }
          <ImageGalleryMobile images={props.item?.img} video={props.item?.video} />
        </>
      )}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    width: state.layout.screenWidth,
    item: state.item.item,
    countryId: state.language.countryId,
  };
};

const mapDispatchToProps = {
  set_single_item,
};

export default connect(mapStateToProps, mapDispatchToProps)(ItemDetailMainMobile);
