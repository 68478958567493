import React, { useState, useEffect } from "react";
import "../../../assets/styles/component/customer/AllItems/ItemsSidebar.scss";
import { VscChevronDown } from "react-icons/vsc";
import { HiOutlineChevronRight, HiOutlineChevronLeft } from "react-icons/hi";

import { Modal, Button } from "react-bootstrap";
import ItemsCheckbox from "./ItemsCheckbox";
import ItemsCategory from "./ItemsCategory";
import { HiArrowLeft } from "react-icons/hi";

import { set_all_items } from "../../../redux/actions/AllItemsAction";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

function ItemsSidebar(props) {
  const [categoryBol, setCategoryBol] = useState(
    props.width === "sm" ? false : true
  );
  const [brandBol, setBrandBol] = useState(props.width === "sm" ? false : true);
  const [priceBol, setPriceBol] = useState(props.width === "sm" ? false : true);
  const [productBol, setProductBol] = useState(
    props.width === "sm" ? false : true
  );
  const [sellerBol, setSellerBol] = useState(
    props.width === "sm" ? false : true
  );
  const [subCategory, setSubCategory] = useState(false);
  const [subBrand, setSubBrand] = useState(false);
  const [subPrice, setSubPrice] = useState(false);
  const [subProduct, setSubProduct] = useState(false);
  const [subSeller, setSubSeller] = useState(false);
  const [lan, setLan] = useState("");
  const { t } = useTranslation();
  const handleClose = () => {
    setSubCategory(false);
    setSubBrand(false);
    setSubPrice(false);
    setSubProduct(false);
    setSubSeller(false);
  };

  const SubCategoryModal = (body, showModal, heading) => (
    <Modal show={props.width === "sm" ? showModal : false} onHide={handleClose}>
      <Modal.Body
        style={{
          textAlign: "start",
          direction: props.language === "ar" ? "rtl" : "ltr",
        }}
        className="pt-2 pl-3 pr-3 pb-0"
      >
        <div className="modal-header-mob">
          <HiArrowLeft
            style={{
              transform:
                props.language?.toLowerCase() === "en"
                  ? "unset"
                  : `rotate(180deg)`,
            }}
            onClick={handleClose}
            color="#404553"
            size="21"
          />
          <h6
            style={{
              color: "#404553",
              fontWeight: "bold",
              marginInlineStart: "10px",
              marginBottom: "0px",
            }}
          >
            {heading}
          </h6>
        </div>
        {body}
      </Modal.Body>
      <Modal.Footer>
        <Button
          style={{ borderRadius: "0px", fontWeight: "bold", fontSize: "14px" }}
          variant="primary"
          block
          onClick={() => {
            if (heading === t("itemsSidebar.price")) {
              props.set_all_items({
                go: !props.go,
                mobileFiltersCache: [
                  ...props.mobileFiltersCache.filter(
                    (item) => item.heading !== t("itemsSidebar.price")
                  ),
                  {
                    heading: t("itemsSidebar.price"),
                    name: props.minPrice + "-" + props.maxPrice,
                  },
                ],
              });
            }

            handleClose();
          }}
        >
          {t("itemsSidebar.continue")}
        </Button>
      </Modal.Footer>
    </Modal>
  );

  useEffect(() => {
    setLan(localStorage.getItem("i18nextLng"));
  }, []);

  const [brands, setBrands] = useState([]);

  useEffect(() => {
    var top_ten_brands = [];
    var other_brands = [];
    props.brand?.forEach(brand => {
      if (brand.name_en == "Rust-Oleum") {
        top_ten_brands[0] = brand;
      }
      else if (brand.name_en == "Fevicol") {
        top_ten_brands[1] = brand;
      }
      else if (brand.name_en == "Philips") {
        top_ten_brands[2] = brand;
      }
      else if (brand.name_en == "TOSHIBA") {
        top_ten_brands[3] = brand;
      }
      else if (brand.name_en == "Karcher") {
        top_ten_brands[4] = brand;
      }
      else if (brand.name_en == "Gorilla") {
        top_ten_brands[5] = brand;
      }
      else if (brand.name_en == "Asmaco") {
        top_ten_brands[6] = brand;
      }
      else if (brand.name_en == "Roll Roy") {
        top_ten_brands[7] = brand;
      }
      else if (brand.name_en == "Xpert Decor") {
        top_ten_brands[8] = brand;
      }
      else if (brand.name_en == "Black & Decker") {
        top_ten_brands[9] = brand;
      }
      else {
        other_brands = other_brands.concat(brand);
      }
    });
    let f_brands = [...top_ten_brands, ...other_brands];
    setBrands(f_brands);
  }, [props.brand]);

  const [stores, setStores] = useState([]);

  useEffect(() => {
    var top_ten_sellers = [];
    var other_sellers = [];
    props.seller?.forEach(seller => {
      if (seller.name_en == "Alisuq") {
        top_ten_sellers[0] = seller;
      }
      else if (seller.name_en == "Roll Roy Middle East") {
        top_ten_sellers[1] = seller;
      }
      else if (seller.name_en == "WEICON MIDDLE EAST LLC") {
        top_ten_sellers[2] = seller;
      }
      else if (seller.name_en == "Hadi Enterprises LLC") {
        top_ten_sellers[3] = seller;
      }
      else if (seller.name_en == "AAB Industries LLC") {
        top_ten_sellers[4] = seller;
      }
      else if (seller.name_en == "Auto Plus Mart LLC") {
        top_ten_sellers[5] = seller;
      }
      else if (seller.name_en == "Al Hathboor Group") {
        top_ten_sellers[6] = seller;
      }
      else if (seller.name_en == "Ugarit Auto Paints Trading") {
        top_ten_sellers[7] = seller;
      }
      else if (seller.name_en == "La Decor LLC") {
        top_ten_sellers[8] = seller;
      }
      else if (seller.name_en == "Technomech Trading") {
        top_ten_sellers[9] = seller;
      }
      else {
        other_sellers = other_sellers.concat(seller);
      }
    });
    let f_stores = [...top_ten_sellers, ...other_sellers];
    setStores(f_stores);
  }, [props.seller]);

  return (
    <div
      className={`items-sidebar-desktop ${props.className ? props.className : " "
        }`}
    >
      <div>
        <div
          onClick={() =>
            props.width !== "sm"
              ? setCategoryBol(!categoryBol)
              : setSubCategory(!subCategory)
          }
          className="sidebar-items-cont pointer"
        >
          <span
            onClick={() => {
              if (props.width !== "sm") {
                props.set_all_items({
                  selectedCat: "",
                });
              }
            }}
            className="sidebar-heading"
          >
            {t("items.sidebar.category-lbl")}
          </span>
          <span>
            {categoryBol ? (
              <VscChevronDown size={21} color="#404553" />
            ) : lan === "ar" ? (
              <HiOutlineChevronLeft size={21} color="#404553" />
            ) : (
              <HiOutlineChevronRight size={21} color="#404553" />
            )}
          </span>
        </div>

        {categoryBol && (
          <div>
            <ItemsCategory
              items={props.items}
              categories={props.category}
              selectedCat={(title) => {
                props.set_all_items({
                  selectedCat: title,
                });
              }}
            />
          </div>
        )}

        {subCategory && (
          <div>
            {SubCategoryModal(
              <ItemsCategory
                items={props.items}
                categories={props.category}
                selectedCat={(title) => {
                  props.set_all_items({
                    selectedCat: title,
                  });
                }}
              />,

              subCategory,
              t("itemsSidebar.category")
            )}
          </div>
        )}
      </div>
      {props.type === "Products" && (
        <div>
          <div
            className="sidebar-items-cont pointer"
            onClick={() =>
              props.width !== "sm"
                ? setBrandBol(!brandBol)
                : setSubBrand(!subBrand)
            }
          >
            <span className="sidebar-heading">
              <span>{t("items.sidebar.brand-lbl")}</span>
              {/* <span
                className="small-screen-none pointer clear-style"
                onClick={() => {
                  props.set_all_items({
                    selectedBrands: [],
                    brand: props.brand.map((ele) => ({
                      ...ele,
                      name_en: ele.name_en,
                      name_ar: ele.name_ar,
                      count: ele.count,
                      checked: false,
                    })),
                    filter_by: props.filter_by.filter(
                      (ele) => ele.heading !== t("itemsSidebar.brand")
                    ),
                    mobileFiltersCache: props.filter_by.filter(
                      (ele) => ele.heading !== t("itemsSidebar.brand")
                    ),
                  });
                }}
              >
                {t("items.sidebar.clear")}
              </span> */}
            </span>
            <span>
              {brandBol ? (
                <VscChevronDown size={21} color="#404553" />
              ) : lan === "ar" ? (
                <HiOutlineChevronLeft size={21} color="#404553" />
              ) : (
                <HiOutlineChevronRight size={21} color="#404553" />
              )}
            </span>
          </div>
          {brandBol && (
            <div className="sidebar-checkbox-cont">
              <ItemsCheckbox
                options={brands}
                UpdateOptions={(val) => props.set_all_items({ brand: val })}
                setFilters={(val, id) => {
                  if (val.checked) {
                    props.type === "Products"
                      ? props.set_all_items({
                        selectedBrands: [...props.selectedBrands, id],
                        filter_by: [...props.filter_by, val],
                        mobileFiltersCache: [...props.filter_by, val],
                      })
                      : props.set_all_items({
                        selectedBrands: [],
                        filter_by: [...props.filter_by, val],
                        mobileFiltersCache: [...props.filter_by, val],
                      });
                  } else {
                    props.type === "Products"
                      ? props.set_all_items({
                        selectedBrands: props.selectedBrands.filter(
                          (brand) => brand !== id
                        ),
                        filter_by: props.filter_by.filter(
                          (ele) => ele.name !== val.name
                        ),
                        mobileFiltersCache: props.filter_by.filter(
                          (ele) => ele.name !== val.name
                        ),
                      })
                      : props.set_all_items({
                        selectedBrands: [],
                        filter_by: props.filter_by.filter(
                          (ele) => ele.name !== val.name
                        ),
                        mobileFiltersCache: props.filter_by.filter(
                          (ele) => ele.name !== val.name
                        ),
                      });
                  }
                }}
                name={t("itemsSidebar.brand")}
              />
            </div>
          )}
          {subBrand && (
            <div>
              {SubCategoryModal(
                <ItemsCheckbox
                  options={brands}
                  UpdateOptions={(val) => props.set_all_items({ brand: val })}
                  setFilters={(val, id) => {
                    if (val.checked) {
                      props.type === "Products"
                        ? props.set_all_items({
                          selectedBrands: [...props.selectedBrands, id],
                          mobileFiltersCache: [
                            ...props.mobileFiltersCache,
                            val,
                          ],
                        })
                        : props.set_all_items({
                          selectedBrands: [],
                          mobileFiltersCache: [
                            ...props.mobileFiltersCache,
                            val,
                          ],
                        });
                    } else {
                      props.type === "Products"
                        ? props.set_all_items({
                          selectedBrands: props.selectedBrands.filter(
                            (brand) => brand !== id
                          ),
                          mobileFiltersCache: props.mobileFiltersCache.filter(
                            (ele) => ele.name !== val.name
                          ),
                        })
                        : props.set_all_items({
                          selectedBrands: [],
                          mobileFiltersCache: props.mobileFiltersCache.filter(
                            (ele) => ele.name !== val.name
                          ),
                        });
                    }
                  }}
                  name={t("itemsSidebar.brand")}
                />,
                subBrand,
                t("itemsSidebar.brand")
              )}
            </div>
          )}
        </div>
      )}
      <div>
        <div
          className="sidebar-items-cont pointer"
          onClick={() =>
            props.width !== "sm"
              ? setPriceBol(!priceBol)
              : setSubPrice(!subPrice)
          }
        >
          <span className="sidebar-heading">
            {t("items.sidebar.price-lbl")}
          </span>
          <span>
            {priceBol ? (
              <VscChevronDown size={21} color="#404553" />
            ) : lan === "ar" ? (
              <HiOutlineChevronLeft size={21} color="#404553" />
            ) : (
              <HiOutlineChevronRight size={21} color="#404553" />
            )}
          </span>
        </div>

        {priceBol && (
          <div className="number-cont">
            <input
              value={props.minPrice}
              className="number-input"
              min="0"
              onChange={(e) => {
                props.set_all_items({ minPrice: e.target.value });
              }}
              type="number"
            />
            <span className="text-style">{t("itemsSidebar.to")}</span>
            <input
              value={props.maxPrice}
              onChange={(e) => {
                props.set_all_items({ maxPrice: e.target.value });
              }}
              className="number-input"
              type="number"
              min="0"
            />
            <span
              className="go-style pointer"
              onClick={() => {
                localStorage.setItem("load_items", "true");
                props.set_all_items({
                  go: !props.go,
                  filter_by: [
                    ...props.filter_by.filter(
                      (item) => item.heading !== t("itemsSidebar.price")
                    ),
                    {
                      heading: t("itemsSidebar.price"),
                      name: props.minPrice + "-" + props.maxPrice,
                    },
                  ],
                  mobileFiltersCache: [
                    ...props.filter_by.filter(
                      (item) => item.heading !== t("itemsSidebar.price")
                    ),
                    {
                      heading: t("itemsSidebar.price"),
                      name: props.minPrice + "-" + props.maxPrice,
                    },
                  ],
                });
              }}
            >
              {" "}
              {t("itemsSidebar.go")}
            </span>
          </div>
        )}
        {subPrice && (
          <div>
            {SubCategoryModal(
              <div className="items-sidebar-desktop">
                <div className="number-cont">
                  <input
                    value={props.minPrice}
                    className="number-input"
                    onChange={(e) => {
                      props.set_all_items({ minPrice: e.target.value });
                    }}
                    type="number"
                    min="0"
                  />
                  <span className="text-style">{t("itemsSidebar.to")}</span>
                  <input
                    value={props.maxPrice}
                    onChange={(e) => {
                      props.set_all_items({ maxPrice: e.target.value });
                    }}
                    className="number-input"
                    type="number"
                    min="0"
                  />
                </div>
              </div>,
              subPrice,
              t("itemsSidebar.price")
            )}
          </div>
        )}
      </div>

      <div>
        <div
          onClick={() =>
            props.width !== "sm"
              ? setProductBol(!productBol)
              : setSubProduct(!subProduct)
          }
          className="sidebar-items-cont pointer"
        >
          <span className="sidebar-heading">{t("items.sidebar.rating")}</span>
          <span>
            {productBol ? (
              <VscChevronDown size={21} color="#404553" />
            ) : lan === "ar" ? (
              <HiOutlineChevronLeft size={21} color="#404553" />
            ) : (
              <HiOutlineChevronRight size={21} color="#404553" />
            )}
          </span>
        </div>

        {productBol && (
          <div className="range-slider-cont">
            <span className="rating-value">
              {props.rating} {t("items.sidebar.stars-or-move")}
            </span>
            <input
              type="range"
              min={1}
              max={5}
              step={0.1}
              value={props.rating}
              onChange={(e) => {
                props.set_all_items({
                  rating: e.target.value,
                  filter_by: [
                    ...props.filter_by.filter(
                      (item) => item.heading !== t("itemsSidebar.rating")
                    ),
                    {
                      heading: t("itemsSidebar.rating"),
                      name: e.target.value,
                    },
                  ],
                  mobileFiltersCache: [
                    ...props.filter_by.filter(
                      (item) => item.heading !== t("itemsSidebar.rating")
                    ),
                    {
                      heading: t("itemsSidebar.rating"),
                      name: e.target.value,
                    },
                  ],
                });
              }}
            />
            <div className="rating-range">
              <span className="rating-range-value">1 Star</span>
              <span className="rating-range-value">5 Star</span>
            </div>
          </div>
        )}
        {subProduct && (
          <div>
            {SubCategoryModal(
              <div className="items-sidebar-desktop">
                <div className="range-slider-cont">
                  <span className="rating-value">
                    {props.rating} {t("items.sidebar.stars-or-move")}
                  </span>
                  <input
                    type="range"
                    min={1}
                    max={5}
                    step={0.1}
                    value={props.rating}
                    onChange={(e) => {
                      props.set_all_items({
                        rating: e.target.value,
                        mobileFiltersCache: [
                          ...props.mobileFiltersCache.filter(
                            (item) => item.heading !== t("itemsSidebar.rating")
                          ),
                          {
                            heading: t("itemsSidebar.rating"),
                            name: e.target.value,
                          },
                        ],
                      });
                    }}
                  />
                  <div className="rating-range">
                    <span className="rating-range-value">1 Star</span>
                    <span className="rating-range-value">5 Star</span>
                  </div>
                </div>
              </div>,
              subProduct,
              t("itemsSidebar.product")
            )}
          </div>
        )}
      </div>

      <div className="">
        <div
          onClick={() =>
            props.width !== "sm"
              ? setSellerBol(!sellerBol)
              : setSubSeller(!subSeller)
          }
          className="sidebar-items-cont pointer"
        >
          <span className="sidebar-heading">
            {t("items.sidebar.seller-lbl")}
          </span>
          <span>
            {sellerBol ? (
              <VscChevronDown size={21} color="#404553" />
            ) : lan === "ar" ? (
              <HiOutlineChevronLeft size={21} color="#404553" />
            ) : (
              <HiOutlineChevronRight size={21} color="#404553" />
            )}
          </span>
        </div>
      </div>

      <div className="sidebar-checkbox-cont">
        {sellerBol && (
          <ItemsCheckbox
            options={stores}
            UpdateOptions={(val) => props.set_all_items({ seller: val })}
            setFilters={(val, id) => {
              if (val.checked) {
                props.set_all_items({
                  selectedStores: [...props.selectedStores, id],
                  filter_by: [...props.filter_by, val],
                  mobileFiltersCache: [...props.filter_by, val],
                });
              } else {
                props.set_all_items({
                  selectedStores: props.selectedStores.filter(
                    (seller) => seller !== id
                  ),
                  filter_by: props.filter_by.filter(
                    (ele) => ele.name !== val.name
                  ),
                  mobileFiltersCache: props.filter_by.filter(
                    (ele) => ele.name !== val.name
                  ),
                });
              }
            }}
            name={t("itemsSidebar.seller")}
          />
        )}
        {subSeller && (
          <div>
            {SubCategoryModal(
              <ItemsCheckbox
                options={stores}
                UpdateOptions={(val) => props.set_all_items({ seller: val })}
                setFilters={(val, id) => {
                  if (val.checked) {
                    props.set_all_items({
                      selectedStores: [...props.selectedStores, id],
                      mobileFiltersCache: [...props.mobileFiltersCache, val],
                    });
                  } else {
                    props.set_all_items({
                      selectedStores: props.selectedStores.filter(
                        (seller) => seller !== id
                      ),
                      mobileFiltersCache: props.mobileFiltersCache.filter(
                        (ele) => ele.name !== val.name
                      ),
                    });
                  }
                }}
                name={t("itemsSidebar.seller")}
              />,
              subSeller,
              t("itemsSidebar.seller")
            )}
          </div>
        )}
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    items: state.allItems.items,
    seller: state.allItems.seller,
    selectedBrands: state.allItems.selectedBrands,
    go: state.allItems.go,
    selectedStores: state.allItems.selectedStores,
    brand: state.allItems.brand,
    filter_by: state.allItems.filter_by,
    mobileFiltersCache: state.allItems.mobileFiltersCache,
    category: state.allItems.category,
    selectedCat: state.allItems.selectedCat,
    minPrice: state.allItems.minPrice,
    maxPrice: state.allItems.maxPrice,
    rating: state.allItems.rating,
    width: state.layout.screenWidth,
    originalItems: state.allItems.originalItems,
    language: state.language.language,
  };
};

const mapDispatchToProps = {
  set_all_items,
};
export default connect(mapStateToProps, mapDispatchToProps)(ItemsSidebar);
