import React from "react";
import ProfileMobile from "../../../components/mobile/customer/ProfileMobile";
import HeaderMobile from "../../../components/mobile/HeaderMobile";
import MobileLayoutRenderer from "../layout/MobileLayoutRenderer";

export default function ProfileMobilePage() {
  return (
    <div>
      <MobileLayoutRenderer
        NavbarTop={false}
        BackBtn={true}
        CancelBtn={false}
        NavbarBottom={true}
        SearchIcon={true}
      >
        <div className="home-mob">
          <HeaderMobile />
        </div>
        <ProfileMobile />
      </MobileLayoutRenderer>
    </div>
  );
}
