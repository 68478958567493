import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import "../../assets/styles/pages/contactus/ContactUsPage.scss";
import { set_loading } from "../../redux/actions/LayoutAction";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { apiJson } from "../../Api";
import WebsiteHeader from "../../components/header/WebsiteHeader";
import WebsiteFooter from "../../components/Footer/WebsiteFooter";
import pagesbannerimg from "../../assets/images/footer-pages-banners/Contact-Us.jpg";

function ContactUsPage(props) {
  const { t } = useTranslation();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [messageDes, setMessage] = useState("");
  let message = "";
  const [data, setData] = useState("<p></p>");
  const showError = () => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  useEffect(() => {
    window.document.title = "Contact us";

    window.scrollTo(0, 0);
    props.set_loading(true);
    apiJson.platformData.platformData().then((response) => {
      if (response.success) {
        setData(response.data);
        props.set_loading(false);
      } else if (!response.status) {
        // eslint-disable-next-line
        message = response.message
          ? response.message !== ""
            ? response.message
            : "something went wrong"
          : "something went wrong";
        showError();
      } else {
        if (response.status >= 400 && response.status < 500) {
          message = response.message
            ? response.message
            : response.errors
            ? response.errors
            : "something went wrong";
          showError();
        } else {
          message = response.message
            ? response.message !== ""
              ? response.message
              : "something went wrong"
            : "something went wrong";
          showError();
        }
      }
    });
  }, []);
  return (
    <div className="contact-us-main d-flex flex-column align-items-center justify-content-center">
      <WebsiteHeader />
      <div className="pagesbanner">
        <img
          src={pagesbannerimg}
          alt="category"
          style={{
            width: "100%",
            height: "100%",
            maxHeight: "400px",
          }}
        ></img>
      </div>
      <div className="heading">{t("contact-us.heading")}</div>
      <div className="sub-heading">{data.heading}</div>
      <div className="mt-3">
        <div
          className="mb-1 body"
          dangerouslySetInnerHTML={{
            __html:
              props.language === "en"
                ? data[`contact_us`]
                : data[`contact_us_${props.language}`],
          }}
        ></div>
      </div>

      <div className="d-flex flex-column align-items-center form">
        <div className="form-heading">{t("contact-us.feel-free")}</div>
        <div className="d-flex flex-row justify-content-between align-items-center">
          <div className=" d-flex flex-column input-cont">
            <label className="label">{t("contact-us.full-name")}</label>
            <input value={name} onChange={(e) => setName(e.target.value)} />
          </div>
          <div className="  d-flex flex-column input-cont">
            <label className="label">{t("login.email-placeholder")}</label>
            <input value={email} onChange={(e) => setEmail(e.target.value)} />
          </div>
        </div>
        <div className="message-cont  d-flex flex-column input-cont">
          <label className="label">{t("contact-us.your-message")}</label>
          <textarea
            value={messageDes}
            onChange={(e) => setMessage(e.target.value)}
          />
        </div>

        <div className="submit-button pointer">
          <span>{t("contact-us.send-message")}</span>
        </div>
      </div>
      <WebsiteFooter />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    language: state.language.language,
    loading: state.layout.loading,
  };
};
const mapDispatchToProps = { set_loading };

export default connect(mapStateToProps, mapDispatchToProps)(ContactUsPage);
