import React, { Fragment } from "react";
import { Row } from "reactstrap";
import { connect } from "react-redux";

import "../../assets/styles/header/individualcategory.scss";
import Category from "./Category";

const IndividualCategory = (props) => {
  return (
    <Fragment>
      <div className="individual-category-container-desktop">
        <Row className="inidividual-category-row mx-auto">
          <div
            className="individual-categories-container d-flex mx-auto"
            onMouseLeave={props.changestate}
          >
            <div className="container-wrapper d-flex" ref={props.indivRef}>
              {props.data.slice(0, 4).map((ele) => (
                <div className="individual-category" key={ele.id}>
                  <Category
                    image={ele.image}
                    data={ele.sub_categories}
                    parentId={
                      ele.sub_categories?.length > 0
                        ? [
                            ele.id,
                            ...ele.sub_categories.map((subCat) => subCat.id),
                          ]
                        : [ele.id]
                    }
                    cat={
                      props.language === "en"
                        ? `For ${ele.title_en}`
                        : `بسبب ${ele.title_ar}`
                    }
                  />
                </div>
              ))}
            </div>
          </div>
        </Row>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    language: state.language.language,
    countryUrl: state.language.countryUrl,
  };
};

export default connect(mapStateToProps, null)(IndividualCategory);
