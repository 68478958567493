import React, { useState, useEffect } from "react";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import { Fragment } from "react";
import { Row, Button } from "react-bootstrap";
import { FaMapMarkedAlt } from "react-icons/fa";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { IoChevronBack } from "react-icons/io5";
import "../../assets/styles/customer/addressform.scss";
import Flag from "../../assets/images/flag.png";
import Map from "../../assets/images/map.jpeg";
import { ReactComponent as Checked } from "../../assets/icons/Checked.svg";
import { ReactComponent as Unchecked } from "../../assets/icons/Unchecked.svg";
import { connect } from "react-redux";
import { Redirect, useLocation } from "react-router-dom";
import { apiJson } from "../../Api";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ClipLoader from "react-spinners/ClipLoader";
import { AiFillPlusCircle } from "react-icons/ai";
import { useTranslation } from "react-i18next";
import { set_addresses } from "../../redux/actions/SelectAddressAction";
import { TextField } from "@material-ui/core";
import { set_cart_items } from "../../redux/actions/CartAction";

const addressValidationSchema = Yup.object().shape({
  phone: Yup.string()
    .required("Required")
    .test(
      "phone",
      "Should be a valid phone number",
      (val) =>
        val && /^(?:00971|\+971|0)?(?:|05|5|04|4)\d{8,9}$/.test("+" + val)
    ),
  firstName: Yup.string()
    .required("Required")
    .test(
      "firstName",
      "Should be greater than 2",
      (val) => val && val.length > 1
    ),
  // lastName: Yup.string().required("Required"),
  address1: Yup.string().required("Required"),
  state: Yup.string().required("Required"),
  flatNum: Yup.string().required("Required"),
});

const AddressForm = (props) => {
  const location = useLocation();
  const initialValues = location.state?.data
    ? {
      phone: location.state.data.number.replace("+", ""),
      firstName: location.state.data.firstName,
      // lastName: location.state.data.lastName,
      lastName: "-",
      flatNum: location.state.data.address2,
      address1: location.state.data.address1,
      state: location.state.data.state
    }
    : {
      phone: "",
      firstName: "",
      lastName: "-",
      flatNum: "",
      address1: "",
      state: ""
    };
  const [label, setLabel] = useState("");
  const [isHomeAddress, setIsHomeAddress] = useState(false);
  const [isWorkAddress, setIsWorkAddress] = useState(false);
  const [openMap, setOpenMap] = useState(false);
  const [isLabel, setIsLabel] = useState(false);
  const [addLabel, setAddLabel] = useState(false);
  const [addingData, setAddingData] = useState(false);
  const [redirectPage, setRedirectPage] = useState(false);
  const { t } = useTranslation();
  const [redirect, setRedirect] = useState({
    value: false,
    path: "/" + props.countryUrl + "-" + props.language + "/",
  });

  let message = "";

  const showError = () => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const saveAddress = (values) => {
    let tempLabel = isHomeAddress
      ? "Home"
      : isWorkAddress
        ? "Work"
        : isLabel
          ? label
          : "";

    setAddingData(true);
    apiJson.select
      .addAdress({
        address: {
          country:
            props.countryId == 1
              ? "uae"
              : props.countryId == 2
                ? "ksa"
                : "egypt",
          state: values.state,
          // country: props.mapAddress.country,
          // state: props.mapAddress.state,
          address1: values.address1,
          // address1: props.mapAddress.address,
          address2: values.flatNum,
          latitude: "-",
          longitude: "-",
          // latitude: props.mapAddress.lat,
          // longitude: props.mapAddress.lng,
          label: tempLabel,
          first_name: values.firstName,
          last_name: values.lastName,
          contact_number: "+" + values.phone,
        },
      })
      .then((response) => {
        if (response.success) {
          localStorage.setItem("guest_user_address", JSON.stringify(response.data));
          setAddingData(false);
          setIsHomeAddress(false);
          setIsWorkAddress(false);

          setIsLabel(false);
          setAddLabel(false);
          setAddingData(false);
          if (!props.auth) {
            const request = {
              order: {
                address_id: response.data.id,
              },
            };
            props.set_cart_items({
              order: {
                id: response.data.id,

                number: response.data.contact_number,
                address: response.data,
                request: request,
              },
            });


            // setRedirect({
            //   value: true,
            //   path: "/" + props.countryUrl + "-" + props.language + "/phone",
            // });

            // Added By Kamran
            var address = response.data;
            apiJson.phone.verifyOtp(address.id, "1").then((response) => {
              if (response.success) {
                apiJson.select
                  .varifyAdress({
                    order: {
                      address_id: address.id,
                      is_delivery: 1
                    }
                  }, props.countryId)
                  .then((response) => {
                    // setLoading(false);
                    if (response.success) {
                      props.set_cart_items({
                        order: {
                          address: address,
                          id: response.data.id,
                          createdAddress: response.data,
                        },
                      });
                      setRedirect({
                        value: true,
                        path:
                          "/" + props.countryUrl + "-" + props.language + "/checkout",
                      });
                      const timer = setTimeout(() => {
                        setRedirect({ value: false });
                      }, 1000);
                      clearTimeout(timer);
                    } else if (!response.status) {
                      // setLoading(false);
                      message = t("errors.wrong");
                      showError();
                    } else {
                      // setLoading(false);
                      if (response.status >= 400 && response.status < 500) {
                        message = response.message
                          ? response.message
                          : response.errors
                            ? response.errors
                            : t("errors.wrong");
                        showError();
                      } else {
                        message = response.message
                          ? response.message !== ""
                            ? response.message
                            : t("errors.wrong")
                          : t("errors.wrong");
                        showError();
                      }
                    }
                  });
              } else if (!response.status) {
                // setLoading(false);
                message = response.message
                  ? response.message !== ""
                    ? response.message
                    : t("errors.wrong")
                  : t("errors.wrong");
                showError();
              } else {
                // setLoading(false);
                if (response.status >= 400 && response.status < 500) {
                  message = response.message
                    ? response.message
                    : response.errors
                      ? response.errors
                      : t("errors.wrong");
                  showError();
                } else {
                  message = response.message
                    ? response.message !== ""
                      ? response.message
                      : t("errors.wrong")
                    : t("errors.wrong");
                  showError();
                }
              }
            });

            // setRedirect({
            //   value: true,
            //   path:
            //     "/" + props.countryUrl + "-" + props.language + "/checkout",
            // });
          } else {
            setRedirectPage(true);
            const timer = setTimeout(() => {
              setRedirectPage(false);
            }, 1000);
            clearTimeout(timer);
          }
        } else if (!response.status) {
          message = t("errors.wrong");
          showError();
        } else {
          if (response.status >= 400 && response.status < 500) {
            message = response.message
              ? response.message
              : response.errors
                ? response.errors
                : t("errors.wrong");
            showError();
          } else {
            message = response.message
              ? response.message !== ""
                ? response.message
                : t("errors.wrong")
              : t("errors.wrong");
            showError();
          }
        }
      });
  };

  const UpdateAddress = (values) => {
    let tempLabel = isHomeAddress
      ? "Home"
      : isWorkAddress
        ? "Work"
        : isLabel
          ? label
          : "";

    setAddingData(true);
    apiJson.select
      .updateAddress(location.state.data.id, {
        address: {
          country:
            props.countryId == 1
              ? "uae"
              : props.countryId == 2
                ? "ksa"
                : "egypt",
          state: values.state,
          // country: props.mapAddress.country,
          // state: props.mapAddress.state,
          address1: values.address1,
          // address1: props.mapAddress.address,
          address2: values.flatNum,
          latitude: "-",
          longitude: "-",
          // latitude: props.mapAddress.lat,
          // longitude: props.mapAddress.lng,
          label: tempLabel,
          first_name: values.firstName,
          last_name: values.lastName,
          contact_number: "+" + values.phone,
        },
      })
      .then((response) => {
        if (response.success) {
          setAddingData(false);
          setIsHomeAddress(false);
          setIsWorkAddress(false);

          setIsLabel(false);
          setAddLabel(false);
          setAddingData(false);
          if (!props.auth) {
            const request = {
              order: {
                address_id: response.data.id,
              },
            };
            // props.set_cart_items({
            //   order: {
            //     id: response.data.id,

            //     number: response.data.contact_number,
            //     address: response.data,
            //     request: request,
            //   },
            // });

            // setRedirect({
            //   value: true,
            //   path: "/" + props.countryUrl + "-" + props.language + "/phone",
            // });

            // Added By Kamran
            var address = response.data;
            apiJson.phone.verifyOtp(address.id, "1").then((response) => {
              if (response.success) {
                apiJson.select
                  .varifyAdress({
                    order: {
                      address_id: address.id,
                      is_delivery: 1
                    }
                  }, props.countryId)
                  .then((response) => {
                    // setLoading(false);
                    if (response.success) {
                      props.set_cart_items({
                        order: {
                          address: address,
                          id: response.data.id,
                          createdAddress: response.data,
                          request: request,
                          number: address.contact_number,
                        },
                      });
                      setRedirect({
                        value: true,
                        path:
                          "/" + props.countryUrl + "-" + props.language + "/checkout",
                      });
                      const timer = setTimeout(() => {
                        setRedirect({ value: false });
                      }, 1000);
                      clearTimeout(timer);
                    } else if (!response.status) {
                      // setLoading(false);
                      message = t("errors.wrong");
                      showError();
                    } else {
                      // setLoading(false);
                      if (response.status >= 400 && response.status < 500) {
                        message = response.message
                          ? response.message
                          : response.errors
                            ? response.errors
                            : t("errors.wrong");
                        showError();
                      } else {
                        message = response.message
                          ? response.message !== ""
                            ? response.message
                            : t("errors.wrong")
                          : t("errors.wrong");
                        showError();
                      }
                    }
                  });
              } else if (!response.status) {
                // setLoading(false);
                message = response.message
                  ? response.message !== ""
                    ? response.message
                    : t("errors.wrong")
                  : t("errors.wrong");
                showError();
              } else {
                // setLoading(false);
                if (response.status >= 400 && response.status < 500) {
                  message = response.message
                    ? response.message
                    : response.errors
                      ? response.errors
                      : t("errors.wrong");
                  showError();
                } else {
                  message = response.message
                    ? response.message !== ""
                      ? response.message
                      : t("errors.wrong")
                    : t("errors.wrong");
                  showError();
                }
              }
            });
          } else {
            setRedirectPage(true);
            const timer = setTimeout(() => {
              setRedirectPage(false);
            }, 1000);
            clearTimeout(timer);
          }
        } else if (!response.status) {
          message = t("errors.wrong");
          showError();
        } else {
          if (response.status >= 400 && response.status < 500) {
            message = response.message
              ? response.message
              : response.errors
                ? response.errors
                : t("errors.wrong");
            showError();
          } else {
            message = response.message
              ? response.message !== ""
                ? response.message
                : t("errors.wrong")
              : t("errors.wrong");
            showError();
          }
        }
      });
  };

  useEffect(() => {
    if (location.state?.data) {
      if (location.state.data.label !== "") {
        location.state.data.label === "Home" && setIsHomeAddress(true);

        location.state.data.label === "Work" && setIsWorkAddress(true);
        if (
          location.state.data.label !== "Home" &&
          location.state.data.label !== "Work"
        ) {
          setIsLabel(true);
          setLabel(location.state.data.label);
        }
      }
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Fragment>
      <div className="address-form-desktop mx-auto">
        <div className="row gx-0 no-gutters back-icon-col d-flex align-items-center">
          <span className="pointer" onClick={() => setRedirectPage(true)}>
            <IoChevronBack
              style={{
                transform:
                  props.language === "ar" ? "rotate(180deg)" : "rotate(0deg)",
              }}
            />
            <span className="">{t("address-form.back")}</span>
          </span>
        </div>
        <div className="add-address-form">
          <div className="row gx-0 no-gutters header-col d-flex justify-content-between align-items-center">
            <div className="edit-address">{t("address-form.edit")}</div>
          </div>
          <div className="form-col w-100">
            <Formik
              initialValues={initialValues}
              validationSchema={addressValidationSchema}
              enableReinitialize={true}
              validateOnBlur
              onSubmit={(values) =>
                location.state?.data
                  ? UpdateAddress(values)
                  : saveAddress(values)
              }
            >
              {({
                values,
                setFieldValue,
                errors,
                touched,
                setFieldTouched,
              }) => (
                <Form>
                  <div className="row gx-0 information-col">
                    <div className="location-info-col col-md-12 col-lg-6 d-flex flex-column ">
                      <div className="header d-flex align-items-center justify-content-md-between justify-content-lg-start">
                        <div className="label">
                          {t("address-form.location.location-info")}
                        </div>
                        <img src={Flag} alt="flag" width="40px" height="26px" />
                      </div>
                      {/* <div
                        className="set-map d-flex justify-content-between"
                        style={{ position: "relative" }}
                      >
                        <div className="map-selection d-flex flex-column">
                          <div
                            onClick={() => {
                              setOpenMap(true);
                              const timer = setTimeout(() => {
                                setOpenMap(false);
                              }, 1000);
                              clearTimeout(timer);
                            }}
                            className="pointer label"
                          >
                            <FaMapMarkedAlt />
                            &nbsp;&nbsp;{" "}
                            {t("address-form.location.set-from-map")}
                          </div>
                          <div className="selection-heading">
                            {props.mapAddress.address}
                          </div>
                        </div>
                        <div className="map">
                          <img src={Map} alt="map" width="100%" height="100%" />
                        </div>
                        {!props.mapAddress && (
                          <div
                            className="error"
                            style={{
                              position: "absolute",
                              bottom: "-19px",
                              left: props.language === "ar" ? "92%" : "0",
                            }}
                          >
                            Required
                          </div>
                        )}
                      </div> */}

                      <div
                        className={
                          props.direction === "RTL" ? "input-rtl" : "input-ltr"
                        }
                      >
                        <Field
                          component={TextField}
                          className="w-100"
                          type="text"
                          name="address1"
                          label={t("address-form.location.address1-placeholder")}
                          onBlur={() => setFieldTouched("address1", true)}
                          onChange={(e) =>
                            setFieldValue("address1", e.target.value)
                          }
                          value={values.address1}
                        />
                      </div>
                      {touched.address1 && errors.address1 && (
                        <div className="mt-error error">{errors.address1}</div>
                      )}

                      <div
                        className={
                          props.direction === "RTL" ? "input-rtl" : "input-ltr"
                        }
                      >
                        <Field
                          component={TextField}
                          className="w-100"
                          type="text"
                          name="flatNum"
                          label={t("address-form.location.address-placeholder")}
                          onBlur={() => setFieldTouched("flatNum", true)}
                          onChange={(e) =>
                            setFieldValue("flatNum", e.target.value)
                          }
                          value={values.flatNum}
                        />
                      </div>
                      {touched.flatNum && errors.flatNum && (
                        <div className="mt-error error">{errors.flatNum}</div>
                      )}

                      <div
                        className={
                          props.direction === "RTL" ? "input-rtl" : "input-ltr"
                        }
                      >
                        <select
                          className="w-100 border-0"
                          name="state"
                          label={t("address-form.location.state-placeholder")}
                          onBlur={() => setFieldTouched("state", true)}
                          onChange={(e) =>
                            setFieldValue("state", e.target.value)
                          }
                          value={values.state}
                        >
                          <option value="">--- Select emirate ---</option>
                          {
                            props.countryId == 1 ?
                              <>
                                <option value="Dubai">Dubai</option>
                                <option value="Abu Dhabi">Abu Dhabi</option>
                                <option value="Sharjah">Sharjah</option>
                                <option value="Ajman">Ajman</option>
                                <option value="Umm Al Quwain">Umm Al Quwain</option>
                                <option value="Ras Al Khaimah">Ras Al Khaimah</option>
                                <option value="Fujairah">Fujairah</option>
                              </>
                              :
                              <></>
                          }
                        </select>
                      </div>
                      {touched.state && errors.state && (
                        <div className="mt-error error">{errors.state}</div>
                      )}
                    </div>

                    <div
                      className={
                        (props.direction === "LTR" &&
                          "personal-info-col-ltr col-md-12 col-lg-6") ||
                        (props.direction === "RTL" &&
                          "personal-info-col-rtl col-md-12 col-lg-6")
                      }
                    >
                      <div className="header d-flex align-items-center">
                        <div className="label">
                          {t("address-form.personal.personal-info")}
                        </div>
                      </div>
                      <div
                        className={
                          (props.direction === "LTR" && "intl-phone-ltr") ||
                          (props.direction === "RTL" && "intl-phone-rtl")
                        }
                      >
                        <Field
                          component={TextField}
                          className="w-100"
                          name="phone"
                        >
                          {({ field }) => (
                            <Fragment>
                              <PhoneInput
                                inputProps={{ name: field.name, maxLength: 17 }}
                                placeholder={"Mobile Number"}
                                countryCodeEditable={false}
                                onlyCountries={["ae"]}
                                country={"ae"}
                                value={values.phone}
                                onBlur={() => setFieldTouched("phone", true)}
                                onChange={(val) => setFieldValue("phone", val)}
                                enableLongNumbers={false}
                              />
                            </Fragment>
                          )}
                        </Field>
                      </div>
                      {touched.phone && errors.phone && (
                        <div className="mt-error error">{errors.phone}</div>
                      )}
                      <div
                        className={
                          props.direction === "RTL"
                            ? "first-name-rtl"
                            : "first-name-ltr"
                        }
                      >
                        <Field
                          component={TextField}
                          className="w-100"
                          type="text"
                          name="firstName"
                          onBlur={() => setFieldTouched("firstName", true)}
                          value={values.firstName}
                          onChange={(e) =>
                            setFieldValue("firstName", e.target.value)
                          }
                          label={t(
                            "address-form.personal.first-name-placeholder"
                          )}
                        />
                      </div>
                      {touched.firstName && errors.firstName && (
                        <div className="mt2-error error">
                          {errors.firstName}
                        </div>
                      )}
                      {/* <div
                        className={
                          props.direction === "RTL"
                            ? "last-name-rtl"
                            : "last-name-ltr"
                        }
                      >
                        <Field
                          component={TextField}
                          className="w-100"
                          name="lastName"
                          type="text"
                          label={t(
                            "address-form.personal.last-name-placeholder"
                          )}
                          value={values.lastName}
                          onBlur={() => setFieldTouched("lastName", true)}
                          onChange={(e) =>
                            setFieldValue("lastName", e.target.value)
                          }
                        />
                      </div>
                      {touched.lastName && errors.lastName && (
                        <div className="mt2-error error">{errors.lastName}</div>
                      )} */}
                    </div>
                  </div>
                  <div className="button-group-col w-100 d-flex justify-content-between align-items-center">
                    {/* <Row className="address-label d-flex align-items-center nogutters">
                      <div className="col-5 label d-flex flex-column">
                        <span className="bold-text">
                          {t("address-form.location.address-label")}{" "}
                          <span className="optional-txt">
                            {t("address-form.location.optional")}
                          </span>
                        </span>
                      </div>
                      <div className="col-7">
                        <Row className="labels-input-wrapper w-100 nogutters">
                          <div
                            className="col-md-7 col-lg-7 col-xl-6 no-gutters radio-button d-flex align-items-center"
                            onClick={() => {
                              setIsLabel(false);
                              setIsHomeAddress(true);
                              setIsWorkAddress(false);
                            }}
                          >
                            <div className="pointer">
                              {isHomeAddress ? <Checked /> : <Unchecked />}
                            </div>
                            <div className=" label p-2">
                              {t("address-form.location.home")}
                            </div>
                          </div>
                          <div
                            className="col-md-5 col-lg-5 col-xl-6 no-gutters radio-button d-flex align-items-center"
                            onClick={() => {
                              setIsLabel(false);
                              setIsHomeAddress(false);
                              setIsWorkAddress(true);
                            }}
                          >
                            <div className="pointer">
                              {isWorkAddress ? <Checked /> : <Unchecked />}
                            </div>
                            <div className="label p-2">
                              {t("address-form.location.work")}
                            </div>
                          </div>
                          <div className="empty-div">
                            <label htmlFor="" className="empty-label"></label>
                          </div>
                        </Row>
                      </div>
                    </Row> */}
                    <div className="save-address d-flex align-items-center justify-content-between w-100">
                      {/* <div
                        className="d-flex flex-row align-items-center"
                        onClick={() => {
                          setLabel("");
                          setAddLabel(true);
                          if (isLabel) {
                            setIsLabel(false);
                            setIsHomeAddress(true);
                            setIsWorkAddress(false);
                          }
                        }}
                      >
                        <div className="add-button d-flex align-items-center">
                          <AiFillPlusCircle size={20} color="#d3af44" />
                        </div>
                        <div className="label pointer">
                          {t("address-form.location.add-label")}
                        </div>
                      </div> */}
                      <div className="save-button ml-auto">
                        <button
                          type="submit"
                          key={2141206}
                          className="button"
                        // disabled={!props.mapAddress ? true : false}
                        >
                          {addingData ? (
                            <div className="d-flex justify-content-center align-items-center w-100">
                              <ClipLoader color="#fff" loading size={18} />
                            </div>
                          ) : location.state?.data ? (
                            t("address-form.location.update-address")
                          ) : (
                            t("address-form.location.save-address")
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                  {/* <div className="row w-50 no-gutters new-upcoming-label d-flex align-items-center">
                    <div className="col-5 left-col">
                      <label className="empty-lavel"></label>
                    </div>
                    <div className="col-7 right-col">
                      {label !== "" && (
                        <div
                          className="radio-button d-flex align-items-center"
                          onClick={() => {
                            setIsHomeAddress(false);
                            setIsWorkAddress(false);
                            setIsLabel(true);
                          }}
                        >
                          <div className="pointer">
                            {isLabel ? <Checked /> : <Unchecked />}
                          </div>
                          <div className="label p-2">{label}</div>
                        </div>
                      )}
                    </div>
                  </div> */}

                  {/* {addLabel && (
                    <div className="w-40 mt-2 add-label">
                      <Field
                        component={TextField}
                        className="w-100"
                        type="text"
                        value={label}
                        onChange={(e) => setLabel(e.target.value)}
                        label="label name"
                      />
                      <Button
                        onClick={(e) => {
                          e.preventDefault();
                          setAddLabel(false);
                        }}
                        variant="secondary"
                        className="mt-2"
                      >
                        {t("address-form.location.save")}
                      </Button>
                    </div>
                  )} */}
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
      {openMap && (
        <Redirect
          to={
            location.state
              ? "/" + props.countryUrl + "-" + props.language + "/profile-map"
              : "/" + props.countryUrl + "-" + props.language + "/ship"
          }
        />
      )}
      {redirectPage && (
        <Redirect
          push
          to={
            location.state
              ? "/" + props.countryUrl + "-" + props.language + "/addresses"
              : "/" +
              props.countryUrl +
              "-" +
              props.language +
              "/select-address"
          }
        />
      )}
      {redirect.value && (
        <Redirect
          to={{
            pathname: redirect.path,
            state: redirect.state,
          }}
        />
      )}
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    mapAddress: state.select.mapAddress,
    coupon_id: state.select.coupon_id,
    direction: state.language.direction,
    auth: state.auth.isSignedIn,
    countryUrl: state.language.countryUrl,
    language: state.language.language,
    countryId: state.language.countryId,
  };
};

const mapDispatchToProps = {
  set_addresses,
  set_cart_items,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddressForm);
