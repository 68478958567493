import React, { useEffect } from "react";
import Row from "react-bootstrap/Row";

import "../../../assets/styles/customer/account/general.scss";
import "../../../assets/styles/pages/walletpage.scss";
import WebsiteHeader from "../../../components/header/WebsiteHeader";
import Sidebar from "../../../components/customer/account/Sidebar";
import CustomerProfileMap from "../../../components/customer/account/CustomerProfileMap/CustomerProfileMap";
import CustomerProfileMapFooter from "../../../components/customer/account/CustomerProfileMap/CustomerProfileMapFooter";

const WalletPage = () => {
  useEffect(() => {
    document.title = "Location";
  }, []);
  return (
    <Row className="page-container no-gutters">
      <div className="w-100">
        <WebsiteHeader />
      </div>

      <div className="content-container all-page-container d-flex justify-content-center mx-auto">
        <div className="left-pane">
          <Sidebar />
        </div>
        <div className="right-pane">
          <CustomerProfileMap />
        </div>
      </div>

      <div className="w-100 all-page-container ml-auto mr-auto">
        <CustomerProfileMapFooter />
      </div>
    </Row>
  );
};

export default WalletPage;
