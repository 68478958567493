import React, { Fragment, useEffect } from "react";
import { Row } from "react-bootstrap";
import Review from "../../../components/customer/account/Review";
import Sidebar from "../../../components/customer/account/Sidebar";
import WebsiteHeader from "../../../components/header/WebsiteHeader";

const ReviewPage = (props) => {
  useEffect(() => {
    document.title = "Review";
  }, []);
  return (
    <Fragment>
      <Row className="page-container no-gutters">
        <div className="w-100">
          <WebsiteHeader />

          <div className="content-container all-page-container d-flex justify-content-center mx-auto">
            <div className="left-pane">
              <Sidebar />
            </div>
            <div className="right-pane">
              <Review />
            </div>
          </div>
        </div>
      </Row>
    </Fragment>
  );
};

export default ReviewPage;
