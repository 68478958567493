import React, { useState, useEffect } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { BsCheck } from "react-icons/bs";
import "react-toastify/dist/ReactToastify.css";

import { RiBankFill } from "react-icons/ri";
import ClipLoader from "react-spinners/ClipLoader";

import { Redirect } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "../../../assets/styles/customer/cart/cartordersummary.scss";
import { apiJson } from "../../../Api";
import { set_cart_items } from "../../../redux/actions/CartAction";

function CartOrderSummary(props) {
  const [coupon, setCoupon] = useState(
    props.couponCode ? props.couponCode : ""
  );
  const suggestme = 100 - props.sub_total;

  const [redirectPage, setRedirectPage] = useState(false);
  const [redirect, setRedirect] = useState(false);

  const { t } = useTranslation();
  let message = "";
  const showError = () => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  useEffect(
    () => setCoupon(props.couponCode ? props.couponCode : ""),
    [props.couponCode]
  );

  const checkoutBtnClicked = (e) => {
    e.preventDefault();
    if (props.items > 0) {
      if (props.auth) {
        apiJson
          .select.getDefault()
          .then(resp => {
            if (resp.success) {
              if (resp.data.length > 0) {
                const request = {
                  order: {
                    address_id: resp.data[0].id,
                    is_delivery: 1
                  },
                };

                apiJson.select
                  .varifyAdress(request, props.countryId)
                  .then((response) => {
                    if (response.success) {
                      props.set_cart_items({
                        order: {
                          address: resp.data[0],
                          id: response.data.id,
                          createdAddress: response.data,
                        },
                      });
                      setRedirect({
                        value: true,
                        path:
                          "/" + props.countryUrl + "-" + props.language + "/checkout",
                      });
                    } else if (!response.status) {
                      message = t("errors.wrong");
                      showError();
                    } else {
                      if (response.status >= 400 && response.status < 500) {
                        message = response.message
                          ? response.message
                          : response.errors
                            ? response.errors
                            : t("errors.wrong");
                        showError();
                      } else {
                        message = response.message
                          ? response.message !== ""
                            ? response.message
                            : t("errors.wrong")
                          : t("errors.wrong");
                        showError();
                      }
                    }
                  });
              }
              else {
                props.set_cart_items({
                  order: {
                    address: null,
                    id: null,
                    createdAddress: null,
                  },
                });
                setRedirect({
                  value: true,
                  path:
                    "/" + props.countryUrl + "-" + props.language + "/checkout",
                });
              }
            }
          });
      }
      else {
        props.set_cart_items({
          order: {
            address: null,
            id: null,
            createdAddress: null,
          },
        });
        setRedirect({
          value: true,
          path:
            "/" + props.countryUrl + "-" + props.language + "/checkout",
        });
      }
    } else {
      message = "Please add products to cart";
      showError();
    }
  }

  return (
    <Row className=" cart-order-summary-desktop mb-2 border no-gutters pb-4 p-2 ">
      <Col md={12} sm={12} className="pb-2 summary-title ">
        {t("cart-page.order-summary")}
      </Col>
      <Col md={12} sm={12} className="pb-4">
        <form>
          <div className="form-group">
            <div className="input-group">
              <input
                type="text"
                className="form-control coupon input-field"
                placeholder={t("cart-page.coupon-code-placeholder")}
                value={coupon}
                onChange={(e) => setCoupon(e.target.value)}
                disabled={props.couponCode}
                style={{
                  borderRadius:
                    props.language?.toLowerCase() === "en"
                      ? "4px 0px 0px 4px"
                      : "0px 4px 4px 0px",
                }}
              />
              {props.couponCode && (
                <div className="d-flex pr-4 align-items-center bg-white">
                  <BsCheck size={20} color="green" />
                </div>
              )}

              <span className="input-group-append">
                {props.couponCode ? (
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      if (localStorage.getItem("access_token")) {
                        props.removeCoupon();
                      } else {
                        message = "Please login to remove coupon";
                        showError();
                      }
                    }}
                    className="pointer cart-remove-button  coupon"
                    style={{
                      borderRadius:
                        props.language?.toLowerCase() === "en"
                          ? "0px 4px 4px 0px"
                          : "4px 0px 0px 4px",
                    }}
                  >
                    {props.couponFlag ? (
                      <div className="d-flex justify-content-center align-items-center w-100">
                        <ClipLoader color="#fff" loading size={18} />
                      </div>
                    ) : (
                      t("cart-page.remove")
                    )}
                  </button>
                ) : (
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      if (localStorage.getItem("access_token")) {
                        if (coupon.length === 0) {
                          message = t("cart-page.add-a-coupon");
                          showError();
                        } else if (coupon.length > 0) {
                          props.applyCoupon(coupon);
                        }
                      } else {
                        message = "Please login to apply coupon";
                        showError();
                      }
                    }}
                    className="pointer cart-apply-button  coupon"
                    style={{
                      height: "40.188px",
                      borderRadius:
                        props.language?.toLowerCase() === "en"
                          ? "0px 4px 4px 0px"
                          : "4px 0px 0px 4px",
                    }}
                  >
                    {props.couponFlag ? (
                      <div className="d-flex justify-content-center align-items-center w-100">
                        <ClipLoader color="#fff" loading size={18} />
                      </div>
                    ) : (
                      t("cart-page.apply")
                    )}
                  </button>
                )}
              </span>
            </div>
          </div>
        </form>
      </Col>

      <Col
        md={12}
        sm={12}
        className="d-flex justify-content-between cart-summary-text"
      >
        <div className="pb-2" style={{ textAlign: "initial" }}>
          <div>
            {t("cart-page.sub")} ({props.items} {t("items.items")})
          </div>
          <div>{t("cart-page.ship")}</div>
          {props.discount && parseFloat(props.discount) !== 0 && (
            <div>{t("cart-mobile.discounts")}</div>
          )}
          {props.couponCode && <div>{t("checkout-mobile.coupon")}</div>}
        </div>
        <div className=" pb-2 price-text" style={{ textAlign: "end" }}>
          <div>{props.currency + " " + props.sub_total}</div>
          <div>{t("cart-page.calculated-lator")}</div>
          {props.discount && parseFloat(props.discount) !== 0 && (
            <div>{props.currency + " " + props.discount}</div>
          )}
          {props.couponCode && <div>{props.couponCode}</div>}
        </div>
      </Col>

      {/* {



        props.sub_total < 100 &&
        <Col md={12} sm={12} className="suggestfree justify-content-between">

          <span className="suggesttext">
            You're Only {props.currency}&nbsp;
            <span className="suggestvalue">
              {
                Math.round((suggestme + Number.EPSILON) * 100) / 100

              }
            </span>
            &nbsp;Away From Free Shipping!
          </span>
        </Col>


      } */}
      <br />
      <Col md={12} sm={12}>
        <div className="d-flex justify-content-between cart-border ">
          <div className="cart-total-text">
            {t("cart-page.total")}
            <span
              className="text-muted small"
              style={{ marginInlineStart: "3px" }}
            >
              ({t("cart-page.inclusive-of-vat")})
            </span>
          </div>
          <div className="cart-total-text">
            {props.currency + " " + props.total.toFixed(2)}
          </div>
        </div>
        <div className="d-flex flex-column justify-content-center ">
          <div className="pay-later-conitainer row justify-content-center no-gutters mb-2 mt-2 p-2">
            <Col md={2} sm={2} className="bank-icon ">
              <RiBankFill />
            </Col>
            <Col md={10} sm={10}>
              {t("cart-page.lorem-ispum")}
              <span>
                <span style={{ color: "#3065F1" }}>
                  {t("cart-page.view-more")}
                </span>
              </span>
            </Col>
          </div>
          <button
            onClick={checkoutBtnClicked}
            tyep="button"
            className="cart-checkout-button"
          >
            {t("cart-page.checkout-btn")}
          </button>
        </div>
        {redirectPage && (
          <Redirect
            push
            to={{
              pathname:
                "/" +
                props.countryUrl +
                "-" +
                props.language +
                "/select-address",
              state: { coupon_id: 1 },
            }}
          />
        )}
        {redirect.value && (
          <Redirect
            push
            to={{
              pathname: redirect.path,
              state: redirect.state,
            }}
          />
        )}
      </Col>
    </Row>
  );
}

const mapStateToProps = (state) => {
  return {
    language: state.language.language,
    countryUrl: state.language.countryUrl,
    countryId: state.language.countryId,
    auth: state.auth.isSignedIn,
  };
};

export default connect(mapStateToProps, {
  set_cart_items
})(CartOrderSummary);
