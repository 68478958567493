import React from "react";
import { useState } from "react";
import Login from "./Login";
import { Modal, Button } from "react-bootstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ClipLoader from "react-spinners/ClipLoader";
import { apiJson } from "../../Api";
import WebsiteHeader from "../header/WebsiteHeader";
import WebsiteFooter from "../Footer/WebsiteFooter";
import { Redirect, useLocation } from "react-router-dom";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { BsFillEyeFill, BsFillEyeSlashFill } from "react-icons/bs";

export default function ResetPassword() {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [currentPass, setCurrentPass] = useState("");
  const [newPass, setNewPass] = useState("");
  const [error, setError] = useState(false);
  const [changing, setChanging] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [showCurrPass, setShowCurrPass] = useState(false);
  const [showNewPass, setShowNewPass] = useState(false);
  let message = "";
  const location = useLocation();
  const reset_password_token = new URLSearchParams(location.search).get(
    "reset_password_token"
  );
  const showError = () => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const handleHide = () => {
    setRedirect(true);

    setOpen(!open);
    const timer = setTimeout(() => {
      setRedirect(false);
    }, 1000);
    return () => clearTimeout(timer);
  };

  const changePass = () => {
    setError(false);
    if (newPass.length > 5 && currentPass.length > 5) {
      setChanging(true);
      const form = new FormData();
      form.append("password", currentPass);
      form.append("password_confirmation", newPass);
      form.append("reset_password_token", reset_password_token);
      apiJson.auth.resetPass(form).then((response) => {
        setChanging(false);
        if (response.success) {
          setRedirect(true);

          setOpen(!open);
          const timer = setTimeout(() => {
            setRedirect(false);
          }, 1000);
          return () => clearTimeout(timer);
        } else if (!response.status) {
          message = response.message
            ? response.message !== ""
              ? response.message
              : t("errors.wrong")
            : t("errors.wrong");
          showError();
        } else {
          if (response.status >= 400 && response.status < 500) {
            message = response.message
              ? response.message
              : response.errors
              ? response.errors
              : t("errors.wrong");
            showError();
          } else {
            message = response.message
              ? response.message !== ""
                ? response.message
                : t("errors.wrong")
              : t("errors.wrong");
            showError();
          }
        }
      });
    } else {
      setError(true);
    }
  };
  useEffect(() => {
    document.title = "Reset Password";
  }, []);
  useEffect(() => {
    if (reset_password_token) {
      setOpen(true);
    } else {
      setRedirect(true);

      const timer = setTimeout(() => {
        setRedirect(false);
      }, 1000);
      return () => clearTimeout(timer);
    }
    // eslint-disable-next-line
  }, []);
  return (
    <div>
      <WebsiteHeader />
      <Login />
      <WebsiteFooter />
      <Modal show={open} className="mt-50" onHide={handleHide} centered>
        <Modal.Header
          style={{
            borderBottom: "none",
            justifyContent: "center",
            paddingBottom: "0px",
          }}
          className="forget-password-modal d-flex justify-content-center"
        >
          <Modal.Title
            className="header"
            style={{
              fontWeight: "bold",
              color: "rgb(65,69,82)",
              fontSize: "1.239575rem",
            }}
          >
            Reset Password
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="pl-3 pr-3 pt-4">
          <label
            className="mb-0"
            style={{
              color: "rgb(126, 133, 155)",
              fontSize: "0.875rem",
              fontWeight: "400",
            }}
          >
            New Password
          </label>
          <div
            className="input-group d-flex flex-direction-row justify-content-between mb-5 align-items-center"
            style={{ borderBottom: "1px solid rgb(226, 229, 241)" }}
          >
            <input
              type={showCurrPass ? "text" : "password"}
              value={currentPass}
              onChange={(e) => setCurrentPass(e.target.value)}
              className="input-with-icon w-90"
              style={{
                border: "0px",
                outline: "none",
                boxShadow: "none",
              }}
            />
            {showCurrPass ? (
              <BsFillEyeFill
                cursor="pointer"
                onClick={() => setShowCurrPass(!showCurrPass)}
                className="eye-icon"
                size="14px"
                color="rgb(126, 133, 155)"
              />
            ) : (
              <BsFillEyeSlashFill
                cursor="pointer"
                onClick={() => setShowCurrPass(!showCurrPass)}
                className="eye-icon"
                size="14px"
                color="rgb(126, 133, 155)"
              />
            )}
          </div>
          <label
            className="mb-0"
            style={{
              color: "rgb(126, 133, 155)",
              fontSize: "0.875rem",
              fontWeight: "400",
            }}
          >
            <span>Confirmation Password</span>
          </label>
          <div
            className="input-group d-flex flex-direction-row justify-content-between mb-5 align-items-center"
            style={{
              borderBottom: "1px solid rgb(226, 229, 241)",
              marginBottom: "60px",
            }}
          >
            <input
              type={showNewPass ? "text" : "password"}
              className="input-with-icon w-90"
              value={newPass}
              onChange={(e) => setNewPass(e.target.value)}
              style={{
                border: "0px",
                outline: "none",
                boxShadow: "none",
              }}
            />
            {showNewPass ? (
              <BsFillEyeFill
                cursor="pointer"
                onClick={() => setShowNewPass(!showNewPass)}
                size="14px"
                color="rgb(126, 133, 155)"
              />
            ) : (
              <BsFillEyeSlashFill
                cursor="pointer"
                onClick={() => setShowNewPass(!showNewPass)}
                size="14px"
                color="rgb(126, 133, 155)"
              />
            )}
          </div>
          {error && (
            <div className="error">Password length must be greater than 5</div>
          )}
        </Modal.Body>
        <Modal.Footer
          className="d-flex p-0"
          style={{ borderTopColor: "rgb(226, 229, 241)" }}
        >
          <Button
            onClick={handleHide}
            className="w-50 pt-3 pb-3 m-0 text-uppercase"
            style={{
              border: "none",
              backgroundColor: "white",
              color: "black",
              borderRight: "1px solid rgb(226, 229, 241)",
              fontSize: "1.09375rem",
              fontWeight: "600",
              outline: "none",
              boxShadow: "none",
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={changePass}
            className="w-50 pt-3 pb-3 m-0 text-uppercase"
            type="submit"
            style={{
              border: "none",
              backgroundColor: "white",
              color: "black",
              fontSize: "1.09375",
              fontWeight: "600",
              outline: "none",
              boxShadow: "none",
            }}
          >
            {changing ? (
              <div className="d-flex justify-content-center align-items-center w-100">
                <ClipLoader color="#000" loading size={20} />
              </div>
            ) : (
              "Ok"
            )}
          </Button>
        </Modal.Footer>
      </Modal>
      {redirect && <Redirect push to="/login" />}
    </div>
  );
}
