import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { connect } from "react-redux";
import { apiJson } from "../../Api";

import CustomerAddress from "../../components/mobile/CustomerAddress/CustomerAddress";
import LoginMobOption1 from "../../components/user-Auth-Option-1/LoginMobOption1";
import SignupMobOption1 from "../../components/user-Auth-Option-1/SignupMobOption1";
import MobileLayoutRenderer from "./layout/MobileLayoutRenderer";
import { Redirect } from "react-router-dom";
import { set_user } from "../../redux/actions/AuthAction";
import { set_loading } from "../../redux/actions/LayoutAction";
import HeaderMobile from "../../components/mobile/HeaderMobile";

function CustomerAddressPage(props) {
  const [signup, setSignup] = useState(false);
  const [data, setData] = useState(false);
  useEffect(() => {
    document.title = "Address";
    if (!props.auth) {
      props.set_loading(true);
      apiJson.cart.guestUser(props.countryId).then((response) => {
        props.set_loading(false);
        if (response.success) {
          props.set_user({
            guestUser: response.data.platform_settings.guest_user_checkout,
            guestSignin: response.data.platform_settings.guest_user_checkout
              ? false
              : !props.auth,
          });
          setData(true);
        } else {
          props.set_user({
            guestUser: response.data.platform_settings.guest_user_checkout,
            guestSignin: response.data.platform_settings.guest_user_checkout
              ? false
              : !props.auth,
          });
          setData(true);
        }
      });
    } else {
      setData(true);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      {window.location.pathname ===
        "/" + props.countryUrl + "-" + props.language + "/addresses" ||
        props.cart_items?.line_items?.length > 0 ? (
        <MobileLayoutRenderer
          NavbarTop={false}
          BackBtn={true}
          CancelBtn={false}
          NavbarBottom={false}
          SearchIcon={true}
        >
          <div className="home-mob">
            <HeaderMobile />
          </div>
          {data ? (
            props.auth || props.flag ? (
              <CustomerAddress />
            ) : signup ? (
              <SignupMobOption1
                signinClicked={(val) => setSignup(val)}
                address={true}
              />
            ) : (
              <LoginMobOption1
                clickSignup={(val) => setSignup(val)}
                address={true}
              />
            )
          ) : (
            <div className=""></div>
          )}
        </MobileLayoutRenderer>
      ) : (
        <Redirect
          to={"/" + props.countryUrl + "-" + props.language + "/cart"}
        />
      )}
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    countryId: state.language.countryId,
    auth: state.auth.isSignedIn,
    cart_items: state.cart.cart_items,
    language: state.language.language,
    countryUrl: state.language.countryUrl,
    flag: state.auth.guestUser,
  };
};

const mapDispatchToProps = { set_user, set_loading };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomerAddressPage);
