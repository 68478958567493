import React from "react";

import ShopPageMobile from "../../components/mobile/Home/ShopPageMobile";

function VendorMobilePage() {
  return (
    <div>
      <ShopPageMobile screen="vendor" />
    </div>
  );
}

export default VendorMobilePage;
