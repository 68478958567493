import React, { useState } from "react";
import { RiSearchLine } from "react-icons/ri";
import { set_loading } from "../../../redux/actions/LayoutAction";
import logo from "../../../assets/images/logo.png";
import { connect } from "react-redux";
import { set_vendor_data } from "../../../redux/actions/VendorShopAction";
import { toast } from "react-toastify";
import { Redirect, useParams } from "react-router-dom";
import MobileLayoutRenderer from "../../../pages/mobile/layout/MobileLayoutRenderer";
import { useTranslation } from "react-i18next";
import { set_country } from "../../../redux/actions/LayoutAction";
import { set_cart_items } from "../../../redux/actions/CartAction";
import { set_redirect_home } from "../../../redux/actions/NotFoundAction";
import "../../../assets/styles/component/mobile/Home/HomeMobile.scss";
import "react-toastify/dist/ReactToastify.css";
import pagesbannerimg from "../../../assets/images/footer-pages-banners/Consumer-Rights.jpg";
import UAEConsumerImg from "../../../assets/images/footer-page-images/UAE-Consumer.png";

function ConsumerRightsComp(props) {
  // eslint-disable-next-line
  const [modalShow, setModalShow] = React.useState(true);
  let message = "";
  // eslint-disable-next-line
  const { id } = useParams();
  // eslint-disable-next-line
  const { t, i18n } = useTranslation();
  const [redirect, setRedirect] = useState({ value: false });

  // eslint-disable-next-line
  const showError = () => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  // eslint-disable-next-line
  function getMobileOperatingSystem() {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
      return "Windows Phone";
    }

    if (/android/i.test(userAgent)) {
      return "Android";
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return "iOS";
    }

    return "unknown";
  }

  return (
    <MobileLayoutRenderer
      NavbarTop={false}
      BackBtn={false}
      CancelBtn={false}
      NavbarBottom={true}
    >
      <div className="home-mob">
        <div className="header">
          <div className="logo">
            <img src={logo} width="100%" height="100%" alt="" />
          </div>
          <div className="search">
            <RiSearchLine
              onClick={() =>
                setRedirect({ value: true, path: "all-categories" })
              }
            />
          </div>
        </div>

        <div className="body" style={{ background: "#F5F5F5" }}>
          <div className="pagesbanner">
            <img
              src={pagesbannerimg}
              alt="category"
              style={{
                width: "100%",
                height: "100%",
                maxHeight: "400px",
              }}
            ></img>
          </div>
          <div className="content-wrapper all-page-container">
            <div className="footer-pages-content">
              <img className="consumer-img" src={UAEConsumerImg} alt=""></img>
              <div
                className="consumer-content"
                style={{
                  listStyle: "none",
                }}
              >
                <p>
                  <b>Your Rights:</b>
                </p>
                <li>
                  1: You have the right to be informed of any personal data
                  which we may hold or process about you.
                </li>
                <li>
                  2: You have the right to require us to correct any
                  inaccuracies in your data free of charge.
                </li>
                <li>
                  3: You have the right to ask us to stop using your personal
                  data for direct marketing purposes.
                </li>
                <li>
                  4: You have the right to ask us to erase your personal data or
                  restrict the processing of your personal data.
                </li>
                <li>
                  5: You have the right to request us to transfer any personal
                  data which we may hold to you or any other service provider of
                  your choice.
                </li>
                <li>
                  6: You have the right to lodge a complaint with the
                  supervisory authority.
                </li>
                <li>7: You have the right to voice an opinion.</li>
                <li>8: You have the right to be educated as a consumer.</li>
                <li>9: You have the right to ensure your safety.</li>
                <li>
                  10: You have the right to express your opinion on the quality
                  of the goods, their services and availability.
                </li>

                <p>
                  Alisuq is a participant in the Digital Protection initiative,
                  dedicated to protecting your consumer rights and addressing
                  your concerns. For more information about consumer rights,
                  visit www.consumerrights.ae
                </p>
              </div>
            </div>
          </div>
        </div>
        {redirect.value && (
          <Redirect
            push
            to={{ pathname: redirect.path, state: redirect.state }}
          />
        )}
      </div>
    </MobileLayoutRenderer>
  );
}

const mapStateToProps = (state) => {
  return {
    // categories: state.home.categories,
    // products: state.vendor.products,
    // services: state.vendor.services,
    // sections: state.home.sections,
    // positionOne: state.home.positionOne,
    // positionTwo: state.home.positionTwo,
    // positionThree: state.home.positionThree,
    // positionFour: state.home.positionFour,
    // positionFive: state.home.positionFive,
    load: state.layout.loading,
    countryId: state.language.countryId,
    language: state.language.language,
    country: state.language.country,
    countryUrl: state.language.countryUrl,
    redirect: state.notFound.redirect,
  };
};

const mapDispatchToProps = {
  set_vendor_data,
  // set_home_categories,
  set_country,
  set_cart_items,
  set_redirect_home,
  set_loading,
};

export default connect(mapStateToProps, mapDispatchToProps)(ConsumerRightsComp);
