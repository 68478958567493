import React, { Fragment, useEffect, useState } from "react";
import "../../../assets/styles/component/mobile/CustomerAddress/CustomerAddress.scss";
import { set_loading } from "../../../redux/actions/LayoutAction";
import CustomerAddressBody from "./CustomerAddressBody";
import { set_address } from "../../../redux/actions/CustomerAddress";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { apiJson } from "../../../Api";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

function CustomerAddress(props) {
  const [data, setData] = useState(false);
  const { t } = useTranslation();
  const location = useLocation();

  let message = "";

  const showError = () => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  useEffect(() => {
    if (localStorage.getItem("access_token")) {
      props.set_loading(true);

      apiJson.select.getAddress().then((response) => {
        props.set_loading(false);

        setData(true);
        if (response.success) {
          props.set_address({
            address: response.data,
          });
        } else if (!response.status) {
          // eslint-disable-next-line
          message = t("errors.wrong");
          showError();
        } else {
          if (response.status >= 400 && response.status < 500) {
            message = response.message
              ? response.message
              : response.errors
                ? response.errors
                : t("errors.wrong");
            showError();
          } else {
            message = response.message
              ? response.message !== ""
                ? response.message
                : t("errors.wrong")
              : t("errors.wrong");
            showError();
          }
        }
      });
    } else {
      setData(true);
      if (localStorage.getItem("guest_user_address")) {
        var guest_user_address = JSON.parse(localStorage.getItem("guest_user_address"));
        props.set_address({
          address: [
            guest_user_address
          ],

          coupon_id: location.state?.coupon_id,
        });
      }
      else {
        props.set_address({
          address: [],

          coupon_id: location.state?.coupon_id,
        });
      }
    }
  }, []);

  return (
    <Fragment>
      {!data ? (
        <></>
      ) : (
        <div className="customer-address-mobile bg-white">
          <div className="w-100">
            <CustomerAddressBody />
          </div>
        </div>
      )}
    </Fragment>
  );
}

const mapStateToProps = (state) => {
  return {
    load: state.layout.loading,
  };
};

const mapDispatchToProps = {
  set_address,
  set_loading,
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomerAddress);
