import React, { Fragment, useEffect, useState } from "react";
import { apiJson } from "../../Api";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import WebsiteFooter from "../../components/Footer/WebsiteFooter";
import pagenamebanner from "../../assets/images/footer-pages-banners/brand-fvavourites.jpg";
import rustbanner from "../../assets/images/footer-page-images/Rustoleum-Banner.jpg";
import krudbanner from "../../assets/images/footer-page-images/Krud-Kutter-Banner.jpg";
import karcherbanner from "../../assets/images/footer-page-images/Karcher-Banner.jpg";
import WebsiteHeader from "../../components/header/WebsiteHeader";
import { set_cart_items } from "../../redux/actions/CartAction";
 import "../../assets/styles/footer-pages/brandfav.scss";
import ProductList from "../../components/product/ProductList";
import { set_loading } from "../../redux/actions/LayoutAction";
import $ from "jquery";

const BrandsFavourites = (props) => {
  const [productsInSlider1, setProductsInSlider1] = useState([]);
  const [productsInSlider2, setProductsInSlider2] = useState([]);
  const [productsInSlider3, setProductsInSlider3] = useState([]);

  let message = "";
  const showError = () => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  const { t } = useTranslation();

  useEffect(() => {
    props.set_loading(true);
    window.document.title = "Brand Favourites";
    $("html, body").animate({ scrollTop: 0 }, "fast");
    apiJson.homepage.getData(props.countryId).then(async (response) => {
      props.set_loading(false);
      if (response.success) {
        // eslint-disable-next-line
        response.data.sections.map((data) => {
          // eslint-disable-next-line
          if (data.title_en == "Brand Favourites 1") {
            setProductsInSlider1(data.products); // eslint-disable-next-line
          } else if (data.title_en == "Brand Favourites 2") {
            setProductsInSlider2(data.products); // eslint-disable-next-line
          } else if (data.title_en == "Brand Favourites 3") {
            setProductsInSlider3(data.products);
          }
          props.set_loading(false);
        });
      } else if (!response.status) {
        // eslint-disable-next-line
        message = response.message
          ? response.message !== ""
            ? response.message
            : t("errors.wrong")
          : t("errors.wrong");
        showError();
      } else {
        if (response.status >= 400 && response.status < 500) {
          message = response.message
            ? response.message
            : response.errors
            ? response.errors
            : t("errors.wrong");
          showError();
        } else {
          message = t("errors.wrong");
          showError();
        }
      }
    });
  }, []);

  return (
    <Fragment>
      <div className="product-home-page-desktop mx-auto">
        <WebsiteHeader />
        <div className="pagesbanner">
          <img
            src={pagenamebanner}
            alt="category"
            style={{
              width: "100%",
              height: "100%",
              maxHeight: "400px",
            }}
          />
        </div>

        <div className="product-home-page-desktop mx-auto">
          <div className="content-wrapper all-page-container">
            <div class="header">Rust-oleum Products</div>
            {/* SLIDER 1 */}
            <ProductList
              type="product"
              home={true}
              products={productsInSlider1}
            />

            <div class="content-wrapper all-page-container">
              <div
                style={{
                  marginTop: "50px",
                }}
              >
                {" "}
              </div>
              <div className="pagesbanner">
                <img
                  src={rustbanner}
                  alt="category"
                  style={{
                    width: "100%",
                    height: "100%",
                    maxHeight: "400px",
                  }}
                />
              </div>
            </div>
            <div class="header">Karcher Products</div>
            {/* SLIDER 2 */}
            <ProductList
              type="product"
              home={true}
              products={productsInSlider2}
            />
            <div class="content-wrapper all-page-container">
              <div className="pagesbanner">
                <img
                  src={karcherbanner}
                  alt="category"
                  style={{
                    width: "100%",
                    height: "100%",
                    maxHeight: "400px",
                  }}
                />
              </div>
            </div>
            <div class="header">Krud Kutter Products</div>
            {/* SLIDER 3 */}
            <ProductList
              type="product"
              home={true}
              products={productsInSlider3}
            />
            <div class="content-wrapper all-page-container">
              <div className="pagesbanner">
                <img
                  src={krudbanner}
                  alt="category"
                  style={{
                    width: "100%",
                    height: "100%",
                    maxHeight: "400px",
                  }}
                />
              </div>
            </div>
          </div>

          <div className="w-100">
            <WebsiteFooter />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    banners: state.home.secondaryBanners,
    countryId: state.language.countryId,
    country: state.language.country,
    recommendedForYou: state.home.recommendedForYou,
    diyProducts: state.home.diyProducts,
    paintersTouch: state.home.paintersTouch,
    krudKutter: state.home.krudKutter,
    sections: state.home.sections,
    positionOne: state.home.positionOne,
    positionTwo: state.home.positionTwo,
    positionThree: state.home.positionThree,
    positionFour: state.home.positionFour,
    positionFive: state.home.positionFive,
    language: state.language.language,
    countryUrl: state.language.countryUrl,
    redirect: state.notFound.redirect,
  };
};

const mapDispatchToProps = {
  // set_home_categories,
  // set_country,
  set_cart_items,
  // set_redirect_home,
  set_loading,
};

export default connect(mapStateToProps, mapDispatchToProps)(BrandsFavourites);
