import React, { useState, useEffect } from "react";
import Select from "react-select";
import { Modal } from "react-bootstrap";
import { connect } from "react-redux";

import "./createmodal.scss";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ClipLoader from "react-spinners/ClipLoader";
import { apiJson } from "../../Api";
import { Redirect } from "react-router-dom";
import { useTranslation } from "react-i18next";

const CreateModal = (props) => {
  const { t } = useTranslation();
  const [description, setDescription] = useState("");
  const [loading, setLoading] = useState(false);
  let message = "";
  const [redirect, setRedirect] = useState({ value: false });
  const [error, setError] = useState(false);
  const refundOptions_en = [
    { value: "Refund to wallet", label: "Refund to wallet" },
    { value: "Refund to bank account", label: "Refund to bank account" },
  ];
  const refundOptions_ar = [
    { value: "رد الأموال إلى المحفظة", label: "رد الأموال إلى المحفظة" },
    { value: "رد الأموال إلى حساب مصرفي", label: "رد الأموال إلى حساب مصرفي" },
  ];
  const [refundMethod, setRefundMethod] = useState(
    props.language === "en" ? refundOptions_en[0] : refundOptions_ar[0]
  );
  const showError = () => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  const showSuccess = () => {
    toast.success(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const Create = () => {
    setError(false);
    if (description.length > 10) {
      setLoading(true);
      props.name === "disputes"
        ? apiJson.disputes
            .createDispute({
              dispute: {
                order_id: props.item.order_id,
                line_item_id: props.item.id,
                vendor_id: props.item.vendor_id,
                title: "",
                description: description,
                role: "customer",
              },
              role: "customer",
            })
            .then((response) => {
              if (response.success) {
                setLoading(false);
                message = "Dispute created successfully.";
                showSuccess();
                setRedirect({
                  value: true,
                  path:
                    "/" + props.countryUrl + "-" + props.language + "/disputes",
                });
              } else if (!response.status) {
                setLoading(false);
                message = response.message
                  ? response.message !== ""
                    ? response.message
                    : t("errors.wrong")
                  : t("errors.wrong");
                showError();
              } else {
                setLoading(false);
                if (response.status >= 400 && response.status < 500) {
                  message = response.message
                    ? response.message
                    : response.errors
                    ? response.errors
                    : t("errors.wrong");
                  showError();
                } else {
                  message = response.message
                    ? response.message !== ""
                      ? response.message
                      : t("errors.wrong")
                    : t("errors.wrong");
                  showError();
                }
              }
            })
        : apiJson.returnItems
            .createRefund({
              refund: {
                reason: description,
                order_id: props.item.order_id,
                line_item_id: props.item.id,
                vendor_id: props.item.vendor_id,
                refund_method:
                  props.language === "ar"
                    ? refundMethod.value === refundOptions_ar[0].value
                      ? "wallet"
                      : "bank"
                    : refundMethod.value === refundOptions_en[0].value
                    ? "wallet"
                    : "bank",
              },
              address: {
                country: props.item.customer_address.country,
                state: props.item.customer_address.state,
                address1: props.item.customer_address.address1,
                contact_number: props.item.customer_address.contact_number,
              },
            })
            .then((response) => {
              if (response.success) {
                setLoading(false);
                message = "Refund created successfully.";
                showSuccess();
                setRedirect({
                  value: true,
                  path:
                    "/" + props.countryUrl + "-" + props.language + "/returns",
                });
              } else if (!response.status) {
                setLoading(false);
                message = response.message
                  ? response.message !== ""
                    ? response.message
                    : t("errors.wrong")
                  : t("errors.wrong");
                showError();
              } else {
                setLoading(false);
                if (response.status >= 400 && response.status < 500) {
                  message = response.message
                    ? response.message
                    : response.errors
                    ? response.errors
                    : t("errors.wrong");
                  showError();
                } else {
                  message = response.message
                    ? response.message !== ""
                      ? response.message
                      : t("errors.wrong")
                    : t("errors.wrong");
                  showError();
                }
              }
            });
    } else {
      setError(true);
    }
  };

  const handleClose = () => {
    props.close(false);
  };
  useEffect(() => {
    if (props.language?.toLowerCase() === "en") {
      if (refundMethod.value === refundOptions_ar[0].value) {
        setRefundMethod(refundOptions_en[0]);
      } else if (refundMethod.value === refundOptions_ar[1].value) {
        setRefundMethod(refundOptions_en[1]);
      }
    } else {
      if (refundMethod.value === refundOptions_en[0].value) {
        setRefundMethod(refundOptions_ar[0]);
      } else if (refundMethod.value === refundOptions_en[1].value) {
        setRefundMethod(refundOptions_ar[1]);
      }
    }
    // eslint-disable-next-line
  }, [props.language]);

  return (
    <Modal show={props.show} dialogClassName="mt-70" onHide={handleClose}>
      <Modal.Body>
        <div className="create-modal">
          <div className="header-row w-100">
            {props.name === "disputes"
              ? t("create-dispute.create-dispute")
              : t("create-dispute.create-refund")}
          </div>
          <div
            className="boday-row w-100"
            style={{
              direction: props.language.toLowerCase() === "en" ? "ltr" : "rtl",
            }}
          >
            <div className="input-group-row w-100 d-flex flex-column">
              <div className="input-row d-flex align-items-center w-100">
                <div className="label">{t("create-dispute.order-no")}</div>
                <div className="value">
                  <div className="select">{props.item.order_id}</div>
                </div>
              </div>
              <div className="input-row d-flex align-items-center w-100">
                <div className="label">{t("create-dispute.item")}</div>
                <div className="value">
                  <div className="select">
                    {(props.item.orderable[`product_name_${props.language}`]
                      ? props.item.orderable[`product_name_${props.language}`]
                      : "") +
                      "" +
                      props.item.orderable[`name_${props.language}`]}
                  </div>
                </div>
              </div>
              {props.name === "refund" && (
                <div className="input-row d-flex align-items-center w-100">
                  <div className="label">
                    {t("create-dispute.refund-method")}
                  </div>
                  <div className="value">
                    {props.item.payment_methods?.payment_card ? (
                      <div className="select">
                        <Select
                          value={refundMethod}
                          onChange={(value) => setRefundMethod(value)}
                          options={
                            props.language === "en"
                              ? refundOptions_en
                              : refundOptions_ar
                          }
                          isSearchable={false}
                        />
                      </div>
                    ) : (
                      refundMethod.value
                    )}
                  </div>
                </div>
              )}

              <div className="input-row description-row d-flex align-items-center w-100">
                <div className="label">
                  {props.name === "disputes"
                    ? t("create-dispute.description")
                    : t("create-dispute.reason")}
                  :
                </div>
                <div className="value">
                  <textarea
                    name="description"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    rows={5}
                    style={{ padding: "5px" }}
                  />
                  {error && (
                    <div className="error">
                      {props.name === "disputes" ? "Description " : "Reason "}
                      must be greater than 10 characters
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="buttons-row w-100 d-fex justify-content-start align-items-center">
              <button
                className="cancel-button"
                type="button"
                onClick={handleClose}
              >
                {t("create-dispute.cancel-btn")}
              </button>
              <button
                className="create-button btn"
                type="button"
                onClick={Create}
              >
                {loading ? (
                  <div className="d-flex justify-content-center align-items-center w-100">
                    <ClipLoader color="#fff" loading size={20} />
                  </div>
                ) : (
                  t("create-dispute.create-btn")
                )}
              </button>
            </div>
          </div>
        </div>
        {redirect.value && <Redirect push to={redirect.path} />}
      </Modal.Body>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return {
    countryUrl: state.language.countryUrl,
    language: state.language.language,
  };
};

export default connect(mapStateToProps, null)(CreateModal);
