import { type } from "../../types/types";
const initialState = {
  category: [],
  brand: [],
  rating: 0,
  seller: [],
  filter_by: [],
  sortBy_en: [],
  sortBy_ar: [],
  display_en: [],
  display_ar: [],
  selectedCat: "",
  minPrice: "0",
  maxPrice: "9999999",
  sortVal: "",
  displayVal: "",
  items: [],
  pagination: [],
  originalItems: [],
  page: 1,
  recordPerPage: 30,
  price_high_to_low: false,
  price_low_to_high: false,
  discount_sort: true,
  new_arrivals: false,
  selectedBrands: [],
  selectedStores: [],
  mobileFiltersCache: [],
  go: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case type.SET_ALL_ITEMS:
      return {
        ...state,
        ...action.items,
      };
    default:
      return state;
  }
}
