import React, { useEffect, useState } from "react";
import Row from "react-bootstrap/Row";

import "../../../assets/styles/customer/account/general.scss";
import "../../../assets/styles/pages/walletpage.scss";
import WebsiteHeader from "../../../components/header/WebsiteHeader";
import Sidebar from "../../../components/customer/account/Sidebar";
import Wallet from "../../../components/customer/account/Wallet";
import { apiJson } from "../../../Api";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { connect } from "react-redux";
import {
  set_wallet_credits,
  set_wallet_tranfers,
} from "../../../redux/actions/WalletAction";
import { set_loading } from "../../../redux/actions/LayoutAction";
import { useTranslation } from "react-i18next";

const WalletPage = (props) => {
  const [data, setData] = useState(false);
  const [balance, setBalance] = useState(0);
  const [currency, setCurrency] = useState("");
  const { t } = useTranslation();

  let message = "";

  const showError = () => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  useEffect(() => {
    document.title = "Wallet";
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    props.set_loading(true);

    apiJson.wallet.getCredit(props.countryId).then((response) => {
      props.set_loading(false);
      if (response.success) {
        props.set_wallet_credits({ credit: response.data });
        setData(true);
      } else if (!response.status) {
        setData(true);
        // eslint-disable-next-line
        message = response.message
          ? response.message !== ""
            ? response.message
            : t("errors.wrong")
          : t("errors.wrong");
        showError();
      } else {
        setData(true);
        if (response.status >= 400 && response.status < 500) {
          message = response.message
            ? response.message
            : response.errors
            ? response.errors
            : t("errors.wrong");
          showError();
        } else {
          message = response.message
            ? response.message !== ""
              ? response.message
              : t("errors.wrong")
            : t("errors.wrong");
          showError();
        }
      }
    });
    apiJson.checkout.walletPayment(props.countryId).then((response) => {
      if (response.success) {
        setBalance(response.data.amount);
        setCurrency(response.data.currency);
      } else if (!response.status) {
        message = response.message
          ? response.message !== ""
            ? response.message
            : t("errors.wrong")
          : t("errors.wrong");
        showError();
      } else {
        if (response.status >= 400 && response.status < 500) {
          message = response.message
            ? response.message
            : response.errors
            ? response.errors
            : t("errors.wrong");
          showError();
        } else {
          message = response.message
            ? response.message !== ""
              ? response.message
              : t("errors.wrong")
            : t("errors.wrong");
          showError();
        }
      }
    });
  }, []);

  return (
    <Row className="page-container no-gutters">
      <div className="w-100">
        <WebsiteHeader />

        <div className="content-container all-page-container d-flex justify-content-center mx-auto">
          <div className="left-pane">
            <Sidebar />
          </div>
          <div className="right-pane">
            {data ? (
              <Wallet balance={balance} currency={currency} />
            ) : (
              <div className="d-flex justify-content-center align-items-center w-100 h-100"></div>
            )}
          </div>
        </div>
      </div>
    </Row>
  );
};
const mapStateToProps = (state) => {
  return { countryId: state.language.countryId };
};

const mapDispatchToProps = {
  set_wallet_tranfers,
  set_wallet_credits,
  set_loading,
};

export default connect(mapStateToProps, mapDispatchToProps)(WalletPage);
