import React, { Fragment, useState } from "react";

import "../../assets/styles/customer/payment.scss";
import MasterCard from "../../assets/icons/card_2.png";
import VisaCard from "../../assets/icons/visaCard.png";
import Switch from "react-switch";
import { MdDelete } from "react-icons/md";
import { connect } from "react-redux";
import moment from "moment";
import { apiJson } from "../../Api";
import ClipLoader from "react-spinners/ClipLoader";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { set_payment_data } from "../../redux/actions/PaymentAction";
import { useTranslation } from "react-i18next";

const Payment = (props) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState({ value: false, id: -1 });
  const [def, setDef] = useState({ value: false, id: -1 });
  let message = "";

  const showError = () => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const deleteCard = (id) => {
    setLoading({ value: true, id: id });
    apiJson.payments.deleteCard(id).then((response) => {
      setLoading({ value: false, id: id });
      if (response.success) {
        props.set_payment_data({
          payment: props.payment.filter((ele) => ele.id !== id),
        });
      } else if (!response.status) {
        message = t("errors.wrong");
        showError();
      } else {
        if (response.status >= 400 && response.status < 500) {
          message = response.message
            ? response.message
            : response.errors
            ? response.errors
            : t("errors.wrong");
          showError();
        } else {
          message = response.message
            ? response.message !== ""
              ? response.message
              : t("errors.wrong")
            : t("errors.wrong");
          showError();
        }
      }
    });
  };

  const setDefault = (id) => {
    setDef({ value: true, id });
    apiJson.payments.setDefault(id).then((response) => {
      setDef({ value: false, id: -1 });
      if (response.success) {
        props.set_payment_data({
          payment: props.payment.map((ele) =>
            ele.id === id
              ? { ...ele, default: !ele.default }
              : ele.default
              ? { ...ele, default: false }
              : ele
          ),
        });
      } else if (!response.status) {
        message = t("errors.wrong");
        showError();
      } else {
        if (response.status >= 400 && response.status < 500) {
          message = response.message
            ? response.message
            : response.errors
            ? response.errors
            : t("errors.wrong");
          showError();
        } else {
          message = response.message
            ? response.message !== ""
              ? response.message
              : t("errors.wrong")
            : t("errors.wrong");
          showError();
        }
      }
    });
  };

  return (
    <Fragment>
      <div className="customer-payment-desktop">
        <div className="payment-header">
          {props.language === "ar" ? (
            <>
              <h3 style={{ textAlign: "initial" }}>
                {t("payment.data.payments")}
              </h3>
              <p style={{ textAlign: "initial" }} className="gray">
                {t("payment.data.manage-card")}
              </p>
            </>
          ) : (
            <>
              <h3>{t("payment.data.payments")}</h3>
              <p className="gray">{t("payment.data.manage-card")}</p>
            </>
          )}
        </div>
        {props.payment?.map((data) => (
          <div key={data.id} className="mb-5 payment-card mx-auto">
            <div className="card-info">
              <div className="card-number-detail d-flex justify-content-between align-items-center">
                <img
                  src={
                    data?.payment_card_type === "Visa"
                      ? VisaCard
                      : data?.payment_card_type === "MasterCard"
                      ? MasterCard
                      : MasterCard
                  }
                  alt="mastercard-logo"
                  width="50px"
                  height="35px"
                />
                <div className="card-number">
                  **** **** ****{" "}
                  {data.card_number.substr(
                    data.card_number.length - 3,
                    data.card_number
                  )}
                </div>
              </div>
              <div className="card-expirey-detail d-flex justify-content-between align-items-center">
                <div className="card-expirey-date">
                  <span className="label">{t("payment.data.expiry")}</span>
                  <span className="date">
                    {moment(
                      new Date(
                        data.expiry_date.split("/")[0] +
                          "/01/" +
                          data.expiry_date.split("/")[1]
                      )
                    ).format("LL")}
                  </span>
                </div>
                <div className="default-card d-flex align-items-center">
                  <span className="label-text">
                    {t("payment.data.default-payment")}
                  </span>
                  <Switch
                    height={22}
                    width={45}
                    onColor="#E3E9F7"
                    onHandleColor="#3866DE"
                    onChange={() => setDefault(data.id)}
                    checked={data.default}
                    uncheckedIcon={false}
                    checkedIcon={false}
                  />
                </div>
              </div>
              {def.value && def.id === data.id && (
                <div className="d-flex justify-content-end align-items-center w-100">
                  <ClipLoader color="#000" loading size={20} />
                </div>
              )}
            </div>
            <div className="delete-card d-flex">
              <button
                onClick={(e) => {
                  e.preventDefault();
                  deleteCard(data.id);
                }}
                type="button"
                className="delete-button mx-auto"
              >
                <MdDelete />{" "}
                {loading.value && loading.id === data.id ? (
                  <div className="d-flex justify-content-center align-items-center w-100">
                    <ClipLoader color="#000" loading size={20} />
                  </div>
                ) : (
                  "Delete"
                )}
              </button>
            </div>
          </div>
        ))}
      </div>
    </Fragment>
  );
};
const mapStateToProps = (state) => {
  return {
    payment: state.payment.payment,
    countryUrl: state.language.countryUrl,
    language: state.language.language,
  };
};

const mapDispatchToProps = {
  set_payment_data,
};

export default connect(mapStateToProps, mapDispatchToProps)(Payment);
