import React, { Fragment } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { ReactComponent as CART_EMPTY_STATE } from "../../../assets/images/Cart/Cart-Empty/empty_cart_art.svg";
import "./../../../assets/styles/customer/cart/cartemptypage.scss";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

const CartEmptyPage = (props) => {
  const { t } = useTranslation();
  return (
    <Fragment>
      <div className="container cart-empty-Page-desktop">
        <Row>
          <Col md={12}>
            <div className="justify-content-center">
              <div className=" cartempty-card-area">
                <CART_EMPTY_STATE />
                <h5 className="cart-text cart-head-text">
                  {t("cart-page.empty.empty-cart")}
                </h5>
                <div className="cart-text cart-sub-text">
                  {t("cart-page.empty.sub-text")}
                </div>
                <Link to={"/" + props.countryUrl + "-" + props.language + "/"}>
                  <button className="cart-continueshopping-button">
                    {t("cart-page.empty.continue-shop-btn")}
                  </button>
                </Link>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    language: state.language.language,
    countryUrl: state.language.countryUrl,
  };
};
export default connect(mapStateToProps, null)(CartEmptyPage);
