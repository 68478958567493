import React, { useState } from "react";
import { Fragment } from "react";
import { connect } from "react-redux";

import "../../../../assets/styles/customer/account/CustomerProfileMap/CustomerProfileMap.scss";
import GoogleMaps from "../../GoogleMaps.js";
import { set_addresses } from "../../../../redux/actions/SelectAddressAction";
import { Link, Redirect, useLocation } from "react-router-dom";

const CustomerProfileMap = (props) => {
  const [active, setActive] = useState(false);
  const [redirectPage, setRedirectPage] = useState(false);
  const location = useLocation();

  return (
    <Fragment>
      <div className="profile-map-desktop">
        <div className="row map-row no-gutters mx-auto">
          <div className={"adress-col col-10 align-items-center"}>
            <div className="label">Add a New Address</div>
          </div>
          <div className="map-col col-10 align-items-center">
            <GoogleMaps
              Address={(val) => props.set_addresses({ mapAddress: val })}
              active={(val) => {
                setActive(val);
              }}
              address={!location.state ? undefined : location.state.data}
            />
          </div>
          <div className="confirm-location-col col-12 d-flex align-items-center justify-content-start">
            <button
              disabled={!active}
              type="button"
              onClick={(e) => {
                e.preventDefault();
                setRedirectPage(true);
              }}
              className={`location-button  ${
                active
                  ? "location-background-active"
                  : "location-background-inactive"
              }`}
            >
              CONFIRM LOCATION
            </button>
            <Link
              to={"/" + props.countryUrl + "-" + props.language + "/addresses"}
            >
              <button type="button" className=" ml-3 back-button">
                BACK
              </button>
            </Link>
          </div>
        </div>
      </div>
      {redirectPage && (
        <Redirect
          push
          to={{
            pathname:
              "/" + props.countryUrl + "-" + props.language + "/address",
            state: {
              name: "customer",
              data: !location.state ? undefined : location.state.data,
            },
          }}
        />
      )}
    </Fragment>
  );
};
const mapStateToProps = (state) => {
  return {
    countryUrl: state.language.countryUrl,
    language: state.language.language,
  };
};
const mapDispatchToProps = { set_addresses };

export default connect(mapStateToProps, mapDispatchToProps)(CustomerProfileMap);
