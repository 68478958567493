import React, { useState } from "react";

import { FaMapMarkedAlt, FaCheckCircle } from "react-icons/fa";
import { HiDotsHorizontal } from "react-icons/hi";
import { apiJson } from "../../Api";
import "../../assets/styles/customer/customeraddress.scss";
import "../../assets/styles/utils.scss";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ClipLoader from "react-spinners/ClipLoader";
import { set_addresses } from "../../redux/actions/SelectAddressAction";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

const CustomerAddress = (props) => {
  const [settingDefault, setSettingDefault] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  let message = "";
  const { t } = useTranslation();

  const showError = () => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const setDefault = () => {
    if (localStorage.getItem("access_token")) {
      setSettingDefault(true);
      apiJson.select.setDefault(props.id).then((response) => {
        if (response.success) {
          props.set_addresses({
            address: props.user_address.map((item) =>
              item.id === response.data.id
                ? { ...item, default: true }
                : item.default
                  ? { ...item, default: false }
                  : item
            ),
          });
          setSettingDefault(false);
        } else if (!response.status) {
          setSettingDefault(false);
          message = t("errors.wrong");
          showError();
        } else {
          setSettingDefault(false);
          if (response.status >= 400 && response.status < 500) {
            message = response.message
              ? response.message
              : response.errors
                ? response.errors
                : t("errors.wrong");
            showError();
          } else {
            message = response.message
              ? response.message !== ""
                ? response.message
                : t("errors.wrong")
              : t("errors.wrong");
            showError();
          }
        }
      });
    }
    else {
      if (localStorage.getItem("guest_user_address")) {
        var guest_user_address = JSON.parse(localStorage.getItem("guest_user_address"));

        props.set_addresses({
          address: props.user_address.map((item) =>
            item.id === guest_user_address.id
              ? { ...item, default: true }
              : item.default
                ? { ...item, default: false }
                : item
          ),
        });
      }
    }
  };

  const deleteAddress = () => {
    if (window.confirm("Are you sure you want to delete this Address")) {
      setDeleting(true);
      apiJson.select.deleteAddress(props.id).then((response) => {
        if (response.success) {
          props.set_addresses({
            address: props.user_address.filter((item) => item.id !== props.id),
          });
          setDeleting(false);
        } else if (!response.status) {
          setDeleting(false);
          message = t("errors.wrong");
          showError();
        } else {
          setDeleting(false);
          if (response.status >= 400 && response.status < 500) {
            message = response.message ? response.message : "Not Found";
            showError();
          } else {
            message = t("errors.wrong");
            showError();
          }
        }
      });
    }
  };
  return (
    <div
      onClick={() => setDefault()}
      className={`${props.isPrimary === true
          ? "primary-address-col"
          : "non-primary-address-col"
        } customer-address`}
    >
      {props.isPrimary === true ? (
        <div className="header">
          {t("customer-address-shipping.primary-address")}
        </div>
      ) : (
        ""
      )}
      <div className="content">
        <div
          className={`details ${props.isPrimary === true ? "primary-address" : ""
            } d-flex justify-content-between`}
        >
          <div className="heading d-flex">
            <div className="label gray-text mr-2">{props.label}</div>
            <FaMapMarkedAlt className="gray-text me-1" />
          </div>
          {
            localStorage.getItem("access-token") ?
              <div
                onMouseEnter={(e) => {
                  e.stopPropagation();
                  setOpenMenu(!openMenu);
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  setOpenMenu(!openMenu);
                }}
                className="pointer menu"
              >
                <HiDotsHorizontal className="dots fw-600" />
              </div>
              :
              <></>
          }
        </div>
        {openMenu && (
          <div className={`menu-options-${props.language}`}>
            {deleting ? (
              <div className="d-flex justify-content-center align-items-center w-100">
                <ClipLoader color="#000" loading size={20} />
              </div>
            ) : (
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  deleteAddress();
                }}
                className="pointer text-style"
              >
                {t("address-dektop.delete")}
              </div>
            )}
          </div>
        )}
        {settingDefault && (
          <div className="d-flex justify-content-center align-items-center w-100">
            <ClipLoader color="#000" loading size={20} />
          </div>
        )}
        <div className="name">
          <div className="name-list d-flex ">
            <div className="label gray-text">
              {t("customer-address-shipping.name")}
            </div>
            <div className="value fw-500">{props.name}</div>
          </div>
        </div>
        <div className="address">
          <div className="address-list d-flex justify-content-between">
            <div className="label gray-text">
              {t("customer-address-shipping.address")}
            </div>
            <div className="value" data-toggle="tooltip" title={props.address}>
              {props.address}
            </div>
          </div>
        </div>
        <div className="phone-number">
          <div className="phone-list d-flex justify-content-between">
            <div className="label">
              {t("customer-address-shipping.phone-numb")}
            </div>
            <div className="value d-flex">
              <div className="number">{props.phone}</div>
              {props.verified && <FaCheckCircle />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user_address: state.select.address,
    direction: state.language.direction,
    language: state.language.language,
  };
};

const mapDispatchToProps = { set_addresses };
export default connect(mapStateToProps, mapDispatchToProps)(CustomerAddress);
