import React, { useState } from "react";

import "../../../assets/styles/component/customer/itemDetail/ImageGallery.scss";
import Rating from "@material-ui/lab/Rating";

import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

function ImageGalleryMobile(props) {
  const { t } = useTranslation();

  useEffect(() => {
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <div className="image-gallery">
      {
        props.images ?
          <>
            <h6 className={"heading " + (props.language == "en" ? "text-left" : "text-right")}>{t("item-detail.product-image-gallery")}</h6>
            {
              props.images.map(img => {
                return (
                  <div className="mt-1 mb-2">
                    <img src={img.img} className="img img-fluid" />
                  </div>
                )
              })
            }
          </>
          :
          <></>
      }
      {
        props.video ?
          <>
            <div className="col-12 p-0">
              <h6 className="heading">Product Video</h6>
              <div
                className="mb-1 innerhtml video-div"
                dangerouslySetInnerHTML={{
                  __html: props.video,
                }}
              ></div>
            </div>
          </>
          :
          <></>
      }
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    width: state.layout.screenWidth,
    language: state.language.language,
  };
};

export default connect(mapStateToProps, null)(ImageGalleryMobile);
