import React, { Fragment } from "react";
import { Row } from "reactstrap";

import "../../assets/styles/product/working.scss";
import WorkingImg from "../../assets/images/Product_Home/Group 842@2x.png";
const Working = (props) => {
  return (
    <Fragment>
      <div className="working-container mx-auto">
        <Row className="working-description-row mx-auto">
          <img
            src={WorkingImg}
            alt="working_image"
            width="100%"
            height="auto"
          />
        </Row>
      </div>
    </Fragment>
  );
};

export default Working;
