import React, { useState, useEffect } from "react";
import "../../assets/styles/mobile/disputedetailspagemob.scss";
import ProductDetailCard from "../../components/mobile/shared/ProductDetailCard";
import DisputeOrderDESC from "../../components/mobile/customer/DisputeOrderDESC";
import MobileLayoutRenderer from "./layout/MobileLayoutRenderer";
import EmptyDisputes from "../../assets/images/dispute_art.png";
import { useTranslation } from "react-i18next";
import { Link, Redirect } from "react-router-dom";
import { toast } from "react-toastify";
import { set_loading } from "../../redux/actions/LayoutAction";
import { apiJson } from "../../Api";
import { connect } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import { getMobileImage } from "../../utils/utils";
import Pagination from "react-paginate";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import HeaderMobile from "../../components/mobile/HeaderMobile";

const DisputeDeatilsPageMob = (props) => {
  const { t } = useTranslation();
  const [data, setData] = useState(false);
  const [disputeData, setDisputesData] = useState([]);
  const [redirect, setRedirect] = useState({ value: false });
  const [pagination, setPagination] = useState({});

  let message = "";
  const showError = () => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const getDisputes = (page) => {
    window.scrollTo(0, 0);
    props.set_loading(true);
    apiJson.disputes.getData(page).then((response) => {
      props.set_loading(false);
      if (response.success) {
        setDisputesData(response.data.disputes);
        setPagination(response.data.pagination);
        setData(true);
      } else if (!response.status) {
        message = response.message
          ? response.message !== ""
            ? response.message
            : t("errors.wrong")
          : t("errors.wrong");
        showError();
      } else {
        if (response.status >= 400 && response.status < 500) {
          message = response.message
            ? response.message
            : response.errors
              ? response.errors
              : t("errors.wrong");
          showError();
        } else {
          message = response.message
            ? response.message !== ""
              ? response.message
              : t("errors.wrong")
            : t("errors.wrong");
          showError();
        }
      }
    });
  };

  const handlePageChange = (pageNumber) => {
    getDisputes(pageNumber.selected + 1);
  };

  useEffect(() => {
    document.title = "Disputes";
    getDisputes(1);
    // eslint-disable-next-line
  }, []);

  return (
    <MobileLayoutRenderer
      NavbarTop={false}
      BackBtn={true}
      CancelBtn={false}
      NavbarBottom={false}
      SearchIcon={true}
    >
      <div className="home-mob">
        <HeaderMobile />
      </div>
      <>
        <div className="dispute-details-page-mobile-container h-100">
          {!data ? (
            <></>
          ) : disputeData.length === 0 ? (
            <div className="empty-disputes-page">
              <div className="img-cont">
                <img
                  src={EmptyDisputes}
                  className="img-fluid"
                  width="100%"
                  height="100%"
                  alt="emptydispute"
                />
              </div>
              <div className="cart-text dispute-empty-head-text">
                {t("dispute-detail-page-mob.empty.no-dispute-requested")}
              </div>
              <div className="cart-text dispute-empty-sub-text">
                {t("dispute-detail-page-mob.empty.submit-new-text")}
              </div>
              <br />

              {/* <button
                onClick={() =>
                  history.push(
                    "/" +
                      props.countryUrl +
                      "-" +
                      props.language +
                      "/order/itemslist/disputes"
                  )
                }
                className="file-a-dispute-button"
              >
                {t("dispute-detail-page-mob.file-new-btn")}
              </button> */}
            </div>
          ) : (
            <>
              {disputeData.map((dispute) => (
                <React.Fragment key={dispute.id}>
                  <div className="header py-3 mt-3 px-3" key={dispute.id}>
                    <div className="id-row text-uppercase">
                      {t("dispute-detail-page-mob.dispute-id")} {dispute.id}
                    </div>
                  </div>
                  <div
                    onClick={() =>
                      setRedirect({
                        value: true,
                        path:
                          "/" +
                          props.countryUrl +
                          "-" +
                          props.language +
                          `/dispute/detail/${dispute.id}`,
                      })
                    }
                  >
                    <div className="dispute-order-description">
                      <DisputeOrderDESC
                        disputeNum={dispute.id}
                        StoreName={dispute[`store_name_${props.language}`]}
                        CreatedAt={dispute.created_at}
                        OrderNum={dispute.order_id}
                        CustomerEmail={dispute.customer_email}
                        Status={dispute.status}
                      />
                    </div>

                    <ProductDetailCard
                      name={dispute[`customer_name_${props.language}`]}
                      brand={dispute[`store_name_${props.language}`]}
                      price={dispute.price}
                      image_path={getMobileImage(
                        dispute.featured_image_resized,
                        dispute.featured_image
                      )}
                      currency={dispute.currency}
                    />
                  </div>
                </React.Fragment>
              ))}
            </>
          )}

          {pagination?.total_pages > 1 && disputeData?.length > 0 && (
            <div
              className="d-flex justify-content-center mb-4 mt-5 w-100"
              style={{ direction: "ltr" }}
            >
              <Pagination
                direction="ltr"
                previousLabel={<FaChevronLeft color="#404553" />}
                nextLabel={<FaChevronRight color="#404553" />}
                pageCount={parseInt(pagination?.total_pages)}
                forcePage={parseInt(pagination?.page) - 1}
                onPageChange={handlePageChange}
                pageRangeDisplayed={props.width === "sm" ? 1 : 2}
                marginPagesDisplayed={props.width === "sm" ? 1 : 2}
                containerClassName="pagination"
                pageClassName="pl-3 pr-3 pt-2 pb-2"
                activeClassName={
                  "ml-3 mr-3 text-primary border border-primary font-weight-bold rounded bg-white"
                }
                nextClassName="bg-white pl-3 pr-3 pt-2 pb-2"
                previousClassName="bg-white pl-3 pr-3 pt-2 pb-2"
              />
            </div>
          )}
        </div>

        <div className="bottom justify-content-center">
          <Link
            to={
              "/" +
              props.countryUrl +
              "-" +
              props.language +
              "/order/itemslist/disputes"
            }
          >
            <div className="file-new-dispute-btn">
              {" "}
              {t("dispute-detail-page-mob.file-new-btn")}
            </div>
          </Link>
        </div>
      </>

      {redirect.value && <Redirect push to={redirect.path} />}
    </MobileLayoutRenderer>
  );
};
const mapStateToProps = (state) => {
  return {
    countryUrl: state.language.countryUrl,
    language: state.language.language,
    load: state.layout.loading,
  };
};

const mapDispatchToProps = {
  set_loading,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DisputeDeatilsPageMob);
