import React, { useState } from "react";
import { RiSearchLine } from "react-icons/ri";
import { set_loading } from "../../../redux/actions/LayoutAction";
import logo from "../../../assets/images/logo.png";
import { connect } from "react-redux";
import { set_vendor_data } from "../../../redux/actions/VendorShopAction";
import { toast } from "react-toastify";
import { Redirect, useParams } from "react-router-dom";
import MobileLayoutRenderer from "../../../pages/mobile/layout/MobileLayoutRenderer";
import { useTranslation } from "react-i18next";
import { set_country } from "../../../redux/actions/LayoutAction";
import { set_cart_items } from "../../../redux/actions/CartAction";
import { set_redirect_home } from "../../../redux/actions/NotFoundAction";
import "../../../assets/styles/component/mobile/Home/HomeMobile.scss";
import "react-toastify/dist/ReactToastify.css";
import pagesbannerimg from "../../../assets/images/footer-pages-banners/Bulk-Enquiries.jpg";
import bulkorderimg from "../../../assets/images/footer-page-images/a.png";

function BulkOrderComp(props) {
  // eslint-disable-next-line
  const [modalShow, setModalShow] = React.useState(true);
  let message = "";
  // eslint-disable-next-line
  const { id } = useParams();
  // eslint-disable-next-line
  const { t, i18n } = useTranslation();
  const [redirect, setRedirect] = useState({ value: false });
  // eslint-disable-next-line
  const showError = () => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  // eslint-disable-next-line
  function getMobileOperatingSystem() {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
      return "Windows Phone";
    }

    if (/android/i.test(userAgent)) {
      return "Android";
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return "iOS";
    }

    return "unknown";
  }

  return (
    <MobileLayoutRenderer
      NavbarTop={false}
      BackBtn={false}
      CancelBtn={false}
      NavbarBottom={true}
    >
      <div className="home-mob">
        <div className="header">
          <div className="logo">
            <img src={logo} width="100%" height="100%" alt="" />
          </div>
          <div className="search">
            <RiSearchLine
              onClick={() =>
                setRedirect({ value: true, path: "all-categories" })
              }
            />
          </div>
        </div>

        <div className="body" style={{ background: "#F5F5F5" }}>
          <div className="pagesbanner">
            <img
              src={pagesbannerimg}
              alt="category"
              style={{
                width: "100%",
                height: "100%",
                maxHeight: "400px",
              }}
            ></img>
          </div>
          <div className="content-wrapper all-page-container">
            <div className="footer-pages-content">
              <h4 className="footer-page-heading">
                Bulk order for BIG SAVINGS{" "}
              </h4>

              <p>
                A bulk purchase can be a great way to save money. With our range
                of products, exclusive prices, personalized service, and
                flexible delivery options, we offer a wide range of options. Our
                products are meant to be used in a variety of ways such as
                Outdoor Lifestyle, Gardening, Homeware, Paint & Décor, DIY &
                Tools, Automotive, Construction & more. To know more, get in
                touch with us at ales@alisuq.com{" "}
              </p>

              <h5 className="footer-page-subheading blko">
                A place to find bulk solutions
              </h5>
              <span class="line">
                <h2 className="footer-page-subheading">CATEGORIES</h2>
              </span>

              <div className="bulkcat">
                <img
                  className="bulkcatimg"
                  src={bulkorderimg}
                  alt="category"
                ></img>
                <img
                  className="bulkcatimg"
                  src={bulkorderimg}
                  alt="category"
                ></img>
                <img
                  className="bulkcatimg"
                  src={bulkorderimg}
                  alt="category"
                ></img>
                <img
                  className="bulkcatimg"
                  src={bulkorderimg}
                  alt="category"
                ></img>
              </div>
            </div>
          </div>
        </div>
        {redirect.value && (
          <Redirect
            push
            to={{ pathname: redirect.path, state: redirect.state }}
          />
        )}
      </div>
    </MobileLayoutRenderer>
  );
}

const mapStateToProps = (state) => {
  return {
    // categories: state.home.categories,
    // products: state.vendor.products,
    // services: state.vendor.services,
    // sections: state.home.sections,
    // positionOne: state.home.positionOne,
    // positionTwo: state.home.positionTwo,
    // positionThree: state.home.positionThree,
    // positionFour: state.home.positionFour,
    // positionFive: state.home.positionFive,
    load: state.layout.loading,
    countryId: state.language.countryId,
    language: state.language.language,
    country: state.language.country,
    countryUrl: state.language.countryUrl,
    redirect: state.notFound.redirect,
  };
};

const mapDispatchToProps = {
  set_vendor_data,
  // set_home_categories,
  set_country,
  set_cart_items,
  set_redirect_home,
  set_loading,
};

export default connect(mapStateToProps, mapDispatchToProps)(BulkOrderComp);
