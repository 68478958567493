import React, { Fragment, useState, useEffect } from "react";
import { IoStorefront } from "react-icons/io5";
import { VscVerified } from "react-icons/vsc";
import { FiChevronLeft } from "react-icons/fi";
import { format } from "date-fns";
import { withStyles } from "@material-ui/core/styles";
import Rating from "@material-ui/lab/Rating";

import "../../../assets/styles/customer/account/review.scss";
import { BsStar, BsStarFill } from "react-icons/bs";
import { Link, Redirect, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ClipLoader from "react-spinners/ClipLoader";
import { connect } from "react-redux";
import { apiJson } from "../../../Api";
import { useTranslation } from "react-i18next";
import { set_loading } from "../../../redux/actions/LayoutAction";
import { getDesktopImage } from "../../../utils/utils";

const StyledRating = withStyles({
  iconFilled: {
    color: "#FFFF00",
    fontSize: "32px",
  },
  icon: {
    fontSize: "32px",
  },
  emptyIcon: {
    fontSize: "32px",
  },
})(Rating);

const Review = (props) => {
  const { t } = useTranslation();
  const [value, setValue] = useState(5);
  const [feedback, setFeedback] = useState("");
  const [item, setItem] = useState(undefined);
  const param = useParams();
  const [redirect, setRedirect] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleChange = (rate) => {
    setValue(parseInt(rate.target.value));
  };
  let message = "";
  const showError = () => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  const showSuccess = () => {
    toast.success(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  const buttonDisable = feedback.length <= 0 ? true : false;

  const createReview = (e) => {
    e.preventDefault();
    setLoading(true);
    apiJson.review
      .addReview({
        review: {
          rating: value,
          title: "",
          description: feedback,
          reviewable_type: item.orderable_type,
          reviewable_id:
            item.orderable_type === "Product"
              ? item.orderable.product_id
              : item.orderable.id,
        },
      })
      .then((response) => {
        setLoading(false);
        if (response.success) {
          message = "Review successfully added!";
          showSuccess();
          setRedirect(true);
        } else if (!response.status) {
          message = response.message
            ? response.message !== ""
              ? response.message
              : t("errors.wrong")
            : t("errors.wrong");
          showError();
        } else {
          if (response.status >= 400 && response.status < 500) {
            message = response.message
              ? response.message
              : response.errors
              ? response.errors
              : t("errors.wrong");
            showError();
          } else {
            message = response.message
              ? response.message !== ""
                ? response.message
                : t("errors.wrong")
              : t("errors.wrong");
            showError();
          }
        }
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    props.set_loading(true);

    apiJson.orderItemsList.getItems().then((response) => {
      props.set_loading(false);
      if (response.success) {
        setItem(
          response.data.filter((item) => item.id === parseInt(param.id))[0]
        );
      } else if (!response.status) {
        message = response.message // eslint-disable-line react-hooks/exhaustive-deps
          ? response.message !== ""
            ? response.message
            : "something went wrong"
          : "something went wrong";
        showError();
      } else {
        if (response.status >= 400 && response.status < 500) {
          message = response.message
            ? response.message
            : response.errors
            ? response.errors
            : t("errors.wrong");
          showError();
        } else {
          message = response.message
            ? response.message !== ""
              ? response.message
              : t("errors.wrong")
            : t("errors.wrong");
          showError();
        }
      }
    });
  }, []);

  return (
    <Fragment>
      {item?.orderable && (
        <div className="review-desktop w-100 pt-5">
          <Link
            push
            style={{ textDecoration: "none" }}
            to={
              "/" +
              props.countryUrl +
              "-" +
              props.language +
              "/order/itemslist/review"
            }
          >
            <div className="back-col d-flex align-items-center">
              <div className="icon d-flex align-items-center">
                <FiChevronLeft
                  style={{
                    transform:
                      props.language === "ar"
                        ? "rotate(180deg)"
                        : "rotate(0deg)",
                  }}
                />
              </div>
              <div className="text ml-2 d-flex align-items-center">
                {t("review-page.back")}
              </div>
            </div>
          </Link>

          <div className="review-container w-100">
            <div className="review-header">
              {t("review-page.review-your-purchase")}
            </div>
            <div className="review-body w-100">
              <div className="item-header-text">
                {t("review-page.you-bought")}
              </div>
              <div className="item-container d-flex align-items-center">
                <div className="image-container d-flex justify-content-center">
                  <img
                    src={getDesktopImage(
                      item?.orderable?.featured_image_resized,
                      item?.orderable?.featured_image
                    )}
                    alt="product"
                    style={{
                      width: "auto",
                      height: "100%",
                      maxWidth: "100%",
                    }}
                  />
                </div>
                <div className="ml-2 description-container">
                  <div className="text">
                    {(item?.orderable[`product_name_${props.language}`]
                      ? item?.orderable[`product_name_${props.language}`]
                      : "") +
                      " " +
                      item?.orderable[`name_${props.language}`]}
                  </div>
                  {item?.orderable[`sku`] && (
                    <div className="text">{item?.orderable[`sku`]}</div>
                  )}
                </div>
              </div>
              <div className="store-info-row d-flex justify-content-between">
                <div className="store-info d-flex align-items-center">
                  <div className="text">{t("review-page.from")}</div>
                  <div className="store-icon d-flex align-items-center justify-content-center">
                    <IoStorefront />
                  </div>
                  <div className="store-name">
                    {item?.orderable[`store_name_${props.language}`]}
                  </div>
                </div>
                <div className="store-review-col d-flex align-items-center">
                  <div className="text">
                    {t("review-page.mobile.rate-item")}
                  </div>
                  <div className="rating">
                    <StyledRating
                      name="rating"
                      value={value}
                      precision={1}
                      onChange={handleChange}
                      icon={<BsStarFill className="fill-icon" />}
                      emptyIcon={<BsStar className="empty-icon" />}
                    />
                  </div>
                </div>
              </div>
              <div className="feedback-row d-flex">
                <div className="feedback-col w-50">
                  <div className="feedback-header-row w-100 d-flex justify-content-between">
                    <div className="header-text">
                      {t("review-page.write-feedback")}
                    </div>
                  </div>
                  <textarea
                    name="feedback"
                    id="feedback"
                    placeholder={t("review-page.tell-us")}
                    cols="30"
                    rows="5"
                    className="w-100 feedback"
                    value={feedback}
                    onChange={(e) => setFeedback(e.target.value)}
                  ></textarea>
                </div>

                <div className="your-review-col w-50">
                  <div className="your-review-header">
                    {t("review-page.how-will-it-work")}
                  </div>
                  <div className="verified-review w-100 d-flex justify-content-between align-items-start">
                    <div className="review-with-date d-flex flex-column">
                      <div className="rating-bar">
                        <StyledRating
                          name="rating"
                          value={value}
                          readOnly
                          precision={1}
                          icon={<BsStarFill className="fill-icon" />}
                          emptyIcon={<BsStar className="empty-icon" />}
                        />
                      </div>
                      <div className="date">
                        {format(new Date(), "dd MMM, yyy")}
                      </div>
                    </div>
                    <div className="verified-icon d-flex align-items-center">
                      <div className="icon">
                        <VscVerified />
                      </div>
                      <div className="text">{t("review-page.verified")}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="radio-buttons-group-row">
                <div className="submit-button-row d-flex justify-content-center align-items-center">
                  <button
                    onClick={(e) => createReview(e)}
                    type="button"
                    className="submit-button"
                    disabled={buttonDisable}
                  >
                    {loading ? (
                      <div className="d-flex justify-content-center align-items-center w-100">
                        <ClipLoader color="#fff" loading size={20} />
                      </div>
                    ) : (
                      t("review-page.submit-btn")
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {redirect && (
        <Redirect
          push
          to={"/" + props.countryUrl + "-" + props.language + "/orders"}
        />
      )}
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    language: state.language.language,
    countryUrl: state.language.countryUrl,
  };
};

const mapDispatchToProps = { set_loading };
export default connect(mapStateToProps, mapDispatchToProps)(Review);
