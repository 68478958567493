import { type } from "../../types/types";
const initialState = {
  cart_items: [],
  cart_items_length: 0,
  wish_list: [],
  order: {},
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case type.SET_CART_ITEMS:
      return {
        ...state,
        ...action.cart_items,
      };
    case type.SET_WISH_LIST:
      return {
        ...state,
        ...action.wish_list,
      };
    default:
      return state;
  }
}
