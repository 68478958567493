import React, { Fragment, useEffect } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { connect } from "react-redux";
import "../../assets/styles/mobile/thankyoupagemobile.scss";
import ThankYouRBG from "../../assets/images/Cart/Cart-Empty/ThankYouPage/ThankYouRBG.png";
import MobileLayoutRenderer from "./layout/MobileLayoutRenderer";
import { useTranslation } from "react-i18next";
import { useLocation, Link, Redirect } from "react-router-dom";
import { set_cart_items } from "../../redux/actions/CartAction";

const en_translation = require("../../assets/locales/en/translation.json");
const ar_translation = require("../../assets/locales/ar/translation.json");

const ThankYouPageMobile = (props) => {
  const { t } = useTranslation();
  const location = useLocation();

  useEffect(
    () =>
      props.set_cart_items({
        cart_items: {},
        cart_items_length: 0,
        order: {},
        sub_total: 0,
        discount: 0,
        delivery_fee: 0,
        vat: 0,
        grand_total: 0,
      }),
    // eslint-disable-next-line
    []
  );
  return (
    <MobileLayoutRenderer
      NavbarTop={false}
      BackBtn={false}
      CancelBtn={false}
      NavbarBottom={true}
    >
      {location.state ? (
        <>
          {props.width === "sm" && (
            <Fragment>
              <div className="container thank-you-page-mobile">
                <Row>
                  <Col md={12}>
                    <div className="justify-content-center">
                      <div className=" thank-you-page-card-area">
                        <div className="col-sm-12  text-center no-gutters">
                          <img
                            src={ThankYouRBG}
                            width="350"
                            height="350"
                            className="img-fluid mb-4 mr-3"
                            alt="thankimage"
                          />
                          <h5 className="cart-text thankyou-head-text">
                            {t("thankyou-page.ty")}
                          </h5>
                          <div className="cart-text thankyou-sub-text">
                            <span className="mt-auto mb-auto order-placed-text">
                              {t("thankyou-page.order-placed")}
                            </span>
                            <h5 className="order-placed-no mt-auto mb-auto">
                              {location.state}
                            </h5>
                          </div>
                          <br />
                          <Link
                            push
                            to={
                              "/" +
                              props.countryUrl +
                              "-" +
                              props.language +
                              "/"
                            }
                          >
                            <button className="thankyoupage-continueshopping-button ">
                              {
                                props.language == "en" ?
                                  en_translation["cart-page"]["empty"]["continue-shop-btn"]
                                  :
                                  ar_translation["cart-page"]["empty"]["continue-shop-btn"]
                              }
                              {/* {t("cart-page.empty.continue-shop-btn")} */}
                            </button>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </Fragment>
          )}
        </>
      ) : (
        <Redirect
          push
          to={"/" + props.countryUrl + "-" + props.language + "/cart"}
        />
      )}
    </MobileLayoutRenderer>
  );
};

const mapStateToProps = (state) => {
  return {
    width: state.layout.screenWidth,
    language: state.language.language,
    countryUrl: state.language.countryUrl,
  };
};

const mapDispatchToProps = { set_cart_items };

export default connect(mapStateToProps, mapDispatchToProps)(ThankYouPageMobile);
