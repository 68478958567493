import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import "../../assets/styles/component/NotFound/NotFound.scss";
import { set_redirect_home } from "../../redux/actions/NotFoundAction";

function NotFound(props) {
  const history = useHistory();
  useEffect(() => props.set_redirect_home(false), []); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <div className="not-foun-container">
      <div className="heading">404</div>
      <div className="sub-heading">Not Found</div>
      <div className="text">
        The resource requested could not be found on the server!
      </div>
      <button
        onClick={() => {
          history.push("/" + props.countryUrl + "-" + props.language + "/");
        }}
        className="go-to-home "
      >
        Go to Home
      </button>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    language: state.language.language,
    countryUrl: state.language.countryUrl,
  };
};
const mapDispatchToProps = { set_redirect_home };

export default connect(mapStateToProps, mapDispatchToProps)(NotFound);
