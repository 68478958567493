import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Row } from "reactstrap";
import "../assets/styles/pages/customervendorshoppage.scss";
import VendorMainSlider from "../components/vendorShopPage/VendorMainSlider";
import ProductList from "../components/product/ProductList";
import WebsiteHeader from "../components/header/WebsiteHeader";
import ProductCategoryList from "../components/product/ProductCategoryList";
import WebsiteFooter from "../components/Footer/WebsiteFooter";
import { apiJson } from "../Api";
import { useTranslation } from "react-i18next";
import { set_vendor_data } from "../redux/actions/VendorShopAction";
import { set_home_categories } from "../redux/actions/HomeAction";
import { connect } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import { useParams } from "react-router-dom";
import YelloColorImage from "../assets/images/homepage/YelloColorImage.png";
import featured1 from "../assets/images/C&BMaterials-banner.jpg";
import featured2 from "../assets/images/cleaning.jpg";
import featured3 from "../assets/images/Hardware&Tools-banner.jpg";
import featured4 from "../assets/images/Paint-banner.jpg";
import featured5 from "../assets/images/Appliance-banner.jpg";

import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';

const CustomerVendorShopPage = (props) => {
  const { id } = useParams();
  const [logo, setLogo] = useState("");
  const [menu_items, setMenuItems] = useState([]);
  const [middle_banners, setMiddleBanners] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    document.title = "Vendor Shop";
  }, []);

  useEffect(() => {
    apiJson.homepage
      .getCategories(props.countryId)
      .then(
        (response) =>
          response.success &&
          props.set_home_categories({ categories: response.data })
      );

    apiJson.vendorShop.getData(props.countryId, id).then((response) => {
      if (response.success) {
        setLogo(response.data.logo_image);
        props.set_vendor_data({
          products: response.data.products,
          services: response.data.services,
          secondaryBanners: response.data.banners.filter(
            (banner) => banner.banner_type === "store_secondary"
          ),
          primaryBanners: response.data.banners.filter(
            (banner) => banner.banner_type === "store_primary"
          ),
        });
      }
    });

    apiJson.vendorShop.getMenu(id)
      .then(response => {
        if (response.success) {
          setMenuItems(response.data.menu_items);
        }
      });

    apiJson.vendorShop.getMiddleBanners(id)
      .then(response => {
        if (response.success) {
          setMiddleBanners(response.data.banners);
        }
      });
    // eslint-disable-next-line
  }, [props.countryId]);

  return (
    <Fragment>
      <div className="customer-vendor-shop-page-desktop mx-auto">
        <WebsiteHeader />
        <div className="content-wrapper all-page-container">
          <VendorMainSlider />
          <Navbar bg="light" expand="lg">
            <Container fluid>
              <Navbar.Brand href="#">
                <img src={logo} height="50px" width="auto" />
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="navbarScroll" />
              <Navbar.Collapse id="navbarScroll">
                <Nav
                  className="me-auto my-2 my-lg-0"
                  style={{ maxHeight: '100px' }}
                  navbarScroll
                >
                  {/* <Form className="d-flex">
                    <Form.Control
                      type="search"
                      placeholder="Search"
                      className="me-2"
                      aria-label="Search"
                    />
                    <Button variant="outline-success">Search</Button>
                  </Form> */}
                  {
                    menu_items?.map(item => {
                      if (item.sub_vendor_menu_items?.length > 0) {
                        return (
                          <NavDropdown title={item.name_en} id="navbarScrollingDropdown">
                            {
                              item.sub_vendor_menu_items.map(sub_item => {
                                return (
                                  <>
                                    <Link
                                      to={{
                                        pathname: "/" + sub_item.link.split("?")[0],
                                        search: sub_item.link.split("?")[1]
                                      }}
                                      className="dropdown-item"
                                    >{sub_item.name_en}</Link>
                                    <NavDropdown.Divider />
                                  </>
                                )
                              })
                            }
                          </NavDropdown>
                        )
                      }
                      else {
                        return (
                          <Link
                            to={{
                              pathname: "/" + item.link.split("?")[0],
                              search: item.link.split("?")[1]
                            }}
                            className="nav-link store-menu"
                          >{item.name_en}</Link>
                        )
                      }
                    })
                  }
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
          <div className="emptyspacer"></div>
          {
            middle_banners.length > 0 ?
              <Row className="featuredcat-vendor">
                <div className="col-lg-6 col-md-6 mia">
                  <Link
                    to={{
                      pathname: "/" + middle_banners[0].link.split("?")[0],
                      search: middle_banners[0].link.split("?")[1]
                    }}
                  >
                    <img className="homeofferimg"
                      src={middle_banners[0].image}
                    />
                  </Link>
                </div>
                <div className="col-lg-6 col-md-6 mib">

                  <Row>
                    {
                      middle_banners.map((banner, key) => {
                        if (key != 0) {
                          return (
                            <div className="col-lg-6 col-md-6 mic">
                              <Link
                                to={{
                                  pathname: "/" + banner.link.split("?")[0],
                                  search: banner.link.split("?")[1]
                                }}
                              >
                                <img className="homeofferimg"
                                  src={banner.image}
                                  alt="Cleaners"
                                />
                              </Link>
                            </div>
                          )
                        }
                      })
                    }
                  </Row>

                </div>
              </Row>
              :
              <></>
          }
          {/* <Row className="featuredcat-vendor">
            <div className="col-lg-6 col-md-6 mia">
              <Link
                to={{
                  pathname: "/" + props.countryUrl +
                    "-" + props.language +
                    "/items/products",
                  search: "?filter_by_category%5B%5D=182&filter_by_category%5B%5D=195&filter_by_category%5B%5D=196&filter_by_category%5B%5D=197&filter_by_category%5B%5D=198&filter_by_category%5B%5D=203&filter_by_category%5B%5D=199&filter_by_category%5B%5D=200&filter_by_category%5B%5D=201&filter_by_category%5B%5D=202&filter_by_category%5B%5D=204"
                }}
              >
                <img className="homeofferimg"
                  src={featured1}
                  alt="Building and Construction Materials"
                />
              </Link>
            </div>
            <div className="col-lg-6 col-md-6 mib">

              <Row>
                <div className="col-lg-6 col-md-6 mic">
                  <Link
                    to={{
                      pathname: "/" + props.countryUrl +
                        "-" + props.language +
                        "/items/products",
                      search: "?filter_by_category%5B%5D=184&filter_by_category%5B%5D=217&filter_by_category%5B%5D=329&filter_by_category%5B%5D=330&filter_by_category%5B%5D=328&filter_by_category%5B%5D=331&filter_by_category%5B%5D=332&filter_by_category%5B%5D=215&filter_by_category%5B%5D=319&filter_by_category%5B%5D=321&filter_by_category%5B%5D=322&filter_by_category%5B%5D=318&filter_by_category%5B%5D=320&filter_by_category%5B%5D=323&filter_by_category%5B%5D=216&filter_by_category%5B%5D=324&filter_by_category%5B%5D=325&filter_by_category%5B%5D=326&filter_by_category%5B%5D=327"
                    }}
                  >
                    <img className="homeofferimg"
                      src={featured2}
                      alt="Cleaners"
                    />
                  </Link>
                </div>
                <div className="col-lg-6 col-md-6 mid">
                  <Link
                    to={{
                      pathname: "/" + props.countryUrl +
                        "-" + props.language +
                        "/items/products",
                      search: "?filter_by_category%5B%5D=191&filter_by_category%5B%5D=259&filter_by_category%5B%5D=262&filter_by_category%5B%5D=257&filter_by_category%5B%5D=260&filter_by_category%5B%5D=261&filter_by_category%5B%5D=258"
                    }}
                  >
                    <img className="homeofferimg"
                      src={featured3}
                      alt="Hardware & Tools"
                    />
                  </Link>
                </div>
              </Row>

              <Row>
                <div className="col-lg-6 col-md-6 mie">
                  <Link
                    to={{
                      pathname: "/" + props.countryUrl +
                        "-" + props.language +
                        "/items/products",
                      search: "?filter_by_category%5B%5D=194&filter_by_category%5B%5D=271&filter_by_category%5B%5D=269&filter_by_category%5B%5D=270&filter_by_category%5B%5D=272&filter_by_category%5B%5D=274&filter_by_category%5B%5D=275&filter_by_category%5B%5D=273"
                    }}
                  >
                    <img className="homeofferimg"
                      src={featured4}
                      alt="Paint"
                    />
                  </Link>
                </div>
                <div className="col-lg-6 col-md-6 mif">
                  <Link
                    to={{
                      pathname: "/" + props.countryUrl +
                        "-" + props.language +
                        "/items/products",
                      search: "?filter_by_category%5B%5D=183&filter_by_category%5B%5D=205&filter_by_category%5B%5D=290&filter_by_category%5B%5D=289&filter_by_category%5B%5D=206&filter_by_category%5B%5D=291&filter_by_category%5B%5D=207&filter_by_category%5B%5D=210&filter_by_category%5B%5D=294&filter_by_category%5B%5D=295&filter_by_category%5B%5D=296&filter_by_category%5B%5D=297&filter_by_category%5B%5D=298&filter_by_category%5B%5D=299&filter_by_category%5B%5D=300&filter_by_category%5B%5D=301&filter_by_category%5B%5D=302&filter_by_category%5B%5D=303&filter_by_category%5B%5D=304&filter_by_category%5B%5D=305&filter_by_category%5B%5D=211&filter_by_category%5B%5D=306&filter_by_category%5B%5D=307&filter_by_category%5B%5D=308&filter_by_category%5B%5D=309&filter_by_category%5B%5D=212&filter_by_category%5B%5D=310&filter_by_category%5B%5D=449&filter_by_category%5B%5D=448&filter_by_category%5B%5D=450&filter_by_category%5B%5D=311&filter_by_category%5B%5D=452&filter_by_category%5B%5D=453&filter_by_category%5B%5D=451&filter_by_category%5B%5D=312&filter_by_category%5B%5D=313&filter_by_category%5B%5D=214&filter_by_category%5B%5D=208&filter_by_category%5B%5D=209&filter_by_category%5B%5D=292&filter_by_category%5B%5D=293&filter_by_category%5B%5D=213&filter_by_category%5B%5D=314&filter_by_category%5B%5D=315&filter_by_category%5B%5D=316&filter_by_category%5B%5D=317"
                    }}
                  >
                    <img className="homeofferimg"
                      src={featured5}
                      alt="Appliances"
                    />
                  </Link>
                </div>
              </Row>

            </div>
          </Row> */}
          <div className="emptyspacer"></div>
          {/* <Row className="product-category-list-row mt-4 mx-auto">
            <ProductCategoryList />
          </Row> */}
          {Object.entries(props.products).length > 0 && (
            <Row className="product-list-row mb-3 mx-auto">
              <ProductList type="product" products={props.products} />
              <div className="ViewAllbtn mt-3 mb-3">
                <Link to={{
                  pathname: "/" + props.countryUrl +
                    "-" + props.language +
                    "/items/products",
                  search: "?filter_by_store%5B%5D=" + id
                }}>
                {t("all-categories.view-all")}
                </Link>
              </div>
            </Row>
          )}

          <Row className="kid-fashion-banner-row mx-auto">
            <div className="kid-fashion-banner-container">
              {props.secondaryBanners[0]?.image ? (
                <div className="vendor-shop-banner">
                  {props.secondaryBanners[0]?.redirects_to_link ? (
                    <a href={props.secondaryBanners[0]?.redirects_to_link}>
                      <img
                        src={props.secondaryBanners[0]?.image}
                        alt="KidFashion"
                        style={{
                          width: "100%",
                          height: "100%",
                          maxHeight: "100%",
                        }}
                      />
                    </a>
                  ) : (
                    <img
                      src={props.secondaryBanners[0]?.image}
                      alt="KidFashion"
                      style={{
                        width: "100%",
                        height: "100%",
                        maxHeight: "100%",
                      }}
                    />
                  )}
                </div>
              ) : (
                <div className="vendor-shop-banner">
                  <img
                    src={YelloColorImage}
                    alt="banner"
                    style={{
                      width: "100%",
                      height: "100%",
                      maxHeight: "100%",
                    }}
                  />
                </div>
              )}
            </div>
          </Row>
          {Object.entries(props.services).length > 0 && (
            <Row className="product-list-row mt-3 mb-3 mx-auto">
              <ProductList type="service" products={props.services} />
            </Row>
          )}
        </div>
        <WebsiteFooter />
      </div>
    </Fragment>
  );
};
const mapStateToProps = (state) => {
  return {
    products: state.vendor.products,
    services: state.vendor.services,
    primaryBanners: state.vendor.primaryBanners,
    secondaryBanners: state.vendor.secondaryBanners,
    countryId: state.language.countryId,
    language: state.language.language,
    countryUrl: state.language.countryUrl,
  };
};

const mapDispatchToProps = {
  set_vendor_data,
  set_home_categories,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomerVendorShopPage);
