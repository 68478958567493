import MobileLayoutRenderer from "../mobile/layout/MobileLayoutRenderer";
import ProductList from "../../components/product/ProductList";
import Row from "react-bootstrap/Row";
import "../../assets/styles/pages/sales.scss";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { apiJson } from "../../Api";
import { useState } from "react";
import { connect } from "react-redux";
import ProductCard from "../../components/mobile/Home/ProductCardMobile";
import PageBanner from "../../assets/images/november-banner-mob.jpg";
import PaintsImage from "../../assets/images/Paints.jpg";
import AutomotiveImage from "../../assets/images/Automotive copy.jpg";
import HardwareImage from "../../assets/images/Hardware copy.jpg";
import BuildingAndConstructionImage from "../../assets/images/C&B.jpg";
import HomeEssentialImage from "../../assets/images/HomeEssential.jpg";
import CleanersImage from "../../assets/images/Cleaner.jpg";
import VacuumImage from "../../assets/images/Vacuum.jpg";
import BatteriesImage from "../../assets/images/Batteries.jpg";
import AppliancesImage from "../../assets/images/Appliances-mobile.jpg";
import DIYPaintsImage from "../../assets/images/DIYPaints-mobile.jpg";
import MiddleImage from "../../assets/images/middle-baner.gif";
import { useTranslation } from "react-i18next";

const NovemberSaleMob = (props) => {
    const [title, setTitle] = useState("");
    const [products, setProducts] = useState([]);
    const { t } = useTranslation();

    useEffect(() => {
        apiJson
            .homepage.getSections(48, props.countryId)
            .then(response => {
                if (response.success) {
                    setTitle(response.data.title_en);
                    setProducts(response.data.products);
                }
            });
    }, []);

    return (
        <MobileLayoutRenderer
            NavbarTop={true}
            BackBtn={false}
            CancelBtn={false}
            NavbarBottom={true}
            SearchIcon={true}
        >
            <div className="sale">
                <div className="row mx-0">
                    <div className="col-12 p-0">
                        <Link
                            to="#">
                            <img
                                src={PageBanner}
                                alt="category"
                                style={{
                                    width: "100%",

                                }} />
                        </Link>
                    </div>
                </div>


                <div className="row mx-0 my-2 cat-11-mob">
                    <div className="col-lg-12">
                        <h4 className="font-weight-bold text-center py-2">DEALS YOU CAN'T MISS</h4>
                    </div>
                    <div className="col-6 my-1">
                        <Link
                            to="/uae-en/offers/section/paints-upto-60">
                            <img
                                src={PaintsImage}
                                alt="category"
                                style={{
                                    width: "100%",

                                }} />
                        </Link>
                    </div>
                    <div className="col-6 my-1">
                        <Link
                            to="/uae-en/offers/section/automotive-upto-60">
                            <img
                                src={AutomotiveImage}
                                alt="category"
                                style={{
                                    width: "100%",

                                }} />
                        </Link>
                    </div>
                    <div className="col-6 my-1">
                        <Link
                            to="/uae-en/offers/section/hardware-tools-upto-55">
                            <img
                                src={HardwareImage}
                                alt="category"
                                style={{
                                    width: "100%",

                                }} />
                        </Link>
                    </div>
                    <div className="col-6 my-1">
                        <Link
                            to="/uae-en/offers/section/building-and-construction-upto-60">

                            <img
                                src={BuildingAndConstructionImage}
                                alt="category"
                                style={{
                                    width: "100%",

                                }} />
                        </Link>
                    </div>
                    <div className="col-6 my-1">
                        <Link
                            to="/uae-en/offers/section/home-essentials-upto-55">

                            <img
                                src={HomeEssentialImage}
                                alt="category"
                                style={{
                                    width: "100%",

                                }} />
                        </Link>
                    </div>
                    <div className="col-6 my-1">
                        <Link
                            to="/uae-en/offers/section/cleaners-upto-50">

                            <img
                                src={CleanersImage}
                                alt="category"
                                style={{
                                    width: "100%",

                                }} />
                        </Link>
                    </div>
                    <div className="col-6 my-1">
                        <Link
                            to="/uae-en/offers/section/vacuum-cleaners-upto-55">

                            <img
                                src={VacuumImage}
                                alt="category"
                                style={{
                                    width: "100%",

                                }} />
                        </Link>
                    </div>
                    <div className="col-6 my-1">
                        <Link
                            to="/uae-en/offers/section/batteries-upto-40">
                            <img
                                src={BatteriesImage}
                                alt="category"
                                style={{
                                    width: "100%",

                                }} />
                        </Link>
                    </div>
                </div>

                <div className="row mx-0">
                    <div className="col-12 p-0">
                        <Link
                            to="#">
                            <img
                                src={MiddleImage}
                                alt="category"
                                style={{
                                    width: "100%"
                                }} />
                        </Link>
                    </div>
                </div>

                <div className="row mx-0 px-1">
                    <div className="title-row justify-content-center">
                        <div className="title mobtit">
                            <h6 className="mb-0 font-weight-bold">{title}</h6>
                        </div>
                        {/* <div class="viewalldiv">
                            <Link
                                to="#"
                                className="view-all-text">
                        {t("all-categories.view-all")}
                                </Link>
                                </div> */}
                    </div>
                </div>
                <div className="product-cont mt-3">
                    {products.map((data) => (
                        <Link
                            style={{ textDecoration: "none" }}
                            to={{
                                pathname:
                                    "/" +
                                    props.countryUrl +
                                    "-" +
                                    props.language +
                                    `/${data.category_title_en?.toLowerCase()
                                        .replace(/ /g, "-")}/${data.slug}`,
                                state: {
                                    id: data.variants[0].id,
                                    type: data.variants ? "products" : "services",
                                    sku: data.variants[0].sku
                                },
                                search: "?sku=" + data.variants[0].sku
                            }}
                            key={data.variants[0].id + Math.random()}
                        >
                            <div
                                className="image-cont"
                                key={data.variants[0].id + Math.random() + "prod"}
                            >
                                <ProductCard
                                    key={data.variants[0].id + Math.random()}
                                    img={
                                        data.variants
                                            ? data.variants[0].featured_image
                                            : data.featured_image
                                    }
                                    storeLogo={data.variants[0].store_logo}
                                    wishlisted={
                                        data.variants
                                            ? data.variants[0].wishlisted
                                            : data.wishlisted
                                    }
                                    home={true}
                                    type={data.variants ? "products" : "services"}
                                    id={data.variants ? data.variants[0].id : data.id}
                                    rating={data.variants[0].average_rating}
                                    reviews={data.variants[0].reviews_count}
                                    name={data[`name_${props.language}`] + " " + data.variants[0][`name_${props.language}`]}
                                    data={data.variants ? data.variants[0] : data}
                                    coupon={data.coupon}
                                    is_inquiry={data.is_inquiry}
                                />
                            </div>
                        </Link>
                    ))}
                </div>

                <div className="row mx-0 my-2 mob-big-cat">
                    <div className="col-12 my-2">
                        <Link
                            to="/uae-en/offers/section/appliances">
                            <img
                                src={AppliancesImage}
                                alt="category"
                                style={{
                                    width: "100%",

                                }} />
                        </Link>
                    </div>
                    <div className="col-12 my-2">
                        <Link
                            to="/uae-en/offers/section/diy-paint">

                            <img
                                src={DIYPaintsImage}
                                alt="category"
                                style={{
                                    width: "100%",

                                }} />
                        </Link>

                    </div>
                </div>
            </div>
        </MobileLayoutRenderer>
    )
};

const mapStateToProps = (state) => {
    return {
        seller: state.allItems.seller,
        filter_by: state.allItems.filter_by,
        brand: state.allItems.brand,
        items: state.allItems.items,
        countryId: state.language.countryId,
        countryUrl: state.language.countryUrl,
        width: state.layout.screenWidth,
        language: state.language.language,
    };
};

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(NovemberSaleMob);