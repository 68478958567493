import React, { Fragment } from "react";
import { Row } from "react-bootstrap";
import "../../../assets/styles/customer/account/general.scss";
import Header from "../../../components/customer/shippingheader/Header";
import ThankYouPage from "../../../components/customer/ThankYouPage";
const OrdePlaced = (props) => {
  return (
    <Fragment>
      <Row className="page-container no-gutters checkout-orderplaced-desktop gx-0 gy-0">
        <div className="w-100">
          <Header screen_name="ORDER" />

          <div className="orderplaced-thankyoupage w-100">
            <ThankYouPage />
          </div>
        </div>
      </Row>
    </Fragment>
  );
};

export default OrdePlaced;
