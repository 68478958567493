import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";
import { IoMdClose } from "react-icons/io";
import "../../assets/styles/mobile/CountryPopup.scss";

import Checked from "../../assets/icons/Checked.svg";
import Unchecked from "../../assets/icons/Unchecked.svg";

import uae from "../../assets/images/flag.png";
import saudia from "../../assets/images/flag2.png";
import egypt from "../../assets/images/flag3.png";
import { connect } from "react-redux";

const useStyles = makeStyles(() => ({
  newPosOfDialog: {
    position: "absolute",
    top: "50%",
    borderRadius: "10px",
  },
}));
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function CountryPopup(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
    props.onClose(false);
  };
  const countries = [
    { flag: saudia, c_name: "Saudi Arabia" },
    { flag: uae, c_name: "United Arab Emirates" },
    { flag: egypt, c_name: "Egypt" },
  ];
  const [country, setCountry] = useState(props.country);

  useEffect(() => setOpen(true), []);

  return (
    <div>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        classes={{
          paper: classes.newPosOfDialog,
        }}
        TransitionComponent={Transition}
      >
        <div className="country-popup">
          <IoMdClose
            onClick={handleClose}
            size="30"
            color="#404553"
            className="close"
          />

          <div className="mt-4 heading">Select Country</div>
          {countries.map(({ flag, c_name }, index) => (
            <div
              key={c_name}
              onClick={() => {
                setCountry(c_name);
                props.setCountry(c_name);
                props.onClose(false);
              }}
              className="d-flex flex-direction-row justify-content-between align-items-center country_cont"
            >
              <span>
                <img src={flag} alt="" width="23px" height="15px" />
                <label
                  className={
                    country === c_name ? "active-country" : "country_name"
                  }
                >
                  {c_name}
                </label>
              </span>
              <img
                alt={c_name}
                src={country === c_name ? Checked : Unchecked}
                width="22px"
                height="22px"
              />
            </div>
          ))}
        </div>
      </Dialog>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    country: state.language.country,
  };
};

export default connect(mapStateToProps, null)(CountryPopup);
