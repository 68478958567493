import React from "react";
import { connect } from "react-redux";
import SignupMobOption1 from "./SignupMobOption1";

function SignupMobPageOpt1(props) {
  return <>{props.width === "sm" && <SignupMobOption1 />}</>;
}

const mapStateToProps = (state) => {
  return {
    width: state.layout.screenWidth,
  };
};

export default connect(mapStateToProps, null)(SignupMobPageOpt1);
