import React, { Fragment } from "react";
import { useState } from "react";
import AddAddressBody from "../../components/mobile/AddAddress/AddAddressBody";
import GoogleMaps from "../../components/customer/GoogleMaps";
import MobileLayoutRenderer from "./layout/MobileLayoutRenderer";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Redirect, useLocation } from "react-router-dom";
import "../../assets/styles/pages/AddAddressPage.scss";

function AddAddressPage(props) {
  const { t } = useTranslation();
  // const [locationAdded, setLocationAdded] = useState(false);
  const [locationAdded, setLocationAdded] = useState(true);
  const [active, setActive] = useState(false);
  const [address, setAddress] = useState("");
  const location = useLocation();

  return (
    <div className="add-address-page-mobile">
      <MobileLayoutRenderer
        NavbarTop={true}
        BackBtn={false}
        CancelBtn={true}
        NavbarBottom={false}
        noPadding={true}
      >
        {locationAdded ? (
          <AddAddressBody
            address={address}
            addresses={location?.state?.name === "addresses" ? true : false}
            setLocation={(val) => {
              setLocationAdded(val);
              setActive(false);
              setAddress("");
            }}
            addressData={
              !location.state?.data ? undefined : location?.state?.data
            }
          />
        ) 
        : location?.state?.name === "addresses" ||
          props.cart_items?.line_items?.length > 0 ? (
          <Fragment>
            <div className="wrapper">
              <div className="map-style">
                <GoogleMaps
                  Address={(val) => setAddress(val)}
                  active={(val) => setActive(val)}
                  address={!location.state ? undefined : location.state.data}
                />
              </div>

              <div
                onClick={() => active && setLocationAdded(true)}
                className={`confirm-button mt-2 ${
                  active
                    ? "button-background-active"
                    : "button-background-inactive"
                }`}
              >
                <span className={`confirm-button-text`}>
                  {t("ship.confirm-location")}
                </span>
              </div>
            </div>
          </Fragment>
        ) 
        : (
          <Redirect
            push
            to={"/" + props.countryUrl + "-" + props.language + "/cart"}
          />
        )}
      </MobileLayoutRenderer>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    cart_items: state.cart.cart_items,
    language: state.language.language,
    countryUrl: state.language.countryUrl,
  };
};

export default connect(mapStateToProps, null)(AddAddressPage);
