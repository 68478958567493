import React from "react";
import { connect } from "react-redux";
import "../../../assets/styles/component/mobile/footer-page.scss";
import pagesbannerimg from "../../../assets/images/footer-pages-banners/Consumer-Rights.jpg";
import UAEConsumerImg from "../../../assets/images/footer-page-images/UAE-Consumer.png";

// import TermsAndConditionsComp from "../../../components/mobile/footer-pages/TermsAndConditionsComp";

function ConsumerRightsMobileApp(props) {
  return (
    <div className="home-mobile">
        <div className="body" style={{ background: "#F5F5F5" }}>
          <div className="pagesbanner">
            <img
              src={pagesbannerimg}
              alt="category"
              style={{
                width: "100%",
                height: "100%",
                maxHeight: "400px",
              }}
            ></img>
          </div>
          <div className="content-wrapper all-page-container">
            <div className="footer-pages-content">
              <img className="consumer-img" src={UAEConsumerImg} alt=""></img>
              <div
                className="consumer-content"
                style={{
                  listStyle: "none",
                }}
              >
                <p>
                  <b>Your Rights:</b>
                </p>
                <li>
                  1: You have the right to be informed of any personal data
                  which we may hold or process about you.
                </li>
                <li>
                  2: You have the right to require us to correct any
                  inaccuracies in your data free of charge.
                </li>
                <li>
                  3: You have the right to ask us to stop using your personal
                  data for direct marketing purposes.
                </li>
                <li>
                  4: You have the right to ask us to erase your personal data or
                  restrict the processing of your personal data.
                </li>
                <li>
                  5: You have the right to request us to transfer any personal
                  data which we may hold to you or any other service provider of
                  your choice.
                </li>
                <li>
                  6: You have the right to lodge a complaint with the
                  supervisory authority.
                </li>
                <li>7: You have the right to voice an opinion.</li>
                <li>8: You have the right to be educated as a consumer.</li>
                <li>9: You have the right to ensure your safety.</li>
                <li>
                  10: You have the right to express your opinion on the quality
                  of the goods, their services and availability.
                </li>

                <p>
                  Alisuq is a participant in the Digital Protection initiative,
                  dedicated to protecting your consumer rights and addressing
                  your concerns. For more information about consumer rights,
                  visit www.consumerrights.ae
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
  )
}

const mapStateToProps = (state) => {
  return {
    width: state.layout.screenWidth,
  };
};

export default connect(mapStateToProps, null)(ConsumerRightsMobileApp);
