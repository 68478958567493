import React from "react";
import { connect } from "react-redux";

import ConsumerRightsComp from "../../../components/mobile/footer-pages/ConsumerRightsComp";

function ConsumerRights(props) {
  return <div>{props.width === "sm" && <ConsumerRightsComp screen="home" />}</div>;
}

const mapStateToProps = (state) => {
  return {
    width: state.layout.screenWidth,
  };
};

export default connect(mapStateToProps, null)(ConsumerRights);
