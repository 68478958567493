import React, { Fragment } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination, Navigation } from "swiper/core";
import "../../assets/styles/homepage/mainslider.scss";
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css";
import "swiper/components/navigation/navigation.min.css";
import { connect } from "react-redux";
import BlueColorImage from "../../assets/images/homepage/BlueColorImage.png";
import YelloColorImage from "../../assets/images/homepage/YelloColorImage.png";

const SliderData = [
  {
    id: 1,
    name: "yellow",
    image: YelloColorImage,
  },
  {
    id: 2,
    name: "blue",
    image: BlueColorImage,
  },
];
SwiperCore.use([Pagination, Navigation]);

const VendorMainSlider = (props) => {
  return (
    <Fragment>
      {props.banners[0]?.image ? (
        <Swiper
          slidesPerView={1}
          spaceBetween={30}
          loop={true}
          pagination={{
            clickable: true,
          }}
          navigation={
            props.screenWidth && props.screenWidth === "sm" ? false : true
          }
          className="homepage-main-banner-swiper mb-0"
          dir="rtl"
        >
          {props.banners?.map((item) => {
            return (
              <SwiperSlide key={item.id}>
                {item?.redirects_to_link ? (
                  <a href={item?.redirects_to_link}>
                    <img
                      className="swiper-image"
                      style={{
                        width: "100%",
                        height: "100%",
                        maxHeight: "100%",
                      }}
                      src={item?.image}
                      alt="banner"
                    />
                  </a>
                ) : (
                  <img
                    className="swiper-image"
                    style={{
                      width: "100%",
                      height: "100%",
                      maxHeight: "100%",
                    }}
                    src={item?.image}
                    alt=""
                  />
                )}
              </SwiperSlide>
            );
          })}
        </Swiper>
      ) : (
        <Swiper
          slidesPerView={1}
          spaceBetween={30}
          loop={true}
          pagination={{
            clickable: true,
          }}
          navigation={
            props.screenWidth && props.screenWidth === "sm" ? false : true
          }
          className="homepage-main-banner-swiper"
          dir="rtl"
        >
          {SliderData?.map((item) => {
            return (
              <SwiperSlide key={item.id}>
                <img className="swiper-image" src={item.image} alt="" />
              </SwiperSlide>
            );
          })}
        </Swiper>
      )}
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    banners: state.vendor.primaryBanners,
    direction: state.language.direction,
    screenWidth: state.layout.screenWidth,
  };
};

export default connect(mapStateToProps, null)(VendorMainSlider);
