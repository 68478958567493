import { type } from "../../types/types";

export default function reducer(
  state = {
    direction: "LTR",
    country: "United Arab Emirates",
    countryId: 1,
    coutriesList: [],
    language: "en",
    countryUrl: "uae",
  },
  action
) {
  switch (action.type) {
    case type.SET_COUNTRY:
      return { ...state, ...action.country };
    case type.SET_DIRECTION: {
      return { ...state, ...action.direction };
    }
    default:
      return state;
  }
}
