import React, { Fragment, useEffect, useState } from "react";
import { Row } from "react-bootstrap";
import WishlistMain from "../../components/customer/cart/WishlistMain";
import WebsiteHeader from "../../components/header/WebsiteHeader";
import { connect } from "react-redux";
import { set_cart_items, set_wish_list } from "../../redux/actions/CartAction";
import { apiJson } from "../../Api";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { set_user } from "../../redux/actions/AuthAction";
import { set_loading } from "../../redux/actions/LayoutAction";
import { useTranslation } from "react-i18next";

const WishlistPage = (props) => {
  const [dataWishlist, setDataWishlist] = useState(false);
  const { t } = useTranslation();

  let message = "";

  const showError = () => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  useEffect(() => {
    document.title = "My Wishlist";
    props.set_user({
      guestSignin: false,
    });
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
    props.set_loading(true);

    apiJson.cart.getWishList(props.countryID).then((response) => {
      props.set_loading(false);
      if (response.success) {
        props.set_wish_list({ wish_list: response.data });
        setDataWishlist(true);
      } else if (!response.status) {
        setDataWishlist(true);
        // eslint-disable-next-line
        message = response.message
          ? response.message !== ""
            ? response.message
            : t("errors.wrong")
          : t("errors.wrong");
        showError();
      } else {
        setDataWishlist(true);
        if (response.status >= 400 && response.status < 500) {
          message = response.message
            ? response.message
            : response.errors
            ? response.errors
            : t("errors.wrong");
          showError();
        } else {
          message = response.message
            ? response.message !== ""
              ? response.message
              : t("errors.wrong")
            : t("errors.wrong");
          showError();
        }
      }
    });

    apiJson.cart.getCartBanners(props.countryID).then((response) => {
      if (response.success) {
        props.set_cart_items({
          primaryBanners: response.data.filter(
            (banner) => banner.banner_type === "checkout_primary"
          ),
          secondaryBanners: response.data.filter(
            (banner) => banner.banner_type === "checkout_secondary"
          ),
        });
      } else if (!response.status) {
        message = response.message
          ? response.message !== ""
            ? response.message
            : t("errors.wrong")
          : t("errors.wrong");
        showError();
      } else {
        if (response.status >= 400 && response.status < 500) {
          message = response.message
            ? response.message
            : response.errors
            ? response.errors
            : t("errors.wrong");
          showError();
        } else {
          message = response.message
            ? response.message !== ""
              ? response.message
              : t("errors.wrong")
            : t("errors.wrong");
          showError();
        }
      }
    });
  }, []);

  return (
    <Fragment>
      <Row className="page-container no-gutters">
        <div className="w-100">
          <WebsiteHeader />
          <div
            className="content-container all-page-container d-flex  mx-auto"
            style={{ height: "calc(100vh - 135px)" }}
          >
            {dataWishlist ? (
              <WishlistMain
                wishlist={props.wish_list}
                currency={
                  props.country.toLowerCase() === "saudi arabia"
                    ? "SAR"
                    : props.country.toLowerCase() === "united arab emirates"
                    ? "AED"
                    : "EGP"
                }
              />
            ) : (
              <div className=""></div>
            )}
          </div>
        </div>
      </Row>
    </Fragment>
  );
};

const mapStateToProsp = (state) => {
  return {
    wish_list: state.cart.wish_list,
    country: state.language.country,
    countryID: state.language.countryId,
  };
};

const mapDispatchToProps = {
  set_wish_list,
  set_cart_items,
  set_user,
  set_loading,
};

export default connect(mapStateToProsp, mapDispatchToProps)(WishlistPage);
