import React, { Fragment, useEffect } from "react";
import WebsiteFooter from "../../components/Footer/WebsiteFooter";
import pagesbannerimg from "../../assets/images/footer-pages-banners/Returns-&-Refunds.jpg";

import WebsiteHeader from "../../components/header/WebsiteHeader";
import $ from "jquery";

const ReturnsRefunds = () => {
  useEffect(() => {
    window.document.title = "Return Refund";

    $("html, body").animate({ scrollTop: 0 }, "fast");
  }, []);
  return (
    <Fragment>
      <div className="product-home-page-desktop mx-auto">
        <WebsiteHeader />
        <div className="pagesbanner">
          <img
            src={pagesbannerimg}
            alt="category"
            style={{
              width: "100%",
              height: "100%",
              maxHeight: "400px",
            }}
          ></img>
        </div>
        <div className="content-wrapper all-page-container">
          <p className="mycenter">
            Want to return the product? Experience our hassle-free return
            policies.
          </p>

          <p>
          <b> What is our cancellation policy?</b>
          </p>
          <p>
            Cancellation before shipment: If the order or the item(s) that you
            want to cancel have not been shipped yet, you can write to our
            customer support team on customerservice@alisuq.com or call us on
            +971543064845 (Sunday to Thursday, 8AM to 5PM and Saturday, 8AM to
            12PM) In such cases, the order will be cancelled, and the money will
            be refunded to you within 7-14 days after the cancellation request
            is duly processed by us.
          </p>

          <p>
          <b> What is our cancellation policy?</b>
          </p>
          <p>
            Cancellation before shipment: If the order or the item(s) that you
            want to cancel have not been shipped yet, you can write to our
            customer support team on customerservice@alisuq.com or call us on
            +971543064845 (Sunday to Thursday, 8AM to 5PM and Saturday, 8AM to
            12PM) In such cases, the order will be cancelled, and the money will
            be refunded to you within 7-14 days after the cancellation request
            is duly processed by us.
          </p>
          <p>
          <b> How long do I have to return an item?</b>
          </p>
          <p>
            You have 7 days from the day of delivery to return any item that
            meets our return policy.
          </p>

          <p>
          <b> What is our return policy?</b>
          </p>
          <p>
            Thank you for shopping at Alisuq, we hope you enjoyed your
            experience! Alisuq offers its customers an ’Easy return policy’,
            wherein you can raise a return/refund request of a product within 7
            days of its delivery. We also accept partial returns wherein you can
            raise a return request for one or all products in your order. - We
            will refund any item(s) accompanied by original proof of purchase,
            such as your receipt or tax invoice. - The returned item(s) must be
            in good and unused condition, in its original sealed packaging and
            with all paperwork, parts and accessories that came along with it. -
            There may be some other items for which returns may not be possible
            or a restocking fee may apply.
          </p>

          <p>
          
            <b>
              {" "}
              How will I get refunded for the cancelled orders and how long will
              this process take?
            </b>
          </p>
          <p>
            In case of cancellation before shipment, we process the refund
            within 24-48 business hours after receiving the cancellation
            request. In case of cancellation once the shipment has already been
            dispatched or if it is being returned, we process the refund once
            the products have been received and verified at our warehouse.
          </p>
          <p>
            <b>
              - For payments done through credit/debit cards or net banking,
            </b>{" "}
            the refund will be processed through Alisuq Wallet within 24-48
            business hours of us receiving the products back, which can be used
            during future purchases.
          </p>
          <p>
            <b>- For cash on delivery transactions,</b> the refund
            will be processed through Alisuq Wallet within 24-48 business hours
            of us receiving the products back, which can be used during future
            purchases.
          </p>

          <p>
          
            <b>
              {" "}
              What if I used discount vouchers during the time of payment and I
              have to cancel my order?
            </b>
          </p>
          <p>
            Discount vouchers are intended for one-time use only and shall be
            treated as used even if you cancel the order.
          </p>

          <p>
            <b> Which are the items that cannot be returned?</b>
          </p>
          <p>
            <b>
              Returns will not be accepted under the following conditions:
            </b>
          </p>
          <p>- Product is damaged due to misuse/overuse</p>
          <p>
            - Returned without original packaging including, price tags, labels,
            original packing, freebies and other accessories or if original
            packaging is damaged
          </p>
          <p>- Serial Number is tampered with.</p>
          <p>- Product is used or altered</p>
          <p>- Free product provided by brand</p>

          <p>
            <b>Categories not eligible for Return:</b>
          </p>
          <p>
            - Customized Products cannot be returned since they are available
            during select promotions and ordered on demand.
          </p>
          <p>- Brand specified which are not returnable. Check List</p>

          <p>
            <b>Please note:</b> For certain marketing campaigns or
            mega sale periods, special return/refund rules may apply.
            Information regarding this is visible on the promotion banner. For
            any clarification, please feel free to contact our customer care.
          </p>
          <p>
            <b>
              I have received a damaged or defective item/wrong product in my
              order, how should I proceed?
            </b>
          </p>
          <p>
            Our shipments go through rigorous quality check processes before
            they leave our warehouse. However, in the rare case that your
            product is damaged during shipment or transit, you can request for a
            replacement or cancellation and refund.
          </p>
          <p>
            If you have received an item in a damaged/defective condition or
            have been sent a wrong product, you can follow a few simple steps to
            initiate you can return/ refund within 7 business days of receiving
            the order:
          </p>

          <p>
            <b>- Step 1:</b> Contact our Customer Support team via
            email ( customerservice@alisuq.com ) within 7 business days of
            receiving the order.
          </p>
          <p>
            <b>- Step 2:</b> Provide us with your order ID details and
            your request to replace the defective/wrong items in your order.
            Kindly share an image of the product and the invoice for our
            reference.
          </p>
          <p>
            <b>- Step 3:</b> We will pick up the products within 2-4
            business days. We will initiate the refund or replacement process
            only if the products are received by us in their original packaging
            with their seals, labels and barcodes intact.
          </p>

          <p>
            <b>Note:</b> If it is a case of replacement, it is subject
            to the availability of stock. In case that a replacement may not be
            available, we will refund you the amount in Alisuq Wallet.
          </p>

          <p>
            <b>Can I return part of my order?</b>
          </p>
          <p>
            Yes. A return can be created at item level and if you have ordered
            multiple items, you can initiate a return/refund for any individual
            item
          </p>

          <p>
            <b>
              How will I get refunded for the returned orders and how long
              will this process take?
            </b>
          </p>
          <p>
            In case of a return/replacement/refund, we process the refund once
            the products have been received and verified at our warehouse.
          </p>

          <p>
            -{" "}
            <b>
              For payments done through credit/debit cards or net banking,
            </b>{" "}
            the refund will be processed through Alisuq Wallet within 24-48
            business hours of us receiving the products back, which can be used
            during future purchases.
          </p>
          <p>
            - <b>For cash on delivery transactions,</b> the refund
            will be processed through Alisuq Wallet within 24-48 business hours
            of us receiving the products back, which can be used during future
            purchases.
          </p>

         
            <b>Will I get my VAT refunded when I return items?</b>
            <p> The VAT amount will be included in your total refund amount. Make
            sure to show your proof of purchase - receipt or tax invoice - when
            returning your item(s).
          </p>

          
            <b>Is there a cash on delivery charge?</b>
            <p>We will charge
            an additional AED10 for cash on delivery orders.
          </p>
          <div className="w-100">
            <WebsiteFooter />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ReturnsRefunds;
