import { type } from "../../types/types";
const initialState = {
  category: [],
  selectedCat: {},
  items: {},
  products: {},
  services: {},
  activeTab: "products",
  page: 1,
  recordPerPage: 30,
  originalItems: [],
  sortBy_en: [],
  sortBy_ar: [],
  display_en: [],
  display_ar: [],
  sortVal: "",
  displayVal: "",
  allCategories: [],
  banners: [],
  price_high_to_low: false,
  price_low_to_high: false,
  new_arrivals: false,
  discount_sort: true
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case type.SET_ALL_CATEGORIES:
      return {
        ...state,
        ...action.categories,
      };
    default:
      return state;
  }
}
