import React, { Fragment, useRef, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { FaCheckCircle } from "react-icons/fa";

import "../../../assets/styles/customer/cart/cartsidebarproductcard.scss";
import QuantityCounter from "../checkout/QuantityCounter";
import { apiJson } from "../../../Api";
import { set_cart_items } from "../../../redux/actions/CartAction";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTranslation } from "react-i18next";
import { getCurrencyOfCountry, getDesktopImage } from "../../../utils/utils";
import moment from "moment";
import { MdDelete } from "react-icons/md";
import ClipLoader from "react-spinners/ClipLoader";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";

const SidebarProductCard = (props) => {
  const { t } = useTranslation();
  const { product } = props;

  const [updatingQuantity, setUpdatingQuantity] = useState(false);
  const [updatingStartDate, setUpdatingStartDate] = useState(false);
  const [updatingEndDate, setUpdatingEndDate] = useState(false);
  const [removeItemLoader, setRemoveItemLoader] = useState(false);
  let message = "";
  const qc = useRef();

  const showError = () => {
    toast.error(message, {
      position: "bottom-left",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  function renderDay(props, currentDate, selectedDate) {
    return (
      <td stlyle={{ fontSize: "13px" }} {...props}>
        {currentDate.date()}
      </td>
    );
  }
  function renderMonth(props, month, year, selectedDate) {
    return <td {...props}>{month}</td>;
  }
  function renderYear(props, year, selectedDate) {
    return <td {...props}>{year % 100}</td>;
  }
  const removeItem = (id) => {
    setUpdatingQuantity(true);
    apiJson.cart.removeItem(id, props.countryId).then((response) => {
      if (response.success) {
        props.set_cart_items({
          cart_items: response.data,
        });

        setUpdatingQuantity(false);
      } else if (!response.status) {
        message = t("errors.wrong");
        showError();
        setUpdatingQuantity(false);
      } else {
        setUpdatingQuantity(false);
        if (response.status >= 400 && response.status < 500) {
          message = response.message
            ? response.message
            : response.errors
            ? response.errors
            : t("errors.wrong");
          showError();
        } else {
          message = response.message
            ? response.message !== ""
              ? response.message
              : t("errors.wrong")
            : t("errors.wrong");
          showError();
        }
      }
    });
  };

  const removeItemFromCart = (id) => {
    setRemoveItemLoader(true);
    apiJson.cart.removeItem(id, props.countryId).then((response) => {
      if (response.success) {
        props.set_cart_items({
          cart_items: response.data,
        });
        setRemoveItemLoader(false);
      } else if (!response.status) {
        message = t("errors.wrong");
        showError();
        setRemoveItemLoader(false);
      } else {
        setRemoveItemLoader(false);
        if (response.status >= 400 && response.status < 500) {
          message = response.message
            ? response.message
            : response.errors
            ? response.errors
            : t("errors.wrong");
          showError();
        } else {
          message = response.message
            ? response.message !== ""
              ? response.message
              : t("errors.wrong")
            : t("errors.wrong");
          showError();
        }
      }
    });
  };
  const updateQuantity = (opt, value, id) => {
    setUpdatingQuantity(true);
    apiJson.cart
      .updateProduct(
        id,
        {
          line_item: {
            quantity: value,
          },
        },
        props.countryId
      )
      .then((response) => {
        if (response.success) {
          props.set_cart_items({
            cart_items: response.data,
          });
        } else if (!response.status) {
          qc.current.setCounValue(product.quantity);

          message = t("errors.wrong");
          showError();
        } else {
          qc.current.setCounValue(product.quantity);

          if (response.status >= 400 && response.status < 500) {
            message = response.message
              ? response.message
              : response.errors
              ? response.errors
              : t("errors.wrong");
            showError();
          } else {
            message = response.message
              ? response.message !== ""
                ? response.message
                : t("errors.wrong")
              : t("errors.wrong");
            showError();
          }
        }

        setUpdatingQuantity(false);
      });
  };
  const updateStartTime = (id, val) => {
    setUpdatingStartDate(true);
    apiJson.cart
      .updateProduct(
        id,
        {
          line_item: {
            start_at: new Date(val).toString(),
            end_at: new Date(product.end_at).toString(),
          },
        },
        props.countryId
      )
      .then((response) => {
        setUpdatingStartDate(false);
        if (response.success) {
          props.set_cart_items({
            cart_items: response.data,
          });
        } else if (!response.status) {
          message = t("errors.wrong");
          showError();
        } else {
          if (response.status >= 400 && response.status < 500) {
            message = response.message
              ? response.message
              : response.errors
              ? response.errors
              : t("errors.wrong");
            showError();
          } else {
            message = response.message
              ? response.message !== ""
                ? response.message
                : t("errors.wrong")
              : t("errors.wrong");
            showError();
          }
        }
      });
  };
  const updateEndTime = (id, val) => {
    setUpdatingEndDate(true);
    apiJson.cart
      .updateProduct(
        id,
        {
          line_item: {
            start_at: new Date(product.start_at).toString(),
            end_at: new Date(val).toString(),
          },
        },
        props.countryId
      )
      .then((response) => {
        setUpdatingEndDate(false);
        if (response.success) {
          props.set_cart_items({
            cart_items: response.data,
          });
        } else if (!response.status) {
          message = t("errors.wrong");
          showError();
        } else {
          if (response.status >= 400 && response.status < 500) {
            message = response.message
              ? response.message
              : response.errors
              ? response.errors
              : t("errors.wrong");
            showError();
          } else {
            message = response.message
              ? response.message !== ""
                ? response.message
                : t("errors.wrong")
              : t("errors.wrong");
            showError();
          }
        }
      });
  };

  return (
    <Fragment>
      <Row className="cart-sidebar-product-card-container-desktop no-gutters">
        <Col className="main">
          <div className="image-container">
            <img
              className="image"
              src={getDesktopImage(
                product.orderable.featured_image_resized,
                product.orderable.featured_image
              )}
              alt="product"
            />
          </div>
        </Col>
        <Col className="ml-2 details-container">
          <div className="description">
            <p>
              {product?.orderable[`product_name_${props.language}`]
                ? product.orderable[`product_name_${props.language}`] + " "
                : ""}
              {product?.orderable[`name_${props.language}`]
                ? product.orderable[`name_${props.language}`] + " "
                : ""}
            </p>
          </div>
          <div className="sku">
            {product?.orderable[`sku`] ? product.orderable[`sku`] : ""}
          </div>
          <div className="qunatity-and-price">
            {product.quantity ? (
              <div className=" quantity-container">
                <QuantityCounter
                  ref={qc}
                  addValue={(value) => updateQuantity("add", value, product.id)}
                  subValue={(value) => updateQuantity("sub", value, product.id)}
                  value={product.quantity}
                  loading={updatingQuantity}
                  remove={() => removeItem(product.id)}
                />
              </div>
            ) : (
              <div className="d-flex flex-column">
                <div className="date-heading mb-1">
                  {t("item-detail.start-date") + ":"}
                </div>

                <div style={{ width: "200px" }} className="mb-2">
                  <Datetime
                    onChange={(val) => {
                      if (
                        moment(new Date(val)).format("YYYY-MM-DDTHH:mm") <
                        moment(new Date(product.end_at)).format(
                          "YYYY-MM-DDTHH:mm"
                        )
                      ) {
                        updateStartTime(product.id, val);
                      } else {
                        message = "Start date should be less than end date";
                        showError();
                      }
                    }}
                    value={new Date(product.start_at)}
                    renderDay={renderDay}
                    renderMonth={renderMonth}
                    renderYear={renderYear}
                    inputProps={{
                      disabled: updatingStartDate,
                    }}
                  />
                </div>
                <div className="date-heading mb-1">
                  {t("item-detail.end-date") + ":"}
                </div>

                <div style={{ width: "200px" }} className="mb-2">
                  <Datetime
                    onChange={(val) => {
                      if (
                        moment(new Date(product.start_at)).format(
                          "YYYY-MM-DDTHH:mm"
                        ) < moment(new Date(val)).format("YYYY-MM-DDTHH:mm")
                      ) {
                        updateEndTime(product.id, val);
                      } else {
                        message = "End date should be greater than start date";
                        showError();
                      }
                    }}
                    value={new Date(product.end_at)}
                    renderDay={renderDay}
                    renderMonth={renderMonth}
                    renderYear={renderYear}
                    inputProps={{
                      disabled: updatingEndDate,
                    }}
                  />
                </div>
              </div>
            )}
            <div className="price-container">
              {product.quantity && (
                <p className="para">
                  <span className="sp-count">{product.quantity} </span>
                  <span className="sp-sign">x</span>
                  <span className="sp-total">
                    {getCurrencyOfCountry(props.country)}{" "}
                    {(product.price / product.quantity).toFixed(2)}
                  </span>
                </p>
              )}
            </div>
          </div>
          {!product.quantity && (
            <div className="">
              <div className="price">
                {getCurrencyOfCountry(props.country)} {product.price}
              </div>
            </div>
          )}
        </Col>
        <div className="confirmation-container w-80">
          <div
            className="remove-btn"
            onClick={() => removeItemFromCart(product.id)}
            style={{ cursor: "pointer" }}
          >
            {removeItemLoader ? (
              <div className="d-flex justify-content-center align-items-center">
                <ClipLoader color="#000" size={17} />
              </div>
            ) : (
              <>
                <MdDelete className="mb-1" />
                {t("checkout.btn-remove")}
              </>
            )}
          </div>
          <div className={"checked"}>
            {t("cart-sidebar.added-to-cart")} <FaCheckCircle color="#3966e0" />
          </div>
        </div>
      </Row>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    language: state.language.language,
    country: state.language.country,
    countryId: state.language.countryId,
  };
};

const mapDispatchToProps = { set_cart_items };

export default connect(mapStateToProps, mapDispatchToProps)(SidebarProductCard);
