import React, { Fragment } from "react";

import "../../../assets/styles/component/mobile/MyAccount/sociallinksandterms.scss";

import { TiSocialFacebook } from "react-icons/ti";
import { TiSocialTwitter } from "react-icons/ti";
import { AiOutlineInstagram } from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa";
import { useTranslation } from "react-i18next";

const LinksAndTerms = (props) => {
  const { t } = useTranslation();
  return (
    <Fragment>
      <div className="social-links-and-terms">
        <div className="social-icons">
          <div className="inner-social">
            <TiSocialFacebook size={30} opacity="0.6" />
            <TiSocialTwitter size={30} opacity="0.6" />
            <AiOutlineInstagram size={30} opacity="0.6" />
            <FaLinkedinIn size={25} opacity="0.6" />
          </div>
        </div>
        {props.loggedIn && (
          <div className="terms-policies-all-rights">
            <div className="terms-first-row">
              <span className="terms-of-use">
                {t("my-account-logged-in.tou")}
              </span>
              <span>.</span>
              <span className="terms-of-sale">
                {t("my-account-logged-in.tos")}
              </span>
              <span>.</span>
              <span className="privacy-policy">
                {t("my-account-logged-in.pr-po")}
              </span>
            </div>
            <div className="policy-second-row">
              <span className="warranty-policy">
                {t("my-account-logged-in.warr-po")}
              </span>
              <span>.</span>
              <span className="return-policy">
                {t("my-account-logged-in.ret-po")}
              </span>
            </div>
            <div className="version-3rd-row">
              {t("my-account-logged-in.version")} 3.12 (937)
            </div>
            <div className="last-row">
              <span className="rights-reserved">
                {t("my-account-logged-in.all-rights")}
              </span>
            </div>
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default LinksAndTerms;
