import React from "react";
import UpperNavigation from "../../../components/mobile/UpperNavigation";
import BottomNav from "../../../components/mobile/BottomNav";

import "../../../assets/styles/mobile/layout/mobilelayoutrenderer.scss";

const MobileLayoutRenderer = (props) => {
  const { NavbarTop, BackBtn, CancelBtn, CloseIcon, NavbarBottom, SearchIcon } = props;
  return (
    <div
      className={`${
        NavbarTop && NavbarBottom ? "with-top-bar-and-bottom-bar" : ""
      } ${(NavbarTop === true) & (NavbarBottom === false) ? "with-top-bar" : ""}
         ${
           NavbarTop === false && NavbarBottom === true ? "with-bottom-nav" : ""
         }
         ${
           NavbarTop === false && NavbarBottom === false
             ? "without-top-bar-and-bottom-bar"
             : ""
         }
         `}
    >
      {NavbarTop && (
        <div className="app-bar">
          <UpperNavigation
            BackButton={BackBtn}
            CancelButton={CancelBtn}
            CloseIcon={CloseIcon}
            SearchIcon={SearchIcon}
          />
        </div>
      )}
      <div className={`page-content ${props.noPadding ? " pb-0" : ""}`}>
        {props.children}
      </div>
      {NavbarBottom && (
        <div className="bottom-nav">
          <BottomNav />
        </div>
      )}
    </div>
  );
};

export default MobileLayoutRenderer;
