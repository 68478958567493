import React, { useState } from "react";

import "../../../assets/styles/component/customer/itemDetail/ItemOverview.scss";
import Rating from "@material-ui/lab/Rating";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import { BsFillStarFill } from "react-icons/bs";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";

import free_returns_usp from "../../../assets/images/free_returns_usp.svg";
import trusted_shipping_usp_v2 from "../../../assets/images/trusted_shipping_usp_v2.svg";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { BsShieldLockFill } from "react-icons/bs";
import { useEffect } from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";

const StyledRating = withStyles({
  iconFilled: {
    color: "rgb(242, 153, 74)",
  },
  iconHover: {
    color: "rgb(242, 153, 74)",
  },
  iconEmpty: {
    color: "#404553",
  },
})(Rating);

function ItemOverview(props) {
  const [activeTab, setActiveTab] = useState("overview");
  const ratings = [0, 0, 0, 0, 0];
  const [rating_arr, setRatingsArr] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    props.data.comments.map((comment) => {
      if (parseInt(comment.rating) > 4.0 && parseInt(comment.rating) < 5.1) {
        ratings[0] = ratings[0] + 1;
      } else if (
        parseInt(comment.rating) > 3.0 &&
        parseInt(comment.rating) < 4.1
      ) {
        ratings[1] = ratings[1] + 1;
      } else if (
        parseInt(comment.rating) > 2.0 &&
        parseInt(comment.rating) < 3.1
      ) {
        ratings[2] = ratings[2] + 1;
      } else if (
        parseInt(comment.rating) > 1.0 &&
        parseInt(comment.rating) < 2.1
      ) {
        ratings[3] = ratings[3] + 1;
      } else {
        ratings[4] = ratings[4] + 1;
      }
      return comment;
    });
    setRatingsArr(ratings);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <div className="item-overview-desktop pb-5">
      <div
        className="d-flex flex-direction-column header-div mb-5"
        style={{ overflowX: "auto" }}
      >
        <span
          style={{ marginInlineStart: "0px" }}
          className={activeTab === "overview" ? "active-tab" : "text-style"}
          onClick={() => setActiveTab("overview")}
        >
          {t("item-detail.overview")}
        </span>
        <span
          className={activeTab === "features" ? "active-tab" : "text-style"}
          onClick={() => setActiveTab("features")}
        >
          {t("item-detail.features")}
        </span>
        <span
          className={activeTab === "benefits" ? "active-tab" : "text-style"}
          onClick={() => setActiveTab("benefits")}
        >
          {t("item-detail.benefits")}
        </span>
        <span
          className={activeTab === "howto" ? "active-tab" : "text-style"}
          onClick={() => setActiveTab("howto")}
        >
          {t("item-detail.howto")}
        </span>
        <span
          className={activeTab === "review" ? "active-tab" : "text-style"}
          onClick={() => setActiveTab("review")}
        >
          {t("item-detail.reviews-rating")}
        </span>
      </div>

      <div className="body-div">
        {activeTab === "overview" && (
          <div className="row">
            <div className="col-lg-6">
              <div
                className="mb-1 innerhtml"
                dangerouslySetInnerHTML={{
                  __html: props.data[`description_${props.language}`],
                }}
              ></div>
              {/* <div
                className="mb-1 innerhtml"
                dangerouslySetInnerHTML={{
                  __html: props.data[`product_description_${props.language}`],
                }}
              ></div>
              <div
                className="mb-1 innerhtml"
                dangerouslySetInnerHTML={{
                  __html: props.data[`product_whats_in_box_${props.language}`],
                }}
              ></div> */}
            </div>
            {
              props.data["video"] ?
                <>
                  {
                    props.width != "sm" && props.width != "md" ?
                      <div className="center-bar"></div>
                      :
                      <></>
                  }
                  <div className="col-lg-6">
                    <div
                      className="mb-1 innerhtml video-div"
                      dangerouslySetInnerHTML={{
                        __html: props.data["video"],
                      }}
                    ></div>
                  </div>
                </>
                :
                <></>
            }
          </div>
        )}

        {activeTab === "benefits" && props.data[`benefits_${props.language}`] && (
          <div
            className="mb-1 innerhtml"
            dangerouslySetInnerHTML={{
              __html: props.data[`benefits_${props.language}`],
            }}
          ></div>
        )}

        {activeTab === "features" && props.data[`features_${props.language}`] && (
          <div
            className="mb-1 innerhtml"
            dangerouslySetInnerHTML={{
              __html: props.data[`features_${props.language}`],
            }}
          ></div>
        )}

        {activeTab === "howto" && props.data[`how_to_use_${props.language}`] && (
          <div
            className="mb-1 innerhtml"
            dangerouslySetInnerHTML={{
              __html: props.data[`how_to_use_${props.language}`],
            }}
          ></div>
        )}

        {activeTab === "review" && (
          <div className="row m-0">
            <div
              className={`${props.width !== "md" ? "col-4" : "col-6"
                } review-child-one d-flex flex-direction-column small-screen-none`}
            >
              <div className="">
                <div className="heading ">{t("item-detail.overall-label")}</div>
                <div className="rating ">
                  {parseFloat(props.data.rating).toFixed(2)}
                </div>
                <div className="rating-star ">
                  <StyledRating
                    name="customized-empty"
                    defaultValue={2}
                    precision={0.5}
                    emptyIcon={<StarBorderIcon fontSize="inherit" />}
                    size="small"
                    readOnly
                    value={parseFloat(props.data.rating)}
                  />
                </div>
                <div className="rating-count">
                  {t("item-detail.b-o")} {props.data.rating_count}{" "}
                  {t("item-detail.rating")}
                </div>
              </div>

              <div className=" review-child-two">
                <div className="invisible checkbox-checked">
                  <CheckBoxIcon style={{ fontSize: "14px" }} />
                </div>
                <div className="num">5</div>
                <div className="">
                  <BsFillStarFill size="12" color="#F09952" />
                </div>
                <div className="rating-slider">
                  <div
                    style={{
                      width:
                        (rating_arr[0] * 100) /
                        (props.data.rating_count === 0
                          ? 100
                          : props.data.rating_count) +
                        "%",
                      height: "100%",
                      borderRadius: "5px",
                      background: "rgb(242, 153, 74)",
                    }}
                  ></div>
                </div>
                <div className="rating-count">
                  {"("}
                  {rating_arr[0]}
                  {")"}
                </div>

                <div className="invisible checkbox">
                  <CheckBoxOutlineBlankIcon style={{ fontSize: "14px" }} />
                </div>
                <div className="num">4</div>
                <div className="">
                  <BsFillStarFill size="12" color="#F09952" />
                </div>
                <div className="rating-slider">
                  <div
                    style={{
                      width:
                        (rating_arr[1] * 100) /
                        (props.data.rating_count === 0
                          ? 100
                          : props.data.rating_count) +
                        "%",
                      height: "100%",
                      borderRadius: "5px",
                      background: "rgb(242, 153, 74)",
                    }}
                  ></div>
                </div>
                <div className="rating-count">
                  {"("}
                  {rating_arr[1]}
                  {")"}
                </div>

                <div className="invisible checkbox">
                  <CheckBoxOutlineBlankIcon style={{ fontSize: "14px" }} />
                </div>
                <div className="num">3</div>
                <div className="">
                  <BsFillStarFill size="12" color="#F09952" />
                </div>
                <div className="rating-slider">
                  <div
                    style={{
                      width:
                        (rating_arr[2] * 100) /
                        (props.data.rating_count === 0
                          ? 100
                          : props.data.rating_count) +
                        "%",
                      height: "100%",
                      borderRadius: "5px",
                      background: "rgb(242, 153, 74)",
                    }}
                  ></div>
                </div>
                <div className="rating-count">
                  {"("}
                  {rating_arr[2]}
                  {")"}
                </div>

                <div className="invisible checkbox">
                  <CheckBoxOutlineBlankIcon style={{ fontSize: "14px" }} />
                </div>
                <div className="num">2</div>
                <div className="">
                  <BsFillStarFill size="12" color="#F09952" />
                </div>
                <div className="rating-slider">
                  <div
                    style={{
                      width:
                        (rating_arr[3] * 100) /
                        (props.data.rating_count === 0
                          ? 100
                          : props.data.rating_count) +
                        "%",
                      height: "100%",
                      borderRadius: "5px",
                      background: "rgb(242, 153, 74)",
                    }}
                  ></div>
                </div>
                <div className="rating-count">
                  {"("}
                  {rating_arr[3]}
                  {")"}
                </div>

                <div className="invisible checkbox-checked">
                  <CheckBoxIcon style={{ fontSize: "14px" }} />
                </div>
                <div className="num">1</div>
                <div className="">
                  <BsFillStarFill size="12" color="#F09952" />
                </div>
                <div className="rating-slider">
                  <div
                    style={{
                      width:
                        (rating_arr[4] * 100) /
                        (props.data.rating_count === 0
                          ? 100
                          : props.data.rating_count) +
                        "%",
                      height: "100%",
                      borderRadius: "5px",
                      background: "rgb(242, 153, 74)",
                    }}
                  ></div>
                </div>
                <div className="rating-count">
                  {"("}
                  {rating_arr[4]}
                  {")"}
                </div>
              </div>
            </div>

            <div
              className={`${props.width !== "md" ? "col-4" : "col-3"
                } text-div small-screen-none`}
            >
              <div className="heading ">{t("item-detail.how-to-review")}</div>
              <div className="body">{t("item-detail.htr-text")}</div>
            </div>

            <div
              className={`${props.width !== "md" ? "col-4" : "col-3"
                } text-div small-screen-none`}
            >
              <div className="heading ">{t("item-detail.reviews-from")}</div>
              <div className="body">{t("item-detail.rf-txt")}</div>
            </div>

            <div
              className="large-screen-none review-child-one d-flex flex-direction-column w-100"
              style={{ borderRight: "none" }}
            >
              <div className="">
                <div className="heading " style={{ fontSize: "13px" }}>
                  {t("item-detail.overall-label")}
                </div>
                <div className="rating " style={{ fontSize: "13px" }}>
                  {parseFloat(props.data.rating).toFixed(2)}
                </div>
                <div className="rating-star ">
                  <StyledRating
                    name="customized-empty"
                    defaultValue={2}
                    precision={0.5}
                    emptyIcon={<StarBorderIcon fontSize="inherit" />}
                    size="small"
                    readOnly
                    value={parseFloat(props.data.rating)}
                  />
                </div>
                <div className="rating-count">
                  {t("item-detail.b-o")} {props.data.rating_count}{" "}
                  {t("item-detail.rating")}
                </div>
              </div>

              <div className=" review-child-two">
                <div className="invisible checkbox-checked">
                  <CheckBoxIcon style={{ fontSize: "14px" }} />
                </div>
                <div className="num">5</div>
                <div className="">
                  <BsFillStarFill size="12" color="#F09952" />
                </div>
                <div className="rating-slider">
                  <div
                    style={{
                      width:
                        (rating_arr[0] * 100) /
                        (props.data.rating_count === 0
                          ? 100
                          : props.data.rating_count) +
                        "%",
                      height: "100%",
                      borderRadius: "5px",
                      background: "rgb(242, 153, 74)",
                    }}
                  ></div>
                </div>
                <div className="rating-count">
                  {"("}
                  {rating_arr[0]}
                  {")"}
                </div>

                <div className="invisible checkbox">
                  <CheckBoxOutlineBlankIcon style={{ fontSize: "14px" }} />
                </div>
                <div className="num">4</div>
                <div className="">
                  <BsFillStarFill size="12" color="#F09952" />
                </div>
                <div className="rating-slider">
                  <div
                    style={{
                      width:
                        (rating_arr[1] * 100) /
                        (props.data.rating_count === 0
                          ? 100
                          : props.data.rating_count) +
                        "%",
                      height: "100%",
                      borderRadius: "5px",
                      background: "rgb(242, 153, 74)",
                    }}
                  ></div>
                </div>
                <div className="rating-count">
                  {"("}
                  {rating_arr[1]}
                  {")"}
                </div>

                <div className="invisible checkbox">
                  <CheckBoxOutlineBlankIcon style={{ fontSize: "14px" }} />
                </div>
                <div className="num">3</div>
                <div className="">
                  <BsFillStarFill size="12" color="#F09952" />
                </div>
                <div className="rating-slider">
                  <div
                    style={{
                      width:
                        (rating_arr[2] * 100) /
                        (props.data.rating_count === 0
                          ? 100
                          : props.data.rating_count) +
                        "%",
                      height: "100%",
                      borderRadius: "5px",
                      background: "rgb(242, 153, 74)",
                    }}
                  ></div>
                </div>
                <div className="rating-count">
                  {"("}
                  {rating_arr[2]}
                  {")"}
                </div>

                <div className="invisible checkbox">
                  <CheckBoxOutlineBlankIcon style={{ fontSize: "14px" }} />
                </div>
                <div className="num">2</div>
                <div className="">
                  <BsFillStarFill size="12" color="#F09952" />
                </div>
                <div className="rating-slider">
                  <div
                    style={{
                      width:
                        (rating_arr[3] * 100) /
                        (props.data.rating_count === 0
                          ? 100
                          : props.data.rating_count) +
                        "%",
                      height: "100%",
                      borderRadius: "5px",
                      background: "rgb(242, 153, 74)",
                    }}
                  ></div>
                </div>
                <div className="rating-count">
                  {"("}
                  {rating_arr[3]}
                  {")"}
                </div>

                <div className="invisible checkbox-checked">
                  <CheckBoxIcon style={{ fontSize: "14px" }} />
                </div>
                <div className="num">1</div>
                <div className="">
                  <BsFillStarFill size="12" color="#F09952" />
                </div>
                <div className="rating-slider">
                  <div
                    style={{
                      width:
                        (rating_arr[4] * 100) /
                        (props.data.rating_count === 0
                          ? 100
                          : props.data.rating_count) +
                        "%",
                      height: "100%",
                      borderRadius: "5px",
                      background: "rgb(242, 153, 74)",
                    }}
                  ></div>
                </div>
                <div className="rating-count">
                  {"("}
                  {rating_arr[4]}
                  {")"}
                </div>
              </div>
            </div>

            <div className="comments-heading-cont mt-5">
              <div className="comments-count">
                {props.data?.comments?.length}{" "}
                {t("item-detail.customer-reviews")}
              </div>
              <span className="m-5">
                {props.data.comments.map((comment, index) => (
                  <div className="comment-body-cont" key={comment.id}>
                    <div className="comment-header">
                      <div className="name-avatar">
                        {
                          comment.display_name ?
                            comment.display_name.slice(0, 1)[0]
                              .toUpperCase()
                            :
                            comment[`user_name_${props.language}`]
                              .slice(0, 1)[0]
                              .toUpperCase()
                        }
                      </div>

                      <div className="user-data">
                        <span className="">
                          <div className="name">
                            {
                              comment.display_name ?
                                comment.display_name
                                :
                                comment[`user_name_${props.language}`]
                            }
                          </div>
                          <div>
                            <StyledRating
                              name="customized-empty"
                              defaultValue={2}
                              precision={0.5}
                              emptyIcon={<StarBorderIcon fontSize="inherit" />}
                              size="small"
                              readOnly
                              value={parseFloat(comment.rating)}
                            />
                          </div>
                          {/* <div className="date">
                            {moment(new Date(comment.created_at)).format("ll")}
                          </div> */}
                        </span>
                        <span className="varified-tag">
                          <span className="icon">
                            <CheckCircleIcon style={{ fontSize: "15px" }} />
                          </span>
                          <span className="text">
                            {t("item-detail.verified")}
                          </span>
                        </span>
                      </div>
                    </div>
                    <div className="comment-body">{comment.description}</div>
                  </div>
                ))}
              </span>
            </div>
          </div>
        )}
      </div>

      <div className="large-screen-none row no-gutters mb-5 mt-3">
        <div className="d-flex display-column  align-items-center col-4">
          <div className="">
            <BsShieldLockFill color="#ede009" />
          </div>

          <div className="w-90">
            <div className="vendure-text w-100">
              {t("item-detail.secure-shopping")}
            </div>
            <div className="returns-detail-text w-100">
              {t("item-detail.data-protected")}
            </div>
          </div>
        </div>

        <div className="d-flex display-column align-items-center col-4">
          <div className="w-30">
            <img width="100%" height="100%" src={free_returns_usp} alt="" />
          </div>

          <div className="w-90">
            <div className="vendure-text w-100">
              {t("item-detail.free-ret")}
            </div>
            <div className="returns-detail-text w-100">
              {t("item-detail.fr-text")}
            </div>
          </div>
        </div>

        <div className="d-flex display-column align-items-center col-4">
          <div className="w-30">
            <img
              width="100%"
              height="100%"
              src={trusted_shipping_usp_v2}
              alt=""
            />
          </div>

          <div className="w-90">
            <div className="vendure-text w-100">{t("item-detail.trusted")}</div>
            <div className="returns-detail-text w-100">
              {t("item-detail.ts-txt")}
            </div>
          </div>
        </div>
      </div>
  

  
    </div>

    
  );
}

const mapStateToProps = (state) => {
  return {
    width: state.layout.screenWidth,
    language: state.language.language,
  };
};

export default connect(mapStateToProps, null)(ItemOverview);
