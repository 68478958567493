import { type } from "../../types/types";
const initialState = {
  credit: [],
  tranfer: [],
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case type.SET_WALLET_CREDIT:
      return {
        ...state,
        ...action.credit,
      };
    case type.SET_WALLET_TRANSFERS:
      return {
        ...state,
        ...action.trasnfer,
      };
    default:
      return state;
  }
}
