import {
  Modal,
  Button
} from "react-bootstrap";
import "../../assets/styles/customer/variants-modal.scss";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { useTranslation } from "react-i18next";
import { apiJson } from "../../Api";
import { toast } from "react-toastify";
import { useOutsideAlerter } from "../../utils/CustomHooks";
import CartSidebar from "./cart/CartSidebar";
import { set_cart_items } from "../../redux/actions/CartAction";
import ClipLoader from "react-spinners/ClipLoader";
import { FaWhatsapp } from "react-icons/fa";
import Spinner from 'react-bootstrap/Spinner';

const en_translation = require("../../assets/locales/en/translation.json");
const ar_translation = require("../../assets/locales/ar/translation.json");

function VariantsModal(props) {
  const sideCartRef = useRef(null);
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();
  const [selected_sku, setSelectedSku] = useState(props.selected_sku);
  const [quantity, setQuantity] = useState(1);
  const [buyNowClicked, setBuyNowClicked] = useState(false);
  const [startDate, setStartDate] = useState(
    new Date(new Date().setSeconds(0))
  );
  const [endDate, setEndDate] = useState(
    new Date(new Date(Date.now() + 3600 * 1000 * 24).setSeconds(0))
  );
  const [adding, setAdding] = useState(false);
  const [showCart, setShowCart] = useState(false);
  const [quantityVal, setQuantityVal] = useState(0);
  useOutsideAlerter(sideCartRef, setShowCart);
  const [checkout_redirect, setCheckoutRedirect] = useState(false);
  let message = "";

  const showError = () => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  useEffect(() => {
    setLoading(true);
    setSelectedSku(props.selected_sku);
  }, [props.selected_sku]);

  useEffect(() => {
    if (props.product?.variants?.filter(v => v.sku == props.selected_sku)[0]?.quantity) {
      setQuantityVal(
        props.product?.variants?.filter(v => v.sku == props.selected_sku)[0]?.quantity
          ? Array.from({ length: props.product?.variants?.filter(v => v.sku == props.selected_sku)[0]?.quantity }, (_, i) => i + 1)
          : undefined
      );
      props.product?.variants?.filter(v => v.sku == props.selected_sku)[0]?.quantity && setQuantity("1");
    }
    setLoading(false);
  }, [props.product]);

  const onVariantSelected = (sku) => {
    setSelectedSku(sku);
  }

  const addToCart = () => {
    let variant = props.product?.variants.filter(variant => variant.sku == selected_sku)[0];

    if (props.product?.product_type == "wholesale") {
      if (variant.qty3range_to) {
        if (parseInt(quantity) > parseInt(variant.qty3range_to)) {
          message = "This product is not available in this quantity. Maximum available stock is " + variant.qty3range_to + ".";
          showError();
        }
        else {
          addToCartApiMethod(variant);
        }
      }
      else if (variant.qty2range_to) {
        if (parseInt(quantity) > parseInt(variant.qty2range_to)) {
          message = "This product is not available in this quantity. Maximum available stock is " + variant.qty2range_to + ".";
          showError();
        }
        else {
          addToCartApiMethod(variant);
        }
      }
      else {
        if (parseInt(quantity) > parseInt(variant.qty1range_to)) {
          message = "This product is not available in this quantity. Maximum available stock is " + variant.qty1range_to + ".";
          showError();
        }
        else {
          addToCartApiMethod(variant);
        }
      }
    }
    else {
      addToCartApiMethod(variant);
    }
  };

  const addToCartApiMethod = (variant) => {
    setAdding(true);

    apiJson.cart
      .addToCart(
        props.product?.product_type == "wholesale" ?
          {
            line_item: {
              quantity: parseInt(quantity.label ? quantity.label : quantity),
              orderable_type: "Variant",
              orderable_id: variant.id,
              product_type: "wholesale"
            }
          }
          :
          props.product?.type === "Product"
            ? {
              line_item: {
                quantity: parseInt(quantity.label ? quantity.label : quantity),
                orderable_type: "Variant",
                orderable_id: variant.id,
              },
            }
            : {
              line_item: {
                orderable_type: "Service",
                orderable_id: props.product?.id,
                start_at: new Date(startDate).toString(),
                end_at: new Date(endDate).toString(),
              },
            },
        props.countryId
      )
      .then((response) => {
        setAdding(false);
        if (response.success) {
          if (
            localStorage.getItem("access_token") ||
            localStorage.getItem("user_key") === response.data.user_key
          ) {
            setShowCart(true);
          } else {
            localStorage.setItem("user_key", response.data.user_key);
            setShowCart(true);
          }
        } else if (!response.status) {
          message = t("errors.wrong");
          showError();
        } else {
          if (response.status >= 400 && response.status < 500) {
            message = response.message
              ? response.message
              : response.errors
                ? response.errors
                : t("errors.wrong");
            showError();
          } else {
            message = response.message
              ? response.message !== ""
                ? response.message
                : t("errors.wrong")
              : t("errors.wrong");
            showError();
          }
        }
      });
  }

  const buyNow = () => {
    let variant = props.product?.variants.filter(variant => variant.sku == selected_sku)[0];

    if (props.product?.product_type == "wholesale") {
      if (variant.qty3range_to) {
        if (parseInt(quantity) > parseInt(variant.qty3range_to)) {
          message = "This product is not available in this quantity. Maximum available stock is " + variant.qty3range_to + ".";
          showError();
        }
        else {
          buyNowApiMethod(variant);
        }
      }
      else if (variant.qty2range_to) {
        if (parseInt(quantity) > parseInt(variant.qty2range_to)) {
          message = "This product is not available in this quantity. Maximum available stock is " + variant.qty2range_to + ".";
          showError();
        }
        else {
          buyNowApiMethod(variant);
        }
      }
      else {
        if (parseInt(quantity) > parseInt(variant.qty1range_to)) {
          message = "This product is not available in this quantity. Maximum available stock is " + variant.qty1range_to + ".";
          showError();
        }
        else {
          buyNowApiMethod(variant);
        }
      }
    }
    else {
      buyNowApiMethod(variant);
    }
  };

  const buyNowApiMethod = (variant) => {
    setBuyNowClicked(true);
    apiJson.cart
      .addToCart(
        props.product?.product_type == "wholesale" ?
          {
            line_item: {
              quantity: parseInt(quantity.label ? quantity.label : quantity),
              orderable_type: "Variant",
              orderable_id: variant.id,
              product_type: "wholesale"
            }
          }
          :
          props.product?.type === "Product"
            ? {
              line_item: {
                quantity: parseInt(quantity.label ? quantity.label : quantity),
                orderable_type: "Variant",
                orderable_id: variant.id,
              },
            }
            : {
              line_item: {
                orderable_type: "Service",
                orderable_id: props.product?.id,
                start_at: new Date(startDate).toString(),
                end_at: new Date(endDate).toString(),
              },
            },
        props.countryId
      )
      .then((response) => {
        setBuyNowClicked(false);
        if (response.success) {
          if (
            localStorage.getItem("access_token") ||
            localStorage.getItem("user_key") === response.data.user_key
          ) {
            // setShowCart(true);
          } else {
            localStorage.setItem("user_key", response.data.user_key);
            // setShowCart(true);
          }

          props.set_cart_items({ cart_items: response.data })

          // move to checkout
          if (props.auth) {
            apiJson
              .select.getDefault()
              .then(resp => {
                if (resp.success) {
                  if (resp.data.length > 0) {
                    const request = {
                      order: {
                        address_id: resp.data[0].id,
                        is_delivery: 1
                      },
                    };

                    apiJson.select
                      .varifyAdress(request, props.countryId)
                      .then((response) => {
                        if (response.success) {
                          props.set_cart_items({
                            order: {
                              address: resp.data[0],
                              id: response.data.id,
                              createdAddress: response.data,
                            },
                          });
                          setCheckoutRedirect({
                            value: true,
                            path:
                              "/" + props.countryUrl + "-" + props.language + "/checkout",
                          });
                        } else if (!response.status) {
                          message = t("errors.wrong");
                          showError();
                        } else {
                          if (response.status >= 400 && response.status < 500) {
                            message = response.message
                              ? response.message
                              : response.errors
                                ? response.errors
                                : t("errors.wrong");
                            showError();
                          } else {
                            message = response.message
                              ? response.message !== ""
                                ? response.message
                                : t("errors.wrong")
                              : t("errors.wrong");
                            showError();
                          }
                        }
                      });
                  }
                  else {
                    props.set_cart_items({
                      order: {
                        address: null,
                        id: null,
                        createdAddress: null,
                      },
                    });
                    setCheckoutRedirect({
                      value: true,
                      path:
                        "/" + props.countryUrl + "-" + props.language + "/checkout",
                    });
                  }
                }
              });
          }
          else {
            props.set_cart_items({
              order: {
                address: null,
                id: null,
                createdAddress: null,
              },
            });
            setCheckoutRedirect({
              value: true,
              path:
                "/" + props.countryUrl + "-" + props.language + "/checkout",
            });
          }
          // move to checkout end

        } else if (!response.status) {
          message = t("errors.wrong");
          showError();
        } else {
          if (response.status >= 400 && response.status < 500) {
            message = response.message
              ? response.message
              : response.errors
                ? response.errors
                : t("errors.wrong");
            showError();
          } else {
            message = response.message
              ? response.message !== ""
                ? response.message
                : t("errors.wrong")
              : t("errors.wrong");
            showError();
          }
        }
      });
  }

  return (
    <Modal
      {...props}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="variants-modal"
    >
      <Modal.Body>
        {
          loading ?
            <div className="d-flex align-items-center justify-content-center"
              style={{
                minHeight: "300px"
              }}>
              <Spinner
                animation="grow"
                style={{
                  color: "#742013"
                }} />
              <Spinner
                animation="grow"
                style={{
                  color: "#742013"
                }} />
              <Spinner
                animation="grow"
                style={{
                  color: "#742013"
                }} />
            </div>
            :
            <div
              className="row m-0"
              dir={
                props.language == "ar" ?
                  "rtl"
                  :
                  "ltr"
              }>
              <div className="col-6 text-center py-4">
                <img
                  src={props.product?.variants.filter(variant => variant.sku == selected_sku)[0]?.featured_image}
                  className="img img-fluid"
                  style={{
                    maxHeight: "300px",
                    margin: "auto"
                  }}
                />
              </div>
              <div className="col-6 info">
                <p className={
                  "product-title"
                  +
                  (props.language == "ar" ? " text-right" : "")
                }>
                  {props.product ? props.product['name_' + props.language] : ""}
                  {" "}
                  {
                    props.product?.variants.filter(variant => variant.sku == selected_sku).length ?
                      props.product?.variants.filter(variant => variant.sku == selected_sku)[0]['name_' + props.language]
                      :
                      ""
                  }
                </p>
                <p className={
                  "sku"
                  +
                  (props.language == "ar" ? " text-right" : "")
                }>SKU:&nbsp;{
                    props.product?.variants.filter(variant => variant.sku == selected_sku).length ?
                      props.product?.variants.filter(variant => variant.sku == selected_sku)[0].sku
                      :
                      ""
                  }</p>
                {
                  props.product?.is_inquiry ?
                    <></>
                    :
                    <div>
                      <div className="d-flex align-items-center mt-4">
                        <span className="price-style">{
                          props.country.toLowerCase() === "saudi arabia"
                            ? "SAR"
                            : props.country.toLowerCase() === "united arab emirates"
                              ? "AED"
                              : "EGP"
                        } {
                            props.product?.variants.filter(variant => variant.sku == selected_sku).length ?
                              props.product?.variants.filter(variant => variant.sku == selected_sku)[0].discounted_price
                              :
                              ""}</span>
                        {
                          props.product?.variants.filter(variant => variant.sku == selected_sku)[0]?.discounted_price != props.product?.variants.filter(variant => variant.sku == selected_sku)[0]?.price ?
                            <span className="old-price"><s>{
                              props.country.toLowerCase() === "saudi arabia"
                                ? "SAR"
                                : props.country.toLowerCase() === "united arab emirates"
                                  ? "AED"
                                  : "EGP"}
                              {
                                props.product?.variants.filter(variant => variant.sku == selected_sku).length ?
                                  props.product?.variants.filter(variant => variant.sku == selected_sku)[0].price
                                  :
                                  ""
                              }</s></span>
                            :
                            <></>
                        }
                        <span className="inclusive-style">{
                          props.language == "en" ?
                            en_translation["item-detail"]["include-vat-text"]
                            :
                            ar_translation["item-detail"]["include-vat-text"]
                        }</span>
                      </div>
                      {
                        props.product?.variants.filter(variant => variant.sku == selected_sku)[0]?.discounted_price != props.product?.variants.filter(variant => variant.sku == selected_sku)[0]?.price ?
                          <div className="d-flex align-items-center">
                            <span style={{ fontSize: "14px" }}>{
                              props.language == "en" ?
                                en_translation["item-detail"]["saving"]
                                :
                                ar_translation["item-detail"]["saving"]
                            }</span>
                            <span className="ml-4 saving-style">{
                              props.country.toLowerCase() === "saudi arabia"
                                ? "SAR"
                                : props.country.toLowerCase() === "united arab emirates"
                                  ? "AED"
                                  : "EGP"
                            } {(parseFloat(props.product?.variants.filter(variant => variant.sku == selected_sku)[0].price) - parseFloat(props.product?.variants.filter(variant => variant.sku == selected_sku)[0]?.discounted_price)).toFixed(2)}</span>
                            <span className="off-perc-style">{
                              (100
                                -
                                ((props.product?.variants.filter(variant => variant.sku == selected_sku)[0]?.discounted_price * 100)
                                  /
                                  props.product?.variants.filter(variant => variant.sku == selected_sku)[0]?.price)
                              ).toFixed(2)}% off</span>
                          </div>
                          :
                          <></>
                      }
                    </div>
                }
                <div className={
                  "mb-3 mt-3 variatspace"
                  +
                  (props.language == "ar" ? " text-right" : "")
                }>
                  <span className="variation-text">{
                    props.language == "en" ?
                      en_translation["item-detail"]["variation"]
                      :
                      ar_translation["item-detail"]["variation"]
                  }: <strong>{props.product?.variants.filter(variant => variant.sku == selected_sku)[0]?.variation}</strong></span>
                </div>
                <div className="city-cont">
                  {
                    <Swiper
                      slidesPerView={4} // or 'auto'
                      slidesPerColumn={1}
                      slidesPerColumnFill="row"
                      spaceBetween={1}
                      pagination={false}
                      navigation={props.product?.variants.length > 4 ? true : false}
                    >
                      {props.product?.variants.map((ele, index) => {
                        return (
                          <SwiperSlide className="vrpop"
                            onClick={(e) => e.preventDefault()}
                            key={ele.id}
                          >
                            <p className={
                              (ele.sku == selected_sku ?
                                "selected-span"
                                :
                                "simple-span") +
                              " px-3 py-2 mb-2"
                            }
                              onClick={
                                () => onVariantSelected(ele.sku)
                              }
                            >
                              <img
                                src={ele?.featured_image_resized[0]}
                                style={{
                                  objectFit: "contain",
                                  maxWidth: "100%",
                                  height: "100%"
                                }}
                                alt=""
                              />
                            </p>
                          </SwiperSlide>
                        );
                      })}
                    </Swiper>
                  }
                </div>
                <div className="quantity-cont">
                  {(props.product?.variants?.filter(v => v.sku == selected_sku)[0]?.quantity && !props.product?.is_inquiry) || props.product?.product_type == "wholesale" && (
                    <div className="quantity-heading small-screen-none">
                      {t("item-detail.quantity")}
                    </div>
                  )}
                  <div className="quantity-body ">
                    <div className="d-flex align-items-center">
                      {
                        props.product?.product_type == "wholesale" ?
                          <>
                            <label className="m-0">{
                              props.language == "en" ?
                                en_translation["item-detail"]["quantity"]
                                :
                                ar_translation["item-detail"]["quantity"]
                            }:&nbsp;</label>
                            <span className="dropdown-cont">
                              <input
                                type="text"
                                className="form-control"
                                value={quantity}
                                style={{ width: "60px" }}
                                onChange={(e) => setQuantity(e.target.value)}
                              />
                            </span>
                          </>
                          :
                          props.product?.variants?.filter(v => v.sku == selected_sku)[0]?.quantity && !props.product?.is_inquiry ?
                            <>
                              <label className="m-0">{
                                props.language == "en" ?
                                  en_translation["item-detail"]["quantity"]
                                  :
                                  ar_translation["item-detail"]["quantity"]
                              }:&nbsp;</label>
                              <span className="dropdown-cont">
                                <Dropdown
                                  options={quantityVal}
                                  onChange={(e) => setQuantity(e)}
                                  value={quantity}
                                  placeholderClassName="dropdownPlaceholder"
                                  menuClassName="dropdownMenu"
                                  className="dropdown-outer"
                                />
                              </span>
                            </>
                            :
                            <></>
                      }
                    </div>

                    {props.product?.type === "Product" ? (
                      props.product?.is_inquiry ?
                        <a
                          href={encodeURI(`//api.whatsapp.com/send?phone=971543064845&text=Hi, I would like to inquire about the product. I am interested in : ${props.product['name_' + props.language]}. Check here: ${process.env.REACT_APP_WEBSITE_URL + "/" + props.countryUrl + "-" + props.language + "/" + props.product.category_title_en?.toLowerCase().replace(/ /g, "-") + "/" + props.product?.slug}?sku=${props.product?.variants?.filter(v => v.sku == selected_sku)[0]?.sku}`)}
                          target="_blank"
                          className={`${!props.product?.variants?.filter(v => v.sku == selected_sku)[0]?.quantity ? "get-inquiry-btn w-100" : "get-inquiry-btn w-100"
                            }`}
                        >
                          <span className="add-to-cart-text w-100">
                            {adding ? (
                              <div className="d-flex justify-content-center align-items-center w-100 h-100">
                                <ClipLoader color="#fff" loading size={20} />
                              </div>
                            ) : props.product?.variants?.filter(v => v.sku == selected_sku)[0]?.quantity ? (
                              <div className="d-flex align-items-center w-100">
                                <FaWhatsapp
                                  size={24}
                                  color="white"
                                />
                                <p className="my-0 mx-auto">{t("item-detail.get-inquiry")}</p>
                              </div>
                            ) : (
                              <div className="d-flex align-items-center w-100">
                                <FaWhatsapp
                                  size={24}
                                  color="white"
                                />
                                <p className="my-0 mx-auto">{t("item-detail.get-inquiry")}</p>
                              </div>
                            )}
                          </span>
                        </a>
                        :
                        <span
                          onClick={() => {
                            if (props.product?.variants?.filter(v => v.sku == selected_sku)[0]?.quantity || props.product?.product_type == "wholesale") {
                              addToCart()
                            }
                          }}
                          className={`${props.product?.variants?.filter(v => v.sku == selected_sku)[0]?.quantity || props.product?.product_type == "wholesale" ? "add-to-cont" : "out-of-stock"
                            }`}
                        >
                          <span className="add-to-cart-text">
                            {adding ? (
                              <div className="d-flex justify-content-center align-items-center w-100 h-100">
                                <ClipLoader color="#fff" loading size={20} />
                              </div>
                            ) :
                              props.product?.variants?.filter(v => v.sku == selected_sku)[0]?.quantity || props.product?.product_type == "wholesale" ? (
                                props.language == "en" ?
                                  en_translation["item-detail"]["add-to-cart"]
                                  :
                                  ar_translation["item-detail"]["add-to-cart"]
                              ) : (
                                t("item-detail.out-of-stock")
                              )}
                          </span>
                        </span>
                    ) : (
                      <span onClick={() => addToCart()} className="add-to-cont">
                        <span className="add-to-cart-text">
                          {adding ? (
                            <div className="d-flex justify-content-center align-items-center w-100 h-100">
                              <ClipLoader color="#fff" loading size={20} />
                            </div>
                          ) : (
                            props.language == "en" ?
                              en_translation["item-detail"]["add-to-cart"]
                              :
                              ar_translation["item-detail"]["add-to-cart"]
                          )}
                        </span>
                      </span>
                    )}
                  </div>
                  {
                    props.product?.type == "Product" ?
                      props.product?.is_inquiry ?
                        <></>
                        :
                        props.product?.variants?.filter(v => v.sku == selected_sku)[0]?.quantity || props.product?.product_type == "wholesale" ?
                          <span
                            onClick={() => {
                              if (props.product?.variants?.filter(v => v.sku == selected_sku)[0]?.quantity || props.product?.product_type == "wholesale") {
                                buyNow()
                              }
                            }}
                            className={`${props.product?.variants?.filter(v => v.sku == selected_sku)[0]?.quantity || props.product?.product_type == "wholesale" ? "buy-now-btn" : "out-of-stock"
                              }`}
                          >
                            <span className="buy-now-text">
                              {buyNowClicked ? (
                                <div className="d-flex justify-content-center align-items-center w-100 h-100">
                                  <ClipLoader color="#fff" loading size={20} />
                                </div>
                              ) : props.product?.variants?.filter(v => v.sku == selected_sku)[0]?.quantity || props.product?.product_type == "wholesale" ? (
                                t("item-detail.buy-now")
                              ) : (
                                t("item-detail.out-of-stock")
                              )}
                            </span>
                          </span>
                          :
                          <></>
                      :
                      <></>
                  }
                </div>
              </div>
            </div>
        }
      </Modal.Body>
      {showCart && <CartSidebar refs={sideCartRef} bool={showCart} />}
      {checkout_redirect.value && (
        <Redirect
          push
          to={{
            pathname: checkout_redirect.path,
            state: checkout_redirect.state,
          }}
        />
      )}
    </Modal>
  );
}

const mapStateToProps = (state) => {
  return {
    countryId: state.language.countryId,
    countryUrl: state.language.countryUrl,
    country: state.language.country,
    language: state.language.language,
    auth: state.auth.isSignedIn
  };
};

const mapDispatchToProps = { set_cart_items };

export default connect(mapStateToProps, mapDispatchToProps)(VariantsModal);