import React, { Fragment, useState } from "react";
import { Redirect } from "react-router-dom";

import "./../../../../assets/styles/customer/account/addresses/address.scss";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

const Header = (props) => {
  const [redirect, setRedirect] = useState(false);
  const { t } = useTranslation();
  return (
    <Fragment>
      <div className="header-desktop d-flex justify-content-between align-items-center mx-0 row ">
        <div className="mr-2 ">
          <div className="heading">
            {t("customer-profile-address.main-label")}
          </div>
          <p className="subheader">
            {t("customer-profile-address.sub-heading")}
          </p>
        </div>
        <div>
          <button
            onClick={(e) => {
              e.preventDefault();
              setRedirect(true);
            }}
            type="button"
            className="buttpon-address"
          >
            {t("customer-profile-address.add-new-btn")}
          </button>
        </div>
      </div>
      {redirect && (
        <Redirect
          push
          to={{
            pathname:
              "/" + props.countryUrl + "-" + props.language + "/address",
            state: {
              name: "customer"
            },
          }}
        />
      )}
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    countryUrl: state.language.countryUrl,
    language: state.language.language,
  };
};
export default connect(mapStateToProps, null)(Header);
