import React, { useState } from "react";
import { connect } from "react-redux";
import "../../../assets/styles/component/mobile/CustomerAddress/CustomerAddressBody.scss";
import EmptyAddress from "../../../assets/images/address_art.svg";
import CustomerAddressData from "./CustomerAddressData";
import { Link, Redirect } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { apiJson } from "../../../Api";
import { useTranslation } from "react-i18next";
import { ClipLoader } from "react-spinners";
import { set_cart_items } from "../../../redux/actions/CartAction";

function CustomerAddressBody(props) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  let message = "";
  const [redirect, setRedirect] = useState({
    value: false,
    path: "/" + props.countryUrl + "-" + props.language + "/select-address",
  });
  let country_code =
    props.country.toLowerCase() === "united arab emirates"
      ? "uae"
      : props.country.toLowerCase() === "saudi arabia"
        ? "ksa"
        : "egypt";

  const showError = () => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  const handleSubmit = () => {
    setLoading(true);
    let selectedAdd = {};
    props.address.map((address) =>
      address.default ? (selectedAdd = address) : address
    );
    if (Object.entries(selectedAdd).length === 0) {
      setLoading(false);
      message = "Please select your Shipping Address";
      showError();
    } else {
      const request = {
        order: {
          address_id: selectedAdd.id,
          is_delivery: 1
        },
      };
      if (selectedAdd.contact_number_verified) {
        apiJson.select
          .varifyAdress(request, props.countryId)
          .then((response) => {
            setLoading(false);
            if (response.success) {
              props.set_cart_items({
                order: {
                  address: selectedAdd,
                  id: response.data.id,
                  createdAddress: response.data,
                  request: request
                },
              });

              setRedirect({
                value: true,
                path:
                  "/" + props.countryUrl + "-" + props.language + "/checkout",
              });
            } else if (!response.status) {
              message = t("errors.wrong");
              showError();
            } else {
              if (response.status >= 400 && response.status < 500) {
                message = response.message
                  ? response.message
                  : response.errors
                    ? response.errors
                    : t("errors.wrong");
                showError();
              } else {
                message = response.message
                  ? response.message !== ""
                    ? response.message
                    : t("errors.wrong")
                  : t("errors.wrong");
                showError();
              }
            }
          });
      } else {
        // props.set_cart_items({
        //   order: {
        //     id: selectedAdd.id,
        //     number: selectedAdd.contact_number,
        //     address: selectedAdd,
        //     request: request,
        //   },
        // });

        apiJson.phone.verifyOtp(selectedAdd.id, "1").then((response) => {
          if (response.success) {
            apiJson.select
              .varifyAdress({
                order: {
                  address_id: selectedAdd.id,
                  is_delivery: 1
                }
              }, props.countryId)
              .then((response) => {
                setLoading(false);
                if (response.success) {
                  props.set_cart_items({
                    order: {
                      address: selectedAdd,
                      id: response.data.id,
                      createdAddress: response.data,
                      request: request,
                    },
                  });
                  
                  setRedirect({
                    value: true,
                    path:
                      "/" + props.countryUrl + "-" + props.language + "/checkout",
                  });
                  const timer = setTimeout(() => {
                    setRedirect({ value: false });
                  }, 1000);
                  clearTimeout(timer);
                } else if (!response.status) {
                  setLoading(false);
                  message = t("errors.wrong");
                  showError();
                } else {
                  setLoading(false);
                  if (response.status >= 400 && response.status < 500) {
                    message = response.message
                      ? response.message
                      : response.errors
                        ? response.errors
                        : t("errors.wrong");
                    showError();
                  } else {
                    message = response.message
                      ? response.message !== ""
                        ? response.message
                        : t("errors.wrong")
                      : t("errors.wrong");
                    showError();
                  }
                }
              });
          } else if (!response.status) {
            setLoading(false);
            message = response.message
              ? response.message !== ""
                ? response.message
                : t("errors.wrong")
              : t("errors.wrong");
            showError();
          } else {
            setLoading(false);
            if (response.status >= 400 && response.status < 500) {
              message = response.message
                ? response.message
                : response.errors
                  ? response.errors
                  : t("errors.wrong");
              showError();
            } else {
              message = response.message
                ? response.message !== ""
                  ? response.message
                  : t("errors.wrong")
                : t("errors.wrong");
              showError();
            }
          }
        });

        // setRedirect({
        //   value: true,
        //   path: "/" + props.countryUrl + "-" + props.language + "/phone",
        // });
        // setRedirect({
        //   value: true,
        //   path:
        //     "/" + props.countryUrl + "-" + props.language + "/checkout",
        // });
      }
      const timer = setTimeout(() => {
        setRedirect({
          value: false,
          path:
            "/" + props.countryUrl + "-" + props.language + "/select-address",
        });
      }, 1000);
      clearTimeout(timer);
    }
  };

  return (
    <div className="customer-address-body-mobile d-flex bg-white">
      {props.address.filter((ele) =>
        window.location.pathname ===
          "/" + props.countryUrl + "-" + props.language + "/addresses"
          ? true
          : ele.country.toLowerCase() === country_code
      ).length > 0 ? (
        <div className="data-cont w-100">
          {props.address.map((ele, index) =>
            window.location.pathname ===
              "/" + props.countryUrl + "-" + props.language + "/addresses" ? (
              <CustomerAddressData
                key={index}
                id={ele.id}
                isDefault={ele.default}
                name={ele.first_name}
                address={ele.address1}
                phone={ele.contact_number}
                label={ele.label}
                country={ele.country}
                state={ele.state}
                lat={ele.latitude}
                lng={ele.longitude}
                first={ele.first_name}
                last={" "}
                address2={ele.address2}
              />
            ) : (
              (ele.country.toLowerCase() === props.country.toLowerCase() ||
                ele.country.toLowerCase() === country_code) && (
                <CustomerAddressData
                  key={index}
                  id={ele.id}
                  isDefault={ele.default}
                  name={ele.first_name}
                  address={ele.address1}
                  phone={ele.contact_number}
                  label={ele.label}
                  country={ele.country}
                  state={ele.state}
                  lat={ele.latitude}
                  lng={ele.longitude}
                  first={ele.first_name}
                  last={" "}
                  address2={ele.address2}
                />
              )
            )
          )}
          <Link
            to={{
              pathname:
                "/" + props.countryUrl + "-" + props.language + "/address",
              state:
                window.location.pathname ===
                  "/" + props.countryUrl + "-" + props.language + "/addresses"
                  ? { name: "addresses" }
                  : { name: undefined },
            }}
          >
            <div
              className={
                window.location.pathname ===
                  "/" + props.countryUrl + "-" + props.language + "/addresses"
                  ? `continue-button`
                  : `d-flex justify-content-center add-address-btn`
              }
            >
              <span
                className={`${window.location.pathname ===
                    "/" + props.countryUrl + "-" + props.language + "/addresses"
                    ? ""
                    : "add-address"
                  }`}
              >
                {t("select-shipping-address.add-new-address")}
              </span>
            </div>
          </Link>

          {window.location.pathname !==
            "/" + props.countryUrl + "-" + props.language + "/addresses" && (
              <div onClick={handleSubmit} className="continue-button">
                {loading ? (
                  <div className="d-flex justify-content-center align-items-center w-100">
                    <ClipLoader color="#fff" loading size={20} />
                  </div>
                ) : (
                  <span> {t("select-shipping-address.continue")}</span>
                )}
              </div>
            )}
        </div>
      ) : (
        <div className="empty-cont">
          <div className="img-cont">
            <img
              src={EmptyAddress}
              className="img-fluid"
              width="100%"
              height="100%"
              alt=""
            />
          </div>
          <div className="save-address mt-4">
            {t("customer-profile-address.mobile.empty.where-are-they")}
          </div>
          <div
            className="delivery-address mt-2"
            style={{ textAlign: "center" }}
          >
            {t("customer-profile-address.mobile.empty.add-address-text")}
          </div>
          <Link
            to={{
              pathname:
                "/" + props.countryUrl + "-" + props.language + "/address",
              state:
                window.location.pathname ===
                  "/" + props.countryUrl + "-" + props.language + "/addresses"
                  ? { name: "addresses" }
                  : { name: undefined },
            }}
          >
            <div className="d-flex justify-content-center mt-4">
              <span className="add-address">
                {t("customer-profile-address.mobile.empty.add-address-btn")}
              </span>
            </div>
          </Link>
        </div>
      )}

      {redirect.value && (
        <Redirect
          push={true}
          to={{
            pathname: redirect.path,
            state: redirect.state,
          }}
        />
      )}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    address: state.customerAddress.address,
    countryUrl: state.language.countryUrl,
    language: state.language.language,
    countryId: state.language.countryId,
    country: state.language.country,
  };
};

const mapDispatchToProps = { set_cart_items };
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomerAddressBody);
