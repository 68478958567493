import React, { Fragment, useState } from "react";

import { AiOutlineRight, AiOutlineLeft, AiTwotonePhone } from "react-icons/ai";
import { connect } from "react-redux";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import "../../../assets/styles/component/mobile/MyAccount/listitems.scss";
import CountryPopup from "../../../pages/mobile/CountryPopup";
import LanguagePopup from "../../../pages/mobile/LanguagePopup";
import {
  set_country,
  set_direction,
} from "../../../redux/actions/LayoutAction";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
// import accountPhone from "../../../assets/icons/phone.png";
// import { BsFillTelephoneFill } from "react-icons/bs";

const ListItems = (props) => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const { header, List, authorized } = props;
  const [countryPopup, setCountryPopup] = useState(false);
  const [langPopup, setLangPopup] = useState(false);
  const [redirect, setRedirect] = useState({ value: false });

  const changeLanguage = (lang) => {
    i18n.changeLanguage(
      lang.toLowerCase() === "English".toLowerCase() ? "en" : "ar"
    );
    props.set_direction(
      lang.toLowerCase() === "English".toLowerCase() ? "en" : "ar"
    );
    history.push({
      pathname:
        "/" +
        props.countryUrl +
        "-" +
        (lang.toLowerCase() === "English".toLowerCase() ? "en" : "ar") +
        "/" +
        window.location.pathname.replace(/(?:.*?\/){2}/, ""),
      state: location.state,
    });
  };

  const changeCountry = async (country) => {
    if (country.toLowerCase() === "United Arab Emirates".toLowerCase()) {
      let c_id = 1;
      props.coutriesList.map((country) => {
        if (country.name_en === "UAE") {
          c_id = country.id;
        }
        return country;
      });
      props.set_country({ country: country, countryId: c_id });
    } else if (country.toLowerCase() === "Saudi Arabia".toLowerCase()) {
      let c_id = 1;
      props.coutriesList.map((country) => {
        if (country.name_en === "KSA") {
          c_id = country.id;
        }
        return country;
      });
      props.set_country({ country: country, countryId: c_id });
    } else {
      let c_id = 1;
      props.coutriesList.map((country) => {
        if (country.name_en === "Egypt") {
          c_id = country.id;
        }
        return country;
      });

      props.set_country({ country: country, countryId: c_id });
    }
  };

  useEffect(() => {
    (props.country.toLowerCase() === "saudi arabia"
      ? "saudi"
      : props.country.toLowerCase() === "united arab emirates"
      ? "uae"
      : "egypt") !== props.countryUrl &&
      props.set_country(
        props.country.toLowerCase() === "saudi arabia"
          ? { countryUrl: "saudi" }
          : props.country.toLowerCase() === "united arab emirates"
          ? { countryUrl: "uae" }
          : { countryUrl: "egypt" }
      );
    (props.country.toLowerCase() === "saudi arabia"
      ? "saudi"
      : props.country.toLowerCase() === "united arab emirates"
      ? "uae"
      : "egypt") !== props.countryUrl &&
      history.push(
        "/" +
          (props.country.toLowerCase() === "saudi arabia"
            ? "saudi"
            : props.country.toLowerCase() === "united arab emirates"
            ? "uae"
            : "egypt") +
          "-" +
          props.lang +
          "/"
      );
  }, [props.countryId]); // eslint-disable-line

  return (
    <Fragment>
      <div className="my-account-list-items-mobile">
        <div className={authorized ? "header-logged-in" : "header-logged-out"}>
          {header}
        </div>
        <>
          {countryPopup && (
            <CountryPopup
              onClose={(val) => setCountryPopup(val)}
              setCountry={(c_name) => {
                changeCountry(c_name);
              }}
            />
          )}
          {langPopup && (
            <LanguagePopup
              onClose={(val) => setLangPopup(val)}
              setLanguage={(name) => {
                changeLanguage(name);
              }}
            />
          )}
          <div className="list-main">
            {List.map((item) => (
              // item.title == t("my-account-logged-in.contact-us") ?
              item.title == "Contact Us" ?
              <a href="tel:+971543064845"
                key={item.id}
                className="list-items"
                style={{ textDecoration: "none" }}
              >
                <div className="left-pan">
                  <div className="left-icon">
                    <AiTwotonePhone size="30" color="gray" />
                    {/* <item.icon size="30" /> */}
                  </div>
                  <div
                    className={
                      authorized ? "title-logged-in" : "title-logged-out"
                    }
                  >
                    {item.title}
                  </div>
                </div>
                <div className="right-pan">
                  <div className="right-icon">
                    {item.title === t("my-account-logged-in.country") && (
                      <img
                        src={item.rightIcon}
                        alt="flag"
                        width="30px"
                        height="20px"
                      />
                    )}
                    {item.title === t("my-account-logged-in.language") && (
                      <span className="inner-span">{item.rightIcon}</span>
                    )}
                  </div>
                  <div className="forward-icon">
                    {props.lang === "en" ? (
                      <AiOutlineRight size={20} color="black" />
                    ) : (
                      <AiOutlineLeft size={20} color="black" />
                    )}
                  </div>
                </div>
              </a>
              :
              <div
                key={item.id}
                onClick={() => {
                  item.title === t("my-account-logged-in.country") &&
                    setCountryPopup(!countryPopup);
                  item.title === t("my-account-logged-in.language") &&
                    setLangPopup(!langPopup);
                  item.path && setRedirect({ value: true, path: item.path });
                  const timer = setTimeout(() => {
                    setRedirect({ value: false });
                  }, 1000);
                  return () => clearTimeout(timer);
                }}
                className="list-items"
              >
                <div className="left-pan">
                  <div className="left-icon">
                    <item.icon size="30" />
                  </div>
                  <div
                    className={
                      authorized ? "title-logged-in" : "title-logged-out"
                    }
                  >
                    {item.title}
                  </div>
                </div>
                <div className="right-pan">
                  <div className="right-icon">
                    {item.title === t("my-account-logged-in.country") && (
                      <img
                        src={item.rightIcon}
                        alt="flag"
                        width="30px"
                        height="20px"
                      />
                    )}
                    {item.title === t("my-account-logged-in.language") && (
                      <span className="inner-span">{item.rightIcon}</span>
                    )}
                  </div>
                  <div className="forward-icon">
                    {props.lang === "en" ? (
                      <AiOutlineRight size={20} />
                    ) : (
                      <AiOutlineLeft size={20} />
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </>
      </div>
      {redirect.value && <Redirect push to={redirect.path} />}
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    country: state.language.country,
    countryId: state.language.countryId,
    coutriesList: state.language.coutriesList,
    lang: state.language.language,
    countryUrl: state.language.countryUrl,
  };
};

const mapDispatchToProps = {
  set_country,
  set_direction,
};
export default connect(mapStateToProps, mapDispatchToProps)(ListItems);
