import { type } from "../../types/types";

export default function reducer(state = { returned_items: [] }, action) {
  switch (action.type) {
    case type.SET_RETURN_ITEMS:
      return { ...state, ...action.returnItems };
    default:
      return state;
  }
}
