import React, { Fragment } from "react";
import { useState, useRef } from "react";
import { connect } from "react-redux";
import ProductCard from "./ProductCard";

import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination, Navigation } from "swiper/core";

import "swiper/swiper.scss";
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css";
import "swiper/components/navigation/navigation.min.css";

import "../../assets/styles/product/productlist.scss";

import { getDesktopImage } from "../../utils/utils.js";

import { useOutsideAlerter } from "../../utils/CustomHooks";
import CartSidebar from "../customer/cart/CartSidebar";

const screenBreakpoints = {
  md: 768,
  lg: 992,
  xl: 1024,
  xxl: 1200,
  xxxl: 1440,
};
SwiperCore.use([Pagination, Navigation]);

const TabProductList = (props) => {
  const [width, setWidth] = useState(window.innerWidth);
  const sideCartRef = useRef(null);
  const [showCart, setShowCart] = useState(false);
  useOutsideAlerter(sideCartRef, setShowCart);

  const setShowCartSidebar = (val) => {
    setShowCart(val);
  }

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  window.addEventListener("resize", handleWindowSizeChange);

  const productCountPerSlideView = () => {
    if (width >= screenBreakpoints.xxxl) {
      return 5;
    } else if (width >= screenBreakpoints.xxl) {
      return 5;
    } else if (width >= screenBreakpoints.xl) {
      return 4;
    } else if (width >= screenBreakpoints.lg) {
      return 3;
    } else if (width >= screenBreakpoints.md) {
      return 3;
    } else {
      return 5;
    }
  };

  return (
    <Fragment>
      <div className="product-list-desktop">
        <div className="product-list-container mx-auto">
          <Swiper
            spaceBetween={0}
            slidesPerView={productCountPerSlideView()}
            pagination={{
              clickable: true,
            }}
            navigation={true}
          >
            {props.products.map((product) =>
              product.product ?
                product.product?.variants ? (
                  <SwiperSlide key={product.product.id + Math.random() + "prod"}>
                    <ProductCard
                      key={product.product.id + Math.random() + "prod"}
                      home={props.home}
                      product={{
                        id: product.product.id,
                        slug: product.product.slug,
                        line_item_id: product.product.variants[0].id,
                        img: getDesktopImage(
                          product.product.variants[0].featured_image_resized,
                          product.product.variants[0].featured_image
                        ),
                        type: "products",
                        description: product.product[`name_${props.language}`],
                        storeLogo: product.product.store_logo,
                        description_en: product.product[`name_en`],
                        category_en: product.product.category_title_en,
                        price_after_discount: parseFloat(
                          product.product.variants[0].discounted_price
                        ),
                        price_before_discount: parseFloat(
                          product.product.variants[0].price
                        ),
                        rating: parseInt(product.product.average_rating),
                        count: product.product.reviews_count,
                        wishlisted: product.product.variants[0].wishlisted,
                        sku: product.product.variants[0].sku,
                        start_price: product.product.variants[0].start_price,
                        end_price: product.product.variants[0].end_price,
                        coupon: product.product.coupon,
                        is_inquiry: product.product.is_inquiry,
                        product_type: product.product.product_type,
                        quantity: product.product.variants[0].quantity
                      }}
                      onQuickViewClicked={props.onQuickViewClicked}
                      setShowCartSidebar={setShowCartSidebar}
                    />
                  </SwiperSlide>
                ) : (
                  <SwiperSlide key={product.product.id + Math.random() + "ser"}>
                    <ProductCard
                      key={product.product.id + Math.random() + "ser"}
                      isHome={props.home}
                      product={{
                        id: product.product.id,
                        img: getDesktopImage(
                          product.product.featured_image_resized,
                          product.product.featured_image
                        ),
                        type: "services",
                        slug: product.product.slug,
                        description: product.product[`name_${props.language}`],
                        description_en: product.product[`name_en`],
                        storeLogo: product.product.store_logo,
                        category_en: product.product.category_title_en,
                        price_after_discount: parseFloat(product.product.discounted_price),
                        price_before_discount: parseFloat(product.product.price),
                        rating: parseInt(product.product.average_rating),
                        count: product.product.reviews_count,
                        wishlisted: product.product.wishlisted,
                        sku: product.product.sku,
                        coupon: product.product.coupon,
                        is_inquiry: product.product.is_inquiry,
                        product_type: product.product.product_type
                      }}
                    />
                  </SwiperSlide>
                )
                :
                <></>
            )}
          </Swiper>
        </div>
        {showCart && <CartSidebar refs={sideCartRef} bool={showCart} />}
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    language: state.language.language,
  };
};

export default connect(mapStateToProps, null)(TabProductList);
