import { type } from "../../types/types";
const initialState = {
  allCategories: [],
  featuredCategories: [],
  subCategories: [],
  searchCat: [],
  searchBrands: [],
  searchProducts: [],
  searchServices: [],
  searchHistory: [],
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case type.SET_CATEGORIES:
      return {
        ...state,
        ...action.categories,
      };
    case type.SET_SEARCH:
      return {
        ...state,
        ...action.search,
      };
    default:
      return state;
  }
}
