import React from "react";

import Logo from "../../../assets/images/logo.png";
import flag from "../../../assets/images/flag.png";
import flag1 from "../../../assets/images/flag2.png";
import flag2 from "../../../assets/images/flag3.png";
import "../../../assets/styles/component/mobile/SelectCountry/CountryMobile.scss";
import { useTranslation } from "react-i18next";

export default function SelectCountry() {
  const { t } = useTranslation();
  return (
    <div className="country-mob ml-auto mr-auto">
      <div className="logo-cont">
        <div className="logo-size">
          <img src={Logo} width="100%" height="100%" alt="" />
        </div>

        <div className="heading w-100">{t("select-country-mob.title")}</div>
        <div className="body-text w-100">
          {t("select-country-mob.destination")}
        </div>

        <div className="country-cont">
          <div className="flag">
            <img src={flag1} alt="" width="100%" height="100%" />
          </div>
          <div className="text">{t("select-country-mob.saudia")}</div>
        </div>

        <div className="country-cont mt-4">
          <div className="flag">
            <img src={flag} alt="" width="100%" height="100%" />
          </div>
          <div className="text">{t("select-country-mob.uae")}</div>
        </div>

        <div className="country-cont mt-4">
          <div className="flag">
            <img src={flag2} alt="" width="100%" height="100%" />
          </div>
          <div className="text">{t("select-country-mob.egypt")}</div>
        </div>
      </div>
    </div>
  );
}
