import React from "react";
import { useState } from "react";
import { BsFillEyeFill, BsFillEyeSlashFill } from "react-icons/bs";
import { connect } from "react-redux";
import { apiJson } from "../../Api";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MobileLayoutRenderer from "./layout/MobileLayoutRenderer";
import { useTranslation } from "react-i18next";
import { Redirect } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import "../../assets/styles/mobile/changepasswordmobile.scss";

const ChangePasswordMobile = (props) => {
  const { t } = useTranslation();
  const [currentPassword, setCurrentPassword] = useState("");
  const [showcurntPass, setShowcurntPass] = useState(false);

  const [shinnyPassword, setShinnyPassword] = useState("");

  const [showshinnyPass, setShowshinnyPass] = useState(false);

  const [error, setError] = useState(false);
  const [currError, setCurrError] = useState(false);
  const [changing, setChanging] = useState(false);
  const [redirect, setRedirect] = useState(false);

  let message = "";
  const showError = () => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  const showSuccess = () => {
    toast.success(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const changePass = () => {
    setError(false);
    setCurrError(false);
    if (shinnyPassword.length > 5 && currentPassword.length > 5) {
      setChanging(true);
      apiJson.profile
        .changPass({
          old_password: currentPassword,
          new_password: shinnyPassword,
          confirmation_password: shinnyPassword,
        })
        .then((response) => {
          setChanging(false);
          if (response.success) {
            message = "Password changed";
            showSuccess();
            setRedirect(true);
          } else if (!response.status) {
            message = response.message
              ? response.message !== ""
                ? response.message
                : t("errors.wrong")
              : t("errors.wrong");
            showError();
          } else {
            if (response.status >= 400 && response.status < 500) {
              message = response.message
                ? response.message
                : response.errors
                ? response.errors
                : t("errors.wrong");
              showError();
            } else {
              message = response.message
                ? response.message !== ""
                  ? response.message
                  : t("errors.wrong")
                : t("errors.wrong");
              showError();
            }
          }
        });
    } else {
      shinnyPassword.length < 6 && setError(true);
      currentPassword.length < 6 && setCurrError(true);
    }
  };

  return (
    <>
      <MobileLayoutRenderer
        NavbarTop={true}
        BackBtn={false}
        CancelBtn={true}
        NavbarBottom={false}
      >
        {props.width === "sm" && (
          <div className="change-password-mobile-page">
            <div className="inner-div">
              <div className="change-passsword-fields">
                <div className="change-password-first-row">
                  <label className="e-label">
                    {t("change-pw-mob.current-pw")}
                  </label>

                  <div className="d-flex pass-cont justify-content-center align-items-center">
                    <input
                      className="password"
                      type={`${showcurntPass ? "text" : "password"}`}
                      value={currentPassword}
                      onChange={(e) => setCurrentPassword(e.target.value)}
                      placeholder={t("change-pw-mob.current-pw-ph")}
                    />
                    {showcurntPass ? (
                      <BsFillEyeFill
                        onClick={() => setShowcurntPass(!showcurntPass)}
                        className="eye-icon"
                        size="14px"
                        color="rgb(126, 133, 155)"
                      />
                    ) : (
                      <BsFillEyeSlashFill
                        onClick={() => setShowcurntPass(!showcurntPass)}
                        className="eye-icon"
                        size="14px"
                        color="rgb(126, 133, 155)"
                      />
                    )}
                  </div>
                </div>
                {currError && (
                  <div className="error">Length must be greater than 5</div>
                )}
                <div className="change-password-second-row">
                  <label className="e-label">{t("change-pw-mob.new-pw")}</label>

                  <div className="d-flex pass-cont justify-content-center align-items-center">
                    <input
                      className="password"
                      type={`${showshinnyPass ? "text" : "password"}`}
                      value={shinnyPassword}
                      onChange={(e) => setShinnyPassword(e.target.value)}
                      placeholder={t("change-pw-mob.new-pw-ph")}
                    />
                    {showshinnyPass ? (
                      <BsFillEyeFill
                        onClick={() => setShowshinnyPass(!showshinnyPass)}
                        className="eye-icon"
                        size="14px"
                        color="rgb(126, 133, 155)"
                      />
                    ) : (
                      <BsFillEyeSlashFill
                        onClick={() => setShowshinnyPass(!showshinnyPass)}
                        className="eye-icon"
                        size="14px"
                        color="rgb(126, 133, 155)"
                      />
                    )}
                  </div>
                  {error && (
                    <div className="error">Length must be greater than 5</div>
                  )}
                </div>
              </div>
              <div onClick={changePass} className="change-password-mobile-end">
                <button className="change-password-mobile-button">
                  {changing ? (
                    <div className="d-flex justify-content-center align-items-center w-100">
                      <ClipLoader color="#000" loading size={20} />
                    </div>
                  ) : (
                    t("change-pw-mob.change-pw-btn")
                  )}
                </button>
              </div>
            </div>
          </div>
        )}
        {redirect && <Redirect push to="profile" />}
      </MobileLayoutRenderer>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    width: state.layout.screenWidth,
  };
};

export default connect(mapStateToProps, null)(ChangePasswordMobile);
