import React, { useState, useEffect } from "react";
import "../../../assets/styles/component/customer/itemDetail/FrequentBought.scss";
import FreqProd from './FreqProd';


export default function frequentBought(props) {

    return (

        <div className="frequentContainer">

            <div className="frequent">
                <div className="frequent1empty">

                </div>
                <div className="frequent2main">
                    <span className="frequenttxt">Frequently Bought Together</span>
                    <div className="freqprodbox">
                        <FreqProd sku={props.sku} product={props.product}/>

                    </div>
                    {/* <div className="freqadddiv"><button className="freqadd">Buy 3 togethor for AED 830.40</button>
                    </div> */}
                </div>
            </div>

        </div>














    );


}