import React from "react";
import Banner from "../../../assets/images/homepage/YelloColorImage.png";
import { useTranslation } from "react-i18next";
import { Redirect, Link } from "react-router-dom";
import { useState } from "react";
import "../../../assets/styles/component/mobile/AllCategories/AllCategoriesRightPanel.scss";
import { connect } from "react-redux";

function AllCategoriesRightPanel(props) {
  const { t } = useTranslation();
  const [redirect, setRedirect] = useState({ value: false });

  return (
    <div className="categories-right-panel">
      <div className="main-img mt-3">
        {props.banners[0]?.image ? (
          <>
            {props.banners[0]?.redirects_to_link ? (
              <a href={props.banners[0]?.redirects_to_link}>
                <img
                  src={props.banners[0].image}
                  height="100%"
                  width="100%"
                  alt="banner"
                />
              </a>
            ) : (
              <img
                src={props.banners[0].image}
                height="100%"
                width="100%"
                alt=""
              />
            )}
          </>
        ) : (
          <img src={Banner} height="100%" width="100%" alt="" />
        )}
      </div>

      <div className=" mt-4 data-cont">
        <div className=" d-flex justify-content-between">
          <span className={props.language == "en" ? "option-name" : "option-name no-width"}>{props.name}</span>
          <div className={props.language == "en" ? "viewalldiv" : ""}>
            <span
              onClick={() =>
                setRedirect({
                  value: true,
                  state: { category: undefined, brand: undefined },
                })
              }
              className="view-all"
            >
              {t("all-categories.view-all")}
            </span>
          </div>
        </div>

        <div className="row no-gutters category-cont mt-3">
          {props.subCategory.map((ele) => (
            <Link
              key={ele.id}
              style={{ textDecoration: "none" }}
              to={{
                pathname:
                  "/" +
                  props.countryUrl +
                  "-" +
                  props.language +
                  "/items/products",
                search: "?filter_by_category%5B%5D=" + ele.id
                // state: {
                //   category:
                //     ele.sub_categories?.length > 0
                //       ? [
                //           ele.id,
                //           ...ele.sub_categories.map((subCat) => subCat.id),
                //         ]
                //       : [ele.id],
                // },
              }}
              className="col-4 mt-2 pb-1 pr-1"
            >
              <div className="img-style p-2 mr-auto ml-auto">
                <img
                  src={ele.image}
                  width="100%"
                  height="100%"
                  alt={ele[`title_${props.lang}`]}
                />
              </div>

              <div className="name-style mt-1">
                {ele[`title_${props.lang}`]}
              </div>
            </Link>
          ))}
        </div>
      </div>

      {redirect.value && (
        <Redirect
          push
          to={{
            pathname:
              "/" + props.countryUrl + "-" + props.language + "/items/products",
            state: redirect.state,
          }}
        />
      )}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    language: state.language.language,
    countryUrl: state.language.countryUrl,
    banners: state.categories.banners,
    lang: state.language.language,
  };
};

export default connect(mapStateToProps, null)(AllCategoriesRightPanel);
